import { Action, Reducer } from 'redux';
import { httpService } from '../index';
import { AppThunkAction } from './';
import { Notification } from '../models/notification/Notification'

export interface NotificationState {
    selectedItem: Notification | null,
    list: Array<Notification>,
    isLoading: boolean,
    error: string
}


interface SelectNotificationAction {
    type: 'SELECT_NOTIFICATION';
    item: Notification;
}

interface RequestNotificationsAction {
    type: 'REQUEST_NOTIFICATION';
}

interface ReceiveNotificationsAction {
    type: 'RECEIVE_NOTIFICATION';
    items: Array<Notification>;
    error: '';
}



type KnownAction = SelectNotificationAction | RequestNotificationsAction | ReceiveNotificationsAction;

export const actionCreators = {
    selectItem: (item: Notification): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SELECT_NOTIFICATION', item: item });
    },
    getItems: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.countryList) {
            try {
                dispatch({ type: 'REQUEST_NOTIFICATION' });
                let data = await httpService.getNotifications();
                dispatch({ type: 'RECEIVE_NOTIFICATION', items: data, error: '' });

            } catch (err) {
                dispatch({ type: 'RECEIVE_NOTIFICATION', items: [], error: '' });
            }
        }
    },
};

const unloadedState: NotificationState = { selectedItem: null, isLoading: false, list: [], error: '' };

export const reducer: Reducer<NotificationState> = (state: NotificationState | undefined, incomingAction: Action): NotificationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_NOTIFICATION':
            return {
                selectedItem: action.item,
                list: state.list,
                isLoading: false,
                error: ''
            };
        case 'REQUEST_NOTIFICATION':
            return {
                selectedItem: state.selectedItem,
                list: [],
                isLoading: true,
                error: ''
            };
        case 'RECEIVE_NOTIFICATION':

            let si = state.selectedItem;

            if (action.items && action.items.length > 0 && !state.selectedItem) {
                si = action.items[0];
            }

            return {
                selectedItem: si,
                list: action.items,
                isLoading: false,
                error: action.error
            };


    }
    return state;

};
