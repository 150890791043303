import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import * as DialogStore from '../store/DialogStore';
import { httpService } from '../index';
import { SpecialComp } from '../models/competition/CompetitionDate';
import SpecialCompDialog from './SpecialCompDialog';
import * as CompetitionListStore from '../store/CompetitionListStore';
import { Helpers } from '../utility/Helpers';
import { UserType } from '../models/registered-user/RegisteredUserEnums';


type SpecialCompListProps = {
	specialComps: Array<SpecialComp>;
	parentId: string;
}

class SpecialCompList extends React.Component<SpecialCompListProps & typeof CompetitionListStore.actionCreators & any, { isOpen: boolean; specialComp: SpecialComp | null, specialComps: Array<SpecialComp>, addMode: boolean, judges: any }> {

	constructor(props: any) {
		super(props);
		this.state = {
			isOpen: false,
			specialComp: null,
			specialComps: this.props.specialComps,
			addMode: false,
			judges: []
		}
	}


	addRemoveSpecialComp(item?: any, remove?: boolean) {
		if (remove) {
			let data: any = [...this.state.specialComps ];
			let ind = data.findIndex((i: SpecialComp) => i.id === item!.id);
			if (ind !== -1) {
				data.splice(ind, 1);
				this.setState({ specialComps: data });
			}
		} else if (!remove) {
			let data: any = [ ...this.state.specialComps ];			
			data.push(item)
			this.setState({ specialComps: data });
		}
	}

	removeSpecialComp(item: SpecialComp) {
		this.props.showDialog(`Are you sure you want to delete this special competition?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
			if (confirm && item.id) {
				this.props.increment();
				let i = item
				try {
					await httpService.specialCompetitionDelete(item.id);
					this.addRemoveSpecialComp(i, true)
					this.props.getCompetitionDate(this.props.parentId)
				
					this.props.decrement();
					
				} catch (err) {
					this.props.decrement();
					alert(err.message);
				}
			}
		})
	}


	showDetails = async (id?: string) => {
		this.setState({ isOpen: true })
		if (id) {
			let cd = await httpService.getSpecialCompetition(id)
			let judges = await httpService.getRegisteredUsers({ page: null, username: '', usertype: UserType.JUDGE });
			this.setState({ specialComp: cd, judges: judges })
		} else {
			this.setState({
				specialComp: {
					competitionDateId: this.props.parentId,
					title: '',
					englishTitle: '',
					price: '',
					basePriceTaxPercentage: 0,
					isFinished: false,
					isPresentFinished: false,
					approxStartTime: ''
				}, addMode: true
			})
		}
			
	}

	

	render() {
		return (
			<div className="">
				{!Helpers.isUserEventViewer(this.props.userState.user) &&
					<div style={{ float: "right" }}>
						<label className="cur-p mL-10">
							<a href="#" onClick={(e: any) => {
								e.preventDefault()
								this.showDetails()

							}}>
								<div className="bgc-blue-50 c-blue-500 circle-add">+</div>
							</a>
						</label>
						<br /><br />
					</div>
				}
				<div>
					<table className="table">
						<thead>
							<tr>
								<th scope="col">#</th>
								
								<th scope="col">Title</th>
								<th style={{ textAlign: "right" }} scope="col">Action</th>
							</tr>
						</thead>

						<tbody>
							{this.props.specialComps && this.props.specialComps.length > 0 && (this.props.specialComps.map((specialComp: any, index: number) => (
								<tr key={specialComp.id}>
									<td style={{ color: "grey" }} className="cur-p">
										{index + 1}
									</td>
									
									<td style={{ color: "grey" }} >
										{specialComp.title}
									</td>

									<td style={{ textAlign: "right" }}>

										<span style={{ cursor: "pointer", fontSize: "16px" }} className="icon-holder" onClick={() => this.showDetails(specialComp.id)}><i className={!Helpers.isUserEventViewer(this.props.userState.user) ? "c-light-blue-500 ti-pencil" : "c-light-blue-500 fa fa-search"}></i></span>
										{!Helpers.isUserEventViewer(this.props.userState.user) &&
											<>
											<span style={{ fontSize: "16px" }}> | </span>
											<a href="#" onClick={(e) => { e.preventDefault(); this.removeSpecialComp(specialComp) }}>
												<span style={{ fontSize: "16px" }} className="icon-holder">
													<i className="c-light-blue-500 ti-trash"></i>
												</span>
												</a>
											</>
										}


									</td>
								</tr>
							))) 
								}
						</tbody>
					</table>
					{this.state.specialComps && this.state.specialComps.length < 1 &&
						<div style={{ color: "grey", display: "flex", justifyContent: "center" }} className="cur-p">No
							special competitions.</div>
					}
				
				</div>
				
				{this.state.isOpen &&
					
					<SpecialCompDialog data={this.state.specialComp} open={this.state.isOpen} parentId={this.props.parentId}
						judges={this.state.judges}
						closePreview={() => { this.setState({ isOpen: false, addMode: false }) }}
						add={(item: SpecialComp, remove: boolean) => {
							this.addRemoveSpecialComp(item, remove)

						}}
						addMode={this.state.addMode}
				/>
				}
			</div>
		)
	}

	static defaultProps: SpecialCompListProps = {
		specialComps: [],
		parentId: ''
	}
}

const mapStateToProps = (state: ApplicationState) => {
	return {
		dataState: state.competitionList,
		dialog: state.dialog,
		userState: state.user
	};
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		
		showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
			dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
		},
		showSpecialCompDialog: (d: SpecialComp, detailsOnly: boolean, cal: Function) => {
			dispatch(DialogStore.actionCreators.showSpecialCompDialog(d, detailsOnly, cal))
		},
		getCompetitionDate: (id: string) => {
			dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
		},
		increment: () => {
			dispatch(DialogStore.actionCreators.increment())
		},
		decrement: () => {
			dispatch(DialogStore.actionCreators.decrement())
		},
		hideCompDateDialog: () => {
			dispatch(DialogStore.actionCreators.hideCompDateDialog())
		},
		hideSpecialCompDialog: () => {
			dispatch(DialogStore.actionCreators.hideSpecialCompDialog())
		},
		showNotification: (data: DialogStore.NotificationData) => {
			dispatch(DialogStore.actionCreators.showNotification(data))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
	
)(SpecialCompList)
