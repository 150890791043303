import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import { httpService } from "../../index";
import * as DialogStore from '../../store/DialogStore';
import { CompetitionDate } from '../../models/competition/Competition';
import { InvoiceIssuer } from '../../models/invoice/InvoiceIssuer';
import { InvoiceInfo } from '../../models/invoice/InvoiceInfo';
import { Helpers } from '../../utility/Helpers';
import { IssuerType } from '../../models/invoice/InvoiceEnums';
import SpecialBreedDialog from './SpecialBreedDialog';
import { Breed } from '../../models/dog/Breed';
import { NotificationType } from '../../store/DialogStore';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DateTimePicker from '../DateTimePicker';

type CompDateDialogProps =
    DialogStore.DialogState &
    typeof DialogStore.actionCreators &
    React.ComponentProps<any>;

export type CompDateDialogData = {
    data: CompetitionDate,
    callback: Function
}

class CompDateDialog extends React.PureComponent<CompDateDialogProps, { compDate: CompetitionDate | null, showPopup: boolean, issuers: Array<InvoiceIssuer>, infoList: Array<InvoiceInfo>, specialBreeds: [], isEmpty: boolean, disableButton: boolean }> {

    time: any;

    constructor(props: any) {
        super(props);
    }

    componentDidMount = async () => {
        this.dataChanged();
        this.setState({ specialBreeds: [] })
    }
  
    componentDidUpdate = async (prevProps: any) => {
        if (this.props.dialog.compDateData != prevProps.dialog.compDateData) {
            this.time = setTimeout(() => {
                this.dataChanged();
                this.clearTimeout();
            }, 0)
            
        }
        
    }

    componentWillUnmount() {
        this.clearTimeout();
    }

    private clearTimeout() {
        if (this.time) {
            clearTimeout(this.time);
            this.time = null;
        }
    }

    close(res: boolean) {
        this.time = setTimeout(() => {
            if (this.props.dialog.compDateData.callback && res === true) {
                this.props.dialog.compDateData.callback(this.state.compDate);
                this.setState({ compDate: null })
                return;
            } else {
                this.props.hideCompDateDialog();
                this.setState({ compDate: null })
                
            }
        }, 200)
    }

    addSpecialBreeds = (breedArray: any) => {
        this.setState({ specialBreeds: breedArray })
        
    }

    isBreedArrayEmpty = () => {
        this.setState({isEmpty: true })
    }

    saveChanges(data: any, save: boolean) {

        if (data && save) {
            this.props.showDialog(`Are you sure you want to save this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    let state: any = {...this.state}
                    let newBreeds = !state.specialBreeds ? [] : [...this.state.specialBreeds]
                    data.specialShowBreeds = newBreeds
               
                    try {
                        this.setState({ disableButton: true })
                        let response = await httpService.competitionDateAddOrUpdate(data)
                        this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });
                        this.setState({ compDate: response, specialBreeds: [], isEmpty: false })
                        this.close(true)
                        this.setState({ disableButton: false })

                    } catch (err) {

                        alert(err.message)
                        this.setState({ disableButton: false })

                    }

                }
                    
            })

        }
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let val = value;

        let at: any = { ...this.state.compDate };

        if (this.state.specialBreeds === undefined) {
            this.setState({ specialBreeds: [] })
        }

        if (name === 'invoiceIssuer') {
            let res = this.state.issuers.find(issuer => issuer.id === val);
            if (res) {
                at[name] = res;
            } else {
                at[name] = null;
            }
        } else if (name === 'invoiceInformation') {
            let res = this.state.infoList.find(info => info.id === val);
            if (res) {
                at[name] = res;
            } else {
                at[name] = null;
            }
        } else {
            at[name] = val;
        }

        this.setState({ compDate: at });
    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.compDate };
        data[res.name] = res.value;
        this.setState({
            compDate: data
        })
    }

    private dataChanged = async () => {
        this.setState({ showPopup: (this.props.dialog.compDateData !== null) && true, compDate: this.props.dialog.compDateData ? this.props.dialog.compDateData.data : null, specialBreeds: this.props.dialog.compDateData ? this.props.dialog.compDateData.data.specialShowBreeds : [],isEmpty: false });
        this.getDropdownData();
    }

    private async getDropdownData() {
        try {
            let issuers = await httpService.invoiceIssuers('', IssuerType.NORMAL);
            let info = await httpService.invoiceInfoList(IssuerType.NORMAL);
            this.setState({ issuers: issuers, infoList: info });
        } catch (ex) {

        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    (this.props.dialog.compDateData) &&
                    <Modal id="comp-date" size="lg" isOpen={true}>
                            <ModalHeader toggle={() => { this.close(false) }}>Show</ModalHeader>
                            <ModalBody>
                                {this.state.compDate && <div>
                                    {this.props.dialog.compDateData.detailsOnly && <div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="description">Description *</label>
                                            <input id="description" name="description" className="form-control"
                                                defaultValue={this.state.compDate.description} onChange={this.changeData}
                                                maxLength={2000} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="date">Date *</label>
                                            <DateTimePicker name="date" minDate='' isUtc={false} useTime={true}
                                                maxDate=''
                                                value={Helpers.getIsoDate(this.state.compDate.date)}
                                                onChange={(e: any) => { this.dateChange(e) }} />
                                        </div>
                                        <div className="mb-3">
                                            <div className="checkbox checkbox-info peers ai-c">
                                                <input id="canDownloadCards" name="canDownloadCards" type="checkbox"
                                                    checked={this.state.compDate.canDownloadCards} className="peer"
                                                    onChange={(e) => this.changeData(e)} />
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="canDownloadCards">
                                                    <span className="peer peer-greed">Can Download Cards (Used on web)</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="checkbox checkbox-info peers ai-c">
                                                <input id="canDownloadEvaluations" name="canDownloadEvaluations" type="checkbox"
                                                    checked={this.state.compDate.canDownloadEvaluations} className="peer"
                                                    onChange={(e) => this.changeData(e)} />
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="canDownloadEvaluations">
                                                    <span className="peer peer-greed">Can Download Evaluations (Used on web)</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="checkbox checkbox-info peers ai-c">
                                                <input id="isCACIBAwarded" name="isCACIBAwarded" type="checkbox"
                                                    checked={this.state.compDate.isCACIBAwarded} className="peer"
                                                    onChange={(e) => this.changeData(e)} />
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="isCACIBAwarded">
                                                    <span className="peer peer-greed">Is CACIB Awarded  (Used in mobile app)</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="checkbox checkbox-info peers ai-c">
                                                <input id="catalogNumbersGenerated" name="catalogNumbersGenerated" type="checkbox"
                                                    checked={this.state.compDate.catalogNumbersGenerated} className="peer"
                                                    onChange={(e) => this.changeData(e)} />
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="catalogNumbersGenerated">
                                                    <span className="peer peer-greed">Catalog Numbers Generated (Used on web)</span>
                                                </label>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="mb-3">
                                            <div className="checkbox checkbox-info peers ai-c">
                                                <input id="mustHavePedigrees" name="mustHavePedigrees" type="checkbox"
                                                    checked={this.state.compDate.mustHavePedigrees} className="peer"
                                                    onChange={(e) => this.changeData(e)} />
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="mustHavePedigrees">
                                                    <span className="peer peer-greed">Registrations must have Pedigrees</span>
                                                </label>
                                            </div>
                                        </div>   
                                        <div className="mb-3">
                                            <div className="checkbox checkbox-info peers ai-c">
                                                <input id="hasJuniorHandlingRegistrations" name="hasJuniorHandlingRegistrations" type="checkbox"
                                                    checked={this.state.compDate.hasJuniorHandlingRegistrations} className="peer"
                                                    onChange={(e) => this.changeData(e)} />
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="hasJuniorHandlingRegistrations">
                                                    <span className="peer peer-greed">Has Junior Handling registrations</span>
                                                </label>
                                            </div>
                                        </div>                                        
                                        <div className="mb-3">
                                            <div className="checkbox checkbox-info peers ai-c">
                                                <input id="hasCoupleAndGroupRegistrations" name="hasCoupleAndGroupRegistrations" type="checkbox"
                                                    checked={this.state.compDate.hasCoupleAndGroupRegistrations} className="peer"
                                                    onChange={(e) => this.changeData(e)} />
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="hasCoupleAndGroupRegistrations">
                                                    <span className="peer peer-greed">Has Couple And Group Registrations</span>
                                                </label>
                                            </div>
                                        </div>
                                        <SpecialBreedDialog data={this.state.compDate} actions={true} callback={this.addSpecialBreeds} empty={this.isBreedArrayEmpty} dateDialog={this.state.showPopup} />

                                    </div>}


                                    {this.state.compDate && this.state.compDate.id && !this.props.dialog.compDateData.detailsOnly && <div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="invoiceIssuer">Invoice issuer</label>
                                            <select id="invoiceIssuer" name="invoiceIssuer" onChange={this.changeData} className="form-control" value={this.state.compDate.invoiceIssuer ? this.state.compDate.invoiceIssuer.id : ''}>
                                                <option value="">-- no issuer --</option>
                                                {this.state.issuers && this.state.issuers.map((issuer: InvoiceIssuer) => (
                                                    <option key={issuer.id} value={issuer.id}>{issuer.issuer}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="invoiceInformation">Invoice information</label>
                                            <select id="invoiceInformation" name="invoiceInformation" onChange={this.changeData} className="form-control" value={this.state.compDate.invoiceInformation ? this.state.compDate.invoiceInformation.id : ''}>
                                                <option value="">-- no info --</option>
                                                {this.state.infoList && this.state.infoList.map((info: InvoiceInfo) => (
                                                    <option key={info.id} value={info.id}>{info.description} {info.invoicePrefix}/{info.invoiceNumber}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="invoiceLocation">Invoice location</label>
                                            <input id="invoiceLocation" name="invoiceLocation" className="form-control"
                                                defaultValue={this.state.compDate.invoiceLocation} onChange={this.changeData}
                                                maxLength={500} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="invoiceDate">Invoice date</label>
                                            <input id="invoiceDate" name="invoiceDate" className="form-control"
                                                defaultValue={this.state.compDate.invoiceDate} onChange={this.changeData}
                                                maxLength={500} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="dateOfService">Date of service</label>
                                            <input id="dateOfService" name="dateOfService" className="form-control"
                                                defaultValue={this.state.compDate.dateOfService} onChange={this.changeData}
                                                maxLength={500} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="em">EM</label>
                                            <input id="em" name="em" className="form-control"
                                                defaultValue={this.state.compDate.em} onChange={this.changeData}
                                                maxLength={500} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="vatClause ">VAT Clause</label>
                                            <input id="vatClause" name="vatClause" className="form-control"
                                                defaultValue={this.state.compDate.vatClause} onChange={this.changeData}
                                                maxLength={500} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="invoiceData">Additional invoice data</label>
                                            <input id="invoiceData" name="invoiceData" className="form-control"
                                                defaultValue={this.state.compDate.invoiceData} onChange={this.changeData}
                                                maxLength={500} />
                                        </div>
                                        <div className="mb-3">
                                            <div className="checkbox checkbox-info peers ai-c">
                                                <input id="includeInAllEventsPriceCalculation" name="includeInAllEventsPriceCalculation" type="checkbox"
                                                    checked={this.state.compDate.includeInAllEventsPriceCalculation} className="peer"
                                                    onChange={(e) => this.changeData(e)} />
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="includeInAllEventsPriceCalculation">
                                                    <span className="peer peer-greed">Include in all events price calculation</span>
                                                </label>
                                            </div>
                                        </div>
                                        </div>}
                                        </div>}
                                 
                              

                        </ModalBody>
                        <ModalFooter>
                                    
                        <Button color="secondary" onClick={() => this.close(false)}>Close</Button>
                        <Button disabled={this.state.disableButton} color="primary" onClick={() => {
                                    this.saveChanges(this.state.compDate, true)
                                    
                                }}>Save</Button>
                        </ModalFooter>

                    </Modal>


                    
                   
                }
            </React.Fragment>
        )
    }
}
export default connect(
    (state: ApplicationState) => { return { dialog: state.dialog, dataState: state.competitionList } },
    DialogStore.actionCreators
)(CompDateDialog)
