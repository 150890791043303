import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';

import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,

} from "reactstrap";
import * as DialogStore from '../../store/DialogStore';
import { httpService } from "../..";
import { ApplicationState } from "../../store";
import { Helpers } from "../../utility/Helpers";
import { getBISType, getBISTypeOptions } from "../../models/competition/CompetitionEnums";
import BreedSearch from "../BreedSearch";



type Props = {
    data: any;
    callback: any;
    empty: any;
    recorder: any;
    ringLeader: any;
    action: any;
    actions: any;
    open: any,
    add: any,
    update: any,
    save: any
}


class BISBreedsDialogComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }

    componentDidUpdate() {
        if (this.props.data && !this.state.data) {
            this.getData()
        }

    }

    getData() {

        this.setState({ data: this.props.data })
        if (this.props.data === null) {
            this.state.data = this.state.emptyBreed;
        }
    }



    state: any = {
        data: this.props.data,
        isOpen: false,
        editMode: false,
        previewMode: true,
        addMode: false,
        currentIndex: null,
        disableButton: false,
        emptyBreed: { breed: { id: '', created: Date(), fci: '', name: null } },
    
    }

    saveChanges(data: any) {
        this.setState({ disableButton: true })
        try {
            this.props.save(data)
            this.setState({ data: null, previewMode: true, editMode: false, disableButton: false })
        } catch (err) {
            this.setState({ data: null, previewMode: true, editMode: false, disableButton: false })
        }

    }

    changeData(e: any) {
        let data: any = { ...this.state.data };
        data.breed = e
        this.setState({ data: data })
    }

    setMode(): boolean {
        if (this.props.add) {
            return false
        }
        if (this.state.previewMode) {
            return true
        }
        return false

    }


    render() {

        return (
            <React.Fragment>

                <div>


                    <Modal id="bis" size="md" isOpen={this.props.open}>
                        <ModalHeader toggle={this.props.action}>{this.props.add ? "Add Indigenous Breed" : "Edit Indigenous Breed"}</ModalHeader>
                        <ModalBody>

                            <div>
                        
                                <div className="row">
                                
                                    {!this.props.add && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                        <td><span onClick={() => this.setState({ editMode: !this.state.editMode })} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                            className="c-light-blue-500 ti-pencil"></i></span>
                                        </td>
                                    }


                                </div>
                                <div className="row">
                                    <div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="breed">Breed *</label>
                                            <BreedSearch action={(item: any) => this.changeData(item)} disabled={!this.props.add && !this.state.editMode} data={this.props.data && this.state.data && this.state.data}
                                                suggestions={this.props.dataState.breeds} />
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </ModalBody>
                        <ModalFooter>

                            <Button color="primary" disabled={!this.state.data || this.state.disableButton} onClick={() => {

                                this.saveChanges(this.state.data);

                            }}>Save</Button>
                            <Button color="secondary" onClick={() => {
                                this.setState({ data: null, previewMode: true, editMode: false })
                                this.props.action();

                            }
                            }>Close</Button>
                        </ModalFooter>

                    </Modal>



                </div>

            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => { return { dialog: state.dialog, dataState: state.competitionList, userState: state.user } },
    DialogStore.actionCreators
)(BISBreedsDialogComponent)
