import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import * as DialogStore from '../store/DialogStore';

export enum FileWarningLevel {
	Light,
	Strict
}

type FilePickerProps = {
	warningLevel: FileWarningLevel;
	onChange: Function;
	accept: string;
}

class FilePicker extends React.Component<FilePickerProps & typeof DialogStore.actionCreators> {

	constructor(props: any) {
		super(props);
	}

	imagePicked = ($event: any) => {
		let input = $event.target as HTMLInputElement;
		if (input.files && input.files.length) {
			const file = input.files[0];

			let typeWarning = '';

			//file size in KB
			let minSize = 50000;
			let maxSize = 50000;

			if (this.props.warningLevel === FileWarningLevel.Strict) {
				//file size in KB
				minSize = 250;
				maxSize = 350;
			}

			if (file.size >= minSize * 1024 && file.size <= maxSize * 1024) {
				this.props.showDialog(`<b>File limitations</b><ul><li>file exceeds ${minSize}KB</li>${typeWarning}</ul>Upload anyway?<br/><br/>`, DialogStore.DialogType.Confirm, (confirm: boolean) => {
					if (confirm === true) {
						this.setState({ val: null });
						return;
					}

					this.loadFile(file);
				});
				return;

			} else if (file.size > maxSize * 1024) {
				this.props.showDialog(`<b>File limitations</b><ul><li>file exceeded maximum size of ${maxSize}KB</li>${typeWarning}</ul>`);
				this.setState({ val: null });
				return;
			}

			this.loadFile(file);
		}
	}

	private loadFile(file: File) {
		let newItem = { id: Date.now().toString(), name: URL.createObjectURL(file), file: file };
		this.props.onChange(newItem);
	}

	render() {
		return (
			<input type="file" className="inputfile" value="" accept={ this.props.accept } onChange={(ev) => { this.imagePicked(ev) }} />
		)
	}

	static defaultProps: FilePickerProps = {
		onChange: () => { },
		warningLevel: FileWarningLevel.Light,
		accept: ''
	}
}

export default connect(
	(state: ApplicationState) => { return { dialog: state.dialog } },
	DialogStore.actionCreators
)(FilePicker)
