import { Action, Reducer } from 'redux';
import { httpService } from '../index';
import { AppThunkAction } from './';
import { Breed, BreedCategory } from '../models/dog/Breed';

export interface BreedListState {
    selectedItem: Breed | null,
    list: Array<Breed>,
    filtered: any;
    breed: Breed | null,
    category: Array<BreedCategory>,
    isLoading: boolean,
    error: string,
}

interface SelectBreedAction {
    type: 'SELECT_BREED';
    item: Breed;
}

interface RequestBreedAction {
    type: 'REQUEST_BREED';
}


interface ReceiveBreedsAction {
    type: 'RECEIVE_BREEDS_LIST';
    items: Array<Breed>;
    filtered: any;
    error: '';
}

interface ReceiveCategoryAction {
    type: 'RECEIVE_CATEGORY';
    items: Array<BreedCategory>;
    error: '';
}

interface ReceiveBreedAction {
    type: 'RECEIVE_BREED';
    item: Breed;
    error: '';
}

interface GetFilteredBreedAction {
    type: 'GET_FILTERED_BREEDS';
    items: any;
}

type KnownAction =
    SelectBreedAction
    | RequestBreedAction
    | ReceiveBreedsAction
    | ReceiveBreedAction
    | ReceiveCategoryAction
    | GetFilteredBreedAction

export const actionCreators = {
    selectItem: (item: Breed): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SELECT_BREED', item: item });
    },

    getItems: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.breedList) {
            try {
                dispatch({ type: 'REQUEST_BREED' });
                let data = await httpService.breeds();
                dispatch({ type: 'RECEIVE_BREEDS_LIST', items: data, filtered: data, error: ''});

            } catch (err) {
                dispatch({ type: 'RECEIVE_BREEDS_LIST', items: [], filtered: [], error: '' });
            }
        }
    },

    getBreed: (id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.breedList) {
            dispatch({ type: 'REQUEST_BREED' });
            let data = await httpService.getBreed(id);

            dispatch({ type: 'RECEIVE_BREED', item: data, error: '' });


        }
    },

    getBreedCategories: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.breedList) {
            let data = await httpService.breedCategory();
            dispatch({ type: 'RECEIVE_CATEGORY', items: data, error: '' });
        }
    },

    getFilteredBreeds: (data: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.breedList) {
            dispatch({ type: 'GET_FILTERED_BREEDS', items: data });
        }
    }


}

const unloadedState: BreedListState = { selectedItem: null, isLoading: false, list: [], error: '', breed: null, category: [], filtered: []};

export const reducer: Reducer<BreedListState> = (state: BreedListState | undefined, incomingAction: Action): BreedListState => {
    if (state === undefined) {
        return unloadedState;
    }

    let stateCopy = { ...state }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_BREED':

            stateCopy.selectedItem = action.item
            stateCopy.isLoading = false,
            stateCopy.error = '',
            stateCopy.breed = action.item

            return stateCopy;

        case 'REQUEST_BREED':

            stateCopy.list = []
            stateCopy.isLoading = true
            stateCopy.error = ''

            return stateCopy;

        case 'RECEIVE_BREEDS_LIST':

            let si = state.selectedItem;

            if (action.items && action.items.length > 0 && !state.selectedItem) {
                si = action.items[0];
            }

            stateCopy.selectedItem = si
            stateCopy.list = action.items
            stateCopy.filtered = action.items

            return stateCopy

        case 'RECEIVE_BREED':
            let selected = state.selectedItem;

            stateCopy.selectedItem = selected
            stateCopy.breed = action.item

            return stateCopy

        case 'RECEIVE_CATEGORY':

            stateCopy.category = action.items

            return stateCopy;

        case 'GET_FILTERED_BREEDS':
            let sitem;
            if (action.items.length > 0) {
                sitem = action.items[0]
            }
            sitem = state.selectedItem

            stateCopy.filtered = action.items
            stateCopy.selectedItem = sitem

            return stateCopy;
    }
    return state;
}
