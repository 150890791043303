import React, { ComponentProps } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as DialogStore from '../store/DialogStore';

type LoaderProps =
    DialogStore.DialogState &
    typeof DialogStore.actionCreators &
    ComponentProps<any>;

class NotificationAlert extends React.PureComponent<LoaderProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {
                    (this.props.notificationData) &&
                    <div style={{ zIndex: 1000000, position: "fixed", marginRight: "auto", marginLeft: "auto", width: "100%", textAlign: "center", pointerEvents: "none" }}>
                        <div style={{ width: "50%", display: "inline-block" }} className={`alert alert-${(this.props.notificationData.type === DialogStore.NotificationType.Success) ? 'success' : 'danger'}`} role="alert">{this.props.notificationData.message}</div>
                    </div>
                }
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.dialog,
    DialogStore.actionCreators
)(NotificationAlert);