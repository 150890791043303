import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';
import { Country } from '../../models/country/Country';
import { history, httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as CountryStore from '../../store/CountryStore';
import * as DialogStore from '../../store/DialogStore';
import { DetailsBase } from '../base/DetailsBase';
import { NotificationData } from '../../store/DialogStore';




class CountryEdit extends DetailsBase<Country> {

    codeError: string;
    propsForTrim: Array<string>;

    constructor(props: any) {
        super(props);

        this.codeError = '';
        this.propsForTrim = ['name', 'isO2', 'isO3', 'phonePrefix']
        this.getItem = async (id: string) => { return await httpService.getCountry(id) }
        this.saveItem = async (data: Country) => { return await httpService.countryAddOrUpdate(data) }
        this.redirectAfterSave = (id: string) => { history.replace(`/country/details/${id}`) }


        this.emptyData = {
            name: '',
            isO2: '',
            isO3: '',
            phonePrefix: '',
            created: new Date()
           
        }


    }

    onSubmit = (ev: any) => {
        ev.preventDefault();

        let data: any = { ...this.state.data };

        this.trimProps(this.propsForTrim, data);

        this.saveChanges(data as Country);
    }



    changeData = (ev: any) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let val = value;

        let data: any = { ...this.state.data };
        data[name] = val;

        this.whitespaceTrimProps(this.propsForTrim, data, name);

        this.setState({
            data: data
        })
    }



    render() {
        return (
            <div>
                <h4 className="c-grey-900">{this.isNew(this.state) && !this.state.isLoading ? "Add" : "Edit"} Country</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error} data={this.state.data}></ContentStatus>
                <br />
                {(this.state && this.state.data) &&
                    <form onSubmit={this.onSubmit}>

                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="name">Name *</label>
                                <input id="name" name="name" required className="form-control"
                                       value={this.state.data.name || ''} onChange={this.changeData} maxLength={2000}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="phonePrefix">Phone Prefix *</label>
                                <input id="phonePrefix" name="phonePrefix" required className="form-control"
                                       value={this.state.data.phonePrefix || ''} onChange={this.changeData}
                                       maxLength={2000}/>
                            </div>


                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="isO2">ISO2 *</label>
                                <input id="isO2" name="isO2" required className="form-control"
                                       value={this.state.data.isO2 || ''} onChange={this.changeData} maxLength={2000}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="isO3">ISO3 *</label>
                                <input id="isO3" name="isO3" required className="form-control"
                                       value={this.state.data.isO3 || ''} onChange={this.changeData} maxLength={2000}/>
                            </div>
                     
                        </div>


                        <br />
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { countryState: state.countryList };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: Country) => { dispatch(CountryStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(CountryStore.actionCreators.getItems()) },
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CountryEdit);
