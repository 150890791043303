import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { CompetitionAttachmentType, getCompetitionAttachmentType } from '../../models/competition/CompetitionEnums';
import { Helpers } from '../../utility/Helpers';
import { CompetitionAttachment } from '../../models/competition/Competition';
import { httpService } from '../../index';

export enum FileWarningLevel {
	Light,
	Strict
}

type CompAttachmentListProps = {
	warningLevel: FileWarningLevel;
	attachments?: Array<CompetitionAttachment>;
	onChange: Function;
	canEdit?: boolean;
	parentId: string;
}

class CompAttachmentList extends React.Component<CompAttachmentListProps & typeof DialogStore.actionCreators & any> {

	constructor(props: any) {
		super(props);
	}

	getAttachmentsByType(list: Array<CompetitionAttachment> | undefined, type: CompetitionAttachmentType) {
		return (list) ? list.filter(i => i.type === type) : [];
	}

	getAttachmentsTypes() {
		return [
			CompetitionAttachmentType.OTHER,
			CompetitionAttachmentType.CATALOG,
			CompetitionAttachmentType.EVALUATION_RINGS,
			CompetitionAttachmentType.JUDGE_LIST,
			CompetitionAttachmentType.REGULATIONS,
			CompetitionAttachmentType.BISJUDGE_LIST,
			CompetitionAttachmentType.SPECIAL_SHOWS,
			CompetitionAttachmentType.PROGRAM
		]
	}

	removeFile(item: CompetitionAttachment) {
		this.props.showDialog(`Are you sure you want to delete attachment?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
			if (confirm && item.id) {
				this.props.increment();
				try {
					await httpService.competitionAttachmentDelete(item.id);
					this.props.onChange(item, true);
					this.props.decrement();
				} catch (err) {
					this.props.decrement();
					alert(err.message);
                }
			}
		})
	}

	openEditor(data: CompetitionAttachment) {
		this.props.showAttachmentDialog(data, async (att: CompetitionAttachment) => {

			if (!att.name && !att.file) {
				alert('No file selected.');
				return;
			}

			this.props.increment();
			try {
				let res = await httpService.competitionAttachmentAddOrUpdate(att);

				if (att.file) {
					await httpService.competitionFileUpload(att.file, res.id);
					res = await httpService.competitionAttachment(res.id);
                }

				this.props.decrement();
				this.props.onChange(res, false);
				this.props.hideAttachmentDialog();

			} catch (err) {
				this.props.decrement();
				alert(err.message);
			}
		})
    }

	render() {
		return (
			<div className="picture-picker">
				{this.getAttachmentsTypes().map((type, typeIndex) =>
					<div key={typeIndex}>{getCompetitionAttachmentType(type)}


						{this.props.canEdit &&
							<span>
								{!Helpers.isUserEventViewer(this.props.userState.user) &&
									<label className="cur-p mL-10">

										<a href="#" onClick={(e) => {
											e.preventDefault()
											this.openEditor({ competitionId: this.props.parentId, title: '', displayOnWeb: false, name: '', type: type, created: '' });
										}}>
											<div className="bgc-blue-50 c-blue-500 circle-add">+</div>
										</a>
									</label>
								}

								< br /> <br />
							</span>
						}

						{this.getAttachmentsByType(this.props.attachments, type).map((attach: CompetitionAttachment, index: number) =>
							<div key={attach.id}>
								{!Helpers.isUserEventViewer(this.props.userState.user) &&
									<div>

										<a style={{ float: "right" }} href="#" onClick={(e) => {
											e.preventDefault()
											this.removeFile(attach);
										}}>
											<span style={{ fontSize: "16px" }} className="icon-holder">
												<i className="c-light-blue-500 ti-trash"></i>
											</span>
										</a>
										<span style={{ float: "right", marginLeft: "8px", marginRight: "8px" }}>|</span>
										<label style={{ float: "right" }} onClick={() => { this.openEditor(attach) }} className="cur-p">
											<span className="icon-holder" style={{ fontSize: "16px" }}><i className="c-light-blue-500 ti-pencil"></i></span>
										</label>

									</div>
								}
								<div>Created: {Helpers.getLocalDateTimeString(new Date(attach.created))}</div>
								<div>Display on web: {attach.displayOnWeb.toString()}</div>
								<div style={{ position: 'relative', display: 'inline-block' }}>
									<div>
										<a href={attach.name} target="_blank">
											{Helpers.isImagePath((attach.file) ? attach.file.name : attach.name) && <img key={index}
												alt="attachment"
												src={attach.name}
												style={{
													maxHeight: "400px",
													maxWidth: "300px"
												}} />}

											{Helpers.isImagePath((attach.file) ? attach.file.name : attach.name) === false && <span>
												{Helpers.getAttachmentName((attach.file) ? attach.file.name : attach.name)}
											</span>}
										</a>
									</div>
								</div>
								<hr />
							</div>
						)}

						{this.getAttachmentsByType(this.props.attachments, type).length === 0 && <span>No "{getCompetitionAttachmentType(type)}" attachments.<br /><br /></span>}

					</div>
				)}
			</div>
		)
	}

	static defaultProps: CompAttachmentListProps = {
		canEdit: false,
		onChange: () => { },
		warningLevel: FileWarningLevel.Light,
		attachments: [],
		parentId: ''
	}
}

export default connect(
	(state: ApplicationState) => { return { dialog: state.dialog, userState: state.user } },
	DialogStore.actionCreators
)(CompAttachmentList)
