import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import * as DialogStore from '../../store/DialogStore';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import { Button, DropdownItem, Nav, NavItem, NavLink } from "reactstrap";
import { Helpers } from "../../utility/Helpers";
import { ApplicationState } from "../../store";
import classnames from "classnames";
import { httpService } from "../../index";
import { CompetitionDate } from "../../models/competition/Competition";
import SpecialCompList from "../../components/SpecialCompList";
import { CompDateImage, SpecialComp } from "../../models/competition/CompetitionDate";
import CompDateActions from "../../components/competition/CompDateActions"
import CompDatePriceLists from "../../components/competition/CompDatePriceLists"
import ContentStatus from "../../components/ContentStatus";
import RingsList from "../../components/competition/RingsList";
import SpecialBreedDialog from "../../components/competition/SpecialBreedDialog";
import { StartNumberGenerationType } from "../../models/registrations/RegistrationEnums";
import CompetitionDateImageList from "../../components/competition/CompetitionDateImageList";



const REGISTRATIONS = 'registrations';
const RESULTS = 'results';
const BIS_RESULTS = 'bis-results';
const CATALOG_NUMBERS = 'catalog-numbers';
const JUNIOR_HANDLING = 'start-numbers';
const COUPLE_GROUP_NUMBERS = 'couple-group-start-numbers';
const QR_CODES = 'qr-codes';
const BIS_QR_CODES = 'bis-qr-codes';
const CATALOG = 'catalog';
const SCHEDULE = 'schedule';
const JUDGE_REPORT = 'judge-report';
const EVAL_SHEETS = 'evaluated-sheets';
const CATALOG_EMAIL = 'send-catalog-day';
const EVALUATIONS = 'send-evaluations-after-day';

type PreviewProps = {
    data: any;
    details?: any;
    index?: any;
    open?: any;
    onCompDateChange: Function;
}

/* TODO:: Make this + PreviewPopUp.tsx generic */

class CompetitionDatePreviewPopUpComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }
    state = {
        data: this.props.data,
        breeds: [],
        dropdownOpen: false,
        processing: false,
        loading: "",
        check: false,
        subTab: 0,
        price: false,
        special: false,
        opened: false,
        id: '',

    }

    componentDidMount() {
        super.componentDidMount;
        this.props.getCompetitionDate(this.props.data.id);
        this.fetchImages();
    }

    getSpecial = (id: string) => {
        this.props.getSpecialComp(id)
        this.setState({
            special: !this.state.special
        });
    }

    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }


    async processClick(type: any) {

        let action: any;

        switch (type) {
            case REGISTRATIONS: {
                action = async () => { return await httpService.exportRegistrations(this.props.data.id) }
                break;
            }
            case RESULTS: {
                action = async () => { return await httpService.exportResults(this.props.data.id) }
                break;
            }
            case BIS_RESULTS: {
                action = async () => { return await httpService.exportBISResults(this.props.data.id) }
                break;
            }
            case QR_CODES: {
                action = async () => { return await httpService.generateQrCodes(this.props.data.id) }
                break;
            }
            case CATALOG_NUMBERS: {
                action = async () => { return await httpService.generateCatalogNumbers(this.props.data.id) }
                break;
            }
            case JUNIOR_HANDLING: {
                action = async () => { return await httpService.generateAllJuniorHandlingStartNumbers(this.props.data.id, StartNumberGenerationType.JUNIOR_HANDLING) }
                break;
            }
            case COUPLE_GROUP_NUMBERS: {
                action = async () => { return await httpService.generateAllJuniorHandlingStartNumbers(this.props.data.id, StartNumberGenerationType.COUPLE_GROUP) }
                break;
            }
            case CATALOG: {
                action = async () => { return await httpService.generateCatalog(this.props.data.id) }
                break;
            }
            case SCHEDULE: {
                action = async () => { return await httpService.generateSchedule(this.props.data.id) }
                break;
            }
            case JUDGE_REPORT: {
                action = async () => { return await httpService.generateJudgeReport(this.props.data.id) }
                break;
            }
            case EVAL_SHEETS: {
                action = async () => { return await httpService.generateEvaluatedSheets(this.props.data.id) }
                break;
            }
            case CATALOG_EMAIL: {
                action = async () => { return await httpService.sendCatalog(this.props.data.id) }
                break;
            }
            case EVALUATIONS: {
                action = async () => { return await httpService.sendEvaluations(this.props.data.id) }
                break;
            }
            case BIS_QR_CODES: {
                action = async () => { return await httpService.bisQrCodes(this.props.dataState.competitionDate.competitionDateBIS && this.props.dataState.competitionDate.competitionDateBIS.id) }
                break;
            }
        }

        try {
            this.setState({ loading: type });
            if (type === "bis-qr-codes" && !this.props.dataState.competitionDate.competitionDateBIS) {
                alert("Cannot generate QR codes. BIS is missing.")
                this.setState({ loading: '' });
                return
            }
            let res = await action();
            if (res && !res.hasOwnProperty("errorCode")) {
                this.setState({ loading: '', data: res });
                this.props.onCompDateChange(res);
            } else {
                if (res) {
                    alert(res.message);
                    this.setState({ loading: '' });
                }
                this.props.onCompDateChange(this.state.data);
                this.setState({ loading: '' });
            }

        } catch (err) {
            this.setState({ loading: '' });
            alert(err.message);
        }


    }

    openCompDateEditor = async (data: CompetitionDate, detailsOnly: boolean) => {

        let breeds = await httpService.webBreeds()
        this.setState({ breeds: breeds })

        this.props.showCompDateDialog(data, detailsOnly, async (cd: CompetitionDate) => {

            this.props.increment();
            try {
                let res = await httpService.competitionDateAddOrUpdate(cd);
                this.props.decrement();
                this.props.onCompDateChange(res);
                this.handleChanges()
                this.props.hideCompDateDialog();

            } catch (err) {
                this.props.decrement();
                alert(err.message);
            }
        })
    }

    handleChanges = () => {
        this.props.getCompetitionDate(this.props.data.id)

    }

    async addRemoveImage(item: CompDateImage, remove: boolean) {
        if (remove) {
            let state: any = { ...this.state };
            let ind = state.data.images.findIndex((i: CompDateImage) => i.id === item.id);
            if (ind !== -1) {
                state.data.images.splice(ind, 1);
                this.setState({ data: state.data });
            }
        } else if (!remove) {
            let state: any = { ...this.state };
            // let data: any = {...this.state.data}
            let res = await httpService.getCompetitionDateImage(item.image);
            let ind = state.data.images.findIndex((i: CompDateImage) => i.id === item.id);
            if (ind !== -1) {
                item.image = res
                state.data.images[ind] = (item)
            } else {
                item.image = res
                state.data.images.push(item)
            }
            this.setState({ data: state.data });
        }
    }

    async fetchImages() {

        let data: any = { ...this.state.data }
        for (let i = 0; i < data.images.length; i++) {
            let res = await httpService.getCompetitionDateImage(data.images[i].image);
            data.images[i].image = res
        }
        this.setState({ data: data })

    }


    render() {
        return (
            <React.Fragment>

                <div>

                    {!this.props.data &&
                        <ContentStatus isLoading={true}></ContentStatus>
                    }
                    {this.props.data &&
                        <div>
                            <Button color="link" style={{ border: "none", float: "right" }} onClick={() => {
                                this.props.closePreview()
                                this.setState({ selectedTab: 2 })
                                this.props.setPage(0)
                                this.props.setName("")
                            }}>Back to list</Button>

                            <Nav style={{ marginBottom: "20px", cursor: "pointer" }}>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.subTab === 0 })}
                                        onClick={() => {

                                            this.setState({ subTab: 0, price: false, status: false, special: false })
                                        }}>
                                        Details
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.subTab === 1 })}
                                        onClick={() => {
                                    this.props.getCompetitionDate(this.props.data.id);
                                    this.setState({ subTab: 1, price: false, status: false, special: false })
                                }}
                            >
                                Rings & BIS
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.subTab === 2 })}
                                        onClick={() => {
                                    this.setState({ subTab: 2, price: false, status: false, special: false })
                                }}
                            >
                                Special Shows
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.subTab === 3 })}
                                        onClick={() => {
                                    this.setState({ subTab: 3, price: false, status: false, special: false })
                                }}
                            >
                                Price Lists
                            </NavLink>
                        </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.subTab === 5 })}
                                        onClick={() => {
                                        this.setState({ subTab: 5, price: false, status: false, special: false, data: this.props.data })
                                    }}
                                >
                                    Images
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.subTab === 4 })}
                                        onClick={() => {
                                        this.setState({ subTab: 4, price: false, status: false, special: false, data: this.props.data })
                                    }}
                                >
                                    Actions
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    }
                    {this.state.subTab !== 0 && this.state.subTab !== 3 && this.state.subTab !== 4 && this.state.subTab !== 5 &&
                        <h5>{this.props.data.description} - {Helpers.getLocalDateString(this.props.data.date)}</h5>
                    }
                    {(this.state.subTab === 3 || this.state.subTab === 4) &&
                        <div>
                            <h5>{this.props.data.description} - {Helpers.getLocalDateString(this.props.data.date)}</h5>
                            <br />
                        </div>
                    }

                    {this.state.subTab === 5 &&
                        <div>
                        <h5>{this.props.data.description} - {Helpers.getLocalDateString(this.props.data.date)}(used for start numbers, appreciations, certificates)</h5>
                            <br />
                        </div>
                    }
                    {this.props.data && this.state.subTab === 0 &&
                        <div style={{ color: "grey" }}>

                            <h5>Details
                                {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                    <span
                                        onClick={async () => {
                                            this.openCompDateEditor(this.props.data, true)

                                        }}
                                        className="icon-holder cur-p"
                                        style={{ fontSize: "16px", paddingLeft: "10px" }}>
                                        <i className="c-light-blue-500 ti-pencil"></i>
                                    </span>
                                }
                            </h5>
                            <div className="row">

                                <div className="mb-1 col-md-4">Created:</div>
                                <div className="mb-1 col-md-8"> {Helpers.getLocalDateTimeString(this.props.data.created)}</div>

                            </div>
                            <div className="row">

                                <div className="mb-1 col-md-4">Date:</div>
                                <div className="mb-1 col-md-8"> {Helpers.getLocalDateTimeString(this.props.data.date)}</div>

                            </div>
                            <div className="row">

                                <div className="mb-1 col-md-4">Description:</div>
                                <div className="mb-1 col-md-8"> {this.props.data.description}</div>

                            </div>
                            <div className="row">

                                <div className="mb-1 col-md-4">Can Download Cards<br />(Used on web):</div>
                                <div className="mb-1 col-md-8"> {Helpers.booleanToString(this.props.data.canDownloadCards)}</div>

                            </div>
                            <div className="row">

                                <div className="mb-1 col-md-4">Can Download Evaluations<br />(Used on web):</div>
                                <div className="mb-1 col-md-8"> {Helpers.booleanToString(this.props.data.canDownloadEvaluations)}</div>

                            </div>
                            <div className="row">

                                <div className="mb-1 col-md-4">Is CACIB Awarded<br />(Used in mobile app):</div>
                                <div className="mb-1 col-md-8">  {Helpers.booleanToString(this.props.data.isCACIBAwarded)}</div>

                            </div>

                            <div className="row">

                                <div className="mb-1 col-md-4">Catalog Numbers Generated:</div>
                                <div className="mb-1 col-md-8">  {Helpers.booleanToString(this.props.data.catalogNumbersGenerated)}</div>

                            </div>
                            <br />
                            <div className="row">

                                <div className="mb-1 col-md-4">Registrations must have Pedigrees:</div>
                                <div className="mb-1 col-md-8">  {Helpers.booleanToString(this.props.data.mustHavePedigrees)}</div>

                            </div>

                            <div className="row">

                                <div className="mb-1 col-md-4">Has Junior Handling registrations:</div>
                                <div className="mb-1 col-md-8">  {Helpers.booleanToString(this.props.data.hasJuniorHandlingRegistrations)}</div>

                            </div>

                            <div className="row">

                                <div className="mb-1 col-md-4">Has Couple And Group Registrations:</div>
                                <div className="mb-1 col-md-8">  {Helpers.booleanToString(this.props.data.hasCoupleAndGroupRegistrations)}</div>

                            </div>

                            <br />

                            <SpecialBreedDialog data={this.props.data} actions={false} />
                        </div>

                    }


                    {this.state.subTab === 4 ?

                        (<div>
                            <div>
                                <CompDateActions data={this.props.data} onChange={this.props.onCompDateChange} />
                            </div>
                            <hr />
                            <h5>Exports</h5>
                            <div style={{ display: "inline-grid", gap: "15px", width: "200px" }}>
                                <Button id="registrations" color="info"
                                    style={{ color: "white" }}
                                    onClick={() => {
                                        this.processClick("registrations")
                                    }}
                                    disabled={this.state.loading === "registrations" && true}
                                >Registrations

                                    {this.state.loading === "registrations" &&
                                        <i className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", marginLeft: "10px" }} />
                                    }
                                </Button>
                                <Button id="results" color="info"
                                    style={{ color: "white" }}
                                    onClick={() => {
                                        this.processClick("results")
                                    }}
                                    disabled={this.state.loading === "results" && true}
                                >Results

                                    {this.state.loading === "results" &&
                                        <i className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", marginLeft: "10px" }} />
                                    }
                                </Button>
                                <Button id="bis-results" color="info"
                                    style={{ color: "white" }}
                                    onClick={() => {
                                        this.processClick("bis-results")
                                    }}
                                    disabled={this.state.loading === "bis-results" && true}
                                >BIS Results

                                    {this.state.loading === "bis-results" &&
                                        <i className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", marginLeft: "10px" }} />
                                    }
                                </Button>
                            </div>
                            <br />
                            <br />
                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                <div>
                                    <h5>Show related</h5>

                                    <div style={{ display: "inline-grid", gap: "15px", width: "330px" }}>
                                        <div>{this.state.data && "Last generated catalog number: " + Helpers.getLocalDateTimeString(this.state.data.catalogNumbersGeneratedTimestamp)}</div>
                                        <Button id="catalog-numbers" color="info"
                                            style={{ color: "white" }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.processClick("catalog-numbers")
                                            }}
                                            disabled={this.state.loading === "catalog-numbers" && true}
                                        >Generate catalog numbers

                                            {this.state.loading === "catalog-numbers" &&
                                                <i className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                            }
                                        </Button>
                                        <div style={{ width: "350px" }}>{this.state.data && this.state.data.jhNumbersGenerated && "Last generated start numbers: " + Helpers.getLocalDateTimeString(this.state.data.jhNumbersGeneratedTimestamp)}</div>
                                        <Button id="jh-catalog-numbers" color="info"
                                            style={{ color: "white" }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.processClick("start-numbers")
                                            }}
                                            disabled={this.state.loading === "start-numbers" && true} 
                                            >Generate Junior Handling Start Numbers

                                            {this.state.loading === "start-numbers" &&
                                                <i className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                            }
                                        </Button>
                                        <Button id="couple-group-catalog-numbers" color="info"
                                            style={{ color: "white" }}
                                            onClick={(e) => {
                                                e.preventDefault()  
                                                this.processClick("couple-group-start-numbers")
                                            }}
                                            disabled={this.state.loading === "couple-group-start-numbers" && true}
                                            >Generate Couple/Group Start Numbers
                                            {this.state.loading === "couple-group-start-numbers" &&
                                                <i className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                            }
                                        </Button>
                                        <Button id="qr-codes" color="info"
                                            style={{ color: "white" }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.processClick("qr-codes")
                                            }}
                                            disabled={this.state.loading === "qr-codes" && true}
                                        >Generate QR Codes for judges

                                            {this.state.loading === "qr-codes" &&
                                                <i className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                            }
                                        </Button>
                                        <Button id="qr-codes" color="info"
                                            style={{ color: "white" }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.processClick("bis-qr-codes")
                                            }}
                                            disabled={this.state.loading === "bis-qr-codes" && true}
                                        >Generate QR Codes for BIS

                                            {this.state.loading === "bis-qr-codes" &&
                                                <i className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                            }
                                        </Button>
                                        <Button id="catalog" color="info"
                                            style={{ color: "white" }}
                                            onClick={() => {
                                                this.processClick("catalog")
                                            }}
                                            disabled={this.state.loading === "catalog" && true}
                                        >Generate catalog

                                            {this.state.loading === "catalog" &&
                                                <i className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                            }
                                        </Button>

                                        <Button id="schedule" color="info"
                                            style={{ color: "white" }}
                                            onClick={() => {
                                                this.processClick("schedule")
                                            }}
                                            disabled={this.state.loading === "schedule" && true}
                                        >Generate schedule

                                            {this.state.loading === "schedule" &&
                                                <i className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                            }
                                        </Button>

                                        <Button id="judge-report" color="info"
                                            style={{ color: "white" }}
                                            onClick={() => {
                                                this.processClick("judge-report")
                                            }}
                                            disabled={this.state.loading === "judge-report" && true}
                                        >Generate judge report

                                            {this.state.loading === "judge-report" &&
                                                <i className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                            }
                                        </Button>

                                        <Button id="evaluated-sheets" color="info"
                                            style={{ color: "white" }}
                                            onClick={() => {
                                                this.processClick("evaluated-sheets")
                                            }}
                                            disabled={this.state.loading === "evaluated-sheets" && true}
                                        >Generate Evaluation sheets

                                            {this.state.loading === "evaluated-sheets" &&
                                                <i className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                            }
                                        </Button>
                                    </div>

                                    <br />
                                    <br />

                                    <h5>Emails</h5>


                                    <div style={{ gap: "15px" }}>
                                        <div>{this.state.data && this.state.data.catalogSent && "Catalog sent: " + Helpers.getLocalDateTimeString(this.state.data.catalogSent)}</div>

                                        <Button id="send-catalog-day" color="info"
                                            style={{ color: "white", width: "200px" }}

                                            onClick={() => {
                                                this.props.showDialog(`Are you sure to send catalog e-mails?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                                                    if (confirm) {
                                                        this.processClick("send-catalog-day")
                                                    }
                                                })
                                            }
                                            }
                                            disabled={(this.state.data && this.state.data.catalogSent) || this.state.loading === "send-catalog-day" && true}
                                        >Send Catalog

                                            {this.state.loading === "send-catalog-day" &&
                                                <i className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                            }
                                        </Button>
                                        <br /><br />
                                        <div>{this.state.data && this.state.data.evaluationsSent && "Evaluations sent: " + Helpers.getLocalDateTimeString(this.state.data.evaluationsSent)}</div>
                                        <Button id="send-evaluations-after-day" color="info"
                                            style={{ color: "white", width: "200px" }}
                                            onClick={() => {
                                                this.props.showDialog(`Are you sure to send evaluations e-mails?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                                                    if (confirm) {
                                                        this.processClick("send-evaluations-after-day")
                                                    }
                                                })
                                            }}
                                            disabled={(this.state.data && this.state.data.evaluationsSent) || this.state.loading === "send-evaluations-after-day" && true}
                                        >Send Evaluations

                                            {this.state.loading === "send-evaluations-after-day" &&
                                                <i className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                            }
                                        </Button>
                                    </div>
                                </div>
                            }
                            <br />
                        </div>
                        ) : this.state.subTab === 4 && this.props.dataState.competitionRings && this.props.dataState.competitionRings.length < 1 && (
                            <div style={{ display: "flex", flexDirection: "column" }}>No files to download.</div>)
                    }

                    {this.state.subTab === 1 &&
                        <RingsList rings={this.props.dataState.competitionDate.competitionRings || []} parentId={this.props.data.id} />
                    }

                    {/*price lists tab*/}
                    {this.state.subTab === 3 &&
                        <CompDatePriceLists data={this.props.dataState.competitionDate} compDateId={this.props.dataState.competitionDate ? this.props.dataState.competitionDate.id : this.props.data.id} openCompDateEditor={this.openCompDateEditor} onChange={() => { this.handleChanges() }} ></CompDatePriceLists>
                    }
                    {this.state.subTab === 2 && !this.state.special &&
                        <div>
                            <SpecialCompList
                                specialComps={this.props.dataState.competitionDate.specialCompetitions}
                                parentId={this.props.data.id}
                            />

                        </div>

                    }

                    {this.state.subTab === 5 &&
                        <CompetitionDateImageList
                            onChange={(item: CompDateImage, remove: boolean) => {
                                this.handleChanges();
                                this.addRemoveImage(item, remove)
                            }}
                            images={this.state.data.images}
                            parentId={this.state.data.id} />
                    }

                </div>

            </React.Fragment>
        )
            ;
    }

}


const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.competitionList, userState: state.user };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setPage: (page?: any) => {
            dispatch(CompetitionListStore.actionCreators.setPage(page))
        },
        setName: (name?: any) => {
            dispatch(CompetitionListStore.actionCreators.setName(name))
        },
        getCompetitionDate: (id: any) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
        },
        getSpecialComp: (id: any) => {
            dispatch(CompetitionListStore.actionCreators.getSpecialComp(id))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showCompDateDialog: (d: CompetitionDate, detailsOnly: boolean, cal: Function) => {
            dispatch(DialogStore.actionCreators.showCompDateDialog(d, detailsOnly, cal))
        },
        showSpecialCompDialog: (d: SpecialComp, detailsOnly: boolean, cal: Function) => {
            dispatch(DialogStore.actionCreators.showSpecialCompDialog(d, detailsOnly, cal))
        },
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        hideCompDateDialog: () => {
            dispatch(DialogStore.actionCreators.hideCompDateDialog())
        },
        hideSpecialCompDialog: () => {
            dispatch(DialogStore.actionCreators.hideSpecialCompDialog())
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompetitionDatePreviewPopUpComponent);
