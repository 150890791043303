import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import * as DialogStore from '../../store/DialogStore';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ApplicationState } from "../../store";
import { httpService } from "../../index";
import * as DogListStore from '../../store/DogListStore';
import { DialogType, NotificationData, NotificationType } from "../../store/DialogStore";
import RegistrationLoader from "../RegistrationLoader";
import { DogHistory } from "../../models/dog/Dog";
import { Link } from "react-router-dom";
import { Helpers } from "../../utility/Helpers";
import ProfileSearch from "../ProfileSearch";
import { UserType } from "../../models/registered-user/RegisteredUserEnums";


export interface Props {
    dataId: string,
    open: boolean,
    type: string
}


class EventOwnersComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }


    state: any = {
        isOpen: false,
        modal: true,
        loading: "",
        isDataLoading: true,
        data: null,
        owner: null,
        profileSuggestions: [],
        disableButton: false
        
    };

    emptyOwner: any = {
        competitionId: this.props.dataId,
        profileId: ''
    }



    toggle = () => {
        this.setState({
            modal: !this.state.modal

        });
    }


    async openModal(type: string) {

        
        try {
            this.setState({ loading: type });

            let res = await httpService.getEventOwners(this.props.dataId);

            this.setState({
                data: res, isOpen: true
            })
        } catch (err) {
            this.setState({ loading: '' });
            alert(err.message);
        }
    }

    openOwnerModal = async () => {
        let profiles = await httpService.getRegisteredUsers({page: null, username: '', count: 50, usertype: UserType.EVENT_VIEWER})
        this.setState({ profileSuggestions: profiles, owner: this.emptyOwner })
    }

    saveEventOwner(data: any) {
        if (data) {
            this.props.showDialog(`Are you sure you want to save this event owner?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    try {
                        this.setState({ disableButton: true })
                        await httpService.eventOwnersAdd(data)
                        let newData = await httpService.getEventOwners(this.props.dataId)

                        this.setState({ owner: null, data: newData, disableButton: false })
                    } catch (err) {
                        alert(err.message)
                        this.setState({ disableButton: false })
                    }

                }
            })
        }
    }

    closeModal() {

        this.setState({
            isOpen: false,
            data: null
        })

    }
    closeOwnerModal() {

        this.setState({
            owner: null,
        })

    }

    addEventOwner() {
        this.setState({ owner: {competitionId: this.props.dataId} })
    }

    removeEventOwner = async (id: string) => {
        if (id) {
            this.props.showDialog(`Are you sure you want to delete this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    let data: any = { profileId: id, competitionId: this.props.dataId }
                    try {
                        await httpService.eventOwnerDelete(data)
                        let newData = await httpService.getEventOwners(this.props.dataId)

                        this.setState({ owner: null, data: newData })
                    } catch (err) {
                        alert(err.message)

                    }

                }
            })

        }
    }

    changeData(item: any) {
        let data: any = { ...this.state.owner }
        let owner = this.state.profileSuggestions.find((i: any) => i.userName === item)
        if (owner) {
            data.profileId = owner.id
            this.setState({ owner: data })
        }
        

    }
       

    render() {
        return (
            <React.Fragment>
                <Button style={{ color: "white" }} color="info"
                    onClick={() => {
                        this.openModal(this.props.type)
                    }
                    }>Show Event Owners</Button>

                <br />

                <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.state.isOpen}>
                    <ModalHeader toggle={() => this.closeModal()}>Event Owners</ModalHeader>
                    <ModalBody>

                        {!this.state.data ? <RegistrationLoader /> :

                            <div>
                                <div style={{ float: "right" }}>
                                    <label className="cur-p mL-10">
                                        <a href="#" onClick={(e: any) => {
                                            e.preventDefault()
                                            this.openOwnerModal()
                                        }}>
                                            <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                        </a>
                                    </label>
                                    <br /><br />
                                </div>
                                <table className="table table">
                                    <thead>
                                        <tr>
                                            
                                            <th>Profile</th>

                                            <th style={{ textAlign: "right" }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="dd-container">
                                        {this.state.data.length > 0 && this.state.data.map((item: any, index: any) =>

                                            <tr key={item.id}>
                                               
                                                <td style={{ color: "grey" }}>{item.username}</td>
                                                
                                                <td className="ta-r">
                                                    
                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.removeEventOwner(item.id) }}>
                                                        <span style={{ fontSize: "16px" }} className="icon-holder"><i className="c-light-blue-500 ti-trash"></i></span>
                                                    </a>
                                                </td>
                                            </tr>

                                        )}
                                    </tbody>

                                </table>
                                {this.state.data && this.state.data.length < 1 &&
                                    <div style={{ color: "grey", display: "flex", justifyContent: "center" }} className="cur-p">No event owners.</div>
                                }


                                {this.state.owner &&

                                    <Modal id="coupon-code" size="md" isOpen={this.state.owner}>
                                        <ModalHeader toggle={() => { this.closeOwnerModal() }}>Add Event Owner</ModalHeader>
                                        <ModalBody>

                                            <div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="profile">Profile</label>
                                                    <ProfileSearch suggestions={this.state.profileSuggestions} userType={UserType.EVENT_VIEWER} action={(e: any) => this.changeData(e)} />
                                                </div>

                                            </div>


                                        </ModalBody>
                                        <ModalFooter>

                                            <Button disabled={this.state.disableButton} color="primary" onClick={() => { this.saveEventOwner(this.state.owner); }}>Save</Button>
                                            <Button color="secondary" onClick={() => { this.closeOwnerModal(); }}>Close</Button>
                                        </ModalFooter>


                                    </Modal>
                                }

                            </div>
                        }

                    </ModalBody>
                    <ModalFooter>

                        <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                    </ModalFooter>

                </Modal>


            </React.Fragment>
        );

    }

}


const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.competitionList };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
};




export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventOwnersComponent);
