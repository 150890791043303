import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import {connect} from 'react-redux';
import * as CompetitionClassStore from "../../store/CompetitionClassStore";
import * as DialogStore from '../../store/DialogStore';
import {httpService} from '../../index';
import {ApplicationState} from '../../store';
import {DetailsBase} from '../base/DetailsBase';
import DetailsHeader from '../../components/DetailsHeader';
import {Helpers} from '../../utility/Helpers';
import {CompetitionClass} from "../../models/registrations/Registrations";
import {Link} from "react-router-dom";


class CompetitionClassDetails extends DetailsBase<CompetitionClass> {

    constructor(props: any) {
        super(props);
        this.getItem = async (id: string) => {
            let res = await httpService.getCompetitionClass(id);
            return res;
        }

    }

    render() {
        return (
            <div>
                <DetailsHeader baseUrl="competition-class" data={this.state.data}
                               title={this.state.data ? this.state.data.name : "Competition Class Preview"}
                               onDelete={() => {
                                   this.deleteItem()
                               }}/>

                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                               data={this.state.data}></ContentStatus>
                {(this.state && this.props.dataState && this.state.data) &&
                    <div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Created:</div>
                            <div className="mb-1 col-md-9"> {Helpers.getLocalDateTimeString(this.state.data.created)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Name:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.name}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Description:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.description}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">English name:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.engName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">English description:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.englishDescription}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">German name:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.germanName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">German description:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.germanDescription}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Croatian name:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.croatianName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Croatian description:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.croatianDescription}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Maximum Age in Months:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.maxAgeInMonths}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Minimum Age in Months:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.minAgeInMonths}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Catalog order:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.order.toString()}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Requires Championship:</div>
                            <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.state.data.requiresChampionship)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Requires Pedigree:</div>
                            <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.state.data.requiresPedigree)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Requires Work:</div>
                            <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.state.data.requiresWork)}</div>

                        </div>
                        
                       
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {dataState: state.competitionClassList};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: CompetitionClass) => {
            dispatch(CompetitionClassStore.actionCreators.selectItem(data))
        },
        getItems: () => {
            dispatch(CompetitionClassStore.actionCreators.getItems())
        },
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        showNotification: (data: DialogStore.NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompetitionClassDetails);
