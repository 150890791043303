import * as React from 'react';
import { Route, Router, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Layout from './components/Layout';
import Home from './components/Home';
import Login from './views/Login';
import { ApplicationState } from './store';

import './custom.scss';
import './tv_custom.scss';
import FullLoader from './components/FullLoader/FullLoader';
import CompetitionList from './views/competition/CompetitionList';
import DogList from './views/dog/DogList';
import CountryList from './views/country/CountryList';
import RegisteredUserList from './views/registered-user/RegisteredUserList';
import Dialog from './components/Dialog';
import AttachmentDialog from './components/competition/AttachmentDialog';
import SponsorDialog from './components/competition/SponsorDialog';
import CompDateImageDialog from './components/competition/CompetitionDateImage';
import CompDateDialog from './components/competition/CompDateDialog';
import NotificationAlert from './components/NotificationAlert';
import BreedList from './views/breed/BreedList';
import BreedCategoryList from './views/breed-category/BreedCategoryList';
import PedigreeList from './views/pedigree-abbreviation/PedigreeAbbreviationList';
import CompetitionClassList from "./views/competition-class/CompetitionClassList";
import InvoiceInfoList from './views/invoice-information/InvoiceInfoList';
import InvoiceIssuerList from './views/invoice-issuer/InvoiceIssuerList';
import NotificationList from './views/notification/NotificationList';
import TVFront from './components/tv-screens/TVFront';
import ShowSchedule from './components/tv-screens/ShowSchedule';
import BISSchedule from './components/tv-screens/BISSchedule';
import ShowResults from './components/tv-screens/ShowResults';
import StripeAccountList from './views/stripe-account/StripeAccountList';


export default () => {

    const user = useSelector((state: ApplicationState) => (state && state.user) ? state.user.user : null);
    const location = useLocation();


    return (
        <>
            <AttachmentDialog />
            <SponsorDialog />
            <CompDateImageDialog />
            <CompDateDialog />
            <FullLoader />
            <Dialog />
            <NotificationAlert />


            {user === null && location.pathname !== "/tv" &&
                <Route exact path='/' component={Login} />
            }

            {user !== null && location.pathname !== "/tv" &&
                <Layout>
                    <Route exact path='/' component={Home} />
                    <Route path='/event' component={CompetitionList} />
                    <Route path='/dog' component={DogList} />
                    <Route path='/country' component={CountryList} />
                    <Route path='/stripeaccount' component={StripeAccountList} />
                    <Route path='/registered-user' component={RegisteredUserList} />
                    <Route path='/breed' component={BreedList} />
                    <Route path='/fci-category' component={BreedCategoryList} />
                    <Route path='/pedigree-abbreviation' component={PedigreeList} />
                    <Route path='/competition-class' component={CompetitionClassList} />
                    <Route path='/invoice-information' component={InvoiceInfoList} />
                    <Route path='/invoice-issuer' component={InvoiceIssuerList} />
                    <Route path='/notification' component={NotificationList} />
                </Layout>
            }
            <Switch>
                <Route exact path='/tv' component={TVFront} />
                <Route exact path='/tv/schedule/:id?' component={ShowSchedule} />
                <Route exact path='/tv/results/:id?' component={ShowResults} />
                <Route exact path='/tv/bis-schedule/:id?' component={BISSchedule} />
            </Switch>

        </>
    )
}
