import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as DialogStore from '../../store/DialogStore';
import * as InvoiceInfoStore from '../../store/InvoiceInfoStore';
import { ListBase } from '../base/ListBase';
import { Helpers } from '../../utility/Helpers';
import ContentStatus from '../../components/ContentStatus';
import { BreedCategory } from '../../models/dog/Breed';
import { Link } from 'react-router-dom';
import { InvoiceInfo } from '../../models/invoice/InvoiceInfo';
import InvoiceInfoDetails from './InvoiceInfoDetails';
import InvoiceInfoEdit from './InvoiceInfoEdit';
import { IssuerType } from '../../models/invoice/InvoiceEnums';
//import BreedCategoryDetails from './BreedCategoryDetails';
//import BreedCategoryEdit from './BreedCategoryEdit';




class InvoiceInfoList extends ListBase {

    constructor(props: any, context: any) {
        super(props, context, 'invoice-information');
        //this.deleteItemCall = async (id: string) => {
        //    return await httpService.deleteBreedCategory(id)
        //};
    }

    public componentDidMount() {
        super.componentDidMount();

    }


    render() {
        return (
            <div className="row gap-20 masonry pos-r">
                <div className="masonry-item col-md-5">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <h4 className="c-grey-900 mB-20" style={{ float: "left" }}>Invoice Information List</h4>
                        <div style={{ float: "right", verticalAlign: "middle" }}>
                            <Link to='/invoice-information/add'>
                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                            </Link>
                        </div>

                        <div style={{ clear: "both" }} />

                        <ContentStatus isLoading={this.props.dataState.isLoading} errorMessage={this.props.dataState.error} data={this.props.dataState.list}></ContentStatus>

                        <div style={{ width: "max-content", display: "flex", marginTop: "20px" }}>


                            <div style={{
                                width: "fit-content",
                                verticalAlign: "middle",
                                float: "right",
                                paddingRight: "15px",
                            }}>
                                <button type="button"
                                    className={this.props.dataState.issuerType === IssuerType.NORMAL ? "btn cur-p btn-primary" : "btn cur-p btn-outline-primary"}
                                    onClick={() => {

                                        this.props.getItems(IssuerType.NORMAL)
                                        this.props.selectItem(null)

                                    }}>Normal

                                </button>
                            </div>


                            <div style={{
                                width: "fit-content",
                                verticalAlign: "middle",
                                float: "right",
                                paddingRight: "15px"
                            }}>


                                <button type="button"
                                    className={this.props.dataState.issuerType === IssuerType.FEE ? "btn cur-p btn-primary" : "btn cur-p btn-outline-primary"}
                                    onClick={() => {
                                        this.props.getItems(IssuerType.FEE)
                                        this.props.selectItem(null)

                                    }}>
                                    Fee
                                </button>
                            </div>
                        </div>

                        {(!this.props.dataState.isLoading && !this.props.dataState.error && this.props.dataState.list && this.props.dataState.list.length > 0) &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Description</th>
                                        <th scope="col">Invoice Prefix</th>                                        
                                        <th scope="col" className="ta-c mw-actions-min" >Invoice Number</th>                                        
                                        <th scope="col" className="ta-r mw-actions">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="dd-container" ref={this.dragulaDecorator}>
                                    {this.props.dataState.list.map((info: any, index: number) => (
                                        <tr className={`${this.getActiveClass(info)} ${Helpers.getPublishedClass(info)}`} key={info.id}>
                                            <td className="cur-p" onClick={() => { this.showDetails(index); }}>
                                                <span style={{ fontSize: "16px !important" }} className="icon-holder">{info.description}</span>
                                            </td>
                                            <td className="cur-p" onClick={() => { this.showDetails(index); }}>
                                                {info.invoicePrefix}
                                            </td>                                            
                                            <td className="ta-c" onClick={() => { this.showDetails(index); }}>
                                                <span style={{ fontSize: "16px !important" }} className="icon-holder">{info.invoiceNumber}</span>
                                            </td>                                            
                                            <td className="ta-r">
                                                <Link to={`/invoice-information/edit/${info.id}`}>
                                                    <span style={{ fontSize: "16px" }} className="icon-holder"><i
                                                        className="c-light-blue-500 ti-pencil"></i></span>
                                                </Link>
                                                
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>}

                    </div>
                </div>
                <div className="masonry-item col-md-7">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <Route exact path='/invoice-information/details/:itemId?' component={InvoiceInfoDetails} />
                        <Route exact path='/invoice-information/edit/:itemId?' component={InvoiceInfoEdit} />
                        <Route exact path='/invoice-information/add' component={InvoiceInfoEdit} />
                    </div>
                </div>
            </div>)
    }
};

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.invoiceInfoList, userState: state.user };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) },
        selectItem: (data: InvoiceInfo) => { dispatch(InvoiceInfoStore.actionCreators.selectItem(data)) },
        getItems: (type: any) => { dispatch(InvoiceInfoStore.actionCreators.getItems(type)) },


        //    reorderItem: (id: string, pos?: number) => { dispatch(CompetitionListStore.actionCreators.reorderItem(id, pos)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceInfoList);
