import React, { Component, Fragment } from "react";
import { PedigreeAbbreviation } from "../models/dog/PedigreeAbbreviation";


type defaultProps = {
    suggestions: [],
    data: any,
    action?: any,
    disabled?: any

};

class PedigreeAbbreviationSearch extends Component<any> {

    constructor(props: any) {
        super(props);


    }

    state = {
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: this.props.suggestions,
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // What the user has entered
        userInput: ''
    };

    handleChange = (e: any) => {
        const userInput = e.currentTarget.value;
        const filteredSuggestions = this.props.suggestions.filter((pedigree: any) => pedigree.abbrevation.toUpperCase().includes(userInput.toUpperCase()));

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: filteredSuggestions,
            showSuggestions: true,
            userInput: userInput
        });
    };

    handleClick = (e: any, id: any) => {

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: this.props.suggestions,
            showSuggestions: !this.state.userInput && !this.state.showSuggestions,
            userInput: ''
        });

        let input = this.props.suggestions.find((i: PedigreeAbbreviation) => i.id == id);
        this.props.action(input)
        
    };


    render() {

        let suggestionsListComponent;

        if (this.state.showSuggestions) {
            if (this.state.filteredSuggestions && this.state.filteredSuggestions.length > 0) {
                suggestionsListComponent = (
                    <div style={{ position: 'relative' }}>
                        <ul style={{ position: 'absolute', top: '100%', left: 0, background: "white", zIndex: 1000 }} className="suggestions">
                        {this.state.filteredSuggestions.map((suggestion: any, index: any) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === this.state.activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={className} key={suggestion.id} onClick={(e: any) => this.handleClick(e, suggestion.id)}>
                                    {suggestion.abbrevation} - {suggestion.country}
                                </li>
                            );
                        })}
                        </ul>
                    </div>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions.</em>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <input
                    id="pedigreeSearch" name="pedigreeSearch" className="form-control"
                    onChange={(e: any) =>
                        this.handleChange(e)
                    }
                    onClick={(e: any) => this.handleClick(e, e.target.dataset.key)}
                    required={(!this.props.data.noPedigreeAvailable && !this.props.data.pedigreeInProgress)}
                    disabled={this.props.disabled}
                    placeholder={"-- select pedigree --"}
                    autoComplete="off"
                    defaultValue={this.state.userInput}
                    value={this.state.userInput || (this.props.data && this.props.data.pedigreeAbbreviation && this.props.data.pedigreeAbbreviation.abbrevation && (this.props.data.pedigreeAbbreviation.abbrevation + " - " + this.props.data.pedigreeAbbreviation.country))}

                />
                {suggestionsListComponent}
            </Fragment>
        );
    }
}


export default (PedigreeAbbreviationSearch);