import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import { httpService } from "../../index";
import * as DialogStore from '../../store/DialogStore';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import { CustomDiscount } from '../../models/competition/Competition';
import { Helpers } from '../../utility/Helpers';
import DateTimePicker from '../../components/DateTimePicker';
import { NotificationType } from '../../store/DialogStore';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import RegistrationLoader from '../RegistrationLoader';
import { getCustomDiscountType, getCustomDiscountTypeLabel } from '../../models/competition/CompetitionEnums';
import BreedsSearchTable from './BreedsSearchTable';
import CountriesSearchTable from './CountriesSearchTable';

export type CustomDiscountDialogData = {
    data: CustomDiscount,
    callback: Function,
    open: boolean,
    compDateId: string,
    addMode: boolean
}

class CustomDiscountDialog extends React.PureComponent<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            customDiscount: null,
            isEmpty: false,
            isOpen: false,
            previewMode: true,
            disableButton: false
        }
    }

    componentDidMount() {
        this.isDataSet()
    }

    close(res: boolean) {
        this.setState({ customDiscount: null })
        return;
    }

    saveChanges(data: any, save: boolean) {

        if (data && save) {
            this.props.showDialog(`Are you sure you want to save this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {

                    try {
                        this.setState({ disableButton: true })
                        let response = await httpService.customDiscountAddOrUpdate(data)
                        this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });
                        this.setState({ customDiscount: null, isOpen: false, isEmpty: false })
                        this.props.callback(true)
                        this.setState({ disableButton: false })

                    } catch (err) {
                        alert(err.message)
                        this.setState({ disableButton: false })

                    }
                }
            })

        }
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let val = value;

        let at: any = { ...this.state.customDiscount }
        at[name] = val

        this.setState({ customDiscount: at })

    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.customDiscount };

        if (res.name === 'validTo') {
            res.value = Helpers.getEndDate(new Date(res.value));
        }

        data[res.name] = res.value;
        this.setState({
            customDiscount: data
        })
    }

    isDataSet() {
        this.setState({ customDiscount: this.props.data && this.props.data })
    }

    openEdit() {
        this.setState({ previewMode: !this.state.previewMode })
    }

    setMode(): boolean {
        if (this.props.addMode) {
            return false
        } else if (this.state.previewMode) {
            return true
        }
        return false
    }

    handleClassChange = (cls: any) => {
        const cpData: any = { ...this.state.customDiscount }

        if (cpData !== null) {

            if (!cpData.competitionClasses) {
                cpData.competitionClasses = []
            }

            if (cpData.competitionClasses.length > 0) {
                const index = cpData.competitionClasses.findIndex((i: any) => i.competitionClass.id === cls.id)
                if (index !== -1) {
                    cpData.competitionClasses.splice(index, 1)
                } else {
                    cpData.competitionClasses.push({ competitionClass: cls })
                }
            } else {
                cpData.competitionClasses = [{ competitionClass: cls }]
            }

            this.setState({
                customDiscount: cpData
            })
        }
    }

    isClassChecked = (classId: string) => {
        let data: any = { ...this.state.customDiscount };
        if (data != null && data.competitionClasses) {
            if (data.competitionClasses.length > 0) {
                const mf = data.competitionClasses.find((i: any) => i.competitionClass.id === classId)
                if (mf != null && mf !== undefined) {
                    return true
                }
            }
        }

        return false
    }

    breedsChanged(breeds: any[]) {
        let data: any = { ...this.state.customDiscount }
        data.breeds = [...breeds]
        this.setState({ customDiscount: data })
    }

    countriesChanged(countries: any[]) {
        let data: any = { ...this.state.customDiscount }
        data.countries = [...countries]
        this.setState({ customDiscount: data })
    }

    render() {
        return (
            <React.Fragment>
                {
                    (this.props.data) &&
                    <Modal id="comp-date" size="lg" isOpen={this.state.customDiscount}>
                        <ModalHeader toggle={() => this.props.callback(false)}>Custom Discount</ModalHeader>
                        <ModalBody>
                            {!this.state.customDiscount ? <RegistrationLoader /> :
                                <div>
                                    <div className="row">
                                        {!Helpers.isUserEventViewer(this.props.userState.user) && !this.props.addMode &&
                                            <td><span onClick={() => this.openEdit()} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                                className="c-light-blue-500 ti-pencil"></i></span>
                                            </td>
                                        }
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="title">Title *</label>
                                        <input id="title" name="title" className="form-control"
                                            defaultValue={this.state.customDiscount.title} onChange={this.changeData}
                                            disabled={this.setMode()}
                                            maxLength={2000} />
                                    </div>

                                    <div className='row'>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="validFrom">Valid From *</label>
                                            <DateTimePicker name="validFrom" minDate='' isUtc={false}
                                                maxDate=''
                                                disabled={this.setMode()}
                                                value={Helpers.getIsoDate(this.state.customDiscount.validFrom)}
                                                onChange={(e: any) => { this.dateChange(e) }} />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="validTo">Valid To *</label>
                                            <DateTimePicker name="validTo" minDate='' isUtc={false}
                                                maxDate=''
                                                disabled={this.setMode()}
                                                value={Helpers.getIsoDate(this.state.customDiscount.validTo)}
                                                onChange={(e: any) => { this.dateChange(e) }} />
                                        </div>
                                    </div>
                                        <div className="mb-3">
                                            <div className="checkbox checkbox-info peers ai-c">
                                                <input id="fixedPrice" name="fixedPrice" type="checkbox"
                                                    checked={this.state.customDiscount.fixedPrice} className="peer"
                                                    onChange={(e) => this.changeData(e)}
                                                    disabled={this.setMode()} />
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="fixedPrice">
                                                    <span className="peer peer-greed">Is Fixed Price (Price used in registration)</span>
                                                </label>
                                            </div>
                                        </div>
                                    <div className="mb-3">
                                        <div className="checkbox checkbox-info peers ai-c">
                                                <input id="isPercentage" name="isPercentage" type="checkbox"
                                                    checked={this.state.customDiscount.isPercentage} className="peer"
                                                    onChange={(e) => this.changeData(e)}
                                                    disabled={this.setMode()}/>
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="isPercentage">
                                                <span className="peer peer-greed">Is Percentage</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="discount">Discount *</label>
                                            <input id="discount" name="discount" className="form-control"
                                                defaultValue={this.state.customDiscount.discount} onChange={this.changeData}
                                                disabled={this.setMode()}
                                            maxLength={2000} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="discountType">Discount Type</label>
                                            <select id="discountType" name="discountType"
                                            className="form-control"
                                                value={this.state.customDiscount && this.state.customDiscount.discountType}
                                                onChange={this.changeData} 
                                                disabled={this.setMode()} >
                                                
                                            <option value="">-- select discount --</option>
                                            {this.state.customDiscount && getCustomDiscountTypeLabel().map((item: any) => (
                                                <option key={item.id} selected={this.state.customDiscount && this.state.customDiscount.discountType && item.value === this.state.customDiscount.discountType.value} value={item.value}>{getCustomDiscountType(item.value)}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="invoiceDescription">Invoice Description *</label>
                                        <input id="invoiceDescription" name="invoiceDescription" className="form-control"
                                            disabled={this.setMode()}
                                            defaultValue={this.state.customDiscount.invoiceDescription} onChange={this.changeData}
                                            maxLength={2000} />
                                        </div>
                                    <hr/>
                                    <div className="mb-3">
                                        <div className="checkbox checkbox-info peers ai-c">
                                            <input id="excludeCountries" name="excludeCountries" type="checkbox"
                                                checked={this.state.customDiscount.excludeCountries} className="peer"
                                                onChange={(e) => this.changeData(e)}
                                                disabled={this.setMode()} />
                                            <label className="form-label peers peer-greed js-sb ai-c" htmlFor="excludeCountries">
                                                <span className="peer peer-greed">Exclude countries</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col mb-3">
                                        <CountriesSearchTable title={'Countries'} countries={this.state.customDiscount.countries} editMode={this.setMode() === false} callback={(countries: any) => { this.countriesChanged(countries) }} />
                                        </div>
                                    <hr />
                                    <div className="col mb-3">
                                        <label className="form-label">Competition Classes</label>
                                        {this.props.dataState.classes.map((cls: any, index: number) => (
                                            <div key={index} className="checkbox checkbox-info peers ai-c">
                                                <input id={`clsCall:${index}`} type="checkbox"
                                                    checked={this.isClassChecked(cls.id)}
                                                    name={`clsCall:${index}`} className="peer"
                                                    onChange={(ev) => { this.handleClassChange(cls) }}
                                                    disabled={this.setMode()}
                                                />
                                                <label htmlFor={`clsCall:${index}`} className="form-label peers peer-greed js-sb ai-c">
                                                    <span className="peer peer-greed">{cls.name}</span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <hr />
                                    <div className="mb-3">
                                        <div className="checkbox checkbox-info peers ai-c">
                                            <input id="excludeBreeds" name="excludeBreeds" type="checkbox"
                                                checked={this.state.customDiscount.excludeBreeds} className="peer"
                                                onChange={(e) => this.changeData(e)}
                                                disabled={this.setMode()} />
                                            <label className="form-label peers peer-greed js-sb ai-c" htmlFor="excludeBreeds">
                                                <span className="peer peer-greed">Exclude breeds</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col mb-3">
                                        <BreedsSearchTable title={'Breeds'} breeds={this.state.customDiscount.breeds} editMode={this.setMode() === false} callback={(breeds: any) => { this.breedsChanged(breeds) }} />
                                    </div>                                        
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => this.props.callback(false)}>Close</Button>
                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                <Button disabled={this.state.disableButton} color="primary" onClick={() => {
                                    this.saveChanges(this.state.customDiscount, true)

                                }}>Save</Button>
                            }
                        </ModalFooter>
                    </Modal>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.competitionList, userState: state.user };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getCompetitionDate: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: DialogStore.NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomDiscountDialog)
