import React, { CSSProperties, Component, Fragment } from "react";

import ReactDOM from "react-dom";
import { httpService } from "../..";
import { Link, Route, useHistory } from 'react-router-dom';
import ShowSchedule from "./ShowSchedule";
import ShowResults from "./ShowResults";
import BISSchedule from "./BISSchedule";

const styles: { [key: string]: CSSProperties } = {
    container: {
        //display: 'flex',
        //alignItems: 'center',
        //flexWrap: 'wrap',
        //justifyContent: "center"
    },
    eventContainer: {
        border: '1px solid #ccc',
        padding: '10px',
        margin: '0 0 1vw',
        display: 'flex',
        flexDirection: 'column',
        width: '50vw'
    },
    showsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    showContainer: {
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    buttonContainer: {
        display: 'flex',
        gap: '10px',
    },
    button: {
        padding: '5px 10px',
        fontSize: '14px',
        backgroundColor: '#ddd',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};


class TVFront extends Component<any> {
   
    async componentDidMount() {
        if (this.state.events == null) {
            let events = await httpService.tvEvents();
            this.setState({ events: events })
        }
        window.addEventListener('popstate', this.handlePopstate);
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.handlePopstate);
    }

    state: {
        events: any[] | null;
        showSchedule: any[] | null;
        showScheduleId: any[] | null;
        showResults: any[] | null;
        showBisSchedule: any[] | null;
    } = {
            events: null,
            showSchedule: null,
            showScheduleId: null,
            showResults: null,
            showBisSchedule: null
        };


    handlePopstate = (event: any) => {
        const state = event.state;
        if (this.state.showSchedule) {
            this.setState({ showSchedule: null })
        } else if (this.state.showResults) {
            this.setState({ showResults: null })
        } else if (this.state.showBisSchedule) {
            this.setState({ showBisSchedule: null })
        }

        if (state && state.component === 'ShowSchedule') {
            // Render the ShowSchedule component
            ReactDOM.render(<ShowSchedule />, document.getElementById('root'));
        } else if (state && state.component === 'ShowResults') {
            // Render the ShowSchedule component
            ReactDOM.render(<ShowResults />, document.getElementById('root'));
        } else if (state && state.component === 'BISSchedule') {
            // Render the ShowSchedule component
            ReactDOM.render(<BISSchedule />, document.getElementById('root'));
        }
    };

    async getShowSchedule(id: string) {

        let schedule = await httpService.getShowSchedule(id);
        this.setState({ showSchedule: schedule, showScheduleId: id })

        window.history.replaceState({ component: 'ShowSchedule' }, "", `/tv/schedule/${id}`)

    }

    async getShowResults(id: string) {

        let results = await httpService.getShowResults(id);
        this.setState({ showResults: results, showScheduleId: id })

        window.history.replaceState({ component: 'ShowResults' }, "", `/tv/results/${id}`)

    }

    async getBISSchedule(id: string) {

        let schedule = await httpService.getBISSchedule(id);
        this.setState({ showBisSchedule: schedule, showScheduleId: id })

        window.history.replaceState({ component: 'BISSchedule' }, "", `/tv/bis-schedule/${id}`)
    }

    render() {

        return (
            <div>
                {!this.state.showSchedule && !this.state.showBisSchedule && !this.state.showResults &&
                    <div style={styles.container}>
                        {this.state.events && this.state.events.length > 0 && this.state.events.map((event: any) => (
                            <div key={event.id} style={styles.eventContainer}>
                                <p>{event.description}</p>
                                <div style={styles.showsContainer}>
                                    {event.shows.map((show: any) => (
                                        <div key={show.id} style={styles.showContainer}>
                                            <p>{show.description}</p>
                                            <div style={styles.buttonContainer}>
                                                <Link to="#" style={styles.button} onClick={() => this.getShowSchedule(show.id)}>Schedule</Link>
                                                <Link to="#" style={styles.button} onClick={() => this.getShowResults(show.id)}>Results</Link>
                                                <Link to="#" style={styles.button} onClick={() => this.getBISSchedule(show.id)}>BIS Schedule</Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>}
                {this.state.showSchedule &&
                    <ShowSchedule data={this.state.showSchedule} parentId={this.state.showScheduleId} />}

                {this.state.showResults &&
                    <ShowResults data={this.state.showResults} parentId={this.state.showScheduleId} />}

                {this.state.showBisSchedule &&
                    <BISSchedule data={this.state.showBisSchedule} parentId={this.state.showScheduleId} />}
            </div>
                
        );
    }


}

export default (TVFront)
