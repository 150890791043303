import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';

import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,

} from "reactstrap";
import * as DialogStore from '../../store/DialogStore';
import { httpService } from "../..";
import { ApplicationState } from "../../store";
import BreedSearch from "../BreedSearch";
import { Helpers } from "../../utility/Helpers";



type Props = {
    data: any;
    callback: any;
    empty: any;
    action: any;
    actions: any;
    editSpecialBreed: any;
    dateDialog: any
}


let SINGLE_BREED = 0;
let GROUP_BREEDS = 1;


class SpecialBreedComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }

    componentDidMount() {
        this.getFciGroups();
    }


    componentDidUpdate() {
        if (this.props.data && this.state.data && this.props.data.id && this.props.data.id !== this.state.data.id) {
            this.getData()
        }

    }


    state: any = {
        selectedBreed: null,
        selectedBreedIndex: null,
        data: this.props.data,
        isOpen: false,
        editMode: false,
        breedArray: this.props.data.specialShowBreeds,
        currentIndex: null,
        emptySpecialBreed: { id: '', created: Date(), fci: '', name: null },
        dateDialog: this.props.dateDialog,
        expandBreeds: false,
        fciSelected: [],
        breeds: this.props.dataState.breeds,
        breedListExpanded: false,
        checkedBreeds: [],
        breedAddType: SINGLE_BREED,
        addBreedMode: false,
        editBreedMode: false,
        fciBreeds: [],
        fciCategories: [],
        availableBreeds: [],
        indeterminateGroups: [],

    }



    closeModal() {
        this.setState({ isOpen: !this.state.isOpen, currentIndex: null, selectedBreed: null, editMode: !this.state.editMode })

    }

    editSpecialBreed(index: any) {
        this.setState({ isOpen: !this.state.isOpen, selectedBreed: this.props.data.specialShowBreeds[index], currentIndex: index, editMode: true })

    }

    saveBreed = async (index: any) => {
        const selectedBreed = { ...this.state.selectedBreed };
        const groupBreeds = [...this.state.availableBreeds];
        let breeds = [...(this.state.breedArray || [])];

        if (Object.keys(selectedBreed).length !== 0 && selectedBreed.id != "" && this.state.breedAddType == SINGLE_BREED) {
            const existingBreedIndex = breeds.findIndex((i: any) => i.id === selectedBreed.id);
            if (existingBreedIndex === -1) {
                if (breeds[index]) {
                    breeds[this.state.currentIndex] = selectedBreed;
                } else {
                    breeds.push(selectedBreed);
                }
            } else {
                breeds[existingBreedIndex] = selectedBreed;
            }
            this.setState({ breedArray: breeds, editBreed: false }, () => {
                this.props.callback(this.state.breedArray)
            });

        } else if (groupBreeds && groupBreeds.length > 0) {
            this.setState({ selectedBreed: null });
            let filteredBreeds = groupBreeds.filter((item: any) => {
                const id = item.id;
                const isDuplicate = breeds.some((breed: any) => breed.id === id);
                return !isDuplicate;
            });
            filteredBreeds.sort((a, b) => a.name.localeCompare(b.name))
            breeds = breeds.concat(filteredBreeds);
            this.setState({ breedArray: breeds, editBreed: false, addBreedMode: false, availableBreeds: [], fciSelected: [], checkedBreeds: [], breedAddType: SINGLE_BREED }, () => {
                this.props.callback(this.state.breedArray)
            });
        }

    }


    getData = async () => {
        let d = await httpService.getCompetitionDate(this.props.data.id)
        this.setState({ data: d })
    }

    removeSpecialBreed(index: any) {

        let data = [...this.state.breedArray]
        data.splice(index, 1)
        this.setState({ breedArray: data, currentIndex: null }, () => {
            this.props.callback(this.state.breedArray)
        })
        if (this.state.breedArray.length == 0) {
            this.props.empty(true)
        }

    }


    addEmptyBreed = (index: any) => {
        this.setState({ selectedBreed: this.state.emptySpecialBreed, addBreedMode: true, isOpen: !this.state.isOpen, currentIndex: index })
    }

    closeBreedEdit() {
        this.setState({ editBreed: false, selectedBreed: null, currentIndex: null, breedAddType: SINGLE_BREED, fciBreeds: [], availableBreeds: [], fciSelected: [], checkedBreeds: [], addBreedMode: false, indeterminateGroups: [] })
    }


    changeBreed = (ev: any) => {
        let at: any = { ...this.state.selectedBreed };
        at = ev;
        this.setState({ selectedBreed: at });
    }


    getFciGroups() {
        let result: any = [];

        for (let i = 0; i < this.state.breeds.length; i++) {
            let keyValue = this.state.breeds[i].fci;
            let check = result.find((item: any) => keyValue in item);
            if (check) {
                let ind = result.indexOf(check)
                result[ind][keyValue].push(this.state.breeds[i])
            } else {
                result.push({ [keyValue]: [] })
                result[result.length - 1][keyValue].push(this.state.breeds[i])
            }
        }
        result.sort((a: any, b: any) => ((parseInt(Object.keys(a)[0]) < parseInt(Object.keys(b)[0]) ? -1 : 1)));
        this.setState({ fciCategories: result });
    }


    changeGroupData(e: any, val?: any, i?: any) {
        const target = e.target;
        const name = target.name;
        const value = target && target.type === 'checkbox' && name !== "fciBreeds" ? target.checked : target.value;

        const breedArray = [...this.state.breedArray];
        const newBreeds = [...this.state.availableBreeds];
        let checked = [...this.state.checkedBreeds];
        let indeterminateGroups = [...this.state.indeterminateGroups];
        let newRes: any = [];

        if (name === "fciGroup") {
            let element = document.getElementById(val) as HTMLInputElement
            if (element.checked) {
                const res = this.state.fciCategories.find((item: any) => val in item)[val];
                res.forEach((item: any) => {
                    let newItem = item;
                    if (!newBreeds.includes(newItem)) {
                        checked.push(newItem.id)
                        newRes.push(newItem);
                    }
                })
                this.setState({ fciBreeds: [], availableBreeds: [...newBreeds, ...newRes], checkedBreeds: checked });
            } else {
                newRes = newBreeds.filter((item: any) => !this.state.fciCategories[i][val].includes(item))
                checked = [];
                newRes.forEach((item: any) => {
                    checked.push(item.id)
                })
                this.setState({ fciBreeds: [], availableBreeds: newRes, checkedBreeds: checked });
            }
        } else if (name === "fciBreeds") {
            const fciBreed = newBreeds.find((i: any) => i.id === value);
            if (fciBreed) {
                newBreeds.splice(newBreeds.indexOf(fciBreed), 1);
                checked = checked.filter((e: any) => e !== fciBreed.id);
                let checkLength = newBreeds.find((i: any) => i.fci === fciBreed.fci);
                if (!checkLength) {
                    indeterminateGroups.splice(checked.indexOf(fciBreed.fci), 1);
                }

            } else {
                const breed = this.state.breeds.find((item: any) => item.id == value);
                const newBreed = breed;
                newBreeds.push(newBreed);
                checked.push(newBreed.id)
                if (!indeterminateGroups.includes(newBreed.fci)) {
                    indeterminateGroups.push(newBreed.fci)
                }
            }


            this.setState({ availableBreeds: newBreeds, checkedBreeds: checked, indeterminateGroups: indeterminateGroups });
        }
    }



    expandBreedsCheckbox = (item: any) => {
        let data = [...this.state.fciSelected]
        let check = this.state.fciCategories.length > 0 ? this.state.fciCategories.find((i: any) => i.hasOwnProperty(item)) : false;
        if (check) {
            let ind = data.indexOf(item)
            if (ind == -1) {
                data.push(item)
            } else {
                data.splice(ind, 1)
            }
            this.setState({ expandBreeds: !this.state.expandBreeds, fciSelected: data })
        }
    }

    fciIncludesBreeds(value: any, index: any) {
        const res: any[] = this.state.availableBreeds.filter((i: any) => i.fci === parseInt(value));
        const element = document.getElementById(value) as HTMLInputElement | null;
        if (!element) return;

        if (res.length > 0 && res.some((obj: any) => obj.fci === parseInt(value))) {
            element.indeterminate = true;
        }

        const checking = this.state.fciCategories[index][parseInt(value)].every((item: any) => res.some((obj: any) => obj.id === item.id));
        element.checked = checking ? true : false;
        element.indeterminate = res.length > 0 && !checking;
    }


    render() {
        return (
            <React.Fragment>

                <div>




                    {this.state.dateDialog && this.state.breedArray && this.state.breedArray.length > 0 &&
                        <div>
                            <Button className="c-light-blue-500" style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                outline: "none",
                                boxShadow: "none",
                                width: "135px",
                                position: "absolute",
                                left: "50%",
                                background: "white",
                                borderColor: "#2196f3",
                                borderRadius: "15px",
                                float: "left"

                            }} onClick={() => {
                                this.setState({ breedListExpanded: !this.state.breedListExpanded })
                            }}>
                                <span style={{ marginRight: "auto" }}>{!this.state.breedListExpanded ? "Display breeds" : "Hide breeds"}</span>
                                <i style={{ marginLeft: "auto" }} className={this.state.breedListExpanded ? "fa fa-arrow-up" : "fa fa-arrow-down"}></i>
                            </Button>
                        </div>


                    }

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h5>Specialty Show Breeds (Used for cards only)</h5>

                        {(!Helpers.isUserEventViewer(this.props.userState.user) && !this.state.previewMode && this.state.dateDialog) &&
                            <div style={{ justifyContent: "right", display: "flex", gap: "10px" }}>

                                <button className="btn btn-outline-primary breedAddButton" style={{ outline: "none", boxShadow: "none", background: "white", borderColor: "#2196f3", borderRadius: "15px" }} onClick={(e: any) => {
                                    e.preventDefault()
                                    this.addEmptyBreed(null)
                                    this.setState({ editBreed: !this.state.editBreed, breedAddType: SINGLE_BREED })
                                }}>Add Breed</button>
                                <button className="btn btn-outline-primary breedAddButton" style={{ outline: "none", boxShadow: "none", background: "white", borderColor: "#2196f3", borderRadius: "15px" }} onClick={(e: any) => {
                                    e.preventDefault()
                                    this.setState({ editBreed: true, breedAddType: GROUP_BREEDS, addBreedMode: true })
                                }}>Add FCI Groups</button>



                            </div>
                        }
                    </div>
                    {this.state.breedListExpanded && this.state.breedArray && this.state.breedArray.length > 0 &&
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">FCI</th>
                                    <th scope="col">Name</th>
                                    {this.props.actions &&
                                        <th style={{ textAlign: "right" }} scope="col">Actions</th>
                                    }

                                </tr>
                            </thead>
                            <tbody>

                                {this.state.dateDialog && this.state.breedArray && this.state.breedArray.map((specialBreed: any, index: number) =>

                                (
                                    <tr key={specialBreed.id}>
                                        <td style={{ color: "grey" }}
                                            className="cur-p"
                                        >
                                            {index + 1}
                                        </td>
                                        <td style={{ color: "grey" }}
                                            className="cur-p"
                                        >
                                            {(specialBreed.fci || "/")}
                                        </td>
                                        <td style={{ color: "grey" }}
                                            className="cur-p"
                                        >
                                            {(specialBreed.name || "/")}
                                        </td>

                                        {this.props.actions &&
                                            <td>

                                                <a style={{ float: "right" }} href="#" >

                                                    <span style={{ fontSize: "16px" }} onClick={(e: any) => { e.preventDefault(); this.removeSpecialBreed(index) }} className="icon-holder">
                                                        <i className="c-light-blue-500 ti-trash"></i>
                                                    </span>
                                                </a>

                                            </td>
                                        }

                                    </tr>
                                )
                                )
                                }


                            </tbody>


                        </table>
                    }
                    {
                        !this.state.dateDialog && this.props.data &&
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">FCI</th>
                                    <th scope="col">Name</th>
                                    {this.props.actions &&
                                        <th style={{ textAlign: "right" }} scope="col">Actions</th>
                                    }

                                </tr>
                            </thead>
                            <tbody>

                                {!this.state.dateDialog && this.props.data && this.props.data.specialShowBreeds.map((specialBreed: any, index: number) =>

                                (
                                    <tr key={specialBreed.id}>
                                        <td style={{ color: "grey" }}
                                            className="cur-p"
                                        >
                                            {index + 1}
                                        </td>
                                        <td style={{ color: "grey" }}
                                            className="cur-p"
                                        >
                                            {(specialBreed.fci || "/")}
                                        </td>
                                        <td style={{ color: "grey" }}
                                            className="cur-p"
                                        >
                                            {(specialBreed.name || "/")}
                                        </td>

                                        {this.props.actions &&
                                            <td>

                                                <a style={{ float: "right" }} href="#" >

                                                    <span style={{ fontSize: "16px" }} onClick={(e: any) => { e.preventDefault(); this.removeSpecialBreed(index) }} className="icon-holder">
                                                        <i className="c-light-blue-500 ti-trash"></i>
                                                    </span>
                                                </a>

                                            </td>
                                        }

                                    </tr>
                                )
                                )
                                }

                            </tbody>


                        </table>
                    }
                    {(!this.state.dateDialog && this.props.data.specialShowBreeds && this.props.data.specialShowBreeds.length < 1) &&
                        (
                            <div style={{ color: "grey", display: "flex", justifyContent: "center" }} className="cur-p">No specialty show breeds.</div>
                        )
                    }


                    {!this.state.breedArray || (this.state.breedArray && this.state.breedArray.length < 1) && this.state.dateDialog &&

                        <div>
                            <br />
                            No specialty show breeds.
                        </div>
                    }

                    {
                        this.state.editBreed &&

                        <Modal isOpen={this.state.editBreed} size={this.state.breedAddType == SINGLE_BREED ? "md" : "lg"}>
                            <ModalHeader toggle={() => this.closeBreedEdit()}>{!this.state.addBreedMode ? "Edit Breed" : "Add Breed"}</ModalHeader>
                            <ModalBody>

                                <div className="row">
                                    {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                        !this.state.addBreedMode && !this.state.editBreedMode &&
                                        <td><span onClick={() => this.setState({ editBreedMode: !this.state.editBreedMode })} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                            className="c-light-blue-500 ti-pencil"></i></span>
                                        </td>

                                    }
                                </div>


                                {this.state.breedAddType == SINGLE_BREED &&


                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="breed">Breed *</label>
                                        <BreedSearch action={(item: any, index: any) => this.changeBreed(item)} data={this.state.selectedBreed}
                                            suggestions={this.props.dataState.breeds} />
                                    </div>


                                }
                                {this.state.breedAddType == GROUP_BREEDS &&
                                    <div className="row">


                                        {this.state.fciCategories && this.state.fciCategories.length > 0 && this.state.fciCategories.map((item: any, index: any) => (
                                            <div>

                                                <div key={Object.keys(item)[0]} style={{}} className={`mb-3 checkbox checkbox-info peers column-0`}>
                                                    <input id={Object.keys(item)[0]} name="fciGroup"
                                                        type="checkbox"
                                                        ref={input => {
                                                            if (input) {
                                                                this.fciIncludesBreeds(Object.keys(item)[0], index)
                                                            }
                                                        }}
                                                        onChange={(e: any) => this.changeGroupData(e, Object.keys(item)[0], index)} />
                                                    <label htmlFor={Object.keys(item)[0]} className="form-label peers peer-greed js-sb ai-c">
                                                        <span className="peer peer-greed c-light-blue-500">FCI {Object.keys(item)[0]}</span>

                                                    </label>
                                                    <i style={{ cursor: "pointer" }} id={Object.keys(item)[0]} className={this.state.fciSelected.includes(Object.keys(item)[0]) ? "fa fa-arrow-up column-1" : "fa fa-arrow-down column-1"} aria-hidden="true" onClick={() => this.expandBreedsCheckbox(Object.keys(item)[0])}></i>

                                                </div>

                                                <div className="checkbox-grid">

                                                    {this.state.fciSelected.includes(Object.keys(item)[0]) && this.state.fciCategories[index] && this.state.fciCategories[index][Object.keys(item)[0]].map((breed: any, index: any) => (

                                                        <div key={breed.id} className={`mb-3 checkbox checkbox-info peers ai-c checkbox-item column-${index % 3}`}>

                                                            <input id={breed.id} name="fciBreeds" type="checkbox"
                                                                value={breed.id}
                                                                checked={this.state.checkedBreeds.includes(breed.id)}
                                                                className="peer"
                                                                onChange={(e: any) => this.changeGroupData(e)} />
                                                            <label htmlFor={breed.id} className="form-label peers peer-greed js-sb ai-c">
                                                                <span className="peer peer-greed">{breed.name}</span>
                                                            </label>

                                                        </div>

                                                    ))}

                                                </div>
                                                <hr />
                                            </div>
                                        ))}

                                        <div>


                                        </div>


                                    </div>

                                }
                            </ModalBody>
                            <ModalFooter>
                                {!this.state.addMode && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                    <Button disabled={!this.state.addBreedMode && !this.state.editBreedMode || this.state.disableButton} color="primary" onClick={() => this.saveBreed(this.state.currentIndex)}>Confirm</Button>
                                }
                                <Button color="secondary" onClick={() => {
                                    this.setState({ addBreedMode: false, editBreedMode: false, editBreed: false, checkedBreeds: [], indeterminateGroups: [] })
                                    this.closeBreedEdit()
                                }}>Close</Button>
                            </ModalFooter>

                        </Modal>


                    }


                </div>

            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => { return { dialog: state.dialog, dataState: state.competitionList, userState: state.user } },
    DialogStore.actionCreators
)(SpecialBreedComponent)
