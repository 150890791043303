import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';

import * as DialogStore from '../../store/DialogStore';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as InvoiceInfoStore from '../../store/InvoiceInfoStore';
import { DetailsBase } from '../base/DetailsBase';
import DetailsHeader from '../../components/DetailsHeader';
import { Helpers } from '../../utility/Helpers';
import { InvoiceInfo } from '../../models/invoice/InvoiceInfo';
import { getIssuerType } from '../../models/invoice/InvoiceEnums';


class InvoiceInformationDetails extends DetailsBase<InvoiceInfo> {

    constructor(props: any) {
        super(props);
        this.getItem = async (id: string) => {
            let res = await httpService.getInvoiceInformationDetails(id);
            return res;
        }

    }



    render() {
        return (
            <div>
                <DetailsHeader baseUrl="invoice-information" data={this.state.data}
                    title={this.state.data ? this.state.data.invoicePrefix : "Invoice Information Preview"}
                    onDelete={() => {
                        this.deleteItem()
                    }} />

                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                    data={this.state.data}></ContentStatus>
                {(this.state && this.props.dataState && this.state.data) &&
                    <div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Created:</div>
                            <div className="mb-1 col-md-9"> {Helpers.getLocalDateTimeString(this.state.data.created)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Description:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.description}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Invoice Prefix:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.invoicePrefix}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Invoice Number:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.invoiceNumber}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Number of Characters For Invoice Nr:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.numberOfCharactersForInvoiceNumber}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Issuer Type:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && getIssuerType(this.state.data.type)}</div>

                        </div>
              
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.invoiceInfoList };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: InvoiceInfo) => { dispatch(InvoiceInfoStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(InvoiceInfoStore.actionCreators.getItems()) },
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: DialogStore.NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceInformationDetails);
