import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';

import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,

} from "reactstrap";
import * as DialogStore from '../../store/DialogStore';
import { httpService } from "../..";
import { ApplicationState } from "../../store";
import { Helpers } from "../../utility/Helpers";
import { getBISType, getBISTypeOptions } from "../../models/competition/CompetitionEnums";
import StaffSearch from "../StaffSearch";
import DateTimePicker from "../DateTimePicker";



type Props = {
    data: any;
    callback: any;
    empty: any;
    recorder: any;
    ringLeader: any;
    action: any;
    actions: any;
    editSpecialBreed: any;
    dateDialog: any,
    open: any,
    add: any,
    update: any,
    judges: any,
    save: any,
}


class BISShowsDialogComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }

    componentDidUpdate() {
        if (this.props.data && !this.state.data) {
            this.getData()
        }

    }

    getData() {

        this.setState({ data: this.props.data })
    }



    state: any = {
        selectedBreed: null,
        selectedBreedIndex: null,
        data: this.props.data,
        isOpen: false,
        editMode: false,
        previewMode: true,
        addMode: false,
        judge: null,
        currentIndex: null,
        recorder: this.props.recorder,
        ringLeader: this.props.ringLeader,
        emptySpecialBreed: { id: '', created: Date(), fci: '', name: null },
        dateDialog: this.props.dateDialog,
        disableButton: false


    }

    saveChanges(data: any) {
        this.setState({ disableButton: true })
        try {
            this.props.save(data)
            this.setState({ data: null, selectedBreed: null, previewMode: true, disableButton: false })
        } catch (err) {
            alert(err.message)
            this.setState({ disableButton: false })
        }


    }

    changeData(e: any) {
        const target = e.target;
        let value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        let data: any = { ...this.state.data };

        if (name === "type") {
            value = parseInt(value);
        }
        data[name] = value;
        this.setState({ data: data })
    }

    async changeJudge(e: any) {
        let data: any = { ...this.state.data };

        let j = await httpService.getRegisteredUser(e);
        let judge = { "username": j.userName, "firstName": j.firstName, "lastName": j.lastName, "id": j.id }
        data.judge = judge;

        this.setState({ data: data })
    }

    setMode(): boolean {
        if (this.props.add) {
            return false
        }
        if (this.state.previewMode) {
            return true
        }
        return false

    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.data };
        data[res.name] = Helpers.getLocalISODate(res.value);
        this.setState({
            data: data
        })
    }




    render() {

        return (
            <React.Fragment>

                <div>


                    <Modal id="bis" size="md" isOpen={this.props.open}>
                        <ModalHeader toggle={() => {
                            this.setState({ data: null, previewMode: true })
                            this.props.action()
                        }}>{this.props.add ? "Add BIS Show" : "Edit BIS Show"}</ModalHeader>
                        <ModalBody>

                            <div>

                                <div className="row">

                                    {!this.props.add && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                        <td><span onClick={() => this.setState({ previewMode: !this.state.previewMode })} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                            className="c-light-blue-500 ti-pencil"></i></span>
                                        </td>
                                    }


                                </div>
                                <div className="row">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="judge">Judge</label>

                                        <StaffSearch action={(e: any) => this.changeJudge(e)} data={this.state.data} type="judge"
                                            suggestions={this.props.judges} disabled={this.setMode()} />
                                    </div>


                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="type">Type</label>
                                        <select id="type" name="type"
                                            className="form-control"
                                            disabled={this.setMode()}
                                            value={this.state.data && this.state.data.type && this.state.data.type}
                                            onChange={(e: any) => this.changeData(e)} >
                                            <option value="">-- select type --</option>
                                            {getBISTypeOptions().map((item: any) => (
                                                <option key={item.value} value={item.value}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="approxStartTime">Approximate Start Time </label>
                                        <input id="approxStartTime" name="approxStartTime" className="form-control"
                                            disabled={this.setMode()}
                                            value={this.state.data && this.state.data.approxStartTime} onChange={(e) => this.changeData(e)}
                                            maxLength={2000} />
                                    </div>
                              
                                    <div className="mb-3">
                                        <div className="checkbox checkbox-info peers ai-c">
                                            <input id="isFinished" name="isFinished" type="checkbox"
                                                disabled={this.setMode()}
                                                checked={this.state.data && this.state.data.isFinished} className="peer"
                                                onChange={(e) => this.changeData(e)} />
                                            <label className="form-label peers peer-greed js-sb ai-c" htmlFor="isFinished">
                                                <span className="peer peer-greed">Is Finished</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="checkbox checkbox-info peers ai-c">
                                            <input id="isPresentFinished" name="isPresentFinished" type="checkbox"
                                                disabled={this.setMode()}
                                                checked={this.state.data && this.state.data.isPresentFinished} className="peer"
                                                onChange={(e) => this.changeData(e)} />
                                            <label className="form-label peers peer-greed js-sb ai-c" htmlFor="isPresentFinished">
                                                <span className="peer peer-greed">Is Present Finished</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </ModalBody>
                        <ModalFooter>
                            {(!Helpers.isUserEventViewer(this.props.userState.user) && !this.setMode()) &&
                                <Button color="primary" disabled={this.state.disableButton} onClick={() => {

                                    this.saveChanges(this.state.data);

                                }}>Save</Button>
                            }
                            <Button color="secondary" onClick={() => {
                                this.setState({ data: null, previewMode: true })
                                this.props.action();

                            }
                            }>Close</Button>
                        </ModalFooter>

                    </Modal>



                </div>

            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => { return { dialog: state.dialog, dataState: state.competitionList, userState: state.user } },
    DialogStore.actionCreators
)(BISShowsDialogComponent)
