import React from 'react';
import { Component } from "react";

class TVRingJudgeInfo extends Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <div className="helvetica_regular_56 tv_white ring_judge_info" style={{ display: 'flex', paddingTop: '4.07vh', height:'10vh'}}>
                <div className="ring_info_text">RING {this.props.description}</div>
                <div className="ring_judge_text">{this.props.judge}</div>
            </div>
        )
    }
}

export default (TVRingJudgeInfo as any);