
export enum IssuerType {
    NORMAL = 0,
    FEE = 1
}

export function getIssuerType(type: any) {
    switch (type) {
        case IssuerType.NORMAL:
            return 'Normal';
        case IssuerType.FEE:
            return 'Fee';

    }
}

export function getIssuerTypes() {
    return [
        { label: 'Normal', value: IssuerType.NORMAL },
        { label: 'Fee', value: IssuerType.FEE },
    ]
}