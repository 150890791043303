import { CompetitionRing } from "../competition/CompetitionDate";
import { TaxLevel } from "../competition/CompetitionEnums";
import { Country } from "../country/Country";
import { Breed } from "../dog/Breed";
import { Dog, PhoneNumber } from "../dog/Dog";
import { Profile } from "../registered-user/RegisteredUser";
import { BestPuppyGrade, BISScore, BISScoreType, Cac, Cacib, Grade, PuppyGrade, Rang } from "./RegistrationEnums";

export interface CompetitionRegistration {
    id?: string | null,
    email: string,
    firstName: string,
    lastName: string,
    paymentStatus: PaymentStatus,
    paymentDateTime: Date,
    paymentId: string,
    paymentIntentId?: string,
    paymentAmount?: number,
    paymentFee?: number,
    phoneNumber: PhoneNumber,
    city: string,
    address: string,
    country: Country,
    profile: Profile,
    competitionRegistrationDogs: Array<CompetitionDog>,
    competitionRegistrationCoupleGroups: Array<CompetitionCoupleGroup>,
    competitionRegistrationSpecialShow: Array<CompetitionSpecialShow>,
    competitionRegistrationJuniorSeniorHandling: Array<CompetitionJuniorSenior>



}

export function getPaymentStatusItems() {
    return [
        { label: 'Not set', value: PaymentStatus.NOT_SET },
        { label: 'In Progress', value: PaymentStatus.IN_PROGRESS },
        { label: 'Successful', value: PaymentStatus.SUCCESSFUL },
        { label: 'Cancelled', value: PaymentStatus.CANCELLED },
        { label: 'Refunded', value: PaymentStatus.REFUNDED },
        { label: 'Doggle Imported', value: PaymentStatus.DOGGLE_IMPORTED },
        { label: 'Invalid', value: PaymentStatus.INVALID },
        { label: 'Manual', value: PaymentStatus.MANUAL },
       
    ]
}

export function getCoupleGroupType() {
    return [
        { label: 'Couple', value: CoupleGroupType.COUPLE },
        { label: 'Group', value: CoupleGroupType.GROUP },
    ]
}

export function getPaymentStatus(type: PaymentStatus) {
    switch (type) {
        case PaymentStatus.NOT_SET:
            return 'Not Set';
        case PaymentStatus.IN_PROGRESS:
            return 'In Progress';
        case PaymentStatus.SUCCESSFUL:
            return 'Successful';
        case PaymentStatus.CANCELLED:
            return 'Canceled';
        case PaymentStatus.REFUNDED:
            return 'Refunded';
        case PaymentStatus.DOGGLE_IMPORTED:
            return 'Doggle Imported';
        case PaymentStatus.INVALID:
            return 'Invalid';
        case PaymentStatus.MANUAL:
            return 'Manual';
        
    }
}

export function getCoupleGroup(type: CoupleGroupType) {
    switch (type) {
        case CoupleGroupType.COUPLE:
            return 'Couple';
        case CoupleGroupType.GROUP:
            return 'Group';
   
    }
}

export enum CoupleGroupType {
    COUPLE = 0,
    GROUP = 1
}

export enum PaymentStatus {
    NOT_SET = 0,
    IN_PROGRESS = 1,
    SUCCESSFUL = 2,
    CANCELLED = 3,
    REFUNDED = 4,
    DOGGLE_IMPORTED = 5,
    INVALID = 6,
    MANUAL = 7, 

}

export interface CompetitionClass {
    id?: string,
    created: Date,
    name: string,
    engName: string,
    germanName: string,
    croatianName: string,
    englishDescription: string,
    germanDescription: string,
    croatianDescription: string,
    description: string,
    maxAgeInMonths: number,
    minAgeInMonths: number,
    order: number,
    requiresChampionship: boolean,
    requiresPedigree: boolean,
    requiresWork: boolean

}


export interface CompetitionDog {

    id?: string,
    created: string,
    dog: Dog,
    competitionRegistrationId: string,
    competitionDate: CompetitionDate
    competitionClass: CompetitionClass,
    competitionRing?: CompetitionRing,
    competitionScore?: CompetitionScore,
    registeredOnAllEvents?: boolean,
    refunded?: boolean,
    paymentAmount: number,
    catalogNumber?: number | null,
    couponCode?: string
}

export interface CompetitionCoupleGroup {
    id?: string,
    created: Date,
    type: CoupleGroupType,
    paymentAmount: number,
    competitionDate: CompetitionDate,
    dogs: Array<SingleCoupleDog>



}

export interface CompetitionSpecialShow {
    id?: string,
    created: Date,
    specialCompetition: SpecialCompetition,
    paymentAmount: number,
    competitionDate: CompetitionDate,
    catalogNumber?: number | null,
    dog: Array<SingleCoupleDog>
}

export interface CompetitionJuniorSenior {
    id?: string,
    created: Date,
    firstName: string,
    lastName: string,
    competitionDate: CompetitionDate,
    dog: Dog,
    birthDate: Date,
    paymentAmount: number,
    invoiceNumber: '',
    competitionRegistrationId: string


}

export interface SingleCoupleDog {
    id?: string,
    name: string,
    created: Date,
    breed: Breed

}

export interface SpecialCompetition {
    id: string,
    title: string,
    englishTitle: string,
    price: number,
    invoiceDescription?: string,
    basePriceTaxLevel: TaxLevel,
    competitionDateId?: string
}

export interface CompetitionDate {
    date: Date,
    description: string,
    hasJuniorHandlingRegistrations: boolean,
    hasCoupleAndGroupRegistrations: boolean,
    id?: string,
    created: string

}

export interface CompetitionScore {
    description: string,
    bob: boolean,
    bos: boolean,
    bestFemale: boolean,
    bestMale: boolean,
    juniorBob: boolean,
    veteranBob: boolean,
    bestMinorPuppy: boolean,
    bestPuppy: boolean,
    wasNotPresent: boolean,
    evaluationDone: boolean,
    disqualified: boolean,
    cannotBeEvaluated: boolean,
    grade: Grade,
    bestPuppyGrade: BestPuppyGrade,
    puppyGrade: PuppyGrade,
    cac: Cac,
    cacib: Cacib,
    rang: Rang

}

export interface CompetitionBISScore {
    isPresent: boolean,
    bisScoreType: BISScoreType,
    bisScore: BISScore

}
