import {Action, Reducer} from 'redux';
import {httpService} from '../index';
import {AppThunkAction} from './';
import {Dog} from '../models/dog/Dog'
import {Breed} from '../models/dog/Breed';
import {PedigreeAbbreviation} from '../models/dog/PedigreeAbbreviation';
import {RegisteredUser} from '../models/registered-user/RegisteredUser';
import {Country} from '../models/country/Country';
import {DogStatus} from "../models/dog/DogEnums";

export interface DogListState {
    selectedItem: Dog | null,
    list: Array<Dog>,
    isLoading: boolean,
    isLoadingMore: boolean,
    hasMore: boolean,
    error: string,
    selectedTab: number,
    isOpen: boolean,
    breeds: Array<Breed>,
    webBreeds: Array<Breed>,
    countries: Array<Country>,
    users: Array<RegisteredUser>,
    pedigreeAbbreviations: Array<PedigreeAbbreviation>,
    registrations: Array<any>,
    dogStatus: DogStatus | null,
    page: number,
    name: string,
    count: number,
    profile: any,
    selectedUser: RegisteredUser | null
}

interface SelectDogAction {
    type: 'SELECT_DOG';
    item: Dog;
   
}

interface RequestDogAction {
    type: 'REQUEST_DOG';
}

interface RequestMoreDogsAction {
    type: 'REQUEST_MORE_DOGS';

}

interface ReceiveMoreDogsAction {
    type: 'RECEIVE_MORE_DOGS';
    items: Array<Dog>;
    error: ''

}

interface ReceiveDogAction {
    type: 'RECEIVE_DOG';
    items: Array<Dog>;
    error: string;
}

interface ReceiveFilteredDogAction {
    type: 'RECEIVE_FILTERED_DOG';
    items: Array<Dog>;
    error: string;
}

interface RequestBreedsAction {
    type: 'REQUEST_BREEDS';
}

interface ReceiveBreedsAction {
    type: 'RECEIVE_BREEDS';
    items: Array<Breed>;
    webBreeds: Array<Breed>
}

interface ReceiveBreedAction {
    type: 'RECEIVE_BREED';
    item: Breed;
}

interface ReceiveDogRegistrationAction {
    type: 'RECEIVE_DOG_REGISTRATIONS';
    registrations: Array<any>
}

interface RequestPedigreeAbbreviationsAction {
    type: 'REQUEST_PEDIGREE_ABBREVIATIONS';
}

interface ReceivePedigreeAbbreviationsAction {
    type: 'RECEIVE_PEDIGREE_ABBREVIATIONS';
    items: Array<PedigreeAbbreviation>;
}

interface RequestUserAction {
    type: 'REQUEST_USER';
}

interface ReceiveUserAction {
    type: 'RECEIVE_USER';
    items: Array<RegisteredUser>;
}

interface RequestCountriesAction {
    type: 'REQUEST_COUNTRIES';
}
interface ReceiveCountriesAction {
    type: 'RECEIVE_COUNTRIES';
    items: Array<Country>;
}

interface GetStatusAction {
    type: 'GET_STATUS';

}

interface SetDogStatusAction {
    type: 'SET_DOG_STATUS';
    dogStatus: null


}

interface GetPageAction {
    type: 'GET_PAGE';

}

interface SetPageAction {
    type: 'SET_PAGE';
    page: 0;
}

interface GetNameAction {
    type: 'GET_NAME';

}

interface SetNameAction {
    type: 'SET_NAME';
    name: "";
}

interface SetOpenAction {
    type: 'SET_OPEN';
    open: boolean

}

interface GetUserAction {
    type: 'GET_USER';

}

interface SetUserAction {
    type: 'SET_NEW_USER';
    selectedUser: RegisteredUser | null;
}

interface SetProfileAction {
    type: 'SET_PROFILE';
    profile: null;
}


type KnownAction =
    SelectDogAction
    | RequestDogAction
    | RequestMoreDogsAction
    | ReceiveMoreDogsAction
    | ReceiveDogAction
    | ReceiveFilteredDogAction
    | RequestBreedsAction
    | ReceiveBreedsAction
    | ReceiveBreedAction
    | RequestPedigreeAbbreviationsAction
    | ReceivePedigreeAbbreviationsAction
    | ReceiveDogRegistrationAction
    | RequestUserAction
    | ReceiveUserAction
    | RequestCountriesAction
    | ReceiveCountriesAction
    | SetDogStatusAction
    | GetStatusAction
    | GetPageAction
    | SetPageAction
    | GetNameAction
    | SetNameAction
    | SetOpenAction
    | GetUserAction
    | SetUserAction
    | SetProfileAction;

export const actionCreators = {
    selectItem: (item: Dog): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SELECT_DOG', item: item});
    },

    getItems: (page?: number, name?: string, count?: number, profile?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            try {
                let data;
                dispatch({ type: 'REQUEST_DOG' });
                switch (appState.dogList.dogStatus) {
                    case DogStatus.CONFIRMED:
                        data = await httpService.getConfirmedDogs({ page, name, count, profile });
                        break;
                    case DogStatus.UNCONFIRMED:
                        data = await httpService.getUnconfirmedDogs({ page, name, count, profile });
                        break;
                    case DogStatus.REJECTED:
                        data = await httpService.getRejectedDogs({ page, name, count, profile });
                        break;
                    default:
                        throw new Error(`Invalid dog list dogStatus: ${appState.dogList.dogStatus}`);
                }

                dispatch({ type: 'RECEIVE_FILTERED_DOG', items: data, error: '' });

            } catch (err) {
                dispatch({ type: 'RECEIVE_FILTERED_DOG', items: [], error: err.message && 'Ooops, something went wrong.' });
            }
        }
    },

    getDogRegistrations: (id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            try {
                let registrations = await httpService.getDogRegistrations(id)
                dispatch({ type: 'RECEIVE_DOG_REGISTRATIONS', registrations: registrations });
            } catch (err) {
                dispatch({ type: 'RECEIVE_DOG_REGISTRATIONS', registrations: [] });
            }
        }
    },


    getMoreItems: (page?: number, name?: string, count?: number, profile?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            try {
                dispatch({type: 'REQUEST_MORE_DOGS'});
                if (appState.dogList.dogStatus === DogStatus.CONFIRMED) {
                    let data = await httpService.getConfirmedDogs({ page, name, count, profile });
                    dispatch({type: 'RECEIVE_MORE_DOGS', items: data, error: ''});
                } else if (appState.dogList.dogStatus === DogStatus.UNCONFIRMED) {
                    let data = await httpService.getUnconfirmedDogs({ page, name, count, profile });
                    dispatch({type: 'RECEIVE_MORE_DOGS', items: data, error: ''});
                } else if (appState.dogList.dogStatus === DogStatus.REJECTED) {
                    let data = await httpService.getRejectedDogs({ page, name, count, profile });
                    dispatch({type: 'RECEIVE_MORE_DOGS', items: data, error: ''});
                }


            } catch (err) {
                dispatch({type: 'RECEIVE_DOG', items: [], error: ''});
            }
        }
    },
    getBreeds: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            try {
                dispatch({type: 'REQUEST_BREEDS'});
                let data = await httpService.breeds();
                let web = await httpService.webBreeds();

                dispatch({ type: 'RECEIVE_BREEDS', items: data, webBreeds: web });
            } catch (err) {
                dispatch({ type: 'RECEIVE_BREEDS', items: [], webBreeds: [] });
            }
        }
    },

    getPedigreeAbbreviations: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            try {
                dispatch({type: 'REQUEST_PEDIGREE_ABBREVIATIONS'});
                let data = await httpService.pedigreeAbbreviations();
                dispatch({type: 'RECEIVE_PEDIGREE_ABBREVIATIONS', items: data});
            } catch (err) {
                dispatch({type: 'RECEIVE_PEDIGREE_ABBREVIATIONS', items: []});
            }
        }
    },
    getUsers: (page?: any, username?: string, count?: number, usertype?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            dispatch({type: 'REQUEST_USER'});
            let data = await httpService.getRegisteredUsers({page, username, count, usertype});
            dispatch({type: 'RECEIVE_USER', items: data});


        }
    },
    getFilteredUsers: (username?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            dispatch({type: 'REQUEST_USER'});
            let data = await httpService.getFilteredRegisteredUsers(username);
            dispatch({type: 'RECEIVE_USER', items: data});


        }
    },


    getCountry: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            dispatch({type: 'REQUEST_COUNTRIES'});
            let data = await httpService.getCountries();
            dispatch({type: 'RECEIVE_COUNTRIES', items: data});


        }
    },

    setStatus: (status?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            dispatch({type: 'SET_DOG_STATUS', dogStatus: status});


        }
    },

    setPage: (page?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            dispatch({type: 'SET_PAGE', page: page});


        }
    },
    setOpen: (open?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            dispatch({type: 'SET_OPEN', open: open});


        }
    },

    setName: (name?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            dispatch({type: 'SET_NAME', name: name});


        }
    },

    setProfile: (profile?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            dispatch({ type: 'SET_PROFILE', profile: profile });


        }
    },

    getSingleUser: (id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            if (id !== null) {
                let data = await httpService.getRegisteredUser(id);
                dispatch({type: 'SET_NEW_USER', selectedUser: data});
            } else {
                dispatch({type: 'SET_NEW_USER', selectedUser: null});
            }
        }
    },
        }

const unloadedState: DogListState = {
    selectedItem: null,
    isLoading: false,
    list: [],
    breeds: [],
    webBreeds: [],
    pedigreeAbbreviations: [],
    isLoadingMore: false,
    hasMore: true,
    error: '',
    users: [],
    selectedTab: 0,
    countries: [],
    registrations: [],
    page: 0,
    count: 20,
    name: '',
    profile: null,
    dogStatus: DogStatus.UNCONFIRMED,
    isOpen: false,
    selectedUser: null
};

export const reducer: Reducer<DogListState> = (state: DogListState | undefined, incomingAction: Action): DogListState => {
    if (state === undefined) {
        return unloadedState;
    }

    let stateClon = { ...state };

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_DOG':
            stateClon.selectedItem = action.item;
            stateClon.isLoading = false;
            stateClon.error = '';
            stateClon.isOpen = false;

            return stateClon;

        case 'REQUEST_DOG':
            stateClon.list = [];
            stateClon.isLoading = true;
            stateClon.error = '';
            stateClon.isOpen = false;

            return stateClon;

        case 'RECEIVE_DOG':
            stateClon.hasMore = true
            let si = stateClon.selectedItem;

            if (state.count > action.items.length) {
                stateClon.hasMore = false;
            }

            if (action.items && action.items.length > 0 && !stateClon.selectedItem) {
                si = action.items[0];
            }

            stateClon.selectedItem = si;
            stateClon.list = action.items;
            stateClon.isLoading = false;
            stateClon.error = action.error;
            stateClon.isOpen = false;

            return stateClon;

        case 'RECEIVE_FILTERED_DOG':
            stateClon.hasMore = true
            let newArray = [...state.list];
            if (stateClon.list.length < 1) {
                newArray = action.items
            }
            let s = stateClon.selectedItem;

            if (action.items && action.items.length > 0 && !stateClon.selectedItem) {
                s = action.items[0];
            }
            if (stateClon.count > action.items.length || action.items.length === 0) {
                stateClon.hasMore = false
            }

            stateClon.selectedItem = s;
            stateClon.list = newArray;
            stateClon.isLoading = false;
            stateClon.error = action.error;
            stateClon.isOpen = false;

            return stateClon;

        case 'REQUEST_MORE_DOGS':
            stateClon.error = '';
            stateClon.isOpen = false;
            stateClon.isLoadingMore = true;

            return stateClon;

        case 'RECEIVE_MORE_DOGS':
            let moreDogs: any = []
            let dogsList = [...state.list]

            let items = action.items
            if (state.count === 0) {
                state.count = items.length
            }

            if (action.items.length > 0) {
                moreDogs = [...dogsList, ...items]
            } else {
                moreDogs = dogsList;
            }

            let item = stateClon.selectedItem;

            if (stateClon.count > items.length || items.length === 0) {
                stateClon.hasMore = false
            }

            stateClon.selectedItem = item;
            stateClon.list = moreDogs;
            stateClon.error = action.error;
            stateClon.isOpen = false;
            stateClon.isLoadingMore = false;

            return stateClon;

        case 'REQUEST_BREEDS':
            stateClon.isOpen = false;
            return stateClon;

        case 'RECEIVE_BREEDS':
            stateClon.breeds = action.items;
            stateClon.webBreeds = action.webBreeds;
            stateClon.isOpen = false;
            return stateClon;
        case 'RECEIVE_DOG_REGISTRATIONS':
            stateClon.registrations = action.registrations;
            return stateClon;

        case 'REQUEST_PEDIGREE_ABBREVIATIONS':
            stateClon.isOpen = false;
            return stateClon;

        case 'RECEIVE_PEDIGREE_ABBREVIATIONS':
            stateClon.pedigreeAbbreviations = action.items;
            stateClon.isOpen = false;
            return stateClon;

        case 'REQUEST_USER':
            stateClon.isOpen = false;
            return stateClon;

        case 'RECEIVE_USER':
            stateClon.users = action.items;
            stateClon.isOpen = false;
            return stateClon;
        case 'REQUEST_COUNTRIES':
            stateClon.isOpen = false;
            return stateClon;

        case 'RECEIVE_COUNTRIES':
            stateClon.countries = action.items;
            stateClon.isOpen = false;
            return stateClon;

        case 'SET_DOG_STATUS':
            stateClon.dogStatus = action.dogStatus;
            stateClon.isOpen = false;
            return stateClon;

        case 'SET_PAGE':
            stateClon.page = action.page;
            stateClon.isOpen = false;
            return stateClon;

        case 'SET_NAME':
            stateClon.name = action.name;
            stateClon.isOpen = false;
            return stateClon;

        case 'SET_OPEN':
            stateClon.isOpen = action.open;
            return stateClon;

        case 'SET_NEW_USER':
            stateClon.selectedUser = action.selectedUser;
            return stateClon;

        case 'SET_PROFILE':
            stateClon.profile = action.profile;
            stateClon.isOpen = false;
            return stateClon;
    }

    return state;
}
