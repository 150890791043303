import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';

import { history, httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as InvoiceInfoStore from '../../store/InvoiceInfoStore';
import * as DialogStore from '../../store/DialogStore';
import { DetailsBase } from '../base/DetailsBase';
import { InvoiceInfo } from '../../models/invoice/InvoiceInfo';
import { getIssuerTypes, IssuerType } from '../../models/invoice/InvoiceEnums';


class InvoiceInfoEdit extends DetailsBase<InvoiceInfo> {

    codeError: string;
    propsForTrim: Array<string>;

    constructor(props: any) {
        super(props);

        this.codeError = '';
        this.propsForTrim = ['invoicePrefix', 'invoiceNumber'];

        this.getItem = async (id: string) => { return await httpService.getInvoiceInformationDetails(id) }
        this.saveItem = async (data: InvoiceInfo) => {
            return await httpService.addOrUpdateInvoiceInformation(data)
        }
        this.redirectAfterSave = (id: string) => {
            history.replace(`/invoice-information/details/${id}`)
        }

        this.emptyData = {
            id: '',
            description: '',
            invoicePrefix: '',
            invoiceNumber: 0,
            numberOfCharactersForInvoiceNumber: 0,
            created: new Date,
            type: ''
          
        }
    }

    onSubmit = (ev: any) => {
        ev.preventDefault();

        let data: any = { ...this.state.data };

        this.saveChanges(data as InvoiceInfo, "invoice-info");
        this.props.getItems(data.type)
        this.setState({selectedTab: data.type})
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let val = value;

        if (name === "type") {
            val = parseInt(val)
        }

        let data: any = { ...this.state.data };
        data[name] = val;

        this.whitespaceTrimProps(this.propsForTrim, data, name);

        this.setState({
            data: data
        })
    }


    render() {
        return (
            <div>
                <h4 className="c-grey-900">{this.isNew(this.state) && !this.state.isLoading ? "Add" : "Edit"} Invoice Information</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                    data={this.state.data}></ContentStatus>
                <br />

              
                {(this.state && this.state.data) &&
                    <form onSubmit={this.onSubmit}>

                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="description">Description *</label>
                                <input id="description" name="description" required className="form-control"
                                    defaultValue={this.state.data.description} onChange={this.changeData} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="invoicePrefix">Invoice Prefix *</label>
                                <input id="invoicePrefix" name="invoicePrefix" required className="form-control"
                                    defaultValue={this.state.data.invoicePrefix} onChange={this.changeData} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="invoiceNumber">Invoice Number *</label>
                                <input id="invoiceNumber" name="invoiceNumber" required className="form-control"
                                    defaultValue={this.state.data.invoiceNumber} onChange={this.changeData}>

                                </input>
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="numberOfCharactersForInvoiceNumber">Number Of Characters For Invoice Number *</label>
                                <input id="numberOfCharactersForInvoiceNumber" required name="numberOfCharactersForInvoiceNumber" className="form-control"
                                    defaultValue={this.state.data.numberOfCharactersForInvoiceNumber || ""} onChange={this.changeData}>

                                </input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="type">Invoice issuer *</label>
                                <select id="type" name="type" onChange={this.changeData} className="form-control" value={this.state.data && this.state.data.type}>
                                    <option value="">-- no issuer type --</option>
                                    {getIssuerTypes().map((issuer: any) => (
                                        <option key={issuer.value} value={issuer.value}>{issuer.label}</option>
                                    ))}
                                </select>
                            </div>



                        </div>

                        <br />
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.invoiceInfoList };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: InvoiceInfo) => { dispatch(InvoiceInfoStore.actionCreators.selectItem(data)) },
        getItems: (type?: any) => { dispatch(InvoiceInfoStore.actionCreators.getItems(type)) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: DialogStore.NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceInfoEdit);
