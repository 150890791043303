import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as DialogStore from '../../store/DialogStore';
import { httpService } from "../..";
import { ApplicationState } from "../../store";
import { Helpers } from "../../utility/Helpers";
import { getCustomBISGrades } from "../../models/competition/CompetitionEnums";
import StaffSearch from "../StaffSearch";
import BreedsSearchTable from "./BreedsSearchTable";

class CustomBISShowsDialogComponent extends React.Component<any> {

    customGrades = getCustomBISGrades()

    constructor(props: any) {
        super(props);
    }

    componentDidUpdate() {
        if (this.props.data && !this.state.data) {
            this.getData()
        }
    }

    getData() {
        //deep clone for list item's changes
        this.setState({ data: JSON.parse(JSON.stringify(this.props.data)) })
    }

    state: any = {
        data: this.props.data,
        isOpen: false,
        editMode: false,
        previewMode: true,
        addMode: false,
        currentIndex: null,
        disableButton: false
    }

    saveChanges(data: any) {

        if (!data.title || data.title.trim() === '') {
            alert('Title is required.')
            return
        }

        if (!data.judge) {
            alert('Judge is required.')
            return
        }

        this.setState({ disableButton: true })

        try {
            this.props.save(data)
            this.setState({ data: null, previewMode: true, disableButton: false })
        } catch (err) {
            alert(err.message)
            this.setState({ disableButton: false })
        }
    }

    changeData(e: any) {
        const target = e.target;
        let value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let data: any = { ...this.state.data };

        if (name === "type") {
            value = parseInt(value);
        }

        data[name] = value;
        this.setState({ data: data })
    }

    async changeJudge(e: any) {
        let data: any = { ...this.state.data };

        let j = await httpService.getRegisteredUser(e);
        let judge = { "username": j.userName, "firstName": j.firstName, "lastName": j.lastName, "id": j.id }
        data.judge = judge;

        this.setState({ data: data })
    }

    setMode(): boolean {
        if (this.props.add) {
            return false
        }
        if (this.state.previewMode) {
            return true
        }
        return false
    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.data };
        data[res.name] = Helpers.getLocalISODate(res.value);
        this.setState({
            data: data
        })
    }

    handleChange = (grade: string) => {
        const cpData: any = { ...this.state.data }

        if (cpData !== null) {

            if (!cpData.requiredGrades) {
                cpData.requiredGrades = []
            }

            if (cpData.requiredGrades.length > 0) {
                const index = cpData.requiredGrades.findIndex((i: string) => i === grade)
                if (index !== -1) {
                    cpData.requiredGrades.splice(index, 1)
                } else {
                    cpData.requiredGrades.push(grade)
                }
            } else {
                cpData.requiredGrades = [grade]
            }

            this.setState({
                data: cpData
            })
        }
    }

    isChecked = (grade: string) => {
        let data: any = { ...this.state.data };
        if (data != null && data.requiredGrades) {
            if (data.requiredGrades.length > 0) {
                const mf = data.requiredGrades.find((i: string) => i === grade)
                if (mf != null && mf !== undefined) {
                    return true
                }
            }
        }

        return false
    }

    handleClassChange = (cls: any) => {
        const cpData: any = { ...this.state.data }

        if (cpData !== null) {

            if (!cpData.competitionClasses) {
                cpData.competitionClasses = []
            }

            if (cpData.competitionClasses.length > 0) {
                const index = cpData.competitionClasses.findIndex((i: any) => i.competitionClass.id === cls.id)
                if (index !== -1) {
                    cpData.competitionClasses.splice(index, 1)
                } else {
                    cpData.competitionClasses.push({ competitionClass: cls })
                }
            } else {
                cpData.competitionClasses = [{ competitionClass: cls }]
            }

            this.setState({
                data: cpData
            })
        }
    }

    isClassChecked = (classId: string) => {
        let data: any = { ...this.state.data };
        if (data != null && data.competitionClasses) {
            if (data.competitionClasses.length > 0) {
                const mf = data.competitionClasses.find((i: any) => i.competitionClass.id === classId)
                if (mf != null && mf !== undefined) {
                    return true
                }
            }
        }

        return false
    }

    breedsChanged(breeds: any[]) {
        let data: any = { ...this.state.data }
        data.breeds = [...breeds]
        this.setState({ data: data })
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <Modal id="bis" size="lg" isOpen={this.props.open}>
                        <ModalHeader toggle={() => {
                            this.setState({ data: null, previewMode: true })
                            this.props.action()
                        }}>{this.props.add ? "Add Custom BIS Show" : "Edit Custom BIS Show"}</ModalHeader>
                        <ModalBody>
                            <div>
                                <div className="row">
                                    {!this.props.add && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                        <td><span onClick={() => this.setState({ previewMode: !this.state.previewMode })} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                            className="c-light-blue-500 ti-pencil"></i></span>
                                        </td>
                                    }
                                </div>
                                <div className="row">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="title">Title*</label>
                                        <input id="title" name="title"
                                            className="form-control"
                                            disabled={this.setMode()}
                                            value={this.state.data && this.state.data.title && this.state.data.title}
                                            onChange={(e: any) => this.changeData(e)} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="judge">Judge*</label>

                                        <StaffSearch action={(e: any) => this.changeJudge(e)} data={this.state.data} type="judge"
                                            suggestions={this.props.judges} disabled={this.setMode()} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="approxStartTime">Approximate Start Time </label>
                                        <input id="approxStartTime" name="approxStartTime" className="form-control"
                                            disabled={this.setMode()}
                                            value={this.state.data && this.state.data.approxStartTime} onChange={(e) => this.changeData(e)}
                                            maxLength={2000} />
                                    </div>
                              
                                    <div className="mb-3">
                                        <div className="checkbox checkbox-info peers ai-c">
                                            <input id="isFinished" name="isFinished" type="checkbox"
                                                disabled={this.setMode()}
                                                checked={this.state.data && this.state.data.isFinished} className="peer"
                                                onChange={(e) => this.changeData(e)} />
                                            <label className="form-label peers peer-greed js-sb ai-c" htmlFor="isFinished">
                                                <span className="peer peer-greed">Is Finished</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="checkbox checkbox-info peers ai-c">
                                            <input id="isPresentFinished" name="isPresentFinished" type="checkbox"
                                                disabled={this.setMode()}
                                                checked={this.state.data && this.state.data.isPresentFinished} className="peer"
                                                onChange={(e) => this.changeData(e)} />
                                            <label className="form-label peers peer-greed js-sb ai-c" htmlFor="isPresentFinished">
                                                <span className="peer peer-greed">Is Present Finished</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3">
                                            <label className="form-label">Required Grades</label>
                                            {this.customGrades.map((grade: any, index: number) => (
                                                <div key={index} className="checkbox checkbox-info peers ai-c">
                                                    <input id={`gradeCall:${index}`} type="checkbox"
                                                        checked={this.isChecked(grade.value)}
                                                        name={`gradeCall:${index}`} className="peer"
                                                        onChange={(ev) => { this.handleChange(grade.value) }}
                                                        disabled={this.setMode()}
                                                    />
                                                    <label htmlFor={`gradeCall:${index}`} className="form-label peers peer-greed js-sb ai-c">
                                                        <span className="peer peer-greed">{grade.label}</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col mb-3">
                                            <label className="form-label">Competition Classes</label>
                                            {this.props.dataState.classes.map((cls: any, index: number) => (
                                                <div key={index} className="checkbox checkbox-info peers ai-c">
                                                    <input id={`clsCall:${index}`} type="checkbox"
                                                        checked={this.isClassChecked(cls.id)}
                                                        name={`clsCall:${index}`} className="peer"
                                                        onChange={(ev) => { this.handleClassChange(cls) }}
                                                        disabled={this.setMode()}
                                                    />
                                                    <label htmlFor={`clsCall:${index}`} className="form-label peers peer-greed js-sb ai-c">
                                                        <span className="peer peer-greed">{cls.name}</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="col mb-3">
                                        <BreedsSearchTable title={'BIS Breeds'} breeds={this.state.data && this.state.data.breeds ? this.state.data.breeds : []} editMode={this.setMode() === false} callback={(breeds: any) => { this.breedsChanged(breeds) }} />
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {(!Helpers.isUserEventViewer(this.props.userState.user) && !this.setMode()) &&
                                <Button color="primary" disabled={this.state.disableButton} onClick={() => {

                                    this.saveChanges(this.state.data);

                                }}>Save</Button>
                            }
                            <Button color="secondary" onClick={() => {
                                this.setState({ data: null, previewMode: true })
                                this.props.action();

                            }
                            }>Close</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { dialog: state.dialog, dataState: state.competitionList, userState: state.user } },
    DialogStore.actionCreators
)(CustomBISShowsDialogComponent)
