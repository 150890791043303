import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import * as DialogStore from '../../store/DialogStore';
import * as DogListStore from '../../store/DogListStore';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ApplicationState } from "../../store";
import { NotificationData } from "../../store/DialogStore";
import RegistrationLoader from "../../components/RegistrationLoader";
import { Helpers } from "../../utility/Helpers";
import { DogGender, getDogGender } from "../../models/dog/DogEnums";
import PedigreeAbbreviationSearch from "../../components/PedigreeAbbreviationSearch";
import BreedSearch from "../../components/BreedSearch";
import DateTimePicker from '../../components/DateTimePicker';


type Props = {
    data?: any;
    dogs?: any
    isOpen?: any,
    save: Function,
    addMode: any,
    selectedIndex: any,
    canEdit: any

}

class CoupleGroupExtraRegistrationComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }
    emptyState: any = {

        breed: null,
        name: '',
        gender: DogGender.FEMALE,
        pedigreeAbbreviation: null,
        created: new Date(),
        catalogNumber: null,
        birthDate: new Date()

    }

    state: any = {
        isOpen: true,
        modal: true,
        editMode: true,
        previewMode: this.props.data && !this.props.canEdit ? true : false,
        dogData: this.props.data ? this.props.data : this.emptyState


    };
    componentDidMount() {
        this.dataChanged();
        this.props.getPedigreeAbbreviations();
        this.props.getBreeds();
    }

    dataChanged() {
        this.setState({ dogData: this.props.data ? this.props.data : this.emptyState })
    }


    toggle = () => {
        this.setState({
            modal: !this.state.modal

        });
    }

    changeData(ev: any) {

        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let dogData = { ...this.state.dogData }

        if (name == "gender") {
            dogData[name] = parseInt(value);
        } else {
            dogData[name] = value;
        }
        this.setState({
            dogData: dogData
        });

    }

    changeAbbreviation(item: any) {
        let data: any = { ...this.state.dogData };
        data.pedigreeAbbreviation = item;
        this.setState({ dogData: data })

    }

    changeBreed(item: any) {
        let data: any = { ...this.state.dogData };
        data.breed = item;
        this.setState({ dogData: data })

    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.dogData };

        data[res.name] = res.value;

        this.setState({
            dogData: data
        })
    }


    render() {
        return (
            <React.Fragment>

                <div>

                    <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.state.editMode}>

                        <ModalHeader toggle={this.props.isOpen}>{this.props.data && this.props.data.name ? "Edit " + this.props.data.name : "Add Additional Dog"}</ModalHeader>
                        <form onSubmit={(e: any) => {
                            e.preventDefault();
                            this.props.save(this.state.dogData, this.props.selectedIndex)
                        }}>
                            <ModalBody>

                                {this.state.isDataLoading && !this.state.dogData ? <RegistrationLoader /> :
                                    (
                                        <div>

                                            <div>

                                                <div className="row">
                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="name">Name *</label>
                                                        <input id="name" name="name"
                                                            required
                                                            className="form-control"
                                                            disabled={!this.props.addMode ? this.state.previewMode : this.props.canEdit}
                                                            value={this.state.dogData && this.state.dogData.name && this.state.dogData.name}
                                                            onChange={(e) => this.changeData(e)}

                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="breed">Breed *</label>
                                                        <BreedSearch action={(item: any) => this.changeBreed(item)} data={this.state.dogData}
                                                            suggestions={this.props.dogState.webBreeds} disabled={!this.props.addMode ? this.state.previewMode : this.props.canEdit}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="birthDate">Birth Date *</label>
                                                        <DateTimePicker name="birthDate" minDate=''

                                                            value={Helpers.getIsoDate(this.state.dogData.birthDate)}
                                                            maxDate={Helpers.getIsoDate(new Date())}
                                                            onChange={(e: any) => {
                                                                this.dateChange(e);

                                                            }}
                                                            disabled={!this.props.addMode ? this.state.previewMode : this.props.canEdit}


                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="gender">Gender *</label>
                                                        <select id="gender" name="gender" required className="form-control"
                                                            disabled={!this.props.addMode ? this.state.previewMode : this.props.canEdit}


                                                            defaultValue={this.state.dogData.gender} onChange={(e) => this.changeData(e)}>
                                                            {getDogGender().map((item, index) => (
                                                                <option key={index} value={item.value}>{item.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="pedigreeAbbreviation">Pedigree Abbreviation *</label>
                                                        <PedigreeAbbreviationSearch required action={(item: any) => this.changeAbbreviation(item)} data={this.state.dogData}
                                                            suggestions={this.props.dogState.pedigreeAbbreviations} disabled={!this.props.addMode ? this.state.previewMode : this.props.canEdit}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="pedigreeNumber">Pedigree Number *</label>
                                                        <input id="pedigreeNumber" name="pedigreeNumber"
                                                            required
                                                            className="form-control"
                                                            value={this.state.dogData.pedigreeNumber}
                                                            disabled={!this.props.addMode ? this.state.previewMode : this.props.canEdit}
                                                            onChange={(e) => this.changeData(e)} maxLength={2000} />
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="catalogNumber">Catalog Number</label>
                                                        <input id="catalogNumber" name="catalogNumber"
                                                            className="form-control"
                                                            value={this.state.dogData.catalogNumber}
                                                            disabled={!this.props.addMode ? this.state.previewMode : this.props.canEdit}
                                                            onChange={(e) => this.changeData(e)} maxLength={2000} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                }

                            </ModalBody>
                            <ModalFooter>
                                {(!this.props.previewMode && this.state.dogData != this.emptyState) &&
                                    <Button color="primary" type="submit">Save</Button>
                                }
                                <Button color="secondary" onClick={this.props.isOpen}>Close</Button>
                            </ModalFooter>
                        </form>
                    </Modal>

                </div>

            </React.Fragment>
        );
    }
}


const mapStateToProps = (state: ApplicationState) => {
    return {
        dataState: state.competitionList,
        userState: state.user,
        dogState: state.dogList
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getPedigreeAbbreviations: () => {
            dispatch(DogListStore.actionCreators.getPedigreeAbbreviations())
        },
        getBreeds: () => {
            dispatch(DogListStore.actionCreators.getBreeds())
        },

        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CoupleGroupExtraRegistrationComponent);
