import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { Helpers } from '../../utility/Helpers';
import { httpService } from '../../index';
import { getEmptyCustomDiscount, CompetitionDate, Coupon, CustomDiscount } from '../../models/competition/Competition';
import { BreedEntries, PriceList, PriceListEntries } from '../../models/competition/CompetitionDate';
import PriceListEntriesTable from './PriceListEntriesTable';
import DateTimePicker from '../../components/DateTimePicker';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Breed } from '../../models/dog/Breed';
import { CompetitionListState } from '../../store/CompetitionListStore';
import { DiscountType, getCustomDiscountType } from '../../models/competition/CompetitionEnums';
import CouponDialog from './CouponDialog';
import { NotificationType } from '../../store/DialogStore';
import CustomDiscountDialog from './CustomDiscountDialog';

type CompDatePriceListsProps = {
    onChange: Function;
    data: CompetitionDate;
    openCompDateEditor: Function;
    competitionList?: CompetitionListState;
    compDateId: string
}

class CompDatePriceLists extends React.Component<CompDatePriceListsProps & typeof DialogStore.actionCreators & any,
    {   
        newSelectedBreed?: Breed,
        showBreedEntry: boolean,
        breedEntry: BreedEntries | null,
        priceList: PriceList | null,
        isEdit: boolean,
        coupon: Coupon | null, 
        openDialog: boolean,
        addMode: boolean,
        disableButton: boolean,
        customDiscount: CustomDiscount | null
     }> {

    startDate: string;
    endDate: string;

    constructor(props: any) {
        super(props);
        this.state = {
            breedEntry: null,
            priceList: null,
            isEdit: false,
            showBreedEntry: false,
            coupon: null,
            openDialog: false,
            addMode: false,
            disableButton: false,
            customDiscount: null
        }

        let currentDate = new Date(Date.now());
        this.startDate = Helpers.getStartDate(currentDate);
        this.endDate = Helpers.getEndDate(currentDate, 30);
    }

    getEmptyPriceList() {
        return {
            from: new Date(this.startDate),
            to: new Date(this.endDate),
            hasAllEventsPricing: false,
            hasTaxSplit: false,
            classChangePrice: 0,
            classChangeInvoiceDescription: '',
            classChangePriceTaxPercentage: 0,
            couplesGroupsPrice: 0,
            coupleGroupsPriceTaxPercentage: 0,
            coupleGroupsInvoiceDescription: '',
            juniorSeniorHandlingPrice: 0,
            juniorSeniorHandlingInvoiceDescription: '',
            juniorSeniorHandlingPriceTaxPercentage: 0,
            dogUnregisterPrice: 0,
            dogUnregisterInvoiceDescription: '',
            dogUnregisterPriceTaxPercentage: 0,
            breedEntries: [],
            entries: []
        }
    }

    getEmptyCoupon() {
        return {

            title: '',
            created: new Date,
            validFrom: new Date,
            validTo: new Date,
            discount: 0,
            isPercentage: false,
            invoiceDescription: '',
            discountType: DiscountType.DOG_REGISTRATION,
            couponCodes: [],
            competitionDateId: this.props.data.id || ''
        }
    }

    addPriceList() {
        let newPriceList = this.getEmptyPriceList();
        this.fillPriceListEntries(newPriceList);
        this.setState({ priceList: newPriceList, isEdit: true })
    }

    getPriceList = async (id: string) => {
        let priceList = await httpService.getPriceList(id);

        if (!priceList.entries) {
            priceList.entries = [];
        }

        this.fillPriceListEntries(priceList);

        this.setState({
            priceList: priceList
        })
    }

    private fillPriceListEntries(pl: PriceList) {
        if (this.props.competitionList) {

            this.props.competitionList.classes.forEach((cls: any) => {

                let exists = pl.entries.find((itm: PriceListEntries) => itm.competitionClass.id === cls.id)

                if (!exists) {
                    pl.entries.push({
                        competitionClass: cls
                    } as PriceListEntries)
                }
            })
        }
    }

    removePriceList(pl: PriceList, index: number) {

        this.props.showDialog(`Are you sure to delete selected price list ?`, DialogStore.DialogType.Confirm, async (res: boolean) => {
            if (res === true) {
                this.props.increment();
                try {
                    if (pl.id) {
                        await httpService.priceListDelete(pl.id);
                    }

                    let cpdata: any = { ...this.props.data };
                    cpdata.priceLists.splice(index, 1);
                    this.props.onChange(cpdata);

                    this.props.decrement();
                } catch (err) {
                    alert(err.message);
                    this.props.decrement();
                }
            }
        })
    }

    addCoupon() {
        let newCoupon = this.getEmptyCoupon();
        this.setState({
            coupon: newCoupon, isEdit: true, openDialog: !this.state.openDialog, addMode: true
        })
    }

    getCoupon = async (id: string) => {
        let coupon = await httpService.getCoupon(id);

        this.setState({
            coupon: coupon,
            openDialog: !this.state.openDialog
        })
    }

    addCustomDiscount() {
        let newDiscount = getEmptyCustomDiscount(this.props.data.id || '');
        newDiscount.validFrom = new Date(this.startDate);
        newDiscount.validTo = new Date(this.endDate);

        this.setState({
            customDiscount: newDiscount, isEdit: true, openDialog: !this.state.openDialog, addMode: true
        })
    }

    getCustomDiscount = async (id: string) => {
        try {
            this.props.increment()
            let discount = await httpService.getCustomDiscount(id);

            this.setState({
                customDiscount: discount,
                openDialog: !this.state.openDialog
            })
            this.props.decrement()
        } catch(ex) {
            this.props.decrement()
            alert(ex.message)
        }
    }

    removeCustomDiscount(discount: CustomDiscount, index: number) {

        this.props.showDialog(`Are you sure to delete selected custom discount?`, DialogStore.DialogType.Confirm, async (res: boolean) => {
            if (res === true) {
                this.props.increment();
                try {
                    if (discount.id) {
                        await httpService.customDiscountDelete(discount.id);
                    }

                    let cpdata: any = { ...this.props.data };
                    cpdata.customDiscounts.splice(index, 1);
                    this.props.onChange(cpdata);
                    this.props.decrement();
                } catch (err) {
                    this.props.decrement();
                    alert(err.message);
                }
            }
        })
    }

    removeCoupon(coupon: PriceList, index: number) {

        this.props.showDialog(`Are you sure to delete selected coupon ?`, DialogStore.DialogType.Confirm, async (res: boolean) => {
            if (res) {
                try {
                    if (coupon.id) {
                        await httpService.couponDelete(coupon.id);
                    }

                    let data: any = { ...this.props.data };
                    data.coupons.splice(index, 1);
                    this.props.onChange(data);

                    this.props.decrement();
                } catch (err) {
                    alert(err.message);
                    this.props.decrement();
                }
            }
        })
    }

    closeCouponModal = () => {
        this.setState({ coupon: null, openDialog: false, isEdit: false })
    }

    closeCustomDiscountModal = () => {
        this.setState({ customDiscount: null, openDialog: false, isEdit: false })
    }

    closeModal() {
        this.setState({ priceList: null, isEdit: false })
    }

    toggleEdit = () => {
        this.setState({ isEdit: !this.state.isEdit })
    }

    entryChange(ev: any, index: number, isBreed: boolean = false) {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let val = value;

        let cd: any = { ...this.state.priceList };

        let entries = (isBreed === true) ? cd.breedEntries : cd.entries;

        let breed = entries[index];
        let entryId = (isBreed === true) ? breed.breed.id : breed.competitionClass.id;

        let tName = name.replace(`_${entryId}`, '');

        entries[index][tName] = val;

        this.setState({ priceList: cd });
    }

    removeEntry(index: number, isBreed: boolean = false) {
        let cd: any = { ...this.state.priceList };

        let entries = (isBreed === true) ? cd.breedEntries : cd.entries;

        this.props.showDialog(`Are you sure to delete entry "${entries[index].breed.name}?"`, DialogStore.DialogType.Confirm, (res: boolean) => {
            if (res === true) {
                entries.splice(index, 1);
                this.setState({ priceList: cd });
            }
        })
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let val = value;

        let at: any = { ...this.state.priceList };

        at[name] = val;

        this.setState({ priceList: at });
    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.priceList };

        if (res.name === 'to') {
            res.value = Helpers.getEndDate(new Date(res.value));
        }

        data[res.name] = res.value;

        this.setState({
            priceList: data
        })
    }

    async saveData() {
        this.props.increment();
        try {
            this.setState({ disableButton: true })
            let cp: any = { ...this.state.priceList };
            let entries = [...cp.entries];
            let breedEntries = [...cp.breedEntries];

            if (!cp.competitionDateId) {
                cp.competitionDateId = this.props.data.id;
            }
            if (!cp.hasTaxSplit) {
                entries.forEach((entry: any) => {
                    entry.basePriceDeductable = null;
                    entry.basePriceInvoiceDescription = '';
                    entry.deductablePriceInvoiceDescription = '';
                    entry.deductableTaxPercentage = null;
                });

                breedEntries.forEach((entry: any) => {
                    entry.basePriceDeductable = null;
                    entry.basePriceInvoiceDescription = '';
                    entry.deductablePriceInvoiceDescription = '';
                    entry.deductableTaxPercentage = null;
                });

                cp.entries = entries;
                cp.breedEntries = breedEntries;
            }

            let pl = await httpService.priceListAddOrUpdate(cp);

            let cpdata: any = { ...this.props.data };

            if (!cp.id) {
                cpdata.priceLists.push(pl);
            } else {
                let fndInd: number = cpdata.priceLists.findIndex((i: PriceList) => i.id === cp.id);
                if (fndInd) {
                    cpdata.priceLists[fndInd] = cp;
                }
            }

            this.props.onChange(cpdata);

            this.props.decrement();
            this.setState({ priceList: null, isEdit: false, disableButton: false });
            this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });
        } catch (err) {
            this.props.decrement();
            alert(err.message);
            this.setState({ disableButton: false })
        }
    }

    showBreedEntry() {
        this.setState({
            showBreedEntry: true,
            newSelectedBreed: (this.props.competitionList && this.props.competitionList.breeds) ? this.props.competitionList.breeds[0] : undefined
        });
    }

    closeBreedEntry() {
        this.setState({ showBreedEntry: false });
    }

    addBreedEntry() {

        if (!this.state.newSelectedBreed) {
            alert('Breed is not selected, please select one.');
            return;
        }

        let newBreedEntry = {
            breed: this.state.newSelectedBreed,
            firstDogPrice: "",
            nextDogPrice: "",
            firstDogInvoiceDescription: "",
            nextDogInvoiceDescription: "",
            usePercentageDiscount: false,
            nextDogPricePercentageDiscount: ""
        }

        let stateCopy: any = { ...this.state };

        let checkExisting = stateCopy.priceList.breedEntries.find((itm: BreedEntries) => itm.breed.id === stateCopy.newSelectedBreed.id);
        if (checkExisting) {
            alert('Breed item is already added.');
            return;
        }

        stateCopy.priceList.breedEntries.push(newBreedEntry);

        let array = [...stateCopy.priceList.breedEntries];
        array.sort(function (a: BreedEntries, b: BreedEntries) {
            if (a.breed.name < b.breed.name) { return -1; }
            if (a.breed.name > b.breed.name) { return 1; }
            return 0;
        })

        stateCopy.newSelectedBreed = null;
        stateCopy.priceList.breedEntries = array;

        this.setState(stateCopy);
        this.closeBreedEntry();
    }

    changeNewSelectedBreed(ev: any) {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        let val = value;

        if (this.props.competitionList) {
            let breed = this.props.competitionList.breeds.find((itm: any) => itm.id == val);

            if (breed) {
                this.setState({ newSelectedBreed: breed });
            }
        }
    }

    updateDate(u: boolean) {
        if (u) {
            this.props.onChange()
        }
        this.closeCouponModal()
    }

    updateCustomDiscountData(u: boolean) {
        if (u) {
            this.props.onChange()
        }
        this.closeCustomDiscountModal()
    }

    render() {
        return (
            <div>
                {this.props.data &&
                    <div>
                        {this.props.data.priceLists &&
                            <div>
                                <div>
                                    <h5>Invoice Information
                                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                            <span
                                                onClick={() => { this.props.openCompDateEditor(this.props.data, false) }}
                                                className="icon-holder cur-p"
                                                style={{ fontSize: "16px", paddingLeft: "10px" }}>
                                                <i className="c-light-blue-500 ti-pencil"></i>
                                            </span>
                                        }
                                    </h5>
                                    <div className="row">
                                        <div className="mb-1 col-md-4">Invoice Issuer:</div>
                                        <div className="mb-1 col-md-8">{(this.props.data.invoiceIssuer) ? this.props.data.invoiceIssuer.issuer : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-1 col-md-4">Invoice Information:</div>
                                        <div className="mb-1 col-md-8">{(this.props.data.invoiceInformation) ? this.props.data.invoiceInformation.description : '-'}-- 
                                            {(this.props.data.invoiceInformation) ? this.props.data.invoiceInformation.invoicePrefix : '-'} /
                                            {(this.props.data.invoiceInformation) ? this.props.data.invoiceInformation.invoiceNumber : '-'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-1 col-md-4">Invoice Location, Date:</div>
                                        <div className="mb-1 col-md-8">{this.props.data.invoiceLocation}, {this.props.data.invoiceDate}</div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-1 col-md-4">Date of Service:</div>
                                        <div className="mb-1 col-md-8">{this.props.data.dateOfService}</div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-1 col-md-4">EM:</div>
                                        <div className="mb-1 col-md-8">{this.props.data.em}</div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-1 col-md-4">VAT Clause:</div>
                                        <div className="mb-1 col-md-8">{this.props.data.vatClause}</div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-1 col-md-4">Additional Invoice Data:</div>
                                        <div className="mb-1 col-md-8">{this.props.data.invoiceData}</div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-1 col-md-4">Include In All Events Price Calculation:</div>
                                        <div className="mb-1 col-md-8">{Helpers.booleanToString(this.props.data.includeInAllEventsPriceCalculation)}</div>
                                    </div>
                                </div>

                                <br />
                                <br />

                                <div>
                                    <h5>Price Lists</h5>
                                    {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                        <div style={{ float: "right" }}>
                                            <label className="cur-p mL-10">
                                                <a href="#" onClick={(e: any) => {
                                                    e.preventDefault()
                                                    this.addPriceList()
                                                }}>
                                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                </a>
                                            </label>
                                            <br /><br />
                                        </div>
                                    }
                                    {!this.state.priceList && this.props.data.priceLists.length > 0 ?
                                        <div>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">From</th>
                                                        <th scope="col">To</th>
                                                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                            <th style={{ textAlign: "right" }} scope="col">Action</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody className="dd-container">
                                                    {this.props.data.priceLists.map((price: any, index: number) => (
                                                        <tr key={price.id}>
                                                            <td style={{ color: "grey" }} className="cur-p" onClick={() => this.getPriceList(price.id)}>
                                                                {index + 1}
                                                            </td>
                                                            <td style={{ color: "grey" }} className="cur-p" onClick={() => this.getPriceList(price.id)}>
                                                                {Helpers.getLocalDateTimeString(price.from || "")}
                                                            </td>
                                                            <td style={{ color: "grey" }} className="cur-p" onClick={() => this.getPriceList(price.id)}>
                                                                {Helpers.getLocalDateTimeString(price.to || "")}
                                                            </td>
                                                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                                <td style={{ color: "grey" }}>
                                                                    <a style={{ float: "right" }} href="#" onClick={(e) => {
                                                                        e.preventDefault()
                                                                        this.removePriceList(price, index);
                                                                    }}>
                                                                        <span style={{ fontSize: "16px" }} className="icon-holder">
                                                                            <i className="c-light-blue-500 ti-trash"></i>
                                                                        </span>
                                                                    </a>
                                                                </td>
                                                            }
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                        :
                                        <div style={{ color: "grey" }}>No price lists.</div>
                                    }

                                    <br />

                                    {(this.props.data.coupons.length < 1 && this.props.data.priceLists.length < 1) ?
                                        <hr />
                                        : (
                                            <br />
                                        )
                                    }
                                    <br />
                                    <h5>Coupons</h5>
                                    {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                        <div style={{ float: "right" }}>
                                            <label className="cur-p mL-10">
                                                <a href="#" onClick={(e: any) => {
                                                    e.preventDefault()
                                                    this.addCoupon()
                                                }}>
                                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                </a>
                                            </label>

                                        </div>}
                                    {this.props.data.coupons && this.props.data.coupons.length > 0 ?
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Title</th>
                                                    {/* TODO uncomment when delete method is implemented */}
                                                    {/*<th style={{ textAlign: "right" }} scope="col">Action</th>*/}
                                                </tr>
                                            </thead>
                                            <tbody className="dd-container">
                                                {this.props.data.coupons && this.props.data.coupons.map((coupon: any, index: number) => (
                                                    <tr key={coupon.id}>
                                                        <td style={{ color: "grey" }} className="cur-p" onClick={() => this.getCoupon(coupon.id)}>
                                                            {index + 1}
                                                        </td>
                                                        <td style={{ color: "grey" }} className="cur-p" onClick={() => this.getCoupon(coupon.id)}>
                                                            {coupon.title}
                                                        </td>
                                                        {/*<td style={{ color: "grey" }}>*/}
                                                        {/*    <a style={{ float: "right" }} href="#" onClick={(e) => {*/}
                                                        {/*        e.preventDefault()*/}
                                                        {/*        this.removeCoupon(coupon, index);*/}
                                                        {/*    }}>*/}
                                                        {/*        <span style={{ fontSize: "16px" }} className="icon-holder">*/}
                                                        {/*            <i className="c-light-blue-500 ti-trash"></i>*/}
                                                        {/*        </span>*/}
                                                        {/*    </a>*/}
                                                        {/*</td>*/}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        :
                                        <div style={{ color: "grey" }}>No coupons.</div>
                                    }

                                    <br />

                                    {/*custom discounts*/}
                                    <h5>Custom Discounts</h5>
                                    {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                        <div style={{ float: "right" }}>
                                            <label className="cur-p mL-10">
                                                <a href="#" onClick={(e: any) => {
                                                    e.preventDefault()
                                                    this.addCustomDiscount()
                                                }}>
                                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                </a>
                                            </label>

                                        </div>}
                                    {this.props.data.customDiscounts && this.props.data.customDiscounts.length > 0 ?
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">From</th>
                                                    <th scope="col">To</th>
                                                    {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                        <th style={{ textAlign: "right" }} scope="col">Action</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody className="dd-container">
                                                {this.props.data.customDiscounts && this.props.data.customDiscounts.map((discount: any, index: number) => (
                                                    <tr key={discount.id}>
                                                        <td style={{ color: "grey" }} className="cur-p" onClick={() => this.getCustomDiscount(discount.id)}>
                                                            {index + 1}
                                                        </td>
                                                        <td style={{ color: "grey" }} className="cur-p" onClick={() => this.getCustomDiscount(discount.id)}>
                                                            {discount.title}
                                                        </td>
                                                        <td style={{ color: "grey" }} className="cur-p" onClick={() => this.getCustomDiscount(discount.id)}>
                                                            {getCustomDiscountType(discount.discountType)}
                                                        </td>
                                                        <td style={{ color: "grey" }} className="cur-p" onClick={() => this.getCustomDiscount(discount.id)}>
                                                            {Helpers.getLocalDateTimeString(discount.validFrom || "")}
                                                        </td>
                                                        <td style={{ color: "grey" }} className="cur-p" onClick={() => this.getCustomDiscount(discount.id)}>
                                                            {Helpers.getLocalDateTimeString(discount.validTo || "")}
                                                        </td>
                                                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                            <td style={{ color: "grey" }}>
                                                                <a style={{ float: "right" }} href="#" onClick={(e) => {
                                                                    e.preventDefault()
                                                                    this.removeCustomDiscount(discount, index);
                                                                }}>
                                                                    <span style={{ fontSize: "16px" }} className="icon-holder">
                                                                        <i className="c-light-blue-500 ti-trash"></i>
                                                                    </span>
                                                                </a>
                                                            </td>
                                                        }
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        :
                                        <div style={{ color: "grey" }}>No custom discounts.</div>
                                    }
                                </div>
                            </div>
                        }

                        {this.state.coupon &&
                            <CouponDialog compDateId={this.props.data.id} data={this.state.coupon} open={this.state.coupon !== null} callback={(update: boolean) => { this.updateDate(update), this.setState({ addMode: false }) }} addMode={this.state.addMode} />}

                        {this.state.customDiscount &&
                            <CustomDiscountDialog 
                            compDateId={this.props.data.id}
                            data={this.state.customDiscount}
                            open={this.state.customDiscount !== null}
                            callback={(update: boolean) => { this.updateCustomDiscountData(update), this.setState({ addMode: false }) }} addMode={this.state.addMode} />}

                        {/*price list details*/}
                        {this.state.priceList &&
                            <Modal style={{ maxWidth: '95%', width: '100%' }} size="lg" isOpen={this.state.priceList !== undefined}>
                                <form onSubmit={(e) => { e.preventDefault(); this.saveData() }}>
                                    <ModalHeader toggle={() => { this.closeModal() }}>{this.state.isEdit ? 'Price List Edit' : 'Price List Preview'}&nbsp;
                                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                            <span onClick={this.toggleEdit}>{this.state.isEdit ?
                                                <span className="cur-p icon-holder" style={{ fontSize: "16px" }}><i className="c-light-blue-500 fa fa-search"></i></span> :
                                                <span className="cur-p icon-holder" style={{ fontSize: "16px" }}><i className="c-light-blue-500 ti-pencil"></i></span>}
                                            </span>
                                        }
                                    </ModalHeader>
                                    <ModalBody>
                                        <div style={{ color: "grey" }}>
                                            {!this.state.isEdit && <div>
                                                <div className="row">
                                                    <div className="mb-3 col-md-2">
                                                        From:
                                                        <div><b>{Helpers.getLocalDateTimeString(this.state.priceList.from)}</b></div>
                                                    </div>
                                                    <div className="mb-3 col-md-2">
                                                        To:
                                                        <div><b>{Helpers.getLocalDateTimeString(this.state.priceList.to) || ""}</b></div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 col-md-2">
                                                        Class Change Price:
                                                        <div><b>{this.state.priceList.classChangePrice}</b></div>
                                                    </div>
                                                    <div className="mb-3 col-md-2">
                                                        Class Change Invoice Description:
                                                        <div><b>{this.state.priceList.classChangeInvoiceDescription}</b></div>
                                                    </div>
                                                    <div className="mb-3 col-md-2">
                                                        Class Change Price Tax Percentage:
                                                        <div><b>{this.state.priceList.classChangePriceTaxPercentage}</b></div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 col-md-2">
                                                        Couples/Groups Price:
                                                        <div><b>{this.state.priceList.couplesGroupsPrice}</b></div>
                                                    </div>
                                                    <div className="mb-3 col-md-2">
                                                        Couples/Groups Invoice Description:
                                                        <div><b>{this.state.priceList.coupleGroupsInvoiceDescription}</b></div>
                                                    </div>
                                                    <div className="mb-3 col-md-2">
                                                        Couples/Groups Price Tax Percentage:
                                                        <div><b>{this.state.priceList.coupleGroupsPriceTaxPercentage}</b></div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 col-md-2">
                                                        Junior/Senior Handling Price:
                                                        <div><b>{this.state.priceList.juniorSeniorHandlingPrice}</b></div>
                                                    </div>
                                                    <div className="mb-3 col-md-2">
                                                        Junior/Senior Invoice Description:
                                                        <div><b>{this.state.priceList.juniorSeniorHandlingInvoiceDescription}</b></div>
                                                    </div>
                                                    <div className="mb-3 col-md-2">
                                                        Junior/Senior Handling Price Tax Percentage:
                                                        <div><b>{this.state.priceList.juniorSeniorHandlingPriceTaxPercentage}</b></div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 col-md-2">
                                                        Dog Unregister Price:
                                                        <div><b>{this.state.priceList.dogUnregisterPrice}</b></div>
                                                    </div>
                                                    <div className="mb-3 col-md-2">
                                                        Dog Unregister Invoice Description:
                                                        <div><b>{this.state.priceList.dogUnregisterInvoiceDescription}</b></div>
                                                    </div>
                                                    <div className="mb-3 col-md-2">
                                                        Dog Unregister Price Tax Percentage:
                                                        <div><b>{this.state.priceList.dogUnregisterPriceTaxPercentage}</b></div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="mb-3 col-md-2">
                                                        Has All Events Pricing:
                                                        <div><b>{Helpers.booleanToString(this.state.priceList.hasAllEventsPricing)}</b></div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 col-md-2">
                                                        Has Tax Split:
                                                        <div><b>{Helpers.booleanToString(this.state.priceList.hasTaxSplit)}</b></div>
                                                    </div>
                                                </div>
                                            </div>}

                                            {this.state.isEdit && <div>
                                                <div className="row">
                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="from">From *</label>
                                                        <DateTimePicker name="from"
                                                            value={Helpers.getIsoDate(this.state.priceList.from)}
                                                            onChange={(e: any) => {
                                                                this.dateChange(e)
                                                            }} />
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label className="form-label" htmlFor="to">To *</label>
                                                        <DateTimePicker name="to"
                                                            value={Helpers.getIsoDate(this.state.priceList.to)}
                                                            onChange={(e: any) => {
                                                                this.dateChange(e)
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="classChangePrice">Class Change Price *</label>
                                                        <input id="classChangePrice" name="classChangePrice" className="form-control"
                                                            defaultValue={this.state.priceList.classChangePrice} onChange={this.changeData}
                                                            required maxLength={5} />
                                                    </div>
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="classChangeInvoiceDescription">Class Change Invoice Description *</label>
                                                        <input id="classChangeInvoiceDescription" name="classChangeInvoiceDescription" className="form-control"
                                                            defaultValue={this.state.priceList.classChangeInvoiceDescription} onChange={this.changeData}
                                                            required maxLength={500} />
                                                    </div>
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="classChangePriceTaxPercentage">Class Change Price Tax Percentage *</label>
                                                        <input type="number" step="0.1" min={0.0} className="form-control" name="classChangePriceTaxPercentage" defaultValue={this.state.priceList.classChangePriceTaxPercentage} required onChange={this.changeData}></input>


                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="couplesGroupsPrice">Couples/Groups Price *</label>
                                                        <input id="couplesGroupsPrice" name="couplesGroupsPrice" className="form-control"
                                                            defaultValue={this.state.priceList.couplesGroupsPrice} onChange={this.changeData}
                                                            required maxLength={5} />
                                                    </div>
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="coupleGroupsInvoiceDescription">Couples/Groups Invoice Description *</label>
                                                        <input id="coupleGroupsInvoiceDescription" name="coupleGroupsInvoiceDescription" className="form-control"
                                                            defaultValue={this.state.priceList.coupleGroupsInvoiceDescription} onChange={this.changeData}
                                                            required maxLength={500} />
                                                    </div>
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="coupleGroupsPriceTaxPercentage">Couples/Groups Price Tax Percentage *</label>
                                                        <input type="number" step="0.1" className="form-control" min={0.0} name="coupleGroupsPriceTaxPercentage" defaultValue={this.state.priceList.coupleGroupsPriceTaxPercentage} required onChange={this.changeData}></input>


                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="juniorSeniorHandlingPrice">Junior/Senior Price *</label>
                                                        <input id="juniorSeniorHandlingPrice" name="juniorSeniorHandlingPrice" className="form-control"
                                                            defaultValue={this.state.priceList.juniorSeniorHandlingPrice} onChange={this.changeData}
                                                            required maxLength={5} />
                                                    </div>
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="juniorSeniorHandlingInvoiceDescription">Junior/Senior Invoice Description *</label>
                                                        <input id="juniorSeniorHandlingInvoiceDescription" name="juniorSeniorHandlingInvoiceDescription" className="form-control"
                                                            defaultValue={this.state.priceList.juniorSeniorHandlingInvoiceDescription} onChange={this.changeData}
                                                            required maxLength={500} />
                                                    </div>
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="juniorSeniorHandlingPriceTaxPercentage">Junior/Senior Price Tax Percentage *</label>
                                                        <input type="number" step="0.1" className="form-control" min={0.0} name="juniorSeniorHandlingPriceTaxPercentage" defaultValue={this.state.priceList.juniorSeniorHandlingPriceTaxPercentage} required onChange={this.changeData}></input>


                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="dogUnregisterPrice">Dog Unregister Price *</label>
                                                        <input id="dogUnregisterPrice" name="dogUnregisterPrice" className="form-control"
                                                            defaultValue={this.state.priceList.dogUnregisterPrice} onChange={this.changeData}
                                                            required maxLength={5} />
                                                    </div>
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="dogUnregisterInvoiceDescription">Dog Unregister Invoice Description *</label>
                                                        <input id="dogUnregisterInvoiceDescription" name="dogUnregisterInvoiceDescription" className="form-control"
                                                            defaultValue={this.state.priceList.dogUnregisterInvoiceDescription} onChange={this.changeData}
                                                            required maxLength={500} />
                                                    </div>
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="dogUnregisterPriceTaxPercentage">Dog Unregister Price Tax Percentage *</label>
                                                        <input type="number" step="0.1" className="form-control" min={0.0} name="dogUnregisterPriceTaxPercentage" defaultValue={this.state.priceList.dogUnregisterPriceTaxPercentage} required onChange={this.changeData}></input>


                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="checkbox checkbox-info peers ai-c">
                                                        <input id="hasAllEventsPricing" name="hasAllEventsPricing" type="checkbox"
                                                            checked={this.state.priceList.hasAllEventsPricing} className="peer"
                                                            onChange={(e) => this.changeData(e)} />
                                                        <label className="form-label peers peer-greed js-sb ai-c" htmlFor="hasAllEventsPricing">
                                                            <span className="peer peer-greed">Has All Events Pricing</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <div className="checkbox checkbox-info peers ai-c">
                                                        <input id="hasTaxSplit" name="hasTaxSplit" type="checkbox"
                                                            checked={this.state.priceList.hasTaxSplit} className="peer"
                                                            onChange={(e) => this.changeData(e)} />
                                                        <label className="form-label peers peer-greed js-sb ai-c" htmlFor="hasTaxSplit">
                                                            <span className="peer peer-greed">Has Tax Split</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>}

                                            <br />

                                            <h5 style={{ color: "grey" }}>Class related price list</h5>
                                            <PriceListEntriesTable isBreed={false}
                                                list={this.state.priceList.entries}
                                                isEdit={this.state.isEdit}
                                                hasAllEventsPricing={this.state.priceList.hasAllEventsPricing}
                                                hasTaxSplit={this.state.priceList.hasTaxSplit}
                                                entryChange={(e: any, index: number) => this.entryChange(e, index)} />

                                            <br />

                                            <h5 style={{ color: "grey" }}>Breed related price list&nbsp;
                                                {this.state.isEdit &&
                                                    <div onClick={() => { this.showBreedEntry() }} className="cur-p bgc-blue-50 c-blue-500 circle-add">+</div>
                                                }
                                            </h5>
                                            <PriceListEntriesTable isBreed={true}
                                                list={this.state.priceList.breedEntries}
                                                isEdit={this.state.isEdit}
                                                hasAllEventsPricing={this.state.priceList.hasAllEventsPricing}
                                                hasTaxSplit={this.state.priceList.hasTaxSplit}
                                                removeEntry={(index: number) => this.removeEntry(index, true)}
                                                entryChange={(e: any, index: number) => this.entryChange(e, index, true)} />

                                        </div>

                                        <Modal style={{ maxWidth: '400px', width: '100%' }} size="lg" isOpen={this.state.showBreedEntry === true}>
                                            <ModalHeader toggle={() => { this.closeBreedEntry() }}>Add breed entry</ModalHeader>
                                            <ModalBody>
                                                <label className="form-label" htmlFor="breed">Select breed</label>
                                                <select id="breed" name="breed" className="form-control" value={this.state.newSelectedBreed ? this.state.newSelectedBreed.id : ''} onChange={(e) => { this.changeNewSelectedBreed(e) }}>
                                                    {this.props.competitionList && this.props.competitionList.breeds.map((item: Breed) => <option key={item.id} value={item.id}>{item.name}</option>)}
                                                </select>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="primary" onClick={() => { this.addBreedEntry() }}>Add Entry</Button>&nbsp;&nbsp;
                                                <Button color="secondary" onClick={() => { this.closeBreedEntry() }}>Close</Button>
                                            </ModalFooter>
                                        </Modal>

                                    </ModalBody>
                                    {this.state.isEdit && <ModalFooter>
                                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                            <Button disabled={this.state.disableButton} type="submit" color="primary">Save</Button>
                                        }
                                        <Button color="secondary" onClick={() => { this.closeModal() }}>Close</Button>
                                    </ModalFooter>}
                                </form>
                            </Modal>
                        }
                        {/*end price list details*/}
                    </div>
                }
            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { dialog: state.dialog, competitionList: state.competitionList, userState: state.user } },
    DialogStore.actionCreators
)(CompDatePriceLists)
