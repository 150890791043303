import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { Helpers } from '../../utility/Helpers';
import { Sponsor } from '../../models/competition/Competition';
import { httpService } from '../../index';

type CompSponsorListProps = {
	sponsors?: Array<Sponsor>;
	onChange: Function;
	parentId: string;
}

class CompSponsorList extends React.Component<CompSponsorListProps & typeof DialogStore.actionCreators & any> {

	constructor(props: any) {
		super(props);
	}

	removeFile(item: Sponsor) {
		this.props.showDialog(`Are you sure you want to delete sponsor?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
			if (confirm && item.id) {
				this.props.increment();
				try {
					await httpService.competitionSponsorDelete(item.id);
					this.props.onChange(item, true);
					this.props.decrement();
				} catch (err) {
					this.props.decrement();
					alert(err.message);
				}
			}
		})
	}

	openEditor(data: Sponsor) {
		this.props.showSponsorDialog(data, async (sponsor: Sponsor) => {

			if (!sponsor.sponsorImage && !sponsor.file) {
				alert('No image selected.');
				return;
			}

			this.props.increment();
			try {
				let res = await httpService.competitionSponsorAddOrUpdate(sponsor);

				if (sponsor.file) {
					await httpService.competitionSponsorFileUpload(sponsor.file, res.id);
					res = await httpService.competitionSponsor(res.id);
				}

				this.props.decrement();
				this.props.onChange(res, false);
				this.props.hideSponsorDialog();

			} catch (err) {
				this.props.decrement();
				alert(err.message);
			}
		})
	}

	render() {
		return (
			<div className="picture-picker">
				{!Helpers.isUserEventViewer(this.props.userState.user) &&
				<div>Sponsors
					
						<label className="cur-p mL-10">
							<a href="#" onClick={(e) => {
								e.preventDefault()
								this.openEditor({
									competitionId: this.props.parentId,
									sponsorTitle: '',
									sponsorLink: '',
									orderOnStartNumbers: -1,
									useOnStartNumbers: false,
									created: ''
								});
							}}>
								<div className="bgc-blue-50 c-blue-500 circle-add">+</div>
							</a>
						</label>
					
					</div>
				}
					<br /><br />
					{this.props.sponsors && this.props.sponsors.map((sponsor: Sponsor, index: number) =>
						<div key={sponsor.id}>
							{!Helpers.isUserEventViewer(this.props.userState.user) &&
								<div style={{ textAlign: "right" }}>
									<span style={{ cursor: "pointer", fontSize: "16px" }} className="icon-holder" onClick={() => this.openEditor(sponsor)}><i className="c-light-blue-500 ti-pencil"></i></span>
									<span style={{ fontSize: "16px" }}> | </span>
									<a href="#" onClick={(e) => { e.preventDefault(); this.removeFile(sponsor) }}>
										<span style={{ fontSize: "16px" }} className="icon-holder">
											<i className="c-light-blue-500 ti-trash"></i>
										</span>
									</a>
								</div>
							}
							<div className="row">

								<div className="mb-1 col-md-3">Created:</div>
								<div className="mb-1 col-md-9">  {Helpers.getLocalDateTimeString(new Date(sponsor.created))}</div>

							</div>
							<div className="row">

								<div className="mb-1 col-md-3">Title:</div>
								<div className="mb-1 col-md-9">  {sponsor.sponsorTitle}</div>

							</div>
							<div className="row">

								<div className="mb-1 col-md-3">Use on start numbers:</div>
								<div className="mb-1 col-md-9">  {Helpers.booleanToString(sponsor.useOnStartNumbers)}</div>

							</div>
							<div className="row">

								<div className="mb-1 col-md-3">Start numbers order:</div>
								<div className="mb-1 col-md-9">  {sponsor.orderOnStartNumbers}</div>

							</div>

							
							<div><a href={sponsor.sponsorLink} target="_blank">Sponsor link</a></div>
							<div style={{ display: 'inline-block' }}>
								<div>
									<a href={sponsor.sponsorImage} target="_blank">
										{Helpers.isImagePath((sponsor.file) ? sponsor.file.name : sponsor.sponsorImage) && <img key={index}
											alt="sponsor"
											src={sponsor.sponsorImage}
											style={{
												maxHeight: "400px",
												maxWidth: "300px"
											}} />}

										{Helpers.isImagePath((sponsor.file) ? sponsor.file.name : sponsor.sponsorImage) === false && <span>
											{Helpers.getAttachmentName((sponsor.file) ? sponsor.file.name : sponsor.sponsorImage)}
										</span>}
									</a>
								</div>
							</div>
							<hr />
						</div>
					)}

					{this.props.sponsors && this.props.sponsors.length === 0 && <span>No sponsors.<br /><br /></span>}

				
			</div>
		)
	}

	static defaultProps: CompSponsorListProps = {
		onChange: () => { },
		sponsors: [],
		parentId: ''
	}
}

export default connect(
	(state: ApplicationState) => { return { dialog: state.dialog, userState: state.user } },
	DialogStore.actionCreators
)(CompSponsorList)
