import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';
import { Competition, CompetitionAttachment, CompetitionDate, Sponsor } from '../../models/competition/Competition';
import * as DialogStore from '../../store/DialogStore';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import { Helpers } from '../../utility/Helpers';
import { DetailsBase } from '../base/DetailsBase';
import DetailsHeader from '../../components/DetailsHeader';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import PreviewPopUpComponent from '../../components/competition/PreviewPopUp';
import { getCompetitionType, CompetitionType } from '../../models/competition/CompetitionEnums';
import CompAttachmentList, { FileWarningLevel } from "../../components/competition/CompAttachmentList";
import CompSponsorList from "../../components/competition/CompSponsorList";
import CompActions from "../../components/competition/CompActions";
import CompDateList from "../../components/competition/CompDateList";
import EventOwnersDialog from '../../components/competition/EventOwnersDialog';
import { getPaymentStatus, getPaymentStatusItems } from '../../models/registrations/Registrations';
import { getRegistrationTypes } from '../../models/registrations/RegistrationEnums';


class CompetitionDetails extends DetailsBase<Competition>{
    timer: any;

    constructor(props: any) {
        super(props);
        this.getItem = async (id: string) => {
            let res = await httpService.getCompetition(id);
            return res;
        }
        this.saveItem = async (data: Competition) => {
            return await httpService.competitionAddOrUpdate(data)
        }
        this.deleteItemCall = async (id: string) => {
            return await httpService.competitionDelete(id)
        }

        this.timer = null;
    }

    getActiveClass(item: any): string {
        return (this.props.dataState.selectedItem && item.id === this.props.dataState.selectedItem.id) ? 'active-row' : '';
    }

    toggle(tab: any) {
        if (this.state.selectedTab !== tab) {
            this.setState({
                selectedTab: tab
            });
        }

    }

    addRemoveAttachment(item: CompetitionAttachment, remove: boolean) {
        if (remove) {
            let data: any = { ...this.state.data };
            let ind = data.competitionAttachments.findIndex((i: CompetitionAttachment) => i.id === item.id);
            if (ind !== -1) {
                data.competitionAttachments.splice(ind, 1);
                this.setState({ data: data });
            }
        } else if (!remove) {
            let data: any = { ...this.state.data };
            let ind = data.competitionAttachments.findIndex((i: CompetitionAttachment) => i.id === item.id);

            if (ind === -1) {
                data.competitionAttachments.push(item);
            } else {
                data.competitionAttachments[ind] = item;
            }

            this.setState({ data: data });
        }
    }

    addRemoveSponsor(item: Sponsor, remove: boolean) {
        if (remove) {
            let data: any = { ...this.state.data };
            let ind = data.competitionSponsors.findIndex((i: Sponsor) => i.id === item.id);
            if (ind !== -1) {
                data.competitionSponsors.splice(ind, 1);
                this.setState({ data: data });
            }
        } else if (!remove) {
            let data: any = { ...this.state.data };
            let ind = data.competitionSponsors.findIndex((i: Sponsor) => i.id === item.id);

            if (ind === -1) {
                data.competitionSponsors.push(item);
            } else {
                data.competitionSponsors[ind] = item;
            }

            this.setState({ data: data });
        }
    }

    addRemoveDate(item: CompetitionDate, remove: boolean) {
        if (remove) {
            let data: any = { ...this.state.data };
            let ind = data.competitionDates.findIndex((i: CompetitionDate) => i.id === item.id);
            if (ind !== -1) {
                data.competitionDates.splice(ind, 1);
                this.setState({ data: data });
            }
        } else if (!remove) {
            let data: any = { ...this.state.data };
            let ind = data.competitionDates.findIndex((i: CompetitionDate) => i.id === item.id);

            if (!data.cometitionDates) {
                data.cometitionDates = [];
            }

            if (ind === -1) {
                data.competitionDates.push(item);
            } else {
                data.competitionDates[ind] = item;
            }

            this.setState({ data: data });
        }
    }

    updateCompData = async (compId: any) => {
        let comp = await httpService.getCompetition(compId);
        this.setState({ data: comp });
    }

    handleCatalogNumberSearchChange = (event: any) => {
        const catalogNumber = event.target.value;
        this.props.setPage(null)
        this.props.setCatalogNumber(catalogNumber)
        let status = this.props.dataState.status
        let count = this.props.dataState.count
        const email = this.props.dataState.email
        const dogOwner = this.props.dataState.dogOwner
        const dogName = this.props.dataState.dogName
        const type = this.props.dataState.compType
        const competitionDateId = this.props.dataState.compDateId
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.props.getRegistrationItems(this.props.dataState.page, this.state.data!.id, email, count, status, catalogNumber, type, competitionDateId, dogOwner, dogName);
        }, 1000);
    }

    handleOwnerNameSearchChange = (event: any) => {
        const dogOwner = event.target.value;
        this.props.setPage(null)
        this.props.setDogOwner(dogOwner)
        let status = this.props.dataState.status
        let count = this.props.dataState.count
        const catalogNumber = this.props.dataState.catalogNumber
        const email = this.props.dataState.email
        const dogName = this.props.dataState.dogName
        const type = this.props.dataState.compType
        const competitionDateId = this.props.dataState.compDateId
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.props.getRegistrationItems(this.props.dataState.page, this.state.data!.id, email, count, status, catalogNumber, type, competitionDateId, dogOwner, dogName);
        }, 1000);
    }

    handleDogNameSearchChange = (event: any) => {
        const dogName = event.target.value;
        this.props.setPage(null)
        this.props.setDogName(dogName)
        let status = this.props.dataState.status
        let count = this.props.dataState.count
        const catalogNumber = this.props.dataState.catalogNumber
        const email = this.props.dataState.email
        const dogOwner = this.props.dataState.dogOwner
        const type = this.props.dataState.compType
        const competitionDateId = this.props.dataState.compDateId
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.props.getRegistrationItems(this.props.dataState.page, this.state.data!.id, email, count, status, catalogNumber, type, competitionDateId, dogOwner, dogName);
        }, 1000);
    }

    handleEmailSearchChange = (event: any) => {
        const email = event.target.value;
        this.props.setPage(null)
        this.props.setName(email)
        let status = this.props.dataState.status
        let count = this.props.dataState.count
        const dogOwner = this.props.dataState.dogOwner
        const dogName = this.props.dataState.dogName
        const catalogNumber = this.props.dataState.catalogNumber
        const type = this.props.dataState.compType
        const competitionDateId = this.props.dataState.compDateId
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.props.getRegistrationItems(this.props.dataState.page, this.state.data!.id, email, count, status, catalogNumber, type, competitionDateId, dogOwner, dogName);
        }, 1000);

    }

    render() {
        let time: any;


        return (
            <div>
                <DetailsHeader baseUrl="event" data={this.state.data}
                    title={this.state.data ? this.state.data.title : "Competition Preview"} onDelete={() => {
                        this.deleteItem()
                    }} />

                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                    data={this.state.data}></ContentStatus>

                {!this.state.isLoading && this.state.error !== "Forbidden access." &&
                    <Nav tabs style={{ marginBottom: "16px", marginTop: "10px", cursor: "pointer" }}>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.selectedTab === 0 })}
                                onClick={() => {

                                    this.setState({ selectedTab: 0 })
                                    this.props.setPage(0)
                                    this.toggle(0)
                                    this.props.setPaymentStatus("")
                                    this.props.setCatalogNumber("")
                                    this.props.setCompType("")
                                    this.props.setCompDateId("")
                                    this.props.setName("")
                                    this.props.setDogName("")
                                    this.props.setDogOwner("")
                                    this.props.getRegistration(null)
                                }}>
                                Details
                            </NavLink>
                        </NavItem>
                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.selectedTab === 2 })}
                                    onClick={() => {
                                        this.toggle(2);
                                        this.setState({ selectedTab: 2 })
                                        this.props.setPage(0)
                                        this.props.setPaymentStatus("")
                                        this.props.setCatalogNumber("")
                                        this.props.setCompType("")
                                        this.props.setCompDateId("")
                                        this.props.setName("")
                                        this.props.setDogName("")
                                        this.props.setDogOwner("")
                                        this.props.getRegistration(null)

                                    }}
                                >
                                    Shows
                                </NavLink>
                            </NavItem>
                        }
                        <NavItem>
                            <NavLink ref="btn" disabled={this.state.selectedTab === 1}
                                className={classnames({ active: this.state.selectedTab === 1 })}
                                onClick={async (e: any) => {
                                    this.toggle(1);
                                    this.setState({ selectedTab: 1 })
                                    this.props.setPage(0)
                                    this.props.setPaymentStatus("")
                                    this.props.setCatalogNumber("")
                                    this.props.setCompType("")
                                    this.props.setCompDateId("")
                                    this.props.setName("")
                                    this.props.setDogName("")
                                    this.props.setDogOwner("")
                                    await this.props.getRegistrationItems(0, this.props.dataState.selectedItem.id, null, null, null, null, null)
                                    this.props.getCompetitionDog(null)
                                    this.props.getCountryItems()
                                    this.props.setOpenReg(false)
                                    this.props.getRegistration(null)


                                }}
                            >
                                Registrations
                            </NavLink>
                        </NavItem>
                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.selectedTab === 5 })}
                                    onClick={() => {
                                        this.toggle(5);
                                        this.setState({ selectedTab: 5 })
                                        this.props.setPage(0)
                                        this.props.setPaymentStatus("")
                                        this.props.setCatalogNumber("")
                                        this.props.setCompType("")
                                        this.props.setCompDateId("")
                                        this.props.setName("")
                                        this.props.setDogName("")
                                        this.props.setDogOwner("")
                                        this.props.getRegistration(null)

                                    }}
                                >
                                    Fee Invoice Information
                                </NavLink>
                            </NavItem>
                        }
                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.selectedTab === 4 })}
                                    onClick={() => {
                                        this.toggle(4);
                                        this.setState({ selectedTab: 4 })
                                        this.props.setPage(0)
                                        this.props.setPaymentStatus("")
                                        this.props.setCatalogNumber("")
                                        this.props.setCompType("")
                                        this.props.setCompDateId("")
                                        this.props.setName("")
                                        this.props.setDogName("")
                                        this.props.setDogOwner("")
                                        this.props.getRegistration(null)

                                    }}
                                >
                                    Sponsors
                                </NavLink>
                            </NavItem>
                        }
                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.selectedTab === 3 })}
                                    onClick={() => {
                                        this.toggle(3);
                                        this.setState({ selectedTab: 3 })
                                        this.props.setPage(0)
                                        this.props.setPaymentStatus("")
                                        this.props.setCatalogNumber("")
                                        this.props.setCompType("")
                                        this.props.setCompDateId("")
                                        this.props.setName("")
                                        this.props.setDogName("")
                                        this.props.setDogOwner("")
                                        this.props.getRegistration(null)

                                    }}
                                >
                                    Attachments
                                </NavLink>
                            </NavItem>
                        }
                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.selectedTab === 6 })}
                                    onClick={() => {
                                        this.toggle(6);
                                        this.setState({ selectedTab: 6 })
                                        this.props.setPage(0)
                                        this.props.setPaymentStatus("")
                                        this.props.setCatalogNumber("")
                                        this.props.setCompType("")
                                        this.props.setCompDateId("")
                                        this.props.setName("")
                                        this.props.setDogName("")
                                        this.props.setDogOwner("")
                                        this.props.getRegistration(null)

                                    }}
                                >
                                    Actions
                                </NavLink>
                            </NavItem>
                        }
                    </Nav>
                }

                {(this.state && this.state.data && this.props.dataState && this.state.selectedTab === 0) &&
                   <div style={{ position: "relative" }}>
                   {this.state.data.logo && (
                     <img
                       style={{ position: "absolute", top: 0, right: 0, height: "auto", maxWidth: "300px", maxHeight: "150px" }}
                       src={this.state.data.logo}
                     />
                   )}
                        <div style={{ paddingLeft: "20px"}}>
                            <div className="row">

                                <div className="mb-1 col-md-3">Created:</div>
                                <div className="mb-1 col-md-9"> {Helpers.getLocalDateTimeString(this.state.data.created)}</div>

                            </div>
                            <div className="row">
                                <div className="mb-1 col-md-3">Title:</div>
                                <div className="mb-1 col-md-9"> {this.state.data.title}</div>
                            </div>
                            <div className="row">
                                <div className="mb-1 col-md-3">Subtitle:</div>
                                <div className="mb-1 col-md-9"> {this.state.data.subtitle}</div>
                            </div>
                            <div className="row">
                                <div className="mb-1 col-md-3">Publish Date:</div>
                                <div className="mb-1 col-md-9"> {Helpers.getLocalDateTimeString(this.state.data.publishDate)}</div>
                            </div>
                            <div className="row">
                                <div className="mb-1 col-md-3">Registration From:</div>
                                <div className="mb-1 col-md-9"> {Helpers.getLocalDateTimeString(this.state.data.registrationFrom)}</div>
                            </div>
                            <div className="row">
                                <div className="mb-1 col-md-3">Registration To:</div>
                                <div className="mb-1 col-md-9"> {Helpers.getLocalDateTimeString(this.state.data.registrationTo)}</div>
                            </div>
                            <div className="row">
                                <div className="mb-1 col-md-3">Stripe Reference:</div>
                                <div className="mb-1 col-md-9"> {this.state.data.stripeReference}</div>
                            </div>
                            <div className="row">
                                <div className="mb-1 col-md-3">Stripe Account:</div>
                                <div className="mb-1 col-md-9"> {this.state.data.stripeAccount ? this.state.data.stripeAccount.name : ''}</div>
                            </div>
                            <div className="row">
                                <div className="mb-1 col-md-3">Type:</div>
                                <div className="mb-1 col-md-9"> {this.state.data.type !== "" && getCompetitionType(this.state.data.type)}</div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="mb-1 col-md-3">Address:</div>
                                <div className="mb-1 col-md-9"> {this.state.data.address}</div>
                            </div>
                            <div className="row">
                                <div className="mb-1 col-md-3">City:</div>
                                <div className="mb-1 col-md-9"> {this.state.data.city}</div>
                            </div>
                            <div className="row">
                                <div className="mb-1 col-md-3">Country:</div>
                                <div className="mb-1 col-md-9"> {this.state.data.country.name}</div>
                            </div>
                            <div className="row">
                                <div className="mb-1 col-md-3">Contact:</div>
                                <div className="mb-1 col-md-9"> {this.state.data.contact}</div>
                            </div>

                            <br />
                            {this.state.data.richText &&
                                <div>
                                    <div>Description:</div>

                                    <div style={{ border: "1px solid #e9ecef", padding: "20px" }}
                                        dangerouslySetInnerHTML={{ __html: this.state.data.richText as string }} />
                                </div>}
                            <br />
                            {Helpers.isUserInRole("", "", this.props.userState.user) &&
                                <EventOwnersDialog dataId={this.state.data && this.state.data.id} />}
                        </div>
                    </div>
                }
                {this.state.selectedTab === 1 && !this.props.dataState.openReg && (
                    <div>
                        <div style={{ display: "flex", width: "100%", gap: "20px" }}>
                            <div style={{ width: "50%", marginTop: "25px", marginBottom: "15px" }}>
                                <input
                                    className="form-control" type="text" id="emailSearch" maxLength={50}
                                    placeholder="Search by Email" value={this.props.dataState.email} onChange={this.handleEmailSearchChange} />
                            </div>
                            <div style={{ width: "50%", marginTop: "25px", marginBottom: "15px" }}>
                                <input
                                    className="form-control" type="text" id="catalogNumberSearch" maxLength={50}
                                    placeholder="Search by Catalog number" value={this.props.dataState.catalogNumber} onChange={this.handleCatalogNumberSearchChange} />
                            </div>
                        </div>
                        <div style={{ display: "flex", width: "100%", gap: "20px" }}>
                            <div style={{ width: "50%", marginBottom: "15px" }}>
                                <input
                                    className="form-control" type="text" id="dogOwnerSearch" maxLength={50}
                                    placeholder="Search by Owner" value={this.props.dataState.dogOwner} onChange={this.handleOwnerNameSearchChange} />
                            </div>
                            <div style={{ width: "50%", marginBottom: "15px" }}>
                                <input
                                    className="form-control" type="text" id="dogNameSearch" maxLength={50}
                                    placeholder="Search by Dog Name" value={this.props.dataState.dogName} onChange={this.handleDogNameSearchChange} />
                            </div>
                        </div>
                        <div style={{ display: "flex", width: "100%", gap: "20px" }}>
                            <div style={{ width: "33%", marginBottom: "15px" }}>

                                <select id="paymentStatus" name="paymentStatus"
                                    className="form-control"


                                    onChange={(e) => {
                                        clearTimeout(time);
                                        this.props.setPage(null)
                                        const status = parseInt(e.target.value)
                                        const name = this.props.dataState.email
                                        let catalogNumber = this.props.dataState.catalogNumber
                                        let type = this.props.dataState.compType
                                        let competitionDateId = this.props.dataState.compDateId
                                        let dogOwner = this.props.dataState.dogOwner
                                        let dogName = this.props.dataState.dogName
                                        if (e.target.value !== "All Statuses") {
                                            this.props.setPaymentStatus(status)

                                            this.props.getRegistrationItems(this.props.dataState.page, this.state.data!.id, name, this.props.dataState.count, (status), catalogNumber, type, competitionDateId, dogOwner, dogName)
                                        } else {
                                            this.props.setPaymentStatus(null)
                                            this.props.getRegistrationItems(null, this.state.data!.id, name, this.props.dataState.count, (null), catalogNumber, type, competitionDateId, dogOwner, dogName)
                                        }
                                    }}>

                                    <option>All Statuses</option>
                                    {getPaymentStatusItems().map((item: any, index: number) => (
                                        <option key={index} selected={item.value === this.props.dataState.status} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ width: "33%", marginBottom: "15px" }}>

                                <select id="type" name="type"
                                    className="form-control"

                                    onChange={(e) => {
                                        clearTimeout(time);
                                        this.props.setPage(null)
                                        const type = parseInt(e.target.value)
                                        const name = this.props.dataState.email
                                        let catalogNumber = this.props.dataState.catalogNumber
                                        let dogOwner = this.props.dataState.dogOwner
                                        let dogName = this.props.dataState.dogName
                                        let status = this.props.dataState.status
                                        let competitionDateId = this.props.dataState.compDateId
                                        if (type !== 0) {
                                            this.props.setCompType(type)

                                            this.props.getRegistrationItems(this.props.dataState.page, this.state.data!.id, name, this.props.dataState.count, (status), catalogNumber, type, competitionDateId, dogOwner, dogName)
                                        } else {
                                            this.props.setCompType(null)
                                            this.props.getRegistrationItems(null, this.state.data!.id, name, this.props.dataState.count, (status), catalogNumber, null, competitionDateId, dogOwner, dogName)
                                        }
                                    }} >

                                    {getRegistrationTypes().map((item: any, index: number) => (
                                        <option key={index} selected={item.value === this.props.dataState.compType} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ width: "33%", marginBottom: "15px" }}>

                                <select id="competitionDateId" name="competitionDateId"
                                    className="form-control"

                                    onChange={(e) => {
                                        clearTimeout(time);
                                        this.props.setPage(null)
                                        const competitionDateId = (e.target.value)
                                        const name = this.props.dataState.email
                                        let catalogNumber = this.props.dataState.catalogNumber
                                        let dogOwner = this.props.dataState.dogOwner
                                        let dogName =  this.props.dataState.dogName
                                        let status = this.props.dataState.status
                                        let type = this.props.dataState.compType
                                        if (e.target.value !== "") {
                                            this.props.setCompDateId(competitionDateId)

                                            this.props.getRegistrationItems(this.props.dataState.page, this.state.data!.id, name, this.props.dataState.count, (status), catalogNumber, type, competitionDateId, dogOwner, dogName)
                                        } else {
                                            this.props.setCompDateId(null)
                                            this.props.getRegistrationItems(null, this.state.data!.id, name, this.props.dataState.count, (status), catalogNumber, type, null, dogOwner, dogName)
                                        }
                                    }} >

                                    <option value=''>All Shows</option>
                                    {this.props.dataState && this.props.dataState.selectedItem && this.props.dataState.selectedItem.competitionDates && this.props.dataState.selectedItem.competitionDates.map((item: any, index: number) => (
                                        <option key={index} selected={item.id === this.props.dataState.compDateId} value={item.id}>{item.description}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                )}

                {
                    (!this.props.dataState.isLoading && !this.props.dataState.error) && this.state.selectedTab === 1 &&
                    <div>
                        <PreviewPopUpComponent data={this.props.dataState.registrations} isDataLoading={this.props.dataState.registrations ? false : true} />

                    </div>
                }


                {this.state.selectedTab === 1 && this.props.dataState.registrations && this.props.dataState.registrations.length < 1 &&
                    <div style={{ color: "grey", display: "flex", justifyContent: "center" }} className="cur-p">No
                        registrations.</div>
                }
                {(this.state.data && this.state.data.competitionDates && this.state.selectedTab === 2) &&
                    <div>
                        {!this.props.dataState.isOpen && !this.props.dataState.hasSubTab && !this.props.modal &&
                            <CompDateList
                                onChange={(item: CompetitionDate, remove: boolean) => { this.addRemoveDate(item, remove), this.updateCompData(this.state.data!.id); }}
                                compDates={this.state.data.competitionDates}
                                parentId={this.state.data.id}
                            />
                        }
                    </div>

                }
                {this.state.selectedTab === 2 && this.state.data && this.state.data.competitionDates.length < 1 &&
                    <div style={{ color: "grey", display: "flex", justifyContent: "center" }} className="cur-p">No
                        shows.</div>
                }

                {this.props && this.props.dataState.registrations && this.props.dataState.registrations.length > 0 && !this.props.dataState.openReg && !this.props.dataState.isLoading && this.props.dataState.hasMoreRegistrations && this.state.selectedTab === 1 &&

                    <div style={{
                        width: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>

                        <button type="button" className="btn cur-p btn-outline-secondary" onClick={() => {

                            let newPage = this.props.dataState.page + 1
                            this.props.setPage(newPage)
                            this.props.getMoreItems(newPage, this.props.dataState.selectedItem.id, this.props.dataState.email, this.props.dataState.count, this.props.dataState.status, this.props.dataState.catalogNumber, this.props.dataState.compType, this.props.dataState.compDateId, this.props.dataState.dogOwner, this.props.dataState.dogName)

                        }}
                        >{this.props.dataState.isLoading ? "Loading..." : "Load More"}</button>
                    </div>
                }

                {this.state.selectedTab === 3 &&

                    <div>
                        {this.state.data && <div>
                            <CompAttachmentList canEdit={true}
                                onChange={(item: CompetitionAttachment, remove: boolean) => { this.addRemoveAttachment(item, remove) }}
                                warningLevel={FileWarningLevel.Light}
                                attachments={this.state.data.competitionAttachments}
                                parentId={this.state.data.id}
                            />
                        </div>}
                        <br />
                    </div>
                }

                {this.state.selectedTab === 4 &&
                    <div>
                        {this.state.data && <div>
                            <CompSponsorList
                                onChange={(item: Sponsor, remove: boolean) => { this.addRemoveSponsor(item, remove) }}
                                sponsors={this.state.data.competitionSponsors}
                                parentId={this.state.data.id}
                            />
                        </div>}
                        <br />
                    </div>
                }

                {this.state.data && this.state.selectedTab === 5 &&
                    <div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Invoice Issuer And Information:</div>
                            <div className="mb-1 col-md-9">  {this.state.data.invoiceIssuer && this.state.data.invoiceIssuer.issuer} -- {this.state.data.invoiceInformation && this.state.data.invoiceInformation.description} {this.state.data.invoiceInformation && this.state.data.invoiceInformation.invoicePrefix} {this.state.data.invoiceInformation && " - " + this.state.data.invoiceInformation.invoiceNumber}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Fee Invoice Location:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.feeInvoiceLocation}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Fee Invoice Date:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.feeInvoiceDate}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Fee Date of Service:</div>
                            <div className="mb-1 col-md-9"> {(this.state.data.feeDateOfService)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Fee EM:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.feeEM}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Fee VAT Clause:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.feeVATClause}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Fee:</div>
                            <div className="mb-1 col-md-9"> {(this.state.data.fee)}</div>

                        </div>
                        {/*<div className="row">*/}

                        {/*    <div className="mb-1 col-md-3">Use Croatian resident discount (-15€ on all classes):</div>*/}
                        {/*    <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.state.data.useCustomPriceCalculation)}</div>*/}

                        {/*</div>*/}
                        {/*<div className="row">*/}

                        {/*    <div className="mb-1 col-md-3">Use Croatian resident discount (-10€ on Young, Open, Intermediate, Working, Champion classes):</div>*/}
                        {/*    <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.state.data.useSemiCustomPriceCalculation)}</div>*/}

                        {/*</div>*/}
                    </div>
                }

                {this.state.data && this.state.selectedTab === 6 &&
                    <div>
                        <CompActions comp={this.state.data} compId={this.state.data.id} compChanged={this.updateCompData}></CompActions>
                    </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.competitionList, userState: state.user };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: Competition) => {
            dispatch(CompetitionListStore.actionCreators.selectItem(data))
        },
        getItems: () => {
            dispatch(CompetitionListStore.actionCreators.getItems())
        },
        getRegistrationItems: (page?: any, competition?: string, email?: string, count?: number, paymentStatus?: any, catalogNumber?: any, type?: any, competitionDateId?: any, dogOwner?: string, dogName?: string) => {
            dispatch(CompetitionListStore.actionCreators.getRegistrationItems(page, competition, email, count, paymentStatus, catalogNumber, type, competitionDateId, dogOwner, dogName))
        },
        getMoreItems: (page: number, competition: string, email: string, count: number, paymentStatus: any, catalogNumber: any, type?: any, competitionDateId?: any, dogOwner?: string, dogName?: string) => {
            dispatch(CompetitionListStore.actionCreators.getMoreItems(page, competition, email, count, paymentStatus, catalogNumber, type, competitionDateId, dogOwner, dogName))
        },
        getRegistration: (id?: string) => {
            dispatch(CompetitionListStore.actionCreators.getRegistration(id))
        },
        setImageEditData: (image: any) => {
            dispatch(DialogStore.actionCreators.setImageEditData(image))
        },
        getCompetitionDog: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDog(id))
        },
        getCountryItems: () => {
            dispatch(CompetitionListStore.actionCreators.getCountryItems())
        },
        setPage: (page?: any) => {
            dispatch(CompetitionListStore.actionCreators.setPage(page))
        },
        setName: (name?: any) => {
            dispatch(CompetitionListStore.actionCreators.setName(name))
        },
        setCatalogNumber: (catalogNumber?: any) => {
            dispatch(CompetitionListStore.actionCreators.setCatalogNumber(catalogNumber))
        },
        setDogName: (dogName?: any) => {
            dispatch(CompetitionListStore.actionCreators.setDogName(dogName))
        },
        setDogOwner: (dogOwner?: any) => {
            dispatch(CompetitionListStore.actionCreators.setDogOwner(dogOwner))
        },
        setPaymentStatus: (status?: any) => {
            dispatch(CompetitionListStore.actionCreators.setPaymentStatus(status))
        },
        setCompType: (type?: any) => {
            dispatch(CompetitionListStore.actionCreators.setCompType(type))
        },
        setCompDateId: (compDateId?: any) => {
            dispatch(CompetitionListStore.actionCreators.setCompDateId(compDateId))
        },
        setOpenReg: (openReg?: any) => {
            dispatch(CompetitionListStore.actionCreators.setOpenReg(openReg))
        },
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        showNotification: (data: DialogStore.NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompetitionDetails);
