import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';

import { history, httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as InvoiceIssuerStore from '../../store/InvoiceIssuerStore';
import * as DialogStore from '../../store/DialogStore';
import { DetailsBase } from '../base/DetailsBase';
import { BreedCategory } from '../../models/dog/Breed';
import { getIssuerTypes, IssuerType } from '../../models/invoice/InvoiceEnums';
import { InvoiceIssuer } from '../../models/invoice/InvoiceIssuer';
import InvoiceInfoEdit from '../invoice-information/InvoiceInfoEdit';
import ImagePicker, { PictureWarningLevel } from '../../components/ImagePicker';
import { DialogType } from '../../store/DialogStore';



class InvoiceIssuerEdit extends DetailsBase<InvoiceIssuer> {

    codeError: string;
    propsForTrim: Array<string>;
    stampPath?: any | '';
    deletedPath: string;

    constructor(props: any) {
        super(props);

        this.codeError = '';
        this.propsForTrim = ['issuer', 'invoiceNumber'];

        this.stampPath = '';
        this.deletedPath = '';
        

        this.getItem = async (id: string) => { return await httpService.getInvoiceIssuer(id) }
        this.saveItem = async (data: InvoiceIssuer) => {
            return await httpService.addOrUpdateInvoiceIssuer(data)
        }
        this.redirectAfterSave = (id: string) => {
            history.replace(`/invoice-issuer/details/${id}`)
        }

        this.emptyData = {
            id: '',
            issuer: '',
            created: new Date,
            issuerAddress: '',
            issuerCity: '',
            iban: '',
            personalId: '',
            taxNumber: '',
            invoiceFooter: '',
            invoiceFooter2: '',
            stampPath: '',
            type: ''
          
        }
    }

    onSubmit = async (ev: any) => {
        ev.preventDefault();

        let data: any = { ...this.state.data };

        if (this.state.data && this.state.data.id != undefined && this.deletedPath !== '') {
            try {
                await httpService.deleteInvoiceStamp(this.state.data.id);
                this.state.data.stampPath = '';

            } catch (err) {
                alert(err.message);
                return;
            }
        }
        let saved = await this.saveChanges(data as InvoiceIssuer, "invoice-issuer");

        if (this.stampPath) {
            try {
                await httpService.stampPathImageUpload(this.stampPath, saved.id);
            } catch (err) {
                alert(err.message);
                return;
            }

        }

        

        if (!this.state.isLoading && !this.state.error && this.state.data && this.state.data.id) {
            history.replace(`/invoice-issuer/details/${this.state.data.id}`);
        }
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let val = value;

        if (name === "type") {
            val = parseInt(val)
        }

        let data: any = { ...this.state.data };
        data[name] = val;

        this.whitespaceTrimProps(this.propsForTrim, data, name);

        this.setState({
            data: data
        })
    }

    onStampChanged = (fd: any) => {
        let data: any = { ...this.state.data };
        data.stampPath = fd.url;
        this.stampPath = fd.file;
        this.setState({ data: data });
    }

    deleteStamp() {
        if (this.state.data && !this.state.data.stampPath) {
            return;
        }

        this.props.showDialog(`Are you sure you want to delete stamp?`, DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {
                if (!this.stampPath && this.state.data && this.state.data.stampPath) {
                    this.deletedPath = this.state.data.stampPath;
                }

                this.stampPath = '';
                let data: any = { ...this.state.data };
                data.stampPath = '';
                this.setState({ data: data });
            }
        })
    }

    render() {
        return (
            <div>
                <h4 className="c-grey-900">{this.isNew(this.state) && !this.state.isLoading ? "Add" : "Edit"} Invoice Issuer</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                    data={this.state.data}></ContentStatus>
                <br />

              
                {(this.state && this.state.data) &&
                    
                    <form onSubmit={this.onSubmit}>
                      
                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="issuer">Issuer *</label>
                                <input id="issuer" name="issuer" required className="form-control"
                                    value={this.state.data.issuer} onChange={this.changeData} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="issuerAddress">Issuer Address *</label>
                                <input id="issuerAddress" name="issuerAddress" required className="form-control"
                                    value={this.state.data.issuerAddress} onChange={this.changeData}>

                                </input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="issuerCity">Issuer City *</label>
                                <input id="issuerCity" name="issuerCity" required className="form-control"
                                    value={this.state.data.issuerCity} onChange={this.changeData}>

                                </input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="iban">IBAN *</label>
                                <input id="iban" name="iban" required className="form-control"
                                    value={this.state.data.iban} onChange={this.changeData}>

                                </input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="personalId">Personal ID *</label>
                                <input id="personalId" name="personalId" required className="form-control"
                                    value={this.state.data.personalId} onChange={this.changeData}>

                                </input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="taxNumber">Tax Number *</label>
                                <input id="taxNumber" name="taxNumber" required className="form-control"
                                    value={this.state.data.taxNumber} onChange={this.changeData}>

                                </input>
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="invoiceFooter">Invoice Footer </label>
                                <input id="invoiceFooter" name="invoiceFooter" className="form-control"
                                    value={this.state.data.invoiceFooter || ""} onChange={this.changeData}>

                                </input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="invoiceFooter2">Invoice Footer 2</label>
                                <input id="invoiceFooter2" name="invoiceFooter2" className="form-control"
                                    value={this.state.data.invoiceFooter2 || ""} onChange={this.changeData}>

                                </input>
                            </div>
                        
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="type">Issuer Type</label>
                                <select id="type" name="type" onChange={this.changeData} className="form-control" value={this.state.data && this.state.data.type}>
                                    <option value="">-- no issuer type --</option>
                                    {getIssuerTypes().map((issuer: any) => (
                                        <option key={issuer.value} value={issuer.value}>{issuer.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Stamp</label>
                                {this.state.data.stampPath && <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.deleteStamp()
                                }}>
                                    <div style={{ paddingTop: "5px", marginLeft: "5px" }}
                                        className="bgc-blue-50 c-blue-500 circle-add">
                                        <span className="icon-holder" style={{ fontSize: "16px" }}>
                                            <i className="c-light-blue-500 ti-trash"></i>
                                        </span>
                                    </div>
                                </a>}
                                
                                <ImagePicker onChange={(res: any) => { this.onStampChanged(res) }} src={this.state.data.stampPath && this.state.data.stampPath.startsWith("data") ? this.state.data.stampPath : ("/api/invoice-issuer/stamp/" + this.state.data.stampPath)} width="200px" height="200px"
                                    required={false} warningLevel={PictureWarningLevel.Light} />
                            </div>
                       
                        </div>

                        <br />
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.invoiceIssuerList };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: InvoiceIssuer) => { dispatch(InvoiceIssuerStore.actionCreators.selectItem(data)) },
        getItems: (query: any, type?: any) => { dispatch(InvoiceIssuerStore.actionCreators.getItems(query, type)) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        setImageEditData: (image: any) => {dispatch(DialogStore.actionCreators.setImageEditData(image)) },
        showNotification: (data: DialogStore.NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceIssuerEdit);
