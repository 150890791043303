import * as React from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router';
import {httpService} from '../../index';
import {ApplicationState} from '../../store';
import * as DialogStore from '../../store/DialogStore';
import * as DogListStore from '../../store/DogListStore';
import {ListBase} from '../base/ListBase';
import {Helpers} from '../../utility/Helpers';
import ContentStatus from '../../components/ContentStatus';
import DogEdit from './DogEdit';
import DogDetails from './DogDetails';
import {Link} from 'react-router-dom';
import {DogStatus, getStatus} from "../../models/dog/DogEnums";
import { UserType } from '../../models/registered-user/RegisteredUserEnums';

class DogList extends ListBase {

    constructor(props: any, context: any) {
        super(props, context, 'dog');
        this.deleteItemCall = async (id: string) => {
            return await httpService.dogDelete(id)
        };
    }

    public componentDidMount() {
        
        this.props.getBreeds();
        this.props.getCountry();
        this.props.getSingleUser(null);
        this.props.setPage(0);
        this.props.setStatus(DogStatus.UNCONFIRMED);
        this.props.selectItem(null);
        this.props.getPedigreeAbbreviations();

        super.componentDidMount();
    }

    render() {
        let timer: any;

        return (
            <div className="row gap-20 masonry pos-r">
                <div className="masonry-item col-md-5">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <h4 className="c-grey-900 mB-20" style={{
                            float: "left",
                            textTransform: "capitalize"
                        }}>{getStatus(this.props.dataState.status)} Dogs List</h4>
                        <div style={{ float: "right", verticalAlign: "middle" }}>
                            {!Helpers.isUserDogViewer(this.props.userState.user) &&
                                <Link to='/dog/add'>
                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                </Link>
                            }
                        </div>
                        <div style={{ clear: "both" }} />
                        
                        <div style={{ display: "flex", flexDirection: "row", gap: "15px", margin: 0, padding: 0 }}>
                            <div style={{ width: "40%", marginBottom: "25px" }}>
                                <input className="form-control" type="text" id="exampleInputEmail1"
                                    maxLength={50} placeholder="Search by Dog name" onChange={(e) => {
                                        clearTimeout(timer);
                                        const profile = this.props.dataState.profile
                                        const count = this.props.dataState.count;
                                        const pageNum = this.props.dataState.page;
                                        const name = e.target.value
                                        if (name === "") {
                                            this.props.setPage(0);
                                            this.props.setName("");
                                            this.props.getItems(null, null, null, profile)
                                            this.props.selectItem(null)
                                        } else if (this.props.dataState.page > 0 && name !== "") {
                                            timer = setTimeout(() => {
                                                this.props.setPage(0);
                                                this.props.setName(name);
                                                this.props.getItems(0, name, count, profile)
                                                this.props.selectItem(null)
                                            }, 1000);
                                        } else {
                                            timer = setTimeout(() => {
                                                this.props.setName(name);
                                                this.props.getItems(pageNum, name, count, profile)
                                                this.props.selectItem(null)
                                            }, 1000);


                                        }
                                    }} />
                            </div>
                            <div style={{ width: "40%", marginBottom: "25px" }}>
                                <input
                                    className="form-control" type="text" id="profile" maxLength={50}
                                    placeholder="Search by User" onChange={(e) => {
                                        clearTimeout(timer);
                                        const profile = e.target.value
                                        const name = this.props.dataState.name
                                        const page = this.props.dataState.page;
                                        const count = this.props.dataState.count;
                                        if (profile === "") {
                                            this.props.setPage(0);
                                            this.props.setProfile(null)
                                            this.props.getItems(null, name, null, null)
                                            this.props.selectItem(null)
                                               
                                        } else if (this.props.dataState.page > 0 && profile !== "") {
                                            timer = setTimeout(() => {
                                                this.props.setPage(0);
                                                this.props.setProfile(profile)
                                                this.props.getItems(0, name, count, profile)
                                                this.props.selectItem(null)
                                            }, 1000);
                                        } else {
                                            timer = setTimeout(() => {
                                                this.props.setProfile(profile)
                                                this.props.getItems(page, name, count, profile)
                                                this.props.selectItem(null)
                                            }, 1000);
                                        }
                                    }} />
                            </div>
                        </div>

                        <div style={{width: "fit-content", display: "flex"}}>


                            <div style={{
                                width: "fit-content",
                                verticalAlign: "middle",
                                float: "right",
                                paddingRight: "15px"
                            }}>
                                <button type="button"
                                        className={this.props.dataState.dogStatus === DogStatus.UNCONFIRMED ? "btn cur-p btn-primary" : "btn cur-p btn-outline-primary"}
                                        onClick={() => {

                                            const count = this.props.dataState.count;
                                            const profile = this.props.dataState.profile;
                                            const name = this.props.dataState.name;
                                            this.props.selectItem(null);
                                            this.props.setPage(0);
                                            this.props.setStatus(DogStatus.UNCONFIRMED);
                                            this.props.getItems(0, name, count, profile)

                                        }}>Unconfirmed

                                </button>
                            </div>

                            <div style={{
                                width: "fit-content",
                                verticalAlign: "middle",
                                float: "right",
                                paddingRight: "15px"
                            }}>


                                <button type="button"
                                    className={this.props.dataState.dogStatus === DogStatus.CONFIRMED ? "btn cur-p btn-success" : "btn cur-p btn-outline-success"}
                                        onClick={() => {


                                            const count = this.props.dataState.count;
                                            const name = this.props.dataState.name;
                                            const profile = this.props.dataState.profile;
                                            this.props.setStatus(DogStatus.CONFIRMED);
                                            this.props.setPage(0);
                                            this.props.selectItem(null);
                                            this.props.getItems(0, name, count, profile)

                                        }}>
                                    Confirmed
                                </button>
                            </div>

                            <div style={{
                                width: "fit-content",
                                color: "red",
                                verticalAlign: "middle",
                                float: "right",
                                paddingRight: "15px"
                            }}>
                                <button type="button"
                                    className={this.props.dataState.dogStatus === DogStatus.REJECTED ? "btn cur-p btn-danger" : "btn cur-p btn-outline-danger"}
                                        onClick={() => {


                                            const count = this.props.dataState.count;
                                            const name = this.props.dataState.name;
                                            const profile = this.props.dataState.profile;
                                            this.props.setStatus(DogStatus.REJECTED);
                                            this.props.setPage(0);
                                            this.props.selectItem(null);
                                            this.props.getItems(0, name, count, profile)

                                        }}>
                                    Rejected
                                </button>
                            </div>
                        </div>
                        <br />
                        <br />
                        <ContentStatus isLoading={this.props.dataState.isLoading}
                            errorMessage={this.props.dataState.error}
                            data={this.props.dataState.list && this.props.dataState.list.length > 0}></ContentStatus>

                        {(!this.props.dataState.isLoading && !this.props.dataState.error && this.props.dataState.list && this.props.dataState.list.length > 0) &&
                            <>


                                <div></div>

                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Breed</th>
                                        {(!Helpers.isUserDogViewer(this.props.userState.user) && !Helpers.isUserEventViewer(this.props.userState.user)) &&
                                            <th scope="col" className="ta-r mw-actions">Actions</th>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody className="dd-container">
                                    {this.props.dataState.list.map((dog: any, index: number) => (
                                        <tr className={`${this.getActiveClass(dog)} ${Helpers.getPublishedClass(dog)}`}
                                            key={dog.id}>
                                            <td className="cur-p" onClick={() => {
                                                this.showDetails(index)
                                            }}>
                                                {index + 1}
                                            </td>
                                            <td className="cur-p" onClick={() => {
                                                this.showDetails(index)
                                            }}>
                                                {dog.name}
                                            </td>
                                            <td className="cur-p" onClick={() => {
                                                this.showDetails(index)
                                            }}>
                                                {dog.breed.name}
                                            </td>


                                            {/*TODO: uncomment when delete endpoint exists*/}

                                            <td className="ta-r">
                                                {Helpers.isUserAdmin(this.props.userState.user) &&
                                                    <Link to={`/dog/edit/${dog.id}`}>
                                                        <span style={{ fontSize: "16px" }} className="icon-holder"><i
                                                            className="c-light-blue-500 ti-pencil"></i></span>
                                                    </Link>
                                                }

                                                {/*    <span style={{ fontSize: "16px" }}> | </span>*/}
                                                {/*    <a href="#" onClick={(e) => { e.preventDefault(); this.deleteItem(index, dog.name) }}>*/}
                                                {/*        <span style={{ fontSize: "16px" }} className="icon-holder"><i className="c-light-blue-500 ti-trash"></i></span>*/}
                                                {/*    </a>*/}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                            </>
                        }
                        {this.props && this.props.dataState.list.length > 0 && !this.props.dataState.isLoading && this.props.dataState.hasMore &&
                            <div style={{
                                width: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <button type="button" className="btn cur-p btn-outline-secondary" onClick={() => {


                                    let newPage = this.props.dataState.page + 1
                                    this.props.setPage(newPage)
                                    let newCount = this.props.dataState.count
                                    let newName = (this.props.dataState.name)
                                    let profile = (this.props.dataState.profile)
                                    this.props.getMoreItems(newPage, newName, newCount, profile)
                                }}

                                >{this.props.dataState.isLoadingMore ? "Loading..." : "Load More"}</button>
                            </div>}
                    </div>


                </div>
                <div className="masonry-item col-md-7">
                    <div className="bgc-white p-20 bd cnt-scroll">

                        <Route exact path='/dog/details/:itemId?' component={DogDetails}/>
                        <Route exact path='/dog/edit/:itemId?' component={DogEdit}/>
                        <Route exact path='/dog/add' component={DogEdit}/>
                    </div>
                </div>
            </div>)
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        dataState: state.dogList,
        userState: state.user
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: any) => {
            dispatch(DogListStore.actionCreators.selectItem(data))
        },
        getItems: (page?: number, name?: string, count?: any, profile?: any) => {
            dispatch(DogListStore.actionCreators.getItems(page, name, count, profile))
        },
        setImageEditData: (image: any) => {
            dispatch(DialogStore.actionCreators.setImageEditData(image))
        },
        getMoreItems: (page?: number, name?: string, count?: number, profile?: any) => {
            dispatch(DogListStore.actionCreators.getMoreItems(page, name, count, profile))
        },
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        showNotification: (data: DialogStore.NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        getBreeds: () => {
            dispatch(DogListStore.actionCreators.getBreeds())
        },
        getUsers: (page: number, username?: string, count?: number, usertype?: number) => {
            dispatch(DogListStore.actionCreators.getUsers(page, username, count, usertype))
        },
        getCountry: () => {
            dispatch(DogListStore.actionCreators.getCountry())
        },
        setStatus: (status?: any) => {
            dispatch(DogListStore.actionCreators.setStatus(status))
        },
        setPage: (page?: any) => {
            dispatch(DogListStore.actionCreators.setPage(page))
        },
        setName: (name?: any) => {
            dispatch(DogListStore.actionCreators.setName(name))
        },
        setProfile: (profile?: any) => {
            dispatch(DogListStore.actionCreators.setProfile(profile))
        },
        getSingleUser: (id: string) => {
            dispatch(DogListStore.actionCreators.getSingleUser(id))
        },
        getPedigreeAbbreviations: () => {
            dispatch(DogListStore.actionCreators.getPedigreeAbbreviations())
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DogList);
