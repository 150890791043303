import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { Sponsor } from '../../models/competition/Competition';
import { Helpers } from '../../utility/Helpers';
import FilePicker from '../FilePicker';

type SponsorDialogProps =
    DialogStore.DialogState &
    typeof DialogStore.actionCreators &
    React.ComponentProps<any>;

export type SponsortDialogData = {
    data: Sponsor,
    callback: Function
}

class SponsorDialog extends React.PureComponent<SponsorDialogProps, { sponsor: Sponsor, showPopup: boolean, disableButton: boolean }> {

    time: any;

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.dataChanged();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.dialog.sponsorData != prevProps.dialog.sponsorData) {
            this.time = setTimeout(() => {
                this.setState({ showPopup: (this.props.dialog.sponsorData !== null), sponsor: (this.props.dialog.sponsorData) ? this.props.dialog.sponsorData.data : null, disableButton: false });
                this.clearTimeout();
            }, 0)
        }
    }

    componentWillUnmount() {
        this.clearTimeout();
    }

    private clearTimeout() {
        if (this.time) {
            clearTimeout(this.time);
            this.time = null;
        }
    }

    close(res: boolean) {
        this.time = setTimeout(() => {
            if (this.props.dialog.sponsorData.callback && res === true) {
                this.setState({ disableButton: true })
                this.props.dialog.sponsorData.callback(this.state.sponsor);
                this.setState({ disableButton: false })
                return;
            } else {
                this.props.hideSponsorDialog();
                this.setState({ disableButton: false })
            }
        }, 500)
    }

    changeData(ev: any) {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let val = value;

        let at: any = { ...this.state.sponsor };
        at[name] = val;

        this.setState({ sponsor: at });
    }

    fileChanged(file: any) {
        let data = { ...this.state.sponsor };

        if (data) {
            data.file = file.file;
            data.sponsorImage = file.name;
            this.setState({ sponsor: data });
        }
    }

    private dataChanged() {
        this.setState({ showPopup: (this.props.dialog.sponsorData !== null), sponsor: (this.props.dialog.sponsorData) ? this.props.dialog.sponsorData.data : null, disableButton: false });
    }

    render() {
        return (
            <React.Fragment>
                {
                    (this.props.dialog.sponsorData) &&
                    <div className={`modal fade ${(this.state && this.state.showPopup) ? 'show' : ''}`} aria-hidden="false">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Dialog</h5>
                                    <button type="button" onClick={() => { this.close(false) }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {this.state.sponsor && <div>
                                        {this.state.sponsor.sponsorImage &&
                                            <div>
                                                <a href={this.state.sponsor.sponsorImage} target="_blank">
                                                    {Helpers.isImagePath((this.state.sponsor.file) ? this.state.sponsor.file.name : this.state.sponsor.sponsorImage) && <img
                                                        alt="sponsor"
                                                        src={this.state.sponsor.sponsorImage}
                                                        style={{
                                                            maxHeight: "400px",
                                                            maxWidth: "300px"
                                                        }} />}

                                                    {Helpers.isImagePath((this.state.sponsor.file) ? this.state.sponsor.file.name : this.state.sponsor.sponsorImage) === false && <span>
                                                        {Helpers.getAttachmentName((this.state.sponsor.file) ? this.state.sponsor.file.name : this.state.sponsor.sponsorImage)}
                                                    </span>}
                                                </a>
                                            </div>
                                        }
                                        <br />
                                        <label className="btn btn-primary btn-sm btn-color">CHOOSE A FILE
                                            <FilePicker onChange={(item: any) => { this.fileChanged(item) }}></FilePicker>
                                        </label>
                                        <br />
                                        <br />
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="sponsorTitle">Title</label>
                                            <input id="sponsorTitle" name="sponsorTitle" className="form-control"
                                                    defaultValue={this.state.sponsor && this.state.sponsor.sponsorTitle} onChange={(e) => this.changeData(e)}
                                                maxLength={2000} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="sponsorLink">Sponsor Link</label>
                                            <input id="sponsorLink" name="sponsorLink" className="form-control"
                                                    defaultValue={this.state.sponsor && this.state.sponsor.sponsorLink} onChange={(e) => this.changeData(e)}
                                                maxLength={2000} />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="orderOnStartNumbers">Order on start numbers</label>
                                                <input id="orderOnStartNumbers" name="orderOnStartNumbers" className="form-control"
                                                    defaultValue={this.state.sponsor && this.state.sponsor.orderOnStartNumbers} onChange={(e) => this.changeData(e)}
                                                    maxLength={4} />
                                            </div>                                        
                                            <div className="mb-3">
                                                <div className="checkbox checkbox-info peers">
                                                    <input id="useOnStartNumbers" name="useOnStartNumbers" type="checkbox"
                                                        defaultChecked={this.state.sponsor && this.state.sponsor.useOnStartNumbers} className="peer"
                                                        onChange={(e) => this.changeData(e)} />
                                                    <label className="form-label peers peer-greed js-sb ai-c" htmlFor="useOnStartNumbers">
                                                        <span className="peer peer-greed">Use On Start Numbers</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => { this.close(false) }} data-bs-dismiss="modal">Cancel</button>
                                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                            <button type="button" className="btn btn-primary" onClick={() => { this.close(true) }}>Save</button>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { dialog: state.dialog, state: state.competitionList, userState: state.user } },
    DialogStore.actionCreators
)(SponsorDialog)