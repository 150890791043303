import React from "react";
import { Component } from "react";
import { httpService } from "../..";
import { Helpers } from "../../utility/Helpers";
import { TVHelpers } from "../../utility/TVHelpers";
import TVTopBar from "./TVTopBar";
import TVPagination from "./TVPagination";
import TVRingSideMenu from "./TVRingSideMenu";
import TVRingJudgeInfo from "./TVRingJudgeInfo";
import pawLogo from '../../assets/static/images/white_paw.svg';

type defaultProps = {
    data: any;
    parentId: any;
};

class ShowResults extends Component<any, any> {
    timer: any;
    currentRingIndex: any;
    currentBreedIndex: any;

    breedPages: any;

    constructor(props: any) {
        super(props);
        this.state = {
            data: this.props.data,
            currentRingIndex: 0,
            currentBreedIndex: 0,
        };
    }

    componentDidMount() {
        this.startLooping();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    startLooping = () => {
        this.timer = setInterval(() => {
            const { currentRingIndex, currentBreedIndex, data } = this.state;
            const currentRing = data.rings[currentRingIndex];

            if (currentBreedIndex + TVHelpers.breedsPerPageCount < currentRing.breeds.length) {
                this.setState((prevState: any) => ({
                    currentBreedIndex: prevState.currentBreedIndex + TVHelpers.breedsPerPageCount,
                }));
            } else {
                if (currentRingIndex + 1 < data.rings.length) {
                    this.setState((prevState: any) => ({
                        currentRingIndex: prevState.currentRingIndex + 1,
                        currentBreedIndex: 0,
                    }));
                } else {
                    clearInterval(this.timer);
                    this.fetchData()
                }
            }
        }, TVHelpers.switchTime);
    };

    getCatalogNumberFromEvaluation(title: string, results: any[]) {
        var found = results.find(item => item.evaluation === title);
        if (found)
            return found.catalogNumber;
        return "";
    }

    evaluationExists(title: string, results: any[]) {
        return results.some(item => item.evaluation === title);
    }

    async fetchData() {
        let data = await httpService.getShowResults(this.props.parentId)
        try {
            if (data) {
                this.setState({
                    currentRingIndex: 0,
                    currentBreedIndex: 0,
                    data: data,
                });
                this.startLooping();
            } else {
                this.setState({
                    currentRingIndex: 0,
                    currentBreedIndex: 0,
                });
                this.startLooping();
            }
        }

        catch (error) {
            console.error("Fetch error:", error);

            this.setState({
                currentRingIndex: 0,
                currentBreedIndex: 0,
            });
            this.startLooping();
        };
    }


    render() {

        const { currentRingIndex, currentBreedIndex, data } = this.state;

        if (!data || data.rings.length === 0) {
            return (
                <div className="tv_grey2_bg">
                    <TVTopBar title={`RESULTS:  - `}></TVTopBar>
                    <div className="tv_grey2_bg tv_main_content">
                        <div className="center_align_elements tv_color_main helvetica_bold_100" style={{ width: '100%', height: '75%' }}>NO DATA</div>
                    </div>
                </div>
            );
        }

        const currentRing = data.rings[currentRingIndex];

        this.breedPages = Array(Math.ceil(currentRing.breeds.length / 6)).fill(1);

        const breedsToShow = currentRing.breeds.slice(
            currentBreedIndex,
            currentBreedIndex + TVHelpers.breedsPerPageCount
        );

        return (
            <div className="tv_color_main_bg">
                <TVTopBar title={`RESULTS: ${data.description} - ${Helpers.getLocalDateString(data.date)}`}></TVTopBar>

                <div>
                    <TVRingSideMenu rings={data.rings} currentRingIndex={currentRingIndex}></TVRingSideMenu>                    
                    
                    <div className="table-container tv_grey2_bg tv_main_content">
                        <TVRingJudgeInfo description={currentRing.description} judge={currentRing.judge}></TVRingJudgeInfo>
                        {breedsToShow.length === 0
                            ? <div className="center_align_elements tv_color_main helvetica_bold_100" style={{ width: '100%', height: '75%' }}>EMPTY RING</div>
                            :
                            <div className="tv1506height">
                                <table className="schedule-table tv_main_table">
                                    <thead className="tv_grey_bg tv_white helvetica_regular_60 tv150height">
                                        <tr className="table-header">
                                            <th className="base_border padding_left64 tv_result_breed">Breed</th>
                                            <td className="base_border line_height1 text_align_center tv290width">BOB</td>
                                            <td className="base_border line_height1 text_align_center tv290width">BOS</td>
                                            <td className="base_border line_height1 text_align_center tv290width">JBOB</td>
                                            <td className="base_border line_height1 text_align_center tv290width">VBOB</td>
                                            <td className="base_border line_height1 text_align_center tv290width">PUPPY</td>
                                            <td className="base_border line_height1 text_align_center tv290width">M PUPPY</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {breedsToShow.map((item: any, index: any) => (
                                            <tr key={index} className="table-row tv_white helvetica_bold_60">
                                                <td className="base_border padding_left64 tv_result_breed tv226height">
                                                    <div style={{ display: 'flex' }}>
                                                        <div>
                                                            <p className="tv_text_no_wrap line_height1 tv_result_p_breed flex1">{item.breedName}</p>
                                                            <p className="tv_text_no_wrap line_height1 tv_result_p_breed flex1">{item.breedEnglishName}</p>
                                                        </div>
                                                        <div className="center_align_elements padding_right40" style={{ width: '2.55vw' }}>
                                                            <img className="tv_img_breed"
                                                                src={pawLogo} alt="" hidden={!TVHelpers.displayImage(item.breedInProgress, item.breedFinished)}></img>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="base_border line_height1 tv290width tv226height">
                                                    <div className={`${this.evaluationExists(TVHelpers.bobTitle, item.results) ? 'bob-result-box' : ''} center_align_elements`}>
                                                        {this.getCatalogNumberFromEvaluation(TVHelpers.bobTitle, item.results)}
                                                    </div>
                                                </td>
                                                <td className="base_border line_height1 tv290width tv226height">
                                                    <div className={`${this.evaluationExists(TVHelpers.bosTitle, item.results) ? 'result-box' : ''} center_align_elements`}>
                                                        {this.getCatalogNumberFromEvaluation(TVHelpers.bosTitle, item.results)}
                                                    </div>
                                                </td>
                                                <td className="base_border line_height1 tv290width tv226height">
                                                    <div className={`${this.evaluationExists(TVHelpers.jbobTitle, item.results) ? 'result-box' : ''} center_align_elements`}>
                                                        {this.getCatalogNumberFromEvaluation(TVHelpers.jbobTitle, item.results)}
                                                    </div>
                                                </td>
                                                <td className="base_border line_height1 tv290width tv226height">
                                                    <div className={`${this.evaluationExists(TVHelpers.vbobTitle, item.results) ? 'result-box' : ''} center_align_elements`}>
                                                        {this.getCatalogNumberFromEvaluation(TVHelpers.vbobTitle, item.results)}
                                                    </div>
                                                </td>
                                                <td className="base_border line_height1 tv290width tv226height">
                                                    <div className={`${this.evaluationExists(TVHelpers.puppyTitle, item.results) ? 'result-box' : ''} center_align_elements`}>
                                                        {this.getCatalogNumberFromEvaluation(TVHelpers.puppyTitle, item.results)}
                                                    </div>
                                                </td>
                                                <td className="base_border line_height1 tv290width tv226height">
                                                    <div className={`${this.evaluationExists(TVHelpers.minorPuppyTitle, item.results) ? 'result-box' : ''} center_align_elements`}>
                                                        {this.getCatalogNumberFromEvaluation(TVHelpers.minorPuppyTitle, item.results)}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                        <TVPagination pages={this.breedPages} currentIndex={currentBreedIndex / TVHelpers.breedsPerPageCount} marginLeft={10.42} fetchTime={data.fetchTime}></TVPagination>
                    </div>
                </div>

            </div>
        );
    }
}


export default (ShowResults)