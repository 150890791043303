import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';

import * as DialogStore from '../../store/DialogStore';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as InvoiceIssuerStore from '../../store/InvoiceIssuerStore';
import { DetailsBase } from '../base/DetailsBase';
import DetailsHeader from '../../components/DetailsHeader';
import { Helpers } from '../../utility/Helpers';
import { getIssuerType } from '../../models/invoice/InvoiceEnums';
import { InvoiceIssuer } from '../../models/invoice/InvoiceIssuer';


class InvoiceInformationDetails extends DetailsBase<InvoiceIssuer> {

    constructor(props: any) {
        super(props);
        this.getItem = async (id: string) => {
            let res = await httpService.getInvoiceIssuer(id);
            return res;
        }

    }

    render() {
        return (
            <div>
                <DetailsHeader baseUrl="invoice-issuer" data={this.state.data}
                    title={this.state.data ? this.state.data.issuer : "Invoice Issuer Preview"}
                    onDelete={() => {
                        this.deleteItem()
                    }} />

                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                    data={this.state.data}></ContentStatus>
                {this.state && this.props.dataState && this.state.data &&
                    <div>
                        {this.state.data.stampPath &&
                            <div style={{ position: "fixed", right: "50px" }}>
                                <img style={{ maxHeight: "250px", width: "250px" }} src={this.state.data.stampPath ? "/api/invoice-issuer/stamp/" + this.state.data.stampPath : ""}></img>
                            </div>
                        }

                        <div className="row">

                            <div className="mb-1 col-md-3">Issuer:</div>
                            <div className="mb-1 col-md-6"> {(this.state.data.issuer)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Issuer Address:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.issuerAddress}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Issuer City:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.issuerCity}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">IBAN:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.iban}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Personal ID:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.personalId}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Tax Number:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.taxNumber}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Invoice Footer</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.invoiceFooter}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Invoice Footer 2:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.invoiceFooter2}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Issuer Type:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && getIssuerType(this.state.data.type)}</div>

                        </div>
                   
                        <br />

                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.invoiceIssuerList };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: InvoiceIssuer) => { dispatch(InvoiceIssuerStore.actionCreators.selectItem(data)) },
        getItems: (query: any, type: any) => { dispatch(InvoiceIssuerStore.actionCreators.getItems(query, type)) },
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: DialogStore.NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceInformationDetails);
