import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';

import { history, httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as BreedCategoryStore from '../../store/BreedCategoryStore';
import * as DialogStore from '../../store/DialogStore';
import { DetailsBase } from '../base/DetailsBase';
import { BreedCategory } from '../../models/dog/Breed';



class BreedCategoryEdit extends DetailsBase<BreedCategory> {

    codeError: string;
    propsForTrim: Array<string>;

    constructor(props: any) {
        super(props);

        this.codeError = '';
        this.propsForTrim = ['engName', 'name'];

        this.getItem = async (id: string) => { return await httpService.getBreedCategory(id) }
        this.saveItem = async (data: BreedCategory) => {
            return await httpService.breedCategoryAddOrUpdate(data)
        }
        this.redirectAfterSave = (id: string) => {
            history.replace(`/fci-category/details/${id}`)
        }

        this.emptyData = {
            croatianName: '',
            germanName: '',
            engName: '',
            fci: 1,
            name: '',
            created: new Date

        }
    }

    onSubmit = (ev: any) => {
        ev.preventDefault();

        let data: any = { ...this.state.data };

        this.trimProps(this.propsForTrim, data);

        this.saveChanges(data as BreedCategory);
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let val = value;

        if (name === "fci") {
            val = parseInt(val) 
        }

        let data: any = { ...this.state.data };
        data[name] = val;

        this.whitespaceTrimProps(this.propsForTrim, data, name);

        this.setState({
            data: data
        })
    }



    render() {
        return (
            <div>
                <h4 className="c-grey-900">{this.isNew(this.state) && !this.state.isLoading ? "Add" : "Edit"} FCI
                    Category</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                               data={this.state.data}></ContentStatus>
                <br />
                {(this.state && this.state.data) &&
                    <form onSubmit={this.onSubmit}>
                        
                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="name">Name *</label>
                                <input id="name" name="name" required className="form-control"
                                       defaultValue={this.state.data.name} onChange={this.changeData}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="engName">English Name *</label>
                                <input id="engName" name="engName" required className="form-control"
                                       defaultValue={this.state.data.engName} onChange={this.changeData}>

                                </input>
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="germanName">German Name *</label>
                                <input id="germanName" name="germanName" required className="form-control"
                                       defaultValue={this.state.data.germanName} onChange={this.changeData}>

                                </input>
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="croatianName">Croatian Name *</label>
                                <input id="croatianName" name="croatianName" required className="form-control"
                                    defaultValue={this.state.data.croatianName} onChange={this.changeData}>

                                </input>
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="fci">FCI *</label>
                                <input type="number" id="fci" required name="fci" className="form-control"
                                       defaultValue={this.state.data.fci || ""} onChange={this.changeData}>

                                </input>
                            </div>

                            

                        </div>

                        <br />
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.breedCatList };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: BreedCategory) => { dispatch(BreedCategoryStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(BreedCategoryStore.actionCreators.getItems()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: DialogStore.NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreedCategoryEdit);
