import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';

import { history, httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as BreedStore from '../../store/BreedStore';
import * as DialogStore from '../../store/DialogStore';
import { DetailsBase } from '../base/DetailsBase';
import {Breed, BreedCategory} from '../../models/dog/Breed';
import {Role} from "../../models/registered-user/RegisteredUser";



class BreedEdit extends DetailsBase<Breed> {

    codeError: string;
    propsForTrim: Array<string>;

    constructor(props: any) {
        super(props);

        this.codeError = '';
        this.propsForTrim = ['originalName', 'englishName', 'germanName', 'bosnianName', 'frenchName', 'mark', 'doglleName', 'name', 'standard'];

        this.getItem = async (id: string) => { return await httpService.getBreed(id) }
        this.saveItem = async (data: Breed) => { return await httpService.breedAddOrUpdate(data) }
        this.redirectAfterSave = (id: string) => { history.replace(`/breed/details/${id}`) }

        this.emptyData = {
            originalName: '',
            englishName: '',
            bosnianName: '',
            frenchName: '',
            doglleName: '',
            germanName: '',
            croatianName: '',
            fci: 1,
            standard: '',
            mark: '',
            useInCatalog: false,
            displayOnWeb: false,
            created: new Date,
            name: ''

        }
    }

    


    onSubmit = (ev: any) => {
        ev.preventDefault();

        let data: any = { ...this.state.data };

        this.trimProps(this.propsForTrim, data);

        this.saveChanges(data as Breed);
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let val = value;

        let data: any = { ...this.state.data };
        
        if (name === 'fci') {
            val = (this.props.breedState.category.find((i: BreedCategory) => i.fci === parseInt(val)));
            val = val.fci
        }
        
        data[name] = val;

        this.whitespaceTrimProps(this.propsForTrim, data, name);

        this.setState({
            data: data
        })
    }



    render() {
        return (
            <div>
                <h4 className="c-grey-900">{this.isNew(this.state) && !this.state.isLoading ? "Add" : "Edit"} Breed</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error} data={this.state.data}></ContentStatus>
                <br />
                {(this.state && this.state.data) &&
                    <form onSubmit={this.onSubmit}>
                        <div className="row">

                            <div className="mb-3 col-md-3">
                                <div className="mb-3 checkbox checkbox-info peers ai-c">
                                    <input id="useInCatalog" name="useInCatalog" type="checkbox"
                                        checked={this.state.data.useInCatalog} className="peer"
                                        onChange={(e) => this.changeData(e)} />
                                    <label className="form-label peers peer-greed js-sb ai-c" htmlFor="useInCatalog">
                                        <span className="peer peer-greed">Use In Catalog</span></label>
                                </div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <div className="mb-3 checkbox checkbox-info peers ai-c">
                                    <input id="displayOnWeb" name="displayOnWeb" type="checkbox"
                                        checked={this.state.data.displayOnWeb} className="peer"
                                        onChange={(e) => this.changeData(e)} />

                                    <label className="form-label peers peer-greed js-sb ai-c" htmlFor="displayOnWeb">
                                        <span className="peer peer-greed">Display On Web</span></label>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="name">Name *</label>
                                <input id="name" name="name" required className="form-control"
                                    defaultValue={this.state.data.name} onChange={this.changeData} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="originalName">Original Name *</label>
                                <input id="originalName" name="originalName" required className="form-control"
                                    defaultValue={this.state.data.originalName} onChange={this.changeData}>

                                </input>
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="doglleName">Doglle Name *</label>
                                <input id="doglleName" name="doglleName" required className="form-control"
                                    defaultValue={this.state.data.doglleName} onChange={this.changeData}>

                                </input>
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="englishName">English Name *</label>
                                <input id="englishName" name="englishName" required className="form-control"
                                    defaultValue={this.state.data.englishName} onChange={this.changeData}>

                                </input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="frenchName">French Name *</label>
                                <input id="frenchName" name="frenchName" required className="form-control"
                                    defaultValue={this.state.data.frenchName} onChange={this.changeData}>
                                </input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="germanName">German Name *</label>
                                <input id="germanName" name="germanName" required className="form-control"
                                    defaultValue={this.state.data.germanName} onChange={this.changeData}>

                                </input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="bosnianName">Bosnian Name *</label>
                                <input id="bosnianName" name="bosnianName" required className="form-control"
                                    defaultValue={this.state.data.bosnianName} onChange={this.changeData}>

                                </input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="croatianName">Croatian Name *</label>
                                <input id="croatianName" name="croatianName" required className="form-control"
                                    defaultValue={this.state.data.croatianName} onChange={this.changeData}>

                                </input>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="mark">Mark *</label>
                                <input id="mark" name="mark" required className="form-control"
                                    defaultValue={this.state.data.mark} onChange={this.changeData}
                                    maxLength={10}
                                >


                                </input>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="standard">Standard *</label>
                                <input id="standard" name="standard" required className="form-control"
                                    defaultValue={this.state.data.standard} onChange={this.changeData}
                                    maxLength={10}>

                                </input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="fci">FCI *</label>
                                <select id="fci" name="fci" required className="form-control"
                                    defaultValue={this.state.data.fci} onChange={this.changeData}>
                                    <option value="">-- select FCI --</option>
                                    {this.props.breedState.category.map((item: BreedCategory, index: number) => (
                                        <option key={index}
                                            value={item.fci}>{item.fci} - {item.name}/{item.engName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <br />
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { breedState: state.breedList };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: Breed) => { dispatch(BreedStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(BreedStore.actionCreators.getItems()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: DialogStore.NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreedEdit);
