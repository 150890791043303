import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';
import { RegisteredUser } from '../../models/registered-user/RegisteredUser';
import * as DialogStore from '../../store/DialogStore';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as RegisteredUserStore from '../../store/RegisteredUserStore';
import { DetailsBase } from '../base/DetailsBase';
import DetailsHeader from '../../components/DetailsHeader';
import { Helpers } from '../../utility/Helpers';
import ActionHistory from '../../components/competition/ActionHistory';


class RegisteredUserDetails extends DetailsBase<RegisteredUser> {

    constructor(props: any) {
        super(props);
        this.getItem = async (id: string) => {
            let res = await httpService.getRegisteredUser(id);
            return res;
        }
        this.saveItem = async (data: RegisteredUser) => { return await httpService.registeredUserAddOrUpdate(data) }
        this.deleteItemCall = async (id: string) => { return await httpService.registeredUserDelete(id) }
    }



    render() {
        return (
            <div>

                <DetailsHeader baseUrl="registered-user" data={this.state.data}
                               title={this.state.data ? this.state.data.userName : "Registered User Preview"}
                               onDelete={() => {
                                   this.deleteItem()
                               }}/>

                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error} data={this.state.data}></ContentStatus>
                {(this.state && this.state.data) &&
                    <div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Email Confirmed:</div>
                            <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.state.data.emailConfirmed)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">First Name:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.firstName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Last Name:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.lastName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Username:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.userName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Phone Number:</div>
                            <div className="mb-1 col-md-9">  {this.state.data.phoneNumber && this.state.data.phoneNumber.prefix} {this.state.data.phoneNumber && this.state.data.phoneNumber.number}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">City:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.city}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Address:</div>
                            <div className="mb-1 col-md-9"> {this.state.data && this.state.data.address}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Country:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.country && this.state.data.country.name}</div>

                        </div>
                        <br/>
                        {Helpers.isUserInRole("", "", this.props.userState.user) &&
                            <ActionHistory dataId={this.state.data.id} type="user" />
                        }
        
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.registeredUserList, userState: state.user };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: RegisteredUser) => { dispatch(RegisteredUserStore.actionCreators.selectItem(data)) },
        getItems: (page: number, username?: string) => { dispatch(RegisteredUserStore.actionCreators.getItems(page, username)) },
        setImageEditData: (image: any) => { dispatch(DialogStore.actionCreators.setImageEditData(image)) },
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: DialogStore.NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisteredUserDetails);
