import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';

import * as DialogStore from '../../store/DialogStore';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as BreedStore from '../../store/BreedStore';
import { DetailsBase } from '../base/DetailsBase';
import DetailsHeader from '../../components/DetailsHeader';
import { Helpers } from '../../utility/Helpers';
import { Breed, BreedBase } from '../../models/dog/Breed';


class BreedDetails extends DetailsBase<Breed> {

    constructor(props: any) {
        super(props);
        this.getItem = async (id: string) => {
            let res = await httpService.getBreed(id);
            return res;
        }
       
    }



    render() {
        return (
            <div>
                <DetailsHeader baseUrl="breed" data={this.state.data}
                               title={this.state.data ? this.state.data.name : "Breed Preview"} onDelete={() => {
                    this.deleteItem()
                }}/>

                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error} data={this.state.data}></ContentStatus>
                {(this.state && this.props.dataState.breed && this.state.data) &&
                    <div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Created:</div>
                            <div className="mb-1 col-md-9"> {Helpers.getLocalDateTimeString(this.state.data.created)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Name:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.breed.name}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Original Name:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.breed.originalName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Doglle Name:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.breed.doglleName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3"> French Name:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.breed.frenchName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Bosnian Name:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.breed.bosnianName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">German Name:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.breed.germanName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">English Name:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.breed.englishName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Croatian Name:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.breed.croatianName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">FCI:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.breed.fci}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Mark:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.breed.mark}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Standard:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.breed.standard}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Use In Catalog:</div>
                            <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.props.dataState.breed.useInCatalog)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Display On Web:</div>
                            <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.props.dataState.breed.displayOnWeb)}</div>

                        </div>
              
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.breedList };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: Breed) => { dispatch(BreedStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(BreedStore.actionCreators.getItems()) },
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: DialogStore.NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreedDetails);
