import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { Helpers } from '../../utility/Helpers';
import { Sponsor } from '../../models/competition/Competition';
import { httpService } from '../../index';
import { CompDateImage } from '../../models/competition/CompetitionDate';

type CompDateImagesListProps = {
	images?: Array<CompDateImage>;
	onChange: Function;
	parentId: string;
}

class CompDateImagesList extends React.Component<CompDateImagesListProps & typeof DialogStore.actionCreators & any, any> {

	constructor(props: any) {
		super(props);

	}
	state = {
		images: this.props.images,
	}



	removeFile(item: CompDateImage) {
		this.props.showDialog(`Are you sure you want to delete this image?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
			if (confirm && item.id) {
				this.props.increment();
				try {
					await httpService.competitionDateImageDelete(item.id);
					this.props.onChange(item, true);
					this.props.decrement();
				} catch (err) {
					this.props.decrement();
					alert(err.message);
				}
			}
		})
	}

	async openEditor(data: CompDateImage) {
		this.props.showCompDateImageDialog(data, async (compDateImage: CompDateImage) => {
			if (!compDateImage.image && !compDateImage.file) {
				alert('No image selected.');
				return;
			}
			this.props.increment();
			try {
				this.setState({ disableButton: true })
				let res = await httpService.competitionDateImageAddOrUpdate(compDateImage);

				if (compDateImage.file) {
					await httpService.competitionDateImageFileUpload(compDateImage.file, res.id);
					res = await httpService.competitionDateImage(res.id);
				}
				this.props.onChange(res, false);
				this.props.hideCompDateImageDialog();
				this.props.decrement();
			} catch (err) {
				this.props.decrement();
				alert(err.message);
			}
		})
	}




	render() {
		return (
			<div className="picture-picker">

				{!Helpers.isUserEventViewer(this.props.userState.user) &&
					<div>Images

						<label className="cur-p mL-10">
							<a href="#" onClick={(e) => {
								e.preventDefault()
								this.openEditor({
									competitionDateId: this.props.parentId,
									image: '',
									orderOnStartNumbers: -1,
									useOnStartNumbers: false,

								});
							}}>
								<div className="bgc-blue-50 c-blue-500 circle-add">+</div>
							</a>
						</label>

					</div>
				}
				<br /><br />
				{this.state.images && this.state.images.map((image: CompDateImage, index: number) =>
					<><div key={image.id}>
						{!Helpers.isUserEventViewer(this.props.userState.user) &&
							<div style={{ textAlign: "right" }}>
								<span style={{ cursor: "pointer", fontSize: "16px" }} className="icon-holder" onClick={() => this.openEditor(image)}><i className="c-light-blue-500 ti-pencil"></i></span>
								<span style={{ fontSize: "16px" }}> | </span>
								<a href="#" onClick={(e) => { e.preventDefault(); this.removeFile(image); }}>
									<span style={{ fontSize: "16px" }} className="icon-holder">
										<i className="c-light-blue-500 ti-trash"></i>
									</span>
								</a>
							</div>}

						<div style={{ display: 'inline-block' }}>
							<div>
								<a href={image.image} target="_blank">
									{Helpers.isImagePath((image.file) ? image.file.name : image.image) && <img key={index}
										alt="compDateImage"
										src={image.image}
										style={{
											maxHeight: "400px",
											maxWidth: "300px"
										}} />}

									{Helpers.isImagePath((image.file) ? image.file.name : image.image) === false && <span>
										{Helpers.getAttachmentName((image.file) ? image.file.name : image.image)}
									</span>}
								</a>
							</div>
							<br />
						</div>

					</div>
						<div className="row">

							<div className="mb-1 col-md-3">Use In Generation:</div>
							<div className="mb-1 col-md-6">  {Helpers.booleanToString(image.useOnStartNumbers)}</div>

						</div><div className="row">

							<div className="mb-1 col-md-3">Start numbers order:</div>
							<div className="mb-1 col-md-6">  {image.orderOnStartNumbers}</div>

						</div><hr />
					</>

				)}

				{this.props.sponsors && this.props.sponsors.length === 0 && <span>No sponsors.<br /><br /></span>}


			</div>
		)
	}

	static defaultProps: CompDateImagesListProps = {
		onChange: () => { },
		images: [],
		parentId: ''
	}
}

export default connect(
	(state: ApplicationState) => { return { dialog: state.dialog, userState: state.user } },
	DialogStore.actionCreators
)(CompDateImagesList)
