import React from "react";
import { Component } from "react";
import { httpService } from "../..";
import { Helpers } from "../../utility/Helpers";
import { TVHelpers } from "../../utility/TVHelpers";
import TVTopBar from "./TVTopBar";
import TVPagination from "./TVPagination";
import TVRingSideMenu from "./TVRingSideMenu";
import TVRingJudgeInfo from "./TVRingJudgeInfo";
import pawLogo from '../../assets/static/images/paw.svg';

type defaultProps = {
    data: any;
    parentId: any
};

class ShowSchedule extends Component<any, any> {
    timer: any;
    currentRingIndex: any;
    currentBreedIndex: any;

    breedPages: any;

    constructor(props: any) {
        super(props);
        this.state = {
            data: this.props.data,
            currentRingIndex: 0,
            currentBreedIndex: 0,
        };
    }

    componentDidMount() {
        this.startLooping();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    startLooping = () => {
        this.timer = setInterval(() => {
            const { currentRingIndex, currentBreedIndex, data } = this.state;
            const currentRing = data.rings[currentRingIndex];

            if (currentBreedIndex + TVHelpers.breedsPerPageCount < currentRing.breeds.length) {
                this.setState((prevState: any) => ({
                    currentBreedIndex: prevState.currentBreedIndex + TVHelpers.breedsPerPageCount,
                }));
            } else {
                if (currentRingIndex + 1 < data.rings.length) {
                    this.setState((prevState: any) => ({
                        currentRingIndex: prevState.currentRingIndex + 1,
                        currentBreedIndex: 0,
                    }));
                } else {
                    clearInterval(this.timer);
                    this.fetchData()
                }
            }
        }, TVHelpers.switchTime);
    };

    async fetchData() {
        let data = await httpService.getShowSchedule(this.props.parentId)
        try {
            if (data) {
                this.setState({
                    currentRingIndex: 0,
                    currentBreedIndex: 0,
                    data: data,
                });
                this.startLooping();
            } else {
                this.setState({
                    currentRingIndex: 0,
                    currentBreedIndex: 0,
                });
                this.startLooping();
            }
        }

        catch (error) {
            console.error("Fetch error:", error);
         
            this.setState({
                currentRingIndex: 0,
                currentBreedIndex: 0,
            });
            this.startLooping();
        };
    }


    render() {

        const { currentRingIndex, currentBreedIndex, data } = this.state;

        if (!data || data.rings.length === 0) {
            return (
                <div className = "tv_grey2_bg">
                    <TVTopBar title={`SCHEDULE:  - `}></TVTopBar>
                    <div className="tv_grey2_bg tv_main_content">
                        <div className="center_align_elements tv_color_main helvetica_bold_100" style={{ width: '100%', height: '75%' }}>NO DATA</div>
                    </div>
                </div>
            );
        }

        const currentRing = data.rings[currentRingIndex];

        this.breedPages = Array(Math.ceil(currentRing.breeds.length / TVHelpers.breedsPerPageCount)).fill(1);

        const breedsToShow = currentRing.breeds.slice(
            currentBreedIndex,
            currentBreedIndex + TVHelpers.breedsPerPageCount
        );

        return (
            <div className="tv_grey2_bg" style={{ height:'100vh' }}>
                <TVTopBar title={`SCHEDULE: ${data.description} - ${Helpers.getLocalDateString(data.date)}`}></TVTopBar>

                <div>
                    <TVRingSideMenu rings={data.rings} currentRingIndex={currentRingIndex}></TVRingSideMenu>

                    <div className="table-container tv_grey2_bg tv_main_content">

                        <TVRingJudgeInfo description={currentRing.description} judge={currentRing.judge}></TVRingJudgeInfo>
                        {currentRing.ringFinished
                            ? <div className="center_align_elements tv_color_main helvetica_bold_100" style={{ width: '100%', height: '75%' }}>RING FINISHED</div>
                            : breedsToShow.length === 0
                                ? <div className="center_align_elements tv_color_main helvetica_bold_100" style={{ width: '100%', height: '75%' }}>EMPTY RING</div>
                                : <div>
                                    <div className="tv1506height">
                                        <table className="schedule-table tv_main_table">
                                            <thead className="tv_white tv_grey_bg helvetica_bold_60 tv150height">
                                                <tr className="table-header">
                                                    <th className="base_border padding_left64 tv_breed">Breed</th>
                                                    <th className="base_border line_height1 text_align_center tv_timetable">Timetable</th>
                                                    <th className="base_border line_height1 text_align_center tv_fromto">From-To</th>
                                                    <th className="base_border line_height1 text_align_center tv_total">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {breedsToShow.map((item: any, index: any) => (
                                                    <tr key={index} className="table-row tv_white helvetica_bold_60">
                                                        <td className={`base_border padding_left64 tv_breed tv226height ${TVHelpers.getActiveClass(item.breedInProgress, item.breedFinished)}`}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div>
                                                                    <p className="tv_text_no_wrap line_height1 tv_p_breed flex1">{item.breedEnglishName}</p>
                                                                    <p className="tv_text_no_wrap line_height1 tv_p_breed flex1">{item.breedName}</p>
                                                                </div>
                                                                <div className="center_align_elements padding_right40" style={{ width: '2.55vw' }}>
                                                                    <img className="tv_img_breed"
                                                                        src={pawLogo} alt="" hidden={!TVHelpers.getActiveClass(item.breedInProgress, item.breedFinished)}></img>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={`base_border line_height1 text_align_center tv_timetable tv226height ${TVHelpers.getActiveClass(item.breedInProgress, item.breedFinished)}`}>{item.approximateFrom} - {item.approximateTo}</td>
                                                        <td className={`base_border line_height1 text_align_center tv_fromto tv226height ${TVHelpers.getActiveClass(item.breedInProgress, item.breedFinished)}`}>{item.catalogNumberFrom} - {item.catalogNumberTo}</td>
                                                        <td className={`base_border line_height1 text_align_center tv_total tv226height ${TVHelpers.getActiveClass(item.breedInProgress, item.breedFinished)}`}>{item.dogsCount + item.bitchesCount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <TVPagination pages={this.breedPages} currentIndex={(currentBreedIndex / TVHelpers.breedsPerPageCount)} marginLeft={10.42} fetchTime={data.fetchTime}></TVPagination>
                                </div>
                        }                        
                    </div>
                </div>
            </div>
        );
    }
}
//style={{ height: '678px'}}>

export default (ShowSchedule)