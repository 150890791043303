import { Action, Reducer } from 'redux';
import { httpService } from '../index';
import { AppThunkAction } from './';
import { Country } from '../models/country/Country'
import { PedigreeAbbreviation } from '../models/dog/PedigreeAbbreviation';

export interface PedigreeListState {
    selectedItem: PedigreeAbbreviation | null,
    list: Array<PedigreeAbbreviation>,
    filtered: any,
    isLoading: boolean,
    error: string
}

interface SelectPedigreeAction {
    type: 'SELECT_PEDIGREE';
    item: PedigreeAbbreviation;
}

interface RequestPedigreeAction {
    type: 'REQUEST_PEDIGREE';
}

interface ReceivePedigreeAction {
    type: 'RECEIVE_PEDIGREE';
    items: Array<PedigreeAbbreviation>;
    filtered: any;
    error: '';
}

interface GetFilteredPedigreeAction {
    type: 'GET_FILTERED_PEDIGREE';
    items: any;
  
}

type KnownAction = SelectPedigreeAction | RequestPedigreeAction | ReceivePedigreeAction | GetFilteredPedigreeAction;

export const actionCreators = {
    selectItem: (item: PedigreeAbbreviation): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SELECT_PEDIGREE', item: item });
    },
    getItems: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.pedigreeList) {
            try {
                dispatch({ type: 'REQUEST_PEDIGREE' });
                let data = await httpService.pedigreeAbbreviations();
                dispatch({ type: 'RECEIVE_PEDIGREE', items: data, error: '', filtered: data });

            } catch (err) {
                dispatch({ type: 'RECEIVE_PEDIGREE', items: [], error: '', filtered: [] });
            }
        }
    },

    getFilteredAbbreviations: (data: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.pedigreeList) {
            dispatch({ type: 'GET_FILTERED_PEDIGREE', items: data });
        }
    }
}

const unloadedState: PedigreeListState = { selectedItem: null, isLoading: false, list: [], error: '', filtered: [] };

export const reducer: Reducer<PedigreeListState> = (state: PedigreeListState | undefined, incomingAction: Action): PedigreeListState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_PEDIGREE':
            return {
                selectedItem: action.item,
                list: state.list,
                isLoading: false,
                error: '',
                filtered: state.filtered
            };
        case 'REQUEST_PEDIGREE':
            return {
                selectedItem: state.selectedItem,
                list: [],
                isLoading: true,
                error: '',
                filtered: state.filtered
            };
        case 'RECEIVE_PEDIGREE':

            let si = state.selectedItem;

            if (action.items && action.items.length > 0 && !state.selectedItem) {
                si = action.items[0];
            }

            return {
                selectedItem: si,
                list: action.items,
                isLoading: false,
                error: action.error,
                filtered: action.items
            };
        case 'GET_FILTERED_PEDIGREE':
            let sitem;
            if (action.items.length > 0) {
                sitem = action.items[0]
            } else {
                sitem = state.selectedItem
            }

            return {
                list: state.list,
                isLoading: state.isLoading,
                error: state.error,
                filtered: action.items,
                selectedItem: sitem
            }

    }
    return state;
}
