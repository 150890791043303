import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { Helpers } from '../../utility/Helpers';
import FilePicker from '../FilePicker';
import { httpService } from '../../index';
import { CompetitionDate } from '../../models/competition/Competition';

type CompDateActionsProps = {
	onChange: Function;
	data: CompetitionDate;
}

class CompDateActions extends React.Component<CompDateActionsProps & typeof DialogStore.actionCreators & any> {

	constructor(props: any) {
		super(props);
	}

	removeFile(isIntro: boolean) {
		this.props.showDialog(`Are you sure you want to delete ${isIntro ? 'intro' : 'closing'} pages?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
			if (confirm && this.props.data.id) {
				this.props.increment();
				try {

					let res = null;

					if (isIntro === true) {
						res = await httpService.competitionDateIntroDelete(this.props.data.id);
					} else {
						res = await httpService.competitionDateClosingDelete(this.props.data.id);
                    }
					this.props.onChange(res);
					this.props.decrement();
				} catch (err) {
					this.props.decrement();
					alert(err.message);
				}
			}
		})
	}

	async fileChange(att: any, isIntro: boolean) {
		if (!att || (!att.name && !att.file)) {
			alert('No file selected.');
			return;
		}

		if (!Helpers.isPDF(att.file.name)) {
			alert('Please select PDF file.');
			return;
		}

		this.props.increment();
		try {

			let newAtt = null;

			if (att.file && this.props.data.id) {
				if (isIntro === true) {
					newAtt = await httpService.competitionDateIntroUpload(att.file, this.props.data.id);
				} else {
					newAtt = await httpService.competitionDateClosingUpload(att.file, this.props.data.id);
                }
			}

			this.props.decrement();
			this.props.onChange(newAtt);
			this.props.hideAttachmentDialog();

		} catch (err) {
			this.props.decrement();
			alert(err.message);
		}
    }

	render() {
		return (
			<div className="picture-picker">

				<div>
					<label className="cur-p">Introductory Pages
						{!Helpers.isUserEventViewer(this.props.userState.user) &&
							<div className="bgc-blue-50 c-blue-500 circle-add mL-10">+</div>
						}
						<FilePicker onChange={(item: any) => { this.fileChange(item, true) }} accept=".pdf"></FilePicker>
					</label>
					<br /><br />
					{this.props.data && this.props.data.introductoryPagesPath && <div>
						<div style={{ position: 'relative', display: 'inline-block' }}>
							<div>
								<a href={`api/competition-date/attachment/${this.props.data.introductoryPagesPath}`} target="_blank">
									<span>{this.props.data.introductoryPagesPath}</span>
								</a>
								<br /><br />
							</div>
							{!Helpers.isUserEventViewer(this.props.userState.user) &&
								<a style={{ position: 'absolute', top: '0', right: '-36px' }} href="#" onClick={(e) => { e.preventDefault(); this.removeFile(true) }}>
									<div style={{ paddingTop: "5px", marginLeft: "5px" }} className="bgc-blue-50 c-blue-500 circle-add">
										<span className="icon-holder" style={{ fontSize: "16px" }}>
											<i className="c-light-blue-500 ti-trash"></i>
										</span>
									</div>
								</a>
							}
						</div>
						<hr />
					</div>}
					{!this.props.data.introductoryPagesPath && <span>No introductory pages selected.<br /><br /></span>}
				</div>

				<div>
					<label className="cur-p">Closing Pages
						{!Helpers.isUserEventViewer(this.props.userState.user) &&
							<div className="bgc-blue-50 c-blue-500 circle-add mL-10">+</div>
						}
						<FilePicker onChange={(item: any) => { this.fileChange(item, false) }}></FilePicker>
					</label>
					<br /><br />
					{this.props.data.closingPagesPath && <div>
						<div style={{ position: 'relative', display: 'inline-block' }}>
							<div>
								<a href={`api/competition-date/attachment/${this.props.data.closingPagesPath}`} target="_blank">
									<span>{this.props.data.closingPagesPath}</span>
								</a>
								<br /><br />
							</div>
							{!Helpers.isUserEventViewer(this.props.userState.user) &&
								<a style={{ position: 'absolute', top: '0', right: '-36px' }} href="#" onClick={(e) => { e.preventDefault(); this.removeFile(false) }}>

									<div style={{ paddingTop: "5px", marginLeft: "5px" }} className="bgc-blue-50 c-blue-500 circle-add">
										<span className="icon-holder" style={{ fontSize: "16px" }}>
											<i className="c-light-blue-500 ti-trash"></i>
										</span>
									</div>
								</a>
							}
						</div>
						<hr />
					</div>}
					{!this.props.data.closingPagesPath && <span>No closing pages selected.<br /><br /></span>}
				</div>
			</div>
		)
	}
}

export default connect(
	(state: ApplicationState) => { return { dialog: state.dialog, userState: state.user } },
	DialogStore.actionCreators
)(CompDateActions)
