import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import * as DialogStore from '../../store/DialogStore';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ApplicationState } from "../../store";
import { httpService } from "../../index";
import { CompetitionDog } from "../../models/registrations/Registrations";
import { DialogType, NotificationData, NotificationType } from "../../store/DialogStore";
import RegistrationLoader from "../../components/RegistrationLoader";
import { Helpers } from "../../utility/Helpers";
import CompetitionScore from "../../components/competition/CompetitionScore";
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Link } from "react-router-dom";
import CompetitionBISScore from "../../components/competition/CompetitionBISScore";
import CompetitionCustomBISScore from "../../components/competition/CompetitionCustomBISScore";


type Props = {
    data?: any;
    dogs?: any
    isOpen?: any

}

let CERTIFICATE = 'certificate';
let APPRECIATION = 'appreciation';


class DogFormComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }
    emptyState: any = {

        dog: null,
        competitionDate: null,
        competitionClass: null,
        competitionRing: null,
        paymentAmount: '',
        competitionRegistrationId: this.props.dataState.registration.id,
        created: new Date(),
        registeredOnAllEvents: false,
        refunded: false,
        couponCode: null,
        invoiceNumber: "",
        customBISShows: []
    }

    state: any = {
        isOpen: true,
        selectedTab: 0,
        modal: true,
        loading: "",
        isDataLoading: true,
        processing: false,
        editMode: true,
        add: false,
        previewMode: false,
        editItem: "",
        newData: this.props.dataState.registration,
        currentIndex: 0,
        registrationId: "",
        dropDown: false,
        dogType: '',
        itemConfirmed: false,
        dogData: this.props.dataState.competitionDog,
        score: {
            bestFemale: false, bestMale: false, puppyGrade: null, bestPuppyGrade: null, bob: false, bos: false,
            cac: null, cacib: null, grade: null, juniorBOB: false, rang: null, veteranBOB: false,
            wasNotPresent: false, evaluationDone: false, description: '', cannotBeEvaluated: false, disqualified: false
        },
        competitionBISScore: { isPresent: false, bisScoreType: null, bisScore: null },
        bodCompetitionBISScore: { isPresent: false, bisScoreType: null, bisScore: null },
        breedCompetitionBISScore: { isPresent: false, bisScoreType: null, bisScore: null },
        jrCompetitionBISScore: { isPresent: false, bisScoreType: null, bisScore: null },
        puppyCompetitionBISScore: { isPresent: false, bisScoreType: null, bisScore: null },
        jrBODCompetitionBISScore: { isPresent: false, bisScoreType: null, bisScore: null },
        veteranCompetitionBISScore: { isPresent: false, bisScoreType: null, bisScore: null },
        disableButton: false,
        customBISShows: [],
        customDiscounts: []
    };



    toggle = () => {
        this.setState({
            modal: !this.state.modal

        });
    }

    closeModal() {
        this.props.getCompetitionDog(null)


        this.setState({

            add: false,
            editMode: false,
            previewMode: false,
            dogData: null,
            selectedTab: 0
        })

    }


    openEdit = (id: any, index: number, dogType: string) => {

        this.setState({

            editItem: id,
            previewMode: !this.state.previewMode,
            currentIndex: index,


        })

    }

    openAdd = () => {
        this.setState({
            add: true,
            editItem: '',
            previewMode: !this.state.previewMode,
            currentIndex: null,
            newData: null,
            dogType: '',
            dogData: this.emptyState,

        })
        if (!this.props.dataState.profileDogs) {
            this.props.getProfileDogs(this.props.dataState.registration.profile.id)
        }


    }

    openPreview = async (id: any, index: number, dogType?: string) => {

        this.setState({
            editItem: id,
            editMode: true,
            previewMode: true,
            currentIndex: index,
            isDataLoading: true



        }, () => {
            setTimeout(() => {
                this.getNewestData(id, index)
            }, 200)

        }
        );

        if (this.state.dogData) {
            this.setState({ isDataLoading: false })
        }

    }


    getUrl = (type: string, id: string) => {
        return "generate/" + type + "/" + id
    }

    getNewestData = async (id: any, index: any) => {
        let data = await httpService.getCompetitionRegistrationDog(id)
        const compDateId = this.props.dataState.registration.competitionRegistrationDogs[index]["competitionDate"].id
        let customBISShows = await httpService.customBISList(compDateId)
        let customDiscounts = await httpService.getCustomDiscountDogReg(compDateId)

        this.setState({
            customBISShows: customBISShows,
            dogData: data,
            customDiscounts: customDiscounts
        })

        this.props.getCompetitionDate(compDateId)
    }

    async processClick(type: any, id: any) {

        this.setState({ processing: true });
        this.setState({ loading: type })
        let url = this.getUrl(type, id)
        let response = await httpService.generateDocument(url)

        if (response) {
            window.location.href = (response)
            this.setState({ loading: '' })
        } else {
            alert("Unable to generate file.");
            this.setState({ loading: '' })
        }
    }

    async downloadFile(type: any, id: any) {
        this.setState({ processing: true });
        let action: any;
        switch (type) {
            case CERTIFICATE: {
                action = async () => { return await httpService.getDogCertificate(id) }
                break;
            }
            case APPRECIATION: {
                action = async () => { return await httpService.getDogAppreciation(id) }
                break;
            }
        }
        try {
            this.setState({ loading: type });


            let response = await action();
            this.setState({ loading: '' });

        } catch (err) {
            this.setState({ loading: '' });
            alert('Unable to generate file');
        }

    }

    toggleDropdown = () => {
        this.setState({ dropDown: !this.state.dropDown })
    }

    saveChanges(data: any, type?: any) {
        if (data) {

            if (!data.hasOwnProperty("competitionRegistrationId")) {
                data.competitionRegistrationId = this.props.dataState.registration.id
            }
            if (data.hasOwnProperty("competitionScore")) {
                if (!data.competitionScore.hasOwnProperty("description")) {
                    data.competitionScore.description = ""
                }
            }

            if (data.hasOwnProperty("competitionRing") && data.competitionRing == undefined) {
                data.competitionRing = null
            }


            if (data.hasOwnProperty("competitionDate") && data.competitionDate == undefined) {
                data.competitionDate = null
            }
            this.props.showDialog(`Are you sure you want to save this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    try {
                        this.setState({ disableButton: true })
                        await httpService.updateCompetitionRegistrations(data);
                        this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });
                        this.props.getRegistration(data.competitionRegistrationId)
                        this.setState({ dogData: null, modal: false })
                        this.closeModal()
                        this.setState({ disableButton: false })

                    } catch (err) {

                        alert(err.message)
                        this.setState({ disableButton: false })

                    }

                }
            })

        }
    }

    addScore() {
        let data = { ...this.state.dogData }
        let score = { ...this.state.competitionScore }
        data.competitionScore = score

        this.setState({ dogData: data })


    }

    addBISScore() {
        let data = { ...this.state.dogData }
        let compBISScore = { ...this.state.competitionBISScore }
        data.competitionBISScore = compBISScore;

        this.setState({ dogData: data })


    }

    addCustomBISScore() {
        let data = { ...this.state.dogData }

        if (!data.customBISScores) {
            data.customBISScores = []
        }

        data.customBISScores.push({isPresent: false, BISScore: 0})

        this.setState({ dogData: data })
    }

    addBODBISScore() {
        let data = { ...this.state.dogData }
        let bodCompBISScore = { ...this.state.bodCompetitionBISScore }
        data.bodCompetitionBISScore = bodCompBISScore;

        this.setState({ dogData: data })


    }
    addJrBodBISScore() {
        let data = { ...this.state.dogData }
        let jrBodCompBISScore = { ...this.state.jrBODCompetitionBISScore }
        data.jrBODCompetitionBISScore = jrBodCompBISScore;

        this.setState({ dogData: data })


    }

    addJrBISScore() {
        let data = { ...this.state.dogData }
        let juniorCompBISScore = { ...this.state.jrCompetitionBISScore }
        data.jrCompetitionBISScore = juniorCompBISScore;

        this.setState({ dogData: data })


    }

    addPuppyBISScore() {
        let data = { ...this.state.dogData }
        let puppyCompBISScore = { ...this.state.puppyCompetitionBISScore }
        data.puppyCompetitionBISScore = puppyCompBISScore;

        this.setState({ dogData: data })


    }

    addVeteranBISScore() {
        let data = { ...this.state.dogData }
        let veteranCompBISScore = { ...this.state.veteranCompetitionBISScore }
        data.veteranCompetitionBISScore = veteranCompBISScore;

        this.setState({ dogData: data })


    }

    addBreedBISScore() {
        let data = { ...this.state.dogData }
        let breedCompBISScore = { ...this.state.breedCompetitionBISScore }
        data.breedCompetitionBISScore = breedCompBISScore;

        this.setState({ dogData: data })


    }
    changeScore(ev: any) {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let data = { ...this.state.dogData }

        data.competitionScore[name] = value

        this.setState({
            dogData: data
        })

    }

    changeBISScore(ev: any, type: any) {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let data = { ...this.state.dogData }

        data[type][name] = value

        this.setState({
            dogData: data
        })
    }

    changeCustomBISScore(ev: any, index: number) {
        const target = ev.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        let data = { ...this.state.dogData }

        if (name === 'customBISShow') {
            data.customBISScores[index].customBISShow = this.state.customBISShows.find((show: any) => show.id === value)
        } else {
            data.customBISScores[index][name] = value
        }

        this.setState({
            dogData: data
        })
    }

    async changeData(ev: any, index: number, item?: any, type?: any) {

        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let dogData = { ...this.state.dogData }

        if (!dogData.hasOwnProperty("competitionRegistrationId")) {
            let newKey = dogData.competitionRegistrationId
            newKey = this.props.dataState.registration.id
        } if (target.name === "competitionClass") {
            let val = this.props.dataState.classes.find((i: any) => i.id === value);
            dogData[name] = val
        } if (target.name === "competitionDate") {

            let val = (this.props.dataState.selectedItem.competitionDates.find((i: any) => i.id === value));
            dogData[name] = val
            dogData.competitionRing = null
            if (val) {
                let customBISShows = await httpService.customBISList(val.id)
                let customDiscounts = await httpService.getCustomDiscountDogReg(val.id)

                this.setState({
                    customBISShows: customBISShows,
                    customDiscounts: customDiscounts
                })

                this.props.getCompetitionDate(val.id)
            }
        } if (target.name === "dog") {
            dogData[name] = this.props.dataState.profileDogs.find((i: any) => i.id === value)

        } if (target.name === "paymentAmount") {
            dogData[name] = (value)

        } if (target.name === "competitionRing") {
            dogData[name] = this.props.dataState.rings.find((i: any) => i.id === value);
        } if (target.name === "customDiscount") {
            dogData[name] = this.state.customDiscounts.find((i: any) => i.id === value);
        } if (target.name === "registeredOnAllEvents") {
            dogData[name] = value
        } if (target.name === "refunded") {
            dogData[name] = value
        } if (target.name === "catalogNumber") {
            dogData[name] = value
        } if (target.name === "invoiceNumber") {
            dogData[name] = value
        } if (target.name === "couponCode") {
            dogData[name] = { "code": value, used: false, id: null }
        }
        this.setState({
            dogData: dogData
        });

    }

    async deleteRegistration(item: any) {


        this.props.showDialog(`Are you sure you want to delete "${item ? item.dog.name : "this item"}?"`, DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {

                try {

                    await httpService.deleteCompetitionRegistrationDog(item.id);

                    this.props.showNotification({ message: 'Successfully deleted.', type: NotificationType.Success });
                    this.props.getRegistration(this.props.dataState.registration.id)
                    this.setState({ dogData: null, modal: false })
                    this.closeModal()

                } catch (err) {
                    alert(err.message);
                }
            }
        })
    }


    render() {
        return (
            <React.Fragment>


                <div>
                    <div>

                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                            <div style={{ float: "right", verticalAlign: "middle" }}>
                                <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                    this.openAdd()
                                }}

                                    color="none">
                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                </Button>
                            </div>
                        }
                        <h5>Registered dogs</h5>
                        <table className="table table-bordered">


                            {this.props.dataState.registration.competitionRegistrationDogs.length > 0 ?
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th>Name & Breed</th>

                                        <th>Class</th>
                                        <th>Catalog Nr.</th>
                                        <th>Created</th>
                                        <th>Actions</th>
                                    </tr>

                                </thead>
                                : <span style={{ color: "grey" }}>No sign-ups yet.</span>}

                            <tbody className="dd-container">
                                {this.props.dataState.registration.competitionRegistrationDogs.map((item: any, index: any) =>


                                    <tr key={item.id}>
                                        <td style={{ color: "grey", userSelect: "none" }}>{item.competitionDate.description}</td>
                                        <td style={{ color: "grey", userSelect: "none" }}>
                                            {!Helpers.isUserEventViewer(this.props.userState.user) ? (
                                                <Link target="_blank" to={`/dog/details/${item.dog.id}`}>{item.dog.name} - {item.dog.breed.name}</Link>
                                            ) :
                                                <>
                                                    {item.dog.name} - {item.dog.breed.name}
                                                </>
                                            }
                                        </td>
                                        <td style={{ color: "grey", userSelect: "none" }}>{item.competitionClass.name}</td>
                                        <td style={{ color: "grey", userSelect: "none" }}>{item.catalogNumber}</td>
                                        <td style={{ color: "grey", userSelect: "none" }}>{Helpers.getLocalDateTimeString(item.created)}</td>
                                        <td className="ta-c">
                                            <span onClick={() => this.openPreview(item.id, index, "dog")} style={{ cursor: "pointer", marginLeft: "auto", marginRight: "auto" }} className="icon-holder"><i className="c-light-blue-500 fa fa-search" aria-hidden="true"></i></span>

                                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                <>
                                                    <span style={{ fontSize: "16px" }}> | </span>
                                                    <span style={{ cursor: "pointer" }} className="icon-holder" onClick={(e) =>

                                                        this.deleteRegistration(item)
                                                    }><i className="c-light-blue-500 ti-trash"></i></span>
                                                </>
                                            }

                                        </td>

                                    </tr>

                                )}
                            </tbody>


                        </table>

                    </div>


                    {this.props.dataState.registration.competitionRegistrationDogs.map((dog: any, index: number) =>
                        <div>
                            {
                                !this.state.add && this.state.editItem === dog.id && this.state.editMode && (


                                    <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.state.editMode}>
                                        <ModalHeader toggle={() => this.closeModal()}>{this.state.dogData ? this.state.dogData.dog.name : ""}</ModalHeader>
                                        <ModalBody>
                                            {this.state.isDataLoading && !this.state.dogData ? <RegistrationLoader /> :
                                                (
                                                    <div>
                                                        <div className="row">

                                                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                                <td><span onClick={() => this.openEdit(dog.id, index, "dog")} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                                                    className="c-light-blue-500 ti-pencil"></i></span>
                                                                </td>
                                                            }


                                                        </div>

                                                        <div >

                                                            <div className="row">
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="dog">Dog *</label>
                                                                    <select id="dog" name="dog"
                                                                        className="form-control"
                                                                        required
                                                                        disabled
                                                                        value={this.state.dogData && this.state.dogData.dog && this.state.dogData.dog.id}
                                                                        onChange={(e) => this.changeData(e, 0, this, "dog")}>
                                                                        <option value="">-- select dog --</option>
                                                                        {this.props.dataState.profileDogs.map((item: any, index: number) => (
                                                                            <option key={index} value={item.id}>{item.name} - {item.breed.name}</option>
                                                                        ))}
                                                                    </select>

                                                                </div>
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label"
                                                                        htmlFor="competitionClass">Class</label>
                                                                    <select id="competitionClass" name="competitionClass"
                                                                        className="form-control"
                                                                        disabled={this.state.previewMode}
                                                                        value={this.state.dogData && this.state.dogData.competitionClass && this.state.dogData.competitionClass.id}

                                                                        onChange={(e) => this.changeData(e, index, this, "dog")}>

                                                                        <option>--- select class ---</option>

                                                                        {this.props.dataState.classes.map((c: any, i: number) => (
                                                                            <option key={i} value={c.id}>{c.name}</option>
                                                                        ))}

                                                                    </select>
                                                                </div>

                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="competitionDate">Show</label>
                                                                    <select id="competitionDate" name="competitionDate"
                                                                        className="form-control"
                                                                        disabled={this.state.previewMode}
                                                                        defaultValue={this.state.dogData && this.state.dogData.competitionDate && this.state.dogData.competitionDate.id}
                                                                        onChange={(e) => this.changeData(e, index, this, "dog")}>

                                                                        <option>--- select show ---</option>

                                                                        {this.props.dataState.selectedItem.competitionDates.map((item: any, index: number) => (
                                                                            <option key={index} value={item.id}>{item.description}</option>
                                                                        ))}
                                                                    </select>

                                                                </div>
                                                                <div style={{ display: "flex", alignContent: "flex-start", justifyContent: "flex-start", flexDirection: "column-reverse" }} className="mb-3 col-md-6">
                                                                    <div
                                                                        className="mb-3 checkbox checkbox-info peers ai-c">
                                                                        <input id="registeredOnAllEvents" name="registeredOnAllEvents"
                                                                            type="checkbox" checked={this.state.dogData && this.state.dogData.registeredOnAllEvents}
                                                                            className="peer"
                                                                            disabled={this.state.previewMode}
                                                                            onChange={(e: any) => this.changeData(e, index, "", "dog")} />
                                                                        <label className="form-label peers peer-greed js-sb ai-c"
                                                                            htmlFor="registeredOnAllEvents">
                                                                            <span
                                                                                className="peer peer-greed">Registered On All Events</span></label>
                                                                    </div>
                                                                </div>


                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="competitionRing">
                                                                        Ring</label>
                                                                    <select id="competitionRing" name="competitionRing"
                                                                        className="form-control"
                                                                        disabled={this.state.previewMode}
                                                                        value={this.state.dogData && this.state.dogData.competitionRing && this.state.dogData.competitionRing.id}

                                                                        onChange={(e) => this.changeData(e, index, this, "dog")}>

                                                                        <option>--- select ring ---</option>

                                                                        {this.props.dataState.rings.map((c: any, i: number) => (
                                                                            <option key={i} value={c.id}>{c.description}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="catalogNumber">Catalog
                                                                        Number</label>
                                                                    <input id="catalogNumber" name="catalogNumber"
                                                                        disabled={this.state.previewMode}

                                                                        className="form-control" value={this.state.dogData && this.state.dogData.catalogNumber}
                                                                        onChange={(e) => this.changeData(e, index, "", "dog")}>

                                                                    </input>
                                                                </div>


                                                                <hr />
                         
                                                                <h5>Payment Information</h5>

                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="paymentAmount">Payment
                                                                        Amount *</label>
                                                                    <input type="number" id="paymentAmount" name="paymentAmount" min="0.00"
                                                                        step="0.1"
                                                                        required
                                                                        className="form-control"
                                                                        disabled={this.state.previewMode}
                                                                        defaultValue={this.state.dogData && this.state.dogData.paymentAmount}
                                                                        onChange={(e) => this.changeData(e, index, "", "dog")}>
                                                                    </input>
                                                                </div>
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="invoiceNumber">Invoice
                                                                        Number</label>
                                                                    <input id="invoiceNumber" name="invoiceNumber"
                                                                        disabled={this.state.previewMode}

                                                                        className="form-control" value={this.state.dogData && this.state.dogData.invoiceNumber}
                                                                        onChange={(e) => this.changeData(e, index, "", "dog")}>

                                                                    </input>
                                                                </div>
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="couponCode">Coupon Code</label>
                                                                    <input id="couponCode" name="couponCode"
                                                                        disabled={this.state.previewMode}

                                                                        className="form-control" value={this.state.dogData && this.state.dogData.couponCode && this.state.dogData.couponCode.code}
                                                                        onChange={(e) => this.changeData(e, index, "", "dog")}>

                                                                    </input>
                                                                </div>
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="customDiscount">Custom Discount</label>
                                                                    <select id="customDiscount" name="customDiscount"
                                                                        className="form-control"
                                                                        disabled={this.state.previewMode}
                                                                        defaultValue={this.state.dogData && this.state.dogData.customDiscount && this.state.dogData.customDiscount.id}
                                                                        onChange={(e) => this.changeData(e, index, this, "discount")}>

                                                                        <option>--- select discount ---</option>

                                                                        {this.state.customDiscounts.map((item: any, index: number) => (
                                                                            <option key={index} value={item.id}>{item.title}</option>
                                                                        ))}
                                                                    </select>

                                                                </div>
                                                                <div className="mb-3 col-md-6">
																    <div className="mb-3 col-md-6 checkbox checkbox-info peers ai-c">
                                                                        <input id="refunded" name="refunded"
                                                                            type="checkbox" checked={this.state.dogData && this.state.dogData.refunded}
                                                                            className="peer"
                                                                            disabled={this.state.previewMode}
                                                                            onChange={(e: any) => this.changeData(e, index, "", "dog")} />
                                                                        <label className="form-label peers peer-greed js-sb ai-c"
                                                                            htmlFor="refunded">
                                                                            <span
                                                                                className="peer peer-greed">Refunded</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{ display: "flex", gap: "15px" }}>
                                                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                                <Button id="start-number" color="outline-primary"

                                                                    onClick={() => {
                                                                        this.processClick("start-number", dog.id)
                                                                    }}
                                                                    disabled={this.state.loading === "start-number" && true}
                                                                >Generate Start Number

                                                                    {this.state.loading === "start-number" &&
                                                                        <i className="fa fa-refresh fa-spin"
                                                                            style={{ marginRight: "5px", marginLeft: "10px" }} />
                                                                    }
                                                                </Button>
                                                            }


                                                            <Button id="evaluation-sheet" color="outline-primary"
                                                                onClick={() => {
                                                                    this.processClick("evaluation-sheet", dog.id)
                                                                }}
                                                                disabled={this.state.loading === "evaluation-sheet" && true}
                                                            >Evaluation Sheet

                                                                {this.state.loading === "evaluation-sheet" &&
                                                                    <i className="fa fa-refresh fa-spin"
                                                                        style={{ marginRight: "5px", marginLeft: "10px" }} />
                                                                }
                                                            </Button>


                                                            <Button id="card" color="outline-primary"
                                                                onClick={() => {
                                                                    this.processClick("card", dog.id)
                                                                }}
                                                                disabled={this.state.loading === "card" && true}
                                                            >Card

                                                                {this.state.loading === "card" &&
                                                                    <i className="fa fa-refresh fa-spin"
                                                                        style={{ marginRight: "5px", marginLeft: "10px" }} />
                                                                }
                                                            </Button>
                                                            <Button id="certificate" color="outline-primary"
                                                                onClick={() => {
                                                                    this.downloadFile("certificate", dog.id)
                                                                }}
                                                                disabled={this.state.loading === "certificate" && true}
                                                            >Certificate

                                                                {this.state.loading === "certificate" &&
                                                                    <i className="fa fa-refresh fa-spin"
                                                                        style={{ marginRight: "5px", marginLeft: "10px" }} />
                                                                }
                                                            </Button>
                                                            <Button id="appreciation" color="outline-primary"
                                                                onClick={() => {
                                                                    this.downloadFile("appreciation", dog.id)
                                                                }}
                                                                disabled={this.state.loading === "appreciation" && true}
                                                            >Appreciation

                                                                {this.state.loading === "appreciation" &&
                                                                    <i className="fa fa-refresh fa-spin"
                                                                        style={{ marginRight: "5px", marginLeft: "10px" }} />
                                                                }
                                                            </Button>
                                                        </div>
                                                        <br />

                                                        <div>
                                                            <Nav tabs style={{ marginBottom: "16px", marginTop: "10px", cursor: "pointer" }}>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.selectedTab === 0 })}
                                                                        onClick={() => {
                                                                            this.setState({ selectedTab: 0 });
                                                                        }} >
                                                                        Score
                                                                    </NavLink>

                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.selectedTab === 1 })}
                                                                        onClick={() => {

                                                                            this.setState({ selectedTab: 1 });

                                                                        }} >
                                                                        BIS
                                                                    </NavLink>

                                                                </NavItem>

                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.selectedTab === 2 })}
                                                                        onClick={() => {
                                                                            this.setState({ selectedTab: 2 })
                                                                        }} >
                                                                        Custom BIS
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                        </div>
                                                        {this.state.selectedTab == 0 && (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <h5 style={{ marginRight: '10px' }}>Score</h5>
                                                                {this.state.dogData && !this.state.previewMode && !this.state.dogData.competitionScore &&
                                                                    !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                                        <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                            this.addScore()
                                                                        }}
                                                                            color="none">
                                                                            <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                        </Button>
                                                                    )
                                                                }
                                                            </div>
                                                        )}
                                                        {this.state.selectedTab == 0 && this.state.dogData && this.state.dogData.competitionScore ?

                                                            <CompetitionScore data={this.state.dogData} action={(e: any) => this.changeScore(e)} previewMode={this.state.previewMode} />
                                                            : this.state.selectedTab == 0 && <div>No score.</div>
                                                        }


                                                        {this.state.selectedTab == 1 && (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <h5 style={{ marginRight: '10px' }}>BIS Score</h5>
                                                                {this.state.dogData && !this.state.previewMode && !this.state.dogData.competitionBISScore &&
                                                                    !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                                        <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                            this.addBISScore()
                                                                        }}
                                                                            color="none">
                                                                            <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                        </Button>
                                                                    )
                                                                }
                                                            </div>
                                                        )}

                                                        {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.competitionBISScore ?

                                                            <CompetitionBISScore data={this.state.dogData.competitionBISScore} dataType="dog" action={(e: any) => this.changeBISScore(e, "competitionBISScore")} previewMode={this.state.previewMode} type="BIS Score" />
                                                            : this.state.selectedTab == 1 && <div>No BIS Score.</div>

                                                        }

                                                        {this.state.selectedTab == 1 && (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <h5 style={{ marginRight: '10px' }}>BOD BIS Score</h5>
                                                                {this.state.dogData && !this.state.previewMode && !this.state.dogData.bodCompetitionBISScore &&
                                                                    !Helpers.isUserEventViewer(this.props.userState.user) && (

                                                                        <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                            this.addBODBISScore()
                                                                        }}
                                                                            color="none">
                                                                            <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                        </Button>
                                                                    )
                                                                }
                                                            </div>
                                                        )}

                                                        {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.bodCompetitionBISScore ?

                                                            <CompetitionBISScore data={this.state.dogData.bodCompetitionBISScore} dataType="bodBis" action={(e: any) => this.changeBISScore(e, "bodCompetitionBISScore")} previewMode={this.state.previewMode} type="BOD BIS Score" />

                                                            : this.state.selectedTab == 1 && <div>No BOD BIS Score.</div>

                                                        }
                                                        {this.state.selectedTab == 1 && (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <h5 style={{ marginRight: '10px' }}>Breed BIS Score</h5>
                                                                {this.state.dogData && !this.state.previewMode && !this.state.dogData.breedCompetitionBISScore && (
                                                                    !Helpers.isUserEventViewer(this.props.userState.user) &&

                                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                        this.addBreedBISScore()
                                                                    }}

                                                                        color="none">
                                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                    </Button>

                                                                )
                                                                }
                                                            </div>
                                                        )}
                                                        {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.breedCompetitionBISScore ?


                                                            <CompetitionBISScore data={this.state.dogData.breedCompetitionBISScore} dataType="breedBis" action={(e: any) => this.changeBISScore(e, "breedCompetitionBISScore")} previewMode={this.state.previewMode} type="Breed BIS Score" />
                                                            : this.state.selectedTab == 1 && <div>No Breed BIS Score.</div>

                                                        }

                                                        {this.state.selectedTab == 1 && (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <h5 style={{ marginRight: '10px' }}>Junior BIS Score</h5>
                                                                {this.state.dogData && !this.state.previewMode && !this.state.dogData.jrCompetitionBISScore &&
                                                                    !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                                        <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                            this.addJrBISScore()
                                                                        }}
                                                                            color="none">
                                                                            <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                        </Button>
                                                                    )
                                                                }
                                                            </div>
                                                        )}

                                                        {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.jrCompetitionBISScore ?

                                                            <CompetitionBISScore data={this.state.dogData.jrCompetitionBISScore} dataType="jrBis" action={(e: any) => this.changeBISScore(e, "jrCompetitionBISScore")} previewMode={this.state.previewMode} type="Junior BIS Score" />
                                                            : this.state.selectedTab == 1 && <div>No Junior BIS Score</div>

                                                        }


                                                        {this.state.selectedTab == 1 && (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <h5 style={{ marginRight: '10px' }}>Junior BOD BIS Score</h5>
                                                                {this.state.dogData && !this.state.previewMode && !this.state.dogData.jrBODCompetitionBISScore &&
                                                                    !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                                        <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                            this.addJrBodBISScore()
                                                                        }}
                                                                            color="none">
                                                                            <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                        </Button>
                                                                    )
                                                                }
                                                            </div>
                                                        )}


                                                        {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.jrBODCompetitionBISScore ?

                                                            <CompetitionBISScore data={this.state.dogData.jrBODCompetitionBISScore} dataType="jrBodBis" action={(e: any) => this.changeBISScore(e, "jrBODCompetitionBISScore")} previewMode={this.state.previewMode} type="Junior BOD BIS Score" />
                                                            : this.state.selectedTab == 1 && <div>No Junior BOD BIS Score</div>

                                                        }


                                                        {this.state.selectedTab == 1 && (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <h5 style={{ marginRight: '10px' }}>Puppy BIS Score</h5>
                                                                {this.state.dogData && !this.state.previewMode && !this.state.dogData.puppyCompetitionBISScore &&
                                                                    !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                                        <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                            this.addPuppyBISScore()
                                                                        }}
                                                                            color="none">
                                                                            <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                        </Button>
                                                                    )
                                                                }
                                                            </div>
                                                        )}
                                                        {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.puppyCompetitionBISScore ?

                                                            <CompetitionBISScore data={this.state.dogData.puppyCompetitionBISScore} dataType="puppyBis" action={(e: any) => this.changeBISScore(e, "puppyCompetitionBISScore")} previewMode={this.state.previewMode} type="Puppy BIS Score" />
                                                            : this.state.selectedTab == 1 && <div>No Puppy BIS Score</div>

                                                        }



                                                        {this.state.selectedTab == 1 && (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <h5 style={{ marginRight: '10px' }}>Veteran BIS Score</h5>
                                                                {this.state.dogData && !this.state.previewMode && !this.state.dogData.veteranCompetitionBISScore &&
                                                                    !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                                        <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                            this.addVeteranBISScore()
                                                                        }}
                                                                            color="none">
                                                                            <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                        </Button>
                                                                    )
                                                                }
                                                            </div>
                                                        )}


                                                        {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.veteranCompetitionBISScore ?

                                                            <CompetitionBISScore data={this.state.dogData.veteranCompetitionBISScore} dataType="veteranBis" action={(e: any) => this.changeBISScore(e, "veteranCompetitionBISScore")} previewMode={this.state.previewMode} type="Veteran BIS Score" />
                                                            : this.state.selectedTab == 1 && <div>No Veteran BIS Score</div>

                                                        }

                                                        {this.state.selectedTab == 2 && (
                                                            <div>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <h5 style={{ marginRight: '10px' }}>Custom BIS Score</h5>
                                                                    {this.state.dogData && !this.state.previewMode &&
                                                                        !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                                            <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                                this.addCustomBISScore()
                                                                            }}
                                                                                color="none">
                                                                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                            </Button>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {this.state.dogData && this.state.dogData.customBISScores && this.state.dogData.customBISScores.length > 0 ?
                                                                        <div>
                                                                            {this.state.dogData.customBISScores.map((score: any, index: number) => (
                                                                                <CompetitionCustomBISScore data={score} shows={this.state.customBISShows} action={(e: any) => this.changeCustomBISScore(e, index)} previewMode={this.state.previewMode} />
                                                                            ))}
                                                                        </div>
                                                                        : <div>No Custom BIS Scores.</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                )

                                            }

                                        </ModalBody>
                                        <ModalFooter>
                                            {!this.state.previewMode &&
                                                <Button color="primary" disabled={this.state.disableButton} onClick={() => {
                                                    this.saveChanges(this.state.dogData as CompetitionDog, "dog")
                                                }}>Save</Button>
                                            }
                                            <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                                        </ModalFooter>

                                    </Modal>

                                )

                            }
                        </div>
                    )}

                    {this.state.add && (
                        <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.state.add}>
                            <ModalHeader toggle={() => this.closeModal()}>Add Dog Registration</ModalHeader>
                            <ModalBody>

                                <div>
                                    <div className="row">

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="dog">Dog *</label>
                                            <select id="dog" name="dog"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.dogData && this.state.dogData.dog && this.state.dogData.dog.name}
                                                onChange={(e) => this.changeData(e, 0, this, "dog")}>
                                                <option value="">-- select dog --</option>
                                                {this.props.dataState.profileDogs.map((item: any, index: number) => (
                                                    <option key={index} value={item.id}>{item.name} - {item.breed.name}</option>
                                                ))}
                                            </select>

                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label"
                                                htmlFor="competitionClass">Class *</label>
                                            <select id="competitionClass" name="competitionClass"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.dogData && this.state.dogData.competitionClass && this.state.dogData.competitionClass.id}
                                                onChange={(e) => this.changeData(e, 0, this, "dog")}>
                                                <option value="">-- select class --</option>
                                                {this.props.dataState.classes.map((c: any, i: number) => (
                                                    <option key={i} value={c.id} >{c.name}</option>
                                                ))}

                                            </select>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="competitionDate">Show *</label>
                                            <select id="competitionDate" name="competitionDate"
                                                className="form-control"
                                                required
                                                value={this.state.dogData && this.state.dogData.competitionDate && this.state.dogData.competitionDate.id}
                                                onChange={(e) => this.changeData(e, 0, this, "dog")}>
                                                <option value="">-- select show --</option>
                                                {this.props.dataState.selectedItem.competitionDates.map((item: any, index: number) => (
                                                    <option key={index} value={item.id}>{item.description}</option>
                                                ))}
                                            </select>

                                        </div>
                                        <div style={{ display: "flex", alignContent: "flex-start", justifyContent: "flex-start", flexDirection: "column-reverse" }} className="mb-3 col-md-3">
                                            <div
                                                className="mb-3 checkbox checkbox-info peers ai-c">
                                                <input id="registeredOnAllEvents" name="registeredOnAllEvents"
                                                    type="checkbox" checked={this.state.dogData && this.state.dogData.registeredOnAllEvents}
                                                    className="peer"

                                                    onChange={(e: any) => this.changeData(e, 0, "", "dog")} />
                                                <label className="form-label peers peer-greed js-sb ai-c"
                                                    htmlFor="registeredOnAllEvents">
                                                    <span
                                                        className="peer peer-greed">Registered On All Events</span></label>

                                            </div>


                                        </div>

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="competitionRing">Ring:</label>
                                            <select id="competitionRing" name="competitionRing"
                                                className="form-control"


                                                value={(this.state.dogData && this.state.dogData.competitionRing && this.state.dogData.competitionRing.id)}
                                                onChange={(e) => this.changeData(e, 0, this, "dog")}>
                                                <option value="">-- select ring --</option>
                                                {this.props.dataState.rings.map((c: any, i: number) => (
                                                    <option key={i} value={c.id}>{c.description}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="catalogNumber">Catalog
                                                Number:</label>
                                            <input id="catalogNumber" name="catalogNumber"

                                                className="form-control" defaultValue={this.state.dogData && this.state.dogData.catalogNumber}
                                                onChange={(e) => this.changeData(e, 0, "", "dog")}>

                                            </input>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="paymentAmount">Payment
                                                Amount *</label>
                                            <input type="number" id="paymentAmount" name="paymentAmount" min="0.00"
                                                step="0.1"
                                                required
                                                className="form-control"
                                                defaultValue={this.state.dogData && this.state.dogData.paymentAmount || 0}

                                                onChange={(e) => this.changeData(e, 0, "", "dog")}>
                                            </input>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="invoiceNumber">Invoice Number</label>
                                            <input id="invoiceNumber" name="invoiceNumber"
                                                required
                                                className="form-control"
                                                defaultValue={this.state.dogData && this.state.dogData.invoiceNumber || ""}

                                                onChange={(e) => this.changeData(e, 0, "", "dog")}>
                                            </input>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="couponCode">Coupon Code</label>
                                            <input id="couponCode" name="couponCode"

                                                className="form-control" value={this.state.dogData && this.state.dogData.couponCode && this.state.dogData.couponCode.code}
                                                onChange={(e) => this.changeData(e, 0, "", "dog")}>

                                            </input>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="customDiscount">Custom Discount</label>
                                            <select id="customDiscount" name="customDiscount"
                                                className="form-control"
                                                onChange={(e) => this.changeData(e, 0, "", "customDiscount")}>

                                                <option>--- select discount ---</option>

                                                {this.state.customDiscounts.map((item: any, index: number) => (
                                                    <option key={index} value={item.id}>{item.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <div>
                                                <Nav tabs style={{ marginBottom: "16px", marginTop: "10px", cursor: "pointer" }}>
                                                    <NavItem>
                                                        <NavLink className={classnames({ active: this.state.selectedTab === 0 })}
                                                            onClick={() => {

                                                                this.setState({ selectedTab: 0 });

                                                            }} >
                                                            Score
                                                        </NavLink>

                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink className={classnames({ active: this.state.selectedTab === 1 })}
                                                            onClick={() => {

                                                                this.setState({ selectedTab: 1 });

                                                            }} >
                                                            BIS
                                                        </NavLink>

                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink className={classnames({ active: this.state.selectedTab === 2 })}
                                                            onClick={() => {
                                                                this.setState({ selectedTab: 2 })
                                                            }} >
                                                            Custom BIS
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.selectedTab == 0 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ marginRight: '10px' }}>Score</h5>
                                            {this.state.dogData && !this.state.dogData.competitionScore &&
                                                !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                        this.addScore()
                                                    }}
                                                        color="none">
                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    )}
                                    {this.state.selectedTab == 0 && this.state.dogData && this.state.dogData.competitionScore ?

                                        <CompetitionScore data={this.state.dogData} action={(e: any) => this.changeScore(e)} previewMode={false} />
                                        : this.state.selectedTab == 0 && <div>No score.</div>
                                    }

                                    {this.state.selectedTab == 1 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ marginRight: '10px' }}>BIS Score</h5>
                                            {this.state.dogData && !this.state.dogData.competitionBISScore &&
                                                !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                        this.addBISScore()
                                                    }}
                                                        color="none">
                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    )}


                                    {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.competitionBISScore ?

                                        <CompetitionBISScore data={this.state.dogData.competitionBISScore} dataType="dog" action={(e: any) => this.changeBISScore(e, "competitionBISScore")} previewMode={false} type="BIS Score" />
                                        : this.state.selectedTab == 1 && <div>No BIS Score</div>

                                    }

                                    {this.state.selectedTab == 1 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ marginRight: '10px' }}>BOD BIS Score</h5>
                                            {this.state.dogData && !this.state.dogData.bodCompetitionBISScore &&
                                                !Helpers.isUserEventViewer(this.props.userState.user) && (

                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                        this.addBODBISScore()
                                                    }}
                                                        color="none">
                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    )}

                                    {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.bodCompetitionBISScore ?

                                        <CompetitionBISScore data={this.state.dogData.bodCompetitionBISScore} dataType="bodBis" action={(e: any) => this.changeBISScore(e, "bodCompetitionBISScore")} previewMode={false} type="BOD BIS Score" />
                                        : this.state.selectedTab == 1 && <div>No BOD BIS Score.</div>

                                    }
                                    {this.state.selectedTab == 1 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ marginRight: '10px' }}>Breed BIS Score</h5>
                                            {this.state.dogData && !this.state.dogData.breedCompetitionBISScore && (
                                                !Helpers.isUserEventViewer(this.props.userState.user) &&

                                                <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                    this.addBreedBISScore()
                                                }}

                                                    color="none">
                                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                </Button>

                                            )
                                            }
                                        </div>
                                    )}

                                    {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.breedCompetitionBISScore ?


                                        <CompetitionBISScore data={this.state.dogData.breedCompetitionBISScore} dataType="breedBis" action={(e: any) => this.changeBISScore(e, "breedCompetitionBISScore")} previewMode={false} type="Breed BIS Score" />
                                        : this.state.selectedTab == 1 && <div>No Breed BIS Score.</div>

                                    }


                                    {this.state.selectedTab == 1 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ marginRight: '10px' }}>Junior BIS Score</h5>
                                            {this.state.dogData && !this.state.dogData.juniorCompetitionBISScore &&
                                                !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                        this.addJrBISScore()
                                                    }}
                                                        color="none">
                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    )}

                                    {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.jrCompetitionBISScore ?

                                        <CompetitionBISScore data={this.state.dogData.jrCompetitionBISScore} dataType="jrBis" action={(e: any) => this.changeBISScore(e, "jrCompetitionBISScore")} previewMode={false} type="Junior BIS Score" />
                                        : this.state.selectedTab == 1 && <div>No Junior BIS Score</div>

                                    }


                                    {this.state.selectedTab == 1 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ marginRight: '10px' }}>Junior BOD BIS Score</h5>
                                            {this.state.dogData && !this.state.dogData.jrBODCompetitionBISScore &&
                                                !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                        this.addJrBodBISScore()
                                                    }}
                                                        color="none">
                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    )}


                                    {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.jrBODCompetitionBISScore ?

                                        <CompetitionBISScore data={this.state.dogData.jrBODCompetitionBISScore} dataType="jrBodBis" action={(e: any) => this.changeBISScore(e, "jrBODCompetitionBISScore")} previewMode={false} type="Junior BOD BIS Score" />
                                        : this.state.selectedTab == 1 && <div>No Junior BOD BIS Score</div>

                                    }


                                    {this.state.selectedTab == 1 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ marginRight: '10px' }}>Puppy BIS Score</h5>
                                            {this.state.dogData && !this.state.dogData.puppyCompetitionBISScore &&
                                                !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                        this.addPuppyBISScore()
                                                    }}
                                                        color="none">
                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    )}
                                    {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.puppyCompetitionBISScore ?

                                        <CompetitionBISScore data={this.state.dogData.puppyCompetitionBISScore} dataType="puppyBis" action={(e: any) => this.changeBISScore(e, "puppyCompetitionBISScore")} previewMode={false} type="Puppy BIS Score" />
                                        : this.state.selectedTab == 1 && <div>No Puppy BIS Score</div>

                                    }



                                    {this.state.selectedTab == 1 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ marginRight: '10px' }}>Veteran BIS Score</h5>
                                            {this.state.dogData && !this.state.dogData.veteranCompetitionBISScore &&
                                                !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                        this.addVeteranBISScore()
                                                    }}
                                                        color="none">
                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    )}


                                    {this.state.selectedTab == 1 && this.state.dogData && this.state.dogData.veteranCompetitionBISScore ?

                                        <CompetitionBISScore data={this.state.dogData.veteranCompetitionBISScore} dataType="veteranBis" action={(e: any) => this.changeBISScore(e, "veteranCompetitionBISScore")} previewMode={false} type="Veteran BIS Score" />
                                        : this.state.selectedTab == 1 && <div>No Veteran BIS Score</div>

                                    }

                                    {this.state.selectedTab == 2 && (
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <h5 style={{ marginRight: '10px' }}>Custom BIS Score</h5>
                                                {this.state.dogData && !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                        <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                            this.addCustomBISScore()
                                                        }}
                                                            color="none">
                                                            <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                        </Button>
                                                    )
                                                }
                                            </div>
                                            <div>
                                                {this.state.dogData && this.state.dogData.customBISScores && this.state.dogData.customBISScores.length > 0 ?
                                                    <div>
                                                        {this.state.dogData.customBISScores.map((score: any, index: number) => (
                                                            <CompetitionCustomBISScore data={score} shows={this.state.customBISShows} action={(e: any) => this.changeCustomBISScore(e, index)} previewMode={false} />
                                                        ))}
                                                    </div>
                                                    : <div>No Custom BIS Scores.</div>
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                    <Button disabled={this.state.disableButton} color="primary" onClick={() => {
                                        this.saveChanges(this.state.dogData as CompetitionDog, "dog")
                                    }}>Save</Button>
                                }
                                <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                            </ModalFooter>

                        </Modal>
                    )

                    }

                </div>

            </React.Fragment>
        );

    }

}


const mapStateToProps = (state: ApplicationState) => {
    return {
        dataState: state.competitionList,
        userState: state.user
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getItems: () => {
            dispatch(CompetitionListStore.actionCreators.getItems())
        },
        getRegistrationItems: (page?: any, competition?: string, email?: string, count?: number, paymentStatus?: any, catalogNumber?: any) => {
            dispatch(CompetitionListStore.actionCreators.getRegistrationItems(page, competition, email, count, paymentStatus, catalogNumber))
        },
        getRegistration: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getRegistration(id))
        },
        getProfileDogs: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getProfileDogs(id))
        },
        getCompetitionDate: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
        },
        setOpenReg: (openReg?: any) => {
            dispatch(CompetitionListStore.actionCreators.setOpenReg(openReg))
        },
        getCompetitionDog: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDog(id))
        },
        getCompetitionCoupleGroup: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionCoupleGroup(id))
        },
        getCompetitionSpecialShow: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionSpecialShow(id))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
};




export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DogFormComponent);
