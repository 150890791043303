import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';

import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,

} from "reactstrap";
import * as DialogStore from '../../store/DialogStore';
import { httpService } from "../..";
import { ApplicationState } from "../../store";
import { UserType } from "../../models/registered-user/RegisteredUserEnums";
import { Helpers } from "../../utility/Helpers";
import { getBISType } from "../../models/competition/CompetitionEnums";
import BISShowsDialog from "./BISShowsDialog";
import BISBreedsDialog from "./BISBreedsDialog";
import CustomBISShowsDialog from "./CustomBISShowsDialog";
import Autocomplete from "../Autocomplete";
import StaffSearch from "../StaffSearch";


type Props = {
    data: any;
    callback: any;
    empty: any;
    recorder: any;
    ringLeader: any;
    judges: any
    action: any;
    actions: any;
    editSpecialBreed: any;
    dateDialog: any,
    open: any,
    add: any,
    update: any,
    onCompDateChange: any
}


class BISDialogComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }

    componentDidUpdate() {
        if (this.props.data && this.state.data && this.props.data.id && this.props.data.id !== this.state.data.id) {
            this.getData()
        }

    }


    state: any = {
        selectedBreed: null,
        selectedBreedIndex: null,
        data: this.props.data,
        isOpen: false,
        editMode: false,
        previewMode: !this.props.add,
        addMode: false,
        currentIndex: null,
        bisShowIndex: null,
        customBisShowIndex: null,
        bisBreedIndex: null,
        openBISShows: false,
        openCustomBISShows: false,
        openBreeds: false,
        loading: '',
        disabledButton: false,
        recorder: this.props.recorder,
        ringLeader: this.props.ringLeader,
        emptySpecialBreed: { id: '', created: Date(), fci: '', name: null },
        dateDialog: this.props.dateDialog

    }


    closeModal() {
        this.setState({ isOpen: !this.state.isOpen, currentIndex: null, selectedBreed: null, editMode: !this.state.editMode, previewMode: this.props.data && this.props.data.id !== '' ? true : false })

    }

    editSpecialBreed(index: any) {
        this.setState({ isOpen: !this.state.isOpen, selectedBreed: this.props.data.specialShowBreeds[index], currentIndex: index, editMode: true })

    }

    saveBIS(data: any) {

        if (data) {
            this.props.showDialog(`Are you sure you want to save this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {

                    try {
                        this.setState({ disabledButton: true })
                        await httpService.competitionDateBISAddOrUpdate(data)
                        this.props.action();
                        this.props.update();
                        this.setState({ data: null, disabledButton: false })

                    } catch (err) {
                        this.setState({ disabledButton: false })
                        alert(err.message)

                    }

                }
            })

        }
    }

    saveBreed = async (index: any) => {
        let selectedBreed = { ...this.state.selectedBreed }
        let state = { ...this.state }
        let breeds = !state.breedArray ? [] : [...this.state.breedArray]
        if (Object.keys(selectedBreed).length !== 0) {
            let breed = breeds.find((i: any) => i.id === selectedBreed.id)
            if (!breed) {
                if (breeds[index]) {
                    breeds[this.state.currentIndex] = selectedBreed
                } else {
                    selectedBreed.order = breeds.length + 1
                    breeds.push(selectedBreed)
                }

            } else {
                breeds[this.state.currentIndex] = selectedBreed
            }
            this.setState({ breedArray: breeds, editMode: false, selectedBreed: null, open: false }, () => {

                this.props.callback(this.state.breedArray)
            })

        }

    }

    saveChanges(data: any, save: boolean) {

        if (data && save) {
            this.props.showDialog(`Are you sure you want to save this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    if (this.state.selectedBreed !== null && this.state.currentIndex !== null) {
                        data.specialShowBreeds[this.state.currentIndex] = this.state.selectedBreed
                    } else if (this.state.selectedBreed !== null && this.state.currentIndex === null) {
                        data.specialShowBreeds.push(this.state.selectedBreed)
                    }
                    try {
                        await httpService.competitionDateAddOrUpdate(data)

                        this.closeModal()
                        this.setState({ currentIndex: null, selectedBreed: null })


                    } catch (err) {

                        alert(err.message)

                    }

                }
            })

        }
    }

    getData = async () => {
        let d = await httpService.getCompetitionDate(this.props.data.id)
        let recorders = await httpService.getRegisteredUsers({ page: null, username: '', usertype: UserType.RECORDER });
        let ringLeaders = await httpService.getRegisteredUsers({ page: null, username: '', usertype: UserType.RING_LEADER });
        this.setState({ data: d, recorders: recorders, ringLeaders: ringLeaders })
    }

    removeSpecialBreed(index: any) {
        this.props.showDialog(`Are you sure you want to delete this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {
                let data = [...this.state.breedArray]
                data.splice(index, 1)
                this.setState({ breedArray: data, currentIndex: null }, () => {
                    this.props.callback(this.state.breedArray)
                })
                if (this.state.breedArray.length == 0) {
                    this.props.empty(true)
                }

            }
        })
    }


    changeBreed(ev: any, index: any) {
        if (ev === undefined && index === undefined) {
            return
        } else {
            this.setState({ selectedBreed: ev, selectedBreedIndex: index ? index : null })
        }
    }

    setMode(): boolean {
        if (this.props.add) {
            return false
        }
        if (this.state.previewMode) {
            return true
        }
        return false

    }

    removeBISShow(i: any) {
        let data: any = { ...this.state.data };

        data.bisShows.splice(i, 1);
        this.setState({ data: data })
    }

    removeCustomBISShow(i: any) {
        let data: any = { ...this.state.data };

        data.customBISShows.splice(i, 1);
        this.setState({ data: data })
    }

    removeIndigenousBreeds(i: any) {
        let data: any = { ...this.state.data };

        data.bisIndigenousBreeds.splice(i, 1);
        this.setState({ data: data })
    }

    addOrUpdateBIS(bis: any) {
        let data: any = { ...this.state.data };

        if (bis.id) {
            data.bisShows[this.state.bisShowIndex] = bis;
        } else if (!bis.id && this.state.bisShowIndex !== null) {
            data.bisShows[this.state.bisShowIndex] = bis;
        } else if (!bis.id) {
            data.bisShows.push(bis)
        }
        this.setState({ data: data, openBISShows: false, bisShowIndex: null })

    }

    addOrUpdateCustomBIS(bis: any) {
        let data: any = { ...this.state.data };

        if (bis.id) {
            data.customBISShows[this.state.customBisShowIndex] = bis;
        } else if (!bis.id && this.state.customBisShowIndex !== null) {
            data.customBISShows[this.state.customBisShowIndex] = bis;
        } else if (!bis.id) {
            data.customBISShows.push(bis)
        }
        this.setState({ data: data, openCustomBISShows: false, customBisShowIndex: null })

    }

    addOrUpdateBreed(breed: any) {
        let state: any = { ...this.state };

        if (state.bisBreedIndex !== null) {
            state.data.bisIndigenousBreeds[this.state.bisBreedIndex] = breed;
        } else {
            state.data.bisIndigenousBreeds.push(breed)
        }
        this.setState({ data: state.data, openBreeds: false, bisBreedIndex: null })

    }

    openBISShowDialog(index: any) {
        this.setState({ bisShowIndex: index, openBISShows: true })
    }

    openCustomBISShowDialog(index: any) {
        this.setState({ customBisShowIndex: index, openCustomBISShows: true })
    }

    changeProfile = async (ev: any, usertype: any) => {

        let data: any = { ...this.state.data }
        if (ev) {
            let user = await httpService.getRegisteredUser(ev)
            data[usertype] = { "id": user.id, "firstName": user.firstName, "lastName": user.lastName, "username": user.userName }
            this.setState({ data: data });
        }

    }


    render() {

        return (
            <React.Fragment>

                <div>

                    <Modal id="bis" style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.props.open}>
                        <ModalHeader toggle={this.props.action}>{this.props.add ? "Add BIS" : "Edit BIS"}</ModalHeader>
                        <ModalBody>


                            <div>

                                <div className="row">

                                    {!this.props.add && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                        <td><span onClick={() => this.setState({ previewMode: !this.state.previewMode })} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                            className="c-light-blue-500 ti-pencil"></i></span>
                                        </td>
                                    }


                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="recorder">Recorder</label>

                                        <StaffSearch action={(e: any) => this.changeProfile(e, "recorder")} data={this.state.data} type="recorder"
                                            suggestions={this.state.recorder} disabled={this.setMode()} />
                                    </div>

                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="ringLeader">Ring Leader</label>

                                        <StaffSearch action={(e: any) => this.changeProfile(e, "ringLeader")} data={this.state.data} type="ringLeader"
                                            suggestions={this.state.ringLeader} disabled={this.setMode()} />
                                    </div>
                                </div>
                                
                                {!this.state.previewMode && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                    <div style={{ float: "right" }}>
                                        <label className="cur-p mL-10">
                                            <a href="#" onClick={(e: any) => {
                                                e.preventDefault();
                                                this.setState({ openBISShows: true });

                                            }}>
                                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                            </a>
                                        </label>
                                        <br /><br />
                                    </div>
                                }
                                <h5>BIS Shows</h5>
                                {this.state.data && this.state.data.bisShows && this.state.data.bisShows.length > 0 ?
                                    <table className="table table">
                                        <thead>
                                            <tr>

                                                <th>Judge</th>
                                                <th>Type</th>
                                                <th>Is Finished</th>
                                                <th>Is Present Finished</th>
                                                <th>Approximate Start Time</th>

                                                <th style={{ textAlign: "right" }}>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody className="dd-container">
                                            {this.state.data && this.state.data.bisShows && this.state.data.bisShows.length > 0 ? this.state.data.bisShows.map((item: any, index: any) =>

                                                <tr key={index}>

                                                    <td style={{ color: "grey" }}>{item.judge ? item.judge.username : "/"}</td>
                                                    <td style={{ color: "grey" }}>{getBISType(item.type) || "/"}</td>
                                                    <td style={{ color: "grey" }}>{Helpers.booleanToString(item.isFinished)}</td>
                                                    <td style={{ color: "grey" }}>{Helpers.booleanToString(item.isPresentFinished)}</td>
                                                    <td style={{ color: "grey" }}>{(item.approxStartTime && item.approxStartTime)}</td>

                                                    <td className="ta-r">
                                                        <span style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => { this.openBISShowDialog(index) }} className="icon-holder"><i className="c-light-blue-500 fa fa-search"></i></span>
                                                        {!Helpers.isUserEventViewer(this.props.userState.user) && !this.state.previewMode &&
                                                            <>

                                                                <span style={{ fontSize: "16px" }}> | </span>
                                                                <a href="#" onClick={(e) => { e.preventDefault(); this.removeBISShow(index); }}>
                                                                    <span style={{ fontSize: "16px" }} className="icon-holder">
                                                                        <i className="c-light-blue-500 ti-trash"></i>
                                                                    </span>
                                                                </a>
                                                            </>
                                                        }
                                                    </td>
                                                </tr>

                                            ) : <div>No BIS shows.</div>}
                                        </tbody>

                                    </table>
                                    : <div>No BIS shows.</div>
                                }


                                <BISShowsDialog data={this.state.data.bisShows[this.state.bisShowIndex]}
                                    add={this.state.bisShowIndex === null}
                                    open={this.state.openBISShows}
                                    action={() => { this.setState({ bisShowIndex: null, openBISShows: false }) }}
                                    judges={this.props.judges}
                                    save={(d: any) => this.addOrUpdateBIS(d)}
                                />
                                <br />

                                {!this.state.previewMode &&
                                    <div style={{ float: "right" }}>
                                        <label className="cur-p mL-10">
                                            <a href="#" onClick={(e: any) => {
                                                e.preventDefault();
                                                this.setState({ openBreeds: true });

                                            }}>
                                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                            </a>
                                        </label>
                                        <br /><br />
                                    </div>
                                }
                                <h5>BIS Indigenous Breeds</h5>
                                {this.state.data && this.state.data.bisIndigenousBreeds && this.state.data.bisIndigenousBreeds.length > 0 ?
                                    <table className="table table">
                                        <thead>
                                            <tr>

                                                <th>Name</th>
                                                <th>FCI</th>

                                                {!Helpers.isUserEventViewer(this.props.userState.user) && !this.state.previewMode &&
                                                    <th style={{ textAlign: "right" }}>Action</th>
                                                }
                                            </tr>
                                        </thead>

                                        <tbody className="dd-container">
                                            {this.state.data && this.state.data.bisIndigenousBreeds && this.state.data.bisIndigenousBreeds.length > 0 && this.state.data.bisIndigenousBreeds.map((item: any, index: any) =>

                                                <tr key={item.breed.id}>

                                                    <td style={{ color: "grey" }}>{item.breed && item.breed.name}</td>
                                                    <td style={{ color: "grey" }}>{item.breed && item.breed.fci}</td>

                                                    {!Helpers.isUserEventViewer(this.props.userState.user) && !this.state.previewMode &&
                                                        <td className="ta-r">


                                                            <a href="#" onClick={(e) => { e.preventDefault(); this.removeIndigenousBreeds(index) }}>
                                                                <span style={{ fontSize: "16px" }} className="icon-holder">
                                                                    <i className="c-light-blue-500 ti-trash"></i>
                                                                </span>
                                                            </a>


                                                        </td>
                                                    }
                                                </tr>

                                            )
                                            }
                                        </tbody>

                                    </table>
                                    : <div>No breeds</div>
                                }

                                <BISBreedsDialog data={this.state.data.bisIndigenousBreeds[this.state.bisBreedIndex]}
                                    add={this.state.bisBreedIndex === null}
                                    open={this.state.openBreeds}
                                    action={() => { this.setState({ bisBreedIndex: null, openBreeds: false }) }}

                                    save={(d: any) => this.addOrUpdateBreed(d)}
                                />

                                <br />

                                {!this.state.previewMode && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                    <div style={{ float: "right" }}>
                                        <label className="cur-p mL-10">
                                            <a href="#" onClick={(e: any) => {
                                                e.preventDefault();
                                                this.setState({ openCustomBISShows: true });

                                            }}>
                                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                            </a>
                                        </label>
                                        <br /><br />
                                    </div>
                                }
                                <h5>Custom BIS Shows</h5>
                                {this.state.data && this.state.data.customBISShows && this.state.data.customBISShows.length > 0 ?
                                    <table className="table table">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Judge</th>
                                                <th>Is Finished</th>
                                                <th>Is Present Finished</th>
                                                <th>Approximate Start Time</th>

                                                <th style={{ textAlign: "right" }}>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody className="dd-container">
                                            {this.state.data && this.state.data.customBISShows && this.state.data.customBISShows.length > 0 ? this.state.data.customBISShows.map((item: any, index: any) =>

                                                <tr key={index}>
                                                    <td style={{ color: "grey" }}>{item.title}</td>
                                                    <td style={{ color: "grey" }}>{item.judge ? item.judge.username : "/"}</td>
                                                    <td style={{ color: "grey" }}>{Helpers.booleanToString(item.isFinished)}</td>
                                                    <td style={{ color: "grey" }}>{Helpers.booleanToString(item.isPresentFinished)}</td>
                                                    <td style={{ color: "grey" }}>{(item.approxStartTime && item.approxStartTime)}</td>

                                                    <td className="ta-r">
                                                        <span style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => { this.openCustomBISShowDialog(index) }} className="icon-holder"><i className="c-light-blue-500 fa fa-search"></i></span>
                                                        {!Helpers.isUserEventViewer(this.props.userState.user) && !this.state.previewMode &&
                                                            <>

                                                                <span style={{ fontSize: "16px" }}> | </span>
                                                                <a href="#" onClick={(e) => { e.preventDefault(); this.removeCustomBISShow(index) }}>
                                                                    <span style={{ fontSize: "16px" }} className="icon-holder">
                                                                        <i className="c-light-blue-500 ti-trash"></i>
                                                                    </span>
                                                                </a>
                                                            </>
                                                        }
                                                    </td>
                                                </tr>

                                            ) : <div>No custom BIS shows.</div>}
                                        </tbody>

                                    </table>
                                    : <div>No custom BIS shows.</div>
                                }

                                <CustomBISShowsDialog data={this.state.data.customBISShows[this.state.customBisShowIndex]}
                                    add={this.state.customBisShowIndex === null}
                                    open={this.state.openCustomBISShows}
                                    action={() => { this.setState({ customBisShowIndex: null, openCustomBISShows: false }) }}
                                    judges={this.props.judges}
                                    save={(d: any) => this.addOrUpdateCustomBIS(d)}
                                />

                            </div>


                        </ModalBody>
                        <ModalFooter>

                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                <Button color="primary" disabled={this.state.disabledButton} onClick={() => { this.saveBIS(this.state.data); }}>Save</Button>
                            }

                            <Button color="secondary" onClick={() => {
                                this.setState({ data: null })
                                this.props.action();


                            }}>Close</Button>
                        </ModalFooter>

                    </Modal>



                </div>

            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => { return { dialog: state.dialog, dataState: state.competitionList, userState: state.user, users: state.registeredUserList } },
    DialogStore.actionCreators
)(BISDialogComponent)
