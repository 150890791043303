import * as React from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router';
import {httpService} from '../../index';
//import ContentStatus from '../../components/ContentStatus'
import {ApplicationState} from '../../store';
import * as DialogStore from '../../store/DialogStore';
import * as CompetitionClassStore from '../../store/CompetitionClassStore';
import {ListBase} from '../base/ListBase';
import {Helpers} from '../../utility/Helpers';
import ContentStatus from '../../components/ContentStatus';
import {Link} from 'react-router-dom';
import {CompetitionClass} from "../../models/registrations/Registrations";
import CompetitionClassDetails from "./CompetitionClassDetails";
import CompetitionClassEdit from "./CompetitionClassEdit";

class CompetitionClassList extends ListBase {

    constructor(props: any, context: any) {
        super(props, context, 'competition-class');
        this.deleteItemCall = async (id: string) => {
            return await httpService.deleteCompetitionClass(id)
        }
    }

    render() {
        return (
            <div className="row gap-20 masonry pos-r">
                <div className="masonry-item col-md-5">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <h4 className="c-grey-900 mB-20" style={{float: "left"}}>Competition Class List</h4>
                        {/*<div style={{float: "right", verticalAlign: "middle"}}>*/}
                        {/*    <Link to='/competition-class/add'>*/}
                        {/*        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}

                        <div style={{clear: "both"}}/>

                        <ContentStatus isLoading={this.props.dataState.isLoading}
                                       errorMessage={this.props.dataState.error}
                                       data={this.props.dataState.list}></ContentStatus>

                        {(!this.props.dataState.isLoading && !this.props.dataState.error && this.props.dataState.list && this.props.dataState.list.length > 0) &&
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col" className="ta-r mw-actions">Actions</th>
                                </tr>
                                </thead>
                                <tbody className="dd-container" ref={this.dragulaDecorator}>
                                {this.props.dataState.list.map((competitionClass: any, index: number) => (
                                    <tr className={`${this.getActiveClass(competitionClass)} ${Helpers.getPublishedClass(competitionClass)}`}
                                        key={competitionClass.id}>
                                        <td className="cur-p" onClick={() => {
                                            this.showDetails(index);
                                        }}>
                                            {competitionClass.name}
                                        </td>

                                        <td className="ta-r">
                                            <Link to={`/competition-class/edit/${competitionClass.id}`}>
                                                <span style={{fontSize: "16px"}} className="icon-holder"><i
                                                    className="c-light-blue-500 ti-pencil"></i></span>
                                            </Link>
                                            {/*<span style={{fontSize: "16px"}}> | </span>*/}
                                            {/*<a href="##" onClick={(e) => {*/}
                                            {/*    e.preventDefault();*/}
                                            {/*    this.deleteItem(index, competitionClass.name)*/}
                                            {/*}}>*/}
                                            {/*    <span style={{fontSize: "16px"}} className="icon-holder"><i*/}
                                            {/*        className="c-light-blue-500 ti-trash"></i></span>*/}
                                            {/*</a>*/}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>}

                    </div>
                </div>
                <div className="masonry-item col-md-7">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <Route exact path='/competition-class/details/:itemId?' component={CompetitionClassDetails}/>
                        <Route exact path='/competition-class/edit/:itemId?' component={CompetitionClassEdit}/>
                        <Route exact path='/competition-class/add' component={CompetitionClassEdit}/>
                    </div>
                </div>
            </div>)
    }
};

const mapStateToProps = (state: ApplicationState) => {
    return {dataState: state.competitionClassList, userState: state.user};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        selectItem: (data: CompetitionClass) => {
            dispatch(CompetitionClassStore.actionCreators.selectItem(data))
        },
        getItems: () => {
            dispatch(CompetitionClassStore.actionCreators.getItems())
        },


        //    reorderItem: (id: string, pos?: number) => { dispatch(CompetitionListStore.actionCreators.reorderItem(id, pos)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompetitionClassList);
