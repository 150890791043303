import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import { httpService } from "../../index";
import * as DialogStore from '../../store/DialogStore';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import { Coupon } from '../../models/competition/Competition';
import { Helpers } from '../../utility/Helpers';
import DateTimePicker from '../../components/DateTimePicker';
import { NotificationType } from '../../store/DialogStore';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import RegistrationLoader from '../RegistrationLoader';
import { getDiscountType, getDiscountTypeLabel } from '../../models/competition/CompetitionEnums';
import CouponCode from './CouponCode';


export type CompDateDialogData = {
    data: Coupon,
    callback: Function,
    open: boolean,
    compDateId: string,
    addMode: boolean
}

class CouponDialog extends React.PureComponent<any, any> {

    time: any;

    constructor(props: any) {
        super(props);
        this.state = {
            coupon: null,
            couponCodes: this.props.data.couponCodes,
            isEmpty: false,
            isOpen: false,
            previewMode: true,
            disableButton: false
        }
    }

    componentDidMount() {
        this.isDataSet()
    }

    close(res: boolean) {
        this.setState({ coupon: null })
        return;
    }

   
    saveChanges(data: any, save: boolean) {

        if (data && save) {
            this.props.showDialog(`Are you sure you want to save this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {

                    try {
                        this.setState({ disableButton: true })
                        let response = await httpService.couponAddOrUpdate(data)
                        this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });
                        this.setState({ coupon: null, isOpen: false, couponCodes: [], isEmpty: false })
                        this.props.callback(true)
                        this.setState({ disableButton: false })
                       
                    } catch (err) {
                        alert(err.message)
                        this.setState({ disableButton: false })

                    }
                }
            })

        }
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let val = value;

        let at: any = { ...this.state.coupon };
        at[name] = val

        this.setState({ coupon: at })
        
    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.coupon };
        data[res.name] = res.value;
        this.setState({
            coupon: data
        })
    }
    addCouponCodes = (couponArray: any) => {
        let data: any = { ...this.state.coupon }
        data.couponCodes = couponArray
        this.setState({ coupon: data })
    }


    isCodesArrayEmpty = () => {
        this.setState({ isEmpty: true })
    }

    isDataSet() {
        this.setState({ coupon: this.props.data && this.props.data })
        if (!this.state.couponCodes) {
            this.setState({ couponCodes: this.props.data.couponCodes })
        }
    }

    openEdit() {
        this.setState({ previewMode: !this.state.previewMode})
    }

    setMode(): boolean {
        if (this.props.addMode) {
            return false
        } else if (this.state.previewMode) {
            return true
        }
        return false


    }


    render() {
        return (
            <React.Fragment>
                {
                    (this.props.data) &&
                    <Modal id="comp-date" size="lg" isOpen={this.state.coupon}>
                        <ModalHeader toggle={() => this.props.callback(false)}>Coupon</ModalHeader>
                        <ModalBody>
                            
                                {!this.state.coupon ? <RegistrationLoader/> :
                                    <div>
                                        <div className="row">

                                            {!Helpers.isUserEventViewer(this.props.userState.user) && !this.props.addMode && 
                                                <td><span onClick={() => this.openEdit()} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                                    className="c-light-blue-500 ti-pencil"></i></span>
                                                </td>
                                            }


                                        </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="title">Title *</label>
                                        <input id="title" name="title" className="form-control"
                                                defaultValue={this.state.coupon.title} onChange={this.changeData}
                                                disabled={this.setMode()}
                                            maxLength={2000} />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="validFrom">Valid From *</label>
                                        <DateTimePicker name="validFrom" minDate='' isUtc={false} useTime={true}
                                                maxDate=''
                                                disabled={this.setMode()}
                                            value={Helpers.getIsoDate(this.state.coupon.validFrom)}
                                            onChange={(e: any) => { this.dateChange(e) }} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="validTo">Valid To *</label>
                                        <DateTimePicker name="validTo" minDate='' isUtc={false} useTime={true}
                                                maxDate=''
                                                disabled={this.setMode()}
                                            value={Helpers.getIsoDate(this.state.coupon.validTo)}
                                            onChange={(e: any) => { this.dateChange(e) }} />
                                    </div>
                                    <div className="mb-3">
                                        <div className="checkbox checkbox-info peers ai-c">
                                                <input id="isPercentage" name="isPercentage" type="checkbox"
                                                    checked={this.state.coupon.isPercentage} className="peer"
                                                    onChange={(e) => this.changeData(e)}
                                                    disabled={this.setMode()}                                                />
                                                
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="isPercentage">
                                                <span className="peer peer-greed">Is Percentage</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="discount">Discount *</label>
                                            <input id="discount" name="discount" className="form-control"
                                                defaultValue={this.state.coupon.discount} onChange={this.changeData}
                                                disabled={this.setMode()}
                                            maxLength={2000} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="discountType">Discount Type</label>
                                            <select id="discountType" name="discountType"
                                            className="form-control"
                                                value={this.state.coupon && this.state.coupon.discountType && this.state.discountType}
                                                onChange={this.changeData} 
                                                disabled={this.setMode()} >
                                                
                                            <option value="">-- select discount --</option>
                                            {this.state.coupon && getDiscountTypeLabel().map((item: any) => (
                                                <option key={item.id} selected={this.state.coupon && this.state.coupon.discountType && item.value === this.state.coupon.discountType.value} value={item.value}>{getDiscountType(item.value)}</option>
                                            ))}
                                        </select>

                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="invoiceDescription">Invoice Description *</label>
                                            <input id="invoiceDescription" name="invoiceDescription" className="form-control"
                                                disabled={this.setMode()}
                                                defaultValue={this.state.coupon.invoiceDescription} onChange={this.changeData}
                                                maxLength={2000} />
                                        </div>


                                        <br />
                                        <hr />
                                        
                                        <CouponCode couponId={this.state.coupon.id} data={this.state.coupon.couponCodes} editMode={!this.state.previewMode} callback={this.addCouponCodes} empty={this.isCodesArrayEmpty} dateDialog={this.state.showPopup} add={this.props.addMode} />

                                </div>}




                        </ModalBody>
                        <ModalFooter>

                                <Button color="secondary" onClick={() => this.props.callback(false)}>Close</Button>
                                {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                    <Button disabled={this.state.disableButton} color="primary" onClick={() => {
                                        this.saveChanges(this.state.coupon, true)

                                    }}>Save</Button>
                                }
                        </ModalFooter>

                    </Modal>




                }
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.competitionList, userState: state.user };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getCompetitionDate: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: DialogStore.NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
};




export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CouponDialog);
