import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import * as DialogStore from '../store/DialogStore';

type DialogProps =
    DialogStore.DialogState &
    typeof DialogStore.actionCreators &
    React.ComponentProps<any>;

export type DialogData = {
    message: any,
    type: DialogStore.DialogType,
    callback: Function
}

class Dialog extends React.PureComponent<DialogProps, { showPopup: boolean }> {

    time: any;

    constructor(props: any) {
        super(props);

        window.alert = this.props.showDialog;
        this.state = { showPopup: false }
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.dialogData != prevProps.dialogData) {
            this.time = setTimeout(() => { this.setState({ showPopup: (this.props.dialogData !== null) }); this.clearTimeout(); }, 0);
        }
    }

    componentWillUnmount() {
        this.clearTimeout();
    }

    private clearTimeout() {
        if (this.time) {
            clearTimeout(this.time);
            this.time = null;
            console.log('Timeout cleared');
        }
    }

    close(res: boolean) {
        this.setState({ showPopup: false });
        this.time = setTimeout(() => {
            if (this.props.dialogData.callback) {
                this.props.dialogData.callback(res);
            }

            this.props.hideDialog(null);
        }, 500)
    }

    render() {
        return (
            <React.Fragment>
                {
                    (this.props.dialogData) &&
                    <div className={`modal fade ${(this.state.showPopup) ? 'show' : ''}`} aria-hidden="false">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Dialog</h5>
                                    <button type="button" onClick={() => { this.close(false) }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" dangerouslySetInnerHTML={{ __html: this.props.dialogData.message as string }} />
                                {this.props.dialogData.type === DialogStore.DialogType.Confirm && <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => { this.close(false) }} data-bs-dismiss="modal">No</button>
                                    <button type="button" className="btn btn-primary" onClick={() => { this.close(true) }}>Yes</button>
                                </div>}
                                {this.props.dialogData.type === DialogStore.DialogType.Alert && <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => { this.close(false) }} data-bs-dismiss="modal">Ok</button>
                                </div>}
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.dialog,
    DialogStore.actionCreators
)(Dialog);