import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import {connect} from 'react-redux';
import {RegisteredUser, Role} from '../../models/registered-user/RegisteredUser';
import {history, httpService} from '../../index';
import {ApplicationState} from '../../store';
import * as RegisteredUserStore from '../../store/RegisteredUserStore';
import * as DialogStore from '../../store/DialogStore';
import {DetailsBase} from '../base/DetailsBase';
import {NotificationData} from '../../store/DialogStore';
import {OwnerBreederCountry} from '../../models/country/Country';

import CountrySearch from '../../components/CountrySearch';


class RegisteredUserEdit extends DetailsBase<RegisteredUser> {

    codeError: string;
    propsForTrim: Array<string>;

    constructor(props: any) {
        super(props);

        this.codeError = '';
        this.propsForTrim = ['id', 'email', 'firstName', 'lastName', 'city', 'address', 'username'];

        this.getItem = async (id: string) => {
            return await httpService.getRegisteredUser(id)
        }
        this.saveItem = async (data: RegisteredUser) => {
            return await httpService.registeredUserAddOrUpdate(data)
        }
        this.redirectAfterSave = (id: string) => {
            history.replace(`/registered-user/details/${id}`)
        }

        this.emptyData = {
         
            userName: '',
            firstName: '',
            lastName: '',
            phoneNumber: { prefix: '', number: '' },
            city: '',
            address: '',
            emailConfirmed: false,
            country: { id: '', name: '', created: new Date(), phonePrefix: '' },
            roles: []

        }

    }

    


    onSubmit = (ev: any) => {
        ev.preventDefault();

        let data: any = { ...this.state.data };

        this.trimProps(this.propsForTrim, data);

        this.saveChanges(data as RegisteredUser);
        
    }

    changeCountry(item: any) {
        let data: any = { ...this.state.data };
        data.country = item;
        this.setState({ data: data })
    }


    changeData = (ev: any, type?: string) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let val = value;


        let data: any = { ...this.state.data };

        if (target.name === "prefix" || target.name === "number") {
            if (!data.phoneNumber) {
                data.phoneNumber = {}
            }
            data.phoneNumber[name] = val
        }

        if (type) {
          
            let role = data.roles.find((o: Role) => o.htmlId === type)
            if (!role) {
                data.roles.push(this.props.registeredUserState.roles.find((r: Role) => r.htmlId === type))
                
            } else {
                let filtered = data.roles.filter((i: Role) => i.htmlId !== type);
                data.roles = filtered
            }
            this.setState({
                data: data
            })
            return
        }

        if (data.hasOwnProperty(name)) {
            data[name] = val
        } else if (!data.hasOwnProperty(name) && !(name in ["role", "country", "prefix", "number"])) {
            data.name = ""
            data[name] = val
        }

        this.whitespaceTrimProps(this.propsForTrim, data, name);

        this.setState({
            data: data
        })
    }


    dateChange = (res: any) => {
        let data: any = { ...this.props.data };

        data[res.name] = res.value;

        this.props.dataChanged(data);
    }

    changePassword(value: string) {
        if (this.state.data) {
            this.state.data.password = value;
            this.setState({ data: this.state.data });
        }
    }

    changeRePassword(value: string) {
        this.setState({ retypePassword: value });
    }

    changeForcePassword(value: boolean) {
        if (this.state.data) {
            this.state.data.forcePasswordChange = value;
            this.setState({ data: this.state.data });

        }
    }

    changeShowPassword(value: boolean) {
        if (this.state.data) {

            if (!value) {
                delete this.state.data.password;
                this.setState({ retypePassword: '', data: this.state.data });
            }

            if (value) {
                this.setState({ showPassword: value });
            } else {
                this.setState({ showPassword: value });

            }
        }
    }


    render() {

        return (
            <div>
                <h4 className="c-grey-900">{this.isNew(this.state) && !this.state.isLoading ? "Add" : "Edit"} Registered User</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                    data={this.state.data}></ContentStatus>
                <br />
                {(this.state && this.state.data) &&
                    <form onSubmit={this.onSubmit}>
                        <div className="mb-3 checkbox checkbox-info peers ai-c">
                            <input id="emailConfirmed" name="emailConfirmed" type="checkbox"
                                checked={this.state.data.emailConfirmed} className="peer"
                                onChange={this.changeData} />
                            <label htmlFor="emailConfirmed" className="form-label peers peer-greed js-sb ai-c">
                                <span className="peer peer-greed">Email Confirmed</span>
                            </label>
                        </div>
                        <div className="row">

                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="firstName">First Name *</label>
                                <input id="firstName" name="firstName" required className="form-control"
                                    value={this.state.data.firstName || ''} onChange={this.changeData}
                                    maxLength={2000} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="lastName">Last Name *</label>
                                <input id="lastName" name="lastName" required className="form-control"
                                    value={this.state.data.lastName || ''} onChange={this.changeData}
                                    maxLength={2000} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="userName">Username *</label>
                                <input id="userName" name="userName" required className="form-control"
                                    disabled={this.state.data.id ? true : false}
                                    value={this.state.data.userName || ''} onChange={this.changeData}
                                    maxLength={2000} />
                            </div>
                            

                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="prefix">Prefix</label>
                                <select id="prefix" name="prefix" className="form-control"
                                    value={this.state.data.phoneNumber && this.state.data.phoneNumber.prefix}
                                    onChange={this.changeData}>
                                    <option value="">-- select phone prefix --</option>
                                    {this.props.registeredUserState.countries.map((item: OwnerBreederCountry, index: number) => (
                                        <option key={index}
                                            value={item.phonePrefix!}>{item.phonePrefix!}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="number">Phone Number</label>
                                <input id="number" name="number" className="form-control"
                                    defaultValue={this.state.data.phoneNumber && this.state.data.phoneNumber.number || ""}
                                    onChange={this.changeData} maxLength={2000} />
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="city">City *</label>
                                <input id="city" name="city" required className="form-control"
                                    defaultValue={this.state.data.city || ''} onChange={this.changeData}
                                    maxLength={2000} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="address">Address *</label>
                                <input id="address" name="address" required className="form-control"
                                    defaultValue={this.state.data.address || ''} onChange={this.changeData} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="country">Country *</label>
                                <CountrySearch action={(item: any) => this.changeCountry(item)} data={this.state.data}
                                    suggestions={this.props.registeredUserState.countries} />                      
                            </div>
                           
                            {this.props.registeredUserState.roles && this.props.registeredUserState.roles.slice(1).map((role: any) => (
                                
                                <div className="mb-3 col-md-3">
                                    <div className="mb-3 checkbox checkbox-info peers ai-c">
                                        <input id={role.htmlId} name={role.htmlId} type="checkbox"
                                            checked={(this.state.data!.roles!.find((o: any) => o.name === role.name) && true)}
                                            className="peer"
                                            onChange={(e: any) => this.changeData(e, role.htmlId)} />
                                        <label htmlFor={role.htmlId}
                                            className="form-label peers peer-greed js-sb ai-c">
                                            <span className="peer peer-greed">{role.name}</span>
                                        </label>
                                    </div>
                                </div>
                                
                                ))}

                            
                        </div>
                        <h5>Password</h5>

                        {(this.state.data.id) &&
                            <div className="mb-3 checkbox checkbox-info peers ai-c">
                                <input id="changePasswordChange" type="checkbox" checked={this.state.showPassword}
                                    name="changePasswordChange" className="peer" onChange={(ev) => {
                                        this.changeShowPassword(ev.target.checked)
                                    }} />
                                <label htmlFor="changePasswordChange"
                                    className="form-label peers peer-greed js-sb ai-c">
                                    <span className="peer peer-greed">Change Password</span>
                                </label>
                            </div>
                        }

                        {(!this.state.data.id || this.state.showPassword) &&
                            <div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="password">New Password *</label>
                                    <input type="password" required className="form-control" id="password"
                                        value={this.state.data.password} onChange={(ev) => {
                                            this.changePassword(ev.target.value)
                                        }} placeholder="Enter password" pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,100})"
                                        title="Passwords must be at least 10 characters in length and must contain lowercase, uppercase and digit." />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="repassword">Retype Password *</label>
                                    <input type="password" required className="form-control" id="repassword"
                                        value={this.state.retypePassword} onChange={(ev) => {
                                            this.changeRePassword(ev.target.value)
                                        }} placeholder="Enter password" />
                                </div>
                            </div>
                        }
                        <div>
                            <div className="mb-3 checkbox checkbox-info peers ai-c">
                                <input id="forceChange" type="checkbox" checked={this.state.data.forcePasswordChange}
                                    name="forceChange" className="peer" onChange={(ev) => {
                                        this.changeForcePassword(ev.target.checked)
                                    }} />
                                <label htmlFor="forceChange" className="form-label peers peer-greed js-sb ai-c">
                                    <span className="peer peer-greed">Force password change</span>
                                </label>
                            </div>
                        </div>
                        <br />
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {registeredUserState: state.registeredUserList};
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: RegisteredUser) => {
            dispatch(RegisteredUserStore.actionCreators.selectItem(data))
        },
        getItems: (page: number, username?: string) => {
            dispatch(RegisteredUserStore.actionCreators.getItems(page, username))
        },
        getCountryItems: () => {
            dispatch(RegisteredUserStore.actionCreators.getCountryItems())
        },
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisteredUserEdit)
