import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';
import * as DialogStore from '../../store/DialogStore';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as PedigreeAbbreviationStore from '../../store/PedigreeAbbreviationStore';
import { DetailsBase } from '../base/DetailsBase';
import DetailsHeader from '../../components/DetailsHeader';
import { Helpers } from '../../utility/Helpers';
import { PedigreeAbbreviation } from '../../models/dog/PedigreeAbbreviation';


class PedigreeDetails extends DetailsBase<PedigreeAbbreviation> {

    constructor(props: any) {
        super(props);
        this.getItem = async (id: string) => {
            let res = await httpService.getPedigreeAbbreviation(id);
            return res;
        }
        this.saveItem = async (data: PedigreeAbbreviation) => { return await httpService.pedigreeAbbreviationAddOrUpdate(data) }
        this.deleteItemCall = async (id: string) => { return await httpService.deletePedigreeAbbreviation(id) }
    }



    render() {
        return (
            <div>
                <DetailsHeader baseUrl="pedigree-abbreviation" data={this.state.data}
                               title={this.state.data ? this.state.data.abbrevation : "Pedigree Abbreviation Preview"}
                               onDelete={() => {
                                   this.deleteItem()
                               }}/>

                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error} data={this.state.data}></ContentStatus>
                {(this.state && this.state.data) &&
                    <div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Created:</div>
                            <div className="mb-1 col-md-9"> {Helpers.getLocalDateTimeString(this.state.data.created)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Abbreviation:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.abbrevation}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Country:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.country}</div>

                        </div>
                      

                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.pedigreeList };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: PedigreeAbbreviation) => { dispatch(PedigreeAbbreviationStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(PedigreeAbbreviationStore.actionCreators.getItems()) },
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: DialogStore.NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PedigreeDetails);
