import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';

import * as DialogStore from '../../store/DialogStore';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as BreedCategoryStore from '../../store/BreedCategoryStore';
import { DetailsBase } from '../base/DetailsBase';
import DetailsHeader from '../../components/DetailsHeader';
import { Helpers } from '../../utility/Helpers';
import { Breed, BreedBase, BreedCategory } from '../../models/dog/Breed';
import { Link } from 'react-router-dom';


class BreedCategoryDetails extends DetailsBase<BreedCategory> {

    constructor(props: any) {
        super(props);
        this.getItem = async (id: string) => {
            let res = await httpService.getBreedCategory(id);
            this.props.getBreedsByFci(res.fci);
            return res;
        }

    }

    render() {
        return (
            <div>
                <DetailsHeader baseUrl="fci-category" data={this.state.data}
                               title={this.state.data ? this.state.data.name : "FCI Category Preview"}
                               onDelete={() => {
                                   this.deleteItem()
                               }}/>

                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                               data={this.state.data}></ContentStatus>
                {(this.state && this.props.dataState && this.state.data) &&
                    <div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Created:</div>
                            <div className="mb-1 col-md-9"> {Helpers.getLocalDateTimeString(this.state.data.created)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Name:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.name}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">English Name:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.engName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">German Name:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.germanName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Croatian Name:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.croatianName}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">FCI:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.fci.toString()}</div>

                        </div>

                        <div>
                            <br/>
                            <h5>Breeds</h5>
                            {this.state.data && this.state && this.props.dataState && this.props.dataState.breeds.length > 0 && this.props.dataState.breeds.filter((breed: any) => breed.fci === this.state.data!.fci).map((breed: any) =>

                                <li key={breed.id} className="mb-1 col-md-6">{breed.name}</li>
                                 
                                )}
                        </div>

                    </div>
                    
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.breedCatList };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: BreedCategory) => { dispatch(BreedCategoryStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(BreedCategoryStore.actionCreators.getItems()) },
        getBreedsByFci: (category: number) => { dispatch(BreedCategoryStore.actionCreators.getBreedsByFci(category)) },
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: DialogStore.NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreedCategoryDetails);
