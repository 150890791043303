import React from "react";
import {connect} from "react-redux";
import '../custom.scss';
import * as DialogStore from '../store/DialogStore';
import {DialogType, NotificationData, NotificationType} from '../store/DialogStore';
import {CompetitionDog} from "../models/registrations/Registrations";
import {ApplicationState} from "../store";

import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import * as DogListStore from "../store/DogListStore";
import ContentEditor from "./ContentEditor";
import {Dog} from "../models/dog/Dog";
import HttpService from "../services/httpService";
import {httpService} from "../index";


type EmailProps = {
    email?: string
    open?: boolean

}

class ContactUserComponent extends React.Component<any> {

    codeError: string;

    constructor(props: any) {
        super(props);

        this.codeError = '';

    }

    state = {modal: this.props.open, open: false, data: {to: this.props.email, subject: '', message: ''}, disableButton: false}

    toggle = () => {
        this.props.setOpen(false)
    }


    onSubmit = async (ev: any) => {
        ev.preventDefault();

        let data: any = {...this.state.data};

        this.setState({
            data: data
        })
        this.setState({ disableButton: true })
        let email = await httpService.contactUser(data)
        if (email) {
            this.props.showNotification({message: 'Email successfully sent.', type: NotificationType.Success});
            this.props.setOpen(false)
            this.setState({ disableButton: false })
        } else {
            this.props.showNotification({message: 'Email not sent.', type: NotificationType.Danger});
            this.setState({ disableButton: false })
        }
    }

    changeData = (ev: any, type?: any) => {
        let data: any = {...this.state.data};
        if (type === "message") {
            data[type] = ev;
            this.setState({
                data: data
            })
            return

        }
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let val = value;

        data[target.name] = val;


        this.setState({
            data: data
        })
    }

    render() {
        return (
            <React.Fragment>

                <div>
                    <Modal style={{maxWidth: '700px', width: '100%'}} size="lg" isOpen={this.props.open}
                           toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>Contact User</ModalHeader>
                        <ModalBody>
                            <form onSubmit={this.onSubmit}>
                                <div className="row">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="to">To:</label>
                                        <input id="to" required name="to" defaultValue={this.props.email}
                                               className="form-control"
                                               onChange={this.changeData}
                                               maxLength={2000}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="subject">Subject:</label>
                                        <input id="subject" name="subject" required
                                               defaultValue={this.state.data.subject || ""} className="form-control"
                                               onChange={this.changeData}
                                               maxLength={2000}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="message">Message:</label>
                                        <ContentEditor onValueChange={(e: any) => this.changeData(e, "message")}
                                                       required value={this.state.data.message}/>
                                    </div>
                                </div>
                                <div style={{float: "right"}}>
                                    <button disabled={this.state.disableButton} style={{marginRight: "10px"}} type="submit"
                                            className="btn btn-primary btn-color">Send
                                    </button>
                                    <button onClick={this.toggle} className="btn btn-secondary btn-color">Close</button>
                                </div>
                            </form>
                        </ModalBody>


                    </Modal>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {dataState: state.dogList};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setOpen: (open?: any) => {
            dispatch(DogListStore.actionCreators.setOpen(open))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactUserComponent);
