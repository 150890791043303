import React, { Component, Fragment } from "react";
import { ApplicationState } from "../store";
import * as DialogStore from "../store/DialogStore";
import { connect } from "react-redux";
import ReactDOM from "react-dom";

type defaultProps = {
    suggestions: [],
    data: any,
    action?: any,
    disabled: boolean

};

class BreedSearch extends Component<any> {
    timer: any;

    constructor(props: any) {
        super(props);

    }

    state = {
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: this.props.suggestions,
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // What the user has entered
        userInput: this.props.data && this.props.data.breed ? this.props.data.breed.name : '',
        // The current value of the input field
        inputValue: this.props.data && this.props.data.breed ? this.props.data.breed.name : ''
    };
    handleChange = (e: any) => {
        const userInput = e.currentTarget.value;
        const filteredSuggestions = this.props.suggestions.filter((breed: any) => breed.name.toUpperCase().includes(userInput.toUpperCase()));

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: filteredSuggestions,
            showSuggestions: true,
            userInput: userInput,
            inputValue: userInput
        });

    };

    handleClick = (e: any, id?: any) => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: this.props.suggestions,
            showSuggestions: !this.state.showSuggestions
        });

        if (e.currentTarget.innerText !== "") {
            let input = this.props.suggestions.find((breed: any) => breed.id === id);
            this.props.action(input)
            this.setState({
                userInput: input.name,
                inputValue: input.name 
            });
        }

    };

    render() {

        let suggestionsListComponent;

        if (this.state.showSuggestions) {
            if (this.state.filteredSuggestions && this.state.filteredSuggestions.length > 0) {
                suggestionsListComponent = (
                    <div style={{ position: 'relative' }}>
                        <ul style={{ position: 'absolute', top: '100%', left: 0, background: "white", zIndex:10 }} className="suggestions">
                            {this.state.filteredSuggestions.map((suggestion: any, index: any) => {
                                let className;

                                // Flag the active suggestion with a class
                                if (index === this.state.activeSuggestion) {
                                    className = "suggestion-active";
                                }

                                return (
                                    <li className={className} key={suggestion.id} onClick={(e: any) => this.handleClick(e, suggestion.id)}>
                                        {suggestion.name}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions.</em>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <input
                    id="breedSearch"
                    name="breedSearch"
                    required
                    className="form-control"
                    type="text"
                    onClick={(e: any) => this.handleClick(e, e.target.dataset.key)}
                    onChange={(e: any) => this.handleChange(e)}
                    placeholder="-- select breed --"
                    autoComplete="off"
                    disabled={this.props.disabled}
                    value={this.state.userInput}
                />
                {suggestionsListComponent}
                {this.props.data && this.props.data.breed && this.props.data.breed.name &&
                    <input
                        type="hidden"
                        name="breedSearchHidden"
                        value={this.props.data && this.props.data.breed.name}
                    />
                }
            </Fragment>
        );
    }
}

export default (BreedSearch)
