export enum DogGender {
    MALE = 0,
    FEMALE = 1
}

export enum DogStatus {
    CONFIRMED = 0,
    UNCONFIRMED = 1,
    REJECTED = 2

}

export enum DogAttachmentTypes {
    PEDIGREE = 0,
    WORKDOG = 1,
    CHAMPION = 2,
    PASSPORT = 3
}

export function getGender(type: DogGender) {
    switch (type) {
        case DogGender.MALE:
            return 'Male';
        case DogGender.FEMALE:
            return 'Female';
        
    }
}


export function getStatus(type: DogStatus) {
    switch (type) {
        case DogStatus.CONFIRMED:
            return 'Confirmed';
        case DogStatus.UNCONFIRMED:
            return 'Unconfirmed';
        case DogStatus.REJECTED:
            return 'Rejected';

    }
}

export function getAttachmentType(type: DogAttachmentTypes) {
    switch (type) {
        case DogAttachmentTypes.PEDIGREE:
            return 'Pedigree';
        case DogAttachmentTypes.WORKDOG:
            return 'Workdog';
        case DogAttachmentTypes.CHAMPION:
            return 'Champion';
        case DogAttachmentTypes.PASSPORT:
            return 'Passport';
    }
}


export function getDogGender() {
    return [
        { label: 'Female', value: DogGender.FEMALE },
        { label: 'Male', value: DogGender.MALE },
    ]
}



export function getDogAttachmentTypes() {
    return [
        { label: 'Pedigree', value: DogAttachmentTypes.PEDIGREE },
        { label: 'Workdog', value: DogAttachmentTypes.WORKDOG },
        { label: 'Champion', value: DogAttachmentTypes.CHAMPION }
    ]
}

export function getDogStatus() {
    return [
        { label: 'Confirmed', value: DogStatus.CONFIRMED },
        { label: 'Unconfirmed', value: DogStatus.UNCONFIRMED },
        { label: 'Rejected', value: DogStatus.REJECTED }
    ]
}