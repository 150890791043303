import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { httpService } from '../../index';
import { CompetitionRing, Judge, Recorder, RingLeader, SpecialComp } from '../../models/competition/CompetitionDate';
import SpecialCompDialog from '../SpecialCompDialog';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import { Helpers } from '../../utility/Helpers';
import RingsDialog from './RingsDialog';
import { UserType } from '../../models/registered-user/RegisteredUserEnums';
import { Breed } from '../../models/dog/Breed';
import BISDialog from './BISDialog';


type RingsListProps = {
	rings: Array<CompetitionRing>;
	parentId: string;
}

class RingsList extends React.Component<RingsListProps & typeof CompetitionListStore.actionCreators & any, { isOpen: boolean; ring: CompetitionRing | null, rings: Array<CompetitionRing>, judge: Array<Judge>, recorder: Array<Recorder>, ringLeader: Array<RingLeader>, breeds: Array<Breed>, addMode: boolean, bis: any, isBISOpen: boolean }> {

	constructor(props: any) {
		super(props);


		this.state = {
			isOpen: false,
			ring: null,
			rings: this.props.rings,
			judge: this.props.judge,
			recorder: this.props.recorder,
			ringLeader: this.props.ringLeader,
			breeds: this.props.breeds,
			addMode: false,
			bis: null,
			isBISOpen: false,
			
		}
	}

	

	componentDidMount = async () => {
		let breeds = await httpService.catalogBreeds()
		let judges = await httpService.getRegisteredUsers({page: null, username: '', usertype: UserType.JUDGE});
		let recorders = await httpService.getRegisteredUsers({page: null, username: '', usertype: UserType.RECORDER});
		let ringLeaders = await httpService.getRegisteredUsers({page: null, username: '', usertype: UserType.RING_LEADER});
		
		this.setState({ judge: judges, recorder: recorders, ringLeader: ringLeaders, breeds: breeds, bis: null
			})

	}


	updateRings = async () => {
		let d = await httpService.getCompetitionDate(this.props.parentId)
		this.props.getCompetitionDate(d.id);
		this.setState({ rings: d.competitionRings, bis: d.competitionDateBIS ? d.competitionDateBIS : null })

	}
	addRemoveRing(item?: any, remove?: boolean) {
		if (remove) {
			let data: any = [...this.state.rings];
			let ind = data.findIndex((i: CompetitionRing) => i.id === item!.id);
			if (ind !== -1) {
				data.splice(ind, 1);
				this.setState({ rings: data });
			}
		} else if (!remove) {
			let data: any = [...this.state.rings];
			data.push(item)
			this.setState({ rings: data });
		}		
		this.props.getCompetitionDate(this.props.parentId)
	}

	removeRing(item: any) {
		this.props.showDialog(`Are you sure you want to delete this ring?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
			if (confirm) {
				try {
					await httpService.deleteRing(item.id);
					this.addRemoveRing(item, true)
					this.updateRings();

				} catch (err) {
					alert(err.message);
				}
			}
		})
	}


	showDetails = async (id?: string) => {
		this.setState({ isOpen: true })
		if (id) {
			let cd = await httpService.getRing(id)
			
			this.setState({ ring: cd })
		} else {
			this.setState({ ring: { description: '', startTime: new Date(), breeds: [], order: 0, timePerDog: 0, ringFinished: false, competitionDateId: this.props.parentId }, addMode: true })
		}

	}

	async showBISDetails(id: any) {
		let bisDetails = await httpService.competitionDateBIS(id)
		this.setState({ bis: bisDetails, isBISOpen: true })
	}

	removeBIS(item: any) {
		this.props.showDialog(`Are you sure you want to delete this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
			if (confirm) {
				try {
					await httpService.competitionDateBISDelete(item.id);
					this.updateRings()

				} catch (err) {
					alert(err.message);
				}
			}
		})
	}

	openBISDialog() {
		this.setState({
			isBISOpen: !this.state.isBISOpen, bis: {
				id: '',
				competitionDateId: this.props.parentId,
				recorder: {},
				ringLeader: {},
				bisShows: [],
				bisIndigenousBreeds: [],
				customBISShows: []
			}
		}); 
	}

	closeBISDialog = () => {
		
		this.setState({ isBISOpen: !this.state.isBISOpen, bis: null });

	}

	render() {
		return (
			<div className="picture-picker">
				{!Helpers.isUserEventViewer(this.props.userState.user) &&
					<div style={{ float: "right" }}>
						<label className="cur-p mL-10">
							<a href="#" onClick={(e: any) => {
								e.preventDefault()
								this.showDetails()

							}}>
								<div className="bgc-blue-50 c-blue-500 circle-add">+</div>
							</a>
						</label>
						<br /><br />
					</div>
				}
				<div>
					<table className="table">
						<thead>
							<tr>
								<th scope="col">#</th>

								<th scope="col">Description</th>
								<th scope="col">Start Time</th>
								<th scope="col">Dog Count</th>
								
								<th style={{ textAlign: "right" }} scope="col">Actions</th>
								
							</tr>
						</thead>

						<tbody>
							{this.state.rings && this.state.rings.length > 0 && (this.state.rings.map((ring: any, index: number) => (
								<tr key={ring.id}>
									<td style={{ color: "grey" }} className="cur-p">
										
										{index + 1}
									</td>

									<td style={{ color: "grey" }} className="cur-p">
										
										{ring.description}
									</td>
									<td style={{ color: "grey" }} className="cur-p">
										{Helpers.getLocalDateTimeString(ring.startTime)}
									</td>
									<td style={{ color: "grey" }} className="cur-p">

										{ring.dogCount}
									</td>
									<td style={{ textAlign: "right" }}>
										<span style={{ cursor: "pointer", fontSize: "16px" }} className="icon-holder" onClick={() => this.showDetails(ring.id)}><i className="c-light-blue-500 fa fa-search"></i></span>
									{!Helpers.isUserEventViewer(this.props.userState.user) &&
										<>
											<span style={{ fontSize: "16px" }}> | </span>
											<a href="#" onClick={(e) => { e.preventDefault(); this.removeRing(ring) }}>
												<span style={{ fontSize: "16px" }} className="icon-holder">
													<i className="c-light-blue-500 ti-trash"></i>
												</span>
											</a>
										</>
										}
									</td>
										
								</tr>
							)))
							}
						</tbody>
					</table>
					{this.state.rings && this.state.rings.length < 1 &&
						<div style={{ color: "grey", display: "flex", justifyContent: "center" }} className="cur-p">No rings.</div>
					}

				</div>
			
				{this.state.isOpen && this.state.ring && 

					<RingsDialog data={this.state.ring} open={this.state.isOpen} judge={this.state.judge} recorder={this.state.recorder} ringLeader={this.state.ringLeader} breeds={this.state.breeds} parentId={this.props.parentId}
						closePreview={() => { this.setState({ isOpen: false, addMode: false }) }}
						add={(item: CompetitionRing, remove: boolean) => this.addRemoveRing(item, remove)}
						action={this.updateRings}
						addMode={this.state.addMode}

					/>
				}

				<h5>BIS</h5>
				{!this.props.dataState.competitionDate.competitionDateBIS && !Helpers.isUserEventViewer(this.props.userState.user) &&
					<div style={{ float: "right" }}>
						<label className="cur-p mL-10">
							<a href="#" onClick={(e: any) => {
								e.preventDefault()
								this.openBISDialog();
							}}>
								<div className="bgc-blue-50 c-blue-500 circle-add">+</div>
							</a>
						</label>
						<br /><br />
					</div>
				}
				{this.props.dataState.competitionDate.competitionDateBIS ?
					<table className="table">
						<thead>
							<tr>

								<th scope="col">Recorder</th>
								<th scope="col">Ring Leader</th>

								<th style={{ textAlign: "right" }} scope="col">Actions</th>

							</tr>
						</thead>

						<tbody>

							<tr key={this.props.dataState.competitionDate.competitionDateBIS.id}>

								<td style={{ color: "grey" }} className="cur-p">

									{this.props.dataState.competitionDate.competitionDateBIS.recorder ? this.props.dataState.competitionDate.competitionDateBIS.recorder.username : "/"}
								</td>

								<td style={{ color: "grey" }} className="cur-p">

									{this.props.dataState.competitionDate.competitionDateBIS.ringLeader ? this.props.dataState.competitionDate.competitionDateBIS.ringLeader.username : "/"}
								</td>
								<td style={{ textAlign: "right" }}>
									<span style={{ cursor: "pointer", fontSize: "16px" }} className="icon-holder" onClick={() => this.showBISDetails(this.props.dataState.competitionDate.competitionDateBIS.id)}><i className="c-light-blue-500 fa fa-search"></i></span>
									{!Helpers.isUserEventViewer(this.props.userState.user) &&
										<>
											<span style={{ fontSize: "16px" }}> | </span>
											<a href="#" onClick={(e) => { e.preventDefault(); this.removeBIS(this.props.dataState.competitionDate.competitionDateBIS) }}>
												<span style={{ fontSize: "16px" }} className="icon-holder">
													<i className="c-light-blue-500 ti-trash"></i>
												</span>
											</a>
										</>
									}
								</td>

							</tr>


						</tbody>
					</table>
					:  <div>No BIS.</div>
			}
				{this.state.isBISOpen &&
					<BISDialog data={this.state.bis} open={this.state.isBISOpen} action={() => {
						this.closeBISDialog();
				}}
					add={this.state.bis! !== null && this.state!.bis! && !this.state!.bis!['id']}
					update={this.updateRings} recorder={this.state.recorder}
					judges={this.state.judge} ringLeader={this.state.ringLeader} />

				}
			</div>
		)
	}

	static defaultProps: RingsListProps = {
		rings: [],
		parentId: '',
	}
}

const mapStateToProps = (state: ApplicationState) => {
	return {
		dataState: state.competitionList,
		dialog: state.dialog,
		userState: state.user,
		users: state.registeredUserList
	};
}

const mapDispatchToProps = (dispatch: any) => {
	return {

		showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
			dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
		},
		showRingDialog: (d: CompetitionRing, detailsOnly: boolean, cal: Function) => {
			dispatch(DialogStore.actionCreators.showRingDialog(d, detailsOnly, cal))
		},
		getCompetitionDate: (id: string) => {
			dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
		},
		increment: () => {
			dispatch(DialogStore.actionCreators.increment())
		},
		decrement: () => {
			dispatch(DialogStore.actionCreators.decrement())
		},
		hideCompDateDialog: () => {
			dispatch(DialogStore.actionCreators.hideCompDateDialog())
		},
		hideRingDialog: () => {
			dispatch(DialogStore.actionCreators.hideRingDialog())
		},
		showNotification: (data: DialogStore.NotificationData) => {
			dispatch(DialogStore.actionCreators.showNotification(data))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps

)(RingsList)
