import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { CompetitionAttachment } from '../../models/competition/Competition';
import { Helpers } from '../../utility/Helpers';
import FilePicker from '../FilePicker';

type AttachmentDialogProps =
    DialogStore.DialogState &
    typeof DialogStore.actionCreators &
    React.ComponentProps<any>;

export type AttachmentDialogData = {
    data: CompetitionAttachment,
    callback: Function
}

class AttachmentDialog extends React.PureComponent<AttachmentDialogProps, { attach: CompetitionAttachment, showPopup: boolean }> {

    time: any;

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.dataChanged();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.attachmentData != prevProps.attachmentData) {
            this.time = setTimeout(() => {
                this.setState({ showPopup: (this.props.attachmentData !== null), attach: (this.props.attachmentData) ? this.props.attachmentData.data : null });
                this.clearTimeout();
            }, 0)
        }
    }

    componentWillUnmount() {
        this.clearTimeout();
    }

    private clearTimeout() {
        if (this.time) {
            clearTimeout(this.time);
            this.time = null;
        }
    }

    close(res: boolean) {
        this.time = setTimeout(() => {
            if (this.props.attachmentData.callback && res === true) {
                this.props.attachmentData.callback(this.state.attach);
                return;
            } else {
                this.props.hideAttachmentDialog();
            }
        }, 500)
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let val = value;

        let at: any = { ...this.state.attach };
        at[name] = val;

        this.setState({ attach: at });
    }

    fileChanged(file: any) {
        let data = { ...this.state.attach };

        if (data) {
            data.file = file.file;
            data.name = file.name;
            this.setState({ attach: data });
        }
    }

    private dataChanged() {
        this.setState({ showPopup: (this.props.attachmentData !== null), attach: (this.props.attachmentData) ? this.props.attachmentData.data : null });
    }

    render() {
        return (
            <React.Fragment>
                {
                    (this.props.attachmentData) &&
                    <div className={`modal fade ${(this.state.showPopup) ? 'show' : ''}`} aria-hidden="false">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Dialog</h5>
                                    <button type="button" onClick={() => { this.close(false) }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {this.state.attach && <div>
                                        {this.state.attach.name &&
                                            <div>
                                                <a href={this.state.attach.name} target="_blank">
                                                    {Helpers.isImagePath((this.state.attach.file) ? this.state.attach.file.name : this.state.attach.name) && <img
                                                        alt="attachment"
                                                        src={this.state.attach.name}
                                                        style={{
                                                            maxHeight: "400px",
                                                            maxWidth: "300px"
                                                        }} />}

                                                    {Helpers.isImagePath((this.state.attach.file) ? this.state.attach.file.name : this.state.attach.name) === false && <span>
                                                        {Helpers.getAttachmentName((this.state.attach.file) ? this.state.attach.file.name : this.state.attach.name)}
                                                    </span>}
                                                </a>
                                            </div>
                                        }
                                        <br />
                                        <label className="btn btn-primary btn-sm btn-color">CHOOSE A FILE
                                            <FilePicker onChange={(item: any) => { this.fileChanged(item) }}></FilePicker>
                                        </label>
                                        <br />
                                        <br />
                                        <div>
                                            <div className="checkbox checkbox-info peers ai-c">
                                                <input id="displayOnWeb" name="displayOnWeb" type="checkbox"
                                                    checked={this.state.attach.displayOnWeb} className="peer"
                                                    onChange={(e) => this.changeData(e)} />
                                                <label className="form-label peers peer-greed js-sb ai-c" htmlFor="displayOnWeb">
                                                    <span className="peer peer-greed">Display On Web</span></label>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => { this.close(false) }} data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-primary" onClick={() => { this.close(true) }}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.dialog,
    DialogStore.actionCreators
)(AttachmentDialog)