import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import * as DialogStore from '../../store/DialogStore';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import * as HelpersStore from '../../store/HelpersStore';

import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,

} from "reactstrap";
import { ApplicationState } from "../../store";
import { httpService } from "../../index";
import { CompetitionDog, CompetitionRegistration } from "../../models/registrations/Registrations";
import { DialogType, NotificationData, NotificationType } from "../../store/DialogStore";
import RegistrationLoader from "../../components/RegistrationLoader";
import { getPaymentStatusItems } from "../../models/registrations/Registrations"
import { RegisteredUser } from "../../models/registered-user/RegisteredUser";
import ProfileSearch from "../../components/ProfileSearch";
import { Country, OwnerBreederCountry } from "../../models/country/Country";
import { Helpers } from "../../utility/Helpers";
import CountrySearch from "../../components/CountrySearch";
import DateTimePicker from "../../components/DateTimePicker";


type Props = {
    data?: any;
    dogs?: any;
    isOpen?: any;
    action: any;
}


class RegistrationFormComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }
    emptyState: any = {

        paymentStatus: null,
        firstName: '',
        lastName: '',
        email: '',
        created: new Date(),
        city: '',
        address: '',
        country: { id: '', name: '', phonePrefix: '', created: Date() },
        profile: { username: '', firstName: '', lastName: '', id: '' },
        paymentFee: '',
        phoneNumber: { prefix: '', number: '' }

    }

    state: any = {
        isOpen: this.props.isOpen,
        modal: true,
        loading: "",
        isDataLoading: true,
        processing: false,
        editMode: true,
        add: false,
        previewMode: false,
        editItem: "",
        currentIndex: 0,
        registrationId: "",
        dropDown: false,
        dogType: '',
        itemConfirmed: false,
        users: [],
        registrationData: this.props.dataState.registration,
        disableButton: false

    };

    toggle = () => {
        this.setState({
            modal: !this.state.modal

        });
    }

    closeModal = () => {
        this.setState({
            add: !this.state.add,
            isOpen: !this.state.isOpen,
            editMode: false,
            previewMode: false,

        })
        this.props.action()

    }


    openEdit = (id: any, index: number, dogType: string) => {

        this.setState({

            editItem: id,
            previewMode: !this.state.previewMode,
            currentIndex: index,
        })

    }


    openAdd = async () => {
        let users = await this.props.getUsers(0)

        this.setState({
            add: !this.state.add,
            editItem: '',
            isOpen: !this.state.isOpen,
            previewMode: !this.state.previewMode,
            currentIndex: null,
            registrationData: this.emptyState,
            users: users

        })
        this.props.action();
    }

    openPreview = async (id: any, index: number, dogType?: string) => {

        this.setState({
            editItem: id,
            editMode: true,
            previewMode: true,
            currentIndex: index,
            isDataLoading: true



        }, () => {
            setTimeout(() => {
                this.getNewestData(id, index)
            }, 200)

        }
        );

        if (this.state.registrationData) {
            this.setState({ isDataLoading: false })
        }

    }


    getUrl = (type: string, id: string) => {
        return "generate/" + type + "/" + id
    }



    getNewestData = async (id: any, index: any) => {

        let data = await httpService.getCompetitionRegistrationDog(id);
        this.setState({
            registrationData: data
        });
        this.props.getCompetitionDate(this.props.dataState.registration.competitionRegistrationDogs[index]["competitionDate"].id)

    }

    async processClick(type: any, id: any) {

        this.setState({ processing: true });
        this.setState({ loading: type })
        let url = this.getUrl(type, id)
        let response = await httpService.generateDocument(url)

        if (response) {
            window.location.href = (response)
            this.setState({ loading: '' })
        } else {
            alert("Unable to generate file.");
            this.setState({ loading: '' })
        }
    }

    toggleDropdown = () => {
        this.setState({ dropDown: !this.state.dropDown })
    }

    saveChanges(data: any, type?: any) {
        if (data) {

            if (!data.hasOwnProperty("competitionId")) {
                data.competitionId = this.props.dataState.selectedItem.id
            }
            data.competitionRegistrationDogs = []
            data.competitionRegistrationCoupleGroups = []
            data.competitionRegistrationSpecialShow = []


            this.props.showDialog(`Are you sure you want to save this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    try {
                        this.setState({ disableButton: true })
                        let res = await httpService.addOrUpdateRegistration(data);
                        this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });
                        this.props.getRegistrationItems(0, this.props.dataState.selectedItem.id)
                        await this.props.getRegistration(res.id)
                        this.setState({ registrationData: null, modal: false })
                        this.closeModal()
                        this.setState({ disableButton: false })

                    } catch (err) {

                        alert(err.message)
                        this.setState({ disableButton: false })

                    }

                }
            })

        }
    }

    changeData = async (ev: any, index: number, item?: any, type?: any) => {

        const target = ev.target;
        let value: any;
        let name: any;
        if (!target) {
            value = ev

        } else {
            value = target.type === 'checkbox' ? target.checked : target.value;
            name = target.name;
        }

        let registrationData: any = { ...this.state.registrationData }
        let users: any = [...this.props.helperState.users]


        if (!registrationData.hasOwnProperty("competitionId")) {
            let newKey = registrationData.competitionId
            newKey = this.props.dataState.selectedItem.id
        }
        if (name === "paymentAmount") {
            registrationData[name] = (value)

        } if (type === 'profile' && ev !== "") {
            let userVal: any;
            userVal = users.find((i: any) => i.userName === ev);

            let user = await httpService.getRegisteredUser(userVal.id)

            registrationData.profile = {
                username: user.userName,
                firstName: user.firstName,
                lastName: user.lastName,
                id: user.id
            };

            this.setState({ registrationData: registrationData }, () =>
                console.log(this.state.registrationData));

        } if (name === "prefix") {
            let val = this.props.dataState.countries.find((i: OwnerBreederCountry) => i.phonePrefix === value);
            if (!registrationData.phoneNumber) {
                registrationData.phoneNumber = {}
            }
            if (val) {
                registrationData.phoneNumber.prefix = val.phonePrefix
            } else {
                registrationData.phoneNumber.prefix = null
            }

            this.setState({
                registrationData: registrationData
            })

        } if (name === "number") {

            registrationData.phoneNumber.number = value
            this.setState({
                registrationData: registrationData
            })

        } if (!["prefix", "number", "country", "profile", "phoneNumber"].includes(name) && target) {
            registrationData[name] = value

        }
        this.setState({
            registrationData: registrationData
        });

    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.registrationData };
        data[res.name] = res.value;
        this.setState({
            registrationData: data
        })
    }

    changeCountry(item: any) {
        let data: any = { ...this.state.registrationData };
        data.country = item;
        this.setState({ registrationData: data })
    }

    setUser = async (ev: any) => {
        this.props.getSingleUser(ev.id)

    }

    async deleteRegistration(item: any) {


        this.props.showDialog(`Are you sure you want to delete "${item ? item.dog.name : "this item"}?"`, DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {

                try {

                    await httpService.deleteCompetitionRegistrationDog(item.id);

                    this.props.showNotification({ message: 'Successfully deleted.', type: NotificationType.Success });
                    this.props.getRegistration(this.props.dataState.registration.id)
                    this.setState({ dogData: null, modal: false })
                    this.closeModal()

                } catch (err) {
                    alert(err.message);
                }
            }
        })
    }


    render() {
        return (
            <React.Fragment>


                <div>
                    <div>
                        {!this.state.add && !this.props.isOpen &&
                            <div style={{ float: "right", verticalAlign: "middle" }}>
                                {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                    <Button style={{ border: "none", outline: "none" }} onClick={(e: any) => {

                                        this.openAdd();
                                    }}

                                        color="none">
                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                    </Button>
                                }
                            </div>
                        }
                        {this.props.isOpen && (


                            <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.props.isOpen}>
                                <form onSubmit={(e: any) => {
                                    e.preventDefault();
                                    this.saveChanges(this.state.registrationData as CompetitionRegistration, "reg")
                                }}>
                                    <ModalHeader toggle={() => { this.closeModal() }}>{this.state.add ? "Add Registration" : "Edit Registration"}</ModalHeader>
                                    <ModalBody>

                                        <div>

                                            <div className="row">
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="firstName">First name *</label>
                                                    <input id="firstName" name="firstName"
                                                        className="form-control"
                                                        required
                                                        value={this.state.registrationData && this.state.registrationData.firstName && this.state.registrationData.firstName}
                                                        onChange={(e) => this.changeData(e, 0, "", "")}>

                                                    </input>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="lastName">Last name *</label>
                                                    <input id="lastName" name="lastName"
                                                        className="form-control"
                                                        required
                                                        value={this.state.registrationData && this.state.registrationData.lastName && this.state.registrationData.lastName}
                                                        onChange={(e) => this.changeData(e, 0, "", "")}>

                                                    </input>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="email">Email *</label>
                                                    <input id="email" name="email"
                                                        className="form-control"
                                                        required
                                                        value={this.state.registrationData && this.state.registrationData.email && this.state.registrationData.email}
                                                        onChange={(e) => this.changeData(e, 0, "", "")}>

                                                    </input>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="prefix">Prefix *</label>
                                                    <select required id="prefix" name="prefix" className="form-control"
                                                        value={this.state.registrationData && this.state.registrationData.phoneNumber && this.state.registrationData.phoneNumber.prefix}
                                                        onChange={(e) => {
                                                            this.changeData(e, 0)
                                                        }}>
                                                        <option value="">-- select phone prefix --</option>
                                                        {this.props.dataState.countries.map((item: OwnerBreederCountry, index: number) => (
                                                            <option key={index}
                                                                value={item.phonePrefix}>{item.phonePrefix!}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="number">Phone Number *</label>
                                                    <input id="number" name="number" className="form-control"
                                                        required
                                                        value={this.state.registrationData && this.state.registrationData.phoneNumber && this.state.registrationData.phoneNumber.number}
                                                        onChange={(e) => {
                                                            this.changeData(e, 0)
                                                        }} maxLength={2000} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="address">Address *</label>
                                                    <input id="address" name="address"
                                                        className="form-control"
                                                        required
                                                        value={this.state.registrationData && this.state.registrationData.address && this.state.registrationData.address}
                                                        onChange={(e) => this.changeData(e, 0, "", "")}>

                                                    </input>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="city">City *</label>
                                                    <input id="city" name="city"
                                                        className="form-control"
                                                        required
                                                        value={this.state.registrationData && this.state.registrationData.city && this.state.registrationData.city}
                                                        onChange={(e) => this.changeData(e, 0, "", "")}>

                                                    </input>
                                                </div>

                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="country">Country *</label>
                                                    <CountrySearch action={(item: any) => this.changeCountry(item)} data={this.state.registrationData && this.state.registrationData}
                                                        suggestions={this.props.dataState.countries} />
                                                </div>


                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="profile">Profile *</label>
                                                    <ProfileSearch action={this.changeData} data={this.state.registrationData && this.state.registrationData}
                                                        suggestions={this.props.helperState.users} />


                                                </div>


                                                <hr />
                                                <h5>Payment Information</h5>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="paymentStatus">Payment status *</label>
                                                    <select id="paymentStatus" name="paymentStatus"
                                                        className="form-control"
                                                        required

                                                        value={this.state.registrationData && this.state.registrationData.paymentStatus && this.state.registrationData.paymentStatus}
                                                        onChange={(e) => this.changeData(e, 0, this, "")}>
                                                        <option value="">-- select status --</option>
                                                        {getPaymentStatusItems().map((item: any, index: number) => (
                                                            <option key={index} value={item.value}>{item.label}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="paymentIntentId">Payment Intent ID</label>
                                                    <input id="paymentIntentId" name="paymentIntentId" className="form-control"
                                                        value={this.state.registrationData && this.state.registrationData.paymentIntentId && this.state.registrationData.paymentIntentId}
                                                        onChange={(e) => {
                                                            this.changeData(e, 0)
                                                        }} maxLength={2000} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="paymentAmount">Payment Amount</label>
                                                    <input type="number" id="paymentAmount" name="paymentAmount" min="0.00"
                                                        step="0.1" className="form-control"
                                                        value={this.state.registrationData && this.state.registrationData.paymentAmount && this.state.registrationData.paymentAmount}
                                                        onChange={(e) => {
                                                            this.changeData(e, 0)
                                                        }} maxLength={2000} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="paymentFee">Payment Fee</label>
                                                    <input type="number" id="paymentFee" name="paymentFee" className="form-control" min="0.00"
                                                        step="0.1"
                                                        value={this.state.registrationData && this.state.registrationData.paymentFee && this.state.registrationData.paymentFee}
                                                        onChange={(e) => {
                                                            this.changeData(e, 0)
                                                        }} maxLength={2000} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="paymentDateTime">Payment Date and Time</label>
                                                    <DateTimePicker useTime name="paymentDateTime"
                                                        value={this.state.registrationData && this.state.registrationData.paymentDateTime && Helpers.getIsoDate(this.state.registrationData.paymentDateTime)} requiredTime={false}
                                                        onChange={(e: any) => {
                                                            this.dateChange(e)
                                                        }} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="feeInvoiceNumber">Fee Invoice Number</label>
                                                    <input type="datetime" id="feeInvoiceNumber" name="feeInvoiceNumber" className="form-control"
                                                        value={this.state.registrationData && this.state.registrationData.feeInvoiceNumber && this.state.registrationData.feeInvoiceNumber}
                                                        onChange={(e) => {
                                                            this.changeData(e, 0)
                                                        }} maxLength={2000} />
                                                </div>



                                            </div>
                                        </div>


                                    </ModalBody>
                                    <ModalFooter>
                                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                            <Button disabled={this.state.disableButton} type="submit" color="primary">Save</Button>
                                        }
                                        <Button color="secondary" onClick={() => { this.closeModal() }}>Close</Button>
                                    </ModalFooter>
                                </form>
                            </Modal>

                        )
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state: ApplicationState) => {
    return {
        dataState: state.competitionList,
        helperState: state.helpersList,
        userState: state.user
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getItems: () => {
            dispatch(CompetitionListStore.actionCreators.getItems())
        },
        getRegistrationItems: (page?: any, competition?: string, email?: string, count?: number, paymentStatus?: any, catalogNumber?: any, type?: any, competitionDateId?: any, dogOwner?: string, dogName?: string) => {
            dispatch(CompetitionListStore.actionCreators.getRegistrationItems(page, competition, email, count, paymentStatus, catalogNumber, type, competitionDateId, dogOwner, dogName))
        },
        getRegistration: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getRegistration(id))
        },
        getProfileDogs: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getProfileDogs(id))
        },
        getUsers: (page: number, username?: string, count?: number, usertype?: number) => {
            dispatch(HelpersStore.actionCreators.getUsers(page, username, count, usertype))
        },
        getSingleUser: (id: string) => {
            dispatch(HelpersStore.actionCreators.getSingleUser(id))
        },
        getCompetitionDate: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
        },
        setOpenReg: (openReg?: any) => {
            dispatch(CompetitionListStore.actionCreators.setOpenReg(openReg))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
};




export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationFormComponent);
