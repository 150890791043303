import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Button } from "reactstrap";
import { httpService } from '../../index';
import {ApplicationState} from '../../store';
import * as DialogStore from '../../store/DialogStore';
import * as RegisteredUserStore from '../../store/RegisteredUserStore';
import {ListBase} from '../base/ListBase';
import {Helpers} from '../../utility/Helpers';
import ContentStatus from '../../components/ContentStatus';
import RegisteredUserEdit from './RegisteredUserEdit';
import RegisteredUserDetails from './RegisteredUserDetails';
import {RegisteredUser} from '../../models/registered-user/RegisteredUser';
import {Link} from "react-router-dom";
import {DogStatus} from "../../models/dog/DogEnums";
import {getUserRoleTitle, UserType} from "../../models/registered-user/RegisteredUserEnums";

const EXPORT_USERS = 'export_users';

class RegisteredUserList extends ListBase {

    constructor(props: any, context: any) {
        super(props, context, 'registered-user');
        this.deleteItemCall = async (id: string) => {
            return await httpService.registeredUserDelete(id)
        };
        this.state = {
            loading: ''
        }
    }

    public componentDidMount() {
        super.componentDidMount();
        this.props.getCountryItems();
        this.props.setRole(UserType.ALL)
        this.props.setName("")
        this.props.setPage(0)
        this.props.getAllRoles();
        

    }
    async exportUsers() {
        let action = async () => { return await httpService.exportUsers() }

        try {
            this.setState({ loading: EXPORT_USERS });

            let res = await action();
            
            this.setState({ loading: '' });
        } catch (err) {
            this.setState({ loading: '' });
            alert(err.message ? err.message : err.title);
        }

    };

    render() {
        let timer: any;
        return (
            <div className="row gap-20 masonry pos-r" >
                <div className="masonry-item col-md-5">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <div>
                            <h4 className="c-grey-900 mB-20" style={{ float: "left" }}>User List</h4>
                            <div style={{ float: "right", verticalAlign: "middle" }}>
                                <Button color="info"
                                    style={{ color: "white" }}
                                    onClick={() => {
                                        this.exportUsers()
						            }}
                                    disabled={this.state.loading === EXPORT_USERS}>
                                    Export users
                                    {this.state.loading === EXPORT_USERS &&
                                        <i className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", marginLeft: "10px" }} />
                                    }
                                </Button>
                            </div>
                        </div>
                        <div style={{ clear: "both" }} />

                        <div style={{float: "right", verticalAlign: "middle"}}>
                            <Link to='/registered-user/add'>
                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                            </Link>
                        </div>
                    
                        <div className="search">
                            <input style={{ width: "67%" }} className="form-control" type="text" id="exampleInputEmail1" maxLength={50} placeholder="Enter User Email" onChange={(e) => {
                                clearTimeout(timer);
                                if (e.target.value === "") {
                                    this.props.setPage(0)
                                    this.props.setName("")
                                    this.props.selectItem(null)
                                    this.props.getItems(0, "", this.props.dataState.count, this.props.dataState.role)
                                } else if (this.props.dataState.page > 0 && e.target.value !== "") {
                                    timer = setTimeout(() => {
                                        const count = this.props.dataState.count;
                                        const sesName = e.target.value
                                        const userType = this.props.dataState.role
                                        this.props.setPage(0)
                                        this.props.setName(sesName)
                                         
                                        this.props.getItems(this.props.dataState.page, sesName, count, userType)
                                        this.props.selectItem(null)
                                    }, 1000);
                                } else {

                                    timer = setTimeout(() => {
                                        const pageNum = this.props.dataState.page;
                                        const count = this.props.dataState.count;
                                        const sesName = e.target.value
                                        const userType = this.props.dataState.role
                                        this.props.setName(sesName);
                                        this.props.getItems(pageNum, sesName, count, userType)
                                        this.props.selectItem(null)
                                    }, 1000);

                                }
                            }}/>
                        </div>

                        <div style={{width: "max-content", display: "flex", marginTop: "20px"}}>
                            {this.props.dataState.roles && this.props.dataState.roles.map((role: any) => 
                                <div style={{
                                    width: "fit-content",
                                    verticalAlign: "middle",
                                    float: "right",
                                    paddingRight: "15px",
                                }}>
                                    <button type="button"
                                        className={this.props.dataState.role === role.type ? "btn cur-p btn-primary" : "btn cur-p btn-outline-primary"}
                                        onClick={() => {


                                            const count = this.props.dataState.count;
                                            const name = this.props.dataState.username;
                                            this.props.setPage(0);
                                            this.props.setRole(role.type);
                                            this.props.getItems(0, name, count, role.type)
                                            this.props.selectItem(null);

                                        }}> {getUserRoleTitle(role.type)}

                                    </button>
                                </div>
                                
                            )}

                            
                        </div>
                        <ContentStatus isLoading={this.props.dataState.isLoading}
                                       errorMessage={this.props.dataState.error}
                                       data={this.props.dataState.list}></ContentStatus>

                        {(!this.props.dataState.isLoading && !this.props.dataState.error ) &&
                            <div>

                                <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Username</th>
                                        <th scope="col" className="ta-c mw-actions-min">Email Confirmed</th>
                                        <th scope="col" className="ta-r mw-actions">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="dd-container" ref={this.dragulaDecorator}>
                                    {this.props.dataState.list.map((registeredUser: any, index: number) => (
                                        <tr className={`${this.getActiveClass(registeredUser)} ${Helpers.getPublishedClass(registeredUser)}`}
                                            key={registeredUser.id}>
                                            <td className="cur-p" onClick={() => {
                                                this.showDetails(index)
                                            }}>
                                                {registeredUser.userName}
                                            </td>
                                            <td className="ta-c">
                                                <span style={{fontSize: "16px"}} className="icon-holder"><i
                                                    className={registeredUser.emailConfirmed === true ? "fa fa-check" : "fa fa-window-close"}></i></span>
                                            </td>
                                            <td className="ta-r">
                                                <Link to={`/registered-user/edit/${registeredUser.id}`}>
                                                    <span style={{fontSize: "16px"}} className="icon-holder"><i
                                                        className="c-light-blue-500 ti-pencil"></i></span>
                                                </Link>
                                                {/*<span style={{fontSize: "16px"}}> | </span>*/}
                                                {/*<a href="#" onClick={(e) => {*/}
                                                {/*    e.preventDefault();*/}
                                                {/*    this.deleteItem(index, registeredUser.userName)*/}
                                                {/*}}>*/}
                                                {/*    <span style={{fontSize: "16px"}} className="icon-holder"><i*/}
                                                {/*        className="c-light-blue-500 ti-trash"></i></span>*/}
                                                {/*</a>*/}
                                            </td>
                                        </tr>
                                    ))}
                                   
                                    </tbody>

                                
                            </table>
                                <ContentStatus isLoading={this.props.dataState.isLoading} errorMessage={this.props.dataState.error} data={this.props.dataState.list && this.props.dataState.list.length > 0}></ContentStatus>

                             
                            </div> 
                        }
                        {this.props && this.props.dataState.list.length > 0 && !this.props.dataState.isLoading && this.props.dataState.hasMore &&
                            <div style={{ width: "auto", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <button type="button" className="btn cur-p btn-outline-secondary" onClick={() => {

                                    
                                    let newPage = this.props.dataState.page + 1
                                    this.props.setPage(newPage)
                                    let newCount = this.props.dataState.count
                                    let newName = (this.props.dataState.username)
                                    let newRole = this.props.dataState.role;
                                    this.props.getMoreItems(newPage, newName, newCount, newRole)
                                }}

                                >{this.props.dataState.isLoading ? "Loading..." : "Load More" }</button>
                            </div>}
                    </div>
                    
                    
                </div>
                <div className="masonry-item col-md-7">
                    <div className="bgc-white p-20 bd cnt-scroll">

                        <Route exact path='/registered-user/details/:itemId?' component={RegisteredUserDetails} />
                        <Route exact path='/registered-user/edit/:itemId?' component={RegisteredUserEdit} />
                        <Route exact path='/registered-user/add' component={RegisteredUserEdit} />
                    </div>
                </div>
            </div>)
    }
};

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.registeredUserList, userState: state.user };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        selectItem: (data: RegisteredUser) => {
            dispatch(RegisteredUserStore.actionCreators.selectItem(data))
        },
        getItems: (page: number, username?: string, count?: any, usertype?: any) => {
            dispatch(RegisteredUserStore.actionCreators.getItems(page, username, count, usertype))
        },
        getMoreItems: (page: number, username?: string, count?: any, usertype?: any) => {
            dispatch(RegisteredUserStore.actionCreators.getMoreItems(page, username, count, usertype))
        },
        getCountryItems: () => {
            dispatch(RegisteredUserStore.actionCreators.getCountryItems())
        },
        getAllRoles: () => {
            dispatch(RegisteredUserStore.actionCreators.getAllRoles())
        },
        setPage: (page?: any) => {
            dispatch(RegisteredUserStore.actionCreators.setPage(page))
        },
        setName: (name?: any) => {
            dispatch(RegisteredUserStore.actionCreators.setName(name))
        },
        setRole: (role?: any) => {
            dispatch(RegisteredUserStore.actionCreators.setRole(role))
        },
        //    reorderItem: (id: string, pos?: number) => { dispatch(RegisteredUserStore.actionCreators.reorderItem(id, pos)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisteredUserList);
