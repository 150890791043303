import * as React from 'react';
import * as ProfileStore from '../store/ProfileStore';
import * as DialogStore from '../store/DialogStore';
import {ApplicationState} from '../store';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import {httpService, history} from '../index';
import {RegisteredUser} from "../models/registered-user/RegisteredUser";
import {CountriesEnum} from "../models/registered-user/RegisteredUserEnums";

type ProfileProps =
    ProfileStore.ProfileState &
    typeof ProfileStore.actionCreators &
    typeof DialogStore.actionCreators &
    RouteComponentProps<{}>;

class TopBar extends React.PureComponent<ProfileProps> {

    constructor(props: any) {
        super(props);
    }

    logout = (event: any) => {
        // remove when necessary
        httpService.logout()
        this.props.setUser(null);

        try {
            this.props.setUser(null);
            // this.props.history.push('/');
            window.location.pathname = window.location.origin + "/"
            this.props.decrement();
        } catch (err) {
            this.props.decrement();
        }
    }

    selectCountry(event: any, value: any) {
        event.preventDefault();
        this.props.setCountry(value);
        this.props.increment();
        location.reload();
    }

    public render() {
        return (
            <div className="header navbar">
                <div className="header-container">
                    <ul className="nav-right">
                        
                        <li className="dropdown" style={{ marginRight: "8px" }}>
                            
                            <a href="" className="dropdown-toggle no-after peers fxw-nw ai-c lh-1" data-bs-toggle="dropdown">
                                
                                <div className="peer">
                                    <span style={{ marginRight: "20px"}}
                                        className="fsz-sm c-her-white">{(this.props.user && this.props.user.profile) ? this.props.user.profile!.username : ''}</span>
                                </div>
                                <div className="peer mR-10">
                                    <i className="fa fa-user-circle c-her-white fsz-md" aria-hidden="true"></i>
                                </div>
                            </a>
                            <ul className="dropdown-menu fsz-sm">
                                <li>
                                    <a href="/" onClick={this.logout}
                                       className="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                                        <i className="ti-power-off mR-10"></i>
                                        <span>Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => {
        return state.user
    },
    (dispatch: any) => {
        return {
            increment: () => {
                dispatch(DialogStore.actionCreators.increment())
            },
            decrement: () => {
                dispatch(DialogStore.actionCreators.decrement())
            },
            setUser: (user?: any) => {
                dispatch(ProfileStore.actionCreators.setUser(user))
            },
            setCountry: (country: CountriesEnum) => {
                dispatch(ProfileStore.actionCreators.setCountry(country))
            },
        }
    }
)(TopBar as any);