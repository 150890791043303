import * as React from 'react';
import { Helpers } from '../utility/Helpers';
import Moment from 'moment';

type DatePickerProps = {
    name?: string;
    value: string;
    minDate: string;
    maxDate: string;
    isUtc: boolean;
    useTime: boolean;
    onChange: Function;
    disabled: boolean;
    requiredTime: boolean;
};

type PickerState = {
    calendarDate: moment.Moment;
    selectedDate: moment.Moment | null;
    minDateMom: moment.Moment;
    maxDateMom: moment.Moment;
    localTime: string;
};

class DateTimePicker extends React.Component<DatePickerProps, PickerState> {
    value: string = '';
    useTime: boolean = false;
    input: any;

    constructor(props: any) {
        super(props);

        let calendarDate = this.getMomentDate();
        this.state = {
            calendarDate: calendarDate,
            localTime: '',
            selectedDate: null,
            minDateMom: this.getMomentDate([1, 1, 1]),
            maxDateMom: this.getMomentDate([9999, 12, 31]),
        };

        this.input = React.createRef();
    }

    componentDidMount() {
        this.value = this.props.value;
        this.valueChanged();

        this.useTime = this.props.useTime;
        this.useTimeChanged();

        this.updateMinMaxDate();
    }

    componentDidUpdate(prevProps: DatePickerProps) {
        if (prevProps.value !== this.props.value) {
            this.value = this.props.value;
            this.valueChanged();
        }

        if (prevProps.useTime !== this.props.useTime) {
            this.useTime = this.props.useTime;
            this.useTimeChanged();
        }

        if (prevProps.minDate !== this.props.minDate || prevProps.maxDate !== this.props.maxDate) {
            this.updateMinMaxDate();
        }
    }

    private useTimeChanged() {
        if (this.props.useTime && this.value) {
            this.setState({ localTime: Helpers.getTimeFromDate(this.value) });
        }
    }

    onDateTimeChanged = (ev: any) => {
        const newDateTime = ev.target.value;
        this.value = this.props.useTime 
            ? this.getMomentDate(newDateTime).toISOString() 
            : this.getMomentDate(newDateTime.split('T')[0]).toISOString();
        
        this.valueChanged();
    };

    valueChanged() {
        if (this.value !== this.props.value) {
            this.props.onChange({ name: this.props.name, value: this.value });
        }

        const date = this.getMomentDate(this.value);
        this.setState({ calendarDate: date, selectedDate: date });
    }

    private getMomentDate(val?: any): any {
        return this.props.isUtc ? Moment.utc(val) : Moment(val);
    }

    private updateMinMaxDate() {
        const minDateMom = this.getMomentDate(this.props.minDate || [1, 1, 1]);
        const maxDateMom = this.getMomentDate(this.props.maxDate || [9999, 12, 31]);

        this.setState({ minDateMom, maxDateMom });
    }

    render() {
        const useDatTimeOrDate = this.props.useTime ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD";
        return (
            <React.Fragment>
                <div className="date-time-picker">
                    <input
                        style={{ backgroundColor: this.props.disabled ? "#E9ECEF" : "white", cursor: "pointer" }}
                        id={this.props.name}
                        name={this.props.name}
                        className="form-control"
                        ref={this.input}
                        type={this.props.useTime ? "datetime-local" : "date"}
                        onChange={this.onDateTimeChanged}
                        disabled={this.props.disabled}
                        value={this.state.selectedDate ? this.state.selectedDate.format(useDatTimeOrDate) : ''}
                        min={this.state.minDateMom.format(useDatTimeOrDate)}
                        max={this.state.maxDateMom.format(useDatTimeOrDate)}
                        required={this.props.requiredTime}
                    />
                </div>
            </React.Fragment>
        );
    }

    static defaultProps: DatePickerProps = {
        onChange: () => {},
        isUtc: false,
        maxDate: '',
        minDate: '',
        useTime: false,
        value: '',
        disabled: false,
        requiredTime: false,
    };
}

export default DateTimePicker;
