import React, { Component, Fragment } from "react";

type defaultProps = {
    suggestions: [],
    data: any,
    action?: any
    required?: boolean
}

class CountrySearch extends Component<any> {
    static defaultProps: defaultProps = {
        suggestions: [],
        data: [],
        required: true,
    };

    constructor(props: any) {
        super(props);
    }

    state = {
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: this.props.suggestions,
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // What the user has entered
        userInput: '',
    };

    handleChange = (e: any) => {
        const userInput = e.currentTarget.value;
        const filteredSuggestions = this.props.suggestions.filter((country: any) => country.name.toUpperCase().includes(userInput.toUpperCase()));

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: filteredSuggestions,
            showSuggestions: true,
            userInput: userInput
        });
    };

    handleClick = (e: any, id: any) => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: this.props.suggestions,
            showSuggestions: !this.state.showSuggestions,
        });

        let input = this.props.suggestions.find((country: any) => country.id === id);

        if (!input && this.props.required) {
            return;
        }
        const checkRequiredAndInput = this.props.required && e.currentTarget.innerText === "";
        const inputValue = checkRequiredAndInput  ? input.name : e.target.value

        this.props.action(input)

        this.setState({
            userInput: inputValue,
            inputValue: inputValue,
        });
    
    }


    render() {
        const country = this.props.data && this.props.data.country && this.props.data.country.name;
        const passportCountry = this.props.data && this.props.data.passportCountry && this.props.data.passportCountry.name
        let suggestionsListComponent;

        if (this.state.showSuggestions) {
            if (this.state.filteredSuggestions && this.state.filteredSuggestions.length > 0) {
                suggestionsListComponent = (
                    <div style={{ position: 'relative' }}>
                    <ul className="suggestions" style={{ position: 'absolute', top: '100%', left: 0, background: "white", zIndex: 10 }}>
                        {this.state.filteredSuggestions.map((suggestion: any, index: any) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === this.state.activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={className} key={suggestion.id} onClick={(e: any) => this.handleClick(e, suggestion.id)}>
                                    {suggestion.name}
                                </li>
                            )
                        })}
                    </ul>
                    </div>
                )
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions.</em>
                    </div>
                )
            }
        }

        return (
            <Fragment>
                <input
                    id="countrySearch" name="countrySearch" required={this.props.required} className="form-control"

                    onFocus={(e: any) => this.handleClick(e, e.target.dataset.key)}
                    onChange={(e: any) =>
                        this.handleChange(e)
                    }
                    placeholder={"-- select country --"}
                    autoComplete="off"
                    defaultValue={this.state.userInput}
                    value={this.state.userInput || country || passportCountry}

                />
                {suggestionsListComponent}
            </Fragment>
        )
    }
}

export default (CountrySearch);