import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import { Helpers } from '../../utility/Helpers';
import BreedSearch from '../BreedSearch';

export type BreedsSearchTableProps = {
    title: string,
    breeds: any[],
    callback: Function,
    editMode: boolean,
    dataState: any,
    userState: any
}

class BreedsSearchTable extends React.PureComponent<BreedsSearchTableProps, any> {

    selectedBreed: any

    addBreed(breed: any) {
        if (breed) {
            let breeds: any = [ ...this.props.breeds ]

            if (!breeds) {
                breeds = []
            }

            const exists = breeds.find((i: any) => i.breed.id === breed.id)

            if (exists) {
                alert('Breed is already added.')
                return
            }

            breeds.push({ breed: breed })
            this.props.callback(breeds)
        }
    }

    removeBreed(index: number) {
        let breeds: any = [ ...this.props.breeds ]
        breeds.splice(index, 1)
        this.props.callback(breeds)
    }

    render() {
        return (
            <React.Fragment>
                <h5>{this.props.title}</h5>
                {(this.props.editMode === true) && <div className="row mb-3">
                    <div className="col-6">
                        <BreedSearch action={(item: any) => this.selectedBreed = item} disabled={false} data={this.selectedBreed}
                            suggestions={this.props.dataState.breeds} />
                    </div>
                    <div className="col-6">
                        <button className="btn btn-outline-primary breedAddButton" onClick={() => { this.addBreed(this.selectedBreed) }}>Add selected breed</button>
                    </div>
                </div>}

                {this.props.breeds && this.props.breeds.length > 0 ?
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>FCI</th>
                                {!Helpers.isUserEventViewer(this.props.userState.user) && this.props.editMode &&
                                    <th style={{ textAlign: "right" }}>Action</th>
                                }
                            </tr>
                        </thead>

                        <tbody className="dd-container">
                            {this.props.breeds && this.props.breeds.length > 0 && this.props.breeds.map((item: any, index: number) =>
                                <tr key={item.breed.id}>
                                    <td style={{ color: "grey" }}>{item.breed && item.breed.name}</td>
                                    <td style={{ color: "grey" }}>{item.breed && item.breed.fci}</td>

                                    {!Helpers.isUserEventViewer(this.props.userState.user) && this.props.editMode &&
                                        <td className="ta-r">
                                            <a href="#" onClick={(e) => { e.preventDefault(); this.removeBreed(index) }}>
                                                <span style={{ fontSize: "16px" }} className="icon-holder">
                                                    <i className="c-light-blue-500 ti-trash"></i>
                                                </span>
                                            </a>
                                        </td>
                                    }
                                </tr>
                            )}
                        </tbody>
                    </table>
                    : <div>No breeds</div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.competitionList, userState: state.user };
}

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreedsSearchTable)
