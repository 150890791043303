import React, { ComponentProps } from 'react';
import './FullLoader.scss'
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as DialogStore from '../../store/DialogStore';


type LoaderProps =
    DialogStore.DialogState &
    typeof DialogStore.actionCreators &
    ComponentProps<any>;

class FullLoader extends React.PureComponent<LoaderProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.count > 0 &&
                    <div id='loader'>
                        <div className="spinner"></div>
                    </div>
                }
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.dialog,
    DialogStore.actionCreators
)(FullLoader);