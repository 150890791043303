import { Country } from "../country/Country"
import { Breed } from "../dog/Breed"
import { InvoiceInfo } from "../invoice/InvoiceInfo"
import { InvoiceIssuer } from "../invoice/InvoiceIssuer"
import { StripeAccount } from "../stripe-account/StripeAccount"
import { PriceList } from "./CompetitionDate"
import { CompetitionAttachmentType, CompetitionType, CustomDiscountType, DiscountType } from "./CompetitionEnums"

export interface Competition {
    id?: string,
    title: string,
    subtitle: string,
    richText: string,
    city: string,
    contact: string,
    address: string,
    logo: string,
    publishDate: Date,
    registrationFrom: Date,
    registrationTo: Date,
    type: CompetitionType | string,
    country: Country,
    competitionDates: Array<CompetitionDate>,
    created: Date,
    competitionSponsors: Array<Sponsor>,
    competitionAttachments: Array<CompetitionAttachment>
    registrationCount: number,
    startNumbersAndScheduleSent: Date,
    invoiceNumbersGeneratedTimestamp?: Date,
    feeDateOfService: string,
    feeEM: string,
    feeInvoiceDate: string,
    feeInvoiceLocation: string,
    feeVATClause: string,
    fee: number,
    //useCustomPriceCalculation: boolean,
    //useSemiCustomPriceCalculation: boolean,
    invoiceInformation?: InvoiceInfo | any,
    invoiceIssuer?: InvoiceIssuer | any,
    invoicesAndExtraSent: Date,
    stripeAccount?: StripeAccount,
    stripeReference: string,
    successfullEntrySent: Date
}

export interface CompetitionDate {
    competitionId?: string,
    id?: string,
    description: string,
    date: Date,
    created?: Date,
    hasJuniorHandlingRegistrations: boolean,
    hasCoupleAndGroupRegistrations: boolean,
    mustHavePedigrees: boolean,
    canDownloadCards: boolean,
    canDownloadEvaluations: boolean,
    isCACIBAwarded: boolean,
    invoiceLocation?: string,
    invoiceDate?: string,
    dateOfService?: string,
    invoiceData?: string,
    vatClause?: string,
    em?: string,
    catalogNumbersGenerated?: boolean,
    invoiceIssuer?: InvoiceIssuer,
    invoiceInformation?: InvoiceInfo,
    introductoryPagesPath?: string,
    closingPagesPath?: string,
    specialShowBreeds?: Array<Breed>,
    priceLists?: Array<PriceList>,
    includeInAllEventsPriceCalculation: boolean,
    coupons: Array<Coupon>
}

export interface Sponsor {
    id?: string,
    created: string,
    sponsorTitle: string,
    sponsorLink: string,
    sponsorImage?: string,
    useOnStartNumbers: boolean,
    orderOnStartNumbers: number,
    competitionId: string,
    file?: File
}

export interface CompetitionAttachment {
    id?: string,
    title: string,
    name: string,
    created: string,
    type: CompetitionAttachmentType,
    displayOnWeb: boolean,
    file?: File,
    competitionId: string
}

export interface Coupon {
    id?: string,
    title: string,
    created: Date,
    validFrom: Date,
    validTo: Date,
    discount: number,
    isPercentage: boolean,
    invoiceDescription: string,
    discountType: DiscountType,
    couponCodes: Array<CouponCode>,
    competitionDateId: string

}

export interface CouponCode {
    id?: string,
    created: Date,
    code: string,
    used: boolean
}

export interface CustomDiscount {
    id?: string,
    title: string,
    created: Date,
    validFrom: Date,
    validTo: Date,
    discount: number,
    isPercentage: boolean,
    fixedPrice: boolean,
    excludeCountries: boolean,
    excludeBreeds: boolean,
    invoiceDescription: string,
    discountType: CustomDiscountType,
    competitionDateId: string,
    competitionClasses: any[],
    breeds: any[],
    countries: any[]
}

export function getEmptyCustomDiscount(competitionDateId: string): CustomDiscount {
    return {
        title: '',
        created: new Date,
        validFrom: new Date,
        validTo: new Date,
        discount: 0,
        isPercentage: false,
        fixedPrice: false,
        excludeCountries: false,
        excludeBreeds: false,
        invoiceDescription: '',
        discountType: CustomDiscountType.DOG_REGISTRATION,
        competitionDateId: competitionDateId,
        competitionClasses: [],
        breeds: [],
        countries: []
    }
}
