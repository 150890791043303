import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { Helpers } from '../../utility/Helpers';
import { CompetitionDate } from '../../models/competition/Competition';
import { httpService } from '../../index';
import CompetitionDatePreviewPopUpComponent from "../../views/competition/DatePreviewPopUp";
import ContentStatus from '../ContentStatus';

type CompDateListProps = {
	compDates?: Array<CompetitionDate>;
	onChange: Function;
	parentId: string;
}

class CompDateList extends React.Component<CompDateListProps & typeof DialogStore.actionCreators & any, { isOpen: boolean; compDate: CompetitionDate | null }> {

	constructor(props: any) {
		super(props);
		this.state = {
			isOpen: false,
			compDate: null
		}
	}

	removeCompetitionDate(item: CompetitionDate) {
		this.props.showDialog(`Are you sure you want to delete competition date?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
			if (confirm && item.id) {
				this.props.increment();
				try {
					await httpService.competitionDateDelete(item.id);
					this.props.onChange(item, true);
					this.props.decrement();
				} catch (err) {
					this.props.decrement();
					alert(err.message);
				}
			}
		})
	}

	openEditor(data: CompetitionDate) {
		this.props.showCompDateDialog(data, true, async (cd: CompetitionDate) => {

			this.props.increment();
			try {
				let res = await httpService.competitionDateAddOrUpdate(cd);
				this.props.decrement();
				this.props.onChange(res, false);
				this.props.hideCompDateDialog();

			} catch (err) {
				this.props.decrement();
				alert(err.message);
			}
		})
	}

	showDetails = async (id: string) => {
		
		let cd = await httpService.getCompetitionDate(id)
		this.setState({ compDate: cd, isOpen: true })
		
	}

	render() {
		return (
			<div className="picture-picker">
				{!this.state.isOpen && !Helpers.isUserEventViewer(this.props.userState.user) && <div>
					Shows
					<label className="cur-p mL-10">
						<a href="#" onClick={(e) => {
							e.preventDefault()
							this.openEditor({
								competitionId: this.props.parentId,
								description: '',
								canDownloadCards: false,
								canDownloadEvaluations: false,
								isCACIBAwarded: false,
								includeInAllEventsPriceCalculation: false,
								hasJuniorHandlingRegistrations: true,
								hasCoupleAndGroupRegistrations: true,
								mustHavePedigrees: true,
								date: new Date(),
								coupons: []
							})
						}}>
							<div className="bgc-blue-50 c-blue-500 circle-add">+</div>
						</a>
					</label>
				<br /><br />
				</div>
					
				}
				
				{!this.state.isOpen &&
					<table className="table">
						<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Title</th>
								<th scope="col">Registrations</th>
								{!Helpers.isUserEventViewer(this.props.userState.user) &&
									<th scope="col" style={{ textAlign: "right" }}>Action</th>
								}
							</tr>
						</thead>

						<tbody>
							{this.props.compDates && this.props.compDates.map((competitionDate: any) => (
								<tr key={competitionDate.id}>
									<td style={{ color: "grey" }} className="cur-p"
										onClick={() => this.showDetails(competitionDate.id)}>
										{Helpers.getLocalDateTimeString(competitionDate.date)}
									</td>
									<td style={{ color: "grey" }} className="cur-p"
										onClick={() => this.showDetails(competitionDate.id)}>
										{competitionDate.description}
									</td>
									<td style={{ color: "grey" }} className="cur-p"
										onClick={() => this.showDetails(competitionDate.id)}>
										{competitionDate.registrationCount}({competitionDate.dogRegistrationCount})
									</td>

									{!Helpers.isUserEventViewer(this.props.userState.user) &&
										<td>
											<div>
												<a style={{ float: "right" }} href="#" onClick={(e) => {
													e.preventDefault()
													this.removeCompetitionDate(competitionDate);
												}}>
													<span style={{ fontSize: "16px" }} className="icon-holder">
														<i className="c-light-blue-500 ti-trash"></i>
													</span>
												</a>
												{/*<span style={{ float: "right", marginLeft: "8px", marginRight: "8px" }}>|</span>*/}
												{/*<label style={{ float: "right" }} onClick={() => { this.openEditor(competitionDate) }} className="cur-p">*/}
												{/*	<span className="icon-holder" style={{ fontSize: "16px" }}><i className="c-light-blue-500 ti-pencil"></i></span>*/}
												{/*</label>*/}
											</div>
										</td>
									}

								</tr>
							))}
						</tbody>
					</table>
				}
				{this.state.isOpen && !this.state.compDate &&

					<ContentStatus isLoading={true} />
				}
				{this.state.isOpen && this.state.compDate &&

					<CompetitionDatePreviewPopUpComponent
					data={this.state.compDate}
					open={this.state.isOpen}
					modal={this.state.isOpen}
					onCompDateChange={(data: CompetitionDate) => {
						this.setState({ compDate: data });
						this.props.onChange(data, false)
					}}
					closePreview={() => { this.setState({ isOpen: false }) }} />
				}
	
			</div>
		)
	}

	static defaultProps: CompDateListProps = {
		onChange: () => { },
		compDates: [],
		parentId: ''
	}
}

export default connect(
	(state: ApplicationState) => { return { dialog: state.dialog, userState: state.user } },
	DialogStore.actionCreators
)(CompDateList)
