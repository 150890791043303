import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import { Table } from "reactstrap";

import { getBestPuppyGradeOptions, getCacibOptions, getCacOptions, getGrade, getGradeOptions, getPuppyGradeOptions, getRangOptions } from "../../models/registrations/RegistrationEnums";


type Props = {
    data: any;
    action: any,
    previewMode?: any

}


class CompetitionScoreComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <br />
                    <Table size="sm" bordered>
                        <tbody>
                            <tr>
                                <th>Was Not Present</th>
                                <td>
                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-info peers ai-c" >

                                        <input id="wasNotPresent" name="wasNotPresent"
                                            type="checkbox" checked={this.props.data && this.props.data.competitionScore.wasNotPresent}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e)} />
                                        <label>
                                            <span
                                                className="peer peer-greed"></span></label>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Evaluation Done</th>
                                <td>
                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-info peers ai-c" >

                                        <input id="evaluationDone" name="evaluationDone"
                                            type="checkbox" checked={this.props.data && this.props.data.competitionScore.evaluationDone}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e)} />
                                        <label>
                                            <span
                                                className="peer peer-greed"></span></label>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Disqualified</th>
                                <td>
                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-info peers ai-c" >

                                        <input id="disqualified" name="disqualified"
                                            type="checkbox" checked={this.props.data && this.props.data.competitionScore.disqualified}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e)} />
                                        <label>
                                            <span
                                                className="peer peer-greed"></span></label>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Cannot be Evaluated</th>
                                <td>
                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-info peers ai-c" >

                                        <input id="cannotBeEvaluated" name="cannotBeEvaluated"
                                            type="checkbox" checked={this.props.data && this.props.data.competitionScore.cannotBeEvaluated}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e)} />
                                        <label>
                                            <span
                                                className="peer peer-greed"></span></label>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Grade</th>
                                <td>
                                    <select style={{ border: "none" }} id="grade" name="grade"
                                        className="form-control"
                                        required
                                        disabled={this.props.previewMode}
                                        defaultValue={this.props.data && this.props.data.competitionScore.grade}
                                        onChange={(e) => this.props.action(e)}>
                                        <option value="">-- select grade --</option>
                                        {getGradeOptions().map((item: any, index: number) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Rang</th>
                                <td>
                                    <select style={{ border: "none" }} id="rang" name="rang"
                                        className="form-control"
                                        required
                                        disabled={this.props.previewMode}
                                        defaultValue={this.props.data && this.props.data.competitionScore.rang}
                                        onChange={(e) => this.props.action(e)}>
                                        <option value="">-- select rang --</option>
                                        {getRangOptions().map((item: any, index: number) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>CAC</th>
                                <td>
                                    <select style={{ border: "none" }} id="cac" name="cac"
                                        className="form-control"
                                        required
                                        disabled={this.props.previewMode}
                                        defaultValue={this.props.data && this.props.data.competitionScore.cac}
                                        onChange={(e) => this.props.action(e)}>
                                        <option value="">-- select CAC --</option>
                                        {getCacOptions().map((item: any, index: number) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>CACIB</th>
                                <td>
                                    <select style={{ border: "none" }} id="cacib" name="cacib"
                                        className="form-control"
                                        required
                                        disabled={this.props.previewMode}
                                        defaultValue={this.props.data && this.props.data.competitionScore.cacib}
                                        onChange={(e) => this.props.action(e)}>
                                        <option value="">-- select CACIB --</option>
                                        {getCacibOptions().map((item: any, index: number) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Best Female</th>
                                <td>


                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-info peers ai-c" >

                                        <input id="bestFemale" name="bestFemale"
                                            type="checkbox" checked={this.props.data && this.props.data.competitionScore.bestFemale}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e)} />
                                        <label>
                                            <span
                                                className="peer peer-greed"></span></label>

                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <th>Best Male</th>
                                <td>
                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-info peers ai-c" >

                                        <input id="bestMale" name="bestMale"
                                            type="checkbox" checked={this.props.data && this.props.data.competitionScore.bestMale}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e)} />
                                        <label>
                                            <span
                                                className="peer peer-greed"></span></label>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>BOB</th>
                                <td>
                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-info peers ai-c" >

                                        <input id="bob" name="bob"
                                            type="checkbox" checked={this.props.data && this.props.data.competitionScore.bob}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e)} />
                                        <label>
                                            <span
                                                className="peer peer-greed"></span></label>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>BOS</th>
                                <td>

                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-info peers ai-c" >

                                        <input id="bos" name="bos"
                                            type="checkbox" checked={this.props.data && this.props.data.competitionScore.bos}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e)} />
                                        <label>
                                            <span
                                                className="peer peer-greed"></span></label>

                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th>Junior BOB</th>
                                <td>

                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-info peers ai-c" >

                                        <input id="juniorBOB" name="juniorBOB"
                                            type="checkbox" checked={this.props.data && this.props.data.competitionScore.juniorBOB}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e)} />
                                        <label>
                                            <span
                                                className="peer peer-greed"></span></label>

                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th>Veteran BOB</th>
                                <td>
                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-info peers ai-c" >

                                        <input id="veteranBOB" name="veteranBOB"
                                            type="checkbox" checked={this.props.data && this.props.data.competitionScore.veteranBOB}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e)} />
                                        <label>
                                            <span
                                                className="peer peer-greed"></span></label>

                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th>Puppy Grade</th>
                                <td>

                                    <select style={{ border: "none" }} id="puppyGrade" name="puppyGrade"
                                        className="form-control"
                                        required
                                        disabled={this.props.previewMode}
                                        value={this.props.data && this.props.data.competitionScore.puppyGrade}
                                        onChange={(e) => this.props.action(e)}>
                                        <option value="">-- select puppy grade --</option>

                                        {getPuppyGradeOptions().map((item: any, index: number) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>

                                </td>
                            </tr>
                            <tr>
                                <th>Best Puppy Grade</th>
                                <td>

                                    <select style={{ border: "none" }} id="bestPuppyGrade" name="bestPuppyGrade"
                                        className="form-control"
                                        required
                                        disabled={this.props.previewMode}
                                        defaultValue={this.props.data && this.props.data.competitionScore.bestPuppyGrade}
                                        onChange={(e) => this.props.action(e)}>
                                        <option value="">-- select best puppy grade --</option>
                                        {getBestPuppyGradeOptions().map((item: any, index: number) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <th style={{ textAlign: "center" }} colSpan={2}>
                                    Description
                                </th>

                            </tr>
                            <tr>
                                <th colSpan={2}>
                                    <textarea disabled={this.props.previewMode} style={{ resize: "none", overflow: "auto", borderStyle: "none", borderColor: "transparent" }}
                                        id="description" name="description" maxLength={3000} rows={7} cols={120}
                                        defaultValue={this.props.data && this.props.data.competitionScore.description} onChange={(e: any) => this.props.action(e)} />
                                </th>
                            </tr>

                        </tbody>
                    </Table>


                </div>


            </React.Fragment>
        );

    }

}


export default connect(

)(CompetitionScoreComponent);
