import React from 'react';
import { Component } from "react";


class TVRingSideMenu extends Component<any, any>{
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <div className="sidebar tv_sidebar tv_grey_bg">
                <ul className="sidebar-menu">
                    {this.props.rings.map((item: any, index: any) => (
                        <li key={index}
                            className={`tv-sidebar-menu-item ${this.props.currentRingIndex === index ? 'active' : ''} helvetica_regular_56 tv_white text_align_center line_height128 tv_text_no_wrap`}>
                            RING {item.description}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}
export default (TVRingSideMenu as any);