import { Action, Reducer } from 'redux';
import { httpService } from '../index';
import { AppThunkAction } from './';
import { InvoiceInfo } from '../models/invoice/InvoiceInfo';
import { IssuerType } from '../models/invoice/InvoiceEnums';

export interface InvoiceInfoListState {
    selectedItem: InvoiceInfo | null,
    list: Array<InvoiceInfo>,
    issuerType: IssuerType | null,
    isLoading: boolean,
    error: string,
}

interface SelectInvoiceInfoAction {
    type: 'SELECT_INVOICE_INFO';
    item: InvoiceInfo;
}

interface RequestInvoiceInfoAction {
    type: 'REQUEST_INVOICE_INFO';
}

interface ReceiveInvoiceInfoAction {
    type: 'RECEIVE_INVOICE_INFO';
    items: Array<InvoiceInfo>;
    issuerType: IssuerType | null,
    error: '';
}

type KnownAction =
    SelectInvoiceInfoAction
    | RequestInvoiceInfoAction
    | ReceiveInvoiceInfoAction

export const actionCreators = {
    selectItem: (item: InvoiceInfo): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SELECT_INVOICE_INFO', item: item });
    },

    getItems: (type: any = IssuerType.NORMAL): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.invoiceInfoList) {
            try {
                dispatch({ type: 'REQUEST_INVOICE_INFO' });
                let data = await httpService.invoiceInfoList(type);

                dispatch({ type: 'RECEIVE_INVOICE_INFO', items: data, issuerType: type, error: '' });

            } catch (err) {
                dispatch({ type: 'RECEIVE_INVOICE_INFO', items: [], issuerType: null, error: '' });
            }
        }
    }
}

const unloadedState: InvoiceInfoListState = { selectedItem: null, isLoading: false, list: [], error: '', issuerType: null};

export const reducer: Reducer<InvoiceInfoListState> = (state: InvoiceInfoListState | undefined, incomingAction: Action): InvoiceInfoListState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_INVOICE_INFO':
            return {
                selectedItem: action.item,
                list: state.list,
                isLoading: false,
                error: '',
                issuerType: state.issuerType

            };
        case 'REQUEST_INVOICE_INFO':
            return {
                selectedItem: state.selectedItem,
                list: [],
                isLoading: true,
                error: '',
                issuerType: state.issuerType

            };
        case 'RECEIVE_INVOICE_INFO':

            let si = state.selectedItem;

            if (action.items && action.items.length > 0 && !state.selectedItem) {
                si = action.items[0];
            }

            return {
                selectedItem: si,
                list: action.items,
                isLoading: false,
                error: state.error,
                issuerType: action.issuerType

            };

    }
    return state;
};
