import * as React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import logo from '../assets/static/images/logo-bl.png';
import bg from '../assets/static/images/Backgound.png';
import * as ProfileStore from '../store/ProfileStore';
import * as DialogStore from '../store/DialogStore';
import { ApplicationState } from '../store';
import {httpService} from '../index';
import {RegisteredUser} from "../models/registered-user/RegisteredUser";

class Login extends React.Component<any, {
    passwordChange: boolean
}>
{
    email: string;

    constructor(props: any) {
        super(props);

        this.email = '';

        this.state = {
            passwordChange: false
        };
    }

    handleChange = (event: any) => {
        this.email = event.target.value;
    }

    handleSubmit = async (event: any) => {
        event.preventDefault();

        if (this.state.passwordChange === true) {

            let data = {
                password: event.target.oldPassword.value,
                email: this.email,
                newPassword: event.target.newPassword.value,
                rememberMe: event.target.rememberMe.checked
            }

            if (!data.newPassword || !new RegExp('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{10,100})').test(data.newPassword)) {
                alert("Passwords must be at least 10 characters in length and must contain lowercase, uppercase and digit.");
                return;
            }

            if (data.newPassword !== event.target.retypeNewPassword.value) {
                alert("Password and retyped password are not equal.");
                return;
            }

            try {
                this.props.increment();

                let user = await httpService.passwordChange(data);
                this.props.setUser(user);
                this.props.history.replace('/');
                this.props.decrement();
            } catch (err) {
                this.props.decrement();
                alert(err.message);
            }
        } else {

            let data = {
                email: this.email,
                password: event.target.password.value,
                rememberMe: event.target.rememberMe.checked
            }

            try {
                this.props.increment();
                let user = await httpService.login(data);
                this.props.setUser(user);
                this.props.history.replace('/');
                this.props.decrement();
            } catch (err) {
                this.props.decrement();

                if (err.errorCode && err.errorCode == 'CHANGE_PASS') {
                    this.setState({ passwordChange: true });
                } else {
                    alert(err.message);
                }
            }
        }
    }

    render() {
        return (
            <div className="peers ai-s fxw-nw h-100vh">
                <div className="d-n@sm- peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv" style={{ backgroundImage: `url(${bg})`, width: "60%" }}>
                    <div className="pos-a centerXY">
                        <div className="bgc-white bdrs-50p pos-r" style={{ width: "120px", height: "120px" }}>
                            <img className="pos-a centerXY" src={logo} alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 peer pX-40 pY-80 h-100 bgc-white scrollable pos-r" style={{ minWidth: "320px" }}>
                    <h4 className="fw-300 c-grey-900 mB-40">Login</h4>
                    <form onSubmit={this.handleSubmit}>
                        {(!this.state.passwordChange) &&
                            <div>
                                <div className="mb-3">
                                    <label className="text-normal text-dark form-label">Email</label>
                                    <input className="form-control" placeholder="Email" onChange={this.handleChange} />
                                </div>
                                <div className="mb-3">
                                    <label className="text-normal text-dark form-label">Password</label>
                                    <input name="password" type="password" className="form-control" placeholder="Password" />
                                </div>
                            </div>
                        }

                        {this.state.passwordChange &&
                            <div>
                                <div className="mB-20">You must reset your password to continue.</div>
                                <div className="mb-3">
                                    <label className="text-normal text-dark form-label">Old password</label>
                                    <input name="oldPassword" required type="password" className="form-control" placeholder="Old password" />
                                </div>
                                <div className="mb-3">
                                    <label className="text-normal text-dark form-label">New password</label>
                                    <input name="newPassword" required type="password" className="form-control" placeholder="New password" />
                                </div>
                                <div className="mb-3">
                                    <label className="text-normal text-dark form-label">Repeat new password</label>
                                    <input name="retypeNewPassword" required type="password" className="form-control" placeholder="Repeat password" />
                                </div>
                            </div>
                        }

                        <div className="">
                            <div className="peers ai-c jc-sb fxw-nw">
                                <div className="peer">
                                    <div className="checkbox checkbox-info peers ai-c">
                                        <input type="checkbox" id="rememberMe" name="rememberMe" className="peer" />
                                        <label htmlFor="rememberMe" className="peers peer-greed js-sb ai-c form-label">
                                            <span className="peer peer-greed">Keep me signed in</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="peer">
                                    <input type="submit" className="btn btn-primary btn-color" value="Login" title="Login" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { userState: state.user };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        setUser: (data: RegisteredUser) => {
            dispatch(ProfileStore.actionCreators.setUser(data))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login as any);
