export class TVHelpers {

    public static switchTime: number = 15000;
    public static breedsPerPageCount: number = 6;

    public static bobTitle: string = "BOB";
    public static bosTitle: string = "BOS";
    public static jbobTitle: string = "Junior BOB";
    public static vbobTitle: string = "Veteran BOB";
    public static puppyTitle: string = "Best Puppy";
    public static minorPuppyTitle: string = "Best Minor Puppy";

    public static getActiveClass(isInProgress: boolean, isFinished: boolean) {
        if (isFinished)
            return "tv-finished";
        if (isInProgress)
            return "tv-inprogress";
        return "";
    }

    public static displayImage(isInProgress: boolean, isFinished: boolean) {
        if (isFinished)
            return false;
        if (isInProgress)
            return true;
        return false;
    }
}