import { Component } from 'react'
import { EditorState, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { handleDraftEditorPastedText } from 'draftjs-conductor'
import { Helpers } from '../utility/Helpers'
import React from 'react'

interface DraftEditorProps {
    content: string,
    hasBlockTypes?: boolean,
    ignoreStyle?: boolean,
    handleRequired?: boolean,
    maxChars?: number,
    onValueChange: any
}

class DraftEditor extends Component<DraftEditorProps, { editorState: EditorState }> {
    constructor(props: any) {
        super(props)
        this.state = {
            editorState: EditorState.createEmpty()
        }
    }

    handlePastedText(text: string, html: string | undefined, editorState: EditorState) {
        const newState = handleDraftEditorPastedText(html, editorState)

        if ((newState !== false && !Helpers.isNullOrUndefined(newState)) || false) {
            this.setState({
                editorState: newState
            })

            return true
        }

        return false
    }

    componentDidMount() {
        const match = this.props.content.match(/<div .*?>(.*?)<\/div>/s)

        if (match != null && match.length > 1) {
            this.getContentFromHTML(match[1])
        }

        if (this.props.content !== '') {

            let content = this.props.content

            if (!this.props.content.startsWith('<p')) {
                content = `<p>${content}</p>`
            }
            
            this.getContentFromHTML(content)
        }
    }

    onEditorStateChange = (editorState: any) => {
        this.setState({
            editorState
        })
    }

    onContentStateChange = (contentState: any) => {
        const html = draftToHtml(contentState)
        this.props.onValueChange(html)
    }

    getContentFromHTML(content: string) {
        try {
            const contentBlock = htmlToDraft(content)
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            const editorState = EditorState.createWithContent(contentState)
            this.setState({ editorState })
        } catch(err) {
            alert(JSON.stringify(err.message))
        }
    }

    getContent(content: string) {
        return content
    }

    render() {
        const { editorState } = this.state
        return (
            <div>
                <div>
                    Content:
                </div>
                <Editor
                    editorState={editorState}
                    editorClassName={`form-control ${this.props.ignoreStyle === true ? '' : 'draft-content-editable'}`}
                    onEditorStateChange={this.onEditorStateChange}
                    handlePastedText={this.handlePastedText}
                    toolbar={{
                        options: (this.props.hasBlockTypes === true) ? ['inline', 'blockType', 'colorPicker', 'list', 'textAlign', 'link', 'image'] : ['inline', 'colorPicker', 'list', 'textAlign', 'link', 'image'],
                        inline: {
                            options: ['bold', 'italic', 'underline']
                        },
                        list: {
                            options: ['unordered', 'ordered', 'indent', 'outdent']
                        },
                        blockType: {
                            inDropdown: true,
                            options: ['Normal', 'H1', 'H2'],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined
                        },
                        colorPicker: {
                            colors: ['white', 'black', '#2370C8', 'grey', 
                                'darkred', 'red', 'orange', 'yellow',
                                'green', 'turquoise', 'indigo', 'lightgrey',
                                 'brown', 'pink', 'gold', 'lime']
                        }
                    }}
                    onContentStateChange={this.onContentStateChange}
                />
                <input required={this.props.handleRequired === true} value={this.getContent(this.props.content)} type="text" readOnly maxLength={this.props.maxChars} style={{ height: '1px', width: '100%', opacity: 0, border: '0', margin: '0', padding: '0' }} />
                {this.props.maxChars && <div>Length: <span style={{ color: (this.props.content.length > this.props.maxChars) ? '#f5564a' : ''}}>{this.props.content.length}</span>/{this.props.maxChars}</div>}
            </div>
        )
    }
}

export default DraftEditor
