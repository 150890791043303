import { Action, Reducer } from 'redux';
import { httpService } from '../index';
import { AppThunkAction } from './';
import { Competition } from '../models/competition/Competition'
import { OwnerBreederCountry } from '../models/country/Country';
import { CompetitionClass, CompetitionCoupleGroup, CompetitionDate, CompetitionDog, CompetitionJuniorSenior, CompetitionRegistration, CompetitionSpecialShow, CoupleGroupType, } from '../models/registrations/Registrations';
import { CompetitionRing, SpecialComp } from '../models/competition/CompetitionDate';
import { Dog } from '../models/dog/Dog';
import { Breed } from '../models/dog/Breed';
import { InvoiceInfo } from '../models/invoice/InvoiceInfo';
import { InvoiceIssuer } from '../models/invoice/InvoiceIssuer';
import { IssuerType } from '../models/invoice/InvoiceEnums';
import { StripeAccount } from '../models/stripe-account/StripeAccount';


export interface CompetitionListState {
    selectedItem: Competition | null,
    list: Array<Competition>,
    countries: Array<OwnerBreederCountry>,
    isLoading: boolean,
    error: string,
    isMoreLoading: boolean,
    hasMoreItems: boolean,
    registrations: any,
    hasMoreRegistrations: boolean,
    registration: CompetitionRegistration | null,
    competitionDate: CompetitionDate | null,
    specialComp: Array<SpecialComp>,
    competitionDog: CompetitionDog | null,
    competitionCouplesGroups: CompetitionCoupleGroup | null,
    specialShowRegistration: CompetitionSpecialShow | null,
    juniorSeniorRegistration: CompetitionJuniorSenior | null,
    rings: Array<CompetitionRing>,
    selectedTab: number,
    hasSubTab: boolean,
    classes: Array<CompetitionClass>,
    breeds: Array<Breed>,
    catalogBreeds: Array<Breed>,
    invoiceIssuers: Array<InvoiceIssuer>,
    invoiceInfo: Array<InvoiceInfo>,
    page: number,
    count: number,
    status: any,
    catalogNumber: any;
    compType: any;
    compDateId: any;
    competitionId: string,
    email: string,
    dogOwner: string,
    dogName : string,
    openReg: boolean,
    profileDogs: Array<Dog>,
    stripeAccounts: Array<StripeAccount>
}

interface SelectCompetitionAction {
    type: 'SELECT_COMPETITION';
    item: Competition;
    rings: Array<CompetitionRing>,
    registrations: Array<CompetitionRegistration>
}

interface RequestCompetitionAction {
    type: 'REQUEST_COMPETITION';
}

interface RequestRegistrationAction {
    type: 'REQUEST_REGISTRATION';
}

interface ReceiveCompetitionAction {
    type: 'RECEIVE_COMPETITION';
    items: Array<Competition>;
    hasMoreItems: boolean;
    issuers: Array<InvoiceIssuer>;
    infos: Array<InvoiceInfo>;
    registrations: Array<CompetitionRegistration>;
    error: '';
}

interface RequestMoreCompetitionAction {
    type: 'REQUEST_MORE_COMPETITION';
}

interface ReceiveMoreCompetitionAction {
    type: 'RECEIVE_MORE_COMPETITION';
    items: Array<Competition>;
    hasMoreItems: boolean;
    error: '';
}

interface ReceiveRegistrationAction {
    type: 'RECEIVE_REGISTRATION';
    item: CompetitionRegistration | null;
    dogs: Array<Dog>
}

interface RequestMoreRegistrationsAction {
    type: 'REQUEST_MORE_REGISTRATIONS';
}

interface ReceiveMoreRegistrationsAction {
    type: 'RECEIVE_MORE_REGISTRATIONS';
    items: Array<Competition>;
    registrations: any;
    error: '';
}

interface RequestCompetitionDateAction {
    type: 'REQUEST_COMPETITION_DATE';

}

interface ReceiveCompetitionDateAction {
    type: 'RECEIVE_COMPETITION_DATE';
    item: CompetitionDate;
    rings: Array<CompetitionRing>;
}


interface RequestCompetitionDogAction {
    type: 'REQUEST_COMPETITION_DOG';
}

interface ReceiveCompetitionDogAction {
    type: 'RECEIVE_COMPETITION_DOG';
    item: CompetitionDog | null;
}

interface RequestCompetitionCoupleAction {
    type: 'REQUEST_COMPETITION_COUPLE';
}

interface ReceiveCompetitionCoupleAction {
    type: 'RECEIVE_COMPETITION_COUPLE';
    item: CompetitionCoupleGroup | null;
}

interface RequestCompetitionJuniorSeniorAction {
    type: 'REQUEST_JUNIOR_SENIOR';
}

interface ReceiveCompetitionJuniorSeniorAction {
    type: 'RECEIVE_JUNIOR_SENIOR';
    item: CompetitionJuniorSenior | null;
}

interface ReceiveCompetitionClassesAction {
    type: 'RECEIVE_COMPETITION_CLASSES';
    classes: Array<CompetitionClass>;
}

interface ReceiveBreedsAction {
    type: 'RECEIVE_COMP_BREEDS';
    breeds: Array<Breed>;
    catalogBreeds: Array<Breed>;
}

interface RequestSpecialShowRegistrationAction {
    type: 'REQUEST_SPECIAL_SHOW_REGISTRATION';

}

interface ReceiveSpecialShowRegistrationAction {
    type: 'RECEIVE_SPECIAL_SHOW_REGISTRATION';
    item: CompetitionSpecialShow | null;
}

interface RequestCompetitionRegistrationInfoAction {
    type: 'REQUEST_COMPETITION_REGISTRATION_INFO';
}

interface ReceiveCompetitionRegistrationInfoAction {
    type: 'RECEIVE_COMPETITION_REGISTRATION_INFO';
    
    registrations: any;
    error: '';
}

interface RequestCountriesAction {
    type: 'REQUEST_COUNTRIES';
}

interface ReceiveCountriesAction {
    type: 'RECEIVE_COUNTRIES';
    items: Array<OwnerBreederCountry>;
}

interface RequestProfileDogsAction {
    type: 'REQUEST_PROFILE_DOGS';
}

interface ReceiveProfileDogsAction {
    type: 'RECEIVE_PROFILE_DOGS';
    items: Array<Dog>;
}

interface GetPageAction {
    type: 'GET_PAGE';

}

interface SetPageAction {
    type: 'SET_PAGE';
    page: 0;
}

interface GetEmailAction {
    type: 'GET_EMAIL';

}

interface SetEmailAction {
    type: 'SET_EMAIL';
    email: "";
}

interface SetStatusAction {
    type: 'SET_STATUS';
    status: null;
}

interface SetCatalogNumberAction {
    type: 'SET_CATALOG_NUMBER';
    catalogNumber: "";
}

interface SetDogOwnerAction {
    type: 'SET_DOG_OWNER';
    dogOwner: "";
}

interface SetDogNameAction {
    type: 'SET_DOG_NAME';
    dogName: "";
}

interface SetCompTypeAction {
    type: 'SET_COMP_TYPE';
    compType: "";
}

interface SetCompDateIdAction {
    type: 'SET_COMP_DATE_ID';
    compDateId: "";
}

interface GetSpecialCompAction {
    type: 'GET_SPECIAL_COMP';

}

interface SetSpecialCompAction {
    type: 'SET_SPECIAL_COMP';
    specialComp: Array<SpecialComp>;
}

interface SetOpenRegistrationAction {
    type: 'SET_OPEN_REGISTRATION';
    openReg: boolean;
}

interface RequestStripeAccountsAction {
    type: 'REQUEST_SACCOUNTS';
}

interface ReceiveStripeAccountsAction {
    type: 'RECEIVE_SACOUNTS';
    items: Array<StripeAccount>;
}

type KnownAction =
    SelectCompetitionAction
    | RequestCompetitionAction
    | RequestMoreRegistrationsAction
    | ReceiveMoreRegistrationsAction
    | RequestCompetitionDogAction
    | ReceiveCompetitionDogAction
    | RequestRegistrationAction
    | ReceiveRegistrationAction
    | ReceiveCompetitionAction
    | RequestCompetitionRegistrationInfoAction
    | ReceiveCompetitionRegistrationInfoAction
    | RequestCountriesAction
    | ReceiveCountriesAction
    | RequestCompetitionDateAction
    | ReceiveCompetitionDateAction
    | GetPageAction
    | SetPageAction
    | GetEmailAction
    | SetEmailAction
    | GetSpecialCompAction
    | SetSpecialCompAction
    | SetOpenRegistrationAction
    | RequestCompetitionCoupleAction
    | ReceiveCompetitionCoupleAction
    | RequestSpecialShowRegistrationAction
    | ReceiveSpecialShowRegistrationAction
    | RequestCompetitionJuniorSeniorAction
    | ReceiveCompetitionJuniorSeniorAction
    | RequestProfileDogsAction
    | ReceiveProfileDogsAction
    | ReceiveCompetitionClassesAction
    | ReceiveBreedsAction
    | SetCatalogNumberAction
    | SetStatusAction
    | SetCompTypeAction
    | SetCompDateIdAction
    | SetDogNameAction
    | SetDogOwnerAction
    | RequestMoreCompetitionAction
    | ReceiveMoreCompetitionAction
    | RequestStripeAccountsAction
    | ReceiveStripeAccountsAction;

export const actionCreators = {
    selectItem: (item: Competition, page?: any, compId?: string, email?: string, count?: number, paymentStatus?: number, catalogNumber?: number): AppThunkAction<KnownAction> => async (dispatch) => {
        let regs: any;
        let competition = item && item.id;
        try {
            /*regs = await httpService.getCompetitionRegistrations({ page, competition, email, count, paymentStatus, catalogNumber})*/
            dispatch({ type: 'SELECT_COMPETITION', item: item, rings: [], registrations: regs });
        } catch (err) {
            dispatch({ type: 'SELECT_COMPETITION', item: item, rings: [], registrations: [] });
        }
    },

    getItems: (page: number = 0, count: number = 25): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList && appState.user) {
            try {
                dispatch({ type: 'REQUEST_COMPETITION' });
                let data = await httpService.getCompetitions(page, count);
                let issuer = await httpService.invoiceIssuers("", IssuerType.FEE);
                let info = await httpService.invoiceInfoList(IssuerType.FEE);

                dispatch({ type: 'RECEIVE_COMPETITION', items: data, hasMoreItems: data.length === count, issuers: issuer || [], infos: info || [], registrations: [], error: '' });
            } catch (err) {

                dispatch({ type: 'RECEIVE_COMPETITION', hasMoreItems: false, items: [], issuers: [], infos: [], registrations: [], error: '' });
            }
        }
    },

    getRegistration: (id?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'REQUEST_REGISTRATION' });
            try {
                if (id) {
                    let data = await httpService.getCompetitionRegistration(id);
                    let dogs = await httpService.getProfileDogs(data.profile.id);
                    dispatch({ type: 'RECEIVE_REGISTRATION', item: data, dogs: dogs });
                } else {
                    dispatch({ type: 'RECEIVE_REGISTRATION', item: null, dogs: [] });
                }

            } catch (err) {
                dispatch({ type: 'RECEIVE_REGISTRATION', item: null, dogs: [] });

            }

        }
    },

    getRegistrationItems: (page?: any, competition?: string, email?: string, count?: number, paymentStatus?: number, catalogNumber?: number, type?: number, competitionDateId?: string, dogOwner?: string, dogName?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'REQUEST_COMPETITION_REGISTRATION_INFO' });
            let reg = await httpService.getCompetitionRegistrations({ page, competition, email, count, paymentStatus, catalogNumber, type, competitionDateId, dogName, dogOwner });
            dispatch({
                type: 'RECEIVE_COMPETITION_REGISTRATION_INFO',
                registrations: reg,
                error: ''
            })
        }
    },

    getCompetitionDate: (id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'REQUEST_COMPETITION_DATE' });
            let data = await httpService.getCompetitionDate(id);
            let rings = await httpService.getRingsForDate(data.id)
            dispatch({ type: 'RECEIVE_COMPETITION_DATE', item: data, rings: rings });
        }
    },

    getCompetitionDog: (id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'REQUEST_COMPETITION_DOG' });
            if (id === null) {

                dispatch({ type: 'RECEIVE_COMPETITION_DOG', item: null });
            } else {

                let data = await httpService.getCompetitionRegistrationDog(id);
                dispatch({ type: 'RECEIVE_COMPETITION_DOG', item: data });
            }
        }
    },

    getCompetitionCoupleGroup: (id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'REQUEST_COMPETITION_COUPLE' });
            if (id === null) {

                dispatch({ type: 'RECEIVE_COMPETITION_COUPLE', item: null });
            } else {
                try {

                    let data = await httpService.getCompetitionCoupleGroup(id);
                    dispatch({ type: 'RECEIVE_COMPETITION_COUPLE', item: data });
                } catch (err) {
                    dispatch({ type: 'RECEIVE_COMPETITION_COUPLE', item: null });
                }
            }
        }
    },
    getCompetitionJuniorSenior: (id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'REQUEST_JUNIOR_SENIOR' });
            if (id === null) {

                dispatch({ type: 'RECEIVE_JUNIOR_SENIOR', item: null });
            } else {
                try {

                    let data = await httpService.getCompetitionJuniorSenior(id);
                    dispatch({ type: 'RECEIVE_JUNIOR_SENIOR', item: data });
                } catch (err) {
                    dispatch({ type: 'RECEIVE_JUNIOR_SENIOR', item: null });
                }
            }
        }
    },

    getCompetitionClasses: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            try {
                let data = await httpService.getCompetitionClasses();
                dispatch({ type: 'RECEIVE_COMPETITION_CLASSES', classes: data });
            } catch (err) { }
        }
    },

    getAllBreeds: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            try {
                let web = await httpService.webBreeds();
                let catalog = await httpService.catalogBreeds();
                dispatch({ type: 'RECEIVE_COMP_BREEDS', breeds: web, catalogBreeds: catalog });
            } catch (err) { }
        }
    },

    getCompetitionSpecialShow: (id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'REQUEST_SPECIAL_SHOW_REGISTRATION' });
            if (id === null) {

                dispatch({ type: 'RECEIVE_SPECIAL_SHOW_REGISTRATION', item: null });
            } else {
                try {

                    let data = await httpService.getSpecialShowRegistration(id);
                    dispatch({ type: 'RECEIVE_SPECIAL_SHOW_REGISTRATION', item: data });
                } catch (err) {
                    dispatch({ type: 'RECEIVE_SPECIAL_SHOW_REGISTRATION', item: null });

                }
            }
        }
    },

    getMoreItems: (page?: any, competition?: string, email?: string, count?: number, paymentStatus?: number, catalogNumber?: number, type?: number, competitionDateId?: string, dogOwner?: string, dogName?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {

            dispatch({ type: 'REQUEST_MORE_REGISTRATIONS' });
            let data = await httpService.getCompetitions();
            let reg = await httpService.getCompetitionRegistrations({ page, competition, email, count, paymentStatus, catalogNumber, type, competitionDateId, dogName, dogOwner });
            dispatch({
                type: 'RECEIVE_MORE_REGISTRATIONS',
                items: data,
                registrations: reg,
                error: ''

            })
        }
    },

    getMoreCompetitions: (page: number, count: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {

            dispatch({ type: 'REQUEST_MORE_COMPETITION' });
            let data = await httpService.getCompetitions(page, count);
            dispatch({
                type: 'RECEIVE_MORE_COMPETITION',
                items: data,
                hasMoreItems: data.length === count,
                error: ''
            })
        }
    },

    getProfileDogs: (id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'REQUEST_PROFILE_DOGS' });

            try {
                let data = await httpService.getProfileDogs(id);
                dispatch({ type: 'RECEIVE_PROFILE_DOGS', items: data });
            } catch (err) {
                dispatch({ type: 'RECEIVE_PROFILE_DOGS', items: [] });
            }
        }
    },

    getCountryItems: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'REQUEST_COUNTRIES' });
            let data = await httpService.getCountries();
            dispatch({ type: 'RECEIVE_COUNTRIES', items: data });
        }
    },

    getStripeAccountItems: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'REQUEST_SACCOUNTS' });
            let data = await httpService.getStripeAccounts();
            dispatch({ type: 'RECEIVE_SACOUNTS', items: data });
        }
    },

    setPage: (page?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'SET_PAGE', page: page });
        }
    },

    setName: (name?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'SET_EMAIL', email: name });
        }
    },
    setPaymentStatus: (status?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'SET_STATUS', status: status });
        }
    },
    setCatalogNumber: (catalogNumber?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'SET_CATALOG_NUMBER', catalogNumber: catalogNumber });
        }
    },
    setDogName: (dogName?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'SET_DOG_NAME', dogName: dogName });
        }
    },
    setDogOwner: (dogOwner?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'SET_DOG_OWNER', dogOwner: dogOwner });
        }
    },
    setCompType: (type?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'SET_COMP_TYPE', compType: type });
        }
    },
    setCompDateId: (compDateId?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'SET_COMP_DATE_ID', compDateId: compDateId });
        }
    },

    setOpenReg: (regOpen?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            dispatch({ type: 'SET_OPEN_REGISTRATION', openReg: regOpen });
        }
    },

    getSpecialComp: (id?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionList) {
            let data = await httpService.getSpecialCompetition(id);
            dispatch({ type: 'SET_SPECIAL_COMP', specialComp: data });
        }
    }
}

const unloadedState: CompetitionListState = {
    selectedItem: null,
    isLoading: false,
    isMoreLoading: false,
    hasMoreItems: false,
    list: [],
    countries: [],
    registrations: null,
    registration: null,
    competitionDate: null,
    competitionDog: null,
    specialShowRegistration: null,
    juniorSeniorRegistration: null,
    specialComp: [],
    classes: [],
    breeds: [],
    catalogBreeds: [],
    invoiceInfo: [],
    invoiceIssuers: [],
    profileDogs: [],
    error: '',
    selectedTab: 0,
    hasSubTab: false,
    hasMoreRegistrations: true,
    rings: [],
    competitionCouplesGroups: null,
    page: 0,
    count: 20,
    competitionId: '',
    email: '',
    openReg: false,
    status: null,
    catalogNumber: null,
    compType: null,
    compDateId: null,
    dogName: '',
    dogOwner: '',
    stripeAccounts: []
}

export const reducer: Reducer<CompetitionListState> = (state: CompetitionListState | undefined, incomingAction: Action): CompetitionListState => {
    if (state === undefined) {
        return unloadedState;
    }

    let stateCopy = { ...state }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_COMPETITION':
            stateCopy.hasMoreRegistrations = true

            stateCopy.selectedItem = action.item;
            stateCopy.isLoading = false;
            stateCopy.error = '';
            stateCopy.registrations = null;
            stateCopy.hasSubTab = false;

            return stateCopy;

        case 'REQUEST_COMPETITION':

            stateCopy.list = [];
            stateCopy.countries = [];
            stateCopy.isLoading = true;
            stateCopy.error = '';
            stateCopy.hasSubTab = false;

            return stateCopy;

        case 'RECEIVE_COMPETITION':

            let si = state.selectedItem;

            if (action.items && action.items.length > 0 && !state.selectedItem) {
                si = action.items[0];
            }

            if (si) {
                stateCopy.competitionId = si.id!
            }

            stateCopy.selectedItem = si;
            stateCopy.list = action.items;
            stateCopy.hasMoreItems = action.hasMoreItems;
            stateCopy.invoiceIssuers = action.issuers;
            stateCopy.invoiceInfo = action.infos;
            stateCopy.isLoading = false;
            stateCopy.registrations = action.registrations;
            stateCopy.error = action.error;
            stateCopy.hasSubTab = false;

            return stateCopy;

        case 'REQUEST_MORE_COMPETITION': {
            stateCopy.isMoreLoading = true
            return stateCopy
        }

        case 'RECEIVE_MORE_COMPETITION': {
            stateCopy.isMoreLoading = false;
            stateCopy.list.push(...action.items);
            stateCopy.hasMoreItems = action.hasMoreItems;
            return stateCopy;
        }

        case 'RECEIVE_REGISTRATION':
            let selected = state.selectedItem;

            let sta = { ...state };

            sta.registration = action.item;
            sta.profileDogs = action.dogs;

            return sta;

        case 'RECEIVE_COMPETITION_REGISTRATION_INFO': {
            state.hasMoreRegistrations = true
            let s = state.selectedItem;

            if (action.registrations.length < state.count) {
                state.hasMoreRegistrations = false;

            } else if (action.registrations.length > 0 && state.count === 0 && action.registrations.length < 20) {
                state.count = action.registrations.length
                state.hasMoreRegistrations = false

            } else {
                state.count = action.registrations.length
                state.hasMoreRegistrations = true
            }
            let tab = state.selectedTab
            if (tab === 0) {
                tab = 1;
            }

            let st = { ...state };

            st.registrations = action.registrations;
            st.error = action.error;

            st.isLoading = false;
            st.selectedTab = tab;

            return st;
        }

        case 'RECEIVE_MORE_REGISTRATIONS':
            let moreRegistrations: any = []
            stateCopy.hasMoreRegistrations = true;
            let registrationList = [...state.registrations]

            let items = action.registrations

            if (action.registrations.length > 0) {
                moreRegistrations = [...registrationList, ...items]
            } else {
                moreRegistrations = registrationList;
            }

            let selit = state.selectedItem;

            if (state.count! > items.length || items.length === 0) {
                stateCopy.hasMoreRegistrations = false
            }

            stateCopy.selectedItem = selit
            stateCopy.registrations = moreRegistrations
            stateCopy.isLoading = false
            stateCopy.error = action.error
            return stateCopy;

        case 'RECEIVE_COUNTRIES':

            stateCopy.countries = action.items;
            return stateCopy;

        case 'RECEIVE_SACOUNTS':

            stateCopy.stripeAccounts = action.items;
            return stateCopy;

        case 'RECEIVE_COMPETITION_DATE':

            stateCopy.competitionDate = action.item;
            stateCopy.rings = action.rings;
            return stateCopy;

        case 'RECEIVE_COMPETITION_DOG':

            stateCopy.competitionDog = action.item;
            return stateCopy;

        case 'RECEIVE_JUNIOR_SENIOR':

            stateCopy.juniorSeniorRegistration = action.item;
            return stateCopy;

        case 'SET_PAGE':

            stateCopy.page = action.page;
            return stateCopy;

        case 'SET_EMAIL':

            stateCopy.email = action.email;
            return stateCopy;

        case 'SET_STATUS':

            stateCopy.status = action.status;
            return stateCopy;

        case 'SET_CATALOG_NUMBER':

            stateCopy.catalogNumber = action.catalogNumber;
            return stateCopy;

        case 'SET_DOG_OWNER':

            stateCopy.dogOwner = action.dogOwner;
            return stateCopy;

        case 'SET_DOG_NAME':

            stateCopy.dogName = action.dogName;
            return stateCopy;

        case 'SET_COMP_TYPE':

            stateCopy.compType = action.compType;
            return stateCopy;

        case 'SET_COMP_DATE_ID':

            stateCopy.compDateId = action.compDateId;
            return stateCopy;

        case 'SET_SPECIAL_COMP':

            stateCopy.specialComp = action.specialComp;
            return stateCopy;

        case 'SET_OPEN_REGISTRATION':

            stateCopy.openReg = action.openReg;
            return stateCopy;

        case 'RECEIVE_COMPETITION_COUPLE':

            stateCopy.competitionCouplesGroups = action.item;
            return stateCopy;

        case 'RECEIVE_SPECIAL_SHOW_REGISTRATION':

            stateCopy.specialShowRegistration = action.item;
            return stateCopy;

        case 'RECEIVE_PROFILE_DOGS':

            stateCopy.profileDogs = action.items;
            return stateCopy;

        case 'RECEIVE_COMPETITION_CLASSES':

            stateCopy.classes = action.classes;
            return stateCopy;

        case 'RECEIVE_COMP_BREEDS':

            stateCopy.breeds = action.breeds;
            stateCopy.catalogBreeds = action.catalogBreeds;
            return stateCopy;
    }
    return state;
}
