import React, { ComponentProps } from 'react';
import './FullLoader/FullLoader.scss'
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as DialogStore from '../store/DialogStore';

type LoaderProps = {

    data?: any;
}
  

class RegistrationLoader extends React.Component<any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
               
                   
                    <div id='reg-loader'>
                        <div className="spinner"></div>
                    </div>
                
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.dialog,
    DialogStore.actionCreators
)(RegistrationLoader);