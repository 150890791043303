import * as React from 'react';
import {connect} from 'react-redux';
import { Route } from 'react-router';
import { history } from '../../index';
import {httpService} from '../../index';
import {ApplicationState} from '../../store';
import * as DialogStore from '../../store/DialogStore';
import * as BreedListStore from '../../store/BreedStore';
import {ListBase} from '../base/ListBase';
import {Helpers} from '../../utility/Helpers';
import ContentStatus from '../../components/ContentStatus';
import {Breed} from '../../models/dog/Breed';
import BreedDetails from './BreedDetails';
import BreedEdit from './BreedEdit';
import {Link} from 'react-router-dom';
import { DialogType } from '../../store/DialogStore';


class BreedList extends ListBase {
    scrollHere: any

    constructor(props: any, context: any) {
        super(props, context, 'breed');
        this.scrollHere = React.createRef();
        this.deleteItemCall = async (id: string) => {
            return await httpService.deleteBreed(id)
        };
    }

    public componentDidMount() {

        super.componentDidMount();
        this.props.getBreedCategories();
        
    }

    scrollTo(position: any) {
        // @ts-ignore
        const project: any = document.getElementsByClassName("dd-container")["breed-table"]
        if (project) {
            let children: any = project.children
            if (children) {
                children[0]!.scrollIntoView();
            }
        }

    }

    filterBreeds (input: any) {
        const result = this.props.dataState.list.filter((b: any) => b.name.toLowerCase().includes(input.toLowerCase()));
        this.props.getFilteredBreeds(result)
        if (result.length > 0) {
            this.props.selectItem(result[0]);
            history.replace(`/${this.baseUrl}/details/${result[0].id}`);
        }
        
    }

    async deleteBreedItem(index: number, property: string) {

        let item: any = this.props.dataState.filtered[index];

        this.props.showDialog(`Are you sure you want to delete "${property}" ?`, DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {
                try {

                    await this.deleteItemCall(item.id);
                    this.props.getItems();
                    this.props.selectItem(null);
                   
                } catch (err) {
                    alert(err.message);
                }
            }
        })
    }

    showBreedDetails(index: number) {
        let item: any = this.props.dataState.filtered[index];
        {
            this.props.userState && Helpers.isUserEventViewer(this.props.userState.user) &&
            this.props.selectItem(item)
        }
        history.replace(`/${this.baseUrl}/details/${item.id}`);
    }

    render() {
        const scrollX = window.scrollX;
        const scrollY = window.scrollY;
        let timer: any;

        return (
           
            <div id="breed-table" className="row gap-20 masonry pos-r">
                
                <div className="masonry-item col-md-5">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <h4 className="c-grey-900 mB-20" style={{ float: "left" }}>Breed List</h4>
                        <div style={{ clear: "both" }} />

                        {!Helpers.isUserDogViewer(this.props.userState.user) &&
                            <div style={{ float: "right", verticalAlign: "middle" }}>
                                <Link to='/breed/add'>
                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                </Link>
                            </div>
                        }

                        <div className="search">
                            <input style={{ width: "67%" }} className="form-control" type="text" id="exampleInputEmail1"
                                maxLength={50} placeholder="Enter Breed Name" onChange={(e) => {
                                    clearTimeout(timer);
                                    timer = setTimeout(() => {
                                        this.filterBreeds(e.target.value)
                                    }, 1000);
                                    if (e.target.value === "") {
                                        this.props.getFilteredBreeds(this.props.dataState.list)
                                    }
                                }} />
                        </div>
                        <br/>

                        <ContentStatus isLoading={this.props.dataState.isLoading}
                                       errorMessage={this.props.dataState.error}
                                       data={this.props.dataState.list}></ContentStatus>

                        {(!this.props.dataState.isLoading && !this.props.dataState.error && this.props.dataState.list && this.props.dataState.filtered && this.props.dataState.list.length > 0) &&
                            <table id="breed-table" className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col" className="ta-c mw-actions-min">FCI</th>
                                    <th scope="col" className="ta-r mw-actions">Actions</th>
                                </tr>
                                </thead>
                                <tbody id="breed-table" className="dd-container" ref={this.dragulaDecorator}>

                                    {this.props.dataState.filtered && this.props.dataState.filtered.map((breed: any, index: number) => (
                                    <tr className={`${this.getActiveClass(breed)} ${Helpers.getPublishedClass(breed)}`}
                                        key={index}>
                                        <td>{index + 1 }</td>
                                        <td className="cur-p" onClick={(e: any) => {
                                            e.preventDefault();
                                            this.showBreedDetails(index);
                                           
                                        }}>
                                            {breed.name}
                                        </td>
                                        <td className="ta-c">
                                            <span style={{fontSize: "16px"}}
                                                  className="icon-holder">{breed.fci.toString()}</span>
                                        </td>
                                        <td className="ta-r">
                                            <Link to={`/breed/edit/${breed.id}`}>
                                                <span style={{fontSize: "16px"}} className="icon-holder"><i
                                                    className="c-light-blue-500 ti-pencil"></i></span>
                                            </Link>
                                            <span style={{fontSize: "16px"}}> | </span>
                                            <a href="#" onClick={(e) => {
                                                window.scroll(scrollX, scrollY);
                                                e.preventDefault();
                                                this.deleteBreedItem(index, breed.name)
                                            }}>
                                                <span style={{fontSize: "16px"}} className="icon-holder"><i
                                                    className="c-light-blue-500 ti-trash"></i></span>
                                            </a>
                                        </td>
                                        </tr>
                                    )) }
                                </tbody>
                            </table>}
                        {(this.props.dataState.filtered && this.props.dataState.filtered.length < 1) &&
                            (
                                <div style={{ color: "grey", display: "flex", justifyContent: "center" }} className="cur-p">No breeds.</div>
                            )
                        }

                    </div>
                </div>
                <div className="masonry-item col-md-7">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <Route exact path='/breed/details/:itemId?' component={BreedDetails}/>
                        <Route exact path='/breed/edit/:itemId?' component={BreedEdit}/>
                        <Route exact path='/breed/add' component={BreedEdit}/>
                    </div>
                </div>
            </div>)
    }
};

const mapStateToProps = (state: ApplicationState) => {
    return {dataState: state.breedList, userState: state.user};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        selectItem: (data: Breed) => {
            dispatch(BreedListStore.actionCreators.selectItem(data))
        },
        getItems: () => {
            dispatch(BreedListStore.actionCreators.getItems())
        },
        getFilteredBreeds: (data: any) => {
            dispatch(BreedListStore.actionCreators.getFilteredBreeds(data))
        },
        getBreedCategories: () => {
            dispatch(BreedListStore.actionCreators.getBreedCategories())
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreedList);
