import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import { httpService } from "../index";
import * as DialogStore from '../store/DialogStore';
import { SpecialComp } from '../models/competition/CompetitionDate';
import { NotificationType } from '../store/DialogStore';
import * as CompetitionListStore from '../store/CompetitionListStore';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Helpers } from '../utility/Helpers';
import StaffSearch from './StaffSearch';

type SpecialCompDialogProps =
    DialogStore.DialogState &
    typeof DialogStore.actionCreators &
    React.ComponentProps<any>;

export type SpecialCompDialogData = {
    data?: SpecialComp,
    callback: Function,
    open: any;
    parentId: any,
    addMode: any
}

class SpecialCompDialog extends React.PureComponent<SpecialCompDialogProps, any> {

    time: any;

    constructor(props: any) {
        super(props);
        this.state = {
            specialComp: null,
            showPopup: false,
            previewMode: true,
            disableButton: false
        }
    }

    componentDidMount() {
        this.dataChanged();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.data != prevProps.data) {
            this.time = setTimeout(() => {
                this.dataChanged();
                this.clearTimeout();
            }, 0)
        }
    }

    componentWillUnmount() {
        this.clearTimeout();
    }

    private clearTimeout() {
        if (this.time) {
            clearTimeout(this.time);
            this.time = null;
        }
    }

    async changeJudge(e: any) {
        let data: any = { ...this.state.specialComp }

        let j = this.props.judges.find((judge: any) => judge.id === e)
        if (j) {
            let judge = { "username": j.userName, "firstName": j.firstName, "lastName": j.lastName, "id": j.id }
            data.judge = judge;
            this.setState({ specialComp: data })
        }
    }

    saveChanges = async (data: SpecialComp) => {

        this.props.increment();
        try {
            this.setState({ disableButton: true })
            let res = await httpService.specialCompetitionAddOrUpdate(data);
            if (!data.id) {
                this.props.add({ title: res.title, id: res.id }, false)
            }
            this.props.decrement();
            this.setState({ specialComp: null })

            this.close(true)
            await this.props.getCompetitionDate(this.props.parentId)
            this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });
            this.setState({ disableButton: false })

        } catch (err) {
            this.props.decrement();
            alert(err.message);
            this.setState({ disableButton: false })
        }

    }

    close(res: boolean) {
        this.time = setTimeout(() => {

            this.props.hideSpecialCompDialog();
            this.props.closePreview()


        }, 500)

    }

    editMode() {
        this.setState({ previewMode: false })
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        let at: any = { ...this.state.specialComp };
        at[name] = value

        this.setState({ specialComp: at });
    }


    private dataChanged() {
        this.setState({
            showPopup: true, specialComp: (this.props.data) ? this.props.data : {
                title: '',
                englishTitle: '',
                price: '',
                isFinished: false,
                isPresentFinished: false,
                approxStartTime: ''
            }
        });
    }

    setMode(): boolean {
        if (this.props.addMode) {
            return false
        } else if (this.state.previewMode) {
            return true
        } else {
            return false
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    (this.props.data) &&
                    <Modal style={{ maxWidth: '550px', width: '100%' }} size="lg" isOpen={this.props.open}>
                        <ModalHeader toggle={() => this.close(false)}>{(this.state.specialComp && this.state.specialComp.hasOwnProperty("id")) ? "Edit " + this.state.specialComp.title : "Add Special Competition"}</ModalHeader>
                        <form onSubmit={(e) => { e.preventDefault(); this.saveChanges(this.state.specialComp as SpecialComp) }}>
                            <ModalBody>
                                <div className="row">
                                    {!this.props.addMode && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                        <td><span onClick={() => this.setState({ previewMode: !this.state.previewMode })} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                            className="c-light-blue-500 ti-pencil"></i></span>
                                        </td>

                                    }
                                </div>


                                <div className="mb-3">
                                    <label className="form-label" htmlFor="title">Title *</label>
                                    <input id="title" name="title" required className="form-control"
                                        disabled={this.setMode()}
                                        value={this.state.specialComp && this.state.specialComp.title} onChange={this.changeData}
                                        maxLength={2000} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="englishTitle">English Title *</label>
                                    <input id="englishTitle" name="englishTitle" required className="form-control"
                                        disabled={this.setMode()}
                                        value={this.state.specialComp && this.state.specialComp.englishTitle} onChange={this.changeData}
                                        maxLength={2000} />
                                </div>
                                <hr />
                                <h5>Payment Information</h5>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="price">Price *</label>
                                    <input type="number" id="price" name="price" required className="form-control" min="0.00"
                                        step="0.1"
                                        disabled={this.setMode()}
                                        value={this.state.specialComp && this.state.specialComp.price} onChange={this.changeData}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="invoiceDescription">Invoice Description *</label>
                                    <input id="invoiceDescription" name="invoiceDescription" required className="form-control"
                                        disabled={this.setMode()}
                                        value={this.state.specialComp && this.state.specialComp.invoiceDescription} onChange={this.changeData}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basePriceTaxPercentage">Base Price Tax Percentage *</label>
                                    <input type="number" step="0.1" className="form-control"
                                        disabled={this.setMode()}
                                        name="basePriceTaxPercentage"
                                        min={0.0}
                                        value={this.state.specialComp && this.state.specialComp.basePriceTaxPercentage} required onChange={this.changeData}></input>

                                </div>
                                <hr />
                                <h5>BIS Information</h5>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="judge">Judge</label>
                                        <StaffSearch action={(e: any) => this.changeJudge(e)} data={this.state.specialComp} type="judge"
                                        suggestions={this.props.judges} disabled={this.setMode()} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="approxStartTime">Approximate Start Time </label>
                                    <input id="approxStartTime" name="approxStartTime" className="form-control"
                                        disabled={this.setMode()}
                                        value={this.state.specialComp && this.state.specialComp.approxStartTime} onChange={(e) => this.changeData(e)}
                                        maxLength={2000} />
                                </div>

                                <div className="mb-3">
                                    <div className="checkbox checkbox-info peers ai-c">
                                        <input id="isFinished" name="isFinished" type="checkbox"
                                            disabled={this.setMode()}
                                            checked={this.state.specialComp && this.state.specialComp.isFinished} className="peer"
                                            onChange={(e) => this.changeData(e)} />
                                        <label className="form-label peers peer-greed js-sb ai-c" htmlFor="isFinished">
                                            <span className="peer peer-greed">Is Finished</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="checkbox checkbox-info peers ai-c">
                                        <input id="isPresentFinished" name="isPresentFinished" type="checkbox"
                                            disabled={this.setMode()}
                                            checked={this.state.specialComp && this.state.specialComp.isPresentFinished} className="peer"
                                            onChange={(e) => this.changeData(e)} />
                                        <label className="form-label peers peer-greed js-sb ai-c" htmlFor="isPresentFinished">
                                            <span className="peer peer-greed">Is Present Finished</span>
                                        </label>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>

                                <button type="button" className="btn btn-secondary" onClick={() => { this.close(false) }} data-bs-dismiss="modal">Cancel</button>
                                {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                    <button disabled={this.state.disableButton} type="submit" className="btn btn-primary">Save</button>
                                }

                            </ModalFooter>
                        </form>
                    </Modal>

                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        dataState: state.competitionList,
        dialog: state.dialog,
        userState: state.user
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {

        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showSpecialCompDialog: (d: SpecialComp, detailsOnly: boolean, cal: Function) => {
            dispatch(DialogStore.actionCreators.showSpecialCompDialog(d, detailsOnly, cal))
        },
        getCompetitionDate: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
        },
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        hideCompDateDialog: () => {
            dispatch(DialogStore.actionCreators.hideCompDateDialog())
        },
        hideSpecialCompDialog: () => {
            dispatch(DialogStore.actionCreators.hideSpecialCompDialog())
        },
        showNotification: (data: DialogStore.NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(SpecialCompDialog)
