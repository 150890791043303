import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';
import { history, httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as PedigreeAbbreviationStore from '../../store/PedigreeAbbreviationStore';
import * as DialogStore from '../../store/DialogStore';
import { DetailsBase } from '../base/DetailsBase';
import { NotificationData } from '../../store/DialogStore';
import { PedigreeAbbreviation } from '../../models/dog/PedigreeAbbreviation';




class PedigreeEdit extends DetailsBase<PedigreeAbbreviation> {

    codeError: string;
    propsForTrim: Array<string>;

    constructor(props: any) {
        super(props);

        this.codeError = '';
        this.propsForTrim = ['abbrevation', 'country']
        this.getItem = async (id: string) => { return await httpService.getPedigreeAbbreviation(id) }
        this.saveItem = async (data: PedigreeAbbreviation) => { return await httpService.pedigreeAbbreviationAddOrUpdate(data) }
        this.redirectAfterSave = (id: string) => { history.replace(`/pedigree-abbreviation/details/${id}`) }


        this.emptyData = {
            abbrevation: '',
            country: '',
            created: new Date()
        }


    }

    onSubmit = (ev: any) => {
        ev.preventDefault();

        let data: any = { ...this.state.data };
       

        this.trimProps(this.propsForTrim, data);

        this.saveChanges(data as PedigreeAbbreviation);
    }



    changeData = (ev: any) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let val = value;

        let data: any = { ...this.state.data };
        data[name] = val;

        this.whitespaceTrimProps(this.propsForTrim, data, name);

        this.setState({
            data: data
        })
    }



    render() {
        return (
            <div>
                <h4 className="c-grey-900">{this.isNew(this.state) && !this.state.isLoading ? "Add" : "Edit"} Pedigree Abbreviation</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error} data={this.state.data}></ContentStatus>
                <br />
                {(this.state && this.state.data) &&
                    <form onSubmit={this.onSubmit}>

                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="abbrevation">Abbreviation *</label>
                                <input id="abbrevation" name="abbrevation" required className="form-control"
                                       value={this.state.data.abbrevation || ''} onChange={this.changeData}
                                       maxLength={2000}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="country">Country *</label>
                                <input id="country" name="country" required className="form-control"
                                       value={this.state.data.country || ''} onChange={this.changeData}
                                       maxLength={2000}/>
                            </div>

                        </div>


                        <br />
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.pedigreeList };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: PedigreeAbbreviation) => { dispatch(PedigreeAbbreviationStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(PedigreeAbbreviationStore.actionCreators.getItems()) },
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PedigreeEdit);
