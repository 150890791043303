import React, { Component } from 'react';

export default class ContentStatus extends Component<{
    isLoading: boolean,
    errorMessage?: string,
    data?: any,
    emptyMessage?: string
}> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                {(!this.props.isLoading && !this.props.errorMessage && !this.props.data) &&
                    <div>{this.props.emptyMessage || 'No items found.'}</div>
                }

                {(!this.props.isLoading && this.props.errorMessage && !this.props.data) &&
                    <div>{this.props.errorMessage}</div>
                }

                {this.props.isLoading &&
                    <div>Loading...</div>
                }
            </div>
        );
    }
}