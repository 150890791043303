import * as React from 'react';
import {connect} from 'react-redux';
import {ApplicationState} from '../store';
import * as DialogStore from '../store/DialogStore';

type EditorProps = {
    value?: string,
    required?: boolean,
    acceptTags?: Array<string>,
    stripStyles?: boolean,
    replaceParagraphs?: boolean,
    onValueChange?: any,
    setLinkEditData?: any
}

class ContentEditor extends React.Component<EditorProps, { showInput: boolean }> {

    editor: any;

    constructor(props: any) {
        super(props);
        this.editor = React.createRef();

        this.state = {showInput: false};
    }

    componentDidMount() {
        this.invalidateContent();
    }

    componentDidUpdate(prevProps: EditorProps) {
        if (this.props.acceptTags != prevProps.acceptTags) {
            this.invalidateContent();
        }

        if (this.props.value != prevProps.value) {
            this.invalidateContent();
        }

        if (this.props.stripStyles != prevProps.stripStyles) {
            this.invalidateContent();
        }
    }

    insertLink() {
        var selObj = document.getSelection();

        if (selObj) {
            var selRange = selObj.getRangeAt(0);

            this.props.setLinkEditData({
                url: '', callback: (uri: string) => {
                    var sel = window.getSelection();
                    if (sel != null) {
                        sel.removeAllRanges();
                        sel.addRange(selRange);
                        document.execCommand('createLink', false, uri);
                        this.props.onValueChange(this.editor.current.innerHTML);
                    }
                }
            })
        }
    }

    execCommand(command: string) {
        document.execCommand(command);
        this.props.onValueChange(this.editor.current.innerHTML);
    }

    onBlur = () => {
        if (this.props.value !== this.editor.current.innerHTML) {
            this.props.onValueChange(this.editor.current.innerHTML);
        }
    }

    onClick = (ev: any) => {
        var current = ev.target;

        if (current != null) {

            while (current.contentEditable !== "true" && current.tagName !== 'A') {
                current = current.parentElement;
            }

            if (current.tagName === 'A') {
                this.props.setLinkEditData({
                    url: (current.attributes && current.attributes.href) ? current.attributes.href.value : '',
                    callback: (uri: string) => {
                        current.href = uri;
                        this.props.onValueChange(this.editor.current.innerHTML);
                    }
                });
            }
        }
    }

    private invalidateContent() {
        if (!this.editor)
            return;

        let val = this.props.value ? this.props.value : '';

        if (this.props.replaceParagraphs === true) {
            val = val.replace(/<p/g, '<div');
            val = val.replace(/<\/p>/g, '</div>');
        }

        if (this.editor.current.innerHTML !== val) {
            this.editor.current.innerHTML = val;
        }

        if (this.props.stripStyles || this.props.acceptTags) {
            this.updateChildNodes(this.editor.current);
        }

        if (this.props.value !== this.editor.current.innerHTML) {
            setTimeout(() => this.props.onValueChange(this.editor.current.innerHTML));
        }
    }

    private updateChildNodes(node: Node) {
        for (let i = node.childNodes.length - 1; i >= 0; --i) {
            this.updateNode(node.childNodes[i]);
        }
    }

    private updateNode(node: Node) {
        if (!node)
            return;

        this.updateChildNodes(node);

        if (this.props.stripStyles) {
            var htmlElement = node as HTMLElement;
            if (htmlElement.style)
                htmlElement.removeAttribute("style");

            if (htmlElement.className)
                htmlElement.removeAttribute("class");
        }

        if (node.nodeType != Node.ELEMENT_NODE && node.nodeType != Node.COMMENT_NODE)
            return;


        let nodeAccepted = false;


        if (!nodeAccepted) {
            while (node.childNodes.length) {
                var childNode = node.childNodes[0];
                node.removeChild(childNode);

                if (node.parentNode) {
                    node.parentNode.insertBefore(childNode, node);
                }
            }

            if (node.parentNode) {
                node.parentNode.removeChild(node);
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="content-editable-container">
                    <div className="content-editable-buttons">
                        <button tabIndex={-1} onClick={() => {
                            this.execCommand('bold')
                        }} type="button"><span className="icon-holder" style={{fontSize: "14px"}}><b>B</b></span>
                        </button>
                        <button tabIndex={-1} onClick={() => {
                            this.execCommand('italic')
                        }} type="button"><span className="icon-holder" style={{fontSize: "12px"}}><i
                            className="ti-Italic" aria-hidden="true"></i></span></button>
                        <button tabIndex={-1} onClick={() => {
                            this.execCommand('underline')
                        }} type="button"><span className="icon-holder" style={{fontSize: "12px"}}><i
                            className="ti-underline" aria-hidden="true"></i></span></button>

                    </div>
                    <div contentEditable={true} aria-hidden="true" className="form-control content-editable"
                         onBlur={this.onBlur} ref={this.editor} onInput={this.onBlur} onClick={this.onClick}></div>
                    <textarea className="content-editable-ta" value={this.props.value} onChange={(el: any) => {
                        this.props.onValueChange(el.target.value)
                    }} required={this.props.required}></textarea>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {};
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLinkEditData: (link: any) => {
            dispatch(DialogStore.actionCreators.setLinkEditData(link))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContentEditor);