import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import { Helpers } from '../../utility/Helpers';
import CountrySearch from '../CountrySearch';

export type CountriesSearchTableProps = {
    title: string,
    countries: any[],
    callback: Function,
    editMode: boolean,
    dataState: any,
    userState: any
}

class CountriesSearchTable extends React.PureComponent<CountriesSearchTableProps, any> {

    selectedCountry: any

    addCountry(country: any) {
        if (country) {
            let countries: any = [ ...this.props.countries ]

            if (!countries) {
                countries = []
            }

            const exists = countries.find((i: any) => i.country.id === country.id)

            if (exists) {
                alert('Country is already added.')
                return
            }

            countries.push({ country: country })
            this.props.callback(countries)
        }
    }

    removeCountry(index: number) {
        let countries: any = [ ...this.props.countries ]
        countries.splice(index, 1)
        this.props.callback(countries)
    }

    render() {
        return (
            <React.Fragment>
                <h5>{this.props.title}</h5>
                {(this.props.editMode === true) && <div className="row mb-3">
                    <div className="col-6">
                        <CountrySearch action={(item: any) => this.selectedCountry = item} disabled={false} data={this.selectedCountry}
                            suggestions={this.props.dataState.countries} />
                    </div>
                    <div className="col-6">
                        <button className="btn btn-outline-primary breedAddButton" onClick={() => { this.addCountry(this.selectedCountry) }}>Add selected country</button>
                    </div>
                </div>}

                {this.props.countries && this.props.countries.length > 0 ?
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                {!Helpers.isUserEventViewer(this.props.userState.user) && this.props.editMode &&
                                    <th style={{ textAlign: "right" }}>Action</th>
                                }
                            </tr>
                        </thead>

                        <tbody className="dd-container">
                            {this.props.countries && this.props.countries.length > 0 && this.props.countries.map((item: any, index: number) =>
                                <tr key={item.country.id}>
                                    <td style={{ color: "grey" }}>{item.country && item.country.name}</td>

                                    {!Helpers.isUserEventViewer(this.props.userState.user) && this.props.editMode &&
                                        <td className="ta-r">
                                            <a href="#" onClick={(e) => { e.preventDefault(); this.removeCountry(index) }}>
                                                <span style={{ fontSize: "16px" }} className="icon-holder">
                                                    <i className="c-light-blue-500 ti-trash"></i>
                                                </span>
                                            </a>
                                        </td>
                                    }
                                </tr>
                            )}
                        </tbody>
                    </table>
                    : <div>No countries</div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.competitionList, userState: state.user };
}

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CountriesSearchTable)
