import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { history } from '../../index';
import { httpService } from '../../index';
//import ContentStatus from '../../components/ContentStatus'
import { ApplicationState } from '../../store';
import * as DialogStore from '../../store/DialogStore';
import * as PedigreeAbbreviationStore from '../../store/PedigreeAbbreviationStore';
import { ListBase } from '../base/ListBase';
import { Helpers } from '../../utility/Helpers';
import ContentStatus from '../../components/ContentStatus';
import { Link } from 'react-router-dom';
import PedigreeDetails from './PedigreeAbbreviationDetails';
import PedigreeEdit from './PedigreeAbbreviationEdit';
import { PedigreeAbbreviation } from '../../models/dog/PedigreeAbbreviation';
import { DialogType } from '../../store/DialogStore';




class PedigreeList extends ListBase {

    constructor(props: any, context: any) {
        super(props, context, 'pedigree-abbreviation');
        this.deleteItemCall = async (id: string) => { return await httpService.deletePedigreeAbbreviation(id) };
    }

    public componentDidMount() {
        super.componentDidMount();
    }

    filterAbbreviations(input: any) {
        const result = this.props.dataState.list.filter((b: any) => b.abbrevation.toLowerCase().includes(input.toLowerCase()));
        this.props.getFilteredAbbreviations(result)
        if (result.length > 0) {
            this.props.selectItem(result[0]);
            history.replace(`/${this.baseUrl}/details/${result[0].id}`);
        }
    }

    async deleteAbbreviationItem(index: number, property: string) {

        let item: any = this.props.dataState.filtered[index];

        this.props.showDialog(`Are you sure you want to delete "${property}" ?`, DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {
                try {

                    await this.deleteItemCall(item.id);
                    this.props.getItems();
                    this.props.selectItem(null);

                } catch (err) {
                    alert(err.message);
                }
            }
        })
    }

    showAbbreviationDetails(index: number) {
        let item: any = this.props.dataState.filtered[index];
        {
            this.props.userState && Helpers.isUserEventViewer(this.props.userState.user) &&
                this.props.selectItem(item)
        }
        history.replace(`/${this.baseUrl}/details/${item.id}`);
    }


    render() {
        let timer: any;
        return (
            <div className="row gap-20 masonry pos-r">
                <div className="masonry-item col-md-5">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <h4 className="c-grey-900 mB-20" style={{ float: "left" }}>Pedigree Abbreviation List</h4>
                        <div style={{ clear: "both" }} />

                        {!Helpers.isUserDogViewer(this.props.userState.user) &&
                            <div style={{ float: "right", verticalAlign: "middle" }}>
                                <Link to='/pedigree-abbreviation/add'>
                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                </Link>
                            </div>
                        }

                        <div className="search">
                            <input style={{ width: "67%" }} className="form-control" type="text" id="exampleInputEmail1"
                                maxLength={50} placeholder="Enter Abbreviation" onChange={(e) => {
                                    clearTimeout(timer);
                                    timer = setTimeout(() => {
                                        this.filterAbbreviations(e.target.value)
                                    }, 1000);
                                    if (e.target.value === "") {
                                        this.props.getFilteredAbbreviations(this.props.dataState.list)
                                    }
                                }} />
                        </div>
                        <br />

                        <ContentStatus isLoading={this.props.dataState.isLoading} errorMessage={this.props.dataState.error} data={this.props.dataState.list}></ContentStatus>

                        {(!this.props.dataState.isLoading && !this.props.dataState.error && this.props.dataState.list && this.props.dataState.filtered && this.props.dataState.list.length > 0) &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" className="ta-c">Abbreviation</th>
                                        <th scope="col" className="ta-c">Country</th>
                                        <th scope="col" className="ta-r mw-actions">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="dd-container" ref={this.dragulaDecorator}>
                                    {this.props.dataState.filtered.map((pedigree: any, index: number) => (
                                        <tr className={`${this.getActiveClass(pedigree)} ${Helpers.getPublishedClass(pedigree)}`} key={pedigree.id}>
                                            <td>
                                                {index + 1}
                                            </td>
                                            <td style={{ cursor: "pointer" }} className="ta-c" onClick={() => { this.showAbbreviationDetails(index); }}>
                                                {pedigree.abbrevation}
                                            </td>
                                            <td style={{ cursor: "pointer" }} className="ta-c" onClick={() => { this.showAbbreviationDetails(index); }}>
                                                {pedigree.country}
                                            </td>
                                            <td className="ta-r">
                                                <Link to={`/pedigree-abbreviation/edit/${pedigree.id}`}>
                                                    <span style={{ fontSize: "16px" }} className="icon-holder"><i className="c-light-blue-500 ti-pencil"></i></span>
                                                </Link>
                                                <span style={{ fontSize: "16px" }}> | </span>
                                                <a href="#" onClick={(e) => { e.preventDefault(); this.deleteAbbreviationItem(index, pedigree.abbrevation) }}>
                                                    <span style={{ fontSize: "16px" }} className="icon-holder"><i className="c-light-blue-500 ti-trash"></i></span>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>}
                        {(this.props.dataState.filtered && this.props.dataState.filtered.length < 1) &&
                            (
                                <div style={{ color: "grey", display: "flex", justifyContent: "center" }} className="cur-p">No abbreviations</div>
                            )
                        }

                    </div>
                </div>
                <div className="masonry-item col-md-7">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <Route exact path='/pedigree-abbreviation/details/:itemId?' component={PedigreeDetails} />
                        <Route exact path='/pedigree-abbreviation/edit/:itemId?' component={PedigreeEdit} />
                        <Route exact path='/pedigree-abbreviation/add' component={PedigreeEdit} />
                    </div>
                </div>
            </div>)
    }
};

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.pedigreeList, userState: state.user };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) },
        selectItem: (data: PedigreeAbbreviation) => { dispatch(PedigreeAbbreviationStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(PedigreeAbbreviationStore.actionCreators.getItems()) },
        getFilteredAbbreviations: (data: any) => { dispatch(PedigreeAbbreviationStore.actionCreators.getFilteredAbbreviations(data)) },


        //    reorderItem: (id: string, pos?: number) => { dispatch(CompetitionListStore.actionCreators.reorderItem(id, pos)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PedigreeList);
