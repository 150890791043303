import { Action, Reducer } from 'redux';
import { CompetitionAttachment, CompetitionDate, Sponsor } from '../models/competition/Competition';
import { CompDateImage, CompetitionRing, SpecialComp } from '../models/competition/CompetitionDate';
import { AppThunkAction } from './';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export enum NotificationType {
    Success,
    Danger
}

export enum DialogType {
    Alert,
    Confirm
}

export type DialogData = {
    message: string,
    type: DialogType,
    callback: Function
}

export type NotificationData = {
    message: string,
    type: NotificationType
}

export interface AttachmentData {
    data: CompetitionAttachment,
    callback: Function
}

export interface SponsorData {
    data: Sponsor,
    callback: Function
}

export interface CompDateImageData {
    data: CompDateImage,
    callback: Function
}

export interface CompDateData {
    data: CompetitionDate,
    detailsOnly: boolean,
    callback: Function
}

export interface SpecialCompData {
    data: SpecialComp,
    detailsOnly: boolean,
    callback: Function
}

export interface RingsData {
    data: CompetitionRing,
    detailsOnly: boolean,
    callback: Function
}

export interface DialogState {
    count: number;
    imageEditData: any;
    linkEditData: any;
    notificationData?: NotificationData | null;
    dialogData?: DialogData | null;
    attachmentData?: AttachmentData | null;
    sponsorData?: SponsorData | null;
    compDateImageData?: CompDateImageData | null;
    compDateData?: CompDateData | null;
    specialCompData?: SpecialCompData | null;
    ringData?: RingsData | null;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface IncrementCountAction { type: 'INCREMENT_COUNT' }
export interface DecrementCountAction { type: 'DECREMENT_COUNT' }
export interface ShowNotificationAction { type: 'SHOW_NOTIFICATION', data?: NotificationData | null }
export interface ShowDialogAction { type: 'SHOW_DIALOG', data?: DialogData | null }
export interface ShowAttachmentDialogAction { type: 'SHOW_ATTACHMENT_DIALOG', data?: AttachmentData | null }
export interface ShowSponsorDialogAction { type: 'SHOW_SPONSOR_DIALOG', data?: SponsorData | null }
export interface ShowCompDateImageDialogAction { type: 'SHOW_COMP_DATE_IMAGE_DIALOG', data?: CompDateImageData | null }
export interface ShowCompDateDialogAction { type: 'SHOW_COMPDATE_DIALOG', data?: CompDateData | null }
export interface ShowSpecialCompDialogAction { type: 'SHOW_SPECIALCOMP_DIALOG', data?: SpecialCompData | null }
export interface ShowRingDialogAction { type: 'SHOW_RING_DIALOG', data?: RingsData | null }

export interface SetImageEditAction { type: 'SET_IMAGE_EDIT', data: any }
export interface SetLinkEditAction { type: 'SET_LINK_EDIT', data: any }
export interface SetFaqEditAction { type: 'SET_FAQ_EDIT', data: any }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = IncrementCountAction | DecrementCountAction |
    SetImageEditAction | SetLinkEditAction | ShowNotificationAction |
    ShowDialogAction | SetFaqEditAction | ShowAttachmentDialogAction |
    ShowSponsorDialogAction | ShowCompDateDialogAction | ShowSpecialCompDialogAction | ShowRingDialogAction | ShowCompDateImageDialogAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    increment: () => ({ type: 'INCREMENT_COUNT' } as IncrementCountAction),
    decrement: () => ({ type: 'DECREMENT_COUNT' } as DecrementCountAction),
    setImageEditData: (data: any): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_IMAGE_EDIT', data: data });
    },
    setLinkEditData: (data: any): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_LINK_EDIT', data: data });
    },
    showNotification: (data: NotificationData): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_NOTIFICATION', data: data });
        setTimeout(() => { dispatch({ type: 'SHOW_NOTIFICATION', data: null }) }, 2000);
    },
    showDialog: (message: string, type: DialogType = DialogType.Alert, callback: Function = () => { }): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_DIALOG', data: { message: message, type: type, callback: callback } });
    },
    hideDialog: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_DIALOG', data: null });
    },
    showAttachmentDialog: (data: CompetitionAttachment, callback: Function = () => { }): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_ATTACHMENT_DIALOG', data: {  data: data, callback: callback } });
    },
    hideAttachmentDialog: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_ATTACHMENT_DIALOG', data: null });
    },
    showSponsorDialog: (data: Sponsor, callback: Function = () => { }): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_SPONSOR_DIALOG', data: { data: data, callback: callback } });
    },
    hideSponsorDialog: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_SPONSOR_DIALOG', data: null });
    },
    showCompDateImageDialog: (data: CompDateImage, callback: Function = () => { }): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_COMP_DATE_IMAGE_DIALOG', data: { data: data, callback: callback } });
    },
    hideCompDateImageDialog: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_COMP_DATE_IMAGE_DIALOG', data: null });
    },
    showCompDateDialog: (data: CompetitionDate, detailsOnly: boolean, callback: Function = () => { }): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_COMPDATE_DIALOG', data: { data: data, detailsOnly: detailsOnly, callback: callback } });
    },
    hideCompDateDialog: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_COMPDATE_DIALOG', data: null });
    },
    showSpecialCompDialog: (data: SpecialComp, detailsOnly: boolean, callback: Function = () => { }): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_SPECIALCOMP_DIALOG', data: { data: data, detailsOnly: detailsOnly, callback: callback } });
    },
    hideSpecialCompDialog: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_SPECIALCOMP_DIALOG', data: null });
    },
    showRingDialog: (data: CompetitionRing, detailsOnly: boolean, callback: Function = () => { }): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_RING_DIALOG', data: { data: data, detailsOnly: detailsOnly, callback: callback } });
    },
    hideRingDialog: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_RING_DIALOG', data: null });
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<DialogState> = (state: DialogState | undefined, incomingAction: Action): DialogState => {
    if (state === undefined) {
        return {
            count: 0, imageEditData: null, linkEditData: null, notificationData: null
        };
    }

    const action = incomingAction as KnownAction;

    let temp = { ...state };

    switch (action.type) {
        case 'INCREMENT_COUNT': {
            temp.count = temp.count + 1;
            return temp;
        }
        case 'DECREMENT_COUNT': {
            temp.count = temp.count - 1;
            return temp;
        }
        case 'SHOW_NOTIFICATION': {
            temp.notificationData = action.data;
            return temp;
        }
        case 'SHOW_DIALOG': {
            temp.dialogData = action.data;
            return temp;
        }
        case 'SHOW_ATTACHMENT_DIALOG': {
            temp.attachmentData = action.data;
            return temp;
        }
        case 'SHOW_SPONSOR_DIALOG': {
            temp.sponsorData = action.data;
            return temp;
        }
        case 'SHOW_COMPDATE_DIALOG': {
            temp.compDateData = action.data;
            return temp;
        }
        case 'SHOW_SPECIALCOMP_DIALOG': {
            temp.specialCompData = action.data;
            return temp;
        }
        case 'SHOW_RING_DIALOG': {
            temp.ringData = action.data;
            return temp;
        }
        case 'SHOW_COMP_DATE_IMAGE_DIALOG': {
            temp.compDateImageData = action.data;
            return temp;
        }

        default:
            return state;
    }
}
