import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';
import { Notification } from '../../models/notification/Notification';
import { history, httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as NotificationStore from '../../store/NotificationStore';
import * as DialogStore from '../../store/DialogStore';
import { DetailsBase } from '../base/DetailsBase';
import { NotificationData } from '../../store/DialogStore';
import { Helpers } from '../../utility/Helpers';
import DraftEditor from '../../components/DraftEditor';
import DateTimePicker from '../../components/DateTimePicker';




class NotificationEdit extends DetailsBase<Notification> {

    codeError: string;
    propsForTrim: Array<string>;

    constructor(props: any) {
        super(props);

        this.codeError = '';
        this.propsForTrim = ['title', 'text']
        this.getItem = async (id: string) => { return await httpService.notificationDetails(id) }
        this.saveItem = async (data: Notification) => { return await httpService.addOrUpdateNotification(data) }
        this.redirectAfterSave = (id: string) => { history.replace(`/notification/details/${id}`) }


        this.emptyData = {
            title: '',
            text: '',
            publishDate: new Date()
        }


    }

    onSubmit = (ev: any) => {
        ev.preventDefault();

        let data: any = { ...this.state.data };

        this.trimProps(this.propsForTrim, data);

        this.saveChanges(data as Notification);
    }



    changeData = (ev: any) => {
        const target = ev.target;
        let name: any;
        let value: any;
        let val: any;
        if (target) {
            value = target && target.type === 'checkbox' ? target.checked : target.value;
            name = target.name;
            val = value;
        } else {
            name = "text"
            value = ev
            val = value;
        }
        
        let data: any = { ...this.state.data };
        data[name] = val;

        this.setState({
            data: data
        })
    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.data };
        data[res.name] = res.value;
        this.setState({
            data: data
        })
    }


    render() {
        return (
            <div>
                <h4 className="c-grey-900">{this.isNew(this.state) && !this.state.isLoading ? "Add" : "Edit"} notification</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error} data={this.state.data}></ContentStatus>
                <br />
                {(this.state && this.state.data) &&
                    <form onSubmit={this.onSubmit}>

                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="title">Title</label>
                                <input id="title" name="title" required className="form-control"
                                    value={this.state.data.title || ''} onChange={this.changeData} maxLength={2000} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="text">Text</label>
                                <DraftEditor onValueChange={(e: any) => this.changeData(e)}
                                    content={this.state.data.text} hasBlockTypes={true} />
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="publishDate">Publish Date</label>
                                <DateTimePicker name="publishDate" minDate='' isUtc={false} useTime={true}
                                    maxDate=''
                                    value={Helpers.getIsoDate(this.state.data.publishDate)}
                                    onChange={(e: any) => { this.dateChange(e) }} />
                            </div>
                        </div>


                        <br />
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.notificationList };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: Notification) => { dispatch(NotificationStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(NotificationStore.actionCreators.getItems()) },
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationEdit);
