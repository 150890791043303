import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';
import { history, httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as StripeAccountStore from '../../store/StripeAccountStore';
import * as DialogStore from '../../store/DialogStore';
import { DetailsBase } from '../base/DetailsBase';
import { NotificationData } from '../../store/DialogStore';
import { StripeAccount } from '../../models/stripe-account/StripeAccount';

class StripeAccountEdit extends DetailsBase<StripeAccount> {
    propsForTrim: Array<string>;

    constructor(props: any) {
        super(props);

        this.propsForTrim = ['name', 'stripeKey']
        this.getItem = async (id: string) => { return await httpService.getStripeAccount(id) }
        this.saveItem = async (data: StripeAccount) => { return await httpService.stripeAccountAddOrUpdate(data) }
        this.redirectAfterSave = (id: string) => { history.replace(`/stripeaccount/details/${id}`) }

        this.emptyData = {
            created: new Date(),
            name: '',
            stripeKey: ''
        }
    }

    onSubmit = (ev: any) => {
        ev.preventDefault();

        let data: any = { ...this.state.data };
        this.trimProps(this.propsForTrim, data);
        this.saveChanges(data as StripeAccount);
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let val = value;

        let data: any = { ...this.state.data };
        data[name] = val;

        this.whitespaceTrimProps(this.propsForTrim, data, name);

        this.setState({
            data: data
        })
    }

    render() {
        return (
            <div>
                <h4 className="c-grey-900">{this.isNew(this.state) && !this.state.isLoading ? "Add" : "Edit"} Stripe Account</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error} data={this.state.data}></ContentStatus>
                <br />
                {(this.state && this.state.data) &&
                    <form onSubmit={this.onSubmit}>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Name *</label>
                            <input id="name" name="name" required className="form-control"
                                    value={this.state.data.name} onChange={this.changeData} maxLength={200}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="stripeKey">Stripe key *</label>
                            <input id="stripeKey" name="stripeKey" required className="form-control"
                                    value={this.state.data.stripeKey} onChange={this.changeData} maxLength={200}/>
                        </div>
                        <br />
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.stripeAccountList };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: StripeAccount) => { dispatch(StripeAccountStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(StripeAccountStore.actionCreators.getItems()) },
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StripeAccountEdit)
