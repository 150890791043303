import React, { Component, Fragment } from "react";
import { ApplicationState } from "../store";
import * as DogListStore from "../store/DogListStore";
import * as DialogStore from "../store/DialogStore";
import { connect } from "react-redux";

type defaultProps = {
    suggestions: [],
    data: any,
    action?: any

};

class Autocomplete extends Component<any> {
    timer: any;

    constructor(props: any) {
        super(props);


    }

    state = {
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: this.props.suggestions,
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // What the user has entered
        userInput: ""
    };

    handleChange = (e: any) => {

        clearTimeout(this.timer)
        const userInput = e.currentTarget.value;
        this.timer = setTimeout(() => {
            this.props.getFilteredUsers(userInput)
        }, 1000)


        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: this.props.dogState.users,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
        
    };

    handleClick = (e: any) => {

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: this.props.suggestions,
            showSuggestions: !this.state.userInput && !this.state.showSuggestions,
            userInput: e.currentTarget.innerText
        });
        this.props.action(e.currentTarget.innerText, 0, "user")


    };


    render() {

        let suggestionsListComponent;

        if (this.state.showSuggestions) {
            if (this.state.filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {this.props.dogState.users.map((suggestion: any, index: any) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === this.state.activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={className} key={suggestion.id} onClick={(e) => this.handleClick(e)}>
                                    {suggestion.userName}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions.</em>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <input
                    id="user" name="user" required className="form-control"
                    type="text"
                    onClick={(e: any) => this.handleClick(e)}
                    onChange={(e: any) =>
                        this.handleChange(e)
                    }

                    autoComplete="off"
                    defaultValue={this.props.data.user.userName ? this.props.data.user.userName : this.props.data.user.username}
                    value={this.state.userInput || this.props.data.user.userName}
                    disabled={this.props.data.id && true}
                />
                {suggestionsListComponent}
            </Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dogState: state.dogList };
}

const mapDispatchToProps = (dispatch: any) => {
    return {

        getFilteredUsers: (name?: string) => {
            dispatch(DogListStore.actionCreators.getFilteredUsers(name))
        },
        showNotification: (data: DialogStore.NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Autocomplete);