import * as React from 'react';
import { httpService } from '../..';
import { DialogType, NotificationType } from '../../store/DialogStore';
import { Helpers } from '../../utility/Helpers'

type DetailsState<T> = {
    isLoading: boolean,
    error: string,
    data?: T | null,
    title?: string,
    selectedTab: number,
    open: boolean,
    showPassword: boolean,
    retypePassword: string,
    disableButton: boolean


}

export class DetailsBase<T> extends React.Component<any, DetailsState<T>> {

    itemId: string = '';
    emptyData: T | null = null;
  
    getItem: any = () => { alert('GetItem Not set !!!') };
    redirectAfterSave: any = null;
    handleId: boolean = true;
    deleteItemCall: any = () => { alert('DeleteItem Not set !!!') };

    protected saveItem: any = () => { alert('SaveItem Not set !!!') };

    constructor(props: any, handleId: boolean = true) {
        super(props);

        this.handleId = handleId;

        this.state = {
            isLoading: false,
            error: '',
            data: null,
            title: '',
            selectedTab: 0,
            open: false,
            showPassword: false,
            retypePassword: '',
            disableButton: false
        }
    }

    public componentDidMount() {
        let { itemId } = this.props.match.params;

        if (itemId || !this.handleId) {
            this.props.selectItem({ id: itemId });
            this.getData(itemId);
        } else {
            this.setState({
                data: this.emptyData
            });
        }

        this.itemId = itemId;
    }

    public componentDidUpdate(prevState: any) {
        let { itemId } = this.props.match.params;

        if (this.itemId !== itemId) {
            this.itemId = itemId;
            this.getData(itemId);
        }
    }

    async getData(id?: string) {
        this.setState({
            isLoading: true,
            error: '',
            data: null,
            selectedTab: 0,
            open: false,
            showPassword: false,
            retypePassword: ''


        });

        try {
            
            let data = (id) ? await this.getItem(id) : await this.getItem();
            
        
            
            this.setState({
                isLoading: false,
                error: '',
                data: data,
                selectedTab: 0,
                open: false,
                showPassword: false,
                retypePassword: '',
              

            });

            this.props.selectItem(data);          
        } catch (err) {
            this.setState({
                isLoading: false,
                error: err.message
            })
        }
    }

    async saveChanges(data: T, type?: any) {
        if (data) {
            try {
                this.setState({ disableButton: true })
                let item = await this.saveItem(data);

                if (typeof item != "undefined") {
                    this.setState({ data: item });
                    this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });

                    if (this.redirectAfterSave) {
                        
                        if (type) {
                            if (type === "invoice-info") {
                                this.props.getItems(item.type)
                                this.redirectAfterSave(item.id);
                            } else {
                                this.props.getItems("", item.type)
                                this.redirectAfterSave(item.id);
                            }

                        } else {
                            this.props.getItems()
                            this.redirectAfterSave(item.id);
                        }
                    }
                    if (this.props.breedState) {
                        let ind = this.props.breedState.list.findIndex((i: any) => i.id === item.id)
                        if (ind === -1) {
                            this.props.getItems();
                        }
                    }
                    
                } 
                this.setState({ disableButton: false })

                return item;

            } catch (err) {
               
                alert(err.message);
                this.setState({ disableButton: false })
            }
        }

        return null;
    }

    public isNew(state: any) {
        if (state.data) {
            return !state.data.id;
        }
        return true
    }

    trimProps = Helpers.trimProps;

    whitespaceTrimProps = Helpers.whitespaceTrimProps;

    async deleteItem(property: string = 'title') {
        try {
            let item: any = this.state.data;

            this.props.showDialog(`Are you sure you want to delete "${property ? item[property] : item}?"`, DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    this.props.increment();
                    await this.deleteItemCall(item.id);

                    if (this.props.dataState.selectedItem && this.props.dataState.selectedItem.id === item.id) {
                        this.props.selectItem(null);
                    }

                    this.props.decrement();
                    this.props.getItems();
                }
            })
        } catch (err) {
            this.props.decrement();
            alert(err.message);
        }
    }

    
}