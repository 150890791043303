import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as DialogStore from '../../store/DialogStore';
import * as InvoiceIssuerStore from '../../store/InvoiceIssuerStore';
import { ListBase } from '../base/ListBase';
import ContentStatus from '../../components/ContentStatus';
import { Link } from 'react-router-dom';
import { InvoiceIssuer } from '../../models/invoice/InvoiceIssuer';
import { getIssuerType, IssuerType } from '../../models/invoice/InvoiceEnums';
import InvoiceIssuerDetails from './InvoiceIssuerDetails';
import InvoiceIssuerEdit from './InvoiceIssuerEdit';
import { Helpers } from '../../utility/Helpers';
//import InvoiceInfoDetails from './InvoiceInfoDetails';
//import InvoiceInfoEdit from './InvoiceInfoEdit';



class InvoiceIssuerList extends ListBase {

    constructor(props: any, context: any) {
        super(props, context, 'invoice-issuer');
  
    }

    public componentDidMount() {
        super.componentDidMount();
        this.setState({ selectedTab: 0})

    }

    render() {
        return (
            <div className="row gap-20 masonry pos-r">
                <div className="masonry-item col-md-5">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <h4 className="c-grey-900 mB-20" style={{ float: "left" }}>Invoice Issuer List</h4>
                        <div style={{ float: "right", verticalAlign: "middle" }}>
                            <Link to='/invoice-issuer/add'>
                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                            </Link>
                        </div>

                        <div style={{ clear: "both" }} />

                        <ContentStatus isLoading={this.props.dataState.isLoading} errorMessage={this.props.dataState.error} data={this.props.dataState.list}></ContentStatus>

                        <div style={{ width: "max-content", display: "flex", marginTop: "20px" }}>


                            <div style={{
                                width: "fit-content",
                                verticalAlign: "middle",
                                float: "right",
                                paddingRight: "15px",
                            }}>
                                <button type="button"
                                    className={this.props.dataState.issuerType === IssuerType.NORMAL ? "btn cur-p btn-primary" : "btn cur-p btn-outline-primary"}
                                    onClick={() => {

                                        this.props.getItems("", IssuerType.NORMAL)
                                        this.props.selectItem(null)
                                       
                                    }}>Normal

                                </button>
                            </div>


                            <div style={{
                                width: "fit-content",
                                verticalAlign: "middle",
                                float: "right",
                                paddingRight: "15px"
                            }}>


                                <button type="button"
                                    className={this.props.dataState.issuerType === IssuerType.FEE ? "btn cur-p btn-primary" : "btn cur-p btn-outline-primary"}
                                    onClick={() => {
                                        this.props.getItems("", IssuerType.FEE)
                                        this.props.selectItem(null)

                                    }}>
                                    Fee
                                </button>
                            </div>
                        </div>

                        {(!this.props.dataState.isLoading && !this.props.dataState.error && this.props.dataState.list && this.props.dataState.list.length > 0) &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Issuer</th>
                                        <th scope="col" className="ta-r mw-actions">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="dd-container" ref={this.dragulaDecorator}>
                                    {this.props.dataState.list.map((issuer: any, index: number) => (
                                        <tr className={`${this.getActiveClass(issuer)} ${Helpers.getPublishedClass(issuer)}`} key={issuer.id}>
                                            <td style={{ fontSize: "16px !important" }} className="cur-p" onClick={() => { this.showDetails(index); }}>
                                                {issuer.issuer}
                                            </td>
                                            
                                            <td className="ta-r">
                                                <Link to={`/invoice-issuer/edit/${issuer.id}`}>
                                                    <span style={{ fontSize: "16px" }} className="icon-holder"><i
                                                        className="c-light-blue-500 ti-pencil"></i></span>
                                                </Link>
                                                
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>}

                    </div>
                </div>
                <div className="masonry-item col-md-7">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <Route exact path='/invoice-issuer/details/:itemId?' component={InvoiceIssuerDetails} />
                        <Route exact path='/invoice-issuer/edit/:itemId?' component={InvoiceIssuerEdit} />
                        <Route exact path='/invoice-issuer/add' component={InvoiceIssuerEdit} />
                    </div>
                </div>
            </div>)
    }
};

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.invoiceIssuerList, userState: state.user };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) },
        selectItem: (data: InvoiceIssuer) => { dispatch(InvoiceIssuerStore.actionCreators.selectItem(data)) },
        getItems: (query: any, type: any) => { dispatch(InvoiceIssuerStore.actionCreators.getItems(query, type)) },


        //    reorderItem: (id: string, pos?: number) => { dispatch(CompetitionListStore.actionCreators.reorderItem(id, pos)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceIssuerList);
