import { Action, Reducer } from 'redux';
import { httpService } from '../index';
import { AppThunkAction } from './';
import { Breed, BreedCategory } from '../models/dog/Breed';

export interface BreedCategoryListState {
    selectedItem: BreedCategory | null,
    list: Array<BreedCategory>,
    breeds: Array<Breed> | null,
    isLoading: boolean,
    error: string,

}

interface SelectBreedCategoryAction {
    type: 'SELECT_BREED_CATEGORY';
    item: BreedCategory;
}

interface RequestBreedCategoryAction {
    type: 'REQUEST_BREED_CATEGORY';
}

interface ReceiveBreedsCategoryAction {
    type: 'RECEIVE_BREEDS_CATEGORY';
    items: Array<BreedCategory>;
    error: '';
}

interface ReceiveBreedsByFciAction {
    type: 'RECEIVE_BREEDS_BY_FCI';
    items: Array<Breed>;
    error: '';
}

type KnownAction =
    SelectBreedCategoryAction
    | RequestBreedCategoryAction
    | ReceiveBreedsCategoryAction
    | ReceiveBreedsByFciAction


export const actionCreators = {
    selectItem: (item: BreedCategory): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SELECT_BREED_CATEGORY', item: item });
    },

    getItems: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.breedCatList) {
            try {
                dispatch({ type: 'REQUEST_BREED_CATEGORY' });
                let data = await httpService.breedCategory();

                dispatch({ type: 'RECEIVE_BREEDS_CATEGORY', items: data, error: '' });

            } catch (err) {
                dispatch({ type: 'RECEIVE_BREEDS_CATEGORY', items: [], error: '' });
            }
        }
    },

    getBreedsByFci: (category: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.breedCatList) {
            try {
                let data = await httpService.getBreedsByFci(category);
                dispatch({ type: 'RECEIVE_BREEDS_BY_FCI', items: data, error: '' });
            } catch (err) {
                dispatch({ type: 'RECEIVE_BREEDS_BY_FCI', items: [], error: '' });
            }
        }
    }
}

const unloadedState: BreedCategoryListState = { selectedItem: null, isLoading: false, list: [], error: '', breeds: [] };

export const reducer: Reducer<BreedCategoryListState> = (state: BreedCategoryListState | undefined, incomingAction: Action): BreedCategoryListState => {
    if (state === undefined) {
        return unloadedState;
    }

    let stateCopy = { ...state }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_BREED_CATEGORY':

            stateCopy.selectedItem = action.item
            stateCopy.isLoading = false
            stateCopy.error = ''

            return stateCopy

        case 'REQUEST_BREED_CATEGORY':

            stateCopy.list = []
            stateCopy.isLoading = true
            stateCopy.error = ''

            return stateCopy

        case 'RECEIVE_BREEDS_CATEGORY':

            let si = state.selectedItem;

            if (action.items && action.items.length > 0 && !state.selectedItem) {
                si = action.items[0];
            }

            stateCopy.selectedItem = si
            stateCopy.list = action.items
            stateCopy.isLoading = false

            return stateCopy

        case 'RECEIVE_BREEDS_BY_FCI':

            stateCopy.breeds = action.items
           

            return stateCopy
    }
    return state;
};
