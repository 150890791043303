export enum CountriesEnum {
    SI = 'SI',
    HR = 'HR',
    AT = 'AT',
    HU = 'HU',
    RO = 'RO',
    DE = 'DE'
}

export enum UserType {
    ALL = 0,
    ADMIN = 1,
    JUDGE = 2,
    RECORDER = 3,
    RING_LEADER = 4,
    DOG_VIEWER = 5,
    EVENT_VIEWER = 6
}

export function getUserRoleTitle(type: UserType) {
    switch (type) {
        case UserType.ALL:
            return 'All';
        case UserType.ADMIN:
            return 'Administrator';
        case UserType.JUDGE:
            return 'Judge';
        case UserType.RECORDER:
            return 'Recorder';
        case UserType.RING_LEADER:
            return 'Ring Leader';
        case UserType.DOG_VIEWER:
            return 'Dog Viewer';
        case UserType.EVENT_VIEWER:
            return 'Event Viewer';
        default:
            return 'Undefined';
    }
}

export function getRoleType(type: string) {
    switch (type) {
        case "Administrator":
            return 1;
        case 'Judge':
            return 2;
        case 'Recorder':
            return 3;
        case 'Ring Leader':
            return 4;
        case 'Dog Viewer':
            return 5;
        case 'Event Viewer':
            return 6;
       
    }
}
