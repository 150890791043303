import * as React from 'react';
type HeaderProps = {
    data: any;
    title?: string;
    baseUrl: string;
    onDelete: Function;
    hideEdit?: boolean;
}

export default class DetailsHeader extends React.Component<HeaderProps> {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (       
            <React.Fragment>
                <h4 className="c-grey-900" style={{ float: "left" }}>{ this.props.title }</h4>
                {
                    (this.props.data) && <div style={{ float: "right", verticalAlign: "middle" }}>
                        {/*{!this.props.hideEdit &&*/}
                        {/*    <Link to={`/${this.props.baseUrl}/edit/${this.props.data.id}`}>*/}
                        {/*        <span style={{ fontSize: "16px" }} className="icon-holder"><i className="c-light-blue-500 ti-pencil"></i></span>*/}
                        {/*    </Link>*/}
                        {/*}*/}
                        {/*{!this.props.hideEdit &&*/}
                        {/*    <span style={{ fontSize: "16px" }}> | </span>*/}
                        {/*}*/}
                        {/*<a href="#" onClick={(e) => { e.preventDefault(); this.props.onDelete() }}>*/}
                        {/*    <span style={{ fontSize: "16px" }} className="icon-holder"><i className="c-light-blue-500 ti-trash"></i></span>*/}
                        {/*</a>*/}
                    </div>
                }
                <div style={{ clear: "both" }} />
            </React.Fragment>
        )
    }
}
