import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import * as DialogStore from '../../store/DialogStore';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ApplicationState } from "../../store";
import { httpService } from "../../index";
import { CompetitionCoupleGroup, CoupleGroupType, getCoupleGroup } from "../../models/registrations/Registrations";
import { DialogType, NotificationData, NotificationType } from "../../store/DialogStore";

import { Helpers } from "../../utility/Helpers";
import RegistrationLoader from "../../components/RegistrationLoader";
import { DogGender, getGender } from "../../models/dog/DogEnums";
import CoupleGroupExtraRegistration from "./CoupleGroupExtraRegistration";
import CompetitionBISScore from "../../components/competition/CompetitionBISScore";
import DogSearch from "../../components/DogSearch";

type Props = {
    data?: any
    dogs?: any
    isOpen?: any
}

class CoupleGroupFormComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);
    }

    emptyState: any = {
        dogs: [],
        competitionDate: null,
        type: '',
        paymentAmount: '',
        competitionRegistrationId: '',
        created: new Date()
    }

    state: any = {
        activeButtons: [],
        selectedDog: null,
        openExtraDogs: false,
        isOpen: true,
        dogsNumber: 2,
        modal: true,
        loading: "",
        isDataLoading: true,
        maleSelected: false,
        femaleSelected: false,
        processing: false,
        editMode: false,
        add: false,
        previewMode: false,
        editItem: "",
        newData: this.props.dataState.registration,
        currentIndex: 0,
        registrationId: "",
        dropDown: false,
        dogType: '',
        itemConfirmed: false,
        coupleData: null,
        extraDog: null,
        disableButton: false,
        competitionBISScore: { isPresent: false, bisScoreType: null, bisScore: null },
        customDiscounts: []
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    closeModal() {
        this.props.getCompetitionCoupleGroup(null)

        this.setState({
            add: false,
            editMode: false,
            previewMode: false,
            activeButtons: [],
            coupleData: null
        })
    }

    openEdit(id: any, index: number, dogType: string) {
        this.setState({
            editItem: id,
            previewMode: !this.state.previewMode,
            currentIndex: index,

        })
    }

    openAdd() {
        this.setState({
            add: true,
            editItem: '',
            previewMode: !this.state.previewMode,
            currentIndex: null,
            newData: null,
            dogType: '',
            coupleData: this.emptyState,
            customDiscounts: []
        })

        if (!this.props.dataState.profileDogs) {
            this.props.getProfileDogs(this.props.dataState.registration.profile.id)
        }
    }

    openPreview = async (id: any, index: number, dogType?: string) => {
        this.setState({
            editItem: id,
            editMode: true,
            previewMode: true,
            currentIndex: index,
            isDataLoading: true
        }, () => {
            setTimeout(() => {
                this.getNewestData(id, index)
            }, 200)
        })
    }

    getUrl = (type: string, id: string) => {
        return "generate/" + type + "/" + id
    }

    getNewestData = async (id: any, index: any) => {
        let data = await httpService.getCompetitionCoupleGroup(id)
        let customDiscounts = await httpService.getCustomDiscountCoupleGroupReg(this.props.dataState.registration.competitionRegistrationCoupleGroups[index]["competitionDate"].id)
        this.setState({
            coupleData: data,
            customDiscounts: customDiscounts
        });
        if (this.state.coupleData) {
            this.setState({ isDataLoading: false })
            let result = this.state.coupleData.dogs.map((i: any) => i.id);
            this.setState({ activeButtons: result })
        }
        this.props.getCompetitionDate(this.props.dataState.registration.competitionRegistrationCoupleGroups[index]["competitionDate"].id);

    }

    getActiveButtons(id: any) {
        if (this.state.activeButtons.includes(id)) {
            var filteredArray = this.state.activeButtons.filter((e: any) => e !== id)
            this.setState({ activeButtons: filteredArray })
        } else {
            this.setState({
                activeButtons: [...this.state.activeButtons, id]
            })
        }
    }

    addOrRemoveDog(item: any, id: any, gender?: any) {

        if (this.state.coupleData.type !== '') {
            let data = { ...this.state.coupleData }
            if (item != "empty") {
                if (gender != null) {
                    let exists = data.dogs.find((i: any) => i.gender === gender);
                    if (exists) {
                        var filteredArray = data.dogs.filter((e: any) => e.gender !== gender)
                        data.dogs = filteredArray;

                    }
                    data.dogs = [...data.dogs, item]
                    this.setState({
                        coupleData: data
                    })
                    this.getActiveButtons(id)
                    return

                }

                if (data.dogs.find((i: any) => i.id === item.id)) {
                    var filteredArray = data.dogs.filter((e: any) => e.id !== item.id)
                    data.dogs = filteredArray
                    let gender = item.gender
                    if (gender === DogGender.FEMALE) {
                        this.setState({
                            femaleSelected: false

                        })
                    } else if (gender === DogGender.MALE) {
                        this.setState({
                            maleSelected: false

                        })
                    }
                    this.setState({
                        coupleData: data

                    })
                    this.getActiveButtons(id)

                    return
                }
                if (this.state.coupleData.type === CoupleGroupType.COUPLE && this.state.coupleData.dogs.length < 2) {

                    data.dogs = [...data.dogs, item]
                    this.setState({
                        coupleData: data
                    })
                    this.getActiveButtons(id)


                } else if (this.state.coupleData.type === CoupleGroupType.GROUP) {
                    let data = { ...this.state.coupleData }
                    if (data.dogs.includes(item)) {
                        var filteredArray = data.dogs.filter((e: any) => e !== item.id)
                        data.dogs = filteredArray
                        this.setState({
                            coupleData: data
                        })
                        this.getActiveButtons(id)
                    }
                    data.dogs = [...data.dogs, item]
                    this.setState({
                        coupleData: data
                    })
                    this.getActiveButtons(id)
                }

            } else {
                var filteredArray = data.dogs.filter((e: any) => e.gender !== gender)
                var idToRemove = data.dogs.filter((d: any) => !filteredArray.includes(d))[0].id;
                
                data.dogs = filteredArray;
                this.setState({
                    coupleData: data
                })
                this.getActiveButtons(idToRemove)
            }

        }


    }

    filterDogs(data: any, gender: any) {

        return data.filter((obj: any) =>
            obj.gender === gender
        )
    }

    toggleDropdown = () => {
        this.setState({ dropDown: !this.state.dropDown })
    }

    saveChanges(data: any, type?: any) {
        if (data) {
            if (!data.hasOwnProperty("competitionRegistrationId")) {
                data.competitionRegistrationId = this.props.dataState.registration.id
            }
            if (data.hasOwnProperty("competitionDate") && data.competitionDate == undefined) {
                data.competitionDate = null
            }
            const totalDogs = data.extraDogs ? data.extraDogs.length + data.dogs.length : data.dogs.length;
            let canSave: boolean = true;
            let alertMessage = "Error";
            if (totalDogs >= 3 && data.type == CoupleGroupType.GROUP) {
                canSave = true;
            } if (totalDogs < 3 && data.type == CoupleGroupType.GROUP) {
                canSave = false;
                alertMessage = "Not enough dogs chosen."
            } if (totalDogs == 2 && data.type == CoupleGroupType.COUPLE) {
                canSave = true;
                alertMessage = ""
            } if (totalDogs > 2 && data.type == CoupleGroupType.COUPLE) {
                canSave = false;
                alertMessage = "Too many dogs chosen."
            }

            if (canSave) {
                this.props.showDialog(`Are you sure you want to save this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                    if (confirm) {
                        try {
                            this.setState({ disableButton: true })
                            await httpService.updateCoupleGroupRegistrations(data);
                            this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });
                            this.props.getRegistration(data.competitionRegistrationId)
                            this.setState({ coupleData: null, modal: false, disableButton: false })
                            this.closeModal()

                        } catch (err) {

                            alert(err.message)
                            this.setState({ disableButton: false })

                        }
                    }
                })
            } else {
                alert(alertMessage)
            }

        }
    }

    changeData = async (ev: any) => {

        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let coupleData = { ...this.state.coupleData }

        if (!coupleData.hasOwnProperty("invoiceNumber")) {
            coupleData.invoiceNumber = "";
            this.setState({
                coupleData: coupleData
            });

        }
        if (!coupleData.hasOwnProperty("competitionRegistrationId")) {
            let newKey = coupleData.competitionRegistrationId
            coupleData.competitionRegistrationId = this.props.dataState.registration.id
            this.setState({
                coupleData: coupleData
            });
        } else {
            coupleData.competitionRegistrationId = this.props.dataState.registration.id
            this.setState({
                coupleData: coupleData
            });
        }

        if (target.type === "radio") {
            coupleData[target.name] = parseInt(value);
            this.setState({
                coupleData: coupleData
            });
        }

        else if (target.name === "customDiscount") {
            let val = this.state.customDiscounts.find((i: any) => i.id === value)
            coupleData[name] = val
        }

        else if (target.name === "competitionDate") {

            let val = (this.props.dataState.selectedItem.competitionDates.find((i: any) => i.id === value));
            if (val) {
                let cd = await httpService.getCustomDiscountCoupleGroupReg(val.id)
                this.setState({
                    customDiscounts: cd
                })
            }
            coupleData[name] = val

        } else {
            if (name !== "type") {
                coupleData[name] = value;
            }
        }
        this.setState({
            coupleData: coupleData
        });

    }

    async deleteRegistration(item: any) {

        this.props.showDialog(`Are you sure you want to delete this registration?`, DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {
                try {

                    await httpService.deleteCoupleGroupRegistration(item.id);
                    this.props.showNotification({ message: 'Successfully deleted.', type: NotificationType.Success });
                    this.props.getRegistration(this.props.dataState.registration.id)
                    this.setState({ coupleData: null, modal: false })
                    this.closeModal()

                } catch (err) {

                    alert(err.message);
                }
            }
        })
    }

    addOrEditExtraDog(dog?: any, index?: any) {
        this.setState({ openExtraDogs: !this.state.openExtraDogs, extraDog: dog ? dog : null, selectedDog: index && index })
    }

    saveExtraDog(item: any, index: any) {
        let data: any = { ...this.state.coupleData };
        if (!item.id && index === undefined) {
            if (!data.extraDogs) {
                data.extraDogs = [];
            }
            data.extraDogs = [...data.extraDogs, item];
        } else if (!item.id && index) {
            data.extraDogs[this.state.selectedDog] = item;
        } else {
            let res = data.extraDogs.find((i: any) => i.id === item.id)
            let ind = data.extraDogs.indexOf(res)
            data.extraDogs[ind] = item;
        }
        this.setState({ coupleData: data, openExtraDogs: !this.state.openExtraDogs, extraDog: null, selectedDog: null })

    }

    async removeExtraDog(item: any) {
        let data: any = { ...this.state.coupleData };

        let ind = data.extraDogs.indexOf(item);
        data.extraDogs.splice(ind, 1);

        this.setState({ coupleData: data, extraDog: null })

    }

    changeBISScore(ev: any, type: any) {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let data = { ...this.state.coupleData }

        data[type][name] = value

        this.setState({
            coupleData: data
        })

    }

    addBISScore() {
        let data = { ...this.state.coupleData }
        let score = { ...this.state.competitionBISScore }
        data.competitionBISScore = score;

        this.setState({ coupleData: data })
    }

    changeDogData(dog: any, id: any) {
        let data: any = { ...this.state.coupleData };
        this.setState({ data: data })
    }

    findCommonItem(userDogs: any, dogs: any) {
        for (let i = 0; i < userDogs.length; i++) {
            const id = userDogs[i].id;
            for (let j = 0; j < dogs.length; j++) {
                if (dogs[j].id === id) {
                    return dogs[j];
                }
            }
        }
        return null;
    }


    render() {


        return (
            <React.Fragment>

                <div>
                    <div>
                        <div style={{ float: "right", verticalAlign: "middle" }}>
                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                <Button style={{ border: "none", outline: "none" }} onClick={(e) => {

                                    this.openAdd()
                                }}

                                    color="none">
                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                </Button>
                            }
                        </div>
                        <h5>Couples/groups</h5>
                        <table className="table table-bordered">


                            {this.props.dataState.registration.competitionRegistrationCoupleGroups && this.props.dataState.registration.competitionRegistrationCoupleGroups.length > 0 ?
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th>Name & Breed</th>
                                        <th>Type</th>
                                        <th>Created</th>
                                        <th>Actions</th>
                                    </tr>

                                </thead>
                                : <div style={{ color: "grey" }}>No sign-ups yet.</div>}

                            <tbody className="dd-container">
                                {this.props.dataState.registration.competitionRegistrationCoupleGroups && this.props.dataState.registration.competitionRegistrationCoupleGroups.map((item: any, index: any) =>


                                    <tr key={item.id}>
                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{item.competitionDate.description}</td>
                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">
                                            {item.dogs.map((c: any) => <span>{c.name} - {c.breed.name}<br /></span>)}

                                            <>
                                                {item.extraDogs.map((c: any) => <span>{c.name} - {c.breed.name}<br /></span>)}
                                            </>

                                        </td>

                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{getCoupleGroup(item.type)}</td>
                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{Helpers.getLocalDateTimeString(item.created)}</td>
                                        <td className="ta-c">
                                            <span onClick={() => this.openPreview(item.id, index, "couple/group")} style={{ cursor: "pointer" }} className="icon-holder"><i className="c-light-blue-500 fa fa-search" aria-hidden="true"></i></span>

                                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                <>
                                                    <span style={{ fontSize: "16px" }}> | </span>
                                                    <span style={{ cursor: "pointer" }} className="icon-holder" onClick={(e) =>

                                                        this.deleteRegistration(item)
                                                    }><i className="c-light-blue-500 ti-trash"></i></span>
                                                </>
                                            }

                                        </td>
                                    </tr>

                                )}
                            </tbody>
                        </table>
                    </div>

                    {this.props.dataState.registration.competitionRegistrationCoupleGroups.map((dog: any, index: number) =>
                        <div>
                            {
                                !this.state.add && this.state.editItem === dog.id && this.state.editMode && (


                                    <Modal style={{ maxWidth: '950px', width: '100%' }} size="lg" isOpen={this.state.editMode}>
                                        <ModalHeader toggle={() => this.closeModal()}>Couple/group </ModalHeader>
                                        <ModalBody>

                                            {this.state.isDataLoading && !this.state.coupleData ? <RegistrationLoader /> :
                                                <div>
                                                    <div key={index} className="row">
                                                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                            <td><span onClick={() => this.openEdit(dog.id, index, "extraDog")} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                                                className="c-light-blue-500 ti-pencil"></i></span>
                                                            </td>
                                                        }

                                                        <br />
                                                    </div>
                                                    <div >
                                                        <div className="row">
                                                            <div style={{ marginBottom: "10px" }}>Registration Type *</div>

                                                            <div className="col-sm-10">
                                                                <div className="form-check">

                                                                    <label className="form-label form-check-label">
                                                                        <input id="couple" name="type" className="form-check-input"
                                                                            type="radio"
                                                                            disabled={this.state.previewMode}

                                                                            defaultValue={CoupleGroupType.COUPLE}
                                                                            checked={this.state.coupleData && this.state.coupleData.type === CoupleGroupType.COUPLE}
                                                                            onChange={(e) => this.changeData(e)}
                                                                        />
                                                                        Couple
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <label id="group" className="form-label form-check-label">
                                                                        <input name="type" className="form-check-input"
                                                                            type="radio"
                                                                            disabled={this.state.previewMode && true}

                                                                            defaultValue={CoupleGroupType.GROUP}
                                                                            checked={this.state.coupleData && this.state.coupleData.type === CoupleGroupType.GROUP}
                                                                            onChange={(e) => this.changeData(e)}
                                                                        />
                                                                        Group
                                                                    </label>

                                                                </div>
                                                            </div>
                                                            {this.state.coupleData && this.state.coupleData.type === CoupleGroupType.COUPLE &&


                                                                <div>
                                                                    {this.filterDogs(this.props.dataState.profileDogs, DogGender.MALE).length < 10 ?
                                                                        <><><div>Male</div><div style={{ display: "flex", gap: "7px", flexDirection: "row", flexWrap: "wrap" }}>{this.filterDogs(this.props.dataState.profileDogs, DogGender.MALE).map((dog: any, i: number) => (
                                                                            <div style={{ cursor: "pointer", opacity: this.state.previewMode ? "0.65" : "1", pointerEvents: this.state.previewMode && "none" }} className={this.state.activeButtons.includes(dog.id) ? "couple-group-btn-active" : "couple-group-btn-unactive"} id={`${i}`}
                                                                                onClick={(e) => { this.addOrRemoveDog(dog, dog.id); }}>{dog.name} - {dog.breed.name}</div>))}</div></><br /></>
                                                                        :
                                                                        <div>
                                                                            <div>
                                                                                <div className="mb-3">
                                                                                    <label className="form-label" htmlFor="male">Male</label>
                                                                                    <DogSearch action={(dog: any, id?: any, gender?: any) => {
                                                                                        this.addOrRemoveDog(dog, id, gender)
                                                                                    }} data={this.state.coupleData && this.state.coupleData.dogs && this.findCommonItem(this.filterDogs(this.props.dataState.profileDogs, DogGender.MALE), this.state.coupleData.dogs)}
                                                                                        suggestions={this.filterDogs(this.props.dataState.profileDogs, DogGender.MALE)} disabled={!this.state.add && this.state.previewMode} /></div>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {this.filterDogs(this.props.dataState.profileDogs, DogGender.FEMALE).length < 10 ?
                                                                        <><><div>Female</div><div style={{ display: "flex", gap: "7px", flexDirection: "row", flexWrap: "wrap" }}>{this.filterDogs(this.props.dataState.profileDogs, DogGender.FEMALE).map((dog: any, i: number) => (
                                                                            <div style={{ cursor: "pointer", opacity: this.state.previewMode ? "0.65" : "1", pointerEvents: this.state.previewMode && "none" }} className={this.state.activeButtons.includes(dog.id) ? "couple-group-btn-active" : "couple-group-btn-unactive"} id={`${i}`}
                                                                                onClick={(e) => { this.addOrRemoveDog(dog, dog.id); }}>{dog.name} - {dog.breed.name}</div>))}</div></><br /></>
                                                                        :
                                                                        <div>
                                                                            <div>
                                                                                <div className="mb-3">
                                                                                    <label className="form-label" htmlFor="female">Female</label>
                                                                                    <DogSearch action={(dog: any, id?: any, gender?: any) => {
                                                                                        this.addOrRemoveDog(dog, id, gender)
                                                                                    }} data={this.state.coupleData && this.state.coupleData.dogs && this.findCommonItem(this.filterDogs(this.props.dataState.profileDogs, DogGender.FEMALE), this.state.coupleData.dogs)}
                                                                                        suggestions={this.filterDogs(this.props.dataState.profileDogs, DogGender.FEMALE)} disabled={!this.state.add && this.state.previewMode} /></div>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            }

                                                            {this.state.coupleData && this.state.coupleData.type === CoupleGroupType.GROUP &&
                                                                <div>
                                                                    <div style={{ display: "flex", gap: "7px", flexDirection: "row", flexWrap: "wrap", marginBottom: "20px !important" }}>
                                                                        {this.props.dataState.profileDogs.map((dog: any, i: number) => (
                                                                            <div style={{ cursor: "pointer", opacity: this.state.previewMode ? "0.65" : "1", pointerEvents: this.state.previewMode && "none" }} className={this.state.activeButtons.includes(dog.id) ? "couple-group-btn-active" : "couple-group-btn-unactive"} id={`${i}`}
                                                                                onClick={(e) => { this.addOrRemoveDog(dog, dog.id) }}>{dog.name} - {dog.breed.name}
                                                                            </div>))}

                                                                    </div>
                                                                    <br />

                                                                </div>
                                                            }

                                                            <div>
                                                                <div style={{ float: "right", verticalAlign: "middle" }}>
                                                                    {!Helpers.isUserEventViewer(this.props.userState.user) && !this.state.previewMode &&
                                                                        <Button style={{ border: "none", outline: "none" }} onClick={(e) => {

                                                                            this.addOrEditExtraDog()
                                                                        }}

                                                                            color="none">
                                                                            <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                        </Button>
                                                                    }
                                                                </div>
                                                                <h5>Additional Dogs</h5>
                                                                <div>

                                                                    <table className="table">

                                                                        {this.state.coupleData && this.state.coupleData.extraDogs && this.state.coupleData.extraDogs.length > 0 ?
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>Name </th>
                                                                                    <th>Breed</th>
                                                                                    {this.state.coupleData.type === CoupleGroupType.COUPLE &&
                                                                                        <th>Gender</th>
                                                                                    }

                                                                                    <th>Catalog Number</th>
                                                                                    <th style={{ textAlign: "right" }}>Actions</th>
                                                                                </tr>

                                                                            </thead>
                                                                            : <div style={{ color: "grey" }}>No additional Dogs.</div>}

                                                                        <tbody className="dd-container">
                                                                            {this.state.coupleData && this.state.coupleData.extraDogs && this.state.coupleData.extraDogs.map((extraDog: any, i: number) =>
                                                                                <tr key={extraDog.id}>
                                                                                    <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{i + 1}</td>
                                                                                    <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{extraDog.name && extraDog.name}</td>
                                                                                    <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{extraDog.breed && extraDog.breed.name}</td>
                                                                                    {this.state.coupleData.type === CoupleGroupType.COUPLE &&
                                                                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{getGender(extraDog.gender)}</td>
                                                                                    }
                                                                                    <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{extraDog.catalogNumber && extraDog.catalogNumber}</td>
                                                                                    <td className="ta-r">
                                                                                        <span onClick={() => { this.addOrEditExtraDog(extraDog, i) }} style={{ cursor: "pointer" }} className="icon-holder"><i className={this.state.previewMode ? "c-light-blue-500 fa fa-search" : "c-light-blue-500 ti-pencil"} aria-hidden="true"></i></span>

                                                                                        {!Helpers.isUserEventViewer(this.props.userState.user) && !this.state.previewMode &&
                                                                                            <>
                                                                                                <span style={{ fontSize: "16px" }}> | </span>
                                                                                                <span style={{ cursor: "pointer" }} className="icon-holder" onClick={(e) =>

                                                                                                    this.removeExtraDog(extraDog)
                                                                                                }><i className="c-light-blue-500 ti-trash"></i></span>
                                                                                            </>
                                                                                        }

                                                                                    </td>


                                                                                </tr>

                                                                            )}
                                                                        </tbody>

                                                                    </table>

                                                                    {this.state.openExtraDogs &&

                                                                        <CoupleGroupExtraRegistration data={this.state.extraDog} addMode={false} previewMode={this.state.previewMode} canEdit={!this.state.previewMode} selectedIndex={this.state.selectedDog} isOpen={() => { this.setState({ openExtraDogs: false }) }}
                                                                            save={(i: any, index: any) => {
                                                                                this.saveExtraDog(i, index)
                                                                            }}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>


                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="competitionDate">Show </label>
                                                                <select id="competitionDate" name="competitionDate"
                                                                    className="form-control"
                                                                    disabled={this.state.previewMode && true}

                                                                    defaultValue={this.state.coupleData && this.state.coupleData.competitionDate && this.state.coupleData.competitionDate.id}
                                                                    onChange={(e) => this.changeData(e)}>
                                                                    {this.props.dataState.selectedItem.competitionDates.map((item: any, index: number) => (
                                                                        <option key={index} value={item.id}>{item.description}</option>
                                                                    ))}
                                                                </select>

                                                            </div>
                                                            <hr />
                                                            <h5>Payment Information</h5>


                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="paymentAmount">Payment
                                                                    Amount</label>
                                                                <input type="number" id="paymentAmount" name="paymentAmount" min="0.00"
                                                                    step="0.1"
                                                                    className="form-control"
                                                                    disabled={this.state.previewMode}
                                                                    defaultValue={this.state.coupleData && this.state.coupleData.paymentAmount}
                                                                    onChange={(e) => this.changeData(e)}>

                                                                </input>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="invoiceNumber">Invoice Number</label>
                                                                <input id="invoiceNumber" name="invoiceNumber"
                                                                    className="form-control"
                                                                    disabled={this.state.previewMode}
                                                                    defaultValue={this.state.coupleData && this.state.coupleData.invoiceNumber}
                                                                    onChange={(e) => this.changeData(e)}>

                                                                </input>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="customDiscount">Custom Discount</label>
                                                                <select id="customDiscount" name="customDiscount"
                                                                    className="form-control"
                                                                    disabled={this.state.previewMode}
                                                                    defaultValue={this.state.coupleData && this.state.coupleData.customDiscount && this.state.coupleData.customDiscount.id}
                                                                    onChange={(e) => this.changeData(e)}>
                                                                    <option value="">-- select discount --</option>
                                                                    {(this.state.customDiscounts && this.state.customDiscounts.length > 0) && this.state.customDiscounts.map((item: any, index: number) => (
                                                                        <option key={index} value={item.id}>{item.title}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h5 style={{ marginRight: '10px' }}>BIS Score</h5>
                                                            {this.state.coupleData && !this.state.previewMode && !this.state.coupleData.competitionBISScore &&
                                                                !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                        this.addBISScore()
                                                                    }}
                                                                        color="none">
                                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                    </Button>
                                                                )
                                                            }
                                                        </div>



                                                        {this.state.coupleData && this.state.coupleData.competitionBISScore ?

                                                            <CompetitionBISScore data={this.state.coupleData.competitionBISScore} dataType="coupleGroup" action={(e: any) => this.changeBISScore(e, "competitionBISScore")} previewMode={this.state.previewMode} type="BIS Score" />
                                                            : <div>No BIS Score.</div>

                                                        }
                                                    </div>

                                                </div>
                                            }

                                        </ModalBody>
                                        <ModalFooter>
                                            {!this.state.previewMode &&
                                                <Button disabled={this.state.disableButton} color="primary" onClick={() => {
                                                    this.saveChanges(this.state.coupleData as CompetitionCoupleGroup, "couple/group")
                                                }}>Save</Button>
                                            }
                                            <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                                        </ModalFooter>

                                    </Modal>

                                )
                            }
                        </div>
                    )}

                    {this.state.add && (
                        <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.state.add}>
                            <ModalHeader toggle={() => this.closeModal()}>Add Couple/group Registration</ModalHeader>
                            <ModalBody>

                                <div className="mT-30">
                                    <div className="row">
                                        <p style={{ marginBottom: "10px" }}>Registration Type(<span style={{ color: "#24a0ed", fontSize: "small" }}>Choose registration type before adding dogs</span>)</p>

                                        <div className="col-sm-10">
                                            <div className="form-check">
                                                <label className="form-label form-check-label">
                                                    <input id="couple" name="type" className="form-check-input"
                                                        type="radio"

                                                        value={CoupleGroupType.COUPLE}
                                                        checked={this.state.coupleData && this.state.coupleData.type === CoupleGroupType.COUPLE}
                                                        onChange={(e) => this.changeData(e)}
                                                    />
                                                    Couple
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label id="group" className="form-label form-check-label">
                                                    <input name="type" className="form-check-input"
                                                        type="radio"
                                                        value={CoupleGroupType.GROUP}
                                                        checked={this.state.coupleData && (this.state.coupleData.type === CoupleGroupType.GROUP)}
                                                        onChange={(e) => this.changeData(e)}
                                                    />
                                                    Group
                                                </label>
                                            </div>
                                            <br />
                                        </div>

                                        {this.state.coupleData && this.state.coupleData.type === CoupleGroupType.COUPLE &&

                                            <div>
                                                {this.filterDogs(this.props.dataState.profileDogs, DogGender.MALE).length < 10 ?
                                                    <><div>Male</div><div style={{ display: "flex", gap: "7px", flexDirection: "row", flexWrap: "wrap" }}>{this.filterDogs(this.props.dataState.profileDogs, DogGender.MALE).map((dog: any, i: number) => (
                                                        <div style={{ cursor: "pointer" }} className={this.state.activeButtons.includes(dog.id) ? "couple-group-btn-active" : "couple-group-btn-unactive"} id={`${i}`}
                                                            onClick={(e) => { this.addOrRemoveDog(dog, dog.id); }}>{dog.name} - {dog.breed.name}</div>))}</div></>
                                                    :
                                                    <div>
                                                        <div>Male</div>
                                                        <DogSearch action={(dog: any, id?: any, gender?: any) => {
                                                            this.addOrRemoveDog(dog, id, gender)
                                                        }} data={this.findCommonItem(this.filterDogs(this.props.dataState.profileDogs, DogGender.MALE), this.state.coupleData.dogs)}
                                                            suggestions={this.filterDogs(this.props.dataState.profileDogs, DogGender.MALE)} /></div>
                                                }
                                                <br />
                                                {this.filterDogs(this.props.dataState.profileDogs, DogGender.FEMALE).length < 10 ?
                                                    <><div>Female</div><div style={{ display: "flex", gap: "7px", flexDirection: "row", flexWrap: "wrap" }}>{this.filterDogs(this.props.dataState.profileDogs, DogGender.FEMALE).map((dog: any, i: number) => (
                                                        <div style={{ cursor: "pointer" }} className={this.state.activeButtons.includes(dog.id) ? "couple-group-btn-active" : "couple-group-btn-unactive"} id={`${i}`}
                                                            onClick={(e) => { this.addOrRemoveDog(dog, dog.id); }}>{dog.name} - {dog.breed.name}</div>))}</div></>

                                                    :
                                                    <div>
                                                        <div>Female</div>
                                                        <DogSearch action={(dog: any, id?: any, gender?: any) => {
                                                            this.addOrRemoveDog(dog, id, gender)
                                                        }} data={this.findCommonItem(this.filterDogs(this.props.dataState.profileDogs, DogGender.FEMALE), this.state.coupleData.dogs)}
                                                            suggestions={this.filterDogs(this.props.dataState.profileDogs, DogGender.FEMALE)} /></div>

                                                }
                                                <br />
                                            </div>
                                        }

                                        {this.state.coupleData && this.state.coupleData.type === CoupleGroupType.GROUP &&
                                            <div>
                                                <div style={{ display: "flex", gap: "7px", flexDirection: "row", flexWrap: "wrap", marginBottom: "20px" }}>{this.props.dataState.profileDogs.map((dog: any, i: number) => (
                                                    <div style={{ cursor: "pointer" }} className={this.state.activeButtons.includes(dog.id) ? "couple-group-btn-active" : "couple-group-btn-unactive"} id={`${i}`}
                                                        onClick={(e) => { this.addOrRemoveDog(dog, dog.id) }}>{dog.name} - {dog.breed.name}</div>))}
                                                    <br />
                                                    <br />
                                                </div>




                                            </div>

                                        }
                                        <div><div style={{ float: "right", verticalAlign: "middle" }}>
                                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                    e.preventDefault();
                                                    this.addOrEditExtraDog()
                                                }}

                                                    color="none">
                                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                </Button>
                                            }
                                        </div>

                                            <h5>Additional Dogs</h5>
                                            <div>
                                                <table className="table">

                                                    {this.state.coupleData && this.state.coupleData.extraDogs ?
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name </th>
                                                                <th>Breed</th>
                                                                <th>Gender</th>
                                                                <th>Catalog Number</th>
                                                                <th style={{ textAlign: "right" }}>Actions</th>
                                                            </tr>

                                                        </thead>
                                                        : <div style={{ color: "grey" }}>No additional Dogs.</div>}

                                                    <tbody className="dd-container">

                                                        {this.state.coupleData && this.state.coupleData.extraDogs && this.state.coupleData.extraDogs.map((extraDog: any, i: number) =>

                                                            <tr key={extraDog.id}>
                                                                <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{i + 1}</td>
                                                                <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{extraDog.name && extraDog.name}</td>
                                                                <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{extraDog.breed && extraDog.breed.name}</td>
                                                                <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{getGender(extraDog.gender)}</td>
                                                                <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{extraDog.catalogNumber && extraDog.catalogNumber}</td>
                                                                <td className="ta-r">
                                                                    <span onClick={() => this.addOrEditExtraDog(extraDog, i)} style={{ cursor: "pointer" }} className="icon-holder"><i className="c-light-blue-500 fa fa-search" aria-hidden="true"></i></span>

                                                                    {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                                        <>
                                                                            <span style={{ fontSize: "16px" }}> | </span>
                                                                            <span style={{ cursor: "pointer" }} className="icon-holder" onClick={(e) =>

                                                                                this.removeExtraDog(extraDog)
                                                                            }><i className="c-light-blue-500 ti-trash"></i></span>
                                                                        </>
                                                                    }

                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>

                                                </table>
                                                {this.state.openExtraDogs &&

                                                    <CoupleGroupExtraRegistration data={this.state.extraDog} addMode={this.state.add} selectedIndex={this.state.selectedDog} isOpen={() => { this.setState({ openExtraDogs: false }) }}
                                                        save={(i: any, index: any) => {
                                                            this.saveExtraDog(i, index)
                                                        }}
                                                    />
                                                }

                                            </div></div>


                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="competitionDate">Show *</label>
                                            <select id="competitionDate" name="competitionDate"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.coupleData && this.state.coupleData.competitionDate && this.state.coupleData.competitionDate.description}
                                                onChange={(e) => this.changeData(e)}>
                                                <option value="">-- select show --</option>
                                                {this.props.dataState.selectedItem.competitionDates.map((item: any, index: number) => (
                                                    <option key={index} value={item.id}>{item.description}</option>
                                                ))}
                                            </select>

                                        </div>
                                        <hr />
                                        <h5>Payment Information</h5>

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="paymentAmount">Payment
                                                Amount *</label>
                                            <input type="number" id="paymentAmount" name="paymentAmount" min="0.00"
                                                step="0.1"
                                                required
                                                className="form-control"
                                                value={this.state.coupleData && this.state.coupleData.paymentAmount}

                                                onChange={(e) => this.changeData(e)}>

                                            </input>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="invoiceNumber">Invoice Number</label>
                                            <input id="invoiceNumber" name="invoiceNumber"
                                                className="form-control"
                                                value={this.state.coupleData && this.state.coupleData.invoiceNumber}
                                                onChange={(e) => this.changeData(e)}>

                                            </input>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="customDiscount">Custom Discount</label>
                                            <select id="customDiscount" name="customDiscount"
                                                className="form-control"
                                                defaultValue={this.state.coupleData && this.state.coupleData.customDiscount && this.state.coupleData.customDiscount.id}
                                                onChange={(e) => this.changeData(e)}>
                                                <option value="">-- select discount --</option>
                                                {(this.state.customDiscounts && this.state.customDiscounts.length > 0) && this.state.customDiscounts.map((item: any, index: number) => (
                                                    <option key={index} value={item.id}>{item.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <hr />
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ marginRight: '10px' }}>BIS Score</h5>
                                            {this.state.coupleData && !this.state.coupleData.competitionBISScore &&
                                                !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                        this.addBISScore()
                                                    }}
                                                        color="none">
                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                    </Button>
                                                )
                                            }
                                        </div>



                                        {this.state.coupleData && this.state.coupleData.competitionBISScore ?

                                            <CompetitionBISScore data={this.state.coupleData.competitionBISScore} dataType="coupleGroup" action={(e: any) => this.changeBISScore(e, "competitionBISScore")} previewMode={false} type="BIS Score" />
                                            : <div>No BIS Score.</div>

                                        }

                                    </div>
                                </div>


                            </ModalBody>
                            <ModalFooter>
                                {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                    <Button disabled={this.state.disableButton} color="primary" onClick={() => {
                                        this.saveChanges(this.state.coupleData as CompetitionCoupleGroup, "couple/group")
                                    }}>Save</Button>
                                }
                                <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                            </ModalFooter>
                        </Modal>
                    )
                    }

                </div>

            </React.Fragment>
        );
    }
}


const mapStateToProps = (state: ApplicationState) => {
    return {
        dataState: state.competitionList,
        userState: state.user
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getItems: () => {
            dispatch(CompetitionListStore.actionCreators.getItems())
        },
        getRegistrationItems: (page?: any, competition?: string, email?: string, count?: number, paymentStatus?: any, catalogNumber?: any) => {
            dispatch(CompetitionListStore.actionCreators.getRegistrationItems(page, competition, email, count, paymentStatus, catalogNumber))
        },
        getRegistration: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getRegistration(id))
        },
        getProfileDogs: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getProfileDogs(id))
        },
        getCompetitionDate: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
        },
        setOpenReg: (openReg?: any) => {
            dispatch(CompetitionListStore.actionCreators.setOpenReg(openReg))
        },
        getCompetitionDog: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDog(id))
        },
        getCompetitionCoupleGroup: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionCoupleGroup(id))
        },
        getCompetitionSpecialShow: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionSpecialShow(id))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CoupleGroupFormComponent);
