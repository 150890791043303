import Moment from 'moment';
import {RegisteredUser} from "../models/registered-user/RegisteredUser";


export class Helpers {

	public static getLocalTimeString(date?: Date | null) {
		if (date) {
			return Moment(date).format('HH:mm');
		}
		return '';
	}

	public static getLocalDateString(date?: Date | null) {
		if (date) {
			return Moment(date).format('DD.MM.YYYY');
		}
		return '';
	}

	public static getLocaleDateString(date?: Date | null) {
		if (date) {
			const d = Moment(date).toDate();
			return d.toLocaleDateString();
		}

		return '';
	}

	public static getLocalDateTimeString(date?: Date | null) {
		if (date) {
			const d = Moment(date).toDate();
			return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
		}

		return '';
	}

	public static getLocalDate(val?: Date | null) {
		if (val) {
			return Moment(val).format('YYYY-MM-DD');
		}

		return '';
	}

	public static getIsoDate(val: Date) {
		if (val) {
			return Moment(val).toISOString();
		}

		return '';
	}

	public static getCurrentDatePlus(add: number) {
		return Moment(Date.now()).add(add, 'd').toDate();
	}

	public static mergeDateTime(date: any, time: string): string {
		var cDate = new Date(date);
		return Moment(cDate.getFullYear() + '-' + this.leadingZeros(cDate.getMonth() + 1) + '-' + this.leadingZeros(cDate.getDate()) + ' ' + time).toISOString();
	}

	public static getTimeFromDate(date: any) {
		var cDate = new Date(date);
		return this.leadingZeros(cDate.getHours()) + ':' + this.leadingZeros(cDate.getMinutes());
	}

	public static getStartDate(date: Date) {
		return Moment([date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0]).toISOString();
	}

	public static getEndDate(date: Date, add: number = 0) {
		return Moment([date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59]).add(add, 'd').toISOString();
	}

	public static getLocalISODate(date: string) {
		return Moment(date).subtract('minutes', new Date(date).getTimezoneOffset()).toISOString().toUpperCase().replace('Z', '');
	}

	public static getPublishedClass(item: any) {
		if (!item.visible || !Moment().isBetween(item.pubStartDate, item.pubEndDate, 'minute', '[]'))
			return "inactive";
		return "";
	}

	private static leadingZeros(n: number): string {
		return (n < 10) ? "0" + n : n.toString();
	}

    public static isUserInRole(country: string, module: string, user: RegisteredUser | null, item?: string) {
        if (user && user.roles && user.roles.length > 0) {
            for (let i = 0; i < user.roles.length; i++) {
                let role = user.roles[i];
                // If he is in admin, he has access to everything;
				if (role.name === 'Administrator') {
					return true;
				} else if (role.name === 'Dog Viewer' && item === "dog") {
					return true;
				} else if (role.name === 'Event Viewer' && (item === "event" || item === "dog")) {
					return true;
				}

			}
		}
	
	}
	public static isUserAdmin(user: RegisteredUser | null) {
		if (user && user.roles && user.roles.length > 0) {
			for (let i = 0; i < user.roles.length; i++) {
				let role = user.roles[i];
				if (role.name === 'Administrator') {
					return true;
				}
			}
		}
		return false;
	}

	public static isUserDogViewer(user: RegisteredUser | null) {
		if (user && user.roles && user.roles.length > 0) {
			for (let i = 0; i < user.roles.length; i++) {
				let role = user.roles[i];
				if (role.name === 'Dog Viewer') {
					return true;
				}
			}
		}
		return false;
	}

	public static isUserEventViewer(user: RegisteredUser | null) {
		if (user && user.roles && user.roles.length > 0) {
			for (let i = 0; i < user.roles.length; i++) {
				let role = user.roles[i];
				if (role.name === 'Event Viewer') {
					return true;
				}
			}
		}
		return false;
	}


	public static trimProps(prNames: Array<string>, data: any) {
		prNames.forEach((name) => {
			if (data[name] !== "" && data[name]) {
				data[name] = data[name].trim();
			}
		})
	}

	public static whitespaceTrimProps(prNames: Array<string>, data: any, current: string) {
		if (prNames.indexOf(current) != -1) {
			let val = data[current];
			if (val.trim() === '') {
				data[current] = '';
			}
		}
	}

	public static getKeyByValue(value: number, list: any) {
		return Object.keys(list).find(key => list[key as keyof typeof list] === value);
	}

	public static booleanToString(value: boolean) {
		return value ? 'Yes' : 'No';
	}

	public static isImagePath(path: string = '') {
		if (path != '') {
			if (path.endsWith('.jpeg') || path.endsWith('.jpg') || path.endsWith('.png') || path.endsWith('.gif') || path.startsWith('data:image/')) {
				return true;
            }
		}

		return false;
	}

	public static isPDF(name: string = '') {
		if (name != '') {
			if (name.endsWith('.pdf')) {
				return true;
			}
		}

		return false;
	}

	public static getAttachmentName(path: string = '') {
		if (path != '') {
			let testIndex = path.lastIndexOf('/');
			if (testIndex !== -1) {
				let res = path.substring(testIndex + 1);
				return res;
			} else {
				return path;
			}
		}

		return 'Attachment';
	}

	public static isNullOrUndefined(par: any): boolean {
        if (par === null || par === undefined) {
            return true
        }

        return false
    }
}
