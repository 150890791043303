import * as React from 'react';
import { history } from '../../index';
import Dragula from 'react-dragula';
import { DialogType } from '../../store/DialogStore';
import { Helpers } from '../../utility/Helpers';


export class ListBase extends React.Component<any, any> {

    drake: any;
    fromIndex: number;
    toIndex: number;
    baseUrl: string;
    deleteItemCall: any = () => { alert('DeleteItem Not set !!!') };
    countries: any;

    constructor(props: any, context: any, baseUrl: string) {
        super(props, context);
        this.baseUrl = baseUrl;

        this.fromIndex = -1;
        this.toIndex = -1;
    }

    public componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        if (this.drake) {
            this.drake.destroy();
        }

        this.props.selectItem(null);
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.dataState && this.props.dataState.list && this.props.dataState.list.length > 0 && (!prevProps.dataState.selectedItem || !this.props.dataState.selectedItem)) {
            history.replace(`/${this.baseUrl}/details/${this.props.dataState.list[0].id}`);
        }
    }

    showDetails(index: number) {
        let item: any = this.props.dataState.list[index];
        {this.props.userState && Helpers.isUserEventViewer(this.props.userState.user) &&
            this.props.selectItem(item)
        }
        history.replace(`/${this.baseUrl}/details/${item.id}`);
    }

    async getData() {
        await this.props.getItems();
    }


    async deleteItem(index: number, property: string) {
        
        let item: any = this.props.dataState.list[index];

        this.props.showDialog(`Are you sure you want to delete "${property}" ?`, DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {
                try {

                    await this.deleteItemCall(item.id);
                    this.getData();
                    if (this.props.dataState.selectedItem && this.props.dataState.selectedItem.id === item.id) {
                        this.props.selectItem(null);
                    }
                    
                } catch (err) {
                    alert(err.message);
                }
            }
        })
    }

    
    getActiveClass(item: any): string {
        return (this.props.dataState.selectedItem && item.id === this.props.dataState.selectedItem.id) ? 'active-row' : '';
    }

    getDomIndex(el: any) {
        if (el.parentNode && el.parentNode.children) {
            return Array.from(el.parentNode.children).indexOf(el);
        }

        return -1;
    }

    dragulaDecorator = (componentBackingInstance: any) => {

        if (componentBackingInstance) {

            let options = {
                moves: function (el: any, container: any, handle: any) {
                    return handle.classList.contains('handle');
                }
            };
            this.drake = Dragula([componentBackingInstance], options);

            this.drake.on("drag", (el: any, source: any) => {
                this.fromIndex = this.getDomIndex(el);
            });

            this.drake.on("drop", async (el: any, target: any, source: any, sibling: any) => {
                this.toIndex = this.getDomIndex(el);

                if (this.props.dataState.list) {
                    let state: any = { ...this.props.dataState };
                    let item = state.list[this.fromIndex];

                    let nextIndex = (this.fromIndex > this.toIndex) ? this.toIndex : this.toIndex + 1;
                    if (state.list.length - 1 >= nextIndex) {
                        this.props.reorderItem(item.id, state.list[nextIndex].order);
                    } else {
                        this.props.reorderItem(item.id);
                    }
                }
            })
        }
    }
}