import React from 'react';
import { Component } from "react";
import { Helpers } from "../../utility/Helpers";

class TVPagination extends Component<any, any>{
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <div>
                <div style={{ paddingLeft: `${this.props.marginLeft}vw`, width: '100%', height:'1.67vh', marginTop:'3.06vh' }} >
                    <div className="center_align_elements">
                        {this.props.pages.map((item: any, index: any) => (
                            <span className={`${index === this.props.currentIndex ? 'active_page_dot' : 'inactive_page_dot'}`} style={{ marginLeft: '0.73vw' }}></span>
                        ))}
                    </div>
                </div>
                <div style={{ width: '100%' }}>
                    <h1 className="tv_white helvetica_regular_40 text_align_right padding_right80">Updated / Posodobljeno: {Helpers.getLocalTimeString(this.props.fetchTime)}</h1>
                </div>
            </div>
        )
    }
}

export default (TVPagination as any);