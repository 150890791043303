import {Action, Reducer} from 'redux';
import {httpService} from '../index';
import {AppThunkAction} from './';
import {CompetitionClass} from "../models/registrations/Registrations";

export interface CompetitionClassListState {
    selectedItem: CompetitionClass | null,
    list: Array<CompetitionClass>,
    isLoading: boolean,
    error: string,
}

interface SelectCompetitionClassAction {
    type: 'SELECT_COMPETITION_CLASS';
    item: CompetitionClass;

}

interface RequestCompetitionClassAction {
    type: 'REQUEST_COMPETITION_CLASS';
}

interface ReceiveCompetitionClassAction {
    type: 'RECEIVE_COMPETITION_CLASS';
    items: Array<CompetitionClass>;
    error: '';
}

type KnownAction =
    SelectCompetitionClassAction
    | RequestCompetitionClassAction
    | ReceiveCompetitionClassAction


export const actionCreators = {
    selectItem: (item: CompetitionClass): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'SELECT_COMPETITION_CLASS', item: item});
    },

    getItems: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.competitionClassList) {
            try {
                dispatch({type: 'REQUEST_COMPETITION_CLASS'});
                let data = await httpService.getCompetitionClasses();

                dispatch({type: 'RECEIVE_COMPETITION_CLASS', items: data, error: ''});

            } catch (err) {
                dispatch({type: 'RECEIVE_COMPETITION_CLASS', items: [], error: ''});
            }
        }
    }
}

const unloadedState: CompetitionClassListState = {selectedItem: null, isLoading: false, list: [], error: ''};

export const reducer: Reducer<CompetitionClassListState> = (state: CompetitionClassListState | undefined, incomingAction: Action): CompetitionClassListState => {
    if (state === undefined) {
        return unloadedState;
    }

    let stateCopy = { ...state }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_COMPETITION_CLASS':

            stateCopy.selectedItem = action.item
            stateCopy.isLoading = false
            stateCopy.error = ''

            return stateCopy

        case 'REQUEST_COMPETITION_CLASS':

            stateCopy.list = []
            stateCopy.isLoading = true
            stateCopy.error = ''

            return stateCopy

        case 'RECEIVE_COMPETITION_CLASS':

            let si = state.selectedItem;

            if (action.items && action.items.length > 0 && !state.selectedItem) {
                si = action.items[0];
            }

            stateCopy.selectedItem = si
            stateCopy.list = action.items
            stateCopy.isLoading = false

            return stateCopy
    }
    return state;
}
