import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';
import { Competition } from '../../models/competition/Competition';
import { history, httpService} from '../../index';
import {ApplicationState} from '../../store';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import * as DialogStore from '../../store/DialogStore';
import {DetailsBase} from '../base/DetailsBase';
import {Helpers} from '../../utility/Helpers';
import {DialogType, NotificationData} from '../../store/DialogStore';
import {Country} from '../../models/country/Country';
import {CompetitionType, getType} from '../../models/competition/CompetitionEnums';
import ImagePicker, { PictureWarningLevel } from '../../components/ImagePicker';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from "classnames";
import { InvoiceIssuer } from '../../models/invoice/InvoiceIssuer';
import { InvoiceInfo } from '../../models/invoice/InvoiceInfo';
import CountrySearch from '../../components/CountrySearch';
import DraftEditor from '../../components/DraftEditor';
import DateTimePicker from '../../components/DateTimePicker';
import { StripeAccount } from '../../models/stripe-account/StripeAccount';

class CompetitionEdit extends DetailsBase<Competition> {

    codeError: string;
    propsForTrim: Array<string>;
    logoFile?: File | null;
    deletedLogo: string;
    richTextMaxLength: number;

    constructor(props: any) {
        super(props);

        this.codeError = '';
        this.logoFile = null;
        this.deletedLogo = '';
        this.richTextMaxLength = 32768;
        this.propsForTrim = ['title', 'contact', 'subtitle', 'address', 'city', 'logo', 'richText'];
        this.getItem = async (id: string) => {
            return await httpService.getCompetition(id)
        }

        this.saveItem = async (data: Competition) => {
            return await httpService.competitionAddOrUpdate(data)
        }

        let currentDate = new Date(Date.now());
        let startDate = Helpers.getStartDate(currentDate);

        this.emptyData = {

            title: '',
            subtitle: '',
            richText: '',
            address: '',
            contact: '',
            city: '',
            logo: '',
            type: CompetitionType.REGIONAL,
            country: { id: '', name: '', created: new Date() },
            publishDate: new Date(startDate),
            registrationFrom: new Date(currentDate),
            registrationTo: new Date(currentDate),
            created: new Date(currentDate),
            competitionDates: [],
            competitionSponsors: [],
            competitionAttachments: [],
            registrationCount: 0,
            feeDateOfService: '',
            feeEM: '',
            feeInvoiceDate: '',
            feeInvoiceLocation: '',
            feeVATClause: '',
            fee: 0,
            //useCustomPriceCalculation: false,
            //useSemiCustomPriceCalculation: false,
            invoiceInformation: '',
            invoiceIssuer: '',
            invoicesAndExtraSent: new Date(),
            stripeReference: '',
            successfullEntrySent: new Date(),
            startNumbersAndScheduleSent: new Date()
        }

       
    }

    componentDidMount() {
        super.componentDidMount();
        this.props.getCountryItems();
    }

    componentDidUpdate(prevState: any) {
        let { itemId } = this.props.match.params;

        if (this.itemId !== itemId) {
            this.deletedLogo = '';
            this.logoFile = null;
        }

        super.componentDidUpdate(prevState);
    }

    onSubmit = async (ev: any) => {
        ev.preventDefault();

        let data: any = { ...this.state.data };
        this.trimProps(this.propsForTrim, data);

        if (this.state.data && this.state.data.richText.length > this.richTextMaxLength) {
            alert(`Rich text maximum length (${this.richTextMaxLength}) exceeded.`)
            return
        }

        //delete image
        if (this.state.data && this.state.data.id != undefined && this.deletedLogo !== '') {
            this.props.increment();
            try {
                await httpService.competitionLogoDelete(this.state.data.id);
                this.state.data.logo = '';
                this.props.decrement();
                
            } catch (err) {
                this.props.decrement();
                alert(err.message);
                return;
            }
        }

        let saved = await this.saveChanges(data as Competition);
        if (saved) {
            this.props.getItems()
            this.props.selectItem(null)
        }

        if (saved && this.logoFile) {
            this.props.increment();
            try {
                await httpService.competitionLogoUpload(this.logoFile, saved.id);
                this.props.decrement();
            } catch (err) {
                this.props.decrement();
                alert(err.message);
                return;
            }
        }

        if (!this.state.isLoading && !this.state.error && this.state.data && this.state.data.id) {
            history.replace(`/event/details/${this.state.data.id}`);
        }
    }

    changeData = (ev: any) => {

        const target = ev.target;
        let name: any;
        let value: any;
        let val: any;
        if (target) {
            value = target && target.type === 'checkbox' ? target.checked : target.value;
            name = target.name;
            val = value;
        } else {
            name = "richText"
            value = ev
        }

        let data: any = { ...this.state.data };

        if (name in data) {
            data[name] = value;
        }

        if (name === "country") {
            val = this.props.competitionState.countries.find((i: Country) => i.id === val);
            data[name] = val
        }

        if (target && target.name === "stripeAccount") {
            data[name] = this.props.competitionState.stripeAccounts.find((i: any) => i.id === value)
        }

        if (name === "type") {
            val = parseInt(val)
        }
        this.whitespaceTrimProps(this.propsForTrim, data, name);

        this.setState({
            data: data
        })
            
    }

    invoiceChange = (ev: any) => {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
       

        let data: any = { ...this.state.data };

        if (name === "invoiceIssuer") {
            let val = this.props.competitionState.invoiceIssuers.find((i: any) => i.id === value)
            data[name] = val
        }
        else if (name === "invoiceInformation") {
            let val = this.props.competitionState.invoiceInfo.find((i: any) => i.id === value)
            data[name] = val
        } else {
            data[name] = value
        }

        this.setState({ data: data})

    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.data };
        data[res.name] = res.value;
        this.setState({
            data: data
        })
    }

    changeCountry(item: any) {
        let data: any = { ...this.state.data };
        data.country = item;
        this.setState({ data: data })
    }

    addShow = () => {
        let data: any = { ...this.state.data };
        // @ts-ignore
        if (!data.competitionDates) {
            data.competitionDates = [];
        }
        // @ts-ignore
        data.competitionDates = [...data.competitionDates, this.emptyData.competitionDates[0]]
        this.setState({
            data: data
        })
    }

    removeShow = (index: number) => {
        let data: any = { ...this.state.data };
        data.competitionDates.splice(index, 1)
        if (data.competitionDates.length < 1) {
            delete data.competitionDates
        }
        this.setState({
            data: data
        })
    }

    onLogoChanged = (fd: any) => {
        let data: any = { ...this.state.data };
        data.logo = fd.url;
        this.logoFile = fd.file;
        this.setState({ data: data });
    }

    deleteLogo() {
        if (this.state.data && !this.state.data.logo) {
            return;
        }

        this.props.showDialog(`Are you sure you want to delete logo?`, DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {
                if (!this.logoFile && this.state.data && this.state.data.logo) {
                    this.deletedLogo = this.state.data.logo;
                }

                this.logoFile = null;
                let data: any = { ...this.state.data };
                data.logo = null;
                this.setState({ data: data });
            }
        })
    }

    render() {

        return (
            <div>
                <h4 className="c-grey-900">Competition Edit</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                    data={this.state.data}></ContentStatus>
                <Nav tabs style={{ marginBottom: "20px", marginTop: "10px", cursor: "pointer" }}>
                    <NavItem style={{ width: "50%" }}>
                        <NavLink
                            className={classnames({ active: this.state.selectedTab === 0 })}
                            onClick={() => {
                                this.setState({ selectedTab: 0 })
                            }}>

                            Details
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ width: "50%" }}>
                        <NavLink
                            className={classnames({ active: this.state.selectedTab === 1 })}
                            onClick={() => {
                                this.setState({ selectedTab: 1 })
                            }}
                        >
                            Fee Invoice Information
                        </NavLink>
                    </NavItem>
                    

                </Nav>
                
                {(this.state && this.state.data) &&
                    <form onSubmit={this.onSubmit}>
                        {this.state.selectedTab === 0 &&
                            <div>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="title">Title *</label>
                                    <input id="title" name="title" required className="form-control"
                                        value={this.state.data.title} onChange={this.changeData}
                                        maxLength={2000} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="subtitle">Subtitle *</label>
                                    <input id="subtitle" name="subtitle" required className="form-control"
                                        value={this.state.data.subtitle || ''} onChange={this.changeData}
                                        maxLength={2000} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="publishDate">Publish Date</label>
                                    <DateTimePicker name="publishDate" minDate='' isUtc={false} useTime={true}
                                        maxDate=''
                                        value={Helpers.getIsoDate(this.state.data.publishDate)}
                                        onChange={this.dateChange} />
                                </div>
                                <div className="row">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="registrationFrom">Registration
                                            From</label>
                                        <DateTimePicker name="registrationFrom" minDate='' isUtc={false} useTime={true}
                                            maxDate=''
                                            value={Helpers.getIsoDate(this.state.data.registrationFrom)}
                                            onChange={this.dateChange} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="registrationTo">Registration To</label>
                                        <DateTimePicker name="registrationTo" maxDate='' isUtc={false} useTime={true}
                                            minDate={Helpers.getLocalDate(this.state.data.registrationFrom)}
                                            value={Helpers.getIsoDate(this.state.data.registrationTo)}
                                            onChange={this.dateChange} />
                                    </div>

                                </div>
                                <br />
                                Stripe
                                <hr style={{ borderColor: 'rgba(0,0,0,0.6)' }} />
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="stripeReference">Stripe Reference</label>
                                    <input id="stripeReference" name="stripeReference" className="form-control"
                                        value={this.state.data.stripeReference || ''} onChange={this.changeData}
                                        maxLength={2000} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="stripeAccount">Stripe Account *</label>
                                    <select id="stripeAccount" name="stripeAccount" className="form-control" required
                                        value={this.state.data.stripeAccount ? this.state.data.stripeAccount.id : ""}
                                        onChange={(e) => {
                                            this.changeData(e)
                                        }}>
                                        <option value="">-- select stripe account --</option>
                                        {this.props.competitionState.stripeAccounts.map((item: StripeAccount, index: number) => (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <hr style={{ borderColor: 'rgba(0,0,0,0.6)' }} />
                                <br />
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="type">Type</label>
                                    <select id="type" name="type" className="form-control"
                                        value={this.state.data.type} onChange={(e) => {
                                            this.changeData(e)
                                        }}>
                                        <option value="">-- select type --</option>
                                        {getType().map((item: any, index: number) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="address">Address *</label>
                                    <input id="address" name="address" required className="form-control"
                                        value={this.state.data.address || ''} onChange={this.changeData}
                                        maxLength={2000} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="city">City *</label>
                                    <input id="city" name="city" required className="form-control"
                                        value={this.state.data.city || ''} onChange={this.changeData}
                                        maxLength={2000} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="country">Country *</label>
                                    <CountrySearch action={(item: any) => this.changeCountry(item)} data={this.state.data}
                                        suggestions={this.props.competitionState.countries} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="contact">Contact *</label>
                                    <input id="contact" name="contact" required className="form-control"
                                        value={this.state.data.contact || ''} onChange={this.changeData}
                                        maxLength={2000} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Logo</label>
                                    {this.state.data.logo && <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.deleteLogo()
                                    }}>
                                        <div style={{ paddingTop: "5px", marginLeft: "5px" }}
                                            className="bgc-blue-50 c-blue-500 circle-add">
                                            <span className="icon-holder" style={{ fontSize: "16px" }}>
                                                <i className="c-light-blue-500 ti-trash"></i>
                                            </span>
                                        </div>
                                    </a>}
                                    <ImagePicker onChange={(res: any) => { this.onLogoChanged(res) }} src={this.state.data.logo} width="250px" height="250px"
                                        required={false} warningLevel={PictureWarningLevel.Light} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Rich Text *</label>
                                    <DraftEditor content={this.state.data.richText} maxChars={this.richTextMaxLength} hasBlockTypes={true} handleRequired={true} onValueChange={(val: string) => { this.changeData(val) }} />
                                </div>
                            </div>
                        }
                        {this.state.selectedTab === 1 && 
                            <div className="row">
                                <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="invoiceIssuer">Invoice Issuer</label>
                                    <select id="invoiceIssuer" name="invoiceIssuer" className="form-control"
                                        value={this.state.data.invoiceIssuer && this.state.data.invoiceIssuer.id}
                                        onChange={(e) => this.invoiceChange(e)}>

                                        <option value="">-- select issuer --</option>
                                        {this.props.competitionState.invoiceIssuers.map((item: InvoiceIssuer, index: number) => (
                                            <option key={index} value={item.id}>{item.issuer}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="invoiceInformation">Invoice Information</label>
                                    <select id="invoiceInformation" name="invoiceInformation" className="form-control"
                                        value={this.state.data.invoiceInformation && this.state.data.invoiceInformation.id}
                                        onChange={(e) => this.invoiceChange(e)}>
                                        <option value="">-- select invoice information --</option>
                                        {this.props.competitionState.invoiceInfo.map((item: InvoiceInfo, index: number) => (
                                            <option key={index} value={item.id}>{item.description} {item.invoicePrefix} - {item.invoiceNumber}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="feeInvoiceLocation">Fee Invoice Location</label>
                                    <input id="feeInvoiceLocation" name="feeInvoiceLocation" className="form-control"
                                        value={this.state.data.feeInvoiceLocation || ''} onChange={this.invoiceChange}
                                        maxLength={2000} />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="feeInvoiceDate">Fee Invoice Date</label>
                                    <input id="feeInvoiceDate" name="feeInvoiceDate" className="form-control"
                                        value={this.state.data.feeInvoiceDate || ''} onChange={this.invoiceChange}
                                        maxLength={2000} />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="feeDateOfService">Fee Date of Service</label>
                                    <input id="feeDateOfService" name="feeDateOfService" className="form-control"
                                        value={this.state.data.feeDateOfService || ''} onChange={this.invoiceChange}
                                        maxLength={2000} />
                                </div>
                                
                                <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="feeEM">Fee EM</label>
                                <input id="feeEM" name="feeEM" className="form-control"
                                        value={this.state.data.feeEM || ''} onChange={this.invoiceChange}
                                    maxLength={2000} />
                                </div>
                                
                               
                                <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="feeVATClause">Fee VAT Clause</label>
                                    <input id="feeVATClause" name="feeVATClause" className="form-control"
                                        value={this.state.data.feeVATClause || ''} onChange={this.invoiceChange}
                                        maxLength={2000} />
                                </div>
                                
                                <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="fee">Fee</label>
                                    <input id="fee" name="fee" className="form-control"
                                        value={this.state.data.fee || ''} onChange={this.invoiceChange}/>
                                </div>

                                {/*<div className="mb-3">*/}
                                {/*    <div className="checkbox checkbox-info peers ai-c">*/}
                                {/*        <input id="useCustomPriceCalculation" name="useCustomPriceCalculation" type="checkbox"*/}
                                {/*            checked={this.state.data.useCustomPriceCalculation} className="peer"*/}
                                {/*            onChange={(e) => this.changeData(e)} />*/}
                                {/*        <label className="form-label peers peer-greed js-sb ai-c" htmlFor="useCustomPriceCalculation">*/}
                                {/*            <span className="peer peer-greed">Use Croatian resident discount (-15€ on all classes):</span>*/}
                                {/*        </label>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<div className="mb-3">*/}
                                {/*    <div className="checkbox checkbox-info peers ai-c">*/}
                                {/*        <input id="useSemiCustomPriceCalculation" name="useSemiCustomPriceCalculation" type="checkbox"*/}
                                {/*            checked={this.state.data.useSemiCustomPriceCalculation} className="peer"*/}
                                {/*            onChange={(e) => this.changeData(e)} />*/}
                                {/*        <label className="form-label peers peer-greed js-sb ai-c" htmlFor="useSemiCustomPriceCalculation">*/}
                                {/*            <span className="peer peer-greed">Use Croatian resident discount (-10€ on Young, Open, Intermediate, Working, Champion classes):</span>*/}
                                {/*        </label>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        
                        }
                        <br />
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {competitionState: state.competitionList};
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: Competition) => {
            dispatch(CompetitionListStore.actionCreators.selectItem(data))
        },
        getItems: () => {
            dispatch(CompetitionListStore.actionCreators.getItems())
        },
        getRegistrationItems: (page?: any, competition?: string, email?: string, count?: number, paymentStatus?: any, catalogNumber?: any) => {
            dispatch(CompetitionListStore.actionCreators.getRegistrationItems(page, competition, email, count, paymentStatus, catalogNumber))
        },
        getCountryItems: () => {
            dispatch(CompetitionListStore.actionCreators.getCountryItems())
        },
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompetitionEdit)
