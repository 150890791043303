
export enum BestPuppyGrade {
    WITHOUT_BEST_PUPPY_GRADE = 0,
    BEST_MINOR_PUPPY = 1,
    BEST_PUPPY = 2,
    NO_TITLE = 3

}

export enum Grade {

    NOT_GRADED = 0,
    WITHOUT_GRADE = 1,
    EXCELLENT = 2,
    VERY_GOOD = 3,
    GOOD = 4,
    SUFFICIENT = 5,
    INSUFFICIENT = 6
}


export enum Rang {

    WITHOUT_RANG = 0,
    FIRST_PLACE = 1,
    SECOND_PLACE = 2,
    THIRD_PLACE = 3,
    FOURTH_PLACE = 4
}


export enum Cac {
    WITHOUT_CAC = 0,
    CAC = 1,
    RCAC = 2,
    NO_TITLE = 3,
    CAC_J = 4,
    CAC_V = 5
}


export enum Cacib {

    WITHOUT_CACIB = 0,
    CACIB = 1,
    RCACIB = 2,
    NO_TITLE = 3,
    CACIB_J = 4,
    CACIB_V = 5
}

export enum PuppyGrade {
    WITHOUT_PUPPY_GRADE = 0,
    VERY_PROMISING = 1,
    PROMISING = 2,
    NO_PROMISING = 3,
    SATISFIES = 4

}

export enum AgeGroup {

    FIRST = 0,
    SECOND = 1,
    THIRD = 2,

}

export enum BISScoreType {
    Unknown,
    BOG,
    BOD,
    BestMinorPuppy,
    BestPuppy,
    BestBOBJr,
    BODBobJr,
    BestBOBVet,
    IndigenousBreed,
    Couple,
    Group,
    JH1,
    JH2,
    JHFinal,
    SH
}

export enum BISScore {
    NoPlacement,
    First,
    Second,
    Third,
    Fourth,
    Fifth
}

export enum StartNumberGenerationType {
    JUNIOR_HANDLING = 0,
    COUPLE_GROUP = 1,
}

export enum RegistrationFilterType {
    All,
    Dog,
    CoupleGroup,
    JuniorHandling,
    Special
}

export function getRegistrationFilterType(type: RegistrationFilterType) {
    switch (type) {
        case RegistrationFilterType.All:
            return 'All';
        case RegistrationFilterType.Dog:
            return 'Dog';
        case RegistrationFilterType.CoupleGroup:
            return 'Couple/group';
        case RegistrationFilterType.JuniorHandling:
            return 'Junior Handling';
        case RegistrationFilterType.Special:
            return 'Special';

    }
}


export function getRegistrationTypes() {
    return [

        { label: 'All Types', value: RegistrationFilterType.All },
        { label: 'Dog', value: RegistrationFilterType.Dog },
        { label: 'Couple/group', value: RegistrationFilterType.CoupleGroup },
        { label: 'Junior Handling', value: RegistrationFilterType.JuniorHandling },
        { label: 'Special', value: RegistrationFilterType.Special },


    ]
}

export function getAgeGroupRange(age: AgeGroup) {
    switch (age) {
        case AgeGroup.FIRST:
            return '9-12';
        case AgeGroup.SECOND:
            return '13-17';
        case AgeGroup.THIRD:
            return '18+';


    }
}


export function getBODBISScoreType() {
    return [

        { label: 'BOD', value: BISScoreType.BOD },


    ]
}

export function getJrBISScoreType() {
    return [

        { label: 'Best Junior BOB', value: BISScoreType.BestBOBJr }

    ]
}

export function getJrBODBISScoreType() {
    return [

        { label: 'BOD BOB Junior', value: BISScoreType.BODBobJr }

    ]
}

export function getPuppyBISScoreType() {
    return [

        { label: 'Best Minor Puppy', value: BISScoreType.BestMinorPuppy },
        { label: 'Best Puppy', value: BISScoreType.BestPuppy }

    ]
}

export function getVeteranBISScoreType() {
    return [

        { label: 'Best Veteran BOB', value: BISScoreType.BestBOBVet }

    ]
}

export function getCoupleGroupBISScoreType() {
    return [


        { label: 'Couple', value: BISScoreType.Couple },
        { label: 'Group', value: BISScoreType.Group }

    ]
}

export function getJuniorSeniorBISScoreType() {
    return [

        { label: 'JH1', value: BISScoreType.JH1 },
        { label: 'JH2', value: BISScoreType.JH2 },
        { label: 'SH', value: BISScoreType.SH }

    ]
}

export function getDogBISScoreType() {
    return [

        { label: 'BOG', value: BISScoreType.BOG }

    ]
}


export function getBreedBISScoreType() {
    return [

        { label: 'Indigenous Breed', value: BISScoreType.IndigenousBreed },

    ]
}

export function getFinalBISScoreType() {
    return [


        { label: 'JH Final', value: BISScoreType.JHFinal },

    ]
}

export function getBISScore() {
    return [
        { label: 'No Placement', value: BISScore.NoPlacement },
        { label: 'First', value: BISScore.First },
        { label: 'Second', value: BISScore.Second },
        { label: 'Third', value: BISScore.Third },

    ]
}

export function getJSHBISScore() {
    return [
        { label: 'No Placement', value: BISScore.NoPlacement },
        { label: 'First', value: BISScore.First },
        { label: 'Second', value: BISScore.Second },
        { label: 'Third', value: BISScore.Third },
        { label: 'Fourth', value: BISScore.Fourth },
        { label: 'Fifth', value: BISScore.Fifth }


    ]
}

export function getCustomBISScore() {
    return [
        { label: 'No Placement', value: BISScore.NoPlacement },
        { label: 'First', value: BISScore.First },
        { label: 'Second', value: BISScore.Second },
        { label: 'Third', value: BISScore.Third },
        { label: 'Fourth', value: BISScore.Fourth },
        { label: 'Fifth', value: BISScore.Fifth }
    ]
}

export function getAgeGroup() {
    return [
        { label: '9-12', value: AgeGroup.FIRST },
        { label: '13-17', value: AgeGroup.SECOND },
        { label: '18+', value: AgeGroup.THIRD },

    ]
}

export function getPuppyGrade(puppy: PuppyGrade) {
    switch (puppy) {
        case PuppyGrade.WITHOUT_PUPPY_GRADE:
            return 'Without puppy grade';
        case PuppyGrade.VERY_PROMISING:
            return 'Very promising';
        case PuppyGrade.PROMISING:
            return 'Promising';
        case PuppyGrade.SATISFIES:
            return 'Satisfies';
        case PuppyGrade.NO_PROMISING:
            return 'Not promising';

    }
}

export function getPuppyGradeOptions() {
    return [
        { label: 'Without puppy grade', value: PuppyGrade.WITHOUT_PUPPY_GRADE },
        { label: 'Very promising', value: PuppyGrade.VERY_PROMISING },
        { label: 'Promising', value: PuppyGrade.PROMISING },
        { label: 'Satisfies', value: PuppyGrade.SATISFIES },
        { label: 'Not promising', value: PuppyGrade.NO_PROMISING },

    ]
}



export function getCAC(cac: Cac) {
    switch (cac) {
        case Cac.WITHOUT_CAC:
            return 'Without CAC';
        case Cac.CAC:
            return 'CAC';
        case Cac.RCAC:
            return 'RCAC';
        case Cac.CAC_J:
            return 'CAC-J';
        case Cac.CAC_V:
            return 'CAC-V';
        case Cac.NO_TITLE:
            return 'No title';


    }
}

export function getCacOptions() {
    return [
        { label: 'Without CAC', value: Cac.WITHOUT_CAC },
        { label: 'CAC', value: Cac.CAC },
        { label: 'RCAC', value: Cac.RCAC },
        { label: 'CAC-J', value: Cac.CAC_J },
        { label: 'CAC-V', value: Cac.CAC_V },
        { label: 'No title', value: Cac.NO_TITLE }


    ]
}


export function getCACIB(cacib: Cacib) {
    switch (cacib) {
        case Cacib.WITHOUT_CACIB:
            return 'Without CACIB';
        case Cacib.CACIB:
            return 'CACIB';
        case Cacib.RCACIB:
            return 'RCACIB';
        case Cacib.CACIB_J:
            return 'CACIB-J';
        case Cacib.CACIB_V:
            return 'CACIB-V';
        case Cacib.NO_TITLE:
            return 'No title';

    }
}

export function getCacibOptions() {
    return [
        { label: 'Without CACIB', value: Cacib.WITHOUT_CACIB },
        { label: 'CACIB', value: Cacib.CACIB },
        { label: 'RCACIB', value: Cacib.RCACIB },
        { label: 'CACIB-J', value: Cacib.CACIB_J },
        { label: 'CACIB-V', value: Cacib.CACIB_V },
        { label: 'No title', value: Cacib.NO_TITLE }


    ]
}

export function getRang(rang: Rang) {
    switch (rang) {
        case Rang.WITHOUT_RANG:
            return 'Without rang';
        case Rang.FIRST_PLACE:
            return 'First place';
        case Rang.SECOND_PLACE:
            return 'Second place';
        case Rang.THIRD_PLACE:
            return 'Third place';
        case Rang.FOURTH_PLACE:
            return 'Fourth place';



    }
}

export function getRangOptions() {
    return [
        { label: 'Without rang', value: Rang.WITHOUT_RANG },
        { label: 'First place', value: Rang.FIRST_PLACE },
        { label: 'Second place', value: Rang.SECOND_PLACE },
        { label: 'Third place', value: Rang.THIRD_PLACE },
        { label: 'Fourth place', value: Rang.FOURTH_PLACE }

    ]
}


export function getBestPuppyGrade(grade: BestPuppyGrade) {
    switch (grade) {
        case BestPuppyGrade.WITHOUT_BEST_PUPPY_GRADE:
            return 'Without grade';
        case BestPuppyGrade.BEST_MINOR_PUPPY:
            return 'Best Minor Puppy';
        case BestPuppyGrade.BEST_PUPPY:
            return 'Best Puppy';
        case BestPuppyGrade.NO_TITLE:
            return 'No title';


    }
}

export function getBestPuppyGradeOptions() {
    return [
        { label: 'Without grade', value: BestPuppyGrade.WITHOUT_BEST_PUPPY_GRADE },
        { label: 'Best Minor Puppy', value: BestPuppyGrade.BEST_MINOR_PUPPY },
        { label: 'Best Puppy', value: BestPuppyGrade.BEST_PUPPY },
        { label: 'No title', value: BestPuppyGrade.NO_TITLE },

    ]
}

export function getGrade(grade: Grade) {
    switch (grade) {
        case Grade.NOT_GRADED:
            return 'Not graded';
        case Grade.WITHOUT_GRADE:
            return 'Without grade';
        case Grade.EXCELLENT:
            return 'Excellent';
        case Grade.VERY_GOOD:
            return 'Very good';
        case Grade.GOOD:
            return 'Good';
        case Grade.SUFFICIENT:
            return 'Sufficient';
        case Grade.INSUFFICIENT:
            return 'Insufficient';


    }
}

export function getGradeOptions() {
    return [
        { label: 'Not graded', value: Grade.NOT_GRADED },
        { label: 'Without grade', value: Grade.WITHOUT_GRADE },
        { label: 'Excellent', value: Grade.EXCELLENT },
        { label: 'Very good', value: Grade.VERY_GOOD },
        { label: 'Good', value: Grade.GOOD },
        { label: 'Sufficient', value: Grade.SUFFICIENT },
        { label: 'Insufficient', value: Grade.INSUFFICIENT }

    ]
}



