import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import * as DialogStore from '../store/DialogStore';
import * as ProfileStore from '../store/ProfileStore';

import { DogAttachmentTypes, getAttachmentType } from '../models/dog/DogEnums';
import { Helpers } from '../utility/Helpers';
import { DogAttachment } from '../models/dog/Dog';
import FilePicker from './FilePicker';
import { httpService } from '../index';
import { UserType } from '../models/registered-user/RegisteredUserEnums';

export enum FileWarningLevel {
	Light,
	Strict
}

type AttachmentListProps = {
	warningLevel: FileWarningLevel;
	attachments?: Array<DogAttachment>;
	onChange: Function;
	parentId: string;
}

class AttachmentList extends React.Component<AttachmentListProps & typeof DialogStore.actionCreators & any> {

	constructor(props: any) {
		super(props);
	}

	getAttachmentsByType(list: Array<DogAttachment> | undefined, type: DogAttachmentTypes) {
		return (list) ? list.filter(i => i.type === type) : [];
	}

	getAttachmentsTypes() {
		return [
			DogAttachmentTypes.WORKDOG,
			DogAttachmentTypes.PEDIGREE,
			DogAttachmentTypes.CHAMPION,
			DogAttachmentTypes.PASSPORT
		]
	}

	removeFile(item: DogAttachment) {
		this.props.showDialog(`Are you sure you want to delete attachment?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
			if (confirm && item.id) {
				this.props.increment();
				try {
					await httpService.dogAttachmentDelete(item.id);
					this.props.onChange(item, true);
					this.props.decrement();
				} catch (err) {
					this.props.decrement();
					alert(err.message);
				}
			}
		})
	}

	async fileChange(att: any) {
		if (!att || (!att.name && !att.file)) {
			alert('No file selected.');
			return;
		}

		this.props.increment();
		try {

			let newAtt = null;

			if (att.file) {
				newAtt = await httpService.dogAttachmentUpload(att.file, this.props.parentId, att.type);
			}

			this.props.decrement();
			this.props.onChange(newAtt, false);
			this.props.hideAttachmentDialog();

		} catch (err) {
			this.props.decrement();
			alert(err.message);
		}
    }

	render() {
		return (
			<div className="picture-picker">
				{this.getAttachmentsTypes().map((type, index) =>
					<div key={index}>{getAttachmentType(type)}
						<label className="cur-p mL-10">
							{Helpers.isUserAdmin(this.props.userState.user) &&
								<div className="bgc-blue-50 c-blue-500 circle-add">+</div>
							}
							<FilePicker onChange={(item: any) => { item.type = type; this.fileChange(item) }}></FilePicker>
						</label>
						<br /><br />
						{this.getAttachmentsByType(this.props.attachments, type).map((attach: any, index: number) =>
							<div key={attach.id}>
								<div style={{ position: 'relative', display: 'inline-block' }}>
									<div>
										<a href={attach.name} target="_blank">
											{Helpers.isImagePath((attach.file) ? attach.file.name : attach.name) && <img key={index}
												alt="attachment"
												src={attach.name}
												style={{
													maxHeight: "400px",
													maxWidth: "300px"
												}} />}

											{Helpers.isImagePath((attach.file) ? attach.file.name : attach.name) === false && <span>
												{Helpers.getAttachmentName((attach.file) ? attach.file.name : attach.name)}
											</span>}
										</a>
										<br /><br />
									</div>
									{Helpers.isUserAdmin(this.props.userState.user) &&
										<a style={{ position: 'absolute', top: '0', right: '-36px' }} href="#" onClick={(e) => { e.preventDefault(); this.removeFile(attach) }}>
											<div style={{ paddingTop: "5px", marginLeft: "5px" }} className="bgc-blue-50 c-blue-500 circle-add">
												<span className="icon-holder" style={{ fontSize: "16px" }}>
													<i className="c-light-blue-500 ti-trash"></i>
												</span>
											</div>
										</a>
									}
								</div>
								<hr />
							</div>
						)}

						{this.getAttachmentsByType(this.props.attachments, type).length === 0 && <span>No "{getAttachmentType(type)}" attachments.<br/><br/></span>}

					</div>
				)}
			</div>
		)
	}

	static defaultProps: AttachmentListProps = {
		onChange: () => { },
		warningLevel: FileWarningLevel.Light,
		attachments: [],
		parentId: ''
	}
}

export default connect(
	(state: ApplicationState) => { return { dialog: state.dialog, userState: state.user } },
	DialogStore.actionCreators
)(AttachmentList)
