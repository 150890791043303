import React from "react";
import { Component } from "react";
import { httpService } from "../..";
import { Helpers } from "../../utility/Helpers";
import { TVHelpers } from "../../utility/TVHelpers";
import TVTopBar from "./TVTopBar";
import TVPagination from "./TVPagination";

type defaultProps = {
    data: any;
    parentId: any
};

class BISSchedule extends Component<any, any>{
    timer: any;
    currentIndex: any;

    bisPages: any;

    constructor(props: any) {
        super(props);
        this.state = {
            data: this.props.data,
            currentIndex: 0
        }
    }

    componentDidMount() {
        this.startLooping();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    startLooping = () => {
        this.timer = setInterval(() => {
            const { currentIndex, data } = this.state;
 
            if (currentIndex + TVHelpers.breedsPerPageCount < data.bisShows.length) {
                this.setState((prevState: any) => ({
                    currentIndex: prevState.currentIndex + TVHelpers.breedsPerPageCount,
                }));
            } else {
                this.setState((prevState: any) => ({
                    currentIndex: 0,
                }));
                clearInterval(this.timer);
                this.fetchData()
            }
        }, TVHelpers.switchTime);
    };

    async fetchData() {
        let data = await httpService.getBISSchedule(this.props.parentId)
        try {
            if (data) {
                this.setState({
                    currentIndex: 0,
                    data: data,
                });
            } else {
                this.setState({
                    currentIndex: 0,
                });
            }
            this.startLooping();
        }

        catch (error) {
            console.error("Fetch error:", error);

            this.setState({
                currentIndex: 0,
            });
            this.startLooping();
        };
    }

    render() {
        const { currentIndex, data } = this.state;

        if (!data || data.bisShows.length === 0) {
            return (
                <div className="tv_grey2_bg">
                    <TVTopBar title={`BIS SCHEDULE:  - `}></TVTopBar>
                    <div className="tv_grey2_bg tv_main_content">
                        <div className="center_align_elements tv_color_main helvetica_bold_100" style={{ width: '100%', height: '75%' }}>NO DATA</div>
                    </div>
                </div>
            );
        }

        this.bisPages = Array(Math.ceil(data.bisShows.length / TVHelpers.breedsPerPageCount)).fill(1);
        const bisShowsToShow = data.bisShows.slice(
            currentIndex,
            currentIndex + TVHelpers.breedsPerPageCount
        );

        return (
            <div className="tv_grey2_bg">
                <TVTopBar title={`BIS SCHEDULE / BIS URNIK: ${data.description} - ${Helpers.getLocalDateString(data.date)}`}></TVTopBar>

                <div>
                    <div className="table-container tv_grey2_bg tv_main_content">
                        {bisShowsToShow.length === 0
                        ? <div className="center_align_elements tv_color_main helvetica_bold_100" style={{ width: '100%', height: '75%' }}>NO BIS SHOWS</div>                   
                        : <div>
                            <div className="tv1506height">
                            <table className="schedule-table tv_bis_table">
                                <thead className="tv_white tv_grey_bg helvetica_regular_60 tv150height">
                                    <tr className="table-header">
                                        <th className="base_border line_height1 padding_left64 tv1520width">Best in Show</th>
                                        <th className="base_border line_height1 text_align_center tv620width">Timetable</th>
                                        <th className="base_border line_height1 text_align_right tv1520width padding_right64">Judge</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bisShowsToShow.map((item: any, index: any) => (
                                        <tr key={index} className="table-row tv_white helvetica_bold_60">
                                            <td className="base_border line_height1 padding_left64 tv1520width tv226height tv_text_no_wrap">{item.type}</td>
                                            <td className="base_border line_height1 tv620width tv226height tv_text_no_wrap text_align_center">{item.approxStartTime}</td>
                                            <td className="base_border line_height1 padding_right64 tv1520width tv226height tv_text_no_wrap text_align_right">{item.judge}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                            <TVPagination pages={this.bisPages} currentIndex={currentIndex / TVHelpers.breedsPerPageCount} marginLeft={0} fetchTime={data.fetchTime}></TVPagination>
                          </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default (BISSchedule)