import { Action, Reducer } from 'redux';
import { httpService } from '../index';
import { AppThunkAction } from './';
import { StripeAccount } from '../models/stripe-account/StripeAccount';

export interface StripeListState {
    selectedItem: StripeAccount | null,
    list: Array<StripeAccount>,
    isLoading: boolean,
    error: string
}

interface SelectStripeAccountAction {
    type: 'SELECT_STRIPEACCOUNT';
    item: StripeAccount;
}

interface RequestStripeAccountAction {
    type: 'REQUEST_STRIPEACCOUNT';
}

interface ReceiveStripeAccountAction {
    type: 'RECEIVE_STRIPEACCOUNT';
    items: Array<StripeAccount>;
    error: '';
}

type KnownAction = SelectStripeAccountAction | RequestStripeAccountAction | ReceiveStripeAccountAction;

export const actionCreators = {
    selectItem: (item: StripeAccount): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SELECT_STRIPEACCOUNT', item: item });
    },
    getItems: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.stripeAccountList) {
            try {
                dispatch({ type: 'REQUEST_STRIPEACCOUNT' });
                let data = await httpService.getStripeAccounts();
                dispatch({ type: 'RECEIVE_STRIPEACCOUNT', items: data, error: '' });

            } catch (err) {
                dispatch({ type: 'RECEIVE_STRIPEACCOUNT', items: [], error: '' });
            }
        }
    }
}

const unloadedState: StripeListState = { selectedItem: null, isLoading: false, list: [], error: '' };

export const reducer: Reducer<StripeListState> = (state: StripeListState | undefined, incomingAction: Action): StripeListState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_STRIPEACCOUNT':
            return {
                selectedItem: action.item,
                list: state.list,
                isLoading: false,
                error: ''
            }
        case 'REQUEST_STRIPEACCOUNT':
            return {
                selectedItem: state.selectedItem,
                list: [],
                isLoading: true,
                error: ''
            }
        case 'RECEIVE_STRIPEACCOUNT':

            let si = state.selectedItem;

            if (action.items && action.items.length > 0 && !state.selectedItem) {
                si = action.items[0];
            }

            return {
                selectedItem: si,
                list: action.items,
                isLoading: false,
                error: action.error
            }
    }
    return state;

}
