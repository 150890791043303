import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';

import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,

} from "reactstrap";
import * as DialogStore from '../../store/DialogStore';
import { httpService } from "../..";
import { ApplicationState } from "../../store";
import BreedSearch from "../BreedSearch";
import { Helpers } from "../../utility/Helpers";
import FilePicker from "../FilePicker";



type Props = {
    data: any;
    callback: any;
    empty: any;
    action: any;
    actions: any;
    editMode: any;
    editCouponCode: any;
    dateDialog: any;
    add: any;
    couponId: string;
}


class CouponCodeComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }


    componentDidUpdate() {
        if (this.props.data && this.state.data && this.props.data.id && this.props.data.id !== this.state.data.id) {
            this.getData()
        }

    }


    state: any = {
        selectedCode: null,
        selectedCodeIndex: null,
        data: this.props.data,
        isOpen: false,
        editMode: false,
        codesArray: this.props.data,
        currentIndex: null,
        emptyCode: { code: null, id: '', used: false },
        dateDialog: this.props.dateDialog,
        disableButton: false

    }



    closeModal() {
        this.setState({ isOpen: !this.state.isOpen, currentIndex: null, selectedCode: null, editMode: !this.state.editMode })

    }

    addCode(index: any) {
        this.setState({ isOpen: !this.state.isOpen, currentIndex: index, selectedCode: this.state.emptyCode })

    }

    editCode(index: any) {
        this.setState({ isOpen: !this.state.isOpen, selectedCode: this.props.data[index], currentIndex: index, editMode: true })

    }

    saveCode = async (index: any) => {
        let selectedCode = { ...this.state.selectedCode }
        let state = { ...this.state }
        let codes = !state.codesArray ? [] : [...this.state.codesArray]
        if (Object.keys(selectedCode).length !== 0) {
            if (selectedCode.id !== '') {
                let code = codes.find((i: any) => i.id === selectedCode.id)
                if (!code) {
                    if (codes[index]) {
                        codes[this.state.currentIndex] = selectedCode
                    } else {
                        codes.push(selectedCode)
                    }

                } else {
                    codes[this.state.currentIndex] = selectedCode
                }
            } else {
                codes.push(selectedCode)
            }

            this.setState({ codesArray: codes, currentIndex: null, editMode: false, selectedCode: null, isOpen: false }, () => {

                this.props.callback(this.state.codesArray)
            })

        }

    }

    saveChanges(data: any, save: boolean) {

        if (data && save) {
            this.props.showDialog(`Are you sure you want to save this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    if (this.state.selectedCode !== null && this.state.currentIndex !== null) {
                        data.couponCodes[this.state.currentIndex] = this.state.selectedCode
                    } else if (this.state.selectedCode !== null && this.state.currentIndex === null) {
                        data.couponCodes.push(this.state.selectedCode)
                    }
                    try {
                        this.setState({ disableButton: true })
                        await httpService.couponAddOrUpdate(data)

                        this.closeModal()
                        this.setState({ currentIndex: null, selectedCode: null, disableButton: false })


                    } catch (err) {

                        alert(err.message)
                        this.setState({ disableButton: false })

                    }

                }
            })

        }
    }

    getData = async () => {
        let d = await httpService.getCoupon(this.props.data.id)
        this.setState({ data: d })
    }

    removeCode(index: any) {
        this.props.showDialog(`Are you sure you want to delete this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {
                let data = [...this.state.codesArray]
                data.splice(index, 1)
                this.setState({ codesArray: data, currentIndex: null }, () => {
                    this.props.callback(this.state.codesArray)
                })
                if (this.state.codesArray.length == 0) {
                    this.props.empty(true)
                }

            }
        })
    }


    changeCode(ev: any, index: any) {
        const target = ev.target;
        const name = target.name;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;

        let data: any = { ...this.state.selectedCode };
        data[name] = value;
        this.setState({ selectedCode: data, selectedCodeIndex: index })
    }

    async fileChanged(file: any) {

        if (file && file.file) {
            try {
                let res = await httpService.couponImport(file.file, this.props.couponId);
                this.setState({ codesArray: res.couponCodes });
                this.props.callback(this.state.codesArray);
                this.props.showNotification({ message: 'Import OK', type: DialogStore.NotificationType.Success})
            } catch (err) {
                alert(err.message)
            }
        }
    }

    exportClick = async () => {
        try {
            let res = await httpService.couponExport(this.props.couponId);
        } catch (err) {
            alert(err.message)
        }
    }

    render() {
        return (
            <React.Fragment>

                <div>
                    {(this.props.editMode || this.props.add) &&
                        !Helpers.isUserEventViewer(this.props.userState.user) &&
                        <div style={{ float: "right" }}>
                            <label className="cur-p mL-10">
                                <a href="#" onClick={(e: any) => {
                                    e.preventDefault();
                                    this.addCode(null)

                                }}>
                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                </a>
                            </label>
                            <br /><br />
                        </div>
                    }


                    <h5 style={{display: 'inline-block'}}>Coupon Codes</h5>
                    {this.props.couponId && <div style={{ display: 'inline-block', float: 'right' }}>
                        <label className="btn btn-primary btn-sm btn-color">IMPORT
                            <FilePicker onChange={(item: any) => { this.fileChanged(item) }}></FilePicker>
                        </label>&nbsp;&nbsp;
                        <label className="btn btn-primary btn-sm btn-color">EXPORT
                            <input type="button" className="inputfile" onClick={this.exportClick} />
                        </label>
                    </div>}
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ width: "20%" }} scope="col">#</th>
                                <th style={{ width: "30%" }} scope="col">Code</th>
                                <th style={{ width: "30%" }} scope="col">Used</th>
                                <th style={{ textAlign: "right", width: "10%" }} scope="col">
                                {(this.props.editMode || this.props.add) && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                   "Actions"
                                }
                                </th>

                            </tr>
                        </thead>
                        <tbody>

                            {this.state.codesArray && this.state.codesArray.length > 0 && this.state.codesArray.map((code: any, index: number) =>

                            (
                                <tr key={code.id ? code.id : code.name}>
                                    <td style={{ color: "grey", width: "20%" }}
                                        className="cur-p"
                                    >
                                        {index + 1}
                                    </td>

                                    <td style={{ color: "grey", width: "30%" }}
                                        className="cur-p"
                                    >
                                        {(code.code || "/")}
                                    </td>
                                    <td style={{ color: "grey", width: "30%" }}
                                        className="cur-p"
                                    >
                                        {Helpers.booleanToString(code.used)}
                                    </td>
                                    <td style={{ color: "grey", width: "10%" }}>
                                    {(this.props.editMode || this.props.add) && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                        

                                            <a style={{ float: "right" }} href="#" >
                                                {!code.used &&
                                                    <>
                                                        <span style={{ fontSize: "16px" }} onClick={(e: any) => { e.preventDefault(); this.removeCode(index) }} className="icon-holder">
                                                            <i className="c-light-blue-500 ti-trash"></i>
                                                        </span>
                                                    </>
                                                }

                                            </a>

                                        
                                    }
                                    </td>

                                </tr>
                            )
                            )
                            }



                        </tbody>


                    </table>
                    {(!this.state.dateDialog && this.props.data && this.props.data.length < 1) &&
                        (
                            <div style={{ color: "grey", display: "flex", justifyContent: "center" }} className="cur-p">No coupon codes.</div>
                        )
                    }


                    {!this.state.codesArray || (this.state.codesArray && this.state.codesArray.length < 1) && this.state.dateDialog &&
                        <div style={{ color: "grey", display: "flex", justifyContent: "center" }}>No coupon codes.</div>
                    }

                    {this.state.data && this.state.selectedCode && this.state.isOpen && (


                        <Modal id="coupon-code" size="md" isOpen={this.state.isOpen}>
                            <ModalHeader toggle={() => { this.closeModal() }}>{this.state.currentIndex == null ? "Add Coupon Code" : "Edit Coupon Code"}</ModalHeader>
                            <ModalBody>

                                <div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="code">Code</label>
                                        <input id="code" name="code" className="form-control"
                                            defaultValue={this.state.selectedCode.code} onChange={(e) => { this.changeCode(e, this.state.currentIndex) }}
                                            maxLength={2000} />
                                    </div>

                                </div>


                            </ModalBody>
                            <ModalFooter>

                                <Button disabled={this.state.disableButton} color="primary" onClick={() => { this.saveCode(this.state.currentIndex); }}>Save</Button>
                                <Button color="secondary" onClick={() => { this.closeModal(); }}>Close</Button>
                            </ModalFooter>

                        </Modal>

                    )
                    }
                </div>

            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => { return { dialog: state.dialog, userState: state.user } },
    DialogStore.actionCreators
)(CouponCodeComponent)
