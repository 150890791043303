import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import {connect} from 'react-redux';
import {Dog, DogAttachment} from '../../models/dog/Dog';
import * as DialogStore from '../../store/DialogStore';
import {httpService} from '../../index';
import {ApplicationState} from '../../store';
import * as DogListStore from '../../store/DogListStore';
import {DetailsBase} from '../base/DetailsBase';
import DetailsHeader from '../../components/DetailsHeader';
import {Helpers} from '../../utility/Helpers';
import {
    getGender,
    getStatus
} from '../../models/dog/DogEnums';
import {DialogType, NotificationType} from '../../store/DialogStore';
import {Button, Nav, NavItem, NavLink} from 'reactstrap';
import ContactUserComponent from "../../components/ContactUserForm";
import classnames from "classnames";
import AttachmentList, { FileWarningLevel } from "../../components/AttachmentList";

import ActionHistory from '../../components/competition/ActionHistory';


class DogDetails extends DetailsBase<Dog> {

    constructor(props: any) {
        super(props);
        this.getItem = async (id: string) => {
            let res = await httpService.getDog(id);
            if (res) {
                this.props.getDogRegistrations(res.id)
            
                return res;
            }
            
        }

        this.saveItem = async (data: Dog) => {
            return await httpService.dogAddOrUpdate(data)
        }
        this.deleteItemCall = async (id: string) => {
            return await httpService.dogDelete(id)
        }
        
    }

    

    async confirmItem(property: string = 'title') {
        try {
            let item: any = this.state.data;

            this.props.showDialog(`Are you sure you want to confirm "${item ? item.name : "this item"}?"`, DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    this.props.increment();
                    await httpService.dogConfirm(item.id);

                    this.props.decrement();
                    this.props.getItems();

                    if (this.props.dataState.selectedItem && this.props.dataState.selectedItem.id === item.id) {
                        this.props.selectItem(null);
                    }

                    this.props.selectItem(null);
                }
            })
        } catch (err) {
            this.props.decrement();
            alert(err.message);
        }
    }

    async rejectItem(property: string = 'title') {
        try {
            let item: any = this.state.data;

            this.props.showDialog(`Are you sure you want to reject "${item ? item.name : "this item"}?"`, DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    this.props.increment();
                    await httpService.dogReject(item.id);

                    this.props.decrement();
                    this.props.getItems();

                    if (this.props.dataState.selectedItem && this.props.dataState.selectedItem.id === item.id) {

                    }


                    this.props.selectItem(null);
                }
            })
        } catch (err) {
            this.props.decrement();
            alert(err.message);
        }
    }

    addRemoveAttachment(item: DogAttachment, remove: boolean) {

        if (remove) {
            let data: any = { ...this.state.data };
            let ind = data.attachments.findIndex((i: DogAttachment) => i.id === item.id);
            if (ind !== -1) {
                data.attachments.splice(ind, 1);
                this.setState({ data: data });
            }
        } else if (!remove) {
            let data: any = { ...this.state.data };
            let ind = data.attachments.findIndex((i: DogAttachment) => i.id === item.id);

            if (ind === -1) {
                data.attachments.push(item);
            } else {
                data.attachments[ind] = item;
            }

            this.setState({ data: data });
        }
    }


    render() {

        return (
            <div>
                <div style={{ display: "flex" }}>
                <DetailsHeader baseUrl="dog" data={this.state.data}
                    title={this.state.data ? this.state.data.name : "Dog Preview"} onDelete={() => {
                        this.deleteItem()
                    }} />
                <div style={{
                    position: "sticky", top: 0, marginLeft: "auto", alignSelf: "flex-end"
                }}>
                    {this.state.data && this.state.data.status !== 0 &&
                        <button type="button" className="btn cur-p btn-outline-success confirmRejectBtn"
                            onClick={(e) => {
                                e.preventDefault();
                                this.confirmItem()
                            }}>
                            <span style={{ fontSize: "16px" }}>Confirm</span>
                        </button>}

                    {this.state.data && this.state.data.status !== 2 &&
                        <button type="button" className="btn cur-p btn-outline-danger confirmRejectBtn"
                            onClick={(e) => {
                                e.preventDefault();
                                this.rejectItem()
                            }}>
                            <span style={{ fontSize: "16px" }}>Reject</span>
                        </button>

                    }
                    </div>
                </div>

                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                    data={this.state.data}></ContentStatus>

                <Nav tabs style={{ marginBottom: "20px", marginTop: "10px", cursor: "pointer" }}>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.selectedTab === 0 })}
                            onClick={() => {
                                this.setState({ selectedTab: 0 })
                            }}>

                            Details
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.selectedTab === 2 })}
                            onClick={() => {
                                this.setState({ selectedTab: 2 })
                            }}
                        >
                            Owner
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.selectedTab === 1 })}
                            onClick={() => {
                                this.setState({ selectedTab: 1 })
                            }}
                        >
                            Breeder
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.selectedTab === 3 })}
                            onClick={() => {
                                this.setState({ selectedTab: 3 })
                            }}
                        >
                            Attachments
                        </NavLink>
                    </NavItem>

                </Nav>

                {(this.state && this.state.data) &&
                    <div>
                        {this.state.selectedTab === 0 &&


                            <div>

                              
                                <div className="row">

                                    <div className="mb-1 col-md-3">Created:</div>
                                    <div className="mb-1 col-md-9"> {Helpers.getLocalDateTimeString(this.state.data.created)}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Name:</div>
                                    <div className="mb-1 col-md-9"> {(this.state.data.name)}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Birth Date:</div>
                                    <div className="mb-1 col-md-9"> {Helpers.getLocaleDateString(this.state.data.birthDate)}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Breed:</div>
                                    <div className="mb-1 col-md-9"> {(this.state.data.breed.name)}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Gender:</div>
                                    <div className="mb-1 col-md-9"> {getGender(this.state.data.gender)}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Microchip Number:</div>
                                    <div className="mb-1 col-md-9"> {this.state.data.microchipNumber}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Father Name:</div>
                                    <div className="mb-1 col-md-9"> {(this.state.data.fatherName)}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Mother Name:</div>
                                    <div className="mb-1 col-md-9"> {(this.state.data.motherName)}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Status:</div>
                                    <div className="mb-1 col-md-9"> {getStatus(this.state.data.status)}</div>

                                </div>

                                {this.state.data.imagePath &&
                                    <div>
                                        <br />
                                        <img className="img-container" src={this.state.data.imagePath} />
                                        <br />
                                    </div>
                                }
                                <br />
                                <h5>User</h5>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Username:</div>
                                    <div className="mb-1 col-md-9"> {(this.state.data.user.username)}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">First Name:</div>
                                    <div className="mb-1 col-md-9"> {(this.state.data.user.firstName)}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Last Name:</div>
                                    <div className="mb-1 col-md-9"> {(this.state.data.user.lastName)}</div>

                                </div>
                                {(Helpers.isUserAdmin(this.props.userState.user) || Helpers.isUserDogViewer(this.props.userState.user)) &&
                                    <Button style={{ border: "none", padding: 0, textDecoration: "none" }} color="link"
                                        onClick={() => {
                                            this.props.setOpen(true)
                                        }
                                        }>Contact User</Button>
                                }
                                <br />

                                {this.props.dataState.isOpen &&
                                    <ContactUserComponent open={this.props.dataState.isOpen}
                                        email={this.state.data.user.username} />
                                }


                                <br />
                                <h5>Pedigree and Passport</h5>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Pedigree in progress:</div>
                                    <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.state.data.pedigreeInProgress)}</div>

                                </div>
                                {this.state.data.pedigreeAbbreviation ? (
                                    <div className="row">

                                        <div className="mb-1 col-md-3">Pedigree Abbreviation:</div>
                                        <div className="mb-1 col-md-9"> {(this.state.data.pedigreeAbbreviation.abbrevation)} / {this.state.data.pedigreeNumber}</div>

                                    </div>
                                ) : <div className="row">

                                    <div className="mb-1 col-md-3">Pedigree Abbreviation:</div>
                                    <div className="mb-1 col-md-9"> /</div>

                                </div>

                                }
                                <div className="row">
                                    <div className="mb-1 col-md-3">No Pedigree Available:</div>
                                    <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.state.data.noPedigreeAvailable)}</div>
                                </div>

                                <div className="row">
                                    <div className="mb-1 col-md-3">Passport Country:</div>
                                    <div className="mb-1 col-md-9"> {(this.state.data.passportCountry ? this.state.data.passportCountry.name : "/")}</div>
                                </div>
                                <div className="row">
                                <div className="mb-1 col-md-3">Passport Number:</div>
                                <div className="mb-1 col-md-9"> {(this.state.data.passportNumber ? this.state.data.passportNumber : "/")}</div>

                                </div>
                                <br/>
                                <h5>Registrations</h5>
                                <br/>
                                {this.props.dataState.registrations.length < 1 ?
                                    <div style={{ color: "grey", display: "flex"}} className="cur-p">No
                                        registrations.</div>

                                    :
                                
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Show</th>
                                            <th scope="col">Class</th>
                                            <th scope="col">Catalog Number</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody className="dd-container">
                                        {
                                                this.props.dataState.registrations && this.props.dataState.registrations.map((item: any, index: any) => (
                                            <tr className={`${Helpers.getPublishedClass(item)}`}
                                                key={item.id}>
                                                <td className="cur-p">
                                                    {index + 1}
                                                        </td>
                                                        <td className="cur-p" style={{ width: "30%" }} >
                                                    {item.competitionDate.description}
                                                </td>
                                                        <td className="cur-p" style={{ width: "30%" }} >
                                                    {item.competitionClass.name}
                                                </td>
                                                  
                                                        <td className="cur-p" style={{ width: "30%" }}>
                                                    {item.catalogNumber}
                                                </td>
                                                
                                             


                                                
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                               
                                }
                                <br />
                                {Helpers.isUserInRole("", "", this.props.userState.user) &&
                                    <ActionHistory dataId={this.state.data && this.state.data.id} type="dog" />
                                }
                            
                          
                            </div>
                        }

                        {this.state.selectedTab === 2 &&
                            <div>
                                <h6>Owners</h6>
                                <div className="row">

                                    <div className="mb-1 col-md-3">User is owner:</div>
                                    <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.state.data.userIsOwner)}</div>

                                </div>
                                <br />

                                {this.state.data && this.state.data.userIsOwner &&
                                    <div >
                                        <div className="row">

                                            <div className="mb-1 col-md-3">First name:</div>
                                            <div className="mb-1 col-md-9"> {this.state.data.user.firstName && this.state.data.user.firstName}</div>

                                        </div>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">Last name:</div>
                                            <div className="mb-1 col-md-9"> {this.state.data.user.lastName && this.state.data.user.lastName}</div>

                                        </div>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">Email:</div>
                                            <div className="mb-1 col-md-9"> {this.state.data.user.username && this.state.data.user.username}</div>

                                        </div>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">Address:</div>
                                            <div className="mb-1 col-md-9"> {this.state.data.user.address && this.state.data.user.address}</div>

                                        </div>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">City:</div>
                                            <div className="mb-1 col-md-9"> {this.state.data.user.city && this.state.data.user.city}</div>

                                        </div>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">Phone number:</div>
                                            <div className="mb-1 col-md-9"> {this.state.data.user.phoneNumber && this.state.data.user.phoneNumber.prefix || ''} {this.state.data.user.phoneNumber && this.state.data.user.phoneNumber.number || ''}</div>

                                        </div>
                                  
                                        <div className="row">

                                            <div className="mb-1 col-md-3">Country:</div>
                                            <div className="mb-1 col-md-9"> {this.state.data.user.country && this.state.data.user.country.name}</div>

                                        </div>

                                        <hr />
                                    </div>

                                }

                                {this.state.data.owners.length > 0 ? this.state.data.owners.map((owner, index) =>
                                    <div key={index}>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">First name:</div>
                                            <div className="mb-1 col-md-9"> {owner.firstName}</div>

                                        </div>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">Last name:</div>
                                            <div className="mb-1 col-md-9"> {owner.lastName}</div>

                                        </div>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">Email:</div>
                                            <div className="mb-1 col-md-9"> {owner.email}</div>

                                        </div>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">Address:</div>
                                            <div className="mb-1 col-md-9"> {owner.address}</div>

                                        </div>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">City:</div>
                                            <div className="mb-1 col-md-9"> {owner.city}</div>

                                        </div>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">Phone number:</div>
                                            <div className="mb-1 col-md-9"> {owner.phoneNumber && owner.phoneNumber.prefix || ''} {owner.phoneNumber && owner.phoneNumber.number || ''}</div>

                                        </div>
                                        <div className="row">

                                            <div className="mb-1 col-md-3">Country:</div>
                                            <div className="mb-1 col-md-9"> {owner.country.name}</div>

                                        </div>
                                        
                                        <hr />
                                    </div>
                                ) : (!this.state.data.userIsOwner && <div> No owners listed</div>)}

                                
                            </div>
                        }

                        {this.state.selectedTab === 1 &&
                            <div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">First name:</div>
                                    <div className="mb-1 col-md-9"> {this.state.data.breeder.firstName}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Last name:</div>
                                    <div className="mb-1 col-md-9"> {this.state.data.breeder.lastName}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Address:</div>
                                    <div className="mb-1 col-md-9"> {this.state.data.breeder.address}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">City:</div>
                                    <div className="mb-1 col-md-9"> {this.state.data.breeder.city}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Is Kennel:</div>
                                    <div className="mb-1 col-md-9"> {Helpers.booleanToString(this.state.data.breeder.isKennel)}</div>

                                </div>
                                <div className="row">

                                    <div className="mb-1 col-md-3">Country:</div>
                                    <div className="mb-1 col-md-9"> {this.state.data.breeder.country && this.state.data.breeder.country.name}</div>

                                </div>
                                
                            </div>
                        }

                        {this.state.selectedTab === 3 &&
                            <div>
                                {this.state.data &&
                                    <div>
                                        <h6>Attachments</h6>
                                        <br />
                                        <AttachmentList
                                            attachments={this.state.data.attachments}
                                            parentId={this.state.data.id}
                                            onChange={(item: DogAttachment, remove: boolean) => { this.addRemoveAttachment(item, remove) }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        <br />
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {dataState: state.dogList, userState: state.user};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: Dog) => {
            dispatch(DogListStore.actionCreators.selectItem(data))
        },
        getItems: (page: number, name?: string, count?: number, profile?: any) => {
            dispatch(DogListStore.actionCreators.getItems(page, name, count, profile))
        },
        getDogRegistrations: (id: string) => {
            dispatch(DogListStore.actionCreators.getDogRegistrations(id))
        },
        setStatus: (status?: any) => {
            dispatch(DogListStore.actionCreators.setStatus(status))
        },
        setImageEditData: (image: any) => {
            dispatch(DialogStore.actionCreators.setImageEditData(image))
        },
        setOpen: (open?: any) => {
            dispatch(DogListStore.actionCreators.setOpen(open))
        },
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        showNotification: (data: DialogStore.NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DogDetails);
