import { Action, Reducer } from 'redux';
import { httpService } from '../index';
import {AppThunkAction} from './';
import {RegisteredUser, Role} from '../models/registered-user/RegisteredUser';
import {Country} from '../models/country/Country';
import {UserType} from "../models/registered-user/RegisteredUserEnums";

export interface RegisteredUserListState {
    selectedItem: RegisteredUser | null,
    list: Array<RegisteredUser>,
    countries: Array<Country>,
    isLoading: boolean,
    hasMore: boolean
    error: string,
    page: number,
    count: number,
    username: string,
    role: UserType | null,
    roles: Array<Role>
}

interface SelectRegisterdUserAction {
    type: 'SELECT_REGISTERED_USER';
    item: RegisteredUser;
}

interface RequestRegisterdUserAction {
    type: 'REQUEST_REGISTERED_USER';
}

interface RequestMoreUserAction {
    type: 'REQUEST_MORE_USERS';
 
}

interface ReceiveMoreUserAction {
    type: 'RECEIVE_MORE_USERS';
    items: Array<RegisteredUser>;
}

interface ReceiveRegisteredUserAction {
    type: 'RECEIVE_REGISTERED_USER';
    items: Array<RegisteredUser>;
    error: '';
}

interface ReceiveCountriesAction {
    type: 'RECEIVE_COUNTRIES';
    items: Array<Country>;
}

interface RequestRolesAction {
    type: 'REQUEST_ROLES';
}

interface ReceiveRolesAction {
    type: 'RECEIVE_ROLES';
    items: Array<Role>;
}

interface GetPageAction {
    type: 'GET_PAGE';

}

interface SetPageAction {
    type: 'SET_PAGE';
    page: 0;
}

interface GetNameAction {
    type: 'GET_NAME';

}

interface SetNameAction {
    type: 'SET_USERNAME';
    username: "";
}

interface SetRoleAction {
    type: 'SET_ROLE';
    role: null;
}

type KnownAction =
    SelectRegisterdUserAction
    | RequestRegisterdUserAction
    | ReceiveRegisteredUserAction
    | ReceiveMoreUserAction
    | RequestMoreUserAction
    | ReceiveCountriesAction
    | RequestRolesAction
    | ReceiveRolesAction
    | GetPageAction
    | SetPageAction
    | GetNameAction
    | SetNameAction
    | SetRoleAction;

export const actionCreators = {
    selectItem: (item: RegisteredUser): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'SELECT_REGISTERED_USER', item: item});
    },
    getItems: (page: number, username?: string, count?: number, usertype?: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.registeredUserList) {
            try {
                dispatch({type: 'REQUEST_REGISTERED_USER'});
                let data = await httpService.getRegisteredUsers({page, username, count, usertype});
                dispatch({type: 'RECEIVE_REGISTERED_USER', items: data, error: ''});

            } catch (err) {
                dispatch({type: 'RECEIVE_REGISTERED_USER', items: [], error: ''});
            }
        }
    },

    getMoreItems: (page: number, username?: string, count?: number, usertype?: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.registeredUserList) {
            try {
                dispatch({type: 'REQUEST_MORE_USERS'});
                let data = await httpService.getRegisteredUsers({page, username, count, usertype});
                dispatch({type: 'RECEIVE_MORE_USERS', items: data});

            } catch (err) {
                dispatch({type: 'RECEIVE_MORE_USERS', items: []});
            }
        }
    },

    getCountryItems: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.registeredUserList) {
            try {
                let data = await httpService.getCountries();
                dispatch({ type: 'RECEIVE_COUNTRIES', items: data });

            } catch (err) {
                dispatch({ type: 'RECEIVE_COUNTRIES', items: [] });
            }
        }
    },

    getAllRoles: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.registeredUserList) {
            try {
                dispatch({ type: 'REQUEST_ROLES' });
                let data = await httpService.getUserRoles();
                dispatch({ type: 'RECEIVE_ROLES', items: data });

            } catch (err) {
                dispatch({ type: 'RECEIVE_ROLES', items: [] });
            }
        }
    },

    setPage: (page?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.registeredUserList) {
            dispatch({type: 'SET_PAGE', page: page});


        }
    },

    setName: (name?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.registeredUserList) {
            dispatch({type: 'SET_USERNAME', username: name});


        }
    },
    setRole: (role?: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.registeredUserList) {
            dispatch({type: 'SET_ROLE', role: role});


        }
    }
}

const unloadedState: RegisteredUserListState = {
    selectedItem: null,
    isLoading: false,
    list: [],
    error: '',
    hasMore: true,
    countries: [],
    page: 0,
    count: 25,
    username: '',
    role: UserType.ALL,
    roles: []
};

export const reducer: Reducer<RegisteredUserListState> = (state: RegisteredUserListState | undefined, incomingAction: Action): RegisteredUserListState => {
    if (state === undefined) {
        return unloadedState;
    }

    let stateCopy = { ...state }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_REGISTERED_USER':

            stateCopy.selectedItem = action.item
            stateCopy.isLoading = false
            stateCopy.error = ''

            return stateCopy

        case 'REQUEST_REGISTERED_USER':

            stateCopy.list = []
            stateCopy.isLoading = true
            stateCopy.error = ''

            return stateCopy

        case 'REQUEST_MORE_USERS':

            stateCopy.isLoading = true
            stateCopy.error = ''

            return stateCopy

        case 'RECEIVE_REGISTERED_USER':

            stateCopy.hasMore = true
            let si = stateCopy.selectedItem;
            if (!stateCopy.count) {
                stateCopy.count = action.items.length
            }
            if (stateCopy.count > action.items.length) {
                stateCopy.hasMore = false;
            }

            if (action.items && action.items.length > 0 && !stateCopy.selectedItem) {
                si = action.items[0];
            }

            stateCopy.selectedItem = si
            stateCopy.list = action.items
            stateCopy.isLoading = false
            stateCopy.error = action.error

            return stateCopy

        case 'RECEIVE_MORE_USERS':

            let moreUsers: any = []
            let userList = [...state.list]

            let items = action.items
            if (stateCopy.count === 0) {
                stateCopy.count = items.length
            }

            if (action.items.length > 0) {
                moreUsers = [...userList, ...items]
            } else {
                moreUsers = userList;
            }

            let selit = stateCopy.selectedItem;

            if (stateCopy.count > items.length || items.length === 0) {
                stateCopy.hasMore = false
            }

            stateCopy.selectedItem = selit
            stateCopy.list = moreUsers
            stateCopy.isLoading = false
            stateCopy.error = ''

            return stateCopy

        case 'RECEIVE_COUNTRIES':

            stateCopy.countries = action.items
            return stateCopy

        case 'RECEIVE_ROLES':

            stateCopy.roles = action.items
            return stateCopy

        case 'SET_PAGE':

            stateCopy.page = action.page
            return stateCopy

        case 'SET_USERNAME':

            stateCopy.username = action.username
            return stateCopy

        case 'SET_ROLE':

            stateCopy.role = action.role
            return stateCopy
    }

    return state;
}
