import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { Sponsor } from '../../models/competition/Competition';
import { Helpers } from '../../utility/Helpers';
import FilePicker from '../FilePicker';
import { CompDateImage } from '../../models/competition/CompetitionDate';
import { httpService } from '../../index';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type CompDateImageDialogProps =
    DialogStore.DialogState &
    typeof DialogStore.actionCreators &
    React.ComponentProps<any>;

export type CompDateImageDialogData = {
    data: CompDateImage,
    callback: Function
}

class CompDateImageDialog extends React.PureComponent<CompDateImageDialogProps, { compDateImage: CompDateImage, showPopup: boolean, disableButton: boolean }> {

    time: any;

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.dataChanged();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.dialog.compDateImageData != prevProps.dialog.compDateImageData) {
            this.time = setTimeout(() => {
                this.setState({ showPopup: (this.props.dialog.compDateImageData !== null), compDateImage: (this.props.dialog.compDateImageData) ? this.props.dialog.compDateImageData.data : null, disableButton: false });
                this.clearTimeout();
            }, 0)
        }
    }

    componentWillUnmount() {
        this.clearTimeout();
    }

    private clearTimeout() {
        if (this.time) {
            clearTimeout(this.time);
            this.time = null;
        }
    }

    close(res: boolean) {
        this.time = setTimeout(() => {
            if (this.props.dialog.compDateImageData.callback && res === true) {
                this.setState({ disableButton: true })
                this.props.dialog.compDateImageData.callback(this.state.compDateImage);
                this.setState({ disableButton: false })
                return;
            } else {
                this.props.hideCompDateImageDialog();
                this.setState({ disableButton: false })
            }
        }, 500)

    }

    changeData(ev: any) {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let val = value;

        let at: any = { ...this.state.compDateImage };
        at[name] = val;

        this.setState({ compDateImage: at });
    }

    fileChanged(file: any) {
        let data = { ...this.state.compDateImage };

        if (data) {
            data.file = file.file;
            data.image = file.name;
            this.setState({ compDateImage: data });
        }
    }

    private async dataChanged() {

        this.setState({ showPopup: (this.props.dialog.compDateImageData !== null), compDateImage: (this.props.dialog.compDateImageData) ? this.props.dialog.compDateImageData.data : null, disableButton: false });

    }

    toggleDisable() {
        this.setState({ disableButton: !this.state.disableButton })
    }

    render() {
        return (
            <React.Fragment>
                {
                    (this.props.dialog.compDateImageData) &&
                    <><Modal isOpen={this.state.showPopup} toggle={() => { this.close(false); }} >
                        <ModalHeader toggle={() => { this.close(false); }}>{this.props.dialog.compDateImageData && this.props.dialog.compDateImageData.data.id ? "Edit Image" : "Add Image"}</ModalHeader>
                        <ModalBody>
                            {this.state.compDateImage && <div>
                                {this.state.compDateImage.image &&
                                    <div>

                                        {Helpers.isImagePath((this.state.compDateImage.file) ? this.state.compDateImage.file.name : this.state.compDateImage.image) && <img
                                            alt="compDateImage"
                                            src={this.state.compDateImage.image}
                                            style={{
                                                maxHeight: "400px",
                                                maxWidth: "300px"
                                            }} />}

                                        {Helpers.isImagePath((this.state.compDateImage.file) ? this.state.compDateImage.file.name : this.state.compDateImage.image) === false && <span>
                                            {Helpers.getAttachmentName((this.state.compDateImage.file) ? this.state.compDateImage.file.name : this.state.compDateImage.image)}
                                        </span>}

                                    </div>}
                                <br />
                                <label className="btn btn-primary btn-sm btn-color">CHOOSE A FILE
                                    <FilePicker onChange={(item: any) => { this.fileChanged(item); }}></FilePicker>
                                </label>
                                <br />
                                <br />


                                <div className="mb-3">
                                    <div className="checkbox checkbox-circle checkbox-info peers">
                                        <input id="useOnStartNumbers" name="useOnStartNumbers" type="checkbox"
                                            defaultChecked={this.state.compDateImage && this.state.compDateImage.useOnStartNumbers} className="peer"
                                            onChange={(e) => this.changeData(e)} />
                                        <label className="form-label peers peer-greed js-sb ai-c" htmlFor="useOnStartNumbers">
                                            <span className="peer peer-greed">Use In Generation</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="orderOnStartNumbers">Order on Start Number</label>
                                    <input id="orderOnStartNumbers" name="orderOnStartNumbers" className="form-control"
                                        defaultValue={this.state.compDateImage && this.state.compDateImage.orderOnStartNumbers} onChange={(e) => this.changeData(e)}
                                        maxLength={2000} />
                                </div>
                            </div>}
                        </ModalBody>
                        <ModalFooter>

                            <button type="button" className="btn btn-secondary" onClick={() => { this.close(false); }} data-bs-dismiss="modal">Cancel</button>
                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                <button disabled={this.state.disableButton} type="button" className="btn btn-primary" onClick={() => {
                                    this.close(true);
                                }}>Save</button>}

                        </ModalFooter>


                    </Modal>
                    </>
                }
            </React.Fragment>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { dialog: state.dialog, state: state.competitionList, userState: state.user } },
    DialogStore.actionCreators
)(CompDateImageDialog)