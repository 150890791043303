import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';
import * as DialogStore from './DialogStore';
import * as ProfileStore from './ProfileStore';
import * as CompetitionListStore from './CompetitionListStore';
import * as DogListStore from './DogListStore';
import * as CountryStore from './CountryStore';
import * as BreedStore from './BreedStore';
import * as BreedCategoryStore from './BreedCategoryStore';
import * as RegisteredUserStore from './RegisteredUserStore';
import * as PedigreeAbbreviationStore from './PedigreeAbbreviationStore';
import * as CompetitionClassStore from "./CompetitionClassStore";
import * as HelpersStore from "./HelpersStore";
import * as InvoiceInfoStore from './InvoiceInfoStore';
import * as InvoiceIssuerStore from './InvoiceIssuerStore';
import * as NotificationStore from './NotificationStore';
import * as StripeAccountStore from './StripeAccountStore';

// The top-level state object
export interface ApplicationState {
    dialog: DialogStore.DialogState | undefined;
    counter: Counter.CounterState | undefined;
    user: ProfileStore.ProfileState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    competitionList: CompetitionListStore.CompetitionListState | undefined;
    dogList: DogListStore.DogListState | undefined;
    countryList: CountryStore.CountryListState | undefined;
    registeredUserList: RegisteredUserStore.RegisteredUserListState | undefined
    breedList: BreedStore.BreedListState | undefined
    breedCatList: BreedCategoryStore.BreedCategoryListState | undefined
    pedigreeList: PedigreeAbbreviationStore.PedigreeListState | undefined
    competitionClassList: CompetitionClassStore.CompetitionClassListState | undefined
    helpersList: HelpersStore.HelpersState | undefined,
    invoiceInfoList: InvoiceInfoStore.InvoiceInfoListState | undefined,
    invoiceIssuerList: InvoiceIssuerStore.InvoiceIssuerListState | undefined
    notificationList: NotificationStore.NotificationState | undefined,
    stripeAccountList:  StripeAccountStore.StripeListState | undefined
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    dialog: DialogStore.reducer,
    user: ProfileStore.reducer,
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    competitionList: CompetitionListStore.reducer,
    dogList: DogListStore.reducer,
    countryList: CountryStore.reducer,
    registeredUserList: RegisteredUserStore.reducer,
    breedList: BreedStore.reducer,
    breedCatList: BreedCategoryStore.reducer,
    pedigreeList: PedigreeAbbreviationStore.reducer,
    competitionClassList: CompetitionClassStore.reducer,
    helpersList: HelpersStore.reducer,
    invoiceInfoList: InvoiceInfoStore.reducer,
    invoiceIssuerList: InvoiceIssuerStore.reducer,
    notificationList: NotificationStore.reducer,
    stripeAccountList: StripeAccountStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
