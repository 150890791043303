import * as React from 'react';
import { connect } from 'react-redux';
import ContentStatus from '../../components/ContentStatus';

import { history, httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as DialogStore from '../../store/DialogStore';
import * as DogListStore from '../../store/DogListStore';
import { DetailsBase } from '../base/DetailsBase';

import classnames from "classnames";
import {
    Button,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import Autocomplete from "../../components/Autocomplete";
import BreedSearch from '../../components/BreedSearch';
import CountrySearch from '../../components/CountrySearch';
import ImagePicker, { PictureWarningLevel } from '../../components/ImagePicker';
import PedigreeAbbreviationSearch from '../../components/PedigreeAbbreviationSearch';
import { Country, OwnerBreederCountry } from '../../models/country/Country';
import { Dog } from '../../models/dog/Dog';
import { DogGender, DogStatus, getDogGender } from '../../models/dog/DogEnums';
import { Profile } from '../../models/registered-user/RegisteredUser';
import { DialogType, NotificationType } from '../../store/DialogStore';
import { Helpers } from '../../utility/Helpers';
import ProfileSearch from '../../components/ProfileSearch';
import DateTimePicker from '../../components/DateTimePicker';

class DogEdit extends DetailsBase<Dog> {

    codeError: string;
    propsForTrim: Array<string>;
    hideOwner: boolean;
    showUsers: Array<string>;
    imageFile?: File | null;
    deletedImage: string;

    constructor(props: any) {
        super(props);

        this.codeError = '';
        this.hideOwner = false
        this.imageFile = null;
        this.deletedImage = '';
        this.propsForTrim = ['name', 'pedigreeNumber', 'motherName', 'fatherName'];
        this.showUsers = [];

        this.getItem = async (id: string) => {
            return await httpService.getDog(id)
        }

        this.saveItem = async (data: Dog) => {
            data.attachments = [];
            return await httpService.dogAddOrUpdate(data)
        }

        this.redirectAfterSave = (id: string) => {
            history.replace(`/dog/details/${id}`)
        }

        this.hideOwner = false
        this.showUsers = []

        this.emptyData = {
            name: '',
            birthDate: new Date(),
            gender: DogGender.MALE,
            userIsOwner: false,
            imagePath: '',
            breeder: {
                firstName: '',
                lastName: '',
                address: '',
                city: '',
                isKennel: false,
                country: { id: '', name: '', created: new Date(), phonePrefix: "" },
                created: new Date()
            },
            breed: { id: '', name: '', fci: 0, created: new Date() },
            status: DogStatus.UNCONFIRMED,
            created: new Date(),
            pedigreeNumber: '',
            pedigreeInProgress: false,
            pedigreeAbbreviation: {
                abbrevation: "",
                country: "",
                id: "",
                created: new Date()
            },
            fatherName: '',
            motherName: '',
            owners: [],
            attachments: [],
            user: { firstName: '', lastName: '', username: '' },
            microchipNumber: '',
            noPedigreeAvailable: false,
            passportNumber: '',
            passportCountry: { id: '', name: '', created: new Date(), phonePrefix: "" },
        }
    }

    componentDidMount() {
        this.props.getFilteredUsers("")
        super.componentDidMount();
    }

    componentDidUpdate(prevState: any) {
        let {itemId} = this.props.match.params;

        if (this.itemId !== itemId) {
            this.deletedImage = '';
            this.imageFile = null;
        }

        super.componentDidUpdate(prevState);
    }

    async saveChanges(data: any) {
        if (data) {
            try {
                this.setState({ disableButton: true })
                let item = await this.saveItem(data);

                if (typeof item != "undefined") {
                    this.setState({ data: item });
                    this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });
                    this.props.getItems(0, this.props.dogState.name, this.props.dogState.count, this.props.dogState.profile)
                    this.redirectAfterSave(item.id);
                    
                }
                this.setState({ disableButton: false })

                return item;
            } catch (err) {
                alert(err.message);
                this.setState({ disableButton: false })

            }
        }
        return null;
    }


    onSubmit = async (ev: any) => {
        ev.preventDefault();

        let data: any = {...this.state.data};

        let propsData: any = {...this.props.dogState}

        if (propsData.selectedUser) {
            data.user = {
                firstName: propsData.selectedUser.firstName,
                lastName: propsData.selectedUser.lastName,
                username: propsData.selectedUser.userName,
                id: propsData.selectedUser.id
            }
            this.setState({
                data: data
            })

        }

        data.birthDate = Helpers.getLocalISODate(data.birthDate);

        if (!data.owners) {
            data.owners = []
            this.setState({
                data: data
            })
        } 

        else if (data.owners.length > 0 && data.userIsOwner && data.owners[0].firstName === "") {
            data.owners = []
            this.setState({
                data: data
            })
        }


        this.trimProps(this.propsForTrim, data);

        //delete image
        if (this.state.data && this.state.data.id != undefined && this.deletedImage !== '') {
            this.props.increment();
            try {
                await httpService.dogImageDelete(this.state.data.id);
                this.state.data.imagePath = '';
                this.props.decrement();
            } catch (err) {
                this.props.decrement();
                alert(err.message);
                return;
            }
        }

        let result: any = await this.saveChanges(data as Dog);

        if (!result) {
            return;
        }

        //upload image
        if (this.imageFile) {
            this.props.increment();
            try {
                await httpService.dogImageUpload(this.imageFile, (this.state.data && this.state.data.id) ? this.state.data.id : '');
                this.props.decrement();
            } catch (err) {
                this.props.decrement();
                alert(err.message);
                return;
            }
        }

        if (!this.state.isLoading && !this.state.error && this.state.data && this.state.data.id) {
            history.replace(`/dog/details/${this.state.data.id}`);
        }
    }

    keyExists = (key: any) => {
        if (this.state.data && key in this.state.data) {
            return true
        }
        return false
    }

    changeData = (ev: any, index: number, object?: string) => {
        const target = object !== "user" && ev.target;
        const value = object !== "user" && target.type === 'checkbox' ? target.checked : target.value;


        let val: any = object !== "user" && value;
        let data: any = {...this.state.data};

        if (target.name === 'gender') {
            val = parseInt(value);
        }

        if (object === "breeder") {
            data.breeder[target.name] = val
        }
        if (target.name === "userIsOwner" && val === true) {
            data[target.name] = val;
           

        } else if (target.name === "userIsOwner" && val === false) {
            data[target.name] = val;
            
        }

        if (target.name === 'microchipNumber') {
            data[target.name] = val;
        }

        if (target.name === 'passportNumber') {
            data[target.name] = val;
        }
        
        
        if (target.name in data) {
            data[target.name] = val;
        }

        this.whitespaceTrimProps(this.propsForTrim, data, target.name);

        this.setState({
            data: data
        })

    }

    changeAbbreviation(item: any) {
        let data: any = { ...this.state.data };
        data.pedigreeAbbreviation = item;
        this.setState({ data: data })

    }

    changeBreed(item: any) {
        let data: any = { ...this.state.data };
        data.breed = item;
        this.setState({ data: data})
        
    }

    changeOwnerCountry(item: any, index: any) {
        let data: any = { ...this.state.data };
        data.owners[index].country = item;
        this.setState({ data: data })

    }

    changeBreederCountry(item: any) {
        let data: any = { ...this.state.data };
        data.breeder.country = item;
        this.setState({ data: data })

    }

    changeProfile = async (ev: any) => {

        let data: any = {...this.state.data}
        let userVal: any;
        userVal = this.props.dogState.users.find((i: any) => i.userName === ev);
        if (userVal) {
            let user = await httpService.getRegisteredUser(userVal.id)
            data.user = user;
            this.setState({ data: data });
        }
       
    }


    dateChange = (res: any) => {
        let data: any = {...this.state.data};
        data[res.name] = res.value;
        this.setState({
            data: data
        })
    }

    onImagesChanged = (images: Array<any>) => {
        let data: any = {...this.state.data};
        data.attachments = [...images, data.attachments];
    }

    onDogImageChanged = (fd: any) => {
        let data: any = { ...this.state.data };
        data.imagePath = fd.url;
        this.imageFile = fd.file;
        this.setState({ data: data });
    }

    deleteImage() {
        if (!this.imageFile && this.state.data && !this.state.data.imagePath) {
            return;
        }

        this.props.showDialog(`Are you sure you want to delete dog image?`, DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {
                if (!this.imageFile && this.state.data && this.state.data.imagePath) {
                    this.deletedImage = this.state.data.imagePath;
                }

                this.imageFile = null;
                let data: any = { ...this.state.data };
                data.imagePath = null;
                this.setState({ data: data });
            }
        })
    }

    addOwner = () => {
        let data: any = {...this.state.data};
        // @ts-ignore
        if (!data.owners) {
            data.owners = [];
        }
        // @ts-ignore
        data.owners = [...data.owners, {
            firstName: '',
            lastName: '',
            kennel: '',
            email: '',
            phoneNumber: { prefix: '', number: '' },
            country: { id: '', name: '', created: new Date(), phonePrefix: '' },
            created: new Date(),
            address: '',
            city: ''
        }]
        this.setState({
            data: data
        })
    }

    changeCountry(item: any) {
        let data: any = { ...this.state.data };
        data.passportCountry = item;
        this.setState({ data: data })
    }

    removeOwner = (index: number) => {
        let data: any = {...this.state.data};
        data.owners.splice(index, 1)
        if (data.owners.length < 1) {
            delete data.owners
        }
        this.setState({
            data: data
        })
    }

    changeOwner = (ev: any, index: any) => {
        const target = ev.target;
        const val = target.type === 'checkbox' ? target.checked : target.value;

        let data: any = { ...this.state.data };


        if (target.name === 'country') {
            let value = this.props.dogState.countries.find((i: Country) => i.id === val);
            data.owners[index!].country = value
            this.setState({
                data: data
            })
        } else if (target.name === "prefix") {
            if (!data.owners[index].phoneNumber) {
                data.owners[index].phoneNumber = {}
            }
            data.owners[index].phoneNumber[target.name] = val
            this.setState({
                data: data
            })
        } else if (target.name === "number") {
            if (!data.owners[index].phoneNumber) {
                data.owners[index].phoneNumber = {}
            }
            data.owners[index].phoneNumber[target.name] = val
            this.setState({
                data: data
            })
        } else {
            data.owners[index][target.name] = val
            this.setState({
                data: data
            })
        }
        
    }

    ownerButtonVisible = (data: any) => {
        if (data.userIsOwner && data.owners && data.owners.length < 1) {
            return true
        } else if (!data.userIsOwner && data.owners && data.owners.length < 2) {
            return true
        } else if (data.userIsOwner && !data.owners) {
            return true
        } else if (!data.userIsOwner && !data.owners) {
            return true
        }
    }


    render() {
        // @ts-ignore
        return (
            <div>
                <h4 className="c-grey-900">{this.state.data && this.state.data.id ? "Dog Edit" : "Dog Add"}</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                               data={this.state.data}></ContentStatus>

                <Nav tabs style={{marginBottom: "20px", marginTop: "10px", cursor: "pointer"}}>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.selectedTab === 0})}
                            onClick={() => {
                                this.setState({selectedTab: 0})
                            }}>

                            Details
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.selectedTab === 2})}
                            onClick={() => {
                                this.setState({selectedTab: 2})
                            }}
                        >
                            Owner
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.selectedTab === 1})}
                            onClick={() => {
                                this.setState({selectedTab: 1})
                            }}
                        >
                            Breeder
                        </NavLink>
                    </NavItem>

                </Nav>

                {(this.state && this.state.data) &&
                    <form onSubmit={this.onSubmit}>
                        {this.state.selectedTab === 0 &&
                            <div>
                                <div className="row">
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="name">Name *</label>
                                        <input id="name" name="name" required className="form-control"
                                               defaultValue={this.state.data.name} onChange={(e) => this.changeData(e, 0)}
                                               maxLength={2000}/>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="birthDate">Birth Date *</label>
                                        <DateTimePicker name="birthDate" minDate=''
                                                        value={Helpers.getIsoDate(this.state.data.birthDate)}
                                                        maxDate={Helpers.getIsoDate(new Date())}
                                                        onChange={(e: any) => {
                                                            this.dateChange(e);

                                                        }}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="gender">Gender *</label>
                                        <select id="gender" name="gender" required className="form-control"
                                            defaultValue={this.state.data.gender} onChange={(e) => this.changeData(e, 0)}>
                                            {getDogGender().map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="microchipNumber">Microchip Number *</label>
                                        <input id="microchipNumber" name="microchipNumber" required className="form-control"
                                            defaultValue={this.state.data.microchipNumber} onChange={(e) => this.changeData(e, 0)}
                                            maxLength={2000} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="pedigreeAbbreviation">Pedigree Abbreviation {(!this.state.data.noPedigreeAvailable && !this.state.data.pedigreeInProgress) && <span>*</span>}</label>
                                        <PedigreeAbbreviationSearch action={(item: any) => this.changeAbbreviation(item)} data={this.state.data}
                                            suggestions={this.props.dogState.pedigreeAbbreviations} />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="pedigreeNumber">Pedigree Number {(!this.state.data.noPedigreeAvailable && !this.state.data.pedigreeInProgress) && <span>*</span>}</label>
                                        <input id="pedigreeNumber" name="pedigreeNumber"
                                                required={(!this.state.data.noPedigreeAvailable && !this.state.data.pedigreeInProgress)}
                                                className="form-control"
                                                defaultValue={this.state.data.pedigreeNumber}
                                            onChange={(e) => this.changeData(e, 0)} maxLength={2000}/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="passportCountry">Passport Country {this.state.data.noPedigreeAvailable && <span>*</span>}</label>
                                        <CountrySearch required={this.state.data.noPedigreeAvailable} action={(item: any) => this.changeCountry(item)} data={this.state.data}
                                        suggestions={this.props.dogState.countries} />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="passportNumber">Passport number {this.state.data.noPedigreeAvailable && <span>*</span>}</label>
                                        <input id="passportNumber" name="passportNumber" required={this.state.data.noPedigreeAvailable} className="form-control"
                                            defaultValue={this.state.data.passportNumber} onChange={(e) => this.changeData(e, 0)}
                                            maxLength={2000} />
                                    </div>
                                </div>

                                <div className="mb-3 col-md-3">
                                    <div style={{ zIndex: 0 }} className="mb-3 checkbox checkbox-info peers ai-c">
                                        <input id="pedigreeInProgress" name="pedigreeInProgress" type="checkbox"
                                                checked={this.state.data.pedigreeInProgress} className="peer"
                                            onChange={(e) => this.changeData(e, 0)}/>

                                        <label className="form-label peers peer-greed js-sb ai-c" htmlFor="pedigreeInProgress">
                                            <span className="peer peer-greed">Pedigree In Progress</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="mb-3 col-md-3 ">
                                    <div style={{ zIndex: 0 }} className="mb-3 checkbox checkbox-info peers ai-c">
                                        <input id="noPedigreeAvailable" name="noPedigreeAvailable" type="checkbox"
                                                checked={this.state.data.noPedigreeAvailable} className="peer"
                                            onChange={(e) => this.changeData(e, 0)}/>

                                        <label className="form-label peers peer-greed js-sb ai-c" htmlFor="noPedigreeAvailable">
                                            <span className="peer peer-greed">No Pedigree Available</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="breed">Breed *</label>
                                        <BreedSearch action={(item: any) => this.changeBreed(item)} data={this.state.data}
                                            suggestions={this.props.dogState.webBreeds} />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="motherName">Mother Name *</label>
                                        <input id="motherName" name="motherName" required className="form-control"
                                            defaultValue={this.state.data.motherName} onChange={(e) => this.changeData(e, 0)}
                                               maxLength={2000}/>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="fatherName">Father Name *</label>
                                        <input id="fatherName" name="fatherName" required className="form-control"
                                            defaultValue={this.state.data.fatherName} onChange={(e) => this.changeData(e, 0)}
                                               maxLength={2000}/>
                                    </div>

                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="user">User *</label>

                                        <Autocomplete action={this.changeProfile} data={this.state.data}
                                            suggestions={this.props.dogState.users} />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-12">
                                        <label className="form-label">Image</label>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.deleteImage()
                                        }}>
                                            <div style={{paddingTop: "5px", marginLeft: "5px"}}
                                                 className="bgc-blue-50 c-blue-500 circle-add">
                                                <span className="icon-holder" style={{fontSize: "16px"}}>
                                                    <i className="c-light-blue-500 ti-trash"></i>
                                                </span>
                                            </div>
                                        </a>
                                        <ImagePicker onChange={(fd: any) => { this.onDogImageChanged(fd) }} src={this.state.data.imagePath} width="200px" height="200px"
                                            required={false} warningLevel={PictureWarningLevel.Light} />
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.selectedTab === 2 &&
                            <div>

                                {this.ownerButtonVisible(this.state.data) &&
                                    <div style={{
                                        width: "fit-content",
                                        verticalAlign: "middle",
                                        float: "right",
                                    }}>
                                        <Button onClick={() => this.addOwner()}>Add Owner</Button>
                                    </div>
                                }

                                <div className="row">
                                    <div className="mb-3 col-md-3 ">
                                        <div className="mb-3 checkbox checkbox-info peers ai-c">
                                            <input id="userIsOwner" name="userIsOwner" type="checkbox"
                                                   checked={this.state.data!.userIsOwner} className="peer"
                                                onChange={(e) => this.changeData(e, 0)}/>

                                            <label className="form-label peers peer-greed js-sb ai-c"
                                                   htmlFor="userIsOwner">
                                                <span className="peer peer-greed">User Is Owner</span></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.selectedTab === 2 && this.state.data.owners && this.state.data.owners.map((owner: any, index: number) =>
                            <div>
                                <div key={index} className="row">
                                    <div>
                                        <a style={{ float: "right" }} href="##" onClick={(e) => {
                                            e.preventDefault()
                                            this.removeOwner(index)
                                        }}>
                                            <span style={{ fontSize: "16px" }} className="icon-holder"><i
                                                className="c-light-blue-500 ti-trash"></i></span>
                                        </a>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="firstName">First Name *</label>
                                        <input id={`firstName_${index}`} name="firstName" required className="form-control"
                                            value={owner.firstName} onChange={(e) => {
                                                this.changeOwner(e, index)
                                            }} maxLength={2000} />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="lastName">Last Name *</label>
                                        <input id={`lastName_{index}`} name="lastName" required className="form-control"
                                            value={owner.lastName} onChange={(e) => {
                                                this.changeOwner(e, index)
                                            }} maxLength={2000} />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="email">Email *</label>
                                        <input type="email" id={`email_${index}`} name="email" required className="form-control"
                                            value={owner.email} onChange={(e) => {
                                                this.changeOwner(e, index)
                                            }} maxLength={2000} />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="address">Address *</label>
                                        <input id={`address_${index}`} name="address" required className="form-control"
                                            value={owner.address} onChange={(e) => {
                                                this.changeOwner(e, index)
                                            }} maxLength={2000} />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="city">City *</label>
                                        <input id={`city_${index}`} name="city" required className="form-control"
                                            value={owner.city} onChange={(e) => {
                                                this.changeOwner(e, index)
                                            }} maxLength={2000} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="prefix">Prefix *</label>
                                        <select required id={`prefix_${index}`} name="prefix" className="form-control"
                                            value={owner.phoneNumber && owner.phoneNumber.prefix}
                                            onChange={(e) => {
                                                this.changeOwner(e, index)
                                            }}>
                                            <option value="">-- select phone prefix --</option>
                                            {this.props.dogState.countries.map((item: OwnerBreederCountry, index: number) => (
                                                <option key={index}
                                                    value={item.phonePrefix!}>{item.phonePrefix!}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="number">Phone Number *</label>
                                        <input id={`number_${index}`} name="number" className="form-control"
                                            value={owner.phoneNumber && owner.phoneNumber.number || ""}
                                            onChange={(e) => {
                                                this.changeOwner(e, index)
                                            }} maxLength={2000} />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="kennel">Kennel</label>
                                        <input id={`kennel_${index}`} name="kennel" className="form-control"
                                            value={owner.kennel} onChange={(e) => {
                                                this.changeOwner(e, index)
                                            }} maxLength={2000} />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="country">Country *</label>
                                    <CountrySearch action={(item: any) => this.changeOwnerCountry(item, index)} data={this.state.data && this.state.data.owners[index]}
                                        suggestions={this.props.dogState.countries} />
                                </div>
                                <hr />

                            </div>
                        )}

                        {this.state.selectedTab === 1 &&
                            <div>
                                <div className="mb-3 col-md-3 ">
                                    <div className="mb-3 checkbox checkbox-info peers ai-c">
                                        <input id="isKennel" name="isKennel" type="checkbox"
                                               checked={this.state.data.breeder.isKennel} className="peer"
                                               onChange={(e) => {
                                                   this.changeData(e, 0, "breeder")
                                               }}/>

                                        <label className="form-label peers peer-greed js-sb ai-c"
                                               htmlFor="isKennel">
                                            <span className="peer peer-greed">Breeder Is Kennel</span></label>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="firstName">First Name *</label>
                                        <input id="firstName" name="firstName" required className="form-control"
                                               defaultValue={this.state.data.breeder.firstName} onChange={(e) => {
                                            this.changeData(e, 0, "breeder")
                                        }} maxLength={2000}/>
                                    </div>
                                    {!this.state.data.breeder.isKennel && <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="lastName">Last Name *</label>
                                        <input id="lastName" name="lastName" required className="form-control"
                                               defaultValue={this.state.data.breeder.lastName} onChange={(e) => {
                                            this.changeData(e, 0, "breeder")
                                        }} maxLength={2000}/>
                                    </div>}

                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="address">Address *</label>
                                        <input id="address" name="address" required className="form-control"
                                               defaultValue={this.state.data.breeder.address} onChange={(e) => {
                                            this.changeData(e, 0, "breeder")
                                        }} maxLength={2000}/>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="city">City *</label>
                                        <input id="city" name="city" required className="form-control"
                                               defaultValue={this.state.data.breeder.city} onChange={(e) => {
                                            this.changeData(e, 0, "breeder")
                                        }} maxLength={2000}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="country">Country *</label>
                                        <CountrySearch action={(item: any) => this.changeBreederCountry(item)} data={this.state.data && this.state.data.breeder}
                                            suggestions={this.props.dogState.countries} />
                                    </div>
                                </div>
                            </div>
                        }

                        <br/>
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>

                    </form>

                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {dogState: state.dogList};
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: Dog) => {
            dispatch(DogListStore.actionCreators.selectItem(data))
        },
        getItems: (page: number, name?: string, count?: number, profile?: any) => {
            dispatch(DogListStore.actionCreators.getItems(page, name, count, profile))
        },
        getUsers: (page: number, username?: string, count?: number, usertype?: number) => {
            dispatch(DogListStore.actionCreators.getUsers(page, username, count, usertype))
        },
        getFilteredUsers: (name?: string) => {
            dispatch(DogListStore.actionCreators.getFilteredUsers(name))
        },
        getSingleUser: (id: string) => {
            dispatch(DogListStore.actionCreators.getSingleUser(id))
        },
        setImageEditData: (image: any) => {
            dispatch(DialogStore.actionCreators.setImageEditData(image))
        },
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        showNotification: (data: DialogStore.NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DogEdit)
