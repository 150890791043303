import React, { Component, Fragment } from "react";


type defaultProps = {
    suggestions: [],
    data: any,
    action?: any,
    disabled: boolean

};

class DogSearch extends Component<any> {
    timer: any;

    constructor(props: any) {
        super(props);

    }

    state = {
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: this.props.suggestions,
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // What the user has entered
        userInput: this.props.data && this.props.data.name ? this.props.data.name + " - " + this.props.data.breed.name : '-- select dog --',
        // The current value of the input field
        inputValue: this.props.data && this.props.data.name ? this.props.data.name + " - " + this.props.data.breed.name : '-- select dog --',
        gender: this.props.data && this.props.data.gender
    };
    

    handleChange = (e: any) => {
        const userInput = e.currentTarget.value;
        const filteredSuggestions = this.props.suggestions.filter((dog: any) => dog.name.toUpperCase().includes(userInput.toUpperCase()));

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: filteredSuggestions,
            showSuggestions: true,
            userInput: userInput,
            inputValue: userInput
        });

    };

    handleClick = (e: any, id?: any) => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: this.props.suggestions,
            showSuggestions: !this.state.showSuggestions
        });

        if (e.currentTarget.innerText !== "" && e.currentTarget.innerText !== "-- select dog --") {
            let input = this.props.suggestions.find((dog: any) => dog.id === id);
            this.props.action(input, input.id, input.gender)
            this.setState({
                userInput: input.name + " - " + input.breed.name,
                inputValue: input.name + " - " + input.breed.name
            });
        } else if (e.currentTarget.innerText === "-- select dog --") {
            let data = {...this.props.data};
            let gender = data.gender;
            this.props.action("empty", '', gender)
            this.setState({
                userInput: '-- select dog --',
                inputValue: '-- select dog --'
            });
        }

    };

    render() {
   
        let suggestionsListComponent;

        if (this.state.showSuggestions) {
            if (this.state.filteredSuggestions && this.state.filteredSuggestions.length > 0) {
                suggestionsListComponent = (
                    <div style={{ position: 'relative' }}>
                        <ul style={{ position: 'absolute', top: '100%', left: 0, background: "white", zIndex: 100 }} className="suggestions">
                            <li value="empty" onClick={(e: any) => this.handleClick(e)}>-- select dog --</li>
                            {this.state.filteredSuggestions.map((suggestion: any, index: any) => {
                                let className;

                                // Flag the active suggestion with a class
                                if (index === this.state.activeSuggestion) {
                                    className = "suggestion-active";
                                }

                                return (
                                    <li className={className} key={suggestion.id} onClick={(e: any) => this.handleClick(e, suggestion.id)}>
                                        {suggestion.name} - {suggestion.breed.name}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions.</em>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <input
                    id="dogSearch"
                    name="dogSearch"
                    required
                    className="form-control"
                    type="text"
                    onClick={(e: any) => this.handleClick(e, e.target.dataset.key)}
                    onChange={(e: any) => this.handleChange(e)}
                   
                    autoComplete="off"
                    disabled={this.props.disabled}
                    value={this.state.userInput}
                />
                {suggestionsListComponent}
                {this.props.data && this.props.data.name && this.props.data.name &&
                    <input
                        type="hidden"
                        name="dogSearchHidden"
                        value={this.props.data && this.props.data.name + " - " + this.props.data.breed.name}
                    />
                }
            </Fragment>
        );
    }
}

export default (DogSearch)
