import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as DialogStore from '../../store/DialogStore';
import * as CountryStore from '../../store/CountryStore';
import { Country } from '../../models/country/Country';
import { Link } from 'react-router-dom';
import { ListBase } from '../base/ListBase';
import { Helpers } from '../../utility/Helpers';
import ContentStatus from '../../components/ContentStatus';
import CountryEdit from './CountryEdit';
import CountryDetails from './CountryDetails';


class CountryList extends ListBase {

    constructor(props: any, context: any) {
        super(props, context, 'country');
        this.deleteItemCall = async (id: string) => { return await httpService.countryDelete(id) };
    }

    public componentDidMount() {
        super.componentDidMount();

    }

    render() {
        return (
            <div className="row gap-20 masonry pos-r">
                <div className="masonry-item col-md-5">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <h4 className="c-grey-900 mB-20" style={{ float: "left" }}>Country List</h4>
                        <div style={{ float: "right", verticalAlign: "middle" }}>
                            <Link to='/country/add'>
                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                            </Link>
                        </div>

                        <div style={{ clear: "both" }} />

                        <ContentStatus isLoading={this.props.dataState.isLoading} errorMessage={this.props.dataState.error} data={this.props.dataState.list && this.props.dataState.list.length > 0}></ContentStatus>
                        {(!this.props.dataState.isLoading && !this.props.dataState.error && this.props.dataState.list && this.props.dataState.list.length > 0) &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                 
                                        <th scope="col" className="ta-r mw-actions">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="dd-container" ref={this.dragulaDecorator}>
                                    {this.props.dataState.list.map((country: any, index: number) => (
                                        <tr className={`${this.getActiveClass(country)} ${Helpers.getPublishedClass(country)}`} key={country.id}>
                                            <td className="cur-p" onClick={() => { this.showDetails(index) }}>
                                                {country.name}
                                            </td>
                                            
                                            <td className="ta-r">
                                                <Link to={`/country/edit/${country.id}`}>
                                                    <span style={{ fontSize: "16px" }} className="icon-holder"><i className="c-light-blue-500 ti-pencil"></i></span>
                                                </Link>
                                                <span style={{ fontSize: "16px" }}> | </span>
                                                <a href="#" onClick={(e) => { e.preventDefault(); this.deleteItem(index, country.name) }}>
                                                    <span style={{ fontSize: "16px" }} className="icon-holder"><i className="c-light-blue-500 ti-trash"></i></span>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>}

                    </div>
                </div>
                <div className="masonry-item col-md-7">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <Route exact path='/country/details/:itemId?' component={CountryDetails} />
                        <Route exact path='/country/edit/:itemId?' component={CountryEdit} />
                        <Route exact path='/country/add' component={CountryEdit} />
                    </div>
                </div>
            </div>)
    }
};

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.countryList, userState: state.user };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) },
        selectItem: (data: Country) => { dispatch(CountryStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(CountryStore.actionCreators.getItems()) },
        reorderItem: (id: string, pos?: number) => { dispatch(CountryStore.actionCreators.reorderItem(id, pos)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CountryList);
