import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as DialogStore from '../../store/DialogStore';
import * as BreedCategoryListStore from '../../store/BreedCategoryStore';
import { ListBase } from '../base/ListBase';
import { Helpers } from '../../utility/Helpers';
import ContentStatus from '../../components/ContentStatus';
import { BreedCategory } from '../../models/dog/Breed';
import { Link } from 'react-router-dom';
import BreedCategoryDetails from './BreedCategoryDetails';
import BreedCategoryEdit from './BreedCategoryEdit';




class BreedCategoryList extends ListBase {

    constructor(props: any, context: any) {
        super(props, context, 'fci-category');
        this.deleteItemCall = async (id: string) => {
            return await httpService.deleteBreedCategory(id)
        };
    }

    public componentDidMount() {
        super.componentDidMount();


    }


    render() {
        return (
            <div className="row gap-20 masonry pos-r">
                <div className="masonry-item col-md-5">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <h4 className="c-grey-900 mB-20" style={{float: "left"}}>FCI Category List</h4>
                        <div style={{float: "right", verticalAlign: "middle"}}>
                            <Link to='/fci-category/add'>
                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                            </Link>
                        </div>

                        <div style={{ clear: "both" }} />

                        <ContentStatus isLoading={this.props.dataState.isLoading} errorMessage={this.props.dataState.error} data={this.props.dataState.list}></ContentStatus>

                        {(!this.props.dataState.isLoading && !this.props.dataState.error && this.props.dataState.list && this.props.dataState.list.length > 0) &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col" className="ta-c mw-actions-min">FCI</th>
                                        <th scope="col" className="ta-r mw-actions">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="dd-container" ref={this.dragulaDecorator}>
                                    {this.props.dataState.list.map((breedCategory: any, index: number) => (
                                        <tr className={`${this.getActiveClass(breedCategory)} ${Helpers.getPublishedClass(breedCategory)}`} key={breedCategory.id}>
                                            <td className="cur-p" onClick={() => { this.showDetails(index); }}>
                                                {breedCategory.name}
                                            </td>
                                            <td className="ta-c">
                                                <span style={{ fontSize: "16px" }} className="icon-holder">{breedCategory.fci.toString()}</span>
                                            </td>
                                            <td className="ta-r">
                                                <Link to={`/fci-category/edit/${breedCategory.id}`}>
                                                    <span style={{fontSize: "16px"}} className="icon-holder"><i
                                                        className="c-light-blue-500 ti-pencil"></i></span>
                                                </Link>
                                                <span style={{fontSize: "16px"}}> | </span>
                                                <a href="#" onClick={(e) => { e.preventDefault(); this.deleteItem(index, breedCategory.name) }}>
                                                    <span style={{ fontSize: "16px" }} className="icon-holder"><i className="c-light-blue-500 ti-trash"></i></span>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>}

                    </div>
                </div>
                <div className="masonry-item col-md-7">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <Route exact path='/fci-category/details/:itemId?' component={BreedCategoryDetails}/>
                        <Route exact path='/fci-category/edit/:itemId?' component={BreedCategoryEdit}/>
                        <Route exact path='/fci-category/add' component={BreedCategoryEdit}/>
                    </div>
                </div>
            </div>)
    }
};

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.breedCatList, userState: state.user };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) },
        selectItem: (data: BreedCategory) => { dispatch(BreedCategoryListStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(BreedCategoryListStore.actionCreators.getItems()) },


        //    reorderItem: (id: string, pos?: number) => { dispatch(CompetitionListStore.actionCreators.reorderItem(id, pos)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreedCategoryList);
