import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { Helpers } from '../../utility/Helpers';
import { BreedEntries, PriceListEntries } from '../../models/competition/CompetitionDate';
import { getTaxLevel, TaxLevel, taxLevelToString } from '../../models/competition/CompetitionEnums';

type PriceListEntriesTableProps = {
    entryChange: Function;
    removeEntry?: Function;
    list: Array<PriceListEntries | BreedEntries>;
    isEdit: boolean;
    isBreed: boolean;
    hasAllEventsPricing: boolean;
    hasTaxSplit: boolean;
}

class PriceListEntriesTable extends React.Component<PriceListEntriesTableProps & typeof DialogStore.actionCreators> {

    constructor(props: any) {
        super(props);
    }

    getBreedOrClassHeader(isBreed: boolean) {
        if (isBreed === true) {
            return 'Breed';
        } else {
            return 'Competition Class';
        }
    }

    getBreedOrClassName(entry: PriceListEntries & BreedEntries) {
        if (entry.competitionClass) {
            return entry.competitionClass.name || "";
        }

        if (entry.breed) {
            return entry.breed.name || "";
        }

        return "";
    }

    getEntryId(entry: PriceListEntries & BreedEntries) {
        if (entry.competitionClass) {
            return entry.competitionClass.id;
        }

        if (entry.breed) {
            return entry.breed.id;
        }

        return '-';
    }

    render() {
        return (
            <div>
                {this.props.list && this.props.list.length > 0 ?

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{this.getBreedOrClassHeader(this.props.isBreed)}</th>
                                
                                
                                <th scope="col">First Dog Price{this.props.hasAllEventsPricing && <span> / All Events</span>}</th>
                                <th scope="col">Next Dog Price{this.props.hasAllEventsPricing && <span> / All Events</span>}</th>
                                <th scope="col">Use Percentage Discount{this.props.hasAllEventsPricing && <span> / All Events</span>}</th>
                                <th scope="col">First Dog Invoice Description{this.props.hasAllEventsPricing && <span> / All Events</span>}</th>
                                <th scope="col">Next Dog Invoice Description{this.props.hasAllEventsPricing && <span> / All Events</span>}</th>
                                <th scope="col">Base Price Tax Percentage</th>
                                {this.props.hasTaxSplit &&
                                    <>
                                        <th scope="col">Base Price Deductable</th>
                                        <th scope="col">Deductable Tax Percentage</th>
                                        <th scope="col">Base Price Invoice Description</th>
                                        <th scope="col">Deductable Price Invoice Description</th></>

                                }
                                {(this.props.removeEntry && this.props.isEdit) && <th scope="col">Actions</th>}
                            </tr>
                        </thead>
                        
                        <tbody className="dd-container">
                            {this.props.list.map((entry: any, index: number) =>
                                <tr key={entry.id}>
                                    <td style={{ color: "grey" }}>
                                        <strong>{index + 1}</strong>
                                    </td>
                                    <td style={{ color: "grey" }}>
                                        {this.getBreedOrClassName(entry)}
                                    </td>
                                    
                                   

                                    <td style={{ color: "grey" }}>
                                        {!this.props.isEdit && <div>
                                            <span>{entry.firstDogPrice || "-"}</span>
                                            {this.props.hasAllEventsPricing && <span>
                                                &nbsp;/&nbsp;{entry.firstDogAllEventsPrice || "-"}
                                            </span>}
                                        </div>}

                                        {this.props.isEdit && <div>
                                            <input placeholder="First price" className="form-control" name={`firstDogPrice_${this.getEntryId(entry) }`} value={entry.firstDogPrice} required onChange={(e) => this.props.entryChange(e, index)}></input>

                                                {this.props.hasAllEventsPricing && <div className="mT-5">
                                                <input placeholder="First All Events price" className="form-control" name={`firstDogAllEventsPrice_${this.getEntryId(entry) }`} value={entry.firstDogAllEventsPrice} required onChange={(e) => this.props.entryChange(e, index)}></input>
                                                </div>}
                                        </div>}
                                    </td>
                                    <td style={{ color: "grey" }}>
                                        {!this.props.isEdit && <div>
                                            <span>{entry.nextDogPrice || "-"}</span>
                                            {this.props.hasAllEventsPricing && <span>
                                                &nbsp;/&nbsp;{entry.nextDogAllEventsPrice || "-"}
                                            </span>}
                                        </div>}

                                        {this.props.isEdit && <div>
                                            <input placeholder="Next price" className="form-control" name={`nextDogPrice_${this.getEntryId(entry)}`} value={entry.nextDogPrice} required={!entry.usePercentageDiscount} onChange={(e) => this.props.entryChange(e, index)}></input>

                                            {this.props.hasAllEventsPricing && <div className="mT-5">
                                                <input placeholder="Next All Events price" className="form-control" name={`nextDogAllEventsPrice_${this.getEntryId(entry)}`} value={entry.nextDogAllEventsPrice} required={!entry.allEventsUsePercentageDiscount} onChange={(e) => this.props.entryChange(e, index)}></input>
                                            </div>}
                                        </div>}
                                    </td>
                                    <td style={{ color: "grey" }} className="cur-p">
                                        {!this.props.isEdit && <div>
                                            <span>{Helpers.booleanToString(entry.usePercentageDiscount)}</span>
                                            {this.props.hasAllEventsPricing && <span>
                                                &nbsp;/&nbsp;{Helpers.booleanToString(entry.allEventsUsePercentageDiscount)}
                                            </span>}
                                        </div>}
                                        {this.props.isEdit && <div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="checkbox checkbox-info peers ai-c ta-c">
                                                        <input className="peer" type="checkbox" id={`usePercentageDiscount_${this.getEntryId(entry)}`} name={`usePercentageDiscount_${this.getEntryId(entry)}`} checked={entry.usePercentageDiscount} onChange={(e) => this.props.entryChange(e, index)}></input>
                                                        <label className="form-label peers peer-greed" htmlFor={`usePercentageDiscount_${this.getEntryId(entry) }`} style={{ marginLeft: '10px', marginRight: '5px' }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <input placeholder="Next Dog Percetange discount" className="form-control" name={`nextDogPricePercentageDiscount_${this.getEntryId(entry) }`} value={entry.nextDogPricePercentageDiscount} required={entry.usePercentageDiscount} onChange={(e) => this.props.entryChange(e, index)}></input>
                                                </div>
                                            </div>
                                            {this.props.hasAllEventsPricing && <div className="row mT-5">
                                                <div className="col-md-2">
                                                    <div className="checkbox checkbox-info peers ai-c ta-c">
                                                        <input className="peer" type="checkbox" id={`allEventsUsePercentageDiscount_${this.getEntryId(entry)}`} name={`allEventsUsePercentageDiscount_${this.getEntryId(entry)}`} checked={entry.allEventsUsePercentageDiscount} onChange={(e) => this.props.entryChange(e, index)}></input>
                                                        <label className="form-label peers peer-greed" htmlFor={`allEventsUsePercentageDiscount_${this.getEntryId(entry) }`} style={{ marginLeft: '10px', marginRight: '5px' }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <input placeholder="Next Dog All Events Percetange discount" className="form-control" name={`nextDogAllEventsPricePercentageDiscount_${this.getEntryId(entry) }`} value={entry.nextDogAllEventsPricePercentageDiscount} required={entry.allEventsUsePercentageDiscount} onChange={(e) => this.props.entryChange(e, index)}></input>
                                                </div>
                                            </div>}
                                        </div>}
                                    </td>
                                    <td style={{ color: "grey" }} className="cur-p">
                                        {!this.props.isEdit && <div>
                                            <span>{entry.firstDogInvoiceDescription || "-"}</span>
                                            {this.props.hasAllEventsPricing && <span>
                                                &nbsp;/&nbsp;{entry.firstDogAllEventsInvoiceDescription || "-"}
                                            </span>}
                                        </div>}
                                        {this.props.isEdit && <div>
                                            <textarea placeholder="First Dog invoice desc." className="form-control" name={`firstDogInvoiceDescription_${this.getEntryId(entry) }`} value={entry.firstDogInvoiceDescription} required onChange={(e) => this.props.entryChange(e, index)} />
                                            {this.props.hasAllEventsPricing && <div className="mT-5">
                                                <textarea placeholder="First Dog All Events invoice desc." rows={2} className="form-control" name={`firstDogAllEventsInvoiceDescription_${this.getEntryId(entry) }`} value={entry.firstDogAllEventsInvoiceDescription} required onChange={(e) => this.props.entryChange(e, index)} />
                                            </div>}
                                        </div>}
                                    </td>
                                    <td style={{ color: "grey" }} className="cur-p">
                                        {!this.props.isEdit && <div>
                                            <span>{entry.nextDogInvoiceDescription || "-"}</span>
                                            {this.props.hasAllEventsPricing && <span>
                                                &nbsp;/&nbsp;{entry.nextDogAllEventsInvoiceDescription || "-"}
                                            </span>}
                                        </div>}
                                        {this.props.isEdit && <div>
                                            <textarea placeholder="Next Dog invoice desc." rows={2} className="form-control" name={`nextDogInvoiceDescription_${this.getEntryId(entry) }`} value={entry.nextDogInvoiceDescription} required onChange={(e) => this.props.entryChange(e, index)} />
                                            {this.props.hasAllEventsPricing && <div className="mT-5">
                                                <textarea placeholder="Next Dog All Events invoice desc." rows={2} className="form-control" name={`nextDogAllEventsInvoiceDescription_${this.getEntryId(entry) }`} value={entry.nextDogAllEventsInvoiceDescription} required onChange={(e) => this.props.entryChange(e, index)} />
                                            </div>}
                                        </div>}
                                    </td>
                                    <td style={{ color: "grey" }}>
                                        {!this.props.isEdit && <div>
                                            <span>{entry.basePriceTaxPercentage}</span>

                                        </div>}

                                        {this.props.isEdit && <div>
                                            <input type="number" min={0.0} step="0.1" className="form-control" name={`basePriceTaxPercentage_${this.getEntryId(entry)}`} defaultValue={entry.basePriceTaxPercentage && entry.basePriceTaxPercentage} required onChange={(e) => this.props.entryChange(e, index)} ></input>


                                        </div>}
                                    </td>
                                    {this.props.hasTaxSplit &&
                                        <>
                                            <td style={{ color: "grey" }}>
                                                {!this.props.isEdit && <div>
                                                    <span>{entry.basePriceDeductable}</span>

                                                </div>}

                                                {this.props.isEdit && <div>
                                                    <input type="number" min={0.0} step="0.1" className="form-control" name={`basePriceDeductable_${this.getEntryId(entry)}`} defaultValue={entry.basePriceDeductable && entry.basePriceDeductable} required={this.props.hasTaxSplit} onChange={(e) => this.props.entryChange(e, index)}></input>


                                                </div>}
                                            </td>
                                            <td style={{ color: "grey" }}>
                                                {!this.props.isEdit && <div>
                                                    <span>{entry.deductableTaxPercentage}</span>

                                                </div>}

                                                {this.props.isEdit && <div>
                                                    <input type="number" min={0.0} step="0.1" className="form-control" name={`deductableTaxPercentage_${this.getEntryId(entry)}`} defaultValue={entry.deductableTaxPercentage && entry.deductableTaxPercentage} required onChange={(e) => this.props.entryChange(e, index)} ></input>

                                                </div>}
                                            </td>
                                            <td style={{ color: "grey" }}>
                                                {!this.props.isEdit && <div>
                                                    <span>{entry.basePriceInvoiceDescription}</span>

                                                </div>}

                                                {this.props.isEdit && <div>
                                                    <textarea placeholder="Base Price Invoice Description" rows={2} className="form-control" name={`basePriceInvoiceDescription_${this.getEntryId(entry)}`} value={entry.basePriceInvoiceDescription && entry.basePriceInvoiceDescription} required={this.props.hasTaxSplit} onChange={(e) => this.props.entryChange(e, index)} />


                                                </div>}
                                            </td>
                                            <td style={{ color: "grey" }}>
                                                {!this.props.isEdit && <div>
                                                    <span>{entry.deductablePriceInvoiceDescription}</span>

                                                </div>}

                                                {this.props.isEdit && <div>
                                                    <textarea placeholder="Deductable Price Invoice Description" rows={2} className="form-control" name={`deductablePriceInvoiceDescription_${this.getEntryId(entry)}`} value={entry.deductablePriceInvoiceDescription && entry.deductablePriceInvoiceDescription} required={this.props.hasTaxSplit} onChange={(e) => this.props.entryChange(e, index)} />


                                                </div>}
                                            </td>
                                        </>
                                    }
                                    {(this.props.removeEntry && this.props.isEdit) && <td>
                                        <div>
                                            <a style={{ float: "right" }} href="#" onClick={(e) => {
                                                e.preventDefault();
                                                if (this.props.removeEntry) {
                                                    this.props.removeEntry(index);
                                                }
                                            }}>
                                                <span style={{ fontSize: "16px" }} className="icon-holder">
                                                    <i className="c-light-blue-500 ti-trash"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </td>}
                                </tr>
                            )}
                        </tbody>
                    </table> : <div>No entries.</div>}
            </div>
        )
    }
}

export default connect(
	(state: ApplicationState) => { return { dialog: state.dialog } },
	DialogStore.actionCreators
)(PriceListEntriesTable)
