import React from "react"
import { connect } from "react-redux"
import '../../custom.scss'
import { Table } from "reactstrap"
import { getCustomBISScore } from "../../models/registrations/RegistrationEnums"

type Props = {
    data: any;
    action: any,
    previewMode?: any,
    shows: []
}

class CompetitionCustomBISScoreComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);
    }

    scoreByDataType() {
        return getCustomBISScore()
    }

    render() {
        return (
            <React.Fragment>
                <div>

                    <br />
                    <Table size="sm" bordered>
                        <tbody>
                            <tr>
                                <th>Is Present</th>
                                <td>
                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-circle checkbox-info peers ai-c" >
                                        <input id="isPresent" name="isPresent"
                                            type="checkbox" checked={this.props.data && this.props.data.isPresent}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e)} />
                                        <label>
                                        <span className="peer peer-greed"></span></label>
                                    </div>
                                </td>
                            </tr>
                            {this.props.shows && <tr>
                                <th>Custom BIS Show</th>
                                <td>
                                    <select style={{ border: "none" }} id="customBISShow" name="customBISShow"
                                        className="form-control"
                                        required
                                        disabled={this.props.previewMode}
                                        value={this.props.data.customBISShow && this.props.data.customBISShow.id}
                                        onChange={(e) => this.props.action(e)}>
                                        <option value="">-- select custom BIS show --</option>

                                        {this.props.shows.map((item: any, index: number) => (
                                            <option key={index} value={item.id}>{item.title}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>}
                            <tr>
                                <th>BIS Score</th>
                                <td>
                                    <select style={{ border: "none" }} id="bisScore" name="bisScore"
                                        className="form-control"
                                        required
                                        disabled={this.props.previewMode}
                                        value={this.props.data && this.props.data.bisScore}
                                        onChange={(e) => this.props.action(e)}>
                                        <option value="">-- select BIS Score --</option>

                                        {this.scoreByDataType().map((item: any, index: number) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </React.Fragment>
        )
    }
}

export default connect()(CompetitionCustomBISScoreComponent)
