import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import {connect} from 'react-redux';

import {history, httpService} from '../../index';
import {ApplicationState} from '../../store';
import * as CompetitionClassStore from '../../store/CompetitionClassStore';
import * as DialogStore from '../../store/DialogStore';
import {DetailsBase} from '../base/DetailsBase';
import {CompetitionClass} from "../../models/registrations/Registrations";


class CompetitionClassEdit extends DetailsBase<CompetitionClass> {

    codeError: string;
    propsForTrim: Array<string>;

    constructor(props: any) {
        super(props);

        this.codeError = '';
        this.propsForTrim = ['name', 'description', 'engName', 'englishDescription', 'germanDescription', 'germanName'];

        this.getItem = async (id: string) => {
            return await httpService.getCompetitionClass(id)
        }
        this.saveItem = async (data: CompetitionClass) => {
            return await httpService.addOrUpdateCompetitionClass(data)
        }
        this.redirectAfterSave = (id: string) => {
            history.replace(`/competition-class/details/${id}`)
        }

        this.emptyData = {

            name: '',
            created: new Date(),
            description: '',
            englishDescription: '',
            engName: '',
            germanName: '',
            germanDescription: '',
            croatianName: '',
            croatianDescription: '',
            requiresChampionship: false,
            requiresPedigree: false,
            requiresWork: false,
            minAgeInMonths: 1,
            maxAgeInMonths: 1,
            order: 0,

        }
    }

    onSubmit = (ev: any) => {
        ev.preventDefault();

        let data: any = {...this.state.data};

        this.trimProps(this.propsForTrim, data);

        this.saveChanges(data as CompetitionClass);
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let val = value;

        if (['maxAgeInMonths', 'minAgeInMonths', 'order'].includes(target.name)) {
            val = parseInt(value);
        }

        let data: any = {...this.state.data};
        data[name] = val;

        this.whitespaceTrimProps(this.propsForTrim, data, name);

        this.setState({
            data: data
        })
    }


    render() {
        return (
            <div>
                <h4 className="c-grey-900">{this.isNew(this.state) && !this.state.isLoading ? "Add" : "Edit"} Competition
                    Class</h4>
                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error}
                               data={this.state.data}></ContentStatus>
                <br/>
                {(this.state && this.state.data) &&
                    <form onSubmit={this.onSubmit}>

                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="name">Name *</label>
                                <input id="name" name="name" required className="form-control"
                                       defaultValue={this.state.data.name} onChange={this.changeData}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="description">Description *</label>
                                <input id="description" name="description" required className="form-control"
                                       defaultValue={this.state.data.description} onChange={this.changeData}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="engName">English Name *</label>
                                <input id="engName" name="engName" required className="form-control"
                                       defaultValue={this.state.data.engName} onChange={this.changeData}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="englishDescription">English Description *</label>
                                <input id="englishDescription" name="englishDescription" required
                                       className="form-control" defaultValue={this.state.data.englishDescription}
                                       onChange={this.changeData}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="germanName">German Name *</label>
                                <input id="germanName" name="germanName" required className="form-control"
                                       defaultValue={this.state.data.germanName} onChange={this.changeData}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="germanDescription">German Description *</label>
                                <input id="germanDescription" name="germanDescription" required className="form-control"
                                       defaultValue={this.state.data.germanDescription} onChange={this.changeData}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="germanName">Croatian Name *</label>
                                <input id="croatianName" name="croatianName" required className="form-control"
                                    defaultValue={this.state.data.croatianName} onChange={this.changeData} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="croatianDescription">Croatian Description *</label>
                                <input id="croatianDescription" name="croatianDescription" required className="form-control"
                                    defaultValue={this.state.data.croatianDescription} onChange={this.changeData} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="minAgeInMonths">Minimum Age In Months *</label>
                                <input id="minAgeInMonths" name="minAgeInMonths" required className="form-control"
                                       defaultValue={this.state.data.minAgeInMonths} onChange={this.changeData}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="maxAgeInMonths">Maximum Age In Months *</label>
                                <input id="maxAgeInMonths" name="maxAgeInMonths" required className="form-control"
                                       defaultValue={this.state.data.maxAgeInMonths} onChange={this.changeData}/>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="order">Catalog Order *</label>
                                <input id="order" name="order" required className="form-control"
                                       defaultValue={this.state.data.order} onChange={this.changeData}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3 col-md-3 ">
                                <div className="mb-3 checkbox checkbox-info peers ai-c">
                                    <input id="requiresChampionship" name="requiresChampionship" type="checkbox"
                                           checked={this.state.data.requiresChampionship} className="peer"
                                           onChange={this.changeData}/>
                                    <label className="form-label peers peer-greed js-sb ai-c"
                                           htmlFor="requiresChampionship">
                                        <span className="peer peer-greed">Requires Championship</span></label>
                                </div>
                            </div>
                            <div className="mb-3 col-md-3 ">
                                <div className="mb-3 checkbox checkbox-info peers ai-c">
                                    <input id="requiresPedigree" name="requiresPedigree" type="checkbox"
                                           checked={this.state.data.requiresPedigree} className="peer"
                                           onChange={this.changeData}/>
                                    <label className="form-label peers peer-greed js-sb ai-c"
                                           htmlFor="requiresPedigree">
                                        <span className="peer peer-greed">Requires Pedigree</span></label>
                                </div>
                            </div>
                            <div className="mb-3 col-md-3 ">
                                <div className="mb-3 checkbox checkbox-info peers ai-c">
                                    <input id="requiresWork" name="requiresWork" type="checkbox"
                                           checked={this.state.data.requiresWork} className="peer"
                                           onChange={this.changeData}/>
                                    <label className="form-label peers peer-greed js-sb ai-c"
                                           htmlFor="requiresWork">
                                        <span className="peer peer-greed">Requires Work</span></label>
                                </div>
                            </div>
                        </div>


                        <br/>
                        <button disabled={this.state.disableButton} type="submit" className="btn btn-primary btn-color">Save</button>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {dataState: state.competitionClassList};
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: CompetitionClass) => {
            dispatch(CompetitionClassStore.actionCreators.selectItem(data))
        },
        getItems: () => {
            dispatch(CompetitionClassStore.actionCreators.getItems())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        showNotification: (data: DialogStore.NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompetitionClassEdit);
