import {Action, Reducer} from 'redux';
import {AppThunkAction} from './';
import {CountriesEnum, getUserRoleTitle, UserType} from "../models/registered-user/RegisteredUserEnums";
import {RegisteredUser} from "../models/registered-user/RegisteredUser";

export interface ProfileState {
    user: RegisteredUser | null;
    selectedCountry: CountriesEnum | null;
    countries: Array<string>
}

interface ReceiveUserAction {
    type: 'SET_USER';
    user: RegisteredUser | null;
}

interface SelectCountryAction {
    type: 'SET_COUNTRY';
    country: CountriesEnum;
}

type KnownAction = ReceiveUserAction | SelectCountryAction;

export const actionCreators = {
    setUser: (user: RegisteredUser | null): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'SET_USER', user: user});
    },
    setCountry: (country: CountriesEnum): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_COUNTRY', country: country });
    }
};

const profileKey = 'profile';
const countryKey = 'country';

const unloadedState: ProfileState = {
    selectedCountry: localStorage.getItem(countryKey) ? localStorage.getItem(countryKey) as CountriesEnum : null,
    user: localStorage.getItem(profileKey) ? JSON.parse(localStorage.getItem(profileKey) as string) : null,
    countries: []
};

const getCountriesFromUser = (user: RegisteredUser | null) => {
    let countries: Array<string> = [];
    if (user && user.roles && user.roles.length > 0) {
        for (let i = 0; i < user.roles.length; i++) {
            let role = user.roles[i];
            if (role.name !== getUserRoleTitle(UserType.ADMIN)) {
                countries.push(role.name);
            } else {
                countries = [CountriesEnum.SI, CountriesEnum.HR, CountriesEnum.AT, CountriesEnum.HU, CountriesEnum.RO, CountriesEnum.DE];
            }
        }
    }

    return countries;
}

export const reducer: Reducer<ProfileState> = (state: ProfileState | undefined, incomingAction: Action): ProfileState => {
    if (state === undefined) {

        unloadedState.countries = getCountriesFromUser(unloadedState.user);

        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_USER':
            if (!action.user) {
                localStorage.removeItem(profileKey);
            } else {
                localStorage.setItem(profileKey, JSON.stringify(action.user));
            }

            let country = state.selectedCountry || localStorage.getItem(countryKey) as CountriesEnum;
            if (!country && action.user && action.user.roles && action.user.roles.length > 0) {
                let firstRole = action.user.roles[0];
                // if (firstRole.name !== getUserRoleTitle(UserType.ADMIN)) {
                //     country = "" 
                // } else {
                //     country = CountriesEnum.SI
                // }

                localStorage.setItem(countryKey, (country) ? country : '');
            }

            let countries: Array<string> = [];
            if (action.user && action.user.roles && action.user.roles.length > 0) {
                for (let i = 0; i < action.user.roles.length; i++) {
                    let role = action.user.roles[i];
                    if (role.name !== 'Admin') {
                        countries.push(role.name);
                    } else {
                        countries = [CountriesEnum.SI, CountriesEnum.HR, CountriesEnum.AT, CountriesEnum.HU, CountriesEnum.RO, CountriesEnum.DE];
                    }
                }
            }

            if (country) {
                let index = countries.indexOf(country);
                if (index === -1) {
                    country = countries[0] as CountriesEnum;
                }
            }

            return {
                user: action.user,
                selectedCountry: country,
                countries: countries
            };
            break;

        case 'SET_COUNTRY':
            if (!action.country) {
                localStorage.removeItem(countryKey);
            } else {
                localStorage.setItem(countryKey, action.country);
            }

            return {
                user: state.user,
                selectedCountry: action.country,
                countries: state.countries
            };            
            break;
    }

    return state;
};
