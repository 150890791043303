import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import * as DialogStore from '../../store/DialogStore';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ApplicationState } from "../../store";
import { httpService } from "../../index";
import { CompetitionDog, CompetitionJuniorSenior } from "../../models/registrations/Registrations";
import { DialogType, NotificationData, NotificationType } from "../../store/DialogStore";
import RegistrationLoader from "../../components/RegistrationLoader";
import { Helpers } from "../../utility/Helpers";
import { Link } from 'react-router-dom';
import { getAgeGroup, getAgeGroupRange } from "../../models/registrations/RegistrationEnums";
import CompetitionBISScore from "../../components/competition/CompetitionBISScore";


type Props = {
    data?: any;
    dogs?: any
    isOpen?: any

}


class JuniorSeniorFormComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }
    emptyState: any = {

        created: new Date(),
        competitionDate: null,
        firstName: '',
        lastName: '',
        ageGroup: null,
        dog: null,
        birthDate: new Date(),
        catalogNumber: null,
        paymentAmount: null,
        invoiceNumber: null,
        competitionRegistrationId: this.props.dataState.registration.id,


    }

    state: any = {
        isOpen: true,
        modal: true,
        loading: "",
        isDataLoading: true,
        processing: false,
        editMode: true,
        add: false,
        previewMode: false,
        editItem: "",
        newData: this.props.dataState.registration,
        currentIndex: 0,
        registrationId: "",
        dropDown: false,
        itemConfirmed: false,
        juniorSeniorData: this.props.dataState.juniorSeniorRegistration,
        disableButton: false,
        competitionBISScore: { isPresent: false, bisScoreType: null, bisScore: null },
        finalCompetitionBISScore: { isPresent: false, bisScoreType: null, bisScore: null },
        customDiscounts: []
    }



    toggle = () => {
        this.setState({
            modal: !this.state.modal

        });
    }

    closeModal() {
        this.props.getCompetitionJuniorSenior(null)


        this.setState({

            add: false,
            editMode: false,
            previewMode: false,
            juniorSeniorData: null
        })

    }


    openEdit = (id: any, index: number, dogType: string) => {

        this.setState({

            editItem: id,
            previewMode: !this.state.previewMode,
            currentIndex: index,


        })

    }

    openAdd = () => {
        this.setState({
            add: true,
            editItem: '',
            previewMode: !this.state.previewMode,
            currentIndex: null,
            newData: null,
            dogType: '',
            juniorSeniorData: this.emptyState,

        })
        if (!this.props.dataState.profileDogs) {
            this.props.getProfileDogs(this.props.dataState.registration.profile.id)
        }


    }


    openPreview = async (id: any, index: number) => {

        this.setState({
            editItem: id,
            editMode: true,
            previewMode: true,
            currentIndex: index,
            isDataLoading: true
        }, () => {
            setTimeout(() => {
                this.getNewestData(id, index)
            }, 200)

        }
        );

        if (this.state.juniorSeniorData) {
            this.setState({ isDataLoading: false })
        }
    }

    getNewestData = async (id: any, index: any) => {

        let data = await httpService.getCompetitionJuniorSenior(id);
        let customDiscounts = await httpService.getCustomDiscountJuniorHandlingReg(this.props.dataState.registration.competitionRegistrationsJuniorSeniorHandling[index]["competitionDate"].id)

        this.setState({
            juniorSeniorData: data,
            customDiscounts: customDiscounts
        })

        this.props.getCompetitionDate(this.props.dataState.registration.competitionRegistrationsJuniorSeniorHandling[index]["competitionDate"].id)
    }

    async processClick(type: any, id: any) {

        this.setState({ processing: true });
        this.setState({ loading: type })
        let response = await httpService.generateJuniorHandlingStartNumbers(id)


        this.setState({ loading: '' })


        //} else {
        //    alert("Unable to generate file.");
        //    this.setState({ loading: '' })
        //}
    }

    toggleDropdown = () => {
        this.setState({ dropDown: !this.state.dropDown })
    }

    saveChanges(data: any, type?: any) {
        if (data) {
            if (!data.hasOwnProperty("competitionRegistrationId")) {
                data.competitionRegistrationId = this.props.dataState.registration.id
            }

            if (data.hasOwnProperty("competitionDate") && data.competitionDate == undefined) {
                data.competitionDate = null
            }
            this.props.showDialog(`Are you sure you want to save this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    try {
                        this.setState({ disableButton: true })
                        data.birthDate = Helpers.getLocalISODate(data.birthDate)
                        await httpService.updateJuniorSeniorRegistrations(data);
                        this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });
                        this.props.getRegistration(data.competitionRegistrationId)
                        this.setState({ juniorSeniorData: null, modal: false, disableButton: false })
                        this.closeModal()

                    } catch (err) {

                        alert(err.message)
                        this.setState({ disableButton: false })

                    }

                }
            })

        }
    }

    changeData = async (ev: any) => {

        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let data = { ...this.state.juniorSeniorData }

        if (!data.hasOwnProperty("competitionRegistrationId")) {
            let newKey = data.competitionRegistrationId
            newKey = this.props.dataState.registration.id
        } if (target.name === "competitionDate") {

            let val = (this.props.dataState.selectedItem.competitionDates.find((i: any) => i.id === value));

            data[name] = val
            data.competitionRing = null
            if (val) {
                this.props.getCompetitionDate(val.id);
                let cd = await httpService.getCustomDiscountJuniorHandlingReg(val.id)
                this.setState({
                    customDiscounts: cd
                })
            } else {
                this.setState({
                    customDiscounts: []
                })
            }
        } if (target.name === "customDiscount") {
            let val = this.state.customDiscounts.find((i: any) => i.id === value)
            data[name] = val
        } if (target.name === "dog") {
            data[name] = this.props.dataState.profileDogs.find((i: any) => i.id === value)

        } if (target.name === "ageGroup") {
            data[name] = parseInt(value)
        } if (target.name === "paymentAmount") {
            data[name] = (value)
        } if (["catalogNumber", "invoiceNumber", "firstName", "lastName"].includes(target.name)) {
            data[name] = value
        }
        this.setState({
            juniorSeniorData: data
        });

    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.juniorSeniorData };
        data["birthDate"] = res.target.value
        this.setState({
            juniorSeniorData: data
        })
    }


    getMaxDate() {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 9);
        return Helpers.getLocalDate(date);
    }

    async deleteRegistration(item: any) {


        this.props.showDialog(`Are you sure you want to delete this item?`, DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {

                try {

                    await httpService.deleteJuniorSeniorRegistration(item.id);
                    this.props.showNotification({ message: 'Successfully deleted.', type: NotificationType.Success });
                    this.props.getRegistration(this.props.dataState.registration.id)
                    this.setState({ juniorSeniorData: null, modal: false })
                    this.closeModal()

                } catch (err) {
                    alert(err.message);
                }
            }
        })
    }

    changeBISScore(ev: any, type: any) {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let data = { ...this.state.juniorSeniorData }

        data[type][name] = value

        this.setState({
            juniorSeniorData: data
        })

    }

    addBISScore() {
        let data = { ...this.state.juniorSeniorData }
        let BISScore = { ...this.state.competitionBISScore };
        data.competitionBISScore = BISScore;

        this.setState({ juniorSeniorData: data })


    }

    addFinalBISScore() {
        let data = { ...this.state.juniorSeniorData }
        let finalBISScore = { ...this.state.finalCompetitionBISScore }
        data.finalCompetitionBISScore = finalBISScore

        this.setState({ juniorSeniorData: data })


    }


    render() {
        return (
            <React.Fragment>


                <div>
                    <div>


                        <div style={{ float: "right", verticalAlign: "middle" }}>
                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                    this.openAdd()
                                }}

                                    color="none">
                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                </Button>
                            }
                        </div>
                        <h5>Junior/Senior Handling</h5>
                        <table className="table table-bordered">


                            {this.props.dataState.registration.competitionRegistrationsJuniorSeniorHandling && this.props.dataState.registration.competitionRegistrationsJuniorSeniorHandling.length > 0 ?
                                <thead>
                                    <tr>
                                        <th>Show</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Age Group</th>
                                        <th>Actions</th>
                                    </tr>

                                </thead>
                                : <span style={{ color: "grey" }}>No sign-ups yet.</span>}

                            <tbody className="dd-container">
                                {this.props.dataState.registration.competitionRegistrationsJuniorSeniorHandling.map((item: any, index: any) =>


                                    <tr key={item.id}>
                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }}>{item.competitionDate.description}</td>
                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }}>{item.firstName}</td>
                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }}>{item.lastName}</td>
                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }}>{getAgeGroupRange(item.ageGroup)}</td>
                                        <td className="ta-c">
                                            <span onClick={() => this.openPreview(item.id, index)} style={{ cursor: "pointer", marginLeft: "auto", marginRight: "auto" }} className="icon-holder"><i className="c-light-blue-500 fa fa-search" aria-hidden="true"></i></span>
                                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                <>
                                                    <span style={{ fontSize: "16px" }}> | </span>
                                                    <span style={{ cursor: "pointer" }} className="icon-holder" onClick={(e) =>
                                                        this.deleteRegistration(item)
                                                    }><i className="c-light-blue-500 ti-trash"></i></span>
                                                </>
                                            }

                                        </td>

                                    </tr>

                                )}
                            </tbody>

                        </table>

                    </div>


                    {this.props.dataState.registration.competitionRegistrationsJuniorSeniorHandling && this.props.dataState.registration.competitionRegistrationsJuniorSeniorHandling.map((junior: any, index: number) =>
                        <div>
                            {
                                !this.state.add && this.state.editItem === junior.id && this.state.editMode && (


                                    <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.state.editMode}>
                                        <ModalHeader toggle={() => this.closeModal()}>Edit Junior/Senior Handling Registration</ModalHeader>
                                        <form onSubmit={(e: any) => { e.preventDefault(); this.saveChanges(this.state.juniorSeniorData as CompetitionJuniorSenior, "junior") }}>
                                            <ModalBody>

                                                {this.state.isDataLoading && !this.state.juniorSeniorData ? <RegistrationLoader /> :
                                                    (
                                                        <div>

                                                            <div className="row">
                                                                {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                                    <td><span onClick={() => this.openEdit(junior.id, index, "junior")} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                                                        className="c-light-blue-500 ti-pencil"></i></span>
                                                                    </td>
                                                                }

                                                            </div>

                                                            <div >

                                                                <div className="row">
                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label" htmlFor="dog">Dog</label>
                                                                        <select id="dog" name="dog"
                                                                            className="form-control"

                                                                            disabled={this.state.previewMode}
                                                                            value={this.state.juniorSeniorData && this.state.juniorSeniorData.dog && this.state.juniorSeniorData.dog.id}
                                                                            onChange={(e) => this.changeData(e)}>
                                                                            <option value="">-- select dog --</option>
                                                                            {this.props.dataState.profileDogs.map((item: any, index: number) => (
                                                                                <option key={index} value={item.id}>{item.name} - {item.breed.name}</option>
                                                                            ))}
                                                                        </select>

                                                                    </div>


                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label" htmlFor="competitionDate">Show</label>
                                                                        <select id="competitionDate" name="competitionDate"
                                                                            className="form-control"
                                                                            disabled={this.state.previewMode}
                                                                            defaultValue={this.state.juniorSeniorData && this.state.juniorSeniorData.competitionDate && this.state.juniorSeniorData.competitionDate.id}
                                                                            onChange={(e) => this.changeData(e)}>

                                                                            <option>--- select show ---</option>

                                                                            {this.props.dataState.selectedItem.competitionDates.map((item: any, index: number) => (
                                                                                <option key={index} value={item.id}>{item.description}</option>
                                                                            ))}
                                                                        </select>

                                                                    </div>

                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label" htmlFor="firstName">First Name *</label>
                                                                        <input id="firstName" name="firstName"
                                                                            disabled={this.state.previewMode}
                                                                            required
                                                                            className="form-control" value={this.state.juniorSeniorData && this.state.juniorSeniorData.firstName}
                                                                            onChange={(e) => this.changeData(e)}>

                                                                        </input>
                                                                    </div>

                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label" htmlFor="lastName">Last Name *</label>
                                                                        <input id="lastName" name="lastName"
                                                                            disabled={this.state.previewMode}
                                                                            required
                                                                            className="form-control" value={this.state.juniorSeniorData && this.state.juniorSeniorData.lastName}
                                                                            onChange={(e) => this.changeData(e)}>

                                                                        </input>
                                                                    </div>
                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label" htmlFor="birthDate">Birth Date *</label>
                                                                        <div className="input-group">
                                                                            <div className="datepicker datepicker-dropdown dropdown-menu datepicker-orient-left datepicker-orient-bottom">
                                                                                <i className="ti-calendar"></i>
                                                                            </div>
                                                                            <input type="date" className="form-control" max={this.getMaxDate()} value={this.state.juniorSeniorData && this.state.juniorSeniorData.birthDate && Helpers.getLocalDate(this.state.juniorSeniorData.birthDate)}
                                                                                required
                                                                                disabled={this.state.previewMode}
                                                                                onChange={(e: any) => {
                                                                                    this.dateChange(e);

                                                                                }} ></input>
                                                                        </div>

                                                                    </div>
                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label" htmlFor="ageGroup">Age Group *</label>
                                                                        <select id="ageGroup" name="ageGroup" className="form-control" disabled={this.state.previewMode}
                                                                            required
                                                                            value={this.state.juniorSeniorData && this.state.juniorSeniorData.ageGroup && this.state.juniorSeniorData.ageGroup} onChange={(e) => this.changeData(e)}>
                                                                            <option value="">-- select age group --</option>
                                                                            {getAgeGroup().map((item: any, index: number) => (
                                                                                <option key={index} value={item.value}>{item.label}</option>
                                                                            ))}
                                                                        </select>

                                                                    </div>

                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label" htmlFor="catalogNumber">Catalog
                                                                            Number</label>
                                                                        <input id="catalogNumber" name="catalogNumber"
                                                                            disabled={this.state.previewMode}

                                                                            className="form-control" value={this.state.juniorSeniorData && this.state.juniorSeniorData.catalogNumber}
                                                                            onChange={(e) => this.changeData(e)}>

                                                                        </input>
                                                                    </div>




                                                                    <hr />
                                                                    <h5>Payment Information</h5>
                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label" htmlFor="paymentAmount">Payment
                                                                            Amount *</label>
                                                                        <input type="number" id="paymentAmount" name="paymentAmount" min="0.00"
                                                                            step="0.1"
                                                                            className="form-control"
                                                                            required
                                                                            disabled={this.state.previewMode}
                                                                            value={this.state.juniorSeniorData && this.state.juniorSeniorData.paymentAmount}
                                                                            onChange={(e) => this.changeData(e)}>

                                                                        </input>
                                                                    </div>
                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label" htmlFor="invoiceNumber">Invoice
                                                                            Number</label>
                                                                        <input id="invoiceNumber" name="invoiceNumber"
                                                                            disabled={this.state.previewMode}

                                                                            className="form-control" value={this.state.juniorSeniorData && this.state.juniorSeniorData.invoiceNumber}
                                                                            onChange={(e) => this.changeData(e)}>

                                                                        </input>
                                                                    </div>
                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label" htmlFor="customDiscount">Custom Discount</label>
                                                                        <select id="customDiscount" name="customDiscount"
                                                                            className="form-control"
                                                                            disabled={this.state.previewMode}
                                                                            defaultValue={this.state.juniorSeniorData && this.state.juniorSeniorData.customDiscount && this.state.juniorSeniorData.customDiscount.id}
                                                                            onChange={(e) => this.changeData(e)}>
                                                                            <option>--- select discount ---</option>
                                                                            {this.state.customDiscounts.map((item: any, index: number) => (
                                                                                <option key={index} value={item.id}>{item.title}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                                    <div style={{ display: "flex", gap: "15px" }}>
                                                                        <Button id="start-number" color="outline-primary"
                                                                            onClick={() => {
                                                                                this.processClick("start-number", junior.id)
                                                                            }}
                                                                            disabled={this.state.loading === "start-number" && true}
                                                                        >Generate Start Number

                                                                            {this.state.loading === "start-number" &&
                                                                                <i className="fa fa-refresh fa-spin"
                                                                                    style={{ marginRight: "5px", marginLeft: "10px" }} />
                                                                            }
                                                                        </Button>

                                                                    </div>
                                                                }
                                                                <hr />
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <h5 style={{ marginRight: '10px' }}>BIS Score</h5>
                                                                    {this.state.juniorSeniorData && !this.state.juniorSeniorData.competitionBISScore &&
                                                                        !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                                            <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                                this.addBISScore()
                                                                            }}
                                                                                color="none">
                                                                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                            </Button>
                                                                        )
                                                                    }
                                                                </div>



                                                                {this.state.juniorSeniorData && this.state.juniorSeniorData.competitionBISScore ?

                                                                    <CompetitionBISScore data={this.state.juniorSeniorData.competitionBISScore} dataType="juniorSenior" action={(e: any) => this.changeBISScore(e, "competitionBISScore")} previewMode={this.state.previewMode} type="BIS Score" />
                                                                    : <div>No BIS Score.</div>

                                                                }

                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <h5 style={{ marginRight: '10px' }}>Final BIS Score</h5>
                                                                    {this.state.juniorSeniorData && !this.state.juniorSeniorData.finalCompetitionBISScore &&
                                                                        !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                                            <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                                                this.addFinalBISScore()
                                                                            }}
                                                                                color="none">
                                                                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                                            </Button>
                                                                        )
                                                                    }
                                                                </div>



                                                                {this.state.juniorSeniorData && this.state.juniorSeniorData.finalCompetitionBISScore ?

                                                                    <CompetitionBISScore data={this.state.juniorSeniorData.finalCompetitionBISScore} dataType="finalBis" action={(e: any) => this.changeBISScore(e, "finalCompetitionBISScore")} previewMode={this.state.previewMode} type="Final BIS Score" />
                                                                    : <div>No Final BIS Score.</div>

                                                                }

                                                            </div>

                                                        </div>

                                                    )

                                                }

                                            </ModalBody>
                                            <ModalFooter>
                                                {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                    <Button disabled={this.state.disableButton} type="submit" color="primary">Save</Button>
                                                }
                                                <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                                            </ModalFooter>
                                        </form>
                                    </Modal>

                                )

                            }
                        </div>
                    )}

                    {this.state.add && (
                        <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.state.add}>
                            <ModalHeader toggle={() => this.closeModal()}>Add Junior/Senior Handling Registration</ModalHeader>
                            <form onSubmit={(e: any) => { e.preventDefault(); this.saveChanges(this.state.juniorSeniorData as CompetitionJuniorSenior, "junior") }}>
                                <ModalBody>

                                    <div>
                                        <div className="row">

                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="dog">Dog</label>
                                                <select id="dog" name="dog"
                                                    className="form-control"
                                                    defaultValue={this.state.juniorSeniorData && this.state.juniorSeniorData.dog && this.state.juniorSeniorData.dog.name}
                                                    onChange={(e) => this.changeData(e)}>
                                                    <option value="">-- select dog --</option>
                                                    {this.props.dataState.profileDogs.map((item: any, index: number) => (
                                                        <option key={index} value={item.id}>{item.name} - {item.breed.name}</option>
                                                    ))}
                                                </select>

                                            </div>

                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="competitionDate">Show</label>
                                                <select id="competitionDate" name="competitionDate"
                                                    className="form-control"
                                                    value={this.state.juniorSeniorData && this.state.juniorSeniorData.competitionDate && this.state.juniorSeniorData.competitionDate.id}
                                                    onChange={(e) => this.changeData(e)}>
                                                    <option value="">-- select show --</option>
                                                    {this.props.dataState.selectedItem.competitionDates.map((item: any, index: number) => (
                                                        <option key={index} value={item.id}>{item.description}</option>
                                                    ))}
                                                </select>

                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="firstName">First Name *</label>
                                                <input id="firstName" name="firstName" required
                                                    className="form-control" value={this.state.juniorSeniorData && this.state.juniorSeniorData.firstName}
                                                    onChange={(e) => this.changeData(e)}>

                                                </input>
                                            </div>

                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="lastName">Last Name *</label>
                                                <input id="lastName" name="lastName" required
                                                    className="form-control" value={this.state.juniorSeniorData && this.state.juniorSeniorData.lastName}
                                                    onChange={(e) => this.changeData(e)}>

                                                </input>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="birthDate">Birth Date *</label>


                                                <div className="input-group">
                                                    <div className="datepicker datepicker-dropdown dropdown-menu datepicker-orient-left datepicker-orient-bottom">
                                                        <i className="ti-calendar"></i>
                                                    </div>
                                                    <input style={{ borderRadius: "0.375rem" }} type="date" data-role="calendarpicker" className="form-control" max={this.getMaxDate()} value={this.state.juniorSeniorData && this.state.juniorSeniorData.birthDate}
                                                        required
                                                        onChange={(e: any) => {
                                                            this.dateChange(e);

                                                        }} ></input>
                                                </div>

                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="ageGroup">Age Group *</label>
                                                <select id="ageGroup" name="ageGroup" className="form-control"
                                                    required
                                                    value={this.state.juniorSeniorData && this.state.juniorSeniorData.ageGroup} onChange={(e) => this.changeData(e)}>
                                                    <option value="">-- select age group --</option>
                                                    {getAgeGroup().map((item: any, index: number) => (
                                                        <option key={index} value={item.value}>{item.label}</option>
                                                    ))}
                                                </select>

                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="catalogNumber">Catalog
                                                    Number</label>
                                                <input id="catalogNumber" name="catalogNumber"

                                                    className="form-control" defaultValue={this.state.juniorSeniorData && this.state.juniorSeniorData.catalogNumber}
                                                    onChange={(e) => this.changeData(e)}>

                                                </input>
                                            </div>
                                            <hr />
                                            <h5>Payment Information</h5>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="paymentAmount">Payment
                                                    Amount *</label>
                                                <input type="number" id="paymentAmount" name="paymentAmount" min="0.00"
                                                    step="0.1"
                                                    required
                                                    className="form-control"
                                                    defaultValue={this.state.juniorSeniorData && this.state.juniorSeniorData.paymentAmount}

                                                    onChange={(e) => this.changeData(e)}>
                                                </input>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="invoiceNumber">Invoice Number</label>
                                                <input id="invoiceNumber" name="invoiceNumber"

                                                    className="form-control"
                                                    defaultValue={this.state.juniorSeniorData && this.state.juniorSeniorData.invoiceNumber || ""}

                                                    onChange={(e) => this.changeData(e)}>
                                                </input>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="customDiscount">Custom Discount</label>
                                                <select id="customDiscount" name="customDiscount"
                                                    className="form-control"
                                                    onChange={(e) => this.changeData(e)}>
                                                    <option>--- select discount ---</option>
                                                    {this.state.customDiscounts.map((item: any, index: number) => (
                                                        <option key={index} value={item.id}>{item.title}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <hr />
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ marginRight: '10px' }}>BIS Score</h5>
                                            {this.state.juniorSeniorData && !this.state.juniorSeniorData.competitionBISScore &&
                                                !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                        this.addBISScore()
                                                    }}
                                                        color="none">
                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                    </Button>
                                                )
                                            }
                                        </div>



                                        {this.state.juniorSeniorData && this.state.juniorSeniorData.competitionBISScore ?

                                            <CompetitionBISScore data={this.state.juniorSeniorData.competitionBISScore} dataType="juniorSenior" action={(e: any) => this.changeBISScore(e, "competitionBISScore")} previewMode={false} type="BIS Score" />
                                            : <div>No BIS Score.</div>

                                        }

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ marginRight: '10px' }}>Final BIS Score</h5>
                                            {this.state.juniorSeniorData && !this.state.juniorSeniorData.finalCompetitionBISScore &&
                                                !Helpers.isUserEventViewer(this.props.userState.user) && (
                                                    <Button style={{ border: "none", outline: "none" }} onClick={(e) => {
                                                        this.addFinalBISScore()
                                                    }}
                                                        color="none">
                                                        <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                                    </Button>
                                                )
                                            }
                                        </div>



                                        {this.state.juniorSeniorData && this.state.juniorSeniorData.finalCompetitionBISScore ?

                                            <CompetitionBISScore data={this.state.juniorSeniorData.finalCompetitionBISScore} dataType="finalBis" action={(e: any) => this.changeBISScore(e, "finalCompetitionBISScore")} previewMode={false} type="Final BIS Score" />
                                            : <div>No Final BIS Score.</div>

                                        }


                                    </div>


                                </ModalBody>
                                <ModalFooter>
                                    {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                        <Button disabled={this.state.disableButton} type="submit" color="primary">Save</Button>
                                    }
                                    <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                                </ModalFooter>
                            </form>
                        </Modal>
                    )

                    }

                </div>

            </React.Fragment>
        );

    }

}


const mapStateToProps = (state: ApplicationState) => {
    return {
        dataState: state.competitionList,
        userState: state.user
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getItems: () => {
            dispatch(CompetitionListStore.actionCreators.getItems())
        },
        getRegistrationItems: (page?: any, competition?: string, email?: string, count?: number, paymentStatus?: any, catalogNumber?: any) => {
            dispatch(CompetitionListStore.actionCreators.getRegistrationItems(page, competition, email, count, paymentStatus, catalogNumber))
        },
        getRegistration: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getRegistration(id))
        },
        getProfileDogs: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getProfileDogs(id))
        },
        getCompetitionDate: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
        },
        setOpenReg: (openReg?: any) => {
            dispatch(CompetitionListStore.actionCreators.setOpenReg(openReg))
        },
        getCompetitionJuniorSenior: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionJuniorSenior(id))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
};




export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JuniorSeniorFormComponent);
