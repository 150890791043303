import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import * as DialogStore from '../../store/DialogStore';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,

} from "reactstrap";
import { ApplicationState } from "../../store";
import { httpService } from "../../index";
import { CompetitionCoupleGroup, CompetitionDog, CompetitionSpecialShow, CoupleGroupType, getCoupleGroup } from "../../models/registrations/Registrations";
import { DialogType, NotificationData, NotificationType } from "../../store/DialogStore";

import { Helpers } from "../../utility/Helpers";
import RegistrationLoader from "../../components/RegistrationLoader";
import CompetitionCustomBISScore from "../../components/competition/CompetitionCustomBISScore";


type Props = {
    data?: any;
    dogs?: any
    isOpen?: any

}


class SpecialShowFormComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }
    emptyState: any = {

        competitionDate: null,
        dog: null,
        specialCompetition: null,
        created: new Date()

    }

    state: any = {
        isOpen: true,
        modal: true,
        loading: "",
        processing: false,
        editMode: false,
        add: false,
        previewMode: false,
        editItem: "",
        newData: this.props.dataState.registration,
        currentIndex: 0,
        registrationId: "",
        dropDown: false,
        dogType: '',
        itemConfirmed: false,
        specialData: null,
        disableButton: false
    };



    toggle = () => {
        this.setState({
            modal: !this.state.modal

        });
    }

    closeModal() {

        this.props.getCompetitionSpecialShow(null)


        this.setState({

            add: false,
            editMode: false,
            previewMode: false,
            specialData: null
        })

    }


    openEdit(id: any, index: number, dogType: string) {

        this.setState({

            editItem: id,
            previewMode: !this.state.previewMode,
            currentIndex: index,


        })

    }

    openAdd() {

        this.setState({
            add: true,
            editItem: '',
            previewMode: !this.state.previewMode,
            currentIndex: null,
            newData: null,
            dogType: '',
            special: this.emptyState,
            specialData: this.emptyState,

        })
        if (!this.props.dataState.profileDogs) {
            this.props.getProfileDogs(this.props.dataState.registration.profile.id)
        }


    }

    openPreview = async (id: any, index: number, dogType?: string) => {


        this.setState({
            editItem: id,
            editMode: true,
            previewMode: true,
            currentIndex: index,
            isDataLoading: true

        }, () => {

            setTimeout(() => {
                this.getNewestData(id, index)
            }, 200)

        });

    }


    getUrl = (type: string, id: string) => {
        return "generate/" + type + "/" + id
    }

    getNewestData = async (id: any, index: any) => {
        let data = await httpService.getSpecialShowRegistration(id)
        this.setState({

            specialData: data

        });
        if (this.state.specialData) {
            this.setState({ isDataLoading: false })
        }
        this.props.getCompetitionDate(this.props.dataState.registration.competitionRegistrationsSpecialShow[index]["competitionDate"].id);


    }


    toggleDropdown = () => {
        this.setState({ dropDown: !this.state.dropDown })
    }

    saveChanges(data: any, type?: any) {
        if (data) {

            if (!data.hasOwnProperty("competitionRegistrationId")) {
                data.competitionRegistrationId = this.props.dataState.registration.id
            }
            if (data.hasOwnProperty("competitionDate") && data.competitionDate == undefined) {
                data.competitionDate = null
            }

            if (data.specialCompetitionBISScore && (data.specialCompetitionBISScore.bisScore === '')) {
                data.specialCompetitionBISScore = null
            }

            this.props.showDialog(`Are you sure you want to save this item?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    try {
                        this.setState({ disableButton: true })
                        await httpService.updateSpecialShowRegistrations(data);
                        this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });

                        this.props.getRegistration(this.props.dataState.registration.id)
                        this.setState({ specialData: null, modal: false, disableButton: false })
                        this.closeModal()

                    } catch (err) {

                        alert(err.message)
                        this.setState({ disableButton: false })

                    }

                }
            })

        }
    }

    async deleteRegistration(item: any) {


        this.props.showDialog(`Are you sure you want to delete "${item ? item.dog.name : "this item"}?"`, DialogType.Confirm, async (confirm: boolean) => {
            if (confirm) {

                try {

                    await httpService.deleteSpecialShowRegistration(item.id);

                    this.props.showNotification({ message: 'Successfully deleted.', type: NotificationType.Success });
                    this.props.getRegistration(this.props.dataState.registration.id)
                    this.setState({ specialData: null, modal: false })
                    this.closeModal()

                } catch (err) {
                    alert(err.message);
                }
            }
        })
    }

    changeData(ev: any, index: number, item?: any, type?: any) {

        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let specialData = { ...this.state.specialData }

        if (!specialData.hasOwnProperty("invoiceNumber")) {
            specialData.invoiceNumber = "";
            this.setState({
                specialData: specialData
            });

        }
        if (!specialData.hasOwnProperty("competitionRegistrationId")) {
            let newKey = specialData.competitionRegistrationId
            specialData.competitionRegistrationId = this.props.dataState.registration.id
            this.setState({
                specialData: specialData
            });
        } if (target.name === "competitionDate") {

            let val = (this.props.dataState.selectedItem.competitionDates.find((i: any) => i.id === value));
            specialData[name] = val
            this.props.getCompetitionDate(val.id)


        } if (target.name === "dog") {

            let dog = (this.props.dataState.profileDogs.find((i: any) => i.id === value));
            specialData[name] = dog
            this.setState({
                specialData: specialData
            });

        } if (target.name === "specialCompetition") {

            let val = (this.props.dataState.competitionDate.specialCompetitions.find((i: any) => i.id === value));
            specialData[name] = val

        } if (target.name === "paymentAmount") {
            specialData[name] = (value)

        }

        if (target.name === "invoiceNumber") {
            specialData[name] = value
        }
        if (target.name === "catalogNumber") {
            specialData[name] = value
        }

        this.setState({
            specialData: specialData
        });



    }

    changeBISScore(ev: any) {
        const target = ev.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        let data = { ...this.state.specialData }

        if (!data.specialCompetitionBISScore) {
            data.specialCompetitionBISScore = { isPresent: false, bisScore: '' }
        }

        data.specialCompetitionBISScore[name] = value

        this.setState({
            specialData: data
        })
    }

    render() {


        return (
            <React.Fragment>


                <div>
                    <div>
                        <div style={{ float: "right", verticalAlign: "middle" }}>
                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                <Button style={{ border: "none", outline: "none" }} onClick={(e) => {

                                    this.openAdd()
                                }}

                                    color="none">
                                    <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                                </Button>
                            }
                        </div>
                        <h5>Special Show</h5>
                        <table className="table table-bordered">


                            {this.props.dataState.registration.competitionRegistrationsSpecialShow && this.props.dataState.registration.competitionRegistrationsSpecialShow.length > 0 ?
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th>Name & Breed</th>
                                        <th>Type</th>
                                        <th>Created</th>
                                        <th>Actions</th>
                                    </tr>

                                </thead>
                                : <span style={{ color: "grey" }}>No sign-ups yet.</span>}

                            <tbody className="dd-container">
                                {this.props.dataState.registration.competitionRegistrationsSpecialShow && this.props.dataState.registration.competitionRegistrationsSpecialShow.map((item: any, index: any) =>



                                    <tr key={item.id}>
                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{item.competitionDate.description}</td>
                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{item.dog.name} - {item.dog.breed.name}</td>
                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{item.specialCompetition.title}</td>

                                        <td style={{ color: "grey", userSelect: "none", cursor: "auto" }} className="cur-p">{Helpers.getLocalDateTimeString(item.created)}</td>

                                        <td className="ta-c">
                                            <span onClick={() => this.openPreview(item.id, index, "special")} style={{ cursor: "pointer", marginLeft: "auto", marginRight: "auto" }} className="icon-holder"><i className="c-light-blue-500 fa fa-search" aria-hidden="true"></i></span>
                                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                <>
                                                    <span style={{ fontSize: "16px" }}> | </span>
                                                    <span style={{ cursor: "pointer" }} className="icon-holder" onClick={(e) =>
                                                        this.deleteRegistration(item)
                                                    }><i className="c-light-blue-500 ti-trash"></i></span>
                                                </>
                                            }

                                        </td>
                                    </tr>




                                )}
                            </tbody>


                        </table>

                    </div>


                    {this.props.dataState.registration.competitionRegistrationsSpecialShow.map((dog: any, index: number) =>
                        <div>
                            {
                                !this.state.add && this.state.editItem === dog.id && this.state.editMode && (


                                    <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.state.editMode}>
                                        <ModalHeader toggle={() => this.closeModal()}>{this.state.specialData ? dog.dog.name : ""} </ModalHeader>
                                        <ModalBody>

                                            {this.state.isDataLoading && !this.state.specialData ? <RegistrationLoader /> :
                                                <div>


                                                    <div key={index} className="row">
                                                        {!Helpers.isUserEventViewer(this.props.userState.user) &&

                                                            <td><span onClick={() => this.openEdit(dog.id, index, "special")} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                                                className="c-light-blue-500 ti-pencil"></i></span>
                                                            </td>
                                                        }


                                                        <br />
                                                    </div>

                                                    <div>
                                                        <div className="row">

                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="dog">Dog *</label>
                                                                <select id="dog" name="dog"
                                                                    className="form-control"
                                                                    required
                                                                    disabled={this.state.previewMode}

                                                                    defaultValue={this.state.specialData && this.state.specialData.dog && this.state.specialData.dog.id}
                                                                    onChange={(e) => this.changeData(e, index, this, "special")}>
                                                                    <option value="">-- select dog --</option>
                                                                    {this.props.dataState.profileDogs.map((item: any, index: number) => (
                                                                        <option key={index} value={item.id}>{item.name} - {item.breed.name}</option>
                                                                    ))}
                                                                </select>

                                                            </div>
                                                        </div>
                                                        <div className="row">

                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="competitionDate">Show *</label>
                                                                <select id="competitionDate" name="competitionDate"
                                                                    className="form-control"
                                                                    disabled={this.state.previewMode}

                                                                    defaultValue={this.state.specialData && this.state.specialData.competitionDate && this.state.specialData.competitionDate.id}
                                                                    onChange={(e) => this.changeData(e, index, this, "special")}>
                                                                    {this.props.dataState.selectedItem.competitionDates.map((item: any, index: number) => (
                                                                        <option key={index} value={item.id}>{item.description}</option>
                                                                    ))}
                                                                </select>

                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label"
                                                                    htmlFor="specialCompetition">Special Show</label>
                                                                <select id="specialCompetition" name="specialCompetition"
                                                                    className="form-control"
                                                                    disabled={this.state.previewMode}

                                                                    defaultValue={this.state.specialData && this.state.specialData.specialCompetition && this.state.specialData.specialCompetition.id}
                                                                    onChange={(e) => this.changeData(e, index, this, "special")}>
                                                                    {this.props.dataState.competitionDate && this.props.dataState.competitionDate.specialCompetitions.length > 0 && this.props.dataState.competitionDate.specialCompetitions.map((c: any, i: number) => (
                                                                        <option key={i} value={c.id}>{c.title}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="catalogNumber">Catalog
                                                                    Number</label>
                                                                <input id="catalogNumber" name="catalogNumber"
                                                                    disabled={this.state.previewMode}

                                                                    className="form-control"
                                                                    value={this.state.specialData && this.state.specialData.catalogNumber}
                                                                    onChange={(e) => this.changeData(e, index, "", "special")}>

                                                                </input>
                                                            </div>
                                                            <hr />
                                                            <h5>Payment Information</h5>

                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="paymentAmount">Payment
                                                                    Amount *</label>
                                                                <input type="number" id="paymentAmount" name="paymentAmount" min="0.00"
                                                                    step="0.1"
                                                                    required
                                                                    disabled={this.state.previewMode}
                                                                    className="form-control"
                                                                    defaultValue={this.state.specialData && this.state.specialData.paymentAmount || 0}

                                                                    onChange={(e) => this.changeData(e, index, "", "special")}>

                                                                </input>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="invoiceNumber">Invoice Number</label>
                                                                <input id="invoiceNumber" name="invoiceNumber"
                                                                    className="form-control"
                                                                    disabled={this.state.previewMode}
                                                                    defaultValue={this.state.specialData && this.state.specialData.invoiceNumber}
                                                                    onChange={(e) => this.changeData(e, index, "", "special")}>

                                                                </input>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <hr />
                                                                <h5>BIS Score</h5>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <CompetitionCustomBISScore data={this.state.specialData && this.state.specialData.specialCompetitionBISScore} action={(e: any) => { this.changeBISScore(e) }} previewMode={this.state.previewMode} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </ModalBody>
                                        <ModalFooter>
                                            {!this.state.previewMode &&
                                                <Button disabled={this.state.disableButton} color="primary" onClick={() => {
                                                    this.saveChanges(this.state.specialData as CompetitionSpecialShow, "special")
                                                }}>Save</Button>
                                            }
                                            <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                                        </ModalFooter>

                                    </Modal>

                                )

                            }
                        </div>
                    )}

                    {this.state.add && (
                        <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.state.add}>
                            <ModalHeader toggle={() => this.closeModal()}>Add Special Show Registration</ModalHeader>
                            <ModalBody>

                                <div >
                                    <div className="row">

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label"
                                                htmlFor="dog">Dog *</label>
                                            <select id="dog" name="dog"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.specialData && this.state.specialData.dog && this.state.specialData.dog.name}
                                                onChange={(e) => this.changeData(e, 0, this, "special")}>
                                                <option value="">-- select dog --</option>
                                                {this.props.dataState.profileDogs && this.props.dataState.profileDogs.map((c: any, i: number) => (
                                                    <option key={i} value={c.id}>{c.name} - {c.breed.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="competitionDate">Show *</label>
                                            <select id="competitionDate" name="competitionDate"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.specialData && this.state.specialData.competitionDate && this.state.specialData.competitionDate.description}
                                                onChange={(e) => this.changeData(e, 0, this, "special")}>
                                                <option value="">-- select date --</option>
                                                {this.props.dataState.selectedItem.competitionDates.map((item: any, index: number) => (
                                                    <option key={index} value={item.id}>{item.description}</option>
                                                ))}
                                            </select>

                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label"
                                                htmlFor="specialCompetition">Special Show *</label>
                                            <select id="specialCompetition" name="specialCompetition"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.specialData && this.state.specialData.specialCompetition && this.state.specialData.specialCompetition.title}
                                                onChange={(e) => this.changeData(e, 0, this)}>
                                                <option value="">-- select special competition --</option>
                                                {this.props.dataState.competitionDate && this.props.dataState.competitionDate.specialCompetitions && this.props.dataState.competitionDate.specialCompetitions.map((c: any, i: number) => (
                                                    <option key={i} value={c.id}>{c.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <hr />
                                        <h5>Payment Information</h5>

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="paymentAmount">Payment
                                                Amount *</label>
                                            <input type="number" id="paymentAmount" name="paymentAmount" min="0.00"
                                                step="0.1"
                                                required
                                                className="form-control"
                                                defaultValue={this.state.specialData && this.state.specialData.paymentAmount || 0}

                                                onChange={(e) => this.changeData(e, 0, "", "special")}>

                                            </input>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="invoiceNumber">Invoice Number</label>
                                            <input id="invoiceNumber" name="invoiceNumber"
                                                className="form-control"
                                                defaultValue={this.state.specialData && this.state.specialData.invoiceNumber}
                                                onChange={(e) => this.changeData(e, 0, "", "dog")}>

                                            </input>
                                        </div>
                                        <div className="col-md-12">
                                            <hr />
                                            <h5>BIS Score</h5>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <CompetitionCustomBISScore data={this.state.specialData && this.state.specialData.specialCompetitionBISScore} action={(e: any) => { this.changeBISScore(e) }} previewMode={false} />
                                        </div>
                                    </div>
                                </div>


                            </ModalBody>
                            <ModalFooter>

                                {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                    <Button disabled={this.state.disableButton} color="primary" onClick={() => {
                                        this.saveChanges(this.state.specialData as CompetitionSpecialShow, "special")
                                    }}>Save</Button>
                                }

                                <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                            </ModalFooter>

                        </Modal>
                    )
                    }


                </div>

            </React.Fragment>
        );

    }

}


const mapStateToProps = (state: ApplicationState) => {
    return {
        dataState: state.competitionList,
        userState: state.user
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getItems: () => {
            dispatch(CompetitionListStore.actionCreators.getItems())
        },
        getRegistrationItems: (page?: any, competition?: string, email?: string, count?: number, paymentStatus?: any, catalogNumber?: any) => {
            dispatch(CompetitionListStore.actionCreators.getRegistrationItems(page, competition, email, count, paymentStatus, catalogNumber))
        },
        getRegistration: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getRegistration(id))
        },
        getProfileDogs: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getProfileDogs(id))
        },
        getCompetitionDate: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
        },
        getSpecialComp: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getSpecialComp(id))
        },
        setOpenReg: (openReg?: any) => {
            dispatch(CompetitionListStore.actionCreators.setOpenReg(openReg))
        },

        getCompetitionSpecialShow: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionSpecialShow(id))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
};




export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpecialShowFormComponent);
