import React, { Component, Fragment } from "react";
import { httpService } from "../index";
import { UserType } from "../models/registered-user/RegisteredUserEnums";

  
const UserTypeMap: any = {
    "judge": UserType.JUDGE,
    "recorder": UserType.RECORDER,
    "ringLeader": UserType.RING_LEADER,
  };

type defaultProps = {
    suggestions: [],
    data: any,
    action?: any,
    type?: any,
    disabled: any

};

class StaffSearch extends Component<any> {
    timer: any;

    constructor(props: any) {
        super(props);

    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.data !== this.props.data) {
          this.setState({
            userInput: this.props.data && this.props.data[this.props.type] && this.props.data[this.props.type].username,
            inputValue: this.props.data && this.props.data[this.props.type] && this.props.data[this.props.type].username
          });
        }
      }
      

    state = {
        activeSuggestion: 0,
        filteredSuggestions: this.props.suggestions,
        showSuggestions: false,
        userInput: this.props.data && this.props.data[this.props.type] && this.props.data[this.props.type].username,
        inputValue: this.props.data && this.props.data[this.props.type] && this.props.data[this.props.type].username

    };

    handleChange = (e: any) => {
        clearTimeout(this.timer);
        const userInput = e.currentTarget.value;
        this.setState({
            userInput: userInput,
            inputValue: userInput
        });
        this.timer = setTimeout(async () => {
            let users = await httpService.getRegisteredUsers({ username: userInput.toLowerCase(), usertype: UserTypeMap[this.props.type] });
            this.setState({ filteredSuggestions: users });
        }, 1000);
    };

    handleClick = (e: any, key: any) => {

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: this.props.suggestions,
            showSuggestions: !this.state.showSuggestions,

        });
        if (e.currentTarget.innerText !== "") {
            this.props.action(key, this.props.type)
            this.setState({ userInput: e.currentTarget.innerText })
        }


    };


    render() {
        let suggestionsListComponent;
        if (this.state.showSuggestions) {
            if (this.state.filteredSuggestions && this.state.filteredSuggestions.length > 0) {
                suggestionsListComponent = (
                    <div style={{ position: 'relative' }}>
                    <ul style={{ position: 'absolute', zIndex: 9999, top: '100%', left: 0, background: "white" }} className="suggestions">
                        {this.state.filteredSuggestions.map((suggestion: any, index: any) => {
                            let className;
                            // Flag the active suggestion with a class
                            if (index === this.state.activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={className} key={suggestion.id} onClick={(e) => this.handleClick(e, suggestion.id)}>
                                {suggestion.userName}
                                </li>
                            );
                        })}
                    </ul>
                    </div>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions.</em>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <input
                    id={this.props.type}
                    name={this.props.type}
                    required
                    className="form-control"
                    type="text"
                    onClick={(e: any) => this.handleClick(e, e.target.dataset.key)}
                    onChange={(e: any) =>
                        this.handleChange(e)
                    }
                    autoComplete="off"
                    value={this.state.userInput}
                    disabled={this.props.disabled}
                />
                {suggestionsListComponent}
                {this.props.data && this.props.data[this.props.type] &&
                    <input
                        type="hidden"
                        name="staffSearchHidden"
                        value={this.props.data && this.props.data[this.props.type].username}
                    />
                }
            </Fragment>
        );
    }
}


export default (StaffSearch);