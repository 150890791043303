import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as DialogStore from '../../store/DialogStore';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import { Competition } from '../../models/competition/Competition';
import { ListBase } from '../base/ListBase';
import { Helpers } from '../../utility/Helpers';
import ContentStatus from '../../components/ContentStatus';
import CompetitionEdit from './CompetitionEdit';
import CompetitionDetails from './CompetitionDetails';
import { Link } from 'react-router-dom';

const PAGE = 0
const COUNT = 25

class CompetitionList extends ListBase {

    page: number
    count: number

    constructor(props: any, context: any) {
        super(props, context, 'event');
        this.deleteItemCall = async (id: string) => {
            
            await httpService.competitionDelete(id)
            this.props.getItems(this.page, this.count)
            this.props.selectItem(null)
            return 
        }

        this.page = PAGE;
        this.count = COUNT;
    }

    public componentDidMount() {
        super.componentDidMount();
        
        this.props.getCompetitionClasses();
        this.props.getAllBreeds();
        this.props.getCountryItems();
        this.props.getStripeAccountItems();
    }

    render() {
        return (
            <div className="row gap-20 masonry pos-r">
                <div className="masonry-item col-md-5">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <h4 className="c-grey-900 mB-20" style={{ float: "left" }}>Events List</h4>

                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                        <div style={{ float: "right", verticalAlign: "middle" }}>
                            <Link to='/event/add'>
                                <div className="bgc-blue-50 c-blue-500 circle-add">+</div>
                            </Link>
                        </div>
                        }

                        <div style={{ clear: "both" }} />
                        
                        <ContentStatus isLoading={this.props.dataState.isLoading} errorMessage={this.props.dataState.error} data={this.props.dataState.list}></ContentStatus>
                        
                        {(!this.props.dataState.isLoading && !this.props.dataState.error && this.props.dataState.list) &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col" className="ta-c mw-actions-min">Registrations</th>
                                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                            <th scope="col" className="ta-r mw-actions">Actions</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody className="dd-container">
                                    {this.props.dataState.list.map((competition: any, index: number) => (
                                        <tr className={`${this.getActiveClass(competition)} ${Helpers.getPublishedClass(competition)}`}
                                            key={competition.id}>
                                            <td className="cur-p" onClick={(e) => {
                                                e.preventDefault();
                                                this.showDetails(index)
                                            }}>
                                                {competition.title}
                                            </td>
                                            <td className="ta-c">
                                                <span style={{ fontSize: "16px" }}
                                                    className="icon-holder">{competition.registrationCount}({competition.dogRegistrationCount})</span>
                                            </td>
                                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                <td className="ta-r">
                                                    <Link to={`/event/edit/${competition.id}`}>
                                                        <span style={{ fontSize: "16px" }} className="icon-holder"><i
                                                            className="c-light-blue-500 ti-pencil"></i></span>
                                                    </Link>
                                                    <span style={{ fontSize: "16px" }}> | </span>
                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.deleteItem(index, competition.title) }}>
                                                        <span style={{ fontSize: "16px" }} className="icon-holder"><i className="c-light-blue-500 ti-trash"></i></span>
                                                    </a>
                                                </td>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>}
                            {(this.props.dataState.hasMoreItems && !this.props.dataState.isLoading) &&
                            <div style={{
                                width: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <button type="button" className="btn cur-p btn-outline-secondary" disabled={this.props.dataState.isMoreLoading} onClick={() => {
                                    this.page = this.page + 1
                                    this.props.getMoreCompetitions(this.page, this.count)                                    
                                }}

                                >{this.props.dataState.isMoreLoading ? "Loading..." : "Load More"}</button>
                            </div>}
                    </div>
                </div>
                <div className="masonry-item col-md-7">
                    <div className="bgc-white p-20 bd cnt-scroll">
                        <Route exact path='/event/details/:itemId?' component={CompetitionDetails}/>
                        <Route exact path='/event/edit/:itemId?' component={CompetitionEdit}/>
                        <Route exact path='/event/add' component={CompetitionEdit}/>
                    </div>
                </div>
            </div>)
    }
};

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.competitionList, userState: state.user };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) },
        selectItem: (data: Competition) => { dispatch(CompetitionListStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(CompetitionListStore.actionCreators.getItems(PAGE, COUNT)) },
        getMoreCompetitions: (page: number, count: number) => { dispatch(CompetitionListStore.actionCreators.getMoreCompetitions(page, count)) },
        getCountryItems: () => { dispatch(CompetitionListStore.actionCreators.getCountryItems()) },
        getCompetitionClasses: () => { dispatch(CompetitionListStore.actionCreators.getCompetitionClasses()) },
        getAllBreeds: () => { dispatch(CompetitionListStore.actionCreators.getAllBreeds()) },
        getStripeAccountItems: () => { dispatch(CompetitionListStore.actionCreators.getStripeAccountItems()) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompetitionList);
