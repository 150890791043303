import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import * as DialogStore from '../../store/DialogStore';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ApplicationState } from "../../store";
import { httpService } from "../../index";
import * as DogListStore from '../../store/DogListStore';
import { DialogType, NotificationData, NotificationType } from "../../store/DialogStore";
import RegistrationLoader from "../RegistrationLoader";
import { DogHistory } from "../../models/dog/Dog";
import { Link } from "react-router-dom";
import { Helpers } from "../../utility/Helpers";


export interface Props {
    dataId: string,
    open: boolean,
    type: string
}

const DOG = 'dog';
const USER = 'user';


class ActionHistoryComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }


    state: any = {
        isOpen: false,
        modal: true,
        loading: "",
        isDataLoading: true,
        data: null

    };



    toggle = () => {
        this.setState({
            modal: !this.state.modal

        });
    }


    async openModal(type: string) {

        let action: any;

        switch (type) {
            case DOG: {
                action = async () => { return await httpService.getDogHistory(this.props.dataId) }
                break;
            }
            case USER: {
                action = async () => { return await httpService.getUserHistory(this.props.dataId) }
                break;
            }
            
        }

        try {
            this.setState({ loading: type });

            let res = await action();

            this.setState({
                data: res, isOpen: true
            })
        } catch (err) {
            this.setState({ loading: '' });
            alert(err.message);
        }
    }

    closeModal() {

        this.setState({
            isOpen: false,
            data: null
        })

    }

    render() {
        return (
            <React.Fragment>
                <Button style={{ color: "white" }} color="info"
                    onClick={() => {
                        this.openModal(this.props.type)
                    }
                    }>Show Action History</Button>

                <br />

                <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.state.isOpen}>
                    <ModalHeader toggle={() => this.closeModal()}>Action History</ModalHeader>
                    <ModalBody>

                        {!this.state.data ? <RegistrationLoader /> :
                            
                            <div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Created</th>
                                            <th>User</th>
                                            <th>Dog</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="dd-container">
                                        {this.state.data.length > 0 && this.state.data.map((item: any, index: any) =>

                                            <tr key={item.id}>
                                                <td style={{ color: "grey" }}>{Helpers.getLocalDateTimeString(item.created)}</td>
                                                <td style={{ color: "grey" }}>{item.profile.username}</td>
                                                <td style={{ color: "grey" }}>{item.dog && item.dog.name}</td>
                                                <td style={{ color: "grey" }}>{item.action}</td>
                                            </tr>

                                        )}
                                    </tbody>

                                </table>
                                {this.state.data && this.state.data.length < 1 &&
                                    <div style={{ color: "grey", display: "flex", justifyContent: "center" }} className="cur-p">No history.</div>
                                }

                            </div>
                        }
                       
                    </ModalBody>
                <ModalFooter>
                   
                    <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                </ModalFooter>

            </Modal>
          

            </React.Fragment>
        );

    }

}


const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.dogList };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
};




export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionHistoryComponent);
