import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import { Table } from "reactstrap";

import { getBISScore, getBODBISScoreType, getBreedBISScoreType, getCoupleGroupBISScoreType, getDogBISScoreType, getFinalBISScoreType, getJSHBISScore, getJrBISScoreType, getJrBODBISScoreType, getJuniorSeniorBISScoreType, getPuppyBISScoreType, getVeteranBISScoreType } from "../../models/registrations/RegistrationEnums";



type Props = {
    data: any;
    action: any,
    previewMode?: any,
    type?: any,
    dataType: string

}

class CompetitionBISScoreComponent extends React.Component<any> {
    constructor(props: any) {
        super(props);

    }

    itemsByDataType(inputString: string) {
        switch (inputString) {
            case 'dog':
                return getDogBISScoreType();
            case 'juniorSenior':
                return getJuniorSeniorBISScoreType();
            case 'coupleGroup':
                return getCoupleGroupBISScoreType();
            case 'bodBis':
                return getBODBISScoreType();
            case 'breedBis':
                return getBreedBISScoreType();
            case 'finalBis':
                return getFinalBISScoreType();
            case 'jrBis':
                return getJrBISScoreType();
            case 'jrBodBis':
                return getJrBODBISScoreType();
            case 'puppyBis':
                return getPuppyBISScoreType();
            case 'veteranBis':
                return getVeteranBISScoreType();


            default:
                return getDogBISScoreType();

        }
    }
    scoreByDataType(inputString: string) {
        switch (inputString) {
            case 'juniorSenior':
                return getJSHBISScore();
            default:
                return getBISScore();

        }
    }


    render() {
        return (
            <React.Fragment>
                <div>

                    <br />
                    <Table size="sm" bordered>

                        <tbody>

                            <tr>
                                <th>Is Present</th>
                                <td>
                                    <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }} className="checkbox checkbox-circle checkbox-info peers ai-c" >

                                        <input id="isPresent" name="isPresent"
                                            type="checkbox" checked={this.props.data && this.props.data.isPresent}
                                            className="peer"
                                            disabled={this.props.previewMode}
                                            onChange={(e: any) => this.props.action(e, this.props.type)} />
                                        <label>
                                            <span
                                                className="peer peer-greed"></span></label>

                                    </div>
                                </td>
                            </tr>


                            <tr>
                                <th>BIS Score Type</th>
                                <td>
                                    <select style={{ border: "none" }} id="bisScoreType" name="bisScoreType"
                                        className="form-control"
                                        required
                                        disabled={this.props.previewMode}
                                        defaultValue={this.props.data && this.props.data.bisScoreType}
                                        onChange={(e) => this.props.action(e, this.props.type)}>
                                        <option value="">-- select BIS Score Type --</option>
                                        {this.itemsByDataType(this.props.dataType).map((item: any, index: number) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <th>BIS Score</th>
                                <td>

                                    <select style={{ border: "none" }} id="bisScore" name="bisScore"
                                        className="form-control"
                                        required
                                        disabled={this.props.previewMode}
                                        value={this.props.data && this.props.data.bisScore}
                                        onChange={(e) => this.props.action(e, this.props.type)}>
                                        <option value="">-- select BIS Score --</option>

                                        {this.scoreByDataType(this.props.dataType).map((item: any, index: number) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>

                                </td>
                            </tr>



                        </tbody>
                    </Table>


                </div>


            </React.Fragment>
        );

    }

}


export default connect(

)(CompetitionBISScoreComponent);
