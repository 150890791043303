import * as React from 'react';
import * as ProfileStore from '../store/ProfileStore';
import * as DialogStore from '../store/DialogStore';
import {ApplicationState} from '../store';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import {NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import {history, httpService} from '../index';


import logo from '../assets/static/images/logo-wh.svg';
import {Helpers} from "../utility/Helpers";
import {getUserRoleTitle, UserType} from "../models/registered-user/RegisteredUserEnums";
import {RegisteredUser} from "../models/registered-user/RegisteredUser";


type RolesProps =
    ProfileStore.ProfileState &
    typeof ProfileStore.actionCreators &
    typeof DialogStore.actionCreators &
    RouteComponentProps<{}>;

class SideMenu extends React.PureComponent<RolesProps, { version: string, pathname: string, dropdownOpen: boolean }> {

    unlisten: any;

    constructor(props: any) {
        super(props);
        this.state = {version: '...', pathname: history.location.pathname, dropdownOpen: false};
    }

    componentDidMount() {
        this.getData();
        this.unlisten = history.listen((location) => {
            let st = {...this.state};
            st.pathname = location.pathname;
            this.setState(st);
        })
    }

    componentWillUnmount() {
        this.unlisten();
    }

    async getData() {
        let data: any = await httpService.getVersion();
        this.setState({version: data.version});
    }

    //used for preventing click on current active menu
    handleCurrentRoute(listUrl: string) {
        return this.state.pathname.startsWith(listUrl) ? '#' : listUrl;
    }

    isActive(url: string, exact: boolean = false): boolean {
        if (exact) {
            return this.state.pathname === url;
        } else {
            return this.state.pathname.startsWith(url);
        }
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    public render() {
        return (
            <div className="sidebar">
                <div className="sidebar-inner">
                    <div className="sidebar-logo">
                        <div className="peers ai-c fxw-nw">
                            <div className="peer peer-greed">
                                <a className="sidebar-link td-n" href="/">
                                    <div className="peers ai-c fxw-nw">
                                        <div className="peer">
                                            <div className="logo">
                                                <img style={{maxWidth: "49px", marginTop: "7px", marginLeft: "10px"}}
                                                     src={logo} alt=""></img>
                                            </div>
                                        </div>
                                        <div className="peer peer-greed">
                                            <h4 className="lh-1 mB-0 mL-10 logo-text c-her-white">E-Dog CMS</h4>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="peer">
                                <div className="mobile-toggle sidebar-toggle">
                                    <a href="" className="td-n">
                                        <i className="ti-arrow-circle-left"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="sidebar-menu scrollable pos-r">
                        <li className={`nav-item mT-30 ${this.isActive('/', true) ? 'active' : ''}`}>
                            <NavLink tag={Link} className="sidebar-link" to="/">
                                <span className="icon-holder">
                                    <i className="c-blue-500 ti-home"></i>
                                </span>
                                <span className="title">Home</span>
                            </NavLink>
                        </li>
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN || UserType.EVENT_VIEWER), this.props.user, "event") &&
                            <li className={`nav-item ${this.isActive('/event', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/event">
                            <span className="icon-holder">
                                    <i className="c-blue-500 ti-medall"></i>
                            </span>
                                    <span className="title">Events</span>
                                </NavLink>
                            </li>
                        )}
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN || UserType.DOG_VIEWER || UserType.EVENT_VIEWER), this.props.user, "dog") &&
                            <li className={`nav-item ${this.isActive('/dog', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/dog">
                                <span className="icon-holder">
                                    <i className="c-blue-500 fa fa-paw fa-light" aria-hidden="true"></i>
                                </span>
                                    <span className="title">Dog</span>
                                </NavLink>
                            </li>
                        )}
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN), this.props.user) &&
                            <li className={`nav-item ${this.isActive('/competition-class', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/competition-class">
                                <span className="icon-holder">
                                    <i className="c-blue-500 fa fa-paw fa-light" aria-hidden="true"></i>
                                </span>
                                    <span className="title">Class</span>
                                </NavLink>

                            </li>
                        )}
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN), this.props.user) &&
                            <li className={`nav-item ${this.isActive('/breed', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/breed">
                                <span className="icon-holder">
                                    <i className="c-blue-500 fa fa-list-alt" aria-hidden="true"></i>
                                </span>
                                    <span className="title">Breeds</span>
                                </NavLink>
                            </li>
                        )}
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN), this.props.user) &&
                            <li className={`nav-item ${this.isActive('/fci-category', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/fci-category">
                                <span className="icon-holder">
                                    <i className="c-blue-500 fa fa-list" aria-hidden="true"></i>
                                </span>
                                    <span className="title">FCI Categories</span>
                                </NavLink>
                            </li>
                        )}
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN), this.props.user) &&
                            <li className={`nav-item ${this.isActive('/pedigree-abbreviation', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/pedigree-abbreviation">
                                <span className="icon-holder">
                                    <i className="c-blue-500 fa fa-font" aria-hidden="true"></i>
                                </span>
                                    <span className="title">Pedigree Abbreviation</span>
                                </NavLink>
                            </li>
                        )}
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN), this.props.user) &&
                            <li className={`nav-item ${this.isActive('/country', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/country">
                                <span className="icon-holder">
                                    <i className="c-blue-500 fa fa-globe" aria-hidden="true"></i>
                                </span>
                                    <span className="title">Country</span>
                                </NavLink>
                            </li>
                        )}
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN), this.props.user) &&
                            <li className={`nav-item ${this.isActive('/stripeaccount', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/stripeaccount">
                                <span className="icon-holder">
                                    <i className="c-blue-500" aria-hidden="true">SA</i>
                                </span>
                                    <span className="title">Stripe Accounts</span>
                                </NavLink>
                            </li>
                        )}
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN), this.props.user) &&
                            <li className={`nav-item ${this.isActive('/registered-user', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/registered-user">
                                <span className="icon-holder">
                                    <i className="c-blue-500 ti-user"></i>
                                </span>
                                    <span className="title">Users</span>
                                </NavLink>
                            </li>
                        )}
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN), this.props.user) &&
                            <li className={`nav-item ${this.isActive('/invoice-information', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/invoice-information">
                                    <span className="icon-holder">
                                        <i className="c-blue-500 fa fa-info"></i>
                                    </span>
                                    <span className="title">Invoice Information</span>
                                </NavLink>
                            </li>
                        )}
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN), this.props.user) &&
                            <li className={`nav-item ${this.isActive('/invoice-issuer', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/invoice-issuer">
                                    <span className="icon-holder">
                                        <i className="c-blue-500 ti-id-badge"></i>
                                    </span>
                                    <span className="title">Invoice Issuer</span>
                                </NavLink>
                            </li>
                        )}
                        {(Helpers.isUserInRole(this.props.selectedCountry!, getUserRoleTitle(UserType.ADMIN), this.props.user) &&
                            <li className={`nav-item ${this.isActive('/notification', false) ? 'active' : ''}`}>
                                <NavLink tag={Link} className="sidebar-link" to="/notification">
                                    <span className="icon-holder">
                                        <i className="c-blue-500 ti-bell"></i>
                                    </span>
                                    <span className="title">Notification</span>
                                </NavLink>
                            </li>
                        )}
                    </ul>
                    <div style={{display: "inline-block", height: "30px", width: "100%", textAlign: "center"}}>
                        V {this.state.version}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => {
        return state.user
    },
    (dispatch: any) => {
        return {
            increment: () => {
                dispatch(DialogStore.actionCreators.increment())
            },
            decrement: () => {
                dispatch(DialogStore.actionCreators.decrement())
            },
            setUser: (user: RegisteredUser) => {
                dispatch(ProfileStore.actionCreators.setUser(user))
            }
        }
    }
)(SideMenu as any);
