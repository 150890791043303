import React from 'react';
import { Component } from "react";
import logoTV from '../../assets/static/images/Logo_tv.svg';

type defaultProps = {
    topBarTitle: any;
};

class TVTopBar extends Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    public render() {
        const currentTime = new Date()

        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();

        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        return (
            <table className="tv_color_main_bg topbar_size">
                <tr>
                    <td className="topbar_logo">
                        <div className="logo center_align_elements">
                            <img className="topbar_logo_img" src={logoTV} alt=""></img>
                        </div>
                    </td>
                    <td className="topbar_title">
                        <h2 className= "tv_grey helvetica_regular_64">
                            {this.props.title}
                        </h2>
                        
                    </td>
                    <td className="topbar_time">
                        <h2 className="tv_grey helvetica_regular_64" style={{ textAlign: "right" }}>
                            {formattedTime}
                        </h2>
                    </td>
                </tr>
            </table>
        )
    }
}


export default (TVTopBar as any);