import React from "react";
import { connect } from "react-redux";
import '../../custom.scss';
import * as DialogStore from '../../store/DialogStore';
import { NotificationData } from '../../store/DialogStore';
import { getPaymentStatus, PaymentStatus } from "../../models/registrations/Registrations";
import { ApplicationState } from "../../store/index";
import * as CompetitionListStore from '../../store/CompetitionListStore';
import { Helpers } from "../../utility/Helpers";
import { Button } from "reactstrap";
import { httpService } from "../../index";
import { Competition } from "../../models/competition/Competition";
import DogRegistrationForm from "../../views/competition/DogRegistrationForm";
import CoupleGroupRegistrationForm from "../../views/competition/CoupleGroupRegistrationForm";
import SpecialShowRegistrationForm from "../../views/competition/SpecialShowRegistrationForm";
import ContentStatus from "../ContentStatus";
import RegistrationForm from "../../views/competition/RegistrationForm";
import JuniorSeniorHandlingForm from "../../views/competition/JuniorSeniorHandlingForm";
import RegistrationLoader from "../RegistrationLoader";
import FullLoader from "../FullLoader/FullLoader";

type PreviewProps = {
    data?: any;
    details?: any;
    index?: any;
    isDataLoading?: any
}

let INVOICE = 'invoice';
let INVOICE_FEE = 'invoice-fee';

class PreviewPopUpComponent extends React.Component<any> {

    codeError: string;

    constructor(props: any) {
        super(props);

        this.codeError = '';
    }

    state = {
        isOpen: false,
        modal: false,
        loading: "",
        processing: false,
        editReg: false,
        editMode: false,
        add: false,
        previewMode: false,
        editItem: "",
        newData: this.props.dataState.registration,
        currentIndex: 0,
        registrationId: "",
        dropDown: false,
        dogType: '',
        itemConfirmed: false,

    };

    handleShowDialog = (id: string) => {

        this.setState({
            modal: !this.state.modal, editMode: false, registrationId: id, previewMode: false
        });
        this.props.getRegistration(id);
        this.props.setOpenReg(true);
        this.setState({ newData: this.props.dataState.registration })

    };

    editReg = () => {
        this.setState({ editReg: !this.state.editReg })
    }


    backToList = () => {
        this.props.setOpenReg(false)
        this.setState({ isOpen: !this.state.isOpen });
        this.props.getRegistration(null)
        this.props.getCompetitionDog(null)
        this.props.getCompetitionJuniorSenior(null)
        this.props.getCompetitionCoupleGroup(null)
        this.props.getCompetitionSpecialShow(null)
        this.props.getRegistrationItems(
            null,
            this.props.dataState.selectedItem.id,
            this.props.dataState.email,
            this.props.dataState.count,
            this.props.dataState.status,
            this.props.dataState.catalogNumber,
            this.props.dataState.compType,
            this.props.dataState.compDateId,
            this.props.dataState.dogOwner,
            this.props.dataState.dogName
        )
        this.setState({
            modal: !this.state.modal, editMode: false, previewMode: false
        });
    };

    toggle = () => {
        this.setState({
            newData: { ...this.props.dataState.registration }
        });

    }

    async confirmItem(item: any) {
        try {
            this.props.showDialog(`Are you sure you want to confirm "${item ? item.email : "this item"}?"`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
                if (confirm) {
                    await httpService.competitionRegistrationConfirm(item.id);
                    if (this.props.dataState.selectedItem && this.props.dataState.selectedItem.id === item.id) {
                        this.props.selectItem(null);
                    }
                    this.setState({ itemConfirmed: true })
                    this.props.getRegistration(item.id);
                }
            })
        } catch (err) {
            alert(err.message);
        }
    }

    async processClick(type: any) {

        let action: any;

        switch (type) {
            case INVOICE: {
                action = async () => { return await httpService.generateInvoice(this.props.dataState.registration.id) }
                break;
            }
            case INVOICE_FEE: {
                action = async () => { return await httpService.generateInvoiceFee(this.props.dataState.registration.id) }
                break;
            }
        }
        try {
            this.setState({ loading: type });
            let res = await action();
            this.setState({ loading: '' });
        } catch (err) {
            this.setState({ loading: '' });
            alert(err.message ? err.message : err.title);

        }
    }

    render() {
        return (
            <React.Fragment>
                {!this.state.modal && !this.props.dataState.openReg &&
                    <div>
                        <RegistrationForm action={this.editReg} isOpen={this.state.editReg} />
                        {this.props.isDataLoading ?
                            <div style={{ position: "relative", marginLeft: "50%", marginRight: "50%" }}>
                                <RegistrationLoader />
                            </div>
                            :

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">First name</th>
                                        <th scope="col">Last name</th>

                                        <th scope="col">Payment Date and time</th>
                                        <th scope="col">Payment Status</th>
                                        {/*    <th scope="col" className="ta-r mw-actions">Actions</th>*/}
                                    </tr>
                                </thead>
                                <tbody className="dd-container">

                                    {this.props.dataState.registrations && this.props.dataState.registrations.map((registration: any, index: number) => (

                                        <tr key={registration.id}>

                                            <td style={{ color: "grey" }} className="cur-p"
                                                onClick={() => {
                                                    this.handleShowDialog(registration.id);
                                                    this.toggle();
                                                }}>
                                                {index + 1}
                                            </td>
                                            <td style={{ color: "grey" }} className="cur-p"
                                                onClick={() => {
                                                    this.handleShowDialog(registration.id);
                                                    this.toggle();
                                                }}>
                                                {registration.email}
                                            </td>
                                            <td style={{ color: "grey" }} className="cur-p" onClick={() => {
                                                this.handleShowDialog(registration.id);
                                                this.toggle();
                                            }}>
                                                {registration.firstName}
                                            </td>
                                            <td style={{ color: "grey" }} className="cur-p" onClick={() => {
                                                this.handleShowDialog(registration.id);
                                                this.toggle();
                                            }}>
                                                {registration.lastName}
                                            </td>
                                            <td style={{ color: "grey" }} className="cur-p" onClick={() => {
                                                this.handleShowDialog(registration.id);
                                                this.toggle();
                                            }}>
                                                {Helpers.getLocalDateTimeString(registration.paymentDateTime)}

                                            </td>


                                            <td style={{ color: "grey" }} className="cur-p" onClick={() => {
                                                this.handleShowDialog(registration.id);
                                                this.toggle();
                                            }}>
                                                {getPaymentStatus(registration.paymentStatus)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }

                    </div>
                }
                {this.props.dataState.openReg && !this.props.dataState.registration &&
                    <ContentStatus isLoading={true}></ContentStatus>
                }

                {this.state.modal && this.props.dataState.openReg && (
                    (this.props.dataState.registration) &&

                    <div>
                        <div style={{ marginBottom: "15px" }}>
                            <Button style={{ padding: 0 }} color="link" onClick={this.backToList}>Back to list</Button>
                        </div>
                        {this.props.dataState.registration.paymentStatus !== PaymentStatus.DOGGLE_IMPORTED && this.props.dataState.registration.paymentStatus !== PaymentStatus.SUCCESSFUL && this.state.itemConfirmed &&

                            <div>
                                {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                    < Button style={{ float: "right" }} color="success" onClick={(e) => {
                                        e.preventDefault();
                                        this.confirmItem(this.props.dataState.registration)
                                    }}>
                                        Confirm</Button>
                                }
                            </div>

                        }
                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                            <div style={{ display: "flex" }}>

                                <span onClick={() => this.editReg()} style={{ position: "sticky", top: 0, marginLeft: "auto", alignSelf: "flex-end", cursor: "pointer" }} className="icon-holder"><i className="c-light-blue-500 ti-pencil" aria-hidden="true"></i></span>

                            </div>
                        }


                        {this.state.editReg &&
                            <RegistrationForm action={this.editReg} isOpen={this.state.editReg} />
                        }
                        <div className="row">

                            <div className="mb-1 col-md-3">Created:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.registration && this.props.dataState.registration.created && Helpers.getLocalDateTimeString(this.props.dataState.registration.created)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">First Name:</div>
                            <div className="mb-1 col-md-9"> {(this.props.dataState.registration && this.props.dataState.registration.firstName && this.props.dataState.registration.firstName)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Last Name:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.registration && this.props.dataState.registration.lastName && (this.props.dataState.registration.lastName)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Email:</div>
                            <div className="mb-1 col-md-9"> {(this.props.dataState.registration && this.props.dataState.registration.email && this.props.dataState.registration.email)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Phone Number:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.registration && this.props.dataState.registration.phoneNumber && this.props.dataState.registration.phoneNumber.prefix && this.props.dataState.registration.phoneNumber.prefix} {this.props.dataState.registration && this.props.dataState.registration.phoneNumber && this.props.dataState.registration.phoneNumber.number && this.props.dataState.registration.phoneNumber.number}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Address:</div>
                            <div className="mb-1 col-md-9"> {(this.props.dataState.registration && this.props.dataState.registration.address && this.props.dataState.registration.address)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">City:</div>
                            <div className="mb-1 col-md-9"> {(this.props.dataState.registration && this.props.dataState.registration.city && this.props.dataState.registration.city)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Country:</div>
                            <div className="mb-1 col-md-9"> {(this.props.dataState.registration && this.props.dataState.registration.country && this.props.dataState.registration.country.name)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Profile:</div>
                            <div className="mb-1 col-md-9"> {(this.props.dataState.registration && this.props.dataState.registration.profile && this.props.dataState.registration.profile.username)}</div>

                        </div>

                        <br />
                        <hr />

                        <h5>Payment Information</h5>
                        <div className="row">

                            <div className="mb-1 col-md-3">Payment Status:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.registration && this.props.dataState.registration.paymentStatus && getPaymentStatus(this.props.dataState.registration.paymentStatus)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Payment Intent ID:</div>
                            <div className="mb-1 col-md-9"> {(this.props.dataState.registration && this.props.dataState.registration.paymentIntentId && this.props.dataState.registration.paymentIntentId)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Payment Amount:</div>
                            <div className="mb-1 col-md-9"> {(this.props.dataState.registration && this.props.dataState.registration.paymentAmount && this.props.dataState.registration.paymentAmount)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Payment Date and Time:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.registration && this.props.dataState.registration.paymentDateTime && Helpers.getLocalDateTimeString(this.props.dataState.registration.paymentDateTime)}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Payment Fee:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.registration && this.props.dataState.registration.paymentFee && this.props.dataState.registration.paymentFee}</div>

                        </div>
                        <div className="row">

                            <div className="mb-1 col-md-3">Fee Invoice Number:</div>
                            <div className="mb-1 col-md-9"> {this.props.dataState.registration && this.props.dataState.registration.feeInvoiceNumber && this.props.dataState.registration.feeInvoiceNumber}</div>

                        </div>
                            <br />
                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                <div style={{ display: "flex", gap: "15px", width: "400px" }}>
                                    <Button id="invoice" color="info"
                                        style={{ color: "white" }}
                                        onClick={() => {
                                            this.processClick(INVOICE)
                                        }}
                                        disabled={this.state.loading === INVOICE && true}
                                    >Generate Invoice

                                        {this.state.loading === INVOICE &&
                                            <i className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px", marginLeft: "10px" }} />
                                        }
                                    </Button>
                                    <Button id="invoice-fee" color="info"
                                        style={{ color: "white" }}
                                        onClick={() => {
                                            this.processClick(INVOICE_FEE)
                                        }}
                                        disabled={this.state.loading === INVOICE_FEE && true}
                                    >Generate Invoice Fee

                                        {this.state.loading === INVOICE_FEE &&
                                            <i className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px", marginLeft: "10px" }} />
                                        }
                                    </Button>

                                </div>
                            }
                        <br />
                        <div>

                            <DogRegistrationForm />
                            <CoupleGroupRegistrationForm />
                            <SpecialShowRegistrationForm />
                            <JuniorSeniorHandlingForm />

                        </div>
                    </div>




                )}


            </React.Fragment>

        );
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        dataState: state.competitionList,
        userState: state.user
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: Competition) => { dispatch(CompetitionListStore.actionCreators.selectItem(data)) },

        getItems: () => {
            dispatch(CompetitionListStore.actionCreators.getItems())
        },
        getRegistrationItems: (page?: any, competition?: string, email?: string, count?: number, paymentStatus?: any, catalogNumber?: any, type?: any, competitionDateId?: any, dogOwner?: string, dogName?: string) => {
            dispatch(CompetitionListStore.actionCreators.getRegistrationItems(page, competition, email, count, paymentStatus, catalogNumber, type, competitionDateId, dogOwner, dogName))
        },
        getRegistration: (id: any) => {
            dispatch(CompetitionListStore.actionCreators.getRegistration(id))
        },
        getProfileDogs: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getProfileDogs(id))
        },
        setOpenReg: (openReg?: any) => {
            dispatch(CompetitionListStore.actionCreators.setOpenReg(openReg))
        },
        setCatalogNumber: (catalogNumber?: any) => {
            dispatch(CompetitionListStore.actionCreators.setCatalogNumber(catalogNumber))
        },
        setName: (name?: any) => {
            dispatch(CompetitionListStore.actionCreators.setName(name))
        },
        setPage: (page?: any) => {
            dispatch(CompetitionListStore.actionCreators.setPage(page))
        },
        setPaymentStatus: (status?: any) => {
            dispatch(CompetitionListStore.actionCreators.setPaymentStatus(status))
        },
        getCompetitionDog: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDog(id))
        },
        getCompetitionCoupleGroup: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionCoupleGroup(id))
        },
        getCompetitionSpecialShow: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionSpecialShow(id))
        },
        getCompetitionJuniorSenior: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionJuniorSenior(id))
        },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showNotification: (data: NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewPopUpComponent);
