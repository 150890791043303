import * as React from 'react';
import ContentStatus from '../../components/ContentStatus';
import { connect } from 'react-redux';
import * as DialogStore from '../../store/DialogStore';
import { httpService } from '../../index';
import { ApplicationState } from '../../store';
import * as StripeAccountStore from '../../store/StripeAccountStore';
import { DetailsBase } from '../base/DetailsBase';
import DetailsHeader from '../../components/DetailsHeader';
import { Helpers } from '../../utility/Helpers';
import { StripeAccount } from '../../models/stripe-account/StripeAccount';


class StripeAccountDetails extends DetailsBase<StripeAccount> {
    constructor(props: any) {
        super(props);
        this.getItem = async (id: string) => {
            let res = await httpService.getStripeAccount(id);
            return res;
        }
        this.saveItem = async (data: StripeAccount) => { return await httpService.stripeAccountAddOrUpdate(data) }
        this.deleteItemCall = async (id: string) => { return await httpService.stripeAccountDelete(id) }
    }

    render() {
        return (
            <div>
                <DetailsHeader baseUrl="sripeaccount" data={this.state.data}
                               title={this.state.data ? this.state.data.name : "Stripe Account Preview"} onDelete={() => {
                    this.deleteItem()
                }}/>

                <ContentStatus isLoading={this.state.isLoading} errorMessage={this.state.error} data={this.state.data}></ContentStatus>
                {(this.state && this.state.data) &&
                    <div>
                        <div className="row">
                            <div className="mb-1 col-md-3">Created:</div>
                            <div className="mb-1 col-md-9"> {Helpers.getLocalDateTimeString(this.state.data.created)}</div>
                        </div>
                        <div className="row">
                            <div className="mb-1 col-md-3">Name:</div>
                            <div className="mb-1 col-md-9"> {this.state.data.name}</div>
                        </div>
                        <div className="row">
                            <div className="mb-1 col-md-3">Stripe Key:</div>
                            <div className="mb-1 col-md-9 wow-bw"> {this.state.data.stripeKey}</div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return { dataState: state.stripeAccountList };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectItem: (data: StripeAccount) => { dispatch(StripeAccountStore.actionCreators.selectItem(data)) },
        getItems: () => { dispatch(StripeAccountStore.actionCreators.getItems()) },
        increment: () => { dispatch(DialogStore.actionCreators.increment()) },
        decrement: () => { dispatch(DialogStore.actionCreators.decrement()) },
        showNotification: (data: DialogStore.NotificationData) => { dispatch(DialogStore.actionCreators.showNotification(data)) },
        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => { dispatch(DialogStore.actionCreators.showDialog(m, t, cal)) }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StripeAccountDetails);
