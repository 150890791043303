import * as React from 'react';
import { connect } from 'react-redux';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { ApplicationState } from '../../store/index';
import { httpService } from "../../index";
import * as DialogStore from '../../store/DialogStore';
import { CompetitionRing, Judge, Recorder, RingLeader, SpecialComp } from '../../models/competition/CompetitionDate';
import { NotificationType } from '../../store/DialogStore';
import * as CompetitionListStore from '../../store/CompetitionListStore';
import DateTimePicker from '../../components/DateTimePicker';
import { Helpers } from '../../utility/Helpers';
import Dragula from 'react-dragula';
import { Breed } from '../../models/dog/Breed';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import RegistrationLoader from '../RegistrationLoader';
import BreedSearch from '../BreedSearch';
import StaffSearch from '../StaffSearch';


let SINGLE_BREED = 0;
let GROUP_BREEDS = 1;

type RingDialogProps =
    DialogStore.DialogState &
    typeof DialogStore.actionCreators &
    React.ComponentProps<any>;

export type RingDialogData = {
    data: any,
    callback: Function,
    parentId: '',
    judge: Array<Judge>,
    recorder: Array<Recorder>,
    ringLeader: Array<RingLeader>,
    breeds: any,
    action: Function,
    open: any;
    addMode: any
}

class RingDialog extends React.PureComponent<RingDialogProps, any> {

    time: any;
    drake: any;
    timeout: any = null;
    fromIndex: any;
    toIndex: any;
    deleteItemCall: any = () => { alert('DeleteItem Not set !!!') };
    reorderItemCall: any = () => { alert('ReorderItem Not set !!!') };
    originalData?: any;


    constructor(props: any) {
        super(props);

        this.fromIndex = -1;
        this.toIndex = -1;

        this.state = {
            editBreed: false,
            expandBreeds: false,
            fciSelected: [],
            breedListExpanded: false,
            checkedBreeds: [],
            breedAddType: SINGLE_BREED,
            previewMode: this.props.addMode ? false : true,
            currentIndex: null,
            selectedBreed: null,
            ring: null,
            showPopup: false,
            breedArray: [],
            addBreedMode: false,
            editBreedMode: false,
            judge: this.props.judge,
            recorder: this.props.recorder,
            ringLeader: this.props.ringLeader,
            breeds: this.props.breeds,
            fciBreeds: [],
            fciCategories: [],
            availableBreeds: [],
            indeterminateGroups: [],
            emptyBreed: {
                approximateFrom: '',
                approximateTo: '',
                breed: {},
                breedFinished: false,
                catalogNumberFrom: 0,
                catalogNumberTo: 0,
                order: 0

            },
            disableButton: false
        }
    }

    componentDidMount() {
        this.dataChanged();
        this.getFciGroups();

    }

    componentDidUpdate(prevProps: any) {
        if (this.props.data != prevProps.data) {
            this.time = setTimeout(() => {
                this.dataChanged();
                this.clearTimeout();
            }, 0)
        }
    }

    componentWillUnmount() {
        this.clearTimeout();
    }

    private clearTimeout() {
        if (this.time) {
            clearTimeout(this.time);
            this.time = null;
        }
    }

    openEdit() {
        this.setState({
            previewMode: !this.state.previewMode,
        })
    }

    saveBreed = async (index: any) => {
        const selectedBreed = { ...this.state.selectedBreed };
        const groupBreeds = [...this.state.availableBreeds];
        let breeds = [...(this.state.breedArray || [])];

        if (Object.keys(selectedBreed).length !== 0 && this.state.breedAddType == SINGLE_BREED) {
            const existingBreedIndex = breeds.findIndex((i: any) => i.breed.id === selectedBreed.breed.id);
            if (existingBreedIndex === -1) {
                selectedBreed.order = breeds.length + 1;
                if (breeds[index]) {
                    breeds[this.state.currentIndex] = selectedBreed;
                } else {
                    breeds.push(selectedBreed);
                }
            } else {
                breeds[existingBreedIndex] = selectedBreed;
            }
            this.setState({ breedArray: breeds, editBreed: false });

        } else if (groupBreeds && groupBreeds.length > 0) {
            this.setState({ selectedBreed: null });
            let filteredBreeds = groupBreeds.filter((item: any) => {
                const id = item.breed.id;
                const isDuplicate = breeds.some((breed: any) => breed.breed.id === id);
                return !isDuplicate;
            });
            filteredBreeds.sort((a, b) => a.breed.name.localeCompare(b.breed.name))
            breeds = breeds.concat(filteredBreeds);
            this.setState({ breedArray: breeds, editBreed: false, addBreedMode: false, availableBreeds: [], fciSelected: [], checkedBreeds: [], breedAddType: SINGLE_BREED });
        }
    }

    saveChanges = async (data: CompetitionRing) => {
        let st = { ...this.state }
        if (st.breedArray) {
            let breeds = [...this.state.breedArray]
            for (let i = 0; i < breeds.length; i++) {
                breeds[i].order = i + 1;
            }
            data.breeds.length = 0
            data.breeds.push.apply(data.breeds, breeds)
        }

        try {
            this.setState({ disableButton: true })
            let res = await httpService.adddOrUpdateRing(data);
            if (!data.id) {
                this.props.add({ description: res.description, startTime: res.startTime, id: res.id }, false)
            }
            this.props.getCompetitionDate(data.competitionDateId)
            this.setState({ disablebutton: false })
            this.close(true);
            this.props.action()
            this.setState({ selectedBreed: null, currentIndex: null, editBreed: false, ring: null, breedArray: [] })
            this.props.showNotification({ message: 'Saved.', type: NotificationType.Success });


        } catch (err) {
            this.props.decrement();
            alert(err.message);
            this.setState({ disableButton: false })
        }
    }

    close(res: boolean) {
        this.props.hideRingDialog();
        this.props.closePreview()
        this.setState({ ring: null })

    }

    closeBreedEdit() {
        this.setState({ editBreed: false, selectedBreed: null, currentIndex: null, breedAddType: SINGLE_BREED, fciBreeds: [], availableBreeds: [], fciSelected: [], checkedBreeds: [], addBreedMode: false, indeterminateGroups: [] })
    }

    openBreedEdit(index: any) {

        this.setState({
            editBreed: !this.state.editBreed, selectedBreed: this.state.breedArray[index], currentIndex: index
        })
    }

    removeBreed(index: number) {
        let data = [...this.state.breedArray]
        data.splice(index, 1)
        data.forEach((item: any, i: any) => {
            item.order = i + 1
        })
        this.setState({ breedArray: data })
    }
    addEmptyBreed = () => {
        this.setState({ selectedBreed: this.state.emptyBreed, addBreedMode: true })
    }

    changeData = (ev: any) => {
        const target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let at: any = { ...this.state.ring };
        at[name] = value
        this.setState({ ring: at });
    }

    changeStaff = async (ev: any, type: any) => {
        let name = ev.target.name
        let at: any = { ...this.state.ring };
        let staff = await httpService.getRegisteredUser(ev.target.value)
        at[name] = { id: staff.id, username: staff.userName, firstName: staff.firstName, lastName: staff.lastName }
        this.setState({ ring: at });
    }

    changeBreedData = (ev: any,) => {
        let target = ev.target;
        const value = target && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let at: any = { ...this.state.selectedBreed };
        if (name === "approximateFrom" || name === "approximateTo") {
            at[name] = value
        } else if ((name !== "approximateFrom" || name !== "approximateTo") && target.type !== "checkbox") {
            at[name] = parseInt(value)
        } else {
            at[name] = value
        }
        this.setState({ selectedBreed: at });

    }


    changeBreed = (ev: any) => {
        let at: any = { ...this.state.selectedBreed };
        at.breed = ev
        this.setState({ selectedBreed: at });
    }

    dateChange = (res: any) => {
        let data: any = { ...this.state.ring };
        data[res.name] = Helpers.getLocalISODate(res.value);
        this.setState({
            ring: data
        })
    }


    private dataChanged() {
        this.setState({ showPopup: true, judge: this.props.judge, recorder: this.props.recorder, breedArray: this.props.data && this.props.data.breeds, breeds: this.props.breeds, ringLeader: this.props.ringLeader, ring: (this.props.data) ? this.props.data : { description: '', startTime: new Date(), judge: {}, recorder: {}, ringLeader: {}, breeds: [], order: null, timePerDog: null, ringFinished: false, competitionDateId: this.props.parentId } });
    }

    getDomIndex(el: any) {
        if (el.parentNode && el.parentNode.children) {
            return Array.from(el.parentNode.children).indexOf(el);
        }
        return -1;
    }

    dragulaDecorator = (componentBackingInstance: any) => {

        if (componentBackingInstance) {
            let options = {
                moves: function (el: any, container: any, handle: any) {
                    return handle.classList.contains('handle');
                }
            };
            this.drake = Dragula([componentBackingInstance], options);

            this.drake.on("drag", (el: any, source: any) => {
                this.fromIndex = this.getDomIndex(el);
            });
            this.drake.on("drop", async (el: any, target: any, source: any, sibling: any) => {
                this.toIndex = this.getDomIndex(el);

                if (this.state.ring) {
                    let state: any = [...this.state.breedArray];
                    const item = state.splice(this.fromIndex, 1)[0];
                    let index = this.toIndex;
                    state.splice(index, 0, item);
                    state.forEach((item: any, i: any) => {
                        item.order = i + 1
                    })
                    this.setState({ breedArray: state, currentIndex: null })

                }

            })
        }
    }

    setMode(): boolean {
        return this.state.previewMode;
    }


    setBreedMode(): boolean {

        if (this.state.addBreedMode) {
            return false
        }
        if (this.state.editBreedMode) {
            return false
        } if (this.state.editBreed) {
            return true
        }
        return false

    }

    setAddBreedType(type: any) {
        this.setState({ breedAddType: type == SINGLE_BREED ? SINGLE_BREED : GROUP_BREEDS });
    }

    getFciGroups() {
        let result: any = [];

        for (let i = 0; i < this.state.breeds.length; i++) {
            let keyValue = this.state.breeds[i].fci;
            let check = result.find((item: any) => keyValue in item);
            if (check) {
                let ind = result.indexOf(check)
                result[ind][keyValue].push(this.state.breeds[i])
            } else {
                result.push({ [keyValue]: [] })
                result[result.length - 1][keyValue].push(this.state.breeds[i])
            }
        }
        result.sort((a: any, b: any) => ((parseInt(Object.keys(a)[0]) < parseInt(Object.keys(b)[0]) ? -1 : 1)));
        this.setState({ fciCategories: result });
    }


    changeGroupData(e: any, val?: any, i?: any) {
        const target = e.target;
        const name = target.name;
        const value = target && target.type === 'checkbox' && name !== "fciBreeds" ? target.checked : target.value;

        const breedArray = [...this.state.breedArray];
        const newBreeds = [...this.state.availableBreeds];
        let checked = [...this.state.checkedBreeds];
        let indeterminateGroups = [...this.state.indeterminateGroups];
        let newRes: any = [];

        if (name === "fciGroup") {
            let element = document.getElementById(val) as HTMLInputElement
            if (element.checked) {
                const res = this.state.fciCategories.find((item: any) => val in item)[val];
                res.forEach((item: any) => {
                    let newItem = { breed: item, order: breedArray.length + 1 };
                    if (!newBreeds.includes(newItem)) {
                        checked.push(newItem.breed.id)
                        newRes.push(newItem);
                    }
                })
                this.setState({ fciBreeds: [], availableBreeds: [...newBreeds, ...newRes], checkedBreeds: checked });
            } else {
                newRes = newBreeds.filter((item: any) => !this.state.fciCategories[i][val].includes(item.breed))
                checked = [];
                newRes.forEach((item: any) => {
                    checked.push(item.breed.id)
                })
                this.setState({ fciBreeds: [], availableBreeds: newRes, checkedBreeds: checked });
            }
        } else if (name === "fciBreeds") {
            const fciBreed = newBreeds.find((i: any) => i.breed.id === value);
            if (fciBreed) {
                newBreeds.splice(newBreeds.indexOf(fciBreed), 1);
                checked = checked.filter((e: any) => e !== fciBreed.breed.id);
                let checkLength = newBreeds.find((i: any) => i.breed.fci === fciBreed.breed.fci);
                if (!checkLength) {
                    indeterminateGroups.splice(checked.indexOf(fciBreed.breed.fci), 1);
                }

            } else {
                const breed = this.state.breeds.find((item: any) => item.id == value);
                const newBreed = { breed: breed, order: breedArray.length + 1 };
                newBreeds.push(newBreed);
                checked.push(newBreed.breed.id)
                if (!indeterminateGroups.includes(newBreed.breed.fci)) {
                    indeterminateGroups.push(newBreed.breed.fci)
                }
            }


            this.setState({ availableBreeds: newBreeds, checkedBreeds: checked, indeterminateGroups: indeterminateGroups });
        }
    }



    expandBreedsCheckbox = (item: any) => {
        let data = [...this.state.fciSelected]
        let check = this.state.fciCategories.length > 0 ? this.state.fciCategories.find((i: any) => i.hasOwnProperty(item)) : false;
        if (check) {
            let ind = data.indexOf(item)
            if (ind == -1) {
                data.push(item)
            } else {
                data.splice(ind, 1)
            }
            this.setState({ expandBreeds: !this.state.expandBreeds, fciSelected: data })
        }
    }

    fciIncludesBreeds(value: any, index: any) {
        const res: any[] = this.state.availableBreeds.filter((i: any) => i.breed.fci === parseInt(value));
        const element = document.getElementById(value) as HTMLInputElement | null;
        if (!element) return;

        if (res.length > 0 && res.some((obj: any) => obj.breed.fci === parseInt(value))) {
            element.indeterminate = true;
        }

        const checking = this.state.fciCategories[index][parseInt(value)].every((item: any) => res.some((obj: any) => obj.breed.id === item.id));
        element.checked = checking ? true : false;
        element.indeterminate = res.length > 0 && !checking;
    }

    changeProfile = async (ev: any, usertype: any) => {

        let data: any = { ...this.state.ring }
        if (ev) {
            let user = await httpService.getRegisteredUser(ev)
            data[usertype] = { "id": user.id, "firstName": user.firstName, "lastName": user.lastName, "username": user.userName }
            this.setState({ ring: data });
        }

    }

    render() {
        return (
            <React.Fragment>

                <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.props.open}>
                    <ModalHeader toggle={() => this.close(false)}>{(this.state.ring && this.state.ring.hasOwnProperty("id")) ? "Edit Ring " + this.state.ring.description : "Add Ring"}</ModalHeader>
                    <ModalBody>
                        {(!this.state.ring && !this.state.ringLeader && !this.state.judge && !this.state.recorder) ? <RegistrationLoader /> :

                            <form onSubmit={(e) => { e.preventDefault(); this.close(true) }}>
                                <div className="row">

                                    {!this.props.addMode && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                        <td><span onClick={() => this.openEdit()} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                            className="c-light-blue-500 ti-pencil"></i></span>
                                        </td>
                                    }


                                </div>
                                <div className="row">

                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="description">Description *</label>
                                        <input id="description" name="description" required className="form-control"
                                            disabled={this.setMode()}
                                            value={this.state.ring && this.state.ring.description} onChange={this.changeData}
                                            maxLength={2000} />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="order">Catalog Order *</label>
                                        <input id="order" name="order" required className="form-control"
                                            disabled={this.setMode()}
                                            value={this.state.ring && this.state.ring.order} onChange={this.changeData}
                                            maxLength={2000} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="startTime">Start Time *</label>
                                        <DateTimePicker useTime name="startTime"
                                            disabled={this.setMode()}
                                            value={this.state.ring && this.state.ring.startTime && Helpers.getIsoDate(this.state.ring.startTime)}
                                            onChange={(e: any) => {
                                                this.dateChange(e)
                                            }} />
                                    </div>


                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="timePerDog">Time Per Dog *</label>
                                        <input id="timePerDog" name="timePerDog" required className="form-control"
                                            disabled={this.setMode()}
                                            value={this.state.ring && this.state.ring.timePerDog} onChange={this.changeData}
                                            maxLength={2000} />
                                    </div>
                                    <div style={{ display: "flex", alignContent: "flex-start", justifyContent: "flex-start", flexDirection: "column-reverse" }} className="mb-3 col-md-6">
                                        <div className="mb-3 checkbox checkbox-info peers ai-c">
                                            <input id="ringFinished" name="ringFinished" type="checkbox"
                                                disabled={this.setMode()}
                                                checked={this.state.ring && this.state.ring.ringFinished} className="peer"
                                                onChange={(e) => this.changeData(e)} />
                                            <label className="form-label peers peer-greed js-sb ai-c" htmlFor="ringFinished">
                                                <span className="peer peer-greed">Ring Finished</span>
                                            </label>
                                        </div>
                                    </div>
                                    <hr />

                                    <h5>Staff</h5>

                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="judge">Judge</label>

                                        <StaffSearch action={(e: any) => this.changeProfile(e, "judge")} data={this.state.ring} type="judge"
                                            suggestions={this.state.judge} disabled={this.setMode()} />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="recorder">Recorder</label>

                                        <StaffSearch action={(e: any) => this.changeProfile(e, "recorder")} data={this.state.ring} type="recorder"
                                            suggestions={this.state.recorder} disabled={this.setMode()} />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label" htmlFor="ringLeader">Ring Leader</label>

                                        <StaffSearch action={(e: any) => this.changeProfile(e, "ringLeader")} data={this.state.ring} type="ringLeader"
                                            suggestions={this.state.ringLeader} disabled={this.setMode()} />
                                    </div>


                                    <div>
                                        <div>

                                            <hr />
                                            {this.state.breedArray && this.state.breedArray.length > 0 &&
                                                <div>
                                                    <Button className="c-light-blue-500" style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        outline: "none",
                                                        boxShadow: "none",
                                                        width: "150px",
                                                        position: "absolute",
                                                        left: "10%",
                                                        background: "white",
                                                        borderColor: "#2196f3",
                                                        borderRadius: "15px",
                                                        float: "left"
                                                        
                                                    }} onClick={() => {
                                                        this.setState({ breedListExpanded: !this.state.breedListExpanded })
                                                    }}>
                                                        <span style={{ marginRight: "auto" }}>{!this.state.breedListExpanded ? "Display breeds" : "Hide breeds"}</span>
                                                        <i style={{ marginLeft: "auto" }} className={this.state.breedListExpanded ? "fa fa-arrow-up" : "fa fa-arrow-down"}></i>
                                                    </Button>
                                                </div>


                                            }

                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>


                                                <h5>Breeds</h5>

                                                {(!Helpers.isUserEventViewer(this.props.userState.user) && !this.state.previewMode) &&
                                                    <div style={{ justifyContent: "right", display: "flex", gap: "10px" }}>

                                                        <button className="btn btn-outline-primary breedAddButton" style={{ outline: "none", boxShadow: "none", background: "white", borderColor: "#2196f3", borderRadius: "15px" }} onClick={(e: any) => {
                                                            e.preventDefault()
                                                            this.addEmptyBreed()
                                                            this.setState({ editBreed: !this.state.editBreed, breedAddType: SINGLE_BREED })
                                                        }}>Add Breed</button>
                                                        <button className="btn btn-outline-primary breedAddButton" style={{ outline: "none", boxShadow: "none", background: "white", borderColor: "#2196f3", borderRadius: "15px" }} onClick={(e: any) => {
                                                            e.preventDefault()
                                                            this.setState({ editBreed: true, breedAddType: GROUP_BREEDS, addBreedMode: true })
                                                        }}>Add FCI Groups</button>



                                                    </div>
                                                }
                                            </div>
                                            <br />
                                            {this.state.breedArray && this.state.breedArray.length < 1 &&
                                                < div style={{ color: "grey" }}>No breeds.</div>
                                            }

                                            <table className="table table-borderless">
                                                <tbody className="container" ref={this.dragulaDecorator}>
                                                    {this.state.breedListExpanded && this.state.ring && this.state.ring.breeds && this.state.breedArray && this.state.breedArray.length > 0 && this.state.breedArray.map((breed: any, index: number) => (

                                                        <tr key={breed.breed.id} className={!this.state.previewMode ? "handle" : ''} style={{ cursor: !this.state.previewMode ? "grab" : 'default', display: "flex", border: "1px solid #c4bebe", borderRadius: "20px", padding: "10px", marginBottom: "10px" }}>
                                                            <td style={{ position: "relative", userSelect: "none" }}>
                                                                <span style={{ fontSize: "16px", marginRight: "20px" }} className="icon-holder"><i className={!this.state.previewMode ? "handle c-light-blue-500 ti-move" : 'handle c-white ti-move'}></i><i className={!this.state.previewMode ? "handle" : ""} style={{ marginLeft: "20px", fontStyle: "normal" }}>{breed.breed.name}</i></span>
                                                            </td>
                                                            {breed.approximateFrom && breed.approximateTo &&
                                                                <td style={{ position: "absolute", right: "30%" }}>
                                                                    <span>{breed.approximateFrom} - {breed.approximateTo}</span>
                                                                </td>
                                                            }
                                                            <td style={{ position: "absolute", right: "90px" }}>
                                                                <span style={{ fontSize: "16px", marginRight: "20px" }}><i className={!this.state.previewMode ? "handle" : ""} style={{ marginRight: "20px", fontStyle: "normal" }}><span className="c-light-blue-500">Total: </span>{breed.bitchesCount && breed.dogsCount ? (breed.bitchesCount + breed.dogsCount) : (breed.bitchesCount || breed.dogsCount || 0)}</i></span>
                                                            </td>

                                                            <td style={{ position: "absolute", right: "30px" }}>

                                                                <span style={{ cursor: "pointer", fontSize: "16px", userSelect: "none" }} className="icon-holder" onClick={() => this.openBreedEdit(index)}><i className={this.state.previewMode ? "c-light-blue-500 fa fa-search" : "c-light-blue-500 ti-pencil"}></i></span>
                                                                {!this.state.previewMode &&
                                                                    <>
                                                                        <span style={{ fontSize: "16px", userSelect: "none" }}> | </span>
                                                                        <span style={{ cursor: "pointer", fontSize: "16px", userSelect: "none" }} onClick={(e) => this.removeBreed(index)} className="icon-holder"><i className="c-light-blue-500 ti-trash"></i></span>
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))


                                                    }
                                                </tbody>
                                            </table>

                                            {
                                                this.state.editBreed &&

                                                <Modal style={{ maxWidth: '850px', width: '100%' }} size="lg" isOpen={this.state.editBreed}>
                                                    <ModalHeader toggle={() => this.closeBreedEdit()}>{!this.state.addBreedMode ? "Edit Breed" : "Add Breed"}</ModalHeader>
                                                    <ModalBody>

                                                        <div className="row">
                                                            {!Helpers.isUserEventViewer(this.props.userState.user) &&
                                                                !this.state.addBreedMode && !this.state.editBreedMode &&
                                                                <td><span onClick={() => this.setState({ editBreedMode: !this.state.editBreedMode })} style={{ fontSize: "16px", cursor: "pointer", float: "right" }} className="icon-holder"><i
                                                                    className="c-light-blue-500 ti-pencil"></i></span>
                                                                </td>

                                                            }
                                                        </div>


                                                        {this.state.breedAddType == SINGLE_BREED &&

                                                            <div className="row">

                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="breed">Breed *</label>
                                                                    <BreedSearch action={(item: any) => this.changeBreed(item)} data={this.state.selectedBreed}
                                                                        suggestions={this.state.breeds} disabled={this.setBreedMode()} />
                                                                </div>
                                                                <div style={{ display: "flex", alignContent: "flex-start", justifyContent: "flex-start", flexDirection: "column-reverse" }} className="mb-3 col-md-6">
                                                                    <div className="mb-3 checkbox checkbox-info peers ai-c">
                                                                        <input id="breedFinished" name="breedFinished" type="checkbox"
                                                                            disabled={this.setBreedMode()}
                                                                            checked={this.state.selectedBreed && this.state.selectedBreed.breedFinished} className="peer"
                                                                            onChange={(e: any) => this.changeBreedData(e)} />
                                                                        <label className="form-label peers peer-greed js-sb ai-c" htmlFor="breedFinished">
                                                                            <span className="peer peer-greed">Breed Finished</span>
                                                                        </label>
                                                                        <br />
                                                                    </div>
                                                                </div>

                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="approximateFrom">Approximate From *</label>
                                                                    <input id="approximateFrom" name="approximateFrom" className="form-control"
                                                                        disabled={this.setBreedMode()}
                                                                        value={this.state.selectedBreed && this.state.selectedBreed.approximateFrom} onChange={(e: any) => this.changeBreedData(e)}
                                                                        maxLength={2000} />
                                                                </div>

                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="approximateTo">Approximate To *</label>
                                                                    <input id="approximateTo" name="approximateTo" className="form-control"
                                                                        disabled={this.setBreedMode()}
                                                                        value={this.state.selectedBreed && this.state.selectedBreed.approximateTo} onChange={(e: any) => this.changeBreedData(e)}
                                                                        maxLength={2000} />
                                                                </div>

                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="catalogNumberFrom">Catalog Number From</label>
                                                                    <input id="catalogNumberFrom" name="catalogNumberFrom" className="form-control"
                                                                        disabled={this.setBreedMode()}
                                                                        value={this.state.selectedBreed && this.state.selectedBreed.catalogNumberFrom || ""} onChange={(e: any) => this.changeBreedData(e)}
                                                                        maxLength={2000} />
                                                                </div>
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="catalogNumberTo">Catalog Number To</label>
                                                                    <input id="catalogNumberTo" name="catalogNumberTo" className="form-control"
                                                                        disabled={this.setBreedMode()}
                                                                        value={this.state.selectedBreed && this.state.selectedBreed.catalogNumberTo || ""} onChange={(e: any) => this.changeBreedData(e)}
                                                                        maxLength={2000} />
                                                                </div>
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="bitchesCount">Bitches Count</label>
                                                                    <input type="number" id="bitchesCount" name="bitchesCount" className="form-control"
                                                                        disabled={this.setBreedMode()}
                                                                        value={this.state.selectedBreed && this.state.selectedBreed.bitchesCount && this.state.selectedBreed.bitchesCount} onChange={(e: any) => this.changeBreedData(e)}
                                                                        maxLength={2000} />
                                                                </div>
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="dogsCount">Dogs Count</label>
                                                                    <input type="number" id="dogsCount" name="dogsCount" className="form-control"
                                                                        disabled={this.setBreedMode()}
                                                                        value={this.state.selectedBreed && this.state.selectedBreed.dogsCount && this.state.selectedBreed.dogsCount} onChange={(e: any) => this.changeBreedData(e)}
                                                                        maxLength={2000} />
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.breedAddType == GROUP_BREEDS &&
                                                            <div className="row">


                                                                {this.state.fciCategories && this.state.fciCategories.length > 0 && this.state.fciCategories.map((item: any, index: any) => (
                                                                    <div>

                                                                        <div key={Object.keys(item)[0]} style={{}} className={`mb-3 checkbox checkbox-info peers column-0`}>
                                                                            <input id={Object.keys(item)[0]} name="fciGroup"
                                                                                type="checkbox"
                                                                                ref={input => {
                                                                                    if (input) {
                                                                                        this.fciIncludesBreeds(Object.keys(item)[0], index)
                                                                                    }
                                                                                }}
                                                                                onChange={(e: any) => this.changeGroupData(e, Object.keys(item)[0], index)} />
                                                                            <label htmlFor={Object.keys(item)[0]} className="form-label peers peer-greed js-sb ai-c">
                                                                                <span className="peer peer-greed c-light-blue-500">FCI {Object.keys(item)[0]}</span>

                                                                            </label>
                                                                            <i style={{ cursor: "pointer" }} id={Object.keys(item)[0]} className={this.state.fciSelected.includes(Object.keys(item)[0]) ? "fa fa-arrow-up column-1" : "fa fa-arrow-down column-1"} aria-hidden="true" onClick={() => this.expandBreedsCheckbox(Object.keys(item)[0])}></i>

                                                                        </div>

                                                                        <div className="checkbox-grid">

                                                                            {this.state.fciSelected.includes(Object.keys(item)[0]) && this.state.fciCategories[index] && this.state.fciCategories[index][Object.keys(item)[0]].map((breed: any, index: any) => (

                                                                                <div key={breed.id} className={`mb-3 checkbox checkbox-info peers ai-c checkbox-item column-${index % 3}`}>

                                                                                    <input id={breed.id} name="fciBreeds" type="checkbox"
                                                                                        value={breed.id}
                                                                                        checked={this.state.checkedBreeds.includes(breed.id)}
                                                                                        className="peer"
                                                                                        onChange={(e: any) => this.changeGroupData(e)} />
                                                                                    <label htmlFor={breed.id} className="form-label peers peer-greed js-sb ai-c">
                                                                                        <span className="peer peer-greed">{breed.name}</span>
                                                                                    </label>

                                                                                </div>

                                                                            ))}

                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                ))}

                                                                <div>


                                                                </div>


                                                            </div>

                                                        }
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        {!this.state.addMode && !Helpers.isUserEventViewer(this.props.userState.user) &&
                                                            <Button disabled={!this.state.addBreedMode && !this.state.editBreedMode || this.state.disableButton} color="primary" onClick={() => this.saveBreed(this.state.currentIndex)}>Confirm</Button>
                                                        }
                                                        <Button color="secondary" onClick={() => {
                                                            this.setState({ addBreedMode: false, editBreedMode: false, editBreed: false, checkedBreeds: [], indeterminateGroups: [] })
                                                            this.closeBreedEdit()
                                                        }}>Close</Button>
                                                    </ModalFooter>

                                                </Modal>


                                            }

                                        </div>

                                    </div>
                                </div>
                            </form>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {!Helpers.isUserEventViewer(this.props.userState.user) &&
                            <Button disabled={this.state.previewMode || this.state.disableButton} color="primary" onClick={() => this.saveChanges(this.state.ring)}>Save</Button>
                        }
                        <Button color="secondary" onClick={() => {

                            this.close(false)
                        }}>Close</Button>
                    </ModalFooter>

                </Modal>



            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        dataState: state.competitionList,
        dialog: state.dialog,
        userState: state.user
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {

        showDialog: (m: string, t: DialogStore.DialogType, cal: Function) => {
            dispatch(DialogStore.actionCreators.showDialog(m, t, cal))
        },
        showRingDialog: (d: CompetitionRing, detailsOnly: boolean, cal: Function) => {
            dispatch(DialogStore.actionCreators.showRingDialog(d, detailsOnly, cal))
        },
        getCompetitionDate: (id: string) => {
            dispatch(CompetitionListStore.actionCreators.getCompetitionDate(id))
        },
        increment: () => {
            dispatch(DialogStore.actionCreators.increment())
        },
        decrement: () => {
            dispatch(DialogStore.actionCreators.decrement())
        },
        hideCompDateDialog: () => {
            dispatch(DialogStore.actionCreators.hideCompDateDialog())
        },
        hideRingDialog: () => {
            dispatch(DialogStore.actionCreators.hideRingDialog())
        },
        showNotification: (data: DialogStore.NotificationData) => {
            dispatch(DialogStore.actionCreators.showNotification(data))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(RingDialog)
