import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import * as DialogStore from '../store/DialogStore';

export enum PictureWarningLevel {
    Light,
    Strict
}

type ImagePickerProps = {
    src: string;
    width: string;
    height: string;
    required: boolean;
    warningLevel: PictureWarningLevel;
    onChange: Function;
}

class ImagePicker extends React.Component<ImagePickerProps & typeof DialogStore.actionCreators> {

    constructor(props: any) {
        super(props);
    }

    imagePicked = ($event: any) => {
        let input = $event.target as HTMLInputElement;
        if (input.files && input.files.length) {
            const file = input.files[0];

            let typeWarning = '';
            
            //file size in KB
            let minSize = 500;
            let maxSize = 750;

            if (this.props.warningLevel === PictureWarningLevel.Strict) {
                //file size in KB
                minSize = 250;
                maxSize = 350;
            }

            if (file.size >= minSize * 1024 && file.size <= maxSize * 1024) {
                this.props.showDialog(`<b>Image limitations</b><ul><li>file exceeds ${minSize}KB</li>${typeWarning}</ul>Upload anyway?<br/><br/>`, DialogStore.DialogType.Confirm, (confirm: boolean) => {
                    if (confirm === true) {
                        return;
                    }

                    this.loadFile(file);
                });
                return;

            } else if (file.size > maxSize * 1024) {
                this.props.showDialog(`<b>Image limitations</b><ul><li>file exceeded maximum size of ${maxSize}KB</li>${typeWarning}</ul>`);
                return;
            }

            this.loadFile(file);
        }
    }

    private loadFile(file: File) {
        var reader = new FileReader();
        reader.onload = (e) => {
            let rs = reader.result as string;
            this.props.onChange({ file: file, url: rs });
        }

        reader.readAsDataURL(file);
    }

    render() {
        return (
            <div className="picture-picker">
                <div className="picture-picker-viewer" style={{ width: this.props.width, height: this.props.height }}>
                    {this.props.src &&
                        <img style={{ width: this.props.width, height: this.props.height }} src={this.props.src}></img>
                    }
                </div>
                
                <label className="btn btn-primary btn-sm btn-color">CHOOSE A FILE<input type="file" className="inputfile" value={''} onChange={this.imagePicked} required={this.props.required && !this.props.src} /></label>
            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { dialog: state.dialog } },
    DialogStore.actionCreators
)(ImagePicker)
