import { Action, Reducer } from 'redux';
import { httpService } from '../index';
import { AppThunkAction } from './';
import { InvoiceIssuer } from '../models/invoice/InvoiceIssuer';
import { IssuerType } from '../models/invoice/InvoiceEnums';

export interface InvoiceIssuerListState {
    selectedItem: InvoiceIssuer | null,
    list: Array<InvoiceIssuer>,
    isLoading: boolean,
    issuerType: IssuerType | null,
    error: string,
}

interface SelectInvoiceIssuerAction {
    type: 'SELECT_INVOICE_ISSUER';
    item: InvoiceIssuer;
}

interface RequestInvoiceIssuerAction {
    type: 'REQUEST_INVOICE_ISSUER';
}

interface ReceiveInvoiceIssuerAction {
    type: 'RECEIVE_INVOICE_ISSUER';
    items: Array<InvoiceIssuer>;
    issuerType: IssuerType | null;
    error: '';
}

type KnownAction =
    SelectInvoiceIssuerAction
    | RequestInvoiceIssuerAction
    | ReceiveInvoiceIssuerAction

export const actionCreators = {
    selectItem: (item: InvoiceIssuer): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SELECT_INVOICE_ISSUER', item: item });
    },

    getItems: (query: any = "", type: any = IssuerType.NORMAL): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.invoiceInfoList) {
            try {
                dispatch({ type: 'REQUEST_INVOICE_ISSUER' });
                let data = await httpService.invoiceIssuers(query, type);

                dispatch({ type: 'RECEIVE_INVOICE_ISSUER', items: data, issuerType: type, error: '' });

            } catch (err) {
                dispatch({ type: 'RECEIVE_INVOICE_ISSUER', items: [], issuerType: null, error: '' });
            }
        }
    }
}

const unloadedState: InvoiceIssuerListState = { selectedItem: null, isLoading: false, list: [], error: '', issuerType: null };

export const reducer: Reducer<InvoiceIssuerListState> = (state: InvoiceIssuerListState | undefined, incomingAction: Action): InvoiceIssuerListState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_INVOICE_ISSUER':
            return {
                selectedItem: action.item,
                list: state.list,
                isLoading: false,
                error: '',
                issuerType: state.issuerType
            }
        case 'REQUEST_INVOICE_ISSUER':
            return {
                selectedItem: state.selectedItem,
                list: [],
                isLoading: true,
                error: '',
                issuerType: state.issuerType
            }
        case 'RECEIVE_INVOICE_ISSUER':

            let si = state.selectedItem;

            if (action.items && action.items.length > 0 && !state.selectedItem) {
                si = action.items[0];
            }

            return {
                selectedItem: si,
                list: action.items,
                isLoading: false,
                error: state.error,
                issuerType: action.issuerType
            }

    }
    return state;
}
