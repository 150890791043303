import { Action, Reducer } from 'redux';
import { httpService } from '../index';
import { AppThunkAction } from './';
import { Country } from '../models/country/Country'

export interface CountryListState {
    selectedItem: Country | null,
    list: Array<Country>,
    isLoading: boolean,
    error: string
}

interface SelectCountryAction {
    type: 'SELECT_COUNTRY';
    item: Country;
}

interface RequestCountryAction {
    type: 'REQUEST_COUNTRY';
}

interface ReceiveCountryAction {
    type: 'RECEIVE_COUNTRY';
    items: Array<Country>;
    error: '';
}

type KnownAction = SelectCountryAction | RequestCountryAction | ReceiveCountryAction;

export const actionCreators = {
    selectItem: (item: Country): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SELECT_COUNTRY', item: item });
    },
    getItems: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.countryList) {
            try {
                dispatch({ type: 'REQUEST_COUNTRY' });
                let data = await httpService.getCountries();
                dispatch({ type: 'RECEIVE_COUNTRY', items: data, error: '' });

            } catch (err) {
                dispatch({ type: 'RECEIVE_COUNTRY', items: [], error: '' });
            }
        }
    },
    
    reorderItem: (id: string, pos?: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.countryList) {
            try {
                await httpService.dogReorder(id, pos);
                let data = await httpService.getCountries();
                dispatch({ type: 'RECEIVE_COUNTRY', items: data, error: '' });

            } catch (err) {
                dispatch({ type: 'RECEIVE_COUNTRY', items: [], error: '' });
            }
        }
    }
}

const unloadedState: CountryListState = { selectedItem: null, isLoading: false, list: [], error: '' };

export const reducer: Reducer<CountryListState> = (state: CountryListState | undefined, incomingAction: Action): CountryListState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_COUNTRY':
            return {
                selectedItem: action.item,
                list: state.list,
                isLoading: false,
                error: ''
            }
        case 'REQUEST_COUNTRY':
            return {
                selectedItem: state.selectedItem,
                list: [],
                isLoading: true,
                error: ''
            }
        case 'RECEIVE_COUNTRY':

            let si = state.selectedItem;

            if (action.items && action.items.length > 0 && !state.selectedItem) {
                si = action.items[0];
            }

            return {
                selectedItem: si,
                list: action.items,
                isLoading: false,
                error: action.error
            }
    }
    return state;

}
