
export enum CompetitionType {
    REGIONAL = 0,
    NATIONAL = 1,
    INTERNATIONAL = 2,
    SPECIAL = 3,
    EUROPEAN = 4,
    WORLD = 5
}

export enum CompetitionAttachmentType {
    OTHER = 0,
    CATALOG = 1,
    EVALUATION_RINGS = 2,
    JUDGE_LIST = 3,
    REGULATIONS = 4,
    BISJUDGE_LIST = 5,
    SPECIAL_SHOWS = 6,
    PROGRAM = 7
}

export enum BISType {
    FCI1,
    FCI2,
    FCI3,
    FCI4,
    FCI5,
    FCI6,
    FCI7,
    FCI8,
    FCI9,
    FCI10,
    FCI11,
    FCI1Jr,
    FCI2Jr,
    FCI3Jr,
    FCI4Jr,
    FCI5Jr,
    FCI6Jr,
    FCI7Jr,
    FCI8Jr,
    FCI9Jr,
    FCI10Jr,
    FCI11Jr,
    BIS,
    BISJr,
    Puppy,
    MinorPuppy,
    Braces, // Couple
    BreedingGroups,
    Veterans,
    JH1,
    JH2,
    SH,
    JHFinal,
    IndigenousBreed
}

export function getBISType(type: BISType) {
    switch (type) {
        case BISType.FCI1:
            return 'FCI1';
        case BISType.FCI2:
            return 'FCI2';
        case BISType.FCI3:
            return 'FCI3';
        case BISType.FCI4:
            return 'FCI4';
        case BISType.FCI5:
            return 'FCI5';
        case BISType.FCI6:
            return 'FCI6';
        case BISType.FCI7:
            return 'FCI7';
        case BISType.FCI8:
            return 'FCI8';
        case BISType.FCI9:
            return 'FCI9';
        case BISType.FCI10:
            return 'FCI10';
        case BISType.FCI11:
            return 'FCI11';
        case BISType.FCI1Jr:
            return 'FCI1 Junior';
        case BISType.FCI2Jr:
            return 'FCI2 Junior';
        case BISType.FCI3Jr:
            return 'FCI3 Junior';
        case BISType.FCI4Jr:
            return 'FCI4 Junior';
        case BISType.FCI5Jr:
            return 'FCI5 Junior';
        case BISType.FCI6Jr:
            return 'FCI6 Junior';
        case BISType.FCI7Jr:
            return 'FCI7 Junior';
        case BISType.FCI8Jr:
            return 'FCI8 Junior';
        case BISType.FCI9Jr:
            return 'FCI9 Junior';
        case BISType.FCI10Jr:
            return 'FCI10 Junior';
        case BISType.FCI11Jr:
            return 'FCI11 Junior';
        case BISType.BIS:
            return 'BIS';
        case BISType.BISJr:
            return 'BIS Junior';
        case BISType.Puppy:
            return 'Puppy';
        case BISType.MinorPuppy:
            return 'Minor Puppy';
        case BISType.Braces:
            return 'Braces';
        case BISType.BreedingGroups:
            return 'Breeding Groups';
        case BISType.Veterans:
            return 'Veterans';
        case BISType.JH1:
            return 'JH1';
        case BISType.JH2:
            return 'JH2';
        case BISType.SH:
            return 'SH';
        case BISType.JHFinal:
            return 'JH Final';
        case BISType.IndigenousBreed:
            return 'Indigenous Breed';

    }
}

export enum DiscountType {
    DOG_REGISTRATION = 0
}

export function getDiscountType(type: DiscountType) {
    switch (type) {
        case DiscountType.DOG_REGISTRATION:
            return 'Dog Registration';
    }
}

export function getDiscountTypeLabel() {
    return [
        { label: 'Dog Registration', value: DiscountType.DOG_REGISTRATION },
    ]
}

export enum CustomDiscountType {
    DOG_REGISTRATION = 0,
    COUPLE_GROUP_REGISTRATION = 1,
    JUNIOR_HANDLING_REGISTRATION = 2
}

export function getCustomDiscountType(type: CustomDiscountType) {
    switch (type) {
        case CustomDiscountType.DOG_REGISTRATION:
            return 'Dog Registration';
        case CustomDiscountType.COUPLE_GROUP_REGISTRATION:
            return 'Couple Group Registration';
        case CustomDiscountType.JUNIOR_HANDLING_REGISTRATION:
            return 'Junior Handling Registration';
    }
}

export function getCustomDiscountTypeLabel() {
    return [
        { label: 'Dog Registration', value: CustomDiscountType.DOG_REGISTRATION },
        { label: 'Couple Group Registration', value: CustomDiscountType.COUPLE_GROUP_REGISTRATION },
        { label: 'Junior Handling Registration', value: CustomDiscountType.JUNIOR_HANDLING_REGISTRATION }
    ]
}

export enum TaxLevel {
    Level0,
    Level9,
    Level22
}

export function getTaxLevel() {
    return [
        { label: 'Level 0', value: TaxLevel.Level0 },
        { label: 'Level 9', value: TaxLevel.Level9 },
        { label: 'Level 22', value: TaxLevel.Level22 }

    ]
}

export function taxLevelToString(tax: TaxLevel) {
    switch (tax) {
        case TaxLevel.Level0:
            return 'Level 0';
        case TaxLevel.Level9:
            return 'Level 9';
        case TaxLevel.Level22:
            return 'Level 22';
        
    }
}

export function getCompetitionAttachmentType(type: CompetitionAttachmentType) {
    switch (type) {
        case CompetitionAttachmentType.OTHER:
            return 'Other';
        case CompetitionAttachmentType.CATALOG:
            return 'Catalog';
        case CompetitionAttachmentType.EVALUATION_RINGS:
            return 'Evaluation Rings';
        case CompetitionAttachmentType.JUDGE_LIST:
            return 'Judge Lists';
        case CompetitionAttachmentType.REGULATIONS:
            return 'Regulations';
        case CompetitionAttachmentType.BISJUDGE_LIST:
            return 'Bis Judge Lists';
        case CompetitionAttachmentType.SPECIAL_SHOWS:
            return 'Special Shows';
        case CompetitionAttachmentType.PROGRAM:
            return 'Program';
    }
}

export function getCompAttachmentType() {
    return [
        { label: 'Other', value: CompetitionAttachmentType.OTHER },
        { label: 'Catalog', value: CompetitionAttachmentType.CATALOG },
        { label: 'Evaluation Rings', value: CompetitionAttachmentType.EVALUATION_RINGS },
        { label: 'Judge List', value: CompetitionAttachmentType.JUDGE_LIST },
        { label: 'Regulations', value: CompetitionAttachmentType.REGULATIONS }
    ]
}

export function getCompetitionType(type: any) {
    switch (type) {
        case CompetitionType.REGIONAL:
            return 'Regional';
        case CompetitionType.NATIONAL:
            return 'National';
        case CompetitionType.INTERNATIONAL:
            return 'International';
        case CompetitionType.SPECIAL:
            return 'Special';
        case CompetitionType.EUROPEAN:
            return 'European';
        case CompetitionType.WORLD:
            return 'World';

    }
}

export function getType() {
    return [
        { label: 'Regional', value: CompetitionType.REGIONAL },
        { label: 'National', value: CompetitionType.NATIONAL },
        { label: 'International', value: CompetitionType.INTERNATIONAL },
        { label: 'Special', value: CompetitionType.SPECIAL },
        { label: 'European', value: CompetitionType.EUROPEAN },
        { label: 'World', value: CompetitionType.WORLD },
    ]
}

export function getBISTypeOptions() {
    return [
        { label: 'FCI1', value: BISType.FCI1 },
        { label: 'FCI2', value: BISType.FCI2 },
        { label: 'FCI3', value: BISType.FCI3 },
        { label: 'FCI4', value: BISType.FCI4 },
        { label: 'FCI5', value: BISType.FCI5 },
        { label: 'FCI6', value: BISType.FCI6 },
        { label: 'FCI7', value: BISType.FCI7 },
        { label: 'FCI8', value: BISType.FCI8 },
        { label: 'FCI9', value: BISType.FCI9 },
        { label: 'FCI10', value: BISType.FCI10 },
        { label: 'FCI11', value: BISType.FCI11 },
        { label: 'FCI1 Junior', value: BISType.FCI1Jr },
        { label: 'FCI2 Junior', value: BISType.FCI2Jr },
        { label: 'FCI3 Junior', value: BISType.FCI3Jr },
        { label: 'FCI4 Junior', value: BISType.FCI4Jr },
        { label: 'FCI5 Junior', value: BISType.FCI5Jr },
        { label: 'FCI6 Junior', value: BISType.FCI6Jr },
        { label: 'FCI7 Junior', value: BISType.FCI7Jr },
        { label: 'FCI8 Junior', value: BISType.FCI8Jr },
        { label: 'FCI9 Junior', value: BISType.FCI9Jr },
        { label: 'FCI10 Junior', value: BISType.FCI10Jr },
        { label: 'FCI11 Junior', value: BISType.FCI11Jr },
        { label: 'BIS', value: BISType.BIS },
        { label: 'BIS Junior', value: BISType.BISJr },
        { label: 'Puppy', value: BISType.Puppy },
        { label: 'Minor Puppy', value: BISType.MinorPuppy },
        { label: 'Braces', value: BISType.Braces },
        { label: 'Breeding Groups', value: BISType.BreedingGroups },
        { label: 'Veterans', value: BISType.Veterans },
        { label: 'JH1', value: BISType.JH1 },
        { label: 'JH2', value: BISType.JH2 },
        { label: 'SH', value: BISType.SH },
        { label: 'JH Final', value: BISType.JHFinal },
        { label: 'Indigenous Breed', value: BISType.IndigenousBreed },
    ];
}

export enum CustomBISShowGradeEnum {
    CAC = 0,
    RCAC = 1,
    CACJ = 2,
    CACV = 3,
    CACIB = 4,
    RCACIB = 5,
    CACIBJ = 6,
    CACIBV = 7,
    BOS = 8,
    BOB = 9,
    JuniorBOB = 10,
    VeteranBOB = 11,
    BestMinorPuppy = 12,
    BestPuppy = 13,
    Rang1 = 14,
    GradeExcellent = 15,
    PuppyVeryPromising = 16,
    BestMale = 17,
    BestFemale = 18
}

export function getCustomBISGradeTitle(type: CustomBISShowGradeEnum) {
    switch (type) {
        case CustomBISShowGradeEnum.BestMinorPuppy:
            return 'Best Minor Puppy'
        case CustomBISShowGradeEnum.BestPuppy:
            return 'Best Puppy'
        case CustomBISShowGradeEnum.BOB:
            return 'BOB'
        case CustomBISShowGradeEnum.BOS:
            return 'BOS'
        case CustomBISShowGradeEnum.CAC:
            return 'CAC'
        case CustomBISShowGradeEnum.CACIB:
            return 'CACIB'
        case CustomBISShowGradeEnum.CACIBJ:
            return 'CACIBJ'
        case CustomBISShowGradeEnum.CACIBV:
            return 'CACIBV'
        case CustomBISShowGradeEnum.CACJ:
            return 'CACJ'
        case CustomBISShowGradeEnum.CACV:
            return 'CACV'
        case CustomBISShowGradeEnum.JuniorBOB:
            return 'Junior BOB'
        case CustomBISShowGradeEnum.RCAC:
            return 'RCAC'
        case CustomBISShowGradeEnum.RCACIB:
            return 'RCACIB'
        case CustomBISShowGradeEnum.VeteranBOB:
            return 'Veteran BOB'

        case CustomBISShowGradeEnum.Rang1:
            return '1'
        case CustomBISShowGradeEnum.GradeExcellent:
            return 'Excellent'
        case CustomBISShowGradeEnum.PuppyVeryPromising:
            return 'Very Promising'
        case CustomBISShowGradeEnum.BestMale:
            return 'Best Male'
        case CustomBISShowGradeEnum.BestFemale:
            return 'Best Female'
        default:
            return 'Undefined'
    }
}

export function getCustomBISGrades() {
    let gradeList = []

    for (let grade in CustomBISShowGradeEnum) {
        const gradeNum = Number(grade)
        if (!isNaN(gradeNum)) {
            gradeList.push(generateValueLabel(gradeNum))
        }
    }

    return gradeList
}

function generateValueLabel(value: CustomBISShowGradeEnum) {
    return {
        value: value,
        label: getCustomBISGradeTitle(value)
    }
}
