import { Action, Reducer } from 'redux';
import { httpService } from '../index';
import { AppThunkAction } from './';
import { RegisteredUser, Role } from '../models/registered-user/RegisteredUser';
import { Country } from '../models/country/Country';
import { Session } from 'inspector';
import { getUserRoleTitle, UserType } from "../models/registered-user/RegisteredUserEnums";

export interface HelpersState {
    users: Array<RegisteredUser>,
    selectedUser: RegisteredUser | null

}

interface SelectRegisterdUserAction {
    type: 'SELECT_REGISTERED_USER';
    item: RegisteredUser;
}

interface RequestRegisterdUserAction {
    type: 'REQUEST_REGISTERED_USER';

}

interface RequestMoreUserAction {
    type: 'REQUEST_MORE_USERS';

}

interface ReceiveMoreUserAction {
    type: 'RECEIVE_MORE_USERS';
    items: Array<RegisteredUser>;

}

interface ReceiveRegisteredUserAction {
    type: 'RECEIVE_REGISTERED_USER';
    items: Array<RegisteredUser>;
    error: '';
}
interface GetUserAction {
    type: 'GET_USER';

}

interface SetUserAction {
    type: 'SET_NEW_USER';
    selectedUser: RegisteredUser | null;
}

type KnownAction =
    SelectRegisterdUserAction
    | RequestRegisterdUserAction
    | ReceiveRegisteredUserAction
    | ReceiveMoreUserAction
    | RequestMoreUserAction
    | GetUserAction
    | SetUserAction;

export const actionCreators = {
    selectItem: (item: RegisteredUser): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SELECT_REGISTERED_USER', item: item });
    },
    getUsers: (page?: number, username?: string, count?: number, usertype?: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.helpersList) {
            try {
                dispatch({ type: 'REQUEST_REGISTERED_USER' });
                let data = await httpService.getRegisteredUsers({page, username, count, usertype});
                dispatch({ type: 'RECEIVE_REGISTERED_USER', items: data, error: '' });

            } catch (err) {
                dispatch({ type: 'RECEIVE_REGISTERED_USER', items: [], error: '' });
            }
        }
    },

    getMoreUsers: (page: number, username?: string, count?: number, usertype?: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.helpersList) {
            try {
                dispatch({ type: 'REQUEST_MORE_USERS' });
                let data = await httpService.getRegisteredUsers({page, username, count, usertype});
                dispatch({ type: 'RECEIVE_MORE_USERS', items: data });

            } catch (err) {
                dispatch({ type: 'RECEIVE_MORE_USERS', items: [] });
            }
        }
    },

    getSingleUser: (id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dogList) {
            if (id !== null) {
                let data = await httpService.getRegisteredUser(id);
                dispatch({ type: 'SET_NEW_USER', selectedUser: data });
            } else {
                dispatch({ type: 'SET_NEW_USER', selectedUser: null });
            }
        }
    }
}

const unloadedState: HelpersState = {
    users: [],
    selectedUser: null
}

export const reducer: Reducer<HelpersState> = (state: HelpersState | undefined, incomingAction: Action): HelpersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_REGISTERED_USER':
            return {
                selectedUser: state.selectedUser,
                users: state.users,
            };
        case 'REQUEST_REGISTERED_USER':
            return {
                selectedUser: state.selectedUser,
                users: state.users
            };

        case 'REQUEST_MORE_USERS':
            return {
                selectedUser: state.selectedUser,
                users: state.users
            };
        case 'RECEIVE_REGISTERED_USER':
            return {
                selectedUser: state.selectedUser,
                users: action.items
            };
        case 'RECEIVE_MORE_USERS':
            return {
                selectedUser: state.selectedUser,
                users: action.items
            };
        case 'SET_NEW_USER':
            return {
                selectedUser: action.selectedUser,
                users: state.users
            };
    }

    return state;
};
