import * as React from 'react';
import SideMenu from './SideMenu';
import TopBar from './TopBar';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>

        <SideMenu />
        <div className="page-container">
            <TopBar />

            {/*### $App Screen Content ###*/}
            <main className='main-content bgc-grey-100'>
                <div id='mainContent'>
                    {props.children}
                </div>
            </main>

            {/*### $App Screen Footer ###*/}
            <footer className="bdT ta-c p-30 lh-0 fsz-sm c-grey-600">
                <span>Copyright &copy; E-Dog 2022. All rights reserved.</span>
            </footer>
        </div>
    </React.Fragment>
);
